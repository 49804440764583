import { Settings } from 'pages/Settings/Settings'
import { CreateUserPage } from 'pages/SuperadminUsers/subPages/CreateUserPage/CreateUserPage'
import { EditUserPage } from 'pages/SuperadminUsers/subPages/EditUserPage/EditUserPage'
import { SuperadminUsersPage } from 'pages/SuperadminUsers/SuperadminUsers'
import { UserProfilePage } from 'pages/UserProfilePage'
import { Route } from './routes-def'
import { initialWebRoles, PagesRoutes } from './routes-utils'

export const userRoutes: Route[] = [
	//Пользователи
	{
		title: 'Пользователи',
		path: PagesRoutes.USERS,
		component: <SuperadminUsersPage />,
		navBar: true,
		icon: 'manage_accounts',
		availableRoles: initialWebRoles
	},
	{
		title: 'Профиль пользователя',
		path: `${PagesRoutes.USERS}/:userId`,
		component: <UserProfilePage />,
		navBar: false,
		availableRoles: ['worker']
	},
	{
		title: 'Архив пользователей',
		path: PagesRoutes.USERS + PagesRoutes.ARCHIVE,
		component: <SuperadminUsersPage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Создание пользователя',
		path: PagesRoutes.USERS + PagesRoutes.CREATE_USER,
		component: <CreateUserPage />,
		navBar: false,
		availableRoles: ['admin']
	},
	{
		title: 'Редактирование пользователя',
		path: PagesRoutes.USERS + PagesRoutes.EDIT_USER,
		component: <EditUserPage />,
		navBar: false,
		availableRoles: ['admin']
	},
	{
		title: 'Редактирование пользователя',
		path: PagesRoutes.USERS + PagesRoutes.EDIT_USER + '/:searchID?',
		component: <EditUserPage />,
		navBar: false,
		availableRoles: ['admin']
	},
	{
		title: 'Удаление пользователя',
		path: PagesRoutes.USERS + PagesRoutes.DELETE_USER + '/:searchID?/',
		component: <EditUserPage />,
		navBar: false,
		availableRoles: ['admin']
	},
	{
		title: 'Восстановление пользователя',
		path: PagesRoutes.USERS + PagesRoutes.RESTORE_USER + '/:searchID?/',
		component: <EditUserPage />,
		navBar: false,
		availableRoles: ['admin']
	},
	{
		title: 'Настройки',
		path: '/settings',
		component: <Settings />,
		navBar: true,
		icon: 'settings',
		availableRoles: ['admin','moderator']
	},

	//Супер админ?
	// {title: "Оргиназиции", path: "/organizations-list", component: <OrganizationsList />, navBar: true, icon: "settings", availableRoles: ["admin", "organizer"],},
	// {title: "Оргиназиции", path: "/organizations-list/users", component: <OrganizationsList />, navBar: false, icon: "settings", availableRoles: ["admin", "organizer"],},
]
