import React, { FC, useCallback } from 'react'
import { FormFieldLabelProps } from './form-field-label-def'
import {SpanButtons} from 'components'
import './form-field-label.sass'

export const FormFieldLabel = <TValue extends string | number = string>({
	title,
	caption,
	value,
	onDelete
}: FormFieldLabelProps<TValue>) => {
	const handleOnDelete = useCallback(() => {
		onDelete?.(value)
	}, [value, onDelete])

	return (
		<div className='form-field-label-value'>
			<div className='form-field-label-header'>
				<h4 className='form-field-label-title'>{title}</h4>
				<h4 className='form-field-label-caption'>{caption}</h4>
			</div>
			{!!onDelete && (
				<SpanButtons
					data={[
						{
							label: 'Удалить',
							onClick: handleOnDelete
						}
					]}
				/>
			)}
		</div>
	)
}
