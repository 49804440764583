import React, {FC, useCallback} from "react";
import {AboutMeSubPageProps} from "./about-me-def";
import "./about-me.sass";
import {SpanButtons, InfoBlock} from "components";

function getJobStatusColor(status: string) {
    const colors = {
        green: "green",
        orange: "orange",
        red: "red",
    };

    switch (status) {
        case "Не работаю, рассматриваю предложения":
            return colors.green;
        case "Трудоустроен, рассматриваю предложения":
            return colors.orange;
        case "Трудоустроен, не рассматриваю предложения":
            return colors.red;
    }
}

export const AboutMeSubPage: FC<AboutMeSubPageProps> = ({
                                                            about,
                                                            workSearchStatus,
                                                            workSearchSchedule,
                                                            setIsOpenEditWorkerProfileForm,
                                                            isDisabledBlock,
                                                        }) => {
    const handleClick = useCallback(() => {
        setIsOpenEditWorkerProfileForm("about-me");
    }, []);

    return (
        <div className="about-me-wrapper">
            <ul className="about-me-list">
                <li className="about-me-item">
                    <InfoBlock
                        title="Текущий статус работы"
                        content={
                            !!workSearchStatus ? (
                                <div className="job-status">
                                    <div
                                        style={{
                                            backgroundColor: getJobStatusColor(workSearchStatus),
                                        }}
                                        className="job-status-circle"
                                    />
                                    <span>{workSearchStatus}</span>
                                </div>
                            ) : (
                                <div/>
                            )
                        }
                    />
                </li>

                <li className="about-me-item">
                    <InfoBlock
                        title="Желательный график работы"
                        content={!!workSearchSchedule ? workSearchSchedule : ""}
                    />
                </li>
                {!!about && (
                    <li className="about-me-item">
                        <InfoBlock title="О себе" content={!!about ? about : ""}/>
                    </li>
                )}
            </ul>

            <div>
                <SpanButtons
                    data={[
                        {
                            label: "Редактировать",
                            onClick: handleClick,
                            disabled: isDisabledBlock,
                        },
                    ]}
                />
            </div>
        </div>
    );
};
