import React, {useContext, useEffect, useState} from "react";
import './AnalyticsPage.style.sass'
import {PageTitle, ProgressPage} from "../../../components";
import {useTranslation} from "react-i18next";
import {Button} from "@rmwc/button";
import {Icon} from "@rmwc/icon";
import {req} from "../../../global/common";
import {format} from "date-fns";
import {InterfaceContext} from "../../../global/context.interface";
import {IAnalyticsPage} from "./AnalyticsPage.types";

export const AnalyticsPage: React.FC<{}> = () => {
    const {t} = useTranslation('common')
    const [isLoadingButtons, setIsLoadingButtons] = useState<string[]>(['log'])
    const [analyticsItems, setAnalyticsItems] = useState<IAnalyticsPage[]>([])
    const interfaceCTX = useContext(InterfaceContext)

    const generateAnalyticsFile = (dataType: string) => {
        setAnalyticsItems(prevState => prevState.map((item: IAnalyticsPage) => {
            if (item.dataType === dataType) {
                return {
                    ...item,
                    loading: true
                }
            } else {
                return {
                    ...item,
                    loading: false
                }
            }
        }))
        setIsLoadingButtons(prevState => {
            if (prevState.includes(dataType)) {
                return prevState
            } else return [...prevState, dataType]
        })
        req.post(`/generate?type=${dataType}`)
            .then(({data}) => {
                getAnalyticsValues()
            })
            .catch((e) => {
                setAnalyticsItems(prevState => prevState.map((item: IAnalyticsPage) => {
                    return {
                        ...item,
                        loading: false
                    }
                }))
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response?.data)
                })
            })
            .finally(() => {
                setIsLoadingButtons(prevState => ([...prevState.filter((item) => item !== dataType)]))
            })
    }

    const downloadFile = (url: string, fullname: string) => {
        const currentDate = format(new Date(), 'dd.MM.yyyy')

        fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = blobUrl;
                link.setAttribute("download", `${fullname} ${currentDate}.csv`);
                document.body.appendChild(link);
                link.click();
            });
    };

    const getAnalyticsValues = (dataType?: string) => {
        req.get('/get')
            .then(({data}) => {
                setAnalyticsItems(data.map((item: IAnalyticsPage) => {
                    if (item.dataType === dataType) {
                        return {
                            ...item,
                            loading: true
                        }
                    } else {
                        return {
                            ...item,
                            loading: false
                        }
                    }
                }))
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response?.data)
                })
            })
    }

    useEffect(() => {
        getAnalyticsValues()
    }, [])

    return (
        <div className={'analytics-page'}>
            <PageTitle title={'Аналитика'} breadcrumbs={true}/>
            <ProgressPage
                state={[]}
                render={() => (
                    <div className={'analytics-items'}>
                        {analyticsItems.map(item => (
                            <div className={'analytics-item'} key={item.dataType}>
                                <div className={'analytics-item-name'}>
                                    {t('analytics.' + item.dataType)}
                                </div>
                                <div className={'analytics-item-date'}>
                                    {item.date ? 'Отчет создан: ' + format(new Date(item.date), 'dd.MM.yyyy HH:mm') : ''}
                                </div>
                                {item.url
                                    ? <div
                                        className={'analytics-item-download'}
                                        onClick={() => {
                                            if (item.url) {
                                                downloadFile(item.url, t('analytics.' + item.dataType))
                                            }
                                        }}
                                    >
                                        Скачать
                                    </div>
                                    : <div className={'analytics-item-nofile'}/>
                                }
                                <div className={'analytics-item-btn'}>
                                    <Button
                                        raised
                                        disabled={isLoadingButtons.includes(item.dataType)}
                                        onClick={() => {
                                            generateAnalyticsFile(item.dataType)
                                        }}
                                    >
                                        {item.loading
                                            ? <div className={'analytics-item-btn-inner'}>
                                                <div className={'analytics-item-btn-text'}>
                                                    Идет загрузка...
                                                </div>
                                                <div className={'analytics-item-btn-loader'}>
                                                    <div className="lds-spinner">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className={'analytics-item-btn-inner'}>
                                                <div className={'analytics-item-btn-text'}>
                                                    Создать отчет
                                                </div>
                                                {!item.url && <Icon icon={'add'}/>}
                                            </div>
                                        }
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            />
        </div>
    )
}