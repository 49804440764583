import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { InterfaceContext } from "../../../../global/context.interface";
import { req } from "../../../../global/common";
import { ProgressPage, TabButtons } from "../../../../components";
import { Icon } from "@rmwc/icon";
import { IOnboarding } from "./Onboarding.types";
import "./Onboarding.style.sass";


export const Onboarding: React.FC<{ platform: "mobile" | "web", module: "admin" | "hr" | "foreman" | "worker" | "recruiter" }> = ({
                                                                                                                        platform,
                                                                                                                        module
                                                                                                                    }) => {
    const interfaceCTX = useContext(InterfaceContext);
    const [onboardingList, setOnboardingList] = useState<IOnboarding[]>([]);
    const [isLoading, setIsLoading] = useState<any>(null);
    const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            const newFile = e.target.files[0];
            if (newFile.size > 5000000) {
                inputRef.current.value = "";
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: "Максимальный вес файла: 5мб"
                });
                return;
            } else if (!(newFile.type.includes("svg") || newFile.type.includes("png"))) {
                inputRef.current.value = "";
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: "Форматы файла: svg, png"
                });
                return;
            } else {
                let formData = new FormData();
                formData.append("file", newFile);
                const reader = new FileReader();
                reader.addEventListener("load", async () => {
                    let image = new Image();
                    image.src = !!reader.result ? reader.result.toString() : "";
                    let timer = setTimeout(() => {
                        let proportion = image.height / 1.75;
                        if (platform === "mobile" && (proportion < image.width - 2 || proportion > image.width + 2)) {
                            interfaceCTX.showMessage({
                                title: "Ошибка",
                                body: "Допустимое соотношение сторон 1х1,75"
                            });
                            clearTimeout(timer);
                            return;
                        }
                        if ((image.width !== 1097 || image.height !== 1920) && platform === "mobile") {
                            interfaceCTX.showMessage({
                                title: "Ошибка",
                                body: "Допустимое разрешение 1097x1920 пикс."
                            });
                            clearTimeout(timer);
                            return;
                        }
                        if ((image.width !== 1920 || image.height !== 1415) && platform === "web") {
                            interfaceCTX.showMessage({
                                title: "Ошибка",
                                body: "Допустимое разрешение 1920х1415 пикс."
                            });
                            clearTimeout(timer);
                            return;
                        }
                        clearTimeout(timer);
                        loadFiles(formData);
                    }, 100);

                });
                reader.readAsDataURL(newFile);
                inputRef.current.value = "";
            }
        }
    }

    const deleteFiles = (id: number) => {
        req.post("/moderator/organisation/delete-onboarding-img", {
            ids: [id]
        })
            .then(({ data }) => {
                isSelectMode();
            });
    };

    const loadFiles = (newFile: FormData) => {
        const position = onboardingList.length > 0 ? onboardingList[onboardingList.length - 1].position + 1 : 1;
        req.post(`/moderator/organisation/onboarding-img?position=${position}&platformType=${platform}&module=${module}`, newFile)
            .then(({ data }) => {
                isSelectMode();
            });
    };

    const isSelectMode = () => {
        setIsLoading(null);
        req.get(`/info/onboarding?platformType=${platform}&module=${module}`)
            .then(({ data }) => {
                setOnboardingList(data.data.sort((a: any, b: any) => {
                    return a.position - b.position;
                }));
            })
            .catch(({  }) => {
                setOnboardingList([]);
            })
            .finally(() => setIsLoading(true));
    };

    useEffect(() => {
        isSelectMode();
    }, [module]);

    return (
        <div className={"onboarding"}>
            <ProgressPage
                state={isLoading}
                render={() => (
                    <>
                        <div className={"onboarding-body"}>
                            <div className={"onboarding-field"}>
                                <div className={"onboarding-files"}>
                                    {onboardingList.map(item => {
                                        return (
                                            <div
                                                className={`onboarding-file onboarding-file-loaded ${platform === "web" ? "web-version" : "mobile-version"}`}
                                                style={{ backgroundImage: `url(${item.path})` }} key={item.id}>
                                                <Icon
                                                    className="close"
                                                    icon={{ icon: "close", size: "large" }}
                                                    onClick={() => {
                                                        deleteFiles(item.id);
                                                    }}
                                                />
                                                <Icon icon={"zoom_in"}
                                                      className={`zoom_in zoom_in_${platform === "web" ? "web" : "mobile"}`}
                                                      onClick={() => interfaceCTX.getPreviewImgUrl(item.path)} />
                                            </div>
                                        );
                                    })}
                                    {onboardingList.length < 18 &&
                                        <div
                                            className={`onboarding-files-upload onboarding-file ${platform === "web" ? "web-version-upload" : "mobile-version-upload"}`}>
                                            <Icon className="add" icon={{ icon: "add", size: "xlarge" }} />
                                            <div className={"onboarding-files-upload-description"}>
                                                {platform === "mobile" ?
                                                    <>
                                                        <div>{"Мин. размер 1097х1920 рх"}</div>
                                                        <div>Максимальный вес 5 МВ</div>
                                                        <div>Формат файла: SVG, PNG</div>
                                                    </>
                                                    : <>
                                                        <div>{"Мин. размер 1920х1415 рх"}</div>
                                                        <div>Максимальный вес 5 МВ</div>
                                                        <div>Формат файла: SVG, PNG</div>
                                                    </>
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                ref={inputRef}
                                                onChange={onSelectFile}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )}
            />
        </div>
    );
};