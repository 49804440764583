import {Icon} from '@rmwc/icon'
import clsx from 'clsx'
import {FC, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import Rating from 'react-rating'
import {isReview, PendingReviewContext} from '../..'
import {ReviewCommentForm} from '../review-comment-form'
import {PendingReviewCardProps} from './pending-review-card-def'
import './pending-review-card.sass'
import {InterfaceContext} from "../../../../../../../global/context.interface";

export const PendingReviewCard: FC<PendingReviewCardProps> = ({
                                                                  className,
                                                                  review,
                                                                  openComment,
                                                                  onOpenCommentForm
                                                              }): JSX.Element => {
    const {t} = useTranslation('common')
    const {setSelectedReview} = useContext(PendingReviewContext)
    const interfaceCTX = useContext(InterfaceContext)

    const openCommentFormHandler = () => {
        if (interfaceCTX.isFormChanged.review) {
            if (window.confirm('Все несохраненные данные будут удалены. Вы уверены?')) {
                setSelectedReview(review)
                onOpenCommentForm()
                interfaceCTX.checkIsFormChanged(false, 'review')
            }
        } else {
            setSelectedReview(review)
            onOpenCommentForm()
        }
    }

    return (
        <div className={clsx('pending-review-card', className)}>
            <div className='pending-review-card-table'>
                {isReview(review) && (
                    <div className='pending-review-card-table-row pending-review-card-review-stats'>
                        <div className='pending-review-card-table-cell'>
                            <span className='pending-review-card-date'>{review.review.date}</span>
                        </div>
                        <div className='pending-review-card-table-cell'>
                            <Rating
                                emptySymbol={<Icon icon='star_outline'/>}
                                fullSymbol={<Icon icon='star'/>}
                                initialRating={review.review.score}
                                readonly
                            />
                        </div>
                        <div className='pending-review-card-table-cell'>
                            <div className='pending-review-card-work-period'>
                                <div>Период работы</div>
                                <div>
                                    {review.review.workFrom} - {review.review.workTo}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='pending-review-card-table-row pending-review-card-profile-review'
                     style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    <div className='pending-review-card-table-cell'>
                        <span
                            className='pending-review-card-profile-review-name'>{`${review.profile.lastName} ${review.profile.firstName} ${review.profile.middleName}`}</span>
                    </div>
                    <div className='pending-review-card-table-cell'>
                        <span
                            className='pending-review-card-profile-review-role'> {t('role.' + review.profile.currentRole)}</span>
                    </div>
                    {isReview(review) && (
                        <div className='pending-review-card-table-cell'>
                            <div className='pending-review-card-profile-review-comment'
                                 style={{
                                     wordWrap: 'break-word',
                                 }}
                            >{review.review.comment}</div>
                        </div>
                    )}
                </div>
                {!openComment && (
                    <div className='pending-review-card-table-row'>
                        {isReview(review) ? (
                            <div className='pending-review-card-table-cell'>
                                <button className='pending-review-card-button' onClick={openCommentFormHandler}>
                                    Редактировать
                                </button>
                            </div>
                        ) : (
                            <div className='pending-review-card-table-cell'>
                                <button className='pending-review-card-button' onClick={openCommentFormHandler}>
                                    Написать
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {openComment && <ReviewCommentForm className='pending-review-card-comment-form'/>}
        </div>
    )
}
