import React, {useContext} from "react";
import {Props} from "./document-def";
import {Icon} from "@rmwc/icon";
import {InterfaceContext} from "../../../../../../../global/context.interface";
import noScanIcon from "../../../../../../../misc/no-document-scan-icon.svg";
import "./document.sass";

export const Document = ({
                             docName,
                             docNumber,
                             docRegNum,
                             issueDate,
                             pathPreview,
                             path,
                         }: Props) => {
    let context = useContext(InterfaceContext);

    let previewWrapperStyle = {};

    if (pathPreview) {
        previewWrapperStyle = {
            backgroundImage: `url('${pathPreview}')`,
        };
    } else {
        previewWrapperStyle = {
            backgroundImage: `url('${noScanIcon}')`,
            backgroundSize: "53px",
            backgroundColor: "#F5F5F8",
            cursor: "default",
        };
    }

    return (
        <div className="document-worker-wrapper">
            <div className="document-worker-inner">
                {docNumber && <div className="document-worker-text">{docNumber}</div>}
                {issueDate && (
                    <div className="info-wrapper">
                        <div className="title">Дата выдачи</div>
                        <div className="text">{issueDate}</div>
                    </div>
                )}
                {docName && (
                    <div className="info-wrapper">
                        <div className="title">{docName}</div>
                        <div className="text">{docRegNum}</div>
                    </div>
                )}

                <div
                    onClick={() => context.getPreviewImgUrl(path as string)}
                    className="img-wrapper"
                    style={previewWrapperStyle}
                >
                    {!!pathPreview && <Icon icon={"zoom_in"} className="preview-loupe"/>}
                </div>
            </div>
        </div>
    );
};
