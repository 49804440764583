import { Checkbox } from "@rmwc/checkbox";
import { Icon } from "@rmwc/icon";
import React, { useContext, useState } from "react";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { InterfaceContext } from "../../global/context.interface";
import { UserContext } from "../../global/context.user";
import { isActivePageAvailableForCurrentRole, ISideBarMenuItem, navigationSections } from "./Settings.service";
import { ModuleBlockNotification, PageTitle } from "components";
import "./Settings.style.sass";

export function Settings() {

    // Включает/отключает отрисовку секций меню и элементов меню в зависимости от роли пользователя
    const disableRoleRender = false;
    // ===============

    const userCTX = React.useContext(UserContext);
    const history = useHistory();
    const location = useLocation();
    const interfaceCTX = useContext(InterfaceContext);

    const [homepage, setHomepage] = React.useState("");
    const [activePage, setActivePage] = React.useState("");
    const [isShowSettingsMenu, setIsShowSettingsMenu] = useState(false);

    const getClassNames = (sectionURL: string, url: string) => {
        if (userCTX.user?.organisation?.modulesStatus.hr !== "active" && `/settings/${sectionURL}/${url}` === "/settings/need/template-customization") {
            return "settings-module-blocked noSelect";
        } else if (userCTX.user?.organisation?.modulesStatus.organizer !== "active" && `/settings/${sectionURL}/${url}` === "/settings/mobilization/mobilization-template" || `/settings/${sectionURL}/${url}` === "/settings/mobilization/demobilization-template") {
            return "settings-module-blockedm noSelect";
        } else return "";
    };

    React.useEffect(() => {
        switch (userCTX.user!.roles[0]) {
            case "admin":
                setHomepage("/settings/organization-profile/profile-template");
                break;
            case "organizer":
                setHomepage("/settings/mobilization/mobilization-template");
                break;
            case "moderator":
                setHomepage("/settings/directory/groups");
                break;
        }
        // Код ниже - вынужденная мера, чтобы заиметь доступ к стилям родителя только на этой странице
        let appContent = document.getElementsByClassName("app-content")[0];
        appContent.classList.add("no-padding");
        return () => {
            appContent.classList.remove("no-padding");
            setActivePage(location.pathname);
        };
        // ==========================================================================================
    }, []);

    React.useEffect(() => {
        if (location.pathname == "/settings") {
            if (homepage != "") {
                history.push(homepage);
            }
        } else {
            if (homepage != "" && !isActivePageAvailableForCurrentRole(userCTX.user!.roles[0], location.pathname, navigationSections)) {
                history.push(homepage);
            }
        }
    }, [homepage]);

    React.useEffect(() => {
        setActivePage(location.pathname);
        if (homepage != "" && location.pathname == "/settings") {
            history.push(homepage);
        }
    }, [location.pathname]);

    return (
        <div className="settings-page">
            <div className={`side-bar-wrapper ${isShowSettingsMenu ? "side-bar-wrapper-show" : ""}`}>
                <div className={`show-icon ${isShowSettingsMenu ? "show-icon-show" : ""}`}
                     onClick={() => {
                         setIsShowSettingsMenu(prevState => !prevState);
                     }}>
                    <Icon icon={isShowSettingsMenu ? "arrow_forward_ios" : "arrow_back_ios"} />
                </div>
                {navigationSections.filter(item => item.show !== false).map((navSection: ISideBarMenuItem) => {
                    if (disableRoleRender || navSection.navItems.filter(navItem => navItem.visibleFor.includes(userCTX.user!.roles[0])).length > 0) {
                        return (
                            <div className="nav-section" key={navSection.sectionURL}>
                                <div
                                    className={`nav-section-head ${isShowSettingsMenu ? "nav-section-head-show" : ""}`}>
                                    <Icon icon={navSection.icon} />
                                    <h3 className={`nav-section-title ${isShowSettingsMenu ? "nav-section-title-show" : ""}`}>
                                        {navSection.sectionTitle}
                                    </h3>
                                </div>
                                {navSection.navItems.map((navItem, index) => {
                                    if ((disableRoleRender || navItem.visibleFor.includes(userCTX.user!.roles[0])) && navItem.navBar) {
                                        return (
                                            <Link
                                                key={navItem.url}
                                                to={`/settings/${navSection.sectionURL}/${navItem.url}`}
                                                className={`nav-item-link ${isShowSettingsMenu ? "nav-item-link-show" : ""} ${activePage == `/settings/${navSection.sectionURL}/${navItem.url}` && "active"}`}
                                                onClick={() => !interfaceCTX.isFormChanged.settings && setActivePage(`/settings/${navSection.sectionURL}/${navItem.url}`)}
                                            >
                                                <div className="nav-item-link-number">{index + 1}</div>
                                                <div
                                                    className={`nav-item-title ${isShowSettingsMenu ? "nav-item-title-show" : ""}`}>{navItem.itemTitle}</div>
                                            </Link>
                                        );
                                    }
                                })}
                            </div>
                        );
                    }
                })}
            </div>
            <div className={`content-wrapper ${isShowSettingsMenu ? "content-wrapper-show" : ""}`}>

                <Switch>
                    {navigationSections.filter(item => item.show !== false).map((navSection: ISideBarMenuItem) =>
                        navSection.navItems.map((navItem, index) => {
                                if (disableRoleRender || navItem.visibleFor.includes(userCTX.user!.roles[0])) {
                                    return <Route key={navItem.url + navSection.sectionURL + index}
                                                  path={`/settings/${navSection.sectionURL}/${navItem.url}`} exact>
                                        <PageTitle
                                            title={
                                                <div className="title">
                                                    <div>{navSection.sectionTitle}</div>
                                                    <Icon className="icon" icon="chevron_right" />
                                                    <div>{navItem.itemTitle}</div>
                                                </div>
                                            }
                                        />
                                        {userCTX.user?.organisation?.modulesStatus.organizer !== "active" &&
                                            (`/settings/${navSection.sectionURL}/${navItem.url}` === "/settings/mobilization/mobilization-template" ||
                                                `/settings/${navSection.sectionURL}/${navItem.url}` === "/settings/mobilization/demobilization-template") &&
                                            <ModuleBlockNotification moduleType={"mobilization"} />
                                        }
                                        <div
                                            className={`content-inner ${navItem.background ? "content-inner-show" : ""}`}>
                                            <div className={`${getClassNames(navSection.sectionURL, navItem.url)}`}>
                                            </div>
                                            {navItem.description &&
                                                <div className="description">
                                                    <div className="inner">
                                                        <div className="description-title">
                                                            Доступность и обязательность полей формы
                                                        </div>
                                                        <div className="row-wrapper">
                                                            <Checkbox
                                                                checked
                                                                disabled
                                                            />
                                                            <div className="text">
                                                                Поле с серым чек-боксом нельзя исключить из отображения
                                                            </div>
                                                        </div>
                                                        <div className="row-wrapper">
                                                            <Checkbox
                                                                checked
                                                            />
                                                            <div className="text">
                                                                Поле с включенным отображением
                                                            </div>
                                                        </div>
                                                        {!navSection.sectionURL.includes("organization")
                                                            && <div className="row-wrapper">
                                                                <Checkbox
                                                                    className="required-default"
                                                                    checked
                                                                />
                                                                <div className="text">
                                                                    Обязательное поле
                                                                </div>
                                                            </div>}
                                                    </div>
                                                </div>
                                            }
                                            {navItem.component}
                                        </div>
                                    </Route>;
                                }
                            }
                        ))}
                </Switch>
            </div>
        </div>
    );
}