import { LoadingProgress, ProgressPage } from 'components'
import { RequestCard } from 'pages/auth-as-worker'
import { FC } from 'react'
import { RequestListProps } from './request-list-def'
import './request-list.sass'

export const RequestList: FC<RequestListProps> = ({ requests, isUploading }): JSX.Element => {
	return (
		<ProgressPage
			state={requests.length !== 0}
			render={() => (
				<>
					<div className='request-list'>
						{requests.map(request => (
							<RequestCard key={request.id} request={request} />
						))}
					</div>
					{isUploading !== undefined && !isUploading && <LoadingProgress />}
				</>
			)}
		/>
	)
}
