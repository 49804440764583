import { ITableHeaderSettings } from "./WorkersDatabase.types";

export const tableHeaderSettings: ITableHeaderSettings[] = [
    {
        id: 0,
        minWidth: 45,
        columnType: 'check',
        sortType: 'NONE',
        filterType: null,
        title: "check",
    },
    {
        id: 1,
        minWidth: 165,
        columnType: 'fullName',
        sortType: 'NONE',
        filterType: null,
        title: "ФИО",
    },
    {
        id: 2,
        minWidth: 180,
        columnType: 'baseProfession',
        sortType: 'NONE',
        filterType: null,
        title: "Специальность",
    },
    {
        id: 3,
        minWidth: 200,
        columnType: 'address',
        sortType: 'NONE',
        filterType: null,
        title: "Регион",
    },
    {
        id: 4,
        minWidth: 275,
        columnType: 'hasContact',
        sortType: 'NONE',
        filterType: null,
        title: "Контактные данные",
    },
    {
        id: 5,
        minWidth: 200,
        columnType: 'workSearchStatus',
        sortType: 'NONE',
        filterType: null,
        title: "Статус работы",
    },
]