import { AxiosPromise } from 'axios'
import { req } from 'global/common'
import { ContractResponse, ShortContractResponseList } from './contracts-def'

export const contractsApi = {
	getContracts: (limit: number, offset: number, type: string): AxiosPromise<ShortContractResponseList> => {
		return req.get('/contracts/my-history', { limit, offset, type })
	},
    getContractById: (workerRecordId: number): AxiosPromise<ContractResponse> => {
        return req.get(`/contracts/${workerRecordId}/get`)
    }
}
