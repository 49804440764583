import { IShiftStatsData } from "./TimeKeepingReport.types"

export function getYearOptions(limitFromCurrentYear: number) {
    let currentYear = new Date().getFullYear()
    let startYear = currentYear - limitFromCurrentYear
    let yearOptions = []
    for (let i = 0; i <= limitFromCurrentYear; i++) {
        yearOptions.push({
            label: currentYear - i,
            value: currentYear - i
        })
    }
    return yearOptions
}

export function formatHoursAndMinutes(data: IShiftStatsData) {
    let formatedMinutes = data.minutes < 10 ? '0' + data.minutes : data.minutes
    let formattedHours = data.hours < 10 ? '0' + data.hours : data.hours

    return formattedHours + ":" + formatedMinutes
}


export const monthOptions = [
    {
        value: 1,
        label: "Январь"
    },
    {
        value: 2,
        label: "Февраль"
    },
    {
        value: 3,
        label: "Март"
    },
    {
        value: 4,
        label: "Апрель"
    },
    {
        value: 5,
        label: "Май"
    },
    {
        value: 6,
        label: "Июнь"
    },
    {
        value: 7,
        label: "Июль"
    },
    {
        value: 8,
        label: "Август"
    },
    {
        value: 9,
        label: "Сентябрь"
    },
    {
        value: 10,
        label: "Октябрь"
    },
    {
        value: 11,
        label: "Ноябрь"
    },
    {
        value: 12,
        label: "Декабрь"
    },
]

export const tableColumnDimensions = {
    orderNumber: {
        minWidth: '65px',
        width: '65px',

        // left: '0'
        // width: "9%"
    },
    name: {
        minWidth: '300px',
        // width: "20%"
    },
    position: {
        minWidth: '300px',
        // width: "20%"
    },
    responsible: {
        minWidth: '300px',
        // width: "20%"
    },
    tabularNumber: {
        minWidth: '150px',
        // width: "12%"
    },
    grade: {
        minWidth: '150px',
        // width: "12%"
    },
    shift: {
        minWidth: '150px',
        // width: "12%"
    },
    day: {
        minWidth: '50px',
        // width: "12%"
    },
    total: {
        minWidth: '140px',
        // width: "12%"
    }
}

let example = [
    {
        objectName: "sss",
        brigades: [
            {
                brigadeName: 'sss',
                workers: [
                    {
                        firstName: 'sss',
                        lastName: 'sss',
                        days: [
                            {
                                17: 8,
                                20: 8
                            },
                        ]
                    }
                ]
            }
        ]
    }
]