import React, {useCallback} from "react";
import {DocumentImageProps} from "./document-image-def";
import {WorkerProfileImage} from "../../../worker-profile-image/worker-profile-image";
import {Controller} from "react-hook-form";
import {FormField} from "../../../form-field/form-field";

export const DocumentImage: React.FC<DocumentImageProps> = ({
                                                                value,
                                                                onChange,
                                                                control,
                                                            }) => {
    const handleAddDocument = useCallback(() => {
        if (!value || value.length >= 10) {
            return;
        }
        onChange?.([
            ...value,
            {id: -1 * Date.now(), type: 'passport'} as NonNullable<DocumentImageProps["value"]>[number],
        ]);
    }, [value, onChange]);

    return (
        <FormField
            value={value}
            style={{
                display: 'flex',
                flexWrap: 'wrap'
            }}
            title="Фото паспорта"
            label="Добавить документ"
            onClick={handleAddDocument}
            renderItem={(document, index) => {
                return (
                        <Controller
                            name={`passportMedia.${index}.path`}
                            control={control}
                            render={({field: {name, onChange}}) => (
                                <WorkerProfileImage
                                    id={name}
                                    image={document}
                                    onImageDelete={() => {
                                        onChange(null);
                                    }}
                                    onImageChange={(file) => {
                                        onChange(URL.createObjectURL(file));
                                    }}
                                    style={{flex: '0 1 43%', margin: '5 auto'}}
                                    className="permission-documents-image-field"
                                />
                            )}
                        />
                );
            }}
        />
    );
};
