import React, { FormEvent, useRef, useState } from "react";
import { IArrayOfValues, IDialogMenuFilter } from "./DialogMenuFilter.types";
import "./DialogMenuFilter.style.sass";
import { TextField } from "@rmwc/textfield";
import { Icon } from "@rmwc/icon";
import { useDebounceEffect } from "custom-hooks";
import { Checkbox } from "@rmwc/checkbox";
import { req } from "global/common";
import { useTranslation } from "react-i18next";
import { ProgressPage } from "components";

export const DialogMenuFilter: React.FC<IDialogMenuFilter> = ({
                                                                  isMenuFilterOpen,
                                                                  onCloseMenuFilter,
                                                                  columnType,
                                                                  columnTitle,
                                                                  onChangeFilterValues,
                                                                  isWithSearch = true,
                                                                  uniqueValues,
                                                                  currentFilter,
                                                                  getApiUrl,
                                                                  paginateFilter,
                                                                  isFirst
                                                              }) => {
    const [textValue, setTextValue] = useState<string>("");
    const { t } = useTranslation("common");
    const [filterValues, setFilterValues] = useState<string[]>(currentFilter ? currentFilter : []);
    const [isSelectAllFilterValues, setIsSelectAllFilterValues] = useState(false);
    const [arrayOfValues, setArrayOfValues] = useState<IArrayOfValues>(null as unknown as IArrayOfValues);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(20);
    const [fetching, setFetching] = useState(false);
    const scroller = useRef<HTMLInputElement>(null);

    const selectAllFilterValues = (isSelected: boolean) => {
        if (isSelected) {
            setFilterValues(arrayOfValues.statics);
            setIsSelectAllFilterValues(true);
        } else if (!isSelected) {
            setFilterValues([]);
            setIsSelectAllFilterValues(false);
        }
    };

    const getValues = (typeCommand: "fetch" | "textChange") => {
        if (!isMenuFilterOpen) return;
        let newLimit = limit;
        if (typeCommand === "fetch") {
            newLimit = limit + 20;
            setLimit(prevState => prevState + 20);
        }
        req.get(`${getApiUrl}`, {
            type: columnType,
            search: textValue,
            limit: newLimit
        })
            .then(({ data }) => {
                let arrayValues: any;
                arrayValues = data.data.filter((item: string) => !!item);
                setArrayOfValues((prevState: IArrayOfValues) => ({
                    ...prevState,
                    statics: arrayValues,
                    mutable: arrayValues
                }));
                setTotal(data.total);
                if (typeCommand === "fetch") {
                    setFetching(false);
                }
            })
            .catch((error) => {
            });
    };

    const scrollHandler = (e: React.UIEvent<HTMLElement>) => {
        //@ts-ignore
        if (e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + scroller.current.offsetHeight) < 100) {
            if (total > arrayOfValues.statics.length) {
                setFetching(true);
            }
        }
    };

    // React.useEffect(() => {
    //     if (getApiUrl) {
    //         getValues();
    //     } else {
    //         setArrayOfValues((prevState: IArrayOfValues) => ({
    //             ...prevState,
    //             statics: uniqueValues.filter((item: any) => !!item),
    //             mutable: uniqueValues.filter((item: any) => !!item)
    //         }));
    //     }
    // }, [searchValue]);

    const searchFilterValues = (value: string) => {
        if (value !== "") {
            setArrayOfValues((prevState: IArrayOfValues) => ({
                ...prevState,
                mutable: prevState.statics.filter(item => !!item && item.toString().toLowerCase().includes(value.toLowerCase()))
            }));
        } else {
            setArrayOfValues((prevState: IArrayOfValues) => ({
                ...prevState,
                mutable: prevState.statics
            }));
        }
    };

    const renderFilterValue = (item: string) => {
        if (!isWithSearch) {
            return t("menu_filter." + item);
        } else return item;
    };

    useDebounceEffect(() => {
        if (getApiUrl) {
            getValues("textChange");
        } else if (arrayOfValues) {
            searchFilterValues(textValue);
        } else {
            setArrayOfValues((prevState: IArrayOfValues) => ({
                ...prevState,
                statics: uniqueValues.filter((item: any) => !!item),
                mutable: uniqueValues.filter((item: any) => !!item)
            }));
        }
    }, 300, [textValue]);

    React.useEffect(() => {
        if (fetching) {
            getValues("fetch");
        }
    }, [fetching]);

    if (!isMenuFilterOpen) return null;

    if (paginateFilter) {
        return (
            // <Dialog
            //     open={isMenuFilterOpen}
            //     onClose={onCloseMenuFilter}
            // >
            <div
                className={`dialog-menu-filter  ${isFirst ? "dialog-menu-filter-first" : ""}`}
                onClick={event => {
                    event.stopPropagation();
                }}
            >
                {isWithSearch &&
                    <div className="dialog-menu-filter-input">
                        <TextField
                            value={textValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                setTextValue(value);
                            }}
                            outlined
                            type="text"
                            style={{ width: "100%" }}
                            icon="search"
                            maxLength={columnType === "countTime" || columnType === "countShifts" ? 5 : 160}
                        />
                    </div>
                }
                <div className="dialog-menu-filter-list">
                    <div className="items" ref={scroller} onScroll={scrollHandler}>
                        <div className="item">
                            <Checkbox
                                className={isSelectAllFilterValues ? "horosiy-checkbox" : "off-checkbox"}
                                checked={isSelectAllFilterValues}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    selectAllFilterValues(!isSelectAllFilterValues);
                                }}
                            />
                            <div className="item-text">Выбрать все</div>
                        </div>
                        <ProgressPage
                            state={arrayOfValues}
                            render={() => (
                                <>
                                    {arrayOfValues.mutable &&
                                        arrayOfValues.mutable.map(item => {
                                            return (
                                                <div className="item" key={item}>
                                                    <Checkbox
                                                        key={item}
                                                        className={filterValues.includes(item) ? "horosiy-checkbox" : "off-checkbox"}
                                                        checked={filterValues ? filterValues.includes(item) : false}
                                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                                            setFilterValues((prevState: string[]) => {
                                                                let alreadyHas = !!prevState && prevState.find(value => value === item);
                                                                let newState: string[] = [];
                                                                if (alreadyHas) {
                                                                    newState = [...prevState.filter(value => value !== item)];
                                                                    setIsSelectAllFilterValues(false);
                                                                } else {
                                                                    newState = [...prevState, item];
                                                                }
                                                                return newState;
                                                            });
                                                        }}
                                                    />
                                                    <div className="item-text">
                                                        {renderFilterValue(item)}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </>
                            )}
                        />
                    </div>
                    <div className="dialog-buttons">
                        <div
                            className="dialog-btn"
                            onClick={() => {
                                onChangeFilterValues(columnType, columnTitle, filterValues.length > 0 ? filterValues : null);
                                onCloseMenuFilter();
                            }}
                        >
                            <Icon className="done" icon={"done"} />
                            <div>ПРИМЕНИТЬ</div>
                        </div>
                        <div
                            className="dialog-btn"
                            onClick={() => {
                                setFilterValues([]);
                                onChangeFilterValues(columnType, columnTitle, null);
                            }}
                        >
                            <Icon className="close" icon={"close"} />
                            <div>ОТМЕНИТЬ</div>
                        </div>
                    </div>
                </div>
            </div>
            // </Dialog>
        );
    } else {
        return (
            // <Dialog
            //     open={isMenuFilterOpen}
            //     onClose={onCloseMenuFilter}
            // >
            <div
                className={`dialog-menu-filter`}
                onClick={event => {
                    event.stopPropagation();
                }}
            >
                {isWithSearch &&
                    <div className="dialog-menu-filter-input">
                        <TextField
                            value={textValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                setTextValue(value);
                            }}
                            outlined
                            type="text"
                            style={{ width: "100%" }}
                            icon="search"
                            maxLength={columnType === "countTime" || columnType === "countShifts" ? 5 : 160}
                        />
                    </div>
                }
                <div className="dialog-menu-filter-list">
                    <div className="items">
                        <div className="item">
                            <Checkbox
                                className={isSelectAllFilterValues ? "horosiy-checkbox" : "off-checkbox"}
                                checked={isSelectAllFilterValues}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    selectAllFilterValues(!isSelectAllFilterValues);
                                }}
                            />
                            <div className="item-text">Выбрать все</div>
                        </div>
                        <ProgressPage
                            state={arrayOfValues}
                            render={() => (
                                <>
                                    {arrayOfValues.mutable &&
                                        arrayOfValues.mutable.map(item => {
                                            return (
                                                <div className="item" key={item}>
                                                    <Checkbox
                                                        key={item}
                                                        className={filterValues.includes(item) ? "horosiy-checkbox" : "off-checkbox"}
                                                        checked={filterValues ? filterValues.includes(item) : false}
                                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                                            setFilterValues((prevState: string[]) => {
                                                                let alreadyHas = !!prevState && prevState.find(value => value === item);
                                                                let newState: string[] = [];
                                                                if (alreadyHas) {
                                                                    newState = [...prevState.filter(value => value !== item)];
                                                                    setIsSelectAllFilterValues(false);
                                                                } else {
                                                                    newState = [...prevState, item];
                                                                }
                                                                return newState;
                                                            });
                                                        }}
                                                    />
                                                    <div className="item-text">
                                                        {renderFilterValue(item)}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </>
                            )}
                        />
                    </div>
                    <div className="dialog-buttons">
                        <div
                            className="dialog-btn"
                            onClick={() => {
                                onChangeFilterValues(columnType, columnTitle, filterValues.length > 0 ? filterValues : null);
                                onCloseMenuFilter();
                            }}
                        >
                            <Icon className="done" icon={"done"} />
                            <div>ПРИМЕНИТЬ</div>
                        </div>
                        <div
                            className="dialog-btn"
                            onClick={() => {
                                setFilterValues([]);
                                onChangeFilterValues(columnType, columnTitle, null);
                            }}
                        >
                            <Icon className="close" icon={"close"} />
                            <div>ОТМЕНИТЬ</div>
                        </div>
                    </div>
                </div>
            </div>
            // </Dialog>
        );
    }
};
