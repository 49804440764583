import { Role } from "../../../../global/definitions/definitions"

export const availableRoles = ['admin', 'hr', 'organizer', 'foreman', "recruiter"]

let roles : any = {}

export class editUser {
    id: number
    lastName: string
    firstName: string
    middleName: string
    birthDate: string
    contactEmail: string
    // role: string
    roles: {
        [key: string]: boolean
    }
    currentRole: Role
    contactPhone: string
    getDefaultRoles: () => {[key: string]: boolean}

    constructor(data: any) {
        this.getDefaultRoles = () => {
            availableRoles.map(role => roles[role] = data.roles.includes(role))
            return roles
        }
        this.id = data.id
        this.lastName = data.lastName
        this.firstName = data.firstName
        this.middleName = data.middleName
        this.birthDate = data.birthDate
        this.contactEmail = data.contactEmail
        this.roles = this.getDefaultRoles()
        this.currentRole = data.currentRole
        this.contactPhone = data.contactPhone

        
    }
}