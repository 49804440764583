import { Checkbox } from '@rmwc/checkbox'
import { Accordion, Divider } from 'components'
import { format } from 'date-fns'
import { getShortInitials } from 'global/utils'
import { RequestField } from 'pages/auth-as-worker'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ShiftInfoProps } from './shift-info-def'
import './shift-info.sass'

export const ShiftInfo: FC<ShiftInfoProps> = ({ name, foreman, tracking, shiftType, object }): JSX.Element => {
	const { t } = useTranslation('common')

	return (
		<Accordion
			headerTitle={
				<div>
					<span className='shift-info-brigade-name'>{name}</span>
					<Link className='shift-info-foreman' to={`/users/${foreman.id}`}>
						{getShortInitials(foreman.lastName, foreman.firstName, foreman.middleName)}
					</Link>
				</div>
			}
		>
			<div className='shift-info-body'>
				<Divider type='horizontally' addOffsets={false} />
				<div className='shift-info-body-item shift-info-body-worker'>
					<RequestField
						className='shift-info-body-field'
						titleClassName='shift-info-body-field-label shift-info-body-field-label-extended'
						valueClassName='shift-info-body-field-value'
						title='Работник'
						value={getShortInitials(tracking.profile.lastName, tracking.profile.firstName, tracking.profile.middleName)}
					/>
					<div className='shift-info-body-time-wrapper'>
						<div className='shift-info-body-time-body'>
							<div className='shift-info-body-field-label'>Начал</div>
							{tracking.startTime && (
								<div className='shift-info-body-time-wrapper'>
									<Checkbox className='shift-info-body-checkbox' checked disabled />
									<span className='shift-info-body-time'>{format(new Date(tracking.startTime), 'HH:mm')}</span>
								</div>
							)}
						</div>
						<div className='shift-info-body-time-body'>
							<div className='shift-info-body-field-label'>Завершил</div>
							{tracking.endTime && (
								<div className='shift-info-body-time-wrapper'>
									<Checkbox className='shift-info-body-checkbox' checked disabled />
									<span className='shift-info-body-time'>{format(new Date(tracking.endTime), 'HH:mm')}</span>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className='shift-info-body-item'>
					<RequestField
						className='shift-info-body-field'
						titleClassName='shift-info-body-field-label'
						valueClassName='shift-info-body-field-value'
						title='Смена'
						value={t('shiftType.' + shiftType)}
					/>
				</div>
				<div className='shift-info-body-item'>
					<RequestField
						className='shift-info-body-field'
						titleClassName='shift-info-body-field-label'
						valueClassName='shift-info-body-field-value'
						title='Объект'
						value={object?.name}
					/>
				</div>
				<div className='shift-info-body-item'>
					<RequestField
						className='shift-info-body-field'
						titleClassName='shift-info-body-field-label'
						valueClassName='shift-info-body-field-value'
						title='Адрес'
						value={object?.address}
					/>
				</div>
			</div>
		</Accordion>
	)
}
