import { Checkbox } from '@rmwc/checkbox'
import clsx from 'clsx'
import { components, GroupBase, OptionProps } from 'react-select'
import './CheckboxOption.style.sass'

export const CheckboxOption = <
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>
>({
	label,
	isSelected,
	isDisabled,
	...rest
}: OptionProps<Option, IsMulti, Group>) => {
	return (
		<components.Option label={label} isSelected={isSelected} isDisabled={isDisabled} {...rest}>
			<div className='checkbox-option'>
				<Checkbox
					className={clsx('checkboxOption-checkbox', {
						'checkbox-option-checkbox--disabled': isDisabled
					})}
					checked={isSelected}
					disabled={isDisabled}
				/>
				<label className='checkbox-option-label'>{label}</label>
			</div>
		</components.Option>
	)
}
