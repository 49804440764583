import { Icon } from "@rmwc/icon";
import { Tooltip } from "@rmwc/tooltip";
import { profile } from "console";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { INameAndStatusProps } from "./NameAndStatus.types";
import './NameAndStatus.style.sass'

function getColor(state: string) {
    switch (state) {
        default: return 'orange'
        case 'documents_undelivered': return 'red'
        case 'accepted': return 'green'
        case 'rejected': return 'red'
        case 'cancelled': return 'red'
        case 'documents_awaiting': return 'orange'
        case 'documents_confirmed': return 'orange'
    }
}

function getStatusDescription(status: string) {
    switch (status) {
        default: return 'Работника заинтересовала Ваша потребность. Ознакомьтесь с профилем, и если он Вам подходит - нажмите "Принять"'
        case 'documents_preparation': return `Работник ожидает пока ему будут направлены документы. Нажатием на иконку "Документы" открывается почтовый клиент по умолчанию. Если этого не произошло вы можете использовать почту из профиля Работника.`
        case 'signature_awaiting': return `Работнику отправлены документы, ожидайте их подписания.`
        case 'documents_undelivered': return 'Работник запросил повторную отправку документов.'
        case 'signed': return 'Работник подтвердил подписание и ознакомление с направленными документами.'
        case 'accepted': return 'Работник был принят на работы и ожидает мобилизации.'
        case 'rejected': return 'Кандидатура Работника была отклонена.'
        case 'cancelled': return 'Работник отозвал отклик.'
        case 'documents_awaiting': return 'Ожидает заполнения профиля.'
        case "documents_confirmed": return "Документы подтверждены."
    }
}

export function NameAndStatus(props: INameAndStatusProps) {

    const history = useHistory()
    const { t } = useTranslation('common')

    return (
        <div className={`name-and-status `}>

            <div className={`wrapper ${props.status === undefined && 'just-name'}`}>

                <div className={'name'}>
                    {props.isProfileDeleted ? props.authorName.includes('null') ? "Профиль удален" : props.authorName : props.authorName}
                </div>
                {props.isProfileDeleted &&
                    <div className="status">
                        <div className="status-circle" style={{ backgroundColor: "red" }}>
                        </div>
                        {"Профиль удален"}
                        {/* {props.tooltip && */}
                            <Tooltip
                                content={
                                    <div className="tooltip">
                                        {"Данный Работник удалил свой профиль из приложения"}
                                    </div>
                                }
                            >
                                <div className="question">
                                    {/* <Icon icon="info" /> */}
                                </div>
                            </Tooltip>
                        {/* } */}
                    </div>
                }
                {props.status &&
                    <div className="status">

                        <div className="status-circle" style={{ backgroundColor: getColor(props.status) }}>
                        </div>
                        {t('response.' + props.status)}
                        {props.tooltip &&
                            <Tooltip
                                content={
                                    <div className="tooltip">
                                        {getStatusDescription(props.status as string)}
                                    </div>
                                }
                            >
                                <div className="question">
                                    {/* <Icon icon="info" /> */}
                                </div>
                            </Tooltip>
                        }
                    </div>
                }

            </div>
        </div>
    )
}