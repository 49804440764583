import { ChangeEvent, FC } from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { ErrorWrapper } from '../../../error-wrapper/error-wrapper'
import { NumberFieldProps } from './number-field-def'
import './number-field.sass'

export const NumberField: FC<NumberFieldProps> = ({
	title,
	name,
	defaultValue,
	control,
	rules,
	format,
	placeholder,
	isRequired = true,
	...rest
}): JSX.Element => {
	return (
		<Controller
			name={name}
			defaultValue={defaultValue}
			control={control}
			rules={rules}
			render={({ field, fieldState }) => {
				return (
					<ErrorWrapper error={fieldState.error}>
						<div style={{ marginBottom: '16px', fontWeight: '600' }}>
							{title}
							{isRequired && <span className='required-field'>*</span>}
						</div>
						{/*//@ts-ignore*/}
						<NumberFormat
							{...field}
							className='number-field'
							format={format}
							placeholder={placeholder}
							{...rest}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								rest.onChange?.(e)
								field.onChange(e)
							}}
						/>
					</ErrorWrapper>
				)
			}}
		/>
	)
}
