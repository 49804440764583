import { ChatWrapper } from 'pages/messages-page/chat-wrapper'
import { MessagesPage } from 'pages/messages-page/messages-page'
import { Route } from './routes-def'
import { initialWebRoles } from './routes-utils'

export const chatRoutes: Route[] = [
	//Чат
	{
		title: 'Чат',
		path: '/messages',
		component: <MessagesPage />,
		navBar: true,
		icon: 'chat',
		availableRoles: [...initialWebRoles, 'worker']
	},
	{
		title: 'Чат',
		path: '/messages/:id',
		component: <ChatWrapper />,
		navBar: false,
		availableRoles: [...initialWebRoles, 'worker']
	},
	{
		title: 'Чат',
		path: '/messages/archive',
		component: <MessagesPage />,
		navBar: false,
		icon: 'mail',
		availableRoles: [...initialWebRoles, 'worker']
	},
	{
		title: 'Чат',
		path: '/messages/ignored',
		component: <MessagesPage />,
		navBar: false,
		icon: 'mail',
		availableRoles: [...initialWebRoles, 'worker']
	}
]
