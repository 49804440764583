import {Chat} from "./chat/chat";
import './chat/chat.sass'
import {useContext, useEffect, useRef, useState} from "react";
import {ChatContext} from "../../global/context.chat";
import {emitCustomEvent} from "react-custom-events";

export const ChatWrapper = () => {
    const {client} = useContext(ChatContext)
    const roomTimelineUpdate = (event: any, room: any, toStartOfTimeline: any) => {
        if (toStartOfTimeline) return;
        if (event.event.type === 'm.room.message') {
            emitCustomEvent("message", {event, room});
        }
    }
    const ref = useRef(roomTimelineUpdate)
    useEffect(() => {

        // @ts-ignore
        client?.on("Room.timeline", ref.current)

        return () => {
            // @ts-ignore
            client?.off("Room.timeline", ref.current)

        }
    }, [client])

    return (
        <div className='chat'>
            <Chat/>
        </div>
    )
}