import { InfoBlock, SpanButtons } from 'components'
import { WorkerProfile } from 'global/definitions'
import { FC, useCallback } from 'react'
import { Document } from './components/document/document'
import { DocumentsSubPageProps } from './documents-def'
import './documents.sass'

export const DocumentsSubPage: FC<DocumentsSubPageProps> = ({
	documents,
	citizenship,
	birthDate,
	registrationAddress,
	actualAddress,
	setIsOpenEditWorkerProfileForm,
	isDisabledBlock
}) => {
	const { inn, passport, snils, media } = documents || ({} as WorkerProfile['documents'])

	const handleClick = useCallback(() => {
		setIsOpenEditWorkerProfileForm('documents')
	}, [])

	return (
		<div className='documents-subpage-containers'>
			<div className='buttons-edit'>
				<SpanButtons
					data={[
						{
							label: 'Редактировать',
							onClick: handleClick,
							disabled: isDisabledBlock
						}
					]}
				/>
			</div>
			<div className='section'>
				<div className='citizenship'>
					<InfoBlock title='Гражданство' content='Российская Федерация' />
				</div>
				<div className='documents-list'>
					<Document
						docName='ИНН'
						docRegNum={inn && inn}
						pathPreview={
							media?.filter((el: any) => el.type === 'inn')[0] && media?.filter((el: any) => el.type === 'inn')[0].path
						}
						path={
							media?.filter((el: any) => el.type === 'inn')[0] && media?.filter((el: any) => el.type === 'inn')[0].path
						}
					/>
					<Document
						docName='СНИЛС'
						docRegNum={snils}
						pathPreview={
							media?.filter((el: any) => el.type === 'snils')[0] &&
							media?.filter((el: any) => el.type === 'snils')[0].path
						}
						path={
							media?.filter((el: any) => el.type === 'snils')[0] &&
							media?.filter((el: any) => el.type === 'snils')[0].path
						}
					/>
				</div>
			</div>
			<div className='passport-container'>
				<InfoBlock className='info-wrapper' title='Документ, удостоверяющий личность' content='Паспорт' />
				<div className='passport-serial-number-wrapper'>
					<InfoBlock className='info-wrapper' title='Серия' content={passport?.series ?? 'Не указано'} />
					<InfoBlock className='info-wrapper' title='Номер' content={passport?.number ?? 'Не указано'} />
				</div>
				<InfoBlock className='info-wrapper' title='Дата выдачи' content={passport?.date ?? 'Не указано'} />
				<InfoBlock className='info-wrapper' title='Дата рождения' content={birthDate ?? 'Не указано'} />
				<InfoBlock
					className='info-wrapper'
					title='Код подразделения'
					content={passport?.subdivisionCode ?? 'Не указано'}
				/>
				<InfoBlock className='info-wrapper' title='Кем выдан' content={passport?.issuedBy ?? 'Не указано'} />
				<InfoBlock
					className='info-wrapper'
					title='Адрес регистрации'
					content={registrationAddress?.address ?? 'Не указано'}
				/>
				<InfoBlock
					className='info-wrapper'
					title='Индекс адреса регистрации'
					content={registrationAddress?.index ?? 'Не указано'}
				/>
				<div className='documents-list'>
					{media?.map(el => {
						if (el.type === 'passport') {
							return <Document pathPreview={el.pathPreview} path={el.path} key={el.path} />
						}
					})}
				</div>
				<div className='info-wrapper living-adress'>
					<InfoBlock
						title='Адрес проживания'
						content={actualAddress?.address ? actualAddress?.address : 'Не указано'}
					/>
					<InfoBlock
						title='Индекс адреса проживания'
						content={actualAddress?.index ? actualAddress?.index : 'Не указано'}
					/>
				</div>
			</div>
		</div>
	)
}
