import { useDebounce } from 'custom-hooks'
import { ProfessionListResponse, infoApi } from 'global/api'
import { useEffect, useState } from 'react'

export const useProfessions = (limit: number = 99, offset: number = 0, search: string = '', type: string = '') => {
	const [data, setData] = useState<ProfessionListResponse>({ data: [], total: 0 })
	const [isSuccess, setIsSuccess] = useState(false)
	const [toggleSearch, setToggleSearch] = useState(false)
	const debouncedValue = useDebounce(search)

	useEffect(() => {
		if (debouncedValue === search) {
			infoApi
				.getProfessions(limit, offset, debouncedValue, type)
				.then(response => {
					setData({ data: [...data.data, ...response.data.data], total: response.data.total })
					setIsSuccess(true)
				})
				.catch(e => {
					setIsSuccess(false)
				})
		}
	}, [toggleSearch, offset])

	useEffect(() => {
		if (isSuccess) {
			setData({ total: 0, data: [] })
			setToggleSearch(!toggleSearch)
		}
	}, [debouncedValue])

	return { professions: data.data, total: data.total, isSuccess }
}
