import { useProfessions } from 'pages/auth-as-worker/hooks'
import { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from 'react'
import { CheckboxOptionsDialog } from '../checkbox-options-dialog/checkbox-options-dialog'
import { FormFieldLabel } from '../form-field-label/form-field-label'
import { FormField } from '../form-field/form-field'
import { Option, SubProfessionFieldProps } from './sub-profession-field-def'

export const SubProfessionField: FC<SubProfessionFieldProps> = ({
	value,
	onChange,
	search,
	onChangeSearch,
	options
}): JSX.Element => {
	const [queryParams, setQueryParams] = useState({ limit: 99, offset: 0, search: '' })
	const { professions, total } = useProfessions(queryParams.limit, queryParams.offset, queryParams.search)
	const [isOpenOptionsDialog, setIsOpenOptionsDialog] = useState(false)
	const ref = useRef<HTMLDivElement>(null)

	const transformedProfessions = useMemo(() => professions.map(p => ({ name: p.name, value: p.id })), [professions])

	const optionsByValue = useMemo(
		() =>
			(transformedProfessions || []).reduce<Record<Option['value'], Option>>((result, option) => {
				const { value } = option
				result[value] = option
				return result
			}, {}),
		[transformedProfessions]
	)

	const handleOpenOptionsDialog = useCallback(() => {
		setIsOpenOptionsDialog(true)
	}, [])

	const handleCloseOptionsDialog = useCallback(() => {
		setQueryParams({ ...queryParams, search: '' })
		setIsOpenOptionsDialog(false)
	}, [onChangeSearch])

	const handleDeleteValue = useCallback(
		id => {
			onChange?.((value || []).filter(valueId => valueId !== id))
		},
		[value, onChange]
	)

	const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
		setQueryParams({ ...queryParams, offset: 0, search: e.target.value })
	}

	const scrollHandler = () => {
		if (ref.current && queryParams.offset <= total) {
			if (
				ref.current.scrollTop > 0 &&
				ref.current.scrollTop + ref.current.offsetHeight > ref.current.scrollHeight - 1
			) {
				setQueryParams({ ...queryParams, offset: queryParams.offset + queryParams.limit })
			}
		}
	}

	return (
		<>
			<FormField
				className='experience-form-subProfession'
				value={value}
				label={value?.length ? 'Добавить или сменить дополнительную профессию' : 'Выбрать дополнительную профессию'}
				onClick={handleOpenOptionsDialog}
				renderItem={subProfessionId => {
					const option = subProfessionId && optionsByValue[subProfessionId]

					if (!option) {
						return <></>
					}

					return <FormFieldLabel title={option.name} value={subProfessionId} onDelete={handleDeleteValue} />
				}}
			/>
			<CheckboxOptionsDialog
				ref={ref}
				title='Дополнительная профессия'
				description='Вы можете выбрать до 5 профессий'
				search={queryParams.search}
				defaultValues={value}
				options={transformedProfessions}
				open={isOpenOptionsDialog}
				hasSearch={true}
				onChangeSearch={inputHandler}
				onClose={handleCloseOptionsDialog}
				onSuccess={onChange}
				onListScroll={scrollHandler}
			/>
		</>
	)
}
