import "./VacancyCardPreview.style.sass";
import React from "react";
import { IVacancyCardPreview } from "./VacancyCardPreview.types";
import { Dialog, DialogTitle } from "@rmwc/dialog";
import { Icon } from "@rmwc/icon";
import { IVacancyData } from "../VacancyPage.types";
import { getSchedule, renderScheduleDescription } from "../VacancyPage.service";
import { useTranslation } from "react-i18next";

export const VacancyCardPreview: React.FC<IVacancyCardPreview> = ({ isOpen, onClose, vacancyData, vacancySettings }) => {
    const { t } = useTranslation("common");

    const renderViewField = (fieldName: keyof IVacancyData) => {
        let field: any;
        if (fieldName === "workFrom") {
            field = `${vacancyData.workFrom} ${vacancyData.workTo ? "- " + vacancyData.workTo : "- бессрочный"}`;
        } else if (fieldName === "workSchedule") {
            //@ts-ignore
            field = vacancyData.workSchedule ? getSchedule(vacancyData.workSchedule.toString()).label + " - " + renderScheduleDescription(vacancyData.workSchedule.toString()) : "Информация не указана";
        } else {
            field = vacancyData[fieldName] ? vacancyData[fieldName] : "Информация не указана";
        }
        return (
            <>
                <div className={"vacancy-card-preview-view-item"}>
                    <div className={"vacancy-card-preview-view-title"}>
                        {t("vacancy.fields." + fieldName)}
                    </div>
                    <div className={"vacancy-card-preview-view-value"}>
                        {field}
                    </div>
                </div>
            </>
        );
    };

    return (
        <Dialog
            className="vacancy-card-preview"
            open={isOpen}
            onClose={() => {
                onClose();
            }}
        >
            <DialogTitle className={'vacancy-card-preview-title'}>
                Предпросмотр вакансии
                <Icon
                    icon={'close'}
                    onClick={() => {
                        onClose()
                    }}
                />
            </DialogTitle>
                <div className={"vacancy-card-preview-view"}>
                    <div className={"vacancy-card-preview-view-wrapper"}>
                        <div className={"vacancy-card-preview-view-headline"}>
                            Основная информация
                        </div>
                        {!vacancySettings.hiddenResponse.position &&
                            vacancyData.position &&
                            renderViewField("position")}
                        {!vacancySettings.hiddenResponse.grade &&
                            vacancyData.grade &&
                            renderViewField("grade")}
                        {!vacancySettings.hiddenResponse.category &&
                            vacancyData.category &&
                            renderViewField("category")}
                        {!vacancySettings.hiddenResponse.workSchedule &&
                            vacancyData.workSchedule &&
                            renderViewField("workSchedule")}
                        {!vacancySettings.hiddenResponse.city &&
                            vacancyData.city &&
                            renderViewField("city")}
                        {!vacancySettings.hiddenResponse.address &&
                            vacancyData.address &&
                            renderViewField("address")}
                    </div>
                    <div className={"vacancy-card-preview-view-wrapper"}>
                        <div className={"vacancy-card-preview-view-headline"}>
                            Договор
                        </div>
                        {!vacancySettings.hiddenResponse.salaryFullNet &&
                            vacancyData.salaryFullNet &&
                            renderViewField("salaryFullNet")}
                        {!vacancySettings.hiddenResponse.period &&
                            vacancyData.workFrom &&
                            renderViewField("workFrom")}
                        {!vacancySettings.hiddenResponse.probationDurationMonths &&
                            vacancyData.probationDurationMonths &&
                            renderViewField("probationDurationMonths")}
                        {!vacancySettings.hiddenResponse.salaryProbationNet &&
                            vacancyData.salaryProbationNet &&
                            renderViewField("salaryProbationNet")}
                    </div>
                    <div className={"vacancy-card-preview-view-wrapper"}>
                        <div className={"vacancy-card-preview-view-headline"}>
                            Дополнительно
                        </div>
                        {!vacancySettings.hiddenResponse.responsibilities &&
                            vacancyData.responsibilities &&
                            renderViewField("responsibilities")}
                        {!vacancySettings.hiddenResponse.additionalInfo &&
                            vacancyData.additionalInfo &&
                            renderViewField("additionalInfo")}
                    </div>
                </div>
        </Dialog>
    );
};