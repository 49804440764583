export const getShortInitials = (
	lastName: string | null | undefined,
	firstName: string | null | undefined,
	middleName?: string | null | undefined
) => {
	if (middleName && firstName) {
		return `${lastName} ${firstName[0]}. ${middleName ? middleName[0] + '.' : ''}`
	}

	return ''
}
