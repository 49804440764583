import React, { FC } from "react";
import { PageDefaultProps } from "./page-default-def";
import "./page-default.sass";
import {SpanButtons} from "components";

export const PageDefault: FC<PageDefaultProps> = ({
  handleClick,
  disabled,
}) => {
  return (
    <div className="page-default">
      <div>
        <SpanButtons
          data={[
            {
              label: "Заполнить",
              onClick: handleClick,
              disabled: disabled,
            },
          ]}
        />
      </div>
      <div className="page-default-text">
        <h3>Этот раздел пуст. Заполните его, добавив информацию о себе.</h3>
      </div>
    </div>
  );
};
