import {IStar} from "./Star.types";

export const Star = ({fill}: IStar) => {
    switch (fill) {
        case 'full':
            return (<svg
                xmlns="http://www.w3.org/2000/svg"
                height="48px"
                viewBox="0 0 24 24"
                width="48px"
                fill="#12324f"
            >
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
            </svg>)
        case 'half':
            return (<svg
                xmlns="http://www.w3.org/2000/svg"
                height="48px"
                viewBox="0 0 24 24"
                width="48px"
                fill="url(#half)"
            >
                <defs>
                    <linearGradient id="half">
                        <stop offset="50%" stopColor="#12324f"/>
                        <stop offset="50%" stopColor="#e1e1e7"/>
                    </linearGradient>
                </defs>
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
            </svg>)

        case 'none':
            return (<svg
                xmlns="http://www.w3.org/2000/svg"
                height="48px"
                viewBox="0 0 24 24"
                width="48px"
                fill="#e1e1e7"
            >
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
            </svg>)
        default:
            return null;
    }

};

