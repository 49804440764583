import clsx from 'clsx'
import { getConvertedPercent } from 'global/utils'
import { FC, useEffect, useState } from 'react'
import { CircularFillingProgressProps } from './CircularFillingProgress.types'
import './CircularFillingProgress.style.sass'

export const CircularFillingProgress: FC<CircularFillingProgressProps> = ({
	className,
	size,
	strokeWidth = 4,
	progress
}): JSX.Element => {
	const [offset, setOffset] = useState(0)
	const center = size / 2
	const radius = center - strokeWidth / 2
	const circumference = 2 * Math.PI * radius
	const convertedProgress = getConvertedPercent(progress)

	useEffect(() => {
		const progressOffset = ((100 - convertedProgress) / 100) * circumference
		setOffset(progressOffset)
	}, [setOffset, convertedProgress, circumference])

	return (
		<div className={clsx('circular-filling-progress', className)}>
			<svg className='circular-filling-progress-circle-wrapper' width={size} height={size}>
				<circle
					className='circular-filling-progress-circle'
					cx={center}
					cy={center}
					r={radius}
					strokeWidth={strokeWidth}
				/>
				<circle
					className='circular-filling-progress-line'
					cx={center}
					cy={center}
					r={radius}
					strokeDasharray={circumference}
					strokeDashoffset={offset}
					strokeWidth={strokeWidth}
				/>
			</svg>
			<span className='circular-filling-progress-text'>{convertedProgress}%</span>
		</div>
	)
}
