import { Tab, TabBar } from '@rmwc/tabs'
import { FC, useCallback } from 'react'
import { TabsProps } from './tabs-def'
import './tabs.sass'

const LABEL_TABS = ['О себе', 'Опыт работы', 'Личные документы', 'Спец. одежда', 'Отзывы', 'Ожидающие отзыва']

export const Tabs: FC<TabsProps> = ({ activeTab, onActiveTab, isDisabled }) => {
	const handleOnActivate = useCallback(
		e => {
			if (isDisabled) {
				return
			}
			onActiveTab(e.detail.index)
		},
		[isDisabled, onActiveTab]
	)

	return (
		<TabBar
			style={{ background: '#E1E1E7', minHeight: '64px' }}
			activeTabIndex={activeTab}
			onActivate={handleOnActivate}
		>
			{LABEL_TABS.map((name, i) => (
				<Tab
					className='tab'
					key={name}
					style={{
						background: activeTab === i ? '#FFFFFF' : '#E1E1E7'
					}}
					iconIndicator=' '
				>
					<h4>{name}</h4>
				</Tab>
			))}
		</TabBar>
	)
}
