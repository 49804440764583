import { authorName } from '../../global/common';
import { Author, PartialRequest } from '../../global/definitions/definitions';
import { Worker } from './Personnel.types';

export class WorkerObject {
  number: string;
  position: string;
  subdivision: string;
  expectedHiringDate: string;
  name: string;
  lastName: string;
  avatar: {
    id: number;
    path: string;
    type: string;
  } | null;
  id: number;
  recordID: number;
  requestID: number;
  requestNumber: string;
  workFrom: string;
  workTo: string;
  workUnlimited: boolean;
  isDeleted: boolean
  

  constructor(data: Worker) {
    this.name = authorName(data.profile);
    this.position = data.position;
    this.avatar = data.profile.avatar;
    this.number = data.request.number;
    this.subdivision = data.subdivision;
    this.expectedHiringDate = data.request.expectedHiringDate;
    this.id = data.profile.id;
    this.lastName = data.profile.lastName;
    this.recordID = data.recordID;
    this.requestNumber = data.request.number;
    this.requestID = data.request.id;
    this.workFrom = data.workFrom;
    this.workTo = data.workTo;
    this.workUnlimited = data.workUnlimited;
    this.isDeleted = data.isDeleted;
  }
}