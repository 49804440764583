import {Raiting} from "components";
import {ReviewBody} from "../../../worker-profile-def";
import "../reviews.sass";
import {FC} from "react";

type ReviewProps = {
    authorName?: string;
} & Pick<ReviewBody,
    "date" | "score" | "workFrom" | "workTo" | "author" | "comment">;

export const Review: FC<ReviewProps> = ({
                                            authorName,
                                            date,
                                            score,
                                            workFrom,
                                            workTo,
                                            author,
                                            comment,
                                        }) => {
    return (
        <div className="worker-profile-review">
            <div className="worker-profile-block">
                <span>{date}</span>
                <Raiting value={score}/>
                {workFrom && workTo && (
                    <span className="employment-time">
            {`Период работы\n${workFrom}` + (workTo ? ` -\n${workTo}` : "")}
          </span>
                )}
            </div>
            <div className="worker-profile-content">
                <div className="author-header">
                    <span className="name">{authorName}</span>
                    <span className="role">{author}</span>
                </div>
                <div className="comment">{comment}</div>
            </div>
        </div>
    );
};
