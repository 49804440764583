import { CustomDialog, FixedButtons, ProgressPage } from "../../../../../../components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./SurveyItem.style.sass";
import { TextField } from "@rmwc/textfield";
import { useTranslation } from "react-i18next";
import { InterfaceContext } from "../../../../../../global/context.interface";
import { req } from "../../../../../../global/common";
import { ISurveyItem } from "./SurveyItem.types";
import { stringToURL } from "../../../../../../components/Form/Form.service";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { allOptionRoles } from "./SurveyItem.service";
import parse from "date-fns/parse";
import { differenceInCalendarDays } from "date-fns";
import { UserContext } from "global/context.user";


export const SurveyItem: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation("common");
    const interfaceCTX = useContext(InterfaceContext);
    const { user } = useContext(UserContext)
    const [isRedact, setIsRedact] = useState(false);
    const [surveyItem, setSurveyItem] = useState<ISurveyItem>(null as unknown as ISurveyItem);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
    const [isOpenCancelDialog, setIsOpenCancelDialog] = useState(false);

    const isModerator = user?.roles.includes('moderator')

    const isSelectMode = (fn?: any) => {
        if (Array.isArray(history.location.state)) {
            const currentState = history.location.state.find((item: any) => item.id);
            if (currentState && currentState.id) {
                setIsLoading(true)
                req.get(`/moderator/survey/get?surveyId=${currentState.id}`)
                    .then(({ data }) => {
                        setIsRedact(false);
                        setSurveyItem({
                            ...data,
                            roles: data.roles.map((item: string) => ({
                                value: item,
                                label: t("role." + item)
                            }))
                        });
                        fn && fn();
                    })
                    .catch(e => {
                        interfaceCTX.showMessage({
                            title: "Ошибка",
                            body: t(e.response.data)
                        });
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            } else {
                setIsRedact(true);
                setSurveyItem({
                    topic: "",
                    dateFrom: "",
                    dateTo: "",
                    roles: [],
                    link: "",
                    description: "",
                    text: ""
                });
                fn && fn();
            }
        }
    };

    const renderFields = (fieldName: keyof ISurveyItem, placeholder?: string, maxLength?: number) => {
        let component;
        if (fieldName === "dateFrom") {
            component = (
                <div className={"survey-item-body-redact-item-period"}>
                    <div className={"survey-item-body-redact-item-period-date"}>
                        с
                        <NumberFormat
                            className={"survey-item-body-redact-item-period-date-field"}
                            value={surveyItem.dateFrom as string}
                            format={"##.##.####"}
                            type={"text"}
                            placeholder="Дата начала дд.мм.гггг"
                            mask={["Д", "Д", "М", "М", "Г", "Г", "Г", "Г"]}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                if (value === " " || value.length > 10) return;
                                setSurveyItem(prevState => ({
                                    ...prevState,
                                    dateFrom: value
                                }));
                            }}
                        />
                    </div>
                    <div className={"survey-item-body-redact-item-period-date"}>
                        до
                        <NumberFormat
                            className={"survey-item-body-redact-item-period-date-field"}
                            value={surveyItem.dateTo as string}
                            format={"##.##.####"}
                            type={"text"}
                            placeholder="Дата окончания дд.мм.гггг"
                            mask={["Д", "Д", "М", "М", "Г", "Г", "Г", "Г"]}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                if (value === " " || value.length > 10) return;
                                setSurveyItem(prevState => ({
                                    ...prevState,
                                    dateTo: value
                                }));
                            }}
                        />
                    </div>
                </div>
            );
        } else if (fieldName === "roles") {
            component = (
                <Select
                    className={"survey-item-body-redact-item-select"}
                    classNamePrefix={"select"}
                    placeholder={placeholder}
                    isClearable={false}
                    menuPosition={"fixed"}
                    menuShouldScrollIntoView={false}
                    options={allOptionRoles(t)}
                    noOptionsMessage={() => "Нет опций"}
                    defaultValue={surveyItem.roles.map(item => item)}
                    isMulti={true}
                    onChange={evt => {
                        if (!evt) return;
                        setSurveyItem(prevState => ({
                            ...prevState,
                            roles: evt as { value: string, label: string }[]
                        }));
                    }}
                />
            );
        } else {
            component = (
                <TextField
                    maxLength={maxLength ? maxLength : 1000}
                    outlined
                    style={{ width: "100%" }}
                    placeholder={placeholder}
                    className={"survey-item-body-redact-item-textarea"}
                    value={surveyItem[fieldName] as string}
                    onChange={(e) => {
                        const value = e.currentTarget.value;
                        interfaceCTX.checkIsFormChanged(true, "settings");
                        if (value === " ") return;
                        setSurveyItem((prevState: ISurveyItem) => ({
                            ...prevState,
                            [fieldName]: value
                        }));
                    }}
                />
            );
        }
        return (
            <div className={"survey-item-body-redact-item"}>
                <div className={"survey-item-body-redact-item-title"}>
                        <div className={"survey-item-body-redact-item-title-notification"}>
                            *
                        </div>
                    {t("survey." + fieldName)}
                </div>
                {component}
            </div>
        );
    };

    const checkDates = () => {
        if (differenceInCalendarDays(parse(surveyItem.dateFrom, "dd.MM.yyyy", new Date()).getTime(), parse(surveyItem.dateTo, "dd.MM.yyyy", new Date()).getTime()) > 0) {
            interfaceCTX.showMessage({
                body: `Дата начала не может быть позже даты окончания`,
                icon: "report"
            })
            return true
        } 
        return false
    }

    const saveSurveyItem = () => {
        let requestData = {
            ...surveyItem,
            roles: surveyItem.roles.map(item => item.value)
        };
        if(checkDates()) return
        setIsLoading(true);
        req.post(`/moderator/survey/set${surveyItem.id ? "?surveyId=" + surveyItem.id : ""}`, requestData)
            .then(({ data }) => {
                interfaceCTX.checkIsFormChanged(false, "settings");
                setIsRedact(false);
                setSurveyItem({
                    ...data,
                    roles: data.roles.map((item: string) => ({
                        value: item,
                        label: t("role." + item)
                    }))
                });
                history.push({
                    pathname: "item",
                    state: [{ id: data.id }]
                });
                interfaceCTX.showMessage({
                    body: "Опрос успешно сохранен",
                    icon: "done"
                });
            })
            .catch(e => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t(e.response.data)
                });
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const deleteSurveyItem = () => {
        setIsLoading(true);
        req.delete(`/moderator/survey/delete?surveyId=${surveyItem.id}`)
            .then(({ data }) => {
                interfaceCTX.checkIsFormChanged(false, "settings");
                history.push("list");
            })
            .catch(e => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t(e.response.data)
                });
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const cancelSurveyItem = () => {
        isSelectMode(() => {
            setIsOpenCancelDialog(false);
            interfaceCTX.checkIsFormChanged(false, "settings");
        });
    };

    const showAlertOnLeave = useCallback(e => {
        e.preventDefault();
        e.returnValue = `Are you sure you want to leave?`;
    }, []);

    React.useEffect(() => {
        Object.values(interfaceCTX.isFormChanged).filter(v => v == true).length != 0
            ? window.addEventListener("beforeunload", showAlertOnLeave)
            : window.removeEventListener("beforeunload", showAlertOnLeave);
    }, [Object.values(interfaceCTX.isFormChanged).filter(v => v == true).length != 0]);

    useEffect(() => {
        isSelectMode();
        return () => {
            interfaceCTX.checkIsFormChanged(false, "settings");
        };
    }, []);

    return (
        <div className={"survey-item"}>
            <ProgressPage
                state={surveyItem}
                render={() => (
                    <>
                        <div className={"survey-item-top"}>
                            <CustomDialog
                                isOpen={isOpenDeleteDialog}
                                onClose={() => setIsOpenDeleteDialog(false)}
                                dialogTitle={"Удалить этот опрос?"}
                                buttons={[
                                    {
                                        callback: () => {
                                            deleteSurveyItem();
                                        },
                                        outlined: true,
                                        raised: false,
                                        disabled: isLoading,
                                        text: "Удалить"
                                    },
                                    {
                                        callback: () => {
                                            setIsOpenDeleteDialog(false);
                                        },
                                        outlined: true,
                                        raised: true,
                                        disabled: isLoading,
                                        text: "Отмена"
                                    }
                                ]}
                            >
                                <div className={"dialog-delete-body"}>
                                    Вы не сможете восстановить этот контент
                                </div>
                            </CustomDialog>
                            <CustomDialog
                                isOpen={isOpenCancelDialog}
                                onClose={() => setIsOpenCancelDialog(false)}
                                dialogTitle={"Удалить изменения?"}
                                buttons={[
                                    {
                                        callback: () => {
                                            cancelSurveyItem();
                                        },
                                        outlined: true,
                                        raised: false,
                                        disabled: isLoading,
                                        text: "Удалить"
                                    },
                                    {
                                        callback: () => {
                                            setIsOpenCancelDialog(false);
                                        },
                                        outlined: true,
                                        raised: true,
                                        disabled: isLoading,
                                        text: "Отмена"
                                    }
                                ]}
                            >
                                <div className={"dialog-delete-body"}>
                                    Вы не сможете восстановить этот контент
                                </div>
                            </CustomDialog>
                            <FixedButtons
                                className={"buttons"}
                                length={2}
                                buttons={[
                                    {
                                        label: "Отменить",
                                        className: "button",
                                        onClick: () => {
                                            setIsOpenCancelDialog(true);
                                        },
                                        primary: false,
                                        hide: !isRedact
                                    },
                                    {
                                        label: "Удалить",
                                        className: "button",
                                        onClick: () => {
                                            setIsOpenDeleteDialog(true);
                                        },
                                        primary: false,
                                        hide: isRedact
                                    },
                                    {
                                        label: "Редактировать",
                                        className: "button",
                                        primary: true,
                                        disabled: false,
                                        hide: isRedact,
                                        onClick: () => {
                                            setIsRedact(true);
                                        }
                                    },
                                    {
                                        label: "Сохранить",
                                        className: "button",
                                        onClick: () => {
                                            saveSurveyItem();
                                        },
                                        primary: true,
                                        disabled: (surveyItem.topic === "" ||
                                            (parse(surveyItem.dateFrom, "dd.MM.yyyy", new Date()) as unknown as string) == "Invalid Date" ||
                                            (parse(surveyItem.dateTo, "dd.MM.yyyy", new Date()) as unknown as string) == "Invalid Date" ||
                                            surveyItem.link === "" ||
                                            surveyItem.text === "" ||
                                            surveyItem.roles.length === 0 || isLoading),
                                        hide: !isRedact
                                    }
                                ]}
                            />
                        </div>
                        <div className={"survey-item-body"}>
                            {isRedact
                                ? <div className={"survey-item-body-redact"}>
                                    {renderFields("topic", "Введите название", 25)}
                                    {renderFields("dateFrom")}
                                    {isModerator && renderFields("description", "Введите текст", 120)}
                                    {renderFields("link", "www.example.com")}
                                    {renderFields("roles", "Выберите из списка роли, которым будет доступен опрос")}
                                    {renderFields("text", "Введите текст до 120 символов", 120)}
                                </div>
                                : <div className={"survey-item-body-view"}>
                                    <div className={"survey-item-body-view-item"}>
                                        <div className={"survey-item-body-view-item-title"}>
                                            Название опроса
                                        </div>
                                        <div className={"survey-item-body-view-item-text"}>
                                            {surveyItem.topic}
                                        </div>
                                    </div>
                                    <div className={"survey-item-body-view-item"}>
                                        <div className={"survey-item-body-view-item-title"}>
                                            Период публикации
                                        </div>
                                        <div className={"survey-item-body-view-item-text"}>
                                            {`${surveyItem.dateFrom} - ${surveyItem.dateTo}`}
                                        </div>
                                    </div>
                                    {isModerator &&
                                        <div className={"survey-item-body-view-item"}>
                                            <div className={"survey-item-body-view-item-title"}>
                                                Описание
                                            </div>
                                            <div className={"survey-item-body-view-item-text"}>
                                                {surveyItem.description}
                                            </div>
                                        </div>
                                    }
                                    <div className={"survey-item-body-view-item"}>
                                        <div className={"survey-item-body-view-item-title"}>
                                            Ссылка на опрос
                                        </div>
                                        <div className={"survey-item-body-view-item-link"}>
                                            <a target={"_blank"} href={stringToURL(surveyItem.link)}>
                                                {surveyItem.link}
                                            </a>
                                        </div>
                                    </div>
                                    <div className={"survey-item-body-view-item"}>
                                        <div className={"survey-item-body-view-item-title"}>
                                            Роли
                                        </div>
                                        <div className={"survey-item-body-view-item-text"}>
                                            {surveyItem.roles.map((role: { label: string, value: string }, index) => {
                                                if (index === surveyItem.roles.length - 1) {
                                                    return role.label;
                                                } else {
                                                    return role.label + ", ";
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className={"survey-item-body-view-item"}>
                                        <div className={"survey-item-body-view-item-title"}>
                                            Текст сообщения пользователю
                                        </div>
                                        <div className={"survey-item-body-view-item-text"}>
                                            {surveyItem.text}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                )}
            />
        </div>
    );
};