export class SuperadminUser {
    id: number | string
    firstName: string
    lastName: string
    middleName: string
    roles: string[]
    contactEmail: string
    contactPhone: string
    birthDate: string
    canInvite: boolean
    activeStatus: boolean

    constructor(data: any) {
        this.id = data.id
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.middleName = data.middleName
        this.roles = data.roles
        this.contactEmail = data.contactEmail
        this.contactPhone = data.contactPhone
        this.birthDate = data.birthDate
        this.activeStatus = data.activeStatus
        this.canInvite = data.canInvite
    }
}