import * as XLSX from "sheetjs-style";
import {TCurrentFilterValues} from "../../../professions-groups/components/groups-list/groups-list-def";
import {
    bodyTableStyle,
    headerTableStyle,
    headerTableTitleStyle,
    settingsStyle
} from "../../../../../../components/ExtendedCustomTable/ExtendedCustomTable.service";
import {declOfNum} from "../../../professions-groups/components/groups-list/groups-list-utils";


export const columns = [
    {
        id: 0,
        title: "Выбрать",
        minWidth: '110px',
        sortType: 'NONE',
        fieldName: 'select'
    },
    {
        id: 1,
        title: "Профессия",
        minWidth: '350px',
        sortType: 'NONE',
        fieldName: 'professionName'
    },
    {
        id: 2,
        title: "Доступность профессии",
        minWidth: '160px',
        sortType: 'NONE',
        fieldName: 'isActive'
    },
    {
        id: 3,
        title: "Категория",
        minWidth: '200px',
        sortType: 'NONE',
        fieldName: 'category'
    },
    {
        id: 4,
        title: "Код профессии",
        minWidth: '165px',
        sortType: 'NONE',
        fieldName: 'professionCode'
    },
    {
        id: 5,
        title: "Группы",
        minWidth: '170px',
        sortType: 'NONE',
        fieldName: 'groups'
    },
]

export const defaultProfessionValues = {
    category: 'worker',
    isActive: true,
    professionCode: '',
    professionName: '',
    groupsIds: [],
    isCreate: true
}

export const renderGroups = (groups: {
                                 category: string
                                 id: number
                                 professionGroupName: string
                                 professionsCount: number
                             }[]
) => {
    if (groups.length === 1) {
        return groups[0].professionGroupName
    } else if (groups.length > 1) {
        return `${groups[0].professionGroupName}, и еще ${groups.length - 1 + declOfNum(groups.length - 1, [' группа', ' группы', ' групп'])}`
    }
}

export const downloadEXCELTable = (tableValues: any[], currentFilter: TCurrentFilterValues, t: any) => {
    const columnSettings = columns.filter(item => item.id !== 0)
    const tableSettings = () => {
        let resultFilter = ['Отфильтровано по: ']
        let resultSort = ['Сортировано по: ']
        currentFilter.forEach((setting, index) => {
            if (setting.filterValue) {
                resultFilter.push(setting.title + ', ')
            }
            if (setting.sortType !== 'NONE') {
                resultSort.push(setting.title)
            }
        })

        return [resultFilter.join(''), '', '', resultSort.join('')]
    }

    const orderedTableValues = tableValues.map((value, index) => {
        const newOrdered: any = {}
        columnSettings.forEach((column) => {
            if (column.fieldName === 'groups') {
                return newOrdered[column.fieldName] = value.group.length > 0 ? value.group.map((item: any) => item.professionGroupName).join(', ') : ""
            } else if (column.fieldName === 'isActive') {
                // @ts-ignore
                return newOrdered[column.fieldName] = t(`menu_filter.${value.isActive === true ? 'available' : 'not_available'}`)
            } else if (column.fieldName === 'category') {
                // @ts-ignore
                return newOrdered[column.fieldName] = t(`menu_filter.` + value.category)
            } else {
                return newOrdered[column.fieldName] = value[column.fieldName]
            }
        })
        return newOrdered
    })

    var workbook = XLSX.utils.book_new()
    var worksheet = XLSX.utils.aoa_to_sheet(
        [
            ['Список профессий'],
            tableSettings(),
            columnSettings.map((c: any, i) => c.title),
            ...orderedTableValues.map((c: any) => Object.values(c))
        ]
    )

    for (let i in worksheet) {
        if (typeof (worksheet[i]) != "object") continue;
        if ((/^[A-Z]1+$\b/).test(i) && i.length === 2) {
            worksheet[i].s = headerTableTitleStyle
        } else if ((/^[A-Z]2+$\b/).test(i) && i.length === 2) {
            //Стили заголовков
            worksheet[i].s = settingsStyle
        } else if ((/^[A-Z]3+$\b/).test(i) && i.length === 2) {
            //Стили заголовков
            worksheet[i].s = headerTableStyle
        } else {
            //Стили остальной части
            worksheet[i].s = bodyTableStyle
        }

    }

    worksheet["!cols"] = columnSettings.map((c: any, i) => {
        return {wch: 30}
    })

    worksheet["!rows"] = columnSettings.map((c: any, i) => {
        if (i === 1) {
            return {hpt: 60}
        } else {
            return {hpt: 30}
        }
    })

    worksheet['!merges'] = [{s: {c: 0, r: 0}, e: {c: 1, r: 0}}, {s: {c: 0, r: 1}, e: {c: 2, r: 1}}]
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Список профессий')
    XLSX.writeFile(workbook, `Список профессий.xlsx`, {bookType: 'xlsx', cellStyles: true})
}