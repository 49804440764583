import { TextField } from '@rmwc/textfield'
import clsx from 'clsx'
import { forwardRef } from 'react'
import { FormFieldProps } from './FormField.types'
import './FormField.style.sass'

export const FormField = forwardRef<HTMLInputElement, FormFieldProps>(
	({ className, title, defaultValue, error, required = false, fullWidth = false, ...rest }, ref) => {
		return (
			<div
				className={clsx('form-field-wrapper', className, {
					'form-field-wrapper-full-width': fullWidth
				})}
			>
				{title && (
					<div className='form-field-title'>
						{title} {required && <span className='form-field-required'>*</span>}
					</div>
				)}
				<TextField defaultValue={defaultValue} outlined invalid={!!error ?? false} ref={ref} {...rest} />
				{error && <div className='form-field-error'>{error}</div>}
			</div>
		)
	}
)
