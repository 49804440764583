import { PagesRoutes } from "global/routes/routes-utils";

export const stepsData = [
    {
        stepTitle: "В этом разделе Работодатель:",
        stepLIstItems: [
            "формирует потребность в персонале;",
            "получает информацию о полученных откликах на опубликованные потребности в персонале;",
            "осуществляет взаимодействие с откликнувшимися на потребность Работниками и ведет переговоры с ними о возможном трудоустройстве;"
        ],
        path: PagesRoutes.NEEDS_LIST,
        pageTitle: 'Список потребностей',
        accessRoles: ['admin' , 'hr' , 'organizer'],
        stepName: 'needsScreen'
    },
    {
        stepTitle: "В этом разделе отображается:",
        stepLIstItems: [
            "информация о времени начала и окончания рабочих смен;",
            "информация о фактически отработанном времени;",
        ],
        path: PagesRoutes.WORKERS,
        pageTitle: 'Работники',
        accessRoles: ['admin' , 'hr' , 'organizer'],
        stepName: 'shiftsScreen'
    },
    {
        stepTitle: "В этом разделе:",
        stepLIstItems: [
            "отображается информация о всех Работниках, с которыми были заключены трудовые отношения;",
            "возможен просмотр распределения Работников между бригадами;",
            "Организатором ведется учет шагов по мобилизации и демобилизации Работников"
        ],
        path: PagesRoutes.TIME_KEEPING,
        pageTitle: 'Учет рабочего времени',
        accessRoles: ['admin' , 'hr' , 'organizer'],
        stepName: 'timeAttendanceScreen'
    },
    {
        stepTitle: "В этом разделе:",
        stepLIstItems: [
            "на основе данных, заполняемых Прорабом и отображаемых в разделе «Учет рабочего времени», возможно сформировать табель учета отработанного времени;",
        ],
        path: '/timesheet',
        pageTitle: 'Табель',
        accessRoles: ['admin' , 'hr' , 'organizer'],
        stepName: 'timeSheetScreen'
    },
    {
        stepTitle: "В этом разделе осуществляется:",
        stepLIstItems: [
            "взаимодействие с Работниками в режиме реального времени",
        ],
        path: '/messages',
        pageTitle: 'Чат',
        accessRoles: ['admin' , 'hr' , 'organizer'],
        stepName: 'chatScreen'
    },
    {
        stepTitle: "В этом разделе ведется учет:",
        stepLIstItems: [
            "объектов, на которых ведутся работы Работодателем",
        ],
        path: PagesRoutes.OBJECTS,
        pageTitle: 'Объекты',
        accessRoles: ['admin' , 'hr' , 'organizer'],
        stepName: 'objectScreen'
    },
    {
        stepTitle: "В этом разделе возможно размещение Работодателем:",
        stepLIstItems: [
            "шаблонов документов;",
            "нормативных или организационно-распорядительных документов, которые могут быть использованы при взаимодействии с Работниками на стадии переговоров или при заключении с ними трудовых договоров",
        ],
        path: '/documents',
        pageTitle: 'Документы',
        accessRoles: ['admin' , 'hr' , 'organizer'],
        stepName: 'documentsScreen'
    },
    {
        stepTitle: "В этом разделе отображаются сотрудники Работодателя:",
        stepLIstItems: [
            "которым предоставлен доступ в систему",
            "Регистрацию новых пользователей осуществляет Администратор, также являющийся одним из сотрудников Работодателя."
        ],
        path: PagesRoutes.USERS,
        pageTitle: 'Пользователи',
        accessRoles: ['admin' , 'hr' , 'organizer'],
        stepName: 'usersScreen'
    },
    {
        stepTitle: "В этом разделе Администратором могут быть выполнены настройки:",
        stepLIstItems: [
            "отображаемых полей в карточке организации;",
            "чата в части определения статусов Работников для возможного их взаимодействия с сотрудниками Работодателя;",
            "отображаемых полей и обязательности их заполнения для карточек потребности в персонале;",
            "последовательности шагов мобилизации и демобилизации Работников"
        ],
        path: '/settings',
        pageTitle: 'Настройки',
        accessRoles: ['admin'],
        stepName: 'settingsScreen'
    },
    {
        stepTitle: "Данный пункт меню:",
        stepLIstItems: [
            "позволит пользователям приложения связаться с техподдержкой для разрешения технических неисправностей;",
        ],
        path: '/profile',
        pageTitle: 'Связь с техподдержкой',
        accessRoles: ['admin' , 'hr' , 'organizer'],
        stepName: 'supportScreen'
    },
    {
        stepTitle: "В этом разделе:",
        stepLIstItems: [
            "отображается информация о пользователе организации;",
            "в режиме редактирования Вы можете изменить свою контактную информацию и она станет доступна другим пользователям организации и Работникам, с которыми Вы осуществляете взаимодействие;",
        ],
        path: '/profile',
        pageTitle: 'Профиль пользователя',
        accessRoles: ['admin' , 'hr' , 'organizer'],
        stepName: 'userProfileScreen'
    },
    {
        stepTitle: "В этом разделе:",
        stepLIstItems: [
            "отображается информация об организации, являющейся Работодателем;",
            "заполнение карточки профиля организации повышает привлекательность публикуемых вакансий и узнаваемость бренда Вашей компании для Работников;",
            "администратору организации доступен режим редактирования этой информации;"
        ],
        path: '/public-profile/',
        pageTitle: 'Профиль карточки организации',
        accessRoles: ['admin' , 'hr' , 'organizer'],
        stepName: 'organisationProfileScreen'
    },
]