import {addYears, format} from "date-fns";
import { isDev, isProd } from "./common";

export const sendYandexMetric = (type: string, value: any) => {
    //@ts-ignore
    // window.ym(88980479, type, value)
}

export const sendMyTrackerMetric = (values: any) => {
    let userId: string
    function uniqueID(){
        function chr4(){
            return Math.random().toString(16).slice(-4);
        }
        return chr4() + chr4() +
            '-' + chr4() +
            '-' + chr4() + chr4() + chr4();
    }
    const currentCookie = document.cookie.match( 'workUserId' + '=([^;]*)(;|$)' )
    if(!!currentCookie && currentCookie[0].split('=')[1]) {
        userId = currentCookie[0].split('=')[1]
    } else {
        let createUserId = uniqueID()
        userId = createUserId
        document.cookie = `workUserId=${createUserId};expires=${addYears(new Date(), 1).toUTCString()}`
    }
    //@ts-ignore
    var _tmr = window._tmr || (window._tmr = []);
    // if(isProd){
    //     _tmr.push({...values, id: "3263823", userid: userId}); //prod
    // }
    // if(isDev) {
    //     _tmr.push({...values, id: "3269604", userid: userId}); //dev
    // }
}