import React, { useContext, useEffect, useState } from "react";
import { ICurrentTariffProps } from "./CurrentTariff.types";
import { Icon } from "@rmwc/icon";
import { useAutoCloseDialog } from "../../custom-hooks";
import { PaidVacanciesHistory } from "./components";
import { UserContext } from "../../global/context.user";
import { isSuperAdm, req } from "../../global/common";
import { Button } from "@rmwc/button";
import { CustomDialog } from "components/CustomDialog";
import { IOrganizationTariff } from "pages/auth-as-moderator/OrganizationProfile/OrganizationProfile.def";
import { InterfaceContext } from "global/context.interface";
import { format } from "date-fns";
import "./CurrentTariff.style.sass";
import { Tooltip } from "@rmwc/tooltip";
import { TariffCards } from "components/TariffCards/TariffCards";
import { useTranslation } from "react-i18next";

export const CurrentTariff: React.FC<ICurrentTariffProps> = ({
	organizationID,
}) => {
	const [isOpenBalanceInfo, setIsOpenBalanceInfo] = useState(false);
	const [isOpenHistory, setIsOpenHistory] = useState(false);
	const [isOpenDescription, setIsOpenDescription] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [balanceHistory, setBalanceHistory] = useState([]);
	const userCTX = useContext(UserContext);
	const interfaceCTX = useContext(InterfaceContext);
	const [isModerationAccepted, setIsModerationAccepted] = useState<
		boolean | null
	>(null);
	const [tariff, setTariff] = useState<IOrganizationTariff | null>(null);
	const [tariffCardsOpen, setTariffCardsOpen] = useState<boolean>(false);
	const { t } = useTranslation("common");

	useEffect(() => {
		if (organizationID) {
			req
				.get(`/organisation/${organizationID}/get`)
				.then(({ data }) => setTariff(data?.tariff))
				.catch((e) => {
					console.log(e);
					interfaceCTX.showMessage({
						title: "Ошибка",
						body: t(e.response.data),
					});
				});
		} else {
			setTariff((prevValue) => userCTX.user?.organisation?.tariff || null);
			interfaceCTX.refreshTopBar();
		}
	}, [organizationID, interfaceCTX.refreshCurrentTariff]);

	useEffect(() => {
		!organizationID &&
			setTariff((prevValue) => userCTX.user?.organisation?.tariff || null);
	}, [userCTX.user]);

	useEffect(() => {
		if (isModerationAccepted === null) {
			return;
		}

		if (isModerationAccepted) {
			setIsLoading(true);
			req
				.post("/amocrm/create_task", {
					workforceUserId: userCTX.user?.id,
				})
				.then(({ data }) => {
					req.get("/organisation/request-coin").then(({ data }) => {});
					if (data.done) {
						setIsOpenBalanceInfo(true);
						setIsOpenDescription(false);
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setIsOpenBalanceInfo(true);
			setIsOpenDescription(false);
		}
	}, [isModerationAccepted]);

	const getCoinHistory = () => {
		if (userCTX.user?.currentRole !== "moderator") {
			req.get("/organisation/coin-history").then(({ data }) => {
				setBalanceHistory(data.reverse());
			});
		}
	};

	const refillTheBalanceHandler = () => {
		setIsModerationAccepted(null);
		if (userCTX.user) {
			req
				.get(`/organisation/${userCTX.user.organisation.id}/get`)
				.then(({ data }) => {
					setIsModerationAccepted(data.moderationPassed === "accepted");
				});
		}
	};

	useAutoCloseDialog(() => {
		setIsOpenHistory(false);
		setIsOpenDescription(false);
	}, []);

	return (
		<div className={"wrapper"}>
			{userCTX.user?.currentRole !== "moderator" && (
				<Tooltip
					align={"bottomLeft"}
					className="description-tooltip"
					content={
						<div>
							Тарифный инфоблок отображает текущее состояние тарифа. <br />
							Бесплатный тариф включен по умолчанию. <br />
							Бесплатный тариф не имеет срока ограничения, доступные для
							списания "Публикаций" и "Контактов" отображаются на экране
							"Вакансии". <br />
							При переходе на платный тариф остаток по контактам и публикациям
							не суммируется. <br />
							При окончании периода действия платного тарифа, остаток сгорает.
							<br />
							Для просмотра тарифной сетки и дальнейшей покупки нажмите на
							"Контактов"
						</div>
					}
				>
					<div className="info-icon">
						<Icon icon="info_outlined" />
					</div>
				</Tooltip>
			)}

			<div className="tariff-elements">
				<div className={"tariff-element"}>
					<span
						className="tariff-element-title"
						{...(userCTX.user?.currentRole !== "moderator" && {
							onClick: (e) => {
								e.stopPropagation();
								setIsOpenHistory(false);
								setIsOpenDescription((prevState) => !prevState);
							},
							className: "tariff-element-title clickable",
						})}
					>
						Публикаций:
					</span>
					<span className={"tariff-element-count"}>
						{tariff?.organisationPublications || 0}
					</span>
				</div>

				<div className={"tariff-element"}>
					<span
						className="tariff-element-title"
						{...(userCTX.user?.currentRole !== "moderator" && {
							onClick: () => setTariffCardsOpen(true),
							className: "tariff-element-title clickable",
						})}
					>
						Контактов:
					</span>
					<div className={"tariff-element-count"}>
						{tariff?.organisationContacts || 0}
					</div>
				</div>

				<div className={"tariff-element"}>
					<span className="tariff-element-title">Срок:</span>
					<div className={"tariff-info-count"}>
						{tariff?.endDate
							? format(new Date(tariff?.endDate || ""), "dd.MM.yyyy")
							: "Бессрочно"}
					</div>
				</div>
			</div>

			{userCTX.user?.currentRole !== "moderator" && (
				<div className="history-wrapper">
					<span
						className="history-title clickable"
						onClick={(event) => {
							event.stopPropagation();
							if (!isOpenHistory) {
								getCoinHistory();
							}
							setIsOpenDescription(false);
							setIsOpenHistory((prevState) => !prevState);
						}}
					>
						История
					</span>

					{/* <div
							onClick={(event) => {
								event.stopPropagation();
							}}
						>
				
						</div> */}
					{isOpenDescription && (
						<div className={"paid-vacancies-wrapper"}>
							<div
								className={"paid-vacancies-description"}
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								<Icon
									onClick={() => {
										setIsOpenDescription((prevState) => !prevState);
									}}
									icon={"close"}
									className={"paid-vacancies-description-close"}
								/>
								<div className={"paid-vacancies-description-text"}>
									Для размещения вакансий, необходимо приобрести возможность
									публиковать объявления, на которые потом смогут откликаться
									соискатели. Нажмите кнопку "Пополнить баланс" и менеджер
									свяжется с вами в ближайшее время.
								</div>
								<Button
									className={"paid-vacancies-description-button"}
									raised={true}
									disabled={isLoading || isSuperAdm(userCTX.user)}
									onClick={() => {
										refillTheBalanceHandler();
									}}
								>
									ПОПОЛНИТЬ БАЛАНС
								</Button>
							</div>
						</div>
					)}
				</div>
			)}

			<CustomDialog
				dialogTitle={"Пополнение баланса"}
				buttons={[
					{
						text: "ок",
						raised: true,
						callback: () => setIsOpenBalanceInfo(false),
					},
				]}
				isOpen={isOpenBalanceInfo}
				onClose={() => setIsOpenBalanceInfo(false)}
			>
				<div className={"paid-vacancies-balance-dialog-body"}>
					{isModerationAccepted ? (
						<span>
							Ваш запрос был передан на обработку менеджеру, который свяжется с
							Вами в ближайшее время
						</span>
					) : (
						<span>
							Вы не можете пополнить баланс, пока ваша организация не пройдет
							модерацию
						</span>
					)}
				</div>
			</CustomDialog>
			<TariffCards
				isOpen={tariffCardsOpen}
				onClose={() => setTariffCardsOpen(false)}
			/>
			{isOpenHistory && (
				<PaidVacanciesHistory balanceHistory={balanceHistory} />
			)}
		</div>
	);
};
