import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import './chat.sass'
import defaultAvatar from '../../../misc/user-avatar-default.png'
import React, {
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import {Elevation} from "@rmwc/elevation";
import {ChatContext} from "../../../global/context.chat";
import {emitCustomEvent, useCustomEventListener} from "react-custom-events";
import {Filter, MatrixEvent, Room} from "matrix-js-sdk";
import {CircularProgress} from "@rmwc/circular-progress";
import {Messages} from "../components/messages/messages";
import {parsedRoomsData, readLastMessage} from "../messages-page-utils";
import {IForwardMessageData, IRoomMeta} from "../messages-page-def";
import {Checkbox} from "@rmwc/checkbox";
import {InterfaceContext} from "../../../global/context.interface";
import {CustomDialog} from "../../../components/CustomDialog/CustomDialog";
import {ChatPanelBottom} from "../components/chat-panel-bottom/chat-panel-bottom";
import {ChatPanelTop} from "../components/chat-panel-top/chat-panel-top";
import {req} from "../../../global/common";

export const Chat = () => {
    const history = useHistory()
    const divRef = useRef<HTMLInputElement>(null)
    const {id}: { id: string } = useParams();
    const [messages, setMessages] = useState<any>([]);
    const [isOpenForwardMessageDialog, setIsOpenForwardMessageDialog] = useState(false)
    const interfaceCTX = React.useContext(InterfaceContext)
    const [messagesLoading, setMessagesLoading] = useState(true);
    const {client, isConnected, ignoredUsers} = useContext(ChatContext);
    const [anotherUser, setAnotherUser] = useState<{ userId: string, avatarUrl: string, displayName: string, isDeleted: boolean } | null>(null)
    const [endToken, setEndToken] = useState<string | null>(null)
    const [isLoadedUserProfile, setIsLoadedUserProfile] = useState(false)
    const createFilter = () => {
        //@ts-ignore
        const filter = new Filter(client?.getUserId())
        filter.setDefinition(
            {
                "room": {
                    "timeline": {
                        "types": [
                            "m.room.message",
                        ],
                    },
                },
            },
        );
        return filter
    }

    const getMessages = async () => {
        if (client === null) return;
        if (!id || id === "") {
            history.replace("/messages");
            return;
        }

        const currentRoom = client?.getRoom(id);
        if (currentRoom === null) {
            history.replace("/messages");
            return;
        }

        currentRoom.currentState.getMembers().forEach((member: any) => {
            if (member.userId !== client.getUserId()) {
                req.get(`/chat/account/user-profile?login=${member.userId}`)
                    .then(({data}) => {
                        if (data.userProfile.currentRole === 'worker') {
                            setAnotherUser({
                                isDeleted: data.userProfile.isDeleted,
                                userId: member.userId,
                                avatarUrl: data.userProfile.avatar ? data.userProfile.avatar.pathPreview : null,
                                displayName: `${data.userProfile.lastName} ${data.userProfile.firstName} ${data.userProfile.middleName}`
                            })
                        } else {
                            setAnotherUser({
                                isDeleted: data.userProfile.isDeleted,
                                userId: member.userId,
                                avatarUrl: data.userProfile.organisation.logoPreview ? data.userProfile.organisation.logoPreview : null,
                                displayName: `${data.userProfile.lastName} ${data.userProfile.firstName} ${data.userProfile.middleName}`
                            })
                        }
                        setIsLoadedUserProfile(true)
                    })
            }
        });
        //@ts-ignore
        await client.createMessagesRequest(currentRoom?.roomId, null, 150, "b", createFilter())
            .then((data) => {
                setMessages([...data.chunk.reverse()]);
                setEndToken(data.end)
                setIsLastMessages(data.chunk.length < 150)
                setMessagesLoading(false);
            })
            .catch(() => {
                setMessages([...currentRoom.timeline]);
                setMessagesLoading(false);
            })
    };

    useEffect(() => {
        readLastMessage(client, id)
        return () => {
            readLastMessage(client, id)
        }
    }, [])

    useEffect(() => {

        setMessagesLoading(true)
        const timeout = setTimeout(() => {

            if (isConnected) getMessages();
        }, 500)

        return () => {
            setMessages([])
            clearTimeout(timeout)
        }
    }, [isConnected, id])

    useCustomEventListener("message", (data: any) => {
        // console.log('message render', data.event)
        if (data.event.event.type === 'm.room.redaction') {
            return;
        }
        if (data.event.event.content['m.new_content']) {
            setMessages((prevState: any[]) => {
                let eventIndex = prevState.findIndex((item: any) => item.event_id === data.event.event.content['m.relates_to'].event_id)
                return prevState.map((item: any, index) => {
                    if (eventIndex === index) {
                        return {
                            ...item,
                            content: {
                                ...item.content,
                                body: data.event.event.content.body
                            }
                        }
                    } else return item
                })
            })
            emitCustomEvent("m.room.message.redact", {
                body: data.event.event.content.body,
                eventId: data.event.event.content['m.relates_to'].event_id
            })
            return;
        }
        if (!anotherUser?.displayName) return;
        let copy = [...messages];
        if (data.event.getRoomId() !== id) return;
        let timeout: any
        // @ts-ignore
        copy.push(data.event.event);
        setMessages(copy);
        if (!!timeout) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            divRef.current?.scrollIntoView()
        }, 100)
    });

    const [isLastMessages, setIsLastMessages] = useState(false)
    const [isStartSending, setIsStartSending] = useState(false)
    const [isAutoScroll, setIsAutoScroll] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const scrollHandler = (e: React.UIEvent<HTMLElement>) =>
        new Promise(async resolve => {
            if (client === null) {
                return;
            }
            const room = client.getRoom(id);
            if (room === null) {
                return;
            }
            let scrollHeight = (e.currentTarget.clientHeight * e.currentTarget.clientHeight / e.currentTarget.scrollHeight);
            let scrollTop = (e.currentTarget.clientHeight - 30 + scrollHeight) * e.currentTarget.scrollTop / e.currentTarget.scrollHeight;
            if (scrollTop <= 200 && isLoading && !isLastMessages) {
                !isAutoScroll && setIsAutoScroll(true)
                const currentRoom = client?.getRoom(id);
                if (!currentRoom) {
                    history.replace("/messages");
                    return;
                }

                setIsLoading(false)
                setIsAutoScroll(false)
                // @ts-ignore
                await client.createMessagesRequest(currentRoom?.roomId, endToken, 100, 'b', createFilter())
                    .then((data: any) => {
                        setMessages((prevState: any[]) => {
                            return [
                                ...data.chunk.reverse(),
                                ...prevState,
                            ]
                        });
                        setEndToken(data.end)
                        setIsLoading(true)
                        setMessagesLoading(false);
                        setIsLastMessages(data.chunk.length < 100)
                    })
                    .catch(() => {
                        setMessagesLoading(false);
                    })
            } else {
                isAutoScroll && setIsAutoScroll(false)
            }
        })

    useEffect(() => {
        let timeout: any
        if (!!timeout) {
            clearTimeout(timeout)
        }
        if (isAutoScroll) {
            timeout = setTimeout(() => {
                divRef.current?.scrollIntoView()
            }, 0)
        }
        if (messages.length === 0) return;
        return () => {
            if (!!timeout) {
                clearTimeout(timeout)
            }
        }
    }, [messages]);

    const [roomsData, setRoomsData] = useState<IRoomMeta[]>([])
    const [forwardMessageContent, setForwardMessageContent] = useState<IForwardMessageData | null>(null)
    const [messageRecipients, setMessageRecipients] = useState<string[]>([])

    const startForwardingMessage = (messageData: IForwardMessageData) => {
        const rooms = client?.getVisibleRooms().filter((room: any) => {
            return !room.hasMembershipState(client?.getUserId(), "leave");
        })
        parsedRoomsData(rooms as Room[], client)
            .then((data) => {
                setRoomsData(data.filter(item => !item.isIgnored))
                setIsOpenForwardMessageDialog(true)
            })
        setForwardMessageContent(messageData)
    }

    const sendForwardMessage = async () => {
        if (messageRecipients.length === 0) {
            interfaceCTX.showMessage({
                body: `Пожалуйста, выберите хотя бы одного получателя`,
                icon: 'report'
            })
        } else if (!!forwardMessageContent && isStartSending) {
            setIsStartSending(false)
            let content: any
            if (forwardMessageContent.msgType === 'm.file') {
                content = {
                    body: forwardMessageContent.body,
                    messageOwner: forwardMessageContent.messageOwner,
                    info: forwardMessageContent.info,
                    msgtype: "m.file",
                    url: forwardMessageContent.url
                }
            } else if (forwardMessageContent.msgType === "m.image") {
                content = {
                    body: forwardMessageContent.body,
                    messageOwner: forwardMessageContent.messageOwner,
                    info: forwardMessageContent.info,
                    msgtype: "m.image",
                    url: forwardMessageContent.url
                }
            } else if (forwardMessageContent.msgType === "m.text") {
                content = {
                    body: forwardMessageContent.body,
                    messageOwner: forwardMessageContent.messageOwner,
                    msgtype: "m.text",
                }
            }
            setForwardMessageContent(null)
            await messageRecipients.forEach((item: string) => {
                client?.sendEvent(item, "m.room.message", content, "").then((res) => {

                })
            })
        }
    }

    return (
        <>
            <CircularProgress style={{width: '150px', height: '150px'}}
                              className={`loader ${messagesLoading && 'open'}`}></CircularProgress>

            {!messagesLoading &&
                <>
                    <ChatPanelTop anotherUser={anotherUser} id={id}/>
                    <Elevation z={3} className="chat-panel">
                        <div className='chat-list'
                             onScroll={scrollHandler}
                        >
                            {messages.length > 0 &&
                                <Messages
                                    messages={messages}
                                    roomId={id}
                                    startForwardingMessage={startForwardingMessage}/>
                            }
                            <div ref={divRef}/>
                            {/*<SubHeader typing={typing} isOnline={isOnline}/>*/}
                        </div>
                        {isLoadedUserProfile && (anotherUser && !anotherUser?.isDeleted
                            ? !ignoredUsers.includes(anotherUser?.userId as string) && <ChatPanelBottom roomId={id}/>
                            : <div className='chat-panel-is-deleted-user'>Профиль удален</div>)
                        }
                    </Elevation>
                    <CustomDialog
                        isOpen={isOpenForwardMessageDialog}
                        buttons={[
                            {
                                text: 'Отправить',
                                callback: () => {
                                    sendForwardMessage().then(() => {
                                        interfaceCTX.showMessage({
                                            body: `Сообщение успешно отправлено`,
                                            icon: 'done'
                                        })
                                    })
                                },
                                raised: true
                            }
                        ]}
                        dialogTitle={'Переслать сообщение'}
                        onClose={() => {
                            setMessageRecipients([])
                            setForwardMessageContent(null)
                            setIsOpenForwardMessageDialog(false)
                        }}
                    >
                        <div className='chat-rooms-items'>
                            {roomsData.length > 0
                                ? roomsData.filter(item => !item.isDeleted || item.isIgnored).map((room: IRoomMeta, index) => (
                                    <div className='chat-rooms-item' key={`room-${room.roomId}`}>
                                        <Checkbox
                                            className={messageRecipients.includes(room.roomId) ? 'horosiy-checkbox' : 'off-checkbox'}
                                            checked={messageRecipients.includes(room.roomId)}
                                            onChange={(e) => {
                                                setIsStartSending(true)
                                                setMessageRecipients((prevState: string[]) => {
                                                    let alreadyHas = prevState.find(value => value === room.roomId)
                                                    let newState: string[] = []
                                                    if (alreadyHas) {
                                                        newState = [...prevState.filter(value => value !== room.roomId)]
                                                    } else {
                                                        newState = [...prevState, room.roomId]
                                                    }
                                                    return newState
                                                })
                                            }}
                                        />
                                        <div style={{position: 'relative'}}>
                                            {room.getAvatar
                                                ? <div className='chat-rooms-item-avatar'
                                                       style={{
                                                           backgroundImage: `url(${room.getAvatar})`,
                                                       }}/>
                                                : <div className='chat-rooms-item-avatar'
                                                       style={{
                                                           backgroundImage: `url(${defaultAvatar})`,
                                                           border: '1px solid #D9D9D9',
                                                       }}/>}
                                        </div>
                                        <div className='chat-rooms-item-text'>
                                            <div className='messages-page-item-fullName'>
                                                {room.name}
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : <div className='doc-container-no-data'>Нет диалогов</div>
                            }
                        </div>
                    </CustomDialog>
                </>
            }
        </>
    )
}