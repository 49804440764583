import { InterfaceContext } from 'global/context.interface'
import { UserProfile } from 'global/definitions/definitions'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { req } from '../../../global/common'
import { UserContext } from '../../../global/context.user'
import { WorkerProfileExperience } from './components/professional-experience-form/professional-experience-form-def'
import { mapExperienceByProductionAndUnit } from './components/professional-experience-form/professional-experience-form-utils'
import {
	Query,
	ReviewBody,
	WorkerProfile,
	WorkerProfilePermission,
	WorkerProfileProfession
} from './worker-profile-def'

export const mapWorkerProfileFromFormValues = (workerProfile: Partial<WorkerProfile>): Partial<UserProfile> => {
	const { personalInfo } = workerProfile

	const allExperience: Record<string, string | number>[] = []
	workerProfile?.experience?.forEach(exp => {
		const { production, unit, value, ...types } = exp

		Object.entries(types).forEach(([type, typeValue]) => {
			//@ts-ignore
			const { expMonths, expYears } = typeValue || {}
			allExperience.push({
				production,
				unit,
				type,
				expMonths: Number(expMonths),
				expYears: Number(expYears)
			})
		})
	})

	return {
		// @ts-ignore
		personalInfo,
		// @ts-ignore
		mainProfession: workerProfile?.mainProfession,
		grade: workerProfile?.grade,
		// TODO:
		// @ts-ignore
		subProfessions: workerProfile?.subProfessions,
		// @ts-ignore
		permissions: workerProfile?.permissions,
		// @ts-ignore
		experience: allExperience
	}
}
export const mapWorkerProfileToFormValues = (
	userProfile: Partial<
		Omit<UserProfile, 'personalInfo'> & {
			personalInfo: Partial<UserProfile['personalInfo']>
		}
	>
): Partial<WorkerProfile> => {
	const {
		avatar,
		personalInfo,
		withoutExperience,
		mainProfession,
		grade,
		permissions,
		permissionDocuments,
		experience,
		subProfessions,
		qualificationDocuments,
		isDeleted
	} = userProfile

	return {
		avatar,
		personalInfo: personalInfo || ({} as WorkerProfile['personalInfo']),
		mainProfession: mainProfession || undefined,
		grade,
		withoutExperience: withoutExperience,
		subProfessions: subProfessions?.map(profession => profession.id),
		permissions: permissions?.map(permission => permission.id),
		experience: mapExperienceByProductionAndUnit(experience || []),
		permissionDocuments,
		qualificationDocuments,
		isDeleted
	}
}

const createRequestToAddDocument = (type: string, formData: FormData) => async () =>
	req.post(`/profile/worker/documents/additional/create?type=${type}`, formData)

const createRequestToUpdateDocument = (id: number, formData: FormData) => async () =>
	req.post(`/profile/worker/documents/additional/${id}/update`, formData)

const createRequestToDeleteDocument = (id: number) => async () =>
	req.delete(`/profile/worker/documents/additional/${id}/delete`)

const createRequestToDeleteDocumentImage = (id: number) => async () =>
	req.delete(`/profile/worker/documents/additional/${id}/delete-media`)

export const mapDocumentsToRequests = async <
	TRequestResult,
	TDocument extends {
		id: number
		number: string
		date: string
		path: string
	} = { id: number; number: string; date: string; path: string }
>(
	prevDocuments: TDocument[] = [],
	documents: TDocument[] = [],
	type: string
) => {
	const requests: (() => Promise<TRequestResult>)[] = []

	const prevDocumentsById = prevDocuments.reduce((result, prevDocument) => {
		const { id } = prevDocument
		result[id as TDocument['id']] = prevDocument
		return result
	}, {} as Record<TDocument['id'], TDocument>)
	const documentsById: Record<TDocument['id'], TDocument> = {} as Record<TDocument['id'], TDocument>

	for (let newDocument of documents) {
		const { id, path, number, date } = newDocument
		documentsById[id as TDocument['id']] = newDocument

		const isDocumentWithNewImage = path?.includes('blob:')

		const image = isDocumentWithNewImage ? await fetch(path).then(r => r.blob()) : undefined

		const formData = new FormData()
		if (image) {
			formData.append('file', image)
		}
		formData.append('number', String(number))
		formData.append('date', date)

		const prevDocument = prevDocumentsById[id as TDocument['id']]

		const isDocumentWithDeletedImage = !!id && !path && prevDocument && prevDocument.path !== path
		const isNewDocument = !prevDocument

		const isChangedDocument =
			prevDocument && (prevDocument.number !== number || prevDocument.date !== date || prevDocument.path !== path)

		if (isNewDocument) {
			// @ts-ignore
			requests.push(createRequestToAddDocument(type, formData))
		}

		if (isChangedDocument) {
			if (isDocumentWithDeletedImage) {
				// @ts-ignore
				requests.push(createRequestToDeleteDocumentImage(id))
			}
			// @ts-ignore
			requests.push(createRequestToUpdateDocument(id, formData))
		}
	}

	prevDocuments.forEach(({ id }) => {
		const isDeletedDocument = !documentsById[id as TDocument['id']]

		if (isDeletedDocument) {
			// @ts-ignore
			requests.push(createRequestToDeleteDocument(id))
		}
	})

	return requests
}

const createRequestToAddMedia = (type: string, formData: FormData) => async () =>
	req.post(`/profile/worker/media/upload?type=${type}`, formData)
// /profile/worker/media/delete/{mediaID}?mediaID=111
const createRequestToDeleteMedia = (id: number) => async () => req.delete(`/profile/worker/media/delete/${id}`)

export const mapMediasToRequests = async <
	TRequestResult,
	TMedia extends {
		id: number
		path: string
		type: string
	} = { id: number; path: string; type: string }
>(
	prevMedia: TMedia[] | null = [],
	media: TMedia[] = []
) => {
	const requests: (() => Promise<TRequestResult>)[] = []

	for (let newDocument of media) {
		const { id, path, type } = newDocument
		const prevDocument = prevMedia?.find(item => item.type === type && item.id === id)

		const isDocumentWithNewImage = path?.includes('blob:')

		const image = isDocumentWithNewImage ? await fetch(path).then(r => r.blob()) : undefined

		const formData = new FormData()
		if (image) {
			formData.append('file', image)
		}

		const isDocumentWithDeletedImage =
			!!id && id == prevDocument?.id && prevDocument?.path !== path && type === prevDocument?.type
		const isNewDocument = !prevDocument

		const isChangedDocument = prevDocument && prevDocument.path !== path

		if (isNewDocument && path) {
			// @ts-ignore
			requests.push(createRequestToAddMedia(type, formData))
		}

		if (isChangedDocument) {
			if (isDocumentWithDeletedImage) {
				// @ts-ignore
				requests.push(createRequestToDeleteMedia(id))
			}
			if (path) {
				// @ts-ignore
				requests.push(createRequestToAddMedia(type, formData))
			}
		}
	}

	return requests
}

export const sequenceRequests = <TRequest extends () => Promise<any>>(requests: TRequest[]): ReturnType<TRequest> => {
	return requests.reduce((result, request) => result.then(request), Promise.resolve()) as ReturnType<TRequest>
}

export const useProfessionOptions = (query: Partial<Query> = {}) => {
	const { t } = useTranslation('common')
	const interfaceCTX = useContext(InterfaceContext)
	const [allProfessions, setAllProfessions] = useState<WorkerProfileProfession[]>([])

	const loadProfessions = useCallback(({ limit, offset, search }: Partial<Query> = {}) => {
		setAllProfessions([])
		req
			.get(`/info/professions`, {
				limit: limit || 99,
				offset: offset || 0,
				search: search || ''
			})
			.then(({ data }) => {
				setAllProfessions(data.data)
			})
			.catch(e => {
				interfaceCTX.showMessage({
					title: 'Ошибка',
					body: t('error.' + e.response.data)
				})
			})
	}, [])

	const { limit, offset, search } = query
	useEffect(() => {
		loadProfessions({
			limit,
			offset,
			search
		})
	}, [loadProfessions, limit, offset, search])

	return useMemo(
		() =>
			allProfessions?.map(({ id, name }) => {
				return { value: id, name: name }
			}),
		[allProfessions]
	)
}

export const usePermissionOptions = (query: Partial<Query> = {}) => {
	const { t } = useTranslation('common')
	const interfaceCTX = useContext(InterfaceContext)
	const [allPermissions, setAllPermissions] = useState<WorkerProfilePermission[]>([])

	const loadPermissions = useCallback(({ limit, offset, search }: Partial<Query> = {}) => {
		setAllPermissions([])
		req
			.get(`/info/permissions`, {
				limit: limit || 99,
				offset: offset || 0,
				search: search || ''
			})
			.then(({ data }) => {
				setAllPermissions(data.data)
			})
			.catch(e => {
				interfaceCTX.showMessage({
					title: 'Ошибка',
					body: t('error.' + e.response.data)
				})
			})
	}, [])

	const { offset, limit, search } = query
	useEffect(() => {
		loadPermissions({
			offset,
			limit,
			search
		})
	}, [loadPermissions, offset, limit, search])

	return useMemo(
		() =>
			allPermissions?.map(({ id, name }) => {
				return { value: id, name: name }
			}),
		[allPermissions]
	)
}

export const useExperienceOptions = () => {
	const { t } = useTranslation('common')
	const interfaceCTX = useContext(InterfaceContext)
	const [allExperience, setAllExperience] = useState<WorkerProfileExperience>({} as WorkerProfileExperience)
	const loadExperience = useCallback(() => {
		req
			.get(`/info/experience`)
			.then(data => {
				setAllExperience(data?.data)
			})
			.catch(e => {
				interfaceCTX.showMessage({
					title: 'Ошибка',
					body: t('error.' + e.response.data)
				})
			})
	}, [])

	useEffect(() => {
		loadExperience()
	}, [loadExperience])

	return allExperience
}

export const useReviewsOptions = (query: Partial<Query> = {}) => {
	const userCTX = React.useContext(UserContext)

	const [reviews, setReviews] = useState<ReviewBody[]>([])

	const loadReviews = useCallback(({ limit, offset, search }: Partial<Query> = {}) => {
		req
			.get(`/reviews/person/${userCTX?.user?.id}/list`, {
				limit: limit || 99,
				offset: offset || 0,
				search: search || ''
			})
			.then(data => {
				setReviews(data?.data?.data)
			})
	}, [])

	const { limit, offset, search } = query
	useEffect(() => {
		loadReviews({
			limit,
			offset,
			search
		})
	}, [loadReviews, limit, offset, search])

	return reviews
}
