import React, {useEffect, useState} from "react";
import './PaymentHistory.style.sass'
import {req} from "../../../../global/common";
import {CircularProgress} from "@rmwc/circular-progress";
import {format} from "date-fns";


export const PaymentHistory = () => {
    const [paymentHistoryList, setPaymentHistoryList] = useState<{
        addDate: string,
        id: number,
        organisation: number,
        price: string,
        type: string
    }[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const convertPrice = (value: string) => {
        return Number(value).toLocaleString() + ' руб.'
    }

    const getPaymentHistory = () => {
        req.get('/organisation/payment-history')
            .then(({data}) => {
                setPaymentHistoryList(data.reverse())
                setIsLoading(false)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getPaymentHistory()
    }, [])

    return (
        <div className={'payment-history'}>
            <div className={'payment-history-wrapper'}>
                <div className={'payment-history-header'}>
                    <div className={'payment-history-header-row'}>
                        <div className={'payment-history-date payment-history-first-cell'}>
                            Дата добавления
                        </div>
                        <div className={'payment-history-service payment-history-middle-cell'}>
                            Стоимость пакета
                        </div>
                        <div className={'payment-history-need payment-history-last-cell'}>
                            Размер пакета
                        </div>
                    </div>
                </div>
                <div className={'payment-history-body'}>
                    {paymentHistoryList.length === 0
                        ? <div className={'payment-history-empty'}>
                            {isLoading
                                ? <CircularProgress
                                    className='circular-progress payment-history-empty'
                                    style={{}}/>
                                : 'Нет истории платежей'
                            }
                        </div>
                        : paymentHistoryList.map((item) => {
                            return (
                                <div className={'payment-history-body-row'} key={item.id}>
                                    <div className={'payment-history-date payment-history-first-cell'}>
                                        {format(new Date(item.addDate), 'dd.MM.yyyy HH:mm')}
                                    </div>
                                    <div
                                        className={'payment-history-price payment-history-middle-cell'}>
                                        {convertPrice(item.price)}
                                    </div>
                                    <div className={'payment-history-type payment-history-last-cell'}>
                                        {item.type}
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>

        </div>
    )
}