import { FixedButtons } from "components/FixedButtons";
import { InviteWorkerForm } from "components/InviteWorkerForm/InviteWorkerForm";
import { useState } from "react";
import { IInviteWorkerBtnProps } from "./InviteWorkerBtn.def";

export function InviteWorkerBtn({
	workerProfile,
	setReviewsReload,
}: IInviteWorkerBtnProps) {
	const [openInviteForm, setOpenInviteForm] = useState<boolean>(false);

	const blockInviteStatuses = ["Трудоустроен, не рассматриваю предложения"];

	return (
		<>
			<FixedButtons
				length={1}
				buttons={[
					{
						disabled: blockInviteStatuses.includes(
							workerProfile.personalInfo?.workSearchStatus || ""
						),
						primary: true,
						label: "Пригласить",
						onClick: () => {
							setOpenInviteForm(true);
						},
					},
				]}
			/>
			<InviteWorkerForm
				open={openInviteForm}
				onSuccessInvite={() => {
					setOpenInviteForm(false)
					setReviewsReload(prevState => !prevState)
				}}
				onClose={() => setOpenInviteForm(false)}
				workerProfile={workerProfile}
			/>
		</>
	);
}
