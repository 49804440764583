import {Role} from "../../global/definitions/definitions"
import {WhoWorkerWrite, PublicOrganizationProfileTemplate, NeedTemplate, EditTemplateSubpage} from "./components";
import {ProfessionsGroups} from "../auth-as-moderator/settings/professions-groups/professions-groups";
import {
    SettingProfessionsList
} from "../auth-as-moderator/settings/setting-professions-list/setting-professions-list";
import {VersionsHistory} from "../auth-as-moderator/settings/VersionsHistory";
import { OnboardingTabsLayout } from "../auth-as-moderator/settings/Onboarding/components/OnboardingTabsLayout";
import { VersionHistoryCover } from "../auth-as-moderator/settings/VersionHistoryCover/VersionHistoryCover";
import { Instructions } from "../auth-as-moderator/settings/Instructions/Instructions";
import {
    CategoryPage
} from "../auth-as-moderator/settings/Instructions/components/CategoryPage/CategoryPage";
import { isDev } from "../../global/common";
import { CategoryList } from "../auth-as-moderator/settings/Instructions/components/CategoryList/CategoryList";
import { SurveyPage } from "../auth-as-moderator/settings/SurveyPage";

export {}

export interface ISideBarMenuItem {
    sectionTitle: string,
    sectionURL: string,
    icon: string
    navItems: {
        itemTitle: string,
        component: JSX.Element,
        url: string,
        visibleFor: Role[]
        navBar?: boolean
        description?: boolean
        background?: boolean
    }[]
    show?: boolean
}

export let navigationSections: ISideBarMenuItem[] = [
    {
        sectionTitle: 'Профиль организации',
        sectionURL: `organization-profile`,
        icon: 'home',
        navItems: [{
            itemTitle: 'Настройка шаблона',
            component: <PublicOrganizationProfileTemplate/>,
            url: 'profile-template',
            visibleFor: ['admin'],
            description: true,
            navBar: true
        }]
    },
    {
        sectionTitle: 'Вакансии',
        sectionURL: `need`,
        icon: 'description',
        navItems: [
            {
                itemTitle: 'Настройка шаблона',
                component: <NeedTemplate/>,
                url: 'template-customization',
                visibleFor: ['admin'],
                description: true,
                navBar: true
            }
        ]
    },
    {
        sectionTitle: 'Справочники',
        sectionURL: `directory`,
        icon: 'home',
        navItems: [
            {
                itemTitle: 'Группы профессий',
                component: <ProfessionsGroups/>,
                url: 'groups',
                visibleFor: ['moderator'],
                navBar: true,
                background: true
            },
            {
                itemTitle: 'Профессии',
                component: <SettingProfessionsList/>,
                url: 'professions',
                visibleFor: ['moderator'],
                navBar: true,
                background: true
            },
            {
                itemTitle: 'Настройка группы профессий',
                component: <ProfessionsGroups/>,
                url: 'redact-group',
                visibleFor: ['moderator'],
                navBar: false,
                background: true
            },
            {
                itemTitle: 'Добавление профессий к группе',
                component: <SettingProfessionsList/>,
                url: 'profession-redact',
                visibleFor: ['moderator'],
                navBar: false,
                background: true
            },
        ],
    },
    {
        sectionTitle: 'История изменений версий',
        sectionURL: `versions`,
        icon: 'history',
        navItems: [
            // {
            //     itemTitle: 'Обложка',
            //     component: <VersionHistoryCover/>,
            //     url: 'cover',
            //     visibleFor: ['moderator'],
            //     navBar: true,
            // },
            {
                itemTitle: 'Web - приложение',
                component: <VersionsHistory platform={'web'}/>,
                url: 'web-list',
                visibleFor: ['moderator'],
                navBar: true,
                background: true
            },
            {
                itemTitle: 'Мобильное приложение',
                component: <VersionsHistory platform={'mobile'}/>,
                url: 'mobile-list',
                visibleFor: ['moderator'],
                navBar: true,
                background: true
            },
            {
                itemTitle: 'Web - приложение',
                component: <VersionsHistory platform={'web'}/>,
                url: 'web-redact',
                visibleFor: ['moderator'],
                navBar: false,
                background: true
            },
            {
                itemTitle: 'Мобильное приложение',
                component: <VersionsHistory platform={'mobile'}/>,
                url: 'mobile-redact',
                visibleFor: ['moderator'],
                navBar: false,
                background: true
            },
        ],
    },
    // {
    //     sectionTitle: 'Инструкции',
    //     sectionURL: `instructions`,
    //     icon: 'history',
    //     show: isDev,
    //     navItems: [
    //         {
    //             itemTitle: 'Web - приложение',
    //             component: <Instructions platform={'web'}/>,
    //             url: 'web',
    //             visibleFor: ['moderator'],
    //             navBar: true,
    //             background: true
    //         },
    //         {
    //             itemTitle: 'Мобильное приложение',
    //             component: <Instructions platform={'mobile'}/>,
    //             url: 'mobile',
    //             visibleFor: ['moderator'],
    //             navBar: true,
    //             background: true
    //         },
    //         {
    //             itemTitle: 'Web - приложение',
    //             component: <CategoryList platform={'web'}/>,
    //             url: 'web-category',
    //             visibleFor: ['moderator'],
    //             navBar: false,
    //             background: true
    //         },
    //         {
    //             itemTitle: 'Мобильное приложение',
    //             component: <CategoryList platform={'mobile'}/>,
    //             url: 'mobile-category',
    //             visibleFor: ['moderator'],
    //             navBar: false,
    //             background: true
    //         },
    //         {
    //             itemTitle: 'Web - приложение',
    //             component: <CategoryPage platform={'web'}/>,
    //             url: 'web-category-item',
    //             visibleFor: ['moderator'],
    //             navBar: false,
    //             background: true
    //         },
    //         {
    //             itemTitle: 'Мобильное приложение',
    //             component: <CategoryPage platform={'mobile'}/>,
    //             url: 'mobile-category-item',
    //             visibleFor: ['moderator'],
    //             navBar: false,
    //             background: true
    //         },
    //     ],
    // },
    {
        sectionTitle: 'Онбоардинг',
        sectionURL: `onboarding`,
        icon: 'school',
        navItems: [
            {
                itemTitle: 'Web - приложение',
                component: <OnboardingTabsLayout platform={'web'}/>,
                url: 'web',
                visibleFor: ['moderator'],
                navBar: true,
                background: true
            },
            {
                itemTitle: 'Мобильное приложение',
                component: <OnboardingTabsLayout platform={'mobile'}/>,
                url: 'mobile',
                visibleFor: ['moderator'],
                navBar: true,
                background: true
            },
        ],
    },
    {
        sectionTitle: 'Помощь',
        sectionURL: `survey`,
        icon: 'question_mark',
        navItems: [
            {
                itemTitle: 'Опрос',
                component: <SurveyPage/>,
                url: 'list',
                visibleFor: ['moderator'],
                navBar: true,
                background: true
            },
            {
                itemTitle: 'Опрос',
                component: <SurveyPage/>,
                url: 'item',
                visibleFor: ['moderator'],
                navBar: false,
                background: true
            },
        ],
    }
]


export function isActivePageAvailableForCurrentRole(role: Role, URL: string, navigationSections: ISideBarMenuItem[]) {
    let parsedURL = URL.split('/')
    let targetURLpart = parsedURL[parsedURL.length - 1]
    let targetNavItem: any = {}
    navigationSections.map(section => section.navItems.map(navItem => {
        if (navItem.url == targetURLpart) targetNavItem = {...navItem}
    }).length > 0)
    return targetNavItem.visibleFor.includes(role)
}