import { add, getDayOfYear, getISOWeek, startOfISOWeek, sub } from 'date-fns'
import { DataObject } from './WeekScroller.types'

export const getWeekData = (offset: number) => {
	const startDate = sub(startOfISOWeek(new Date()), { weeks: 1 - offset })
	const arr = Array(21)
		.fill(undefined)
		.map((_, i) => add(startDate, { days: i }))

	return Array(3)
		.fill(undefined)
		.map((_, i) => new DataObject(arr.slice(i * 7, i * 7 + 7)))
}

export const isCurrentWeek = (date: Date[]) => {
	const currentDate = new Date()

	return date.every(v => getISOWeek(v) === getISOWeek(currentDate) && v.getFullYear() === currentDate.getFullYear())
}

export const isCurrentDay = (day: Date) => {
	const currentDate = new Date()

	return getDayOfYear(day) === getDayOfYear(currentDate) && day.getFullYear() === currentDate.getFullYear()
}
