import { Icon } from '@rmwc/icon'
import { useHistory, useLocation } from 'react-router-dom'
import './FullpageAlert.style.sass'
import {IFullpageAlert} from "./FullpageAlert.types";


export default function FullpageAlert({
    icon, label
}: IFullpageAlert) {
    const history = useHistory()
    const location = useLocation()
    return (
        <div className="fullpage-alert">
            {location.pathname.includes('dump') && <Icon className="back" icon={{icon: "arrow_back_ios_new",  size: "small"}} onClick={() => history.goBack()} />}
            <Icon icon={{ icon: icon ?? 'search_off', size: 'xlarge' }} />
            {label ?? 'Данные отсутствуют'}
        </div>
    )
}