import { max, min } from 'date-fns'
import { ShortShiftResponse } from 'global/definitions'

export interface WeekScrollerProps {
	className?: string
	currentPage: number
	currentDay: string
	shifts: ShortShiftResponse[]
	onSetCurrentPage: (page: number) => void
	onSetCurrentDay: (date: string) => void
}

export class DataObject {
	date: Date[]
	days: Date[]

	constructor(arr: Date[]) {
		this.days = arr

		const minmax = [min(arr), max(arr)]
		if (minmax[0].getMonth() === minmax[1].getMonth()) {
			minmax.shift()
		}
		this.date = minmax
	}
}
