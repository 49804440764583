import React, { useContext, useEffect, useState } from "react";
import "./SystemSettings.style.sass";
import { CustomDialog, FixedButtons, PageTitle, ProgressPage } from "../../../components";
import { useTranslation } from "react-i18next";
import { Icon } from "@rmwc/icon";
import { req } from "../../../global/common";
import { Tooltip } from "@rmwc/tooltip";
import { InterfaceContext } from "../../../global/context.interface";
import { ISystemSettings } from "./SystemSettings.types";
import { Checkbox } from "@rmwc/checkbox";
import Select from "react-select";

export const SystemSettings: React.FC<{}> = () => {
    const { t } = useTranslation("common");
    const interfaceCTX = useContext(InterfaceContext);
    const [settingsData, setSettingsData] = useState<ISystemSettings>({} as ISystemSettings);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenDialog, setIsOpenDialog] = useState(false);

    const saveChangedSystemSettings = () => {
        setIsLoading(true);
        req.post("/moderator/settings/system/update", settingsData)
            .then(({ data }) => {
                setSettingsData(data);
            })
            .catch((e) => {
                setSettingsData({} as ISystemSettings);
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            })
            .finally(() => {
                setIsOpenDialog(false);
                setIsLoading(false);
            });
    };

    const renderSystemSettingsField = (fieldName: keyof ISystemSettings) => {
        return (
            <div className={"system_settings-field"}>
                <Checkbox
                    className={settingsData[fieldName] ? "horosiy-checkbox" : "off-checkbox"}
                    checked={!!settingsData[fieldName]}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        setSettingsData(prevState => ({
                            ...prevState,
                            [fieldName]: !prevState[fieldName]
                        }));
                    }}
                />
                <div className={"system_settings-label"}>
                    {t("system_settings." + fieldName)}
                </div>
                <Tooltip
                    content={
                        <div style={{ width: "350px" }}>
                            {t("system_settings.tooltip." + fieldName)}
                        </div>
                    }
                    align={"topLeft"}
                >
                    <Icon icon={"info_outline"} />
                </Tooltip>
            </div>
        );
    };

    useEffect(() => {
        req.get("/moderator/settings/system/get")
            .then(({ data }) => {
                setSettingsData(data);
            })
            .catch((e) => {
                setSettingsData({} as ISystemSettings);
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            });
    }, []);

    return (
        <div className={"system_settings-page"}>
            <PageTitle title={"Системные настройки"} breadcrumbs={true} />
            <FixedButtons
                length={1}
                className={"system_settings-buttons"}
                buttons={[
                    {
                        label: "Сохранить",
                        primary: true,
                        onClick: () => setIsOpenDialog(true),
                        disabled: isLoading
                    }
                ]}
            />
            <ProgressPage
                state={settingsData}
                render={() => (
                    <div className={"system_settings-container"}>
                        <div className={"system_settings-description"}>
                            <Icon icon={"error_outline"} />
                            <div className={"system_settings-text"}>
                                Данные настройки имеют прямое влияние на работу системы. Для обеспечения оптимальной
                                функциональности системы изменения в настройках должны вносится только модератором.
                            </div>
                        </div>
                        <div className={"system_settings-title"}>
                            Для клиента
                        </div>
                        {renderSystemSettingsField("publicationVacanciesWithoutModeration")}
                        {renderSystemSettingsField("vacanciesCompensation")}
                        <div className={"system_settings-field"}>
                            <div className={"system_settings-text"}>
                                Количество бесплатных публикаций по умолчанию:
                            </div>
                            <Select
                                className={"system_settings-select"}
                                classNamePrefix={"select"}
                                placeholder={"Выберите число"}
                                value={{
                                    value: settingsData.workCoinsDefaultValue,
                                    label: settingsData.workCoinsDefaultValue
                                }}
                                options={[
                                    {
                                        value: 0,
                                        label: 0
                                    },
                                    {
                                        value: 1,
                                        label: 1
                                    },
                                    {
                                        value: 2,
                                        label: 2
                                    },
                                    {
                                        value: 3,
                                        label: 3
                                    },
                                    {
                                        value: 4,
                                        label: 4
                                    },
                                    {
                                        value: 5,
                                        label: 5
                                    }
                                ]}
                                onChange={evt => {
                                    if (!evt) return;
                                    setSettingsData(prevState => ({
                                        ...prevState,
                                        workCoinsDefaultValue: evt.value
                                    }));
                                }}
                                isSearchable={false}
                                menuPosition={"fixed"}
                                menuShouldScrollIntoView={false}
                            />
                        </div>
                        <br />
                        <br />
                        <div className={"system_settings-title"}>
                            Для рабочего
                        </div>
                        {renderSystemSettingsField("vacancyResponseExperienceRequired")}
                    </div>
                )}
            />
            <CustomDialog
                dialogTitle={"Сохранить изменения?"}
                isOpen={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                buttons={[{
                    callback: () => saveChangedSystemSettings(),
                    outlined: true,
                    raised: true,
                    text: "Сохранить"
                }, {
                    callback: () => setIsOpenDialog(false),
                    outlined: true,
                    raised: false,
                    text: "отмена"
                }]}
            >
                <div style={{
                    padding: "20px 50px",
                    width: "500px",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    color: "#12324F",
                    flexDirection: "column"
                }}>
                    Сохранение настроек может повлиять на работу системы. Пожалуйста, будьте внимательны при внесении
                    изменений.
                </div>
            </CustomDialog>
        </div>
    );
};