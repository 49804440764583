import './setting-professions-list.sass'
import React from "react";
import {useHistory} from "react-router";
import {ProfessionsList} from "./components/professions-list/professions-list";

export const SettingProfessionsList = () => {
    const history = useHistory()

    return (
        <div className={'setting-professions-list'}>
            {history.location.pathname.includes('professions') &&
                <ProfessionsList/>
            }
            {history.location.pathname.includes('profession-redact') &&
                <></>
            }
        </div>
    )
}