import { Checkbox } from "@rmwc/checkbox";
import { PageTitle, FixedButtons, Form } from "components";
import { req } from "global/common";
import { InterfaceContext } from "global/context.interface";
import { UserContext } from "global/context.user";
import { checkInputedAge } from "pages/auth-as-worker/auth-as-worker-utils";
import React, { MutableRefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import "./CreateUserPage.style.sass";
import { Tooltip } from "@rmwc/tooltip";
import { Icon } from "@rmwc/icon";
import { RenderModuleNotPaid } from "../RenderModuleNotPaid";

export function CreateUserPage() {
    const userCTX = React.useContext(UserContext);
    const interfaceCTX = React.useContext(InterfaceContext);
    const createUserForm = React.useRef() as MutableRefObject<HTMLFormElement>;
    const [roles, setRoles] = useState<{ label: string; value: string }[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const { t } = useTranslation("common");

    const changeRole = (role: { label: string; value: string }) => {
        setError(false);
        setRoles((prevState: { label: string; value: string }[]) => {
            let newState = prevState.length > 0 ? [...prevState] : [];
            if (!!prevState.find(item => item.value === role.value)) {
                newState = newState.filter(item => item.value !== role.value);
            } else {
                newState.push(role);
            }
            return newState;
        });
    };

    const [error, setError] = useState(false);

    function onSubmit(data: any) {
        setError(false);
        data.organisationID = userCTX!.user!.organisation.id;
        if (roles.length === 0) {
            setError(true);
            return;
        }
        if (data.birthDate === "" || data.birthDate === undefined) {
            data.birthDate = null;
        }
        data.position = roles[0].label;
        data.role = roles[0].value;
        data.roles = roles.map(item => item.value);
        setIsLoading(true);
        req
            .post("/superadmin/user/create?inviteBy=BY_EMAIL", data)
            .then(() => {
                interfaceCTX.showMessage({
                    body: "Пользователь успешно создан",
                    icon: "done"
                });
                history.push({
                    pathname: "/users",
                    state: [{ path: "/users", url: "/users", title: "Пользователи" }]
                });
                setIsLoading(false);
            })
            // .then(() => history.go(0))
            .catch(e => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t(
                        e.response.data.includes("User with phone")
                            ? "Пользователь с таким номера телефона уже существует"
                            : e.response.data
                    )
                });
                setIsLoading(false);
            });
    }

    return (
        <div className="create-user-page">
            <PageTitle
                title={"Создать пользователя"}
                // back={"/users"}
                breadcrumbs={true}
            >
                {/*{userCTX.userHasRole('admin') &&*/}
                {/*    <Link to={{*/}
                {/*        pathname: PagesRoutes.USERS,*/}
                {/*        state: history.location.state*/}
                {/*    }} className="go-to-users">*/}
                {/*        {"Пользователи организации"}*/}
                {/*    </Link>*/}
                {/*}*/}
            </PageTitle>
            <Form
                name="createUserPage"
                // validateMode={'onSubmit'}
                viewMode={false}
                onSubmit={onSubmit}
                useRef={createUserForm}
                data={[
                    [
                        {
                            // title: 'Создать пользователя',
                            values: [
                                {
                                    title: "Фамилия",
                                    var: "lastName",
                                    // defaultValue: "",
                                    validate: {
                                        required: true,
                                        maxLength: 30,
                                        pattern: interfaceCTX.fioValidationPattern
                                    },
                                    errors: {
                                        maxLength: "Не более 30 символов",
                                        pattern: `Только кириллица, знаки "-", "_" и пробел`
                                    }
                                },
                                {
                                    title: "Имя",
                                    var: "firstName",
                                    // defaultValue: "",
                                    validate: {
                                        required: true,
                                        maxLength: 30,
                                        pattern: interfaceCTX.fioValidationPattern
                                    },
                                    errors: {
                                        maxLength: "Не более 30 символов",
                                        pattern: `Только кириллица, знаки "-", "_" и пробел`
                                    }
                                },
                                {
                                    title: "Отчество",
                                    var: "middleName",
                                    // defaultValue: "",
                                    validate: {
                                        required: false,
                                        maxLength: 30,
                                        pattern: interfaceCTX.fioValidationPattern
                                    },
                                    errors: {
                                        maxLength: "Не более 30 символов",
                                        pattern: `Только кириллица, знаки "-", "_" и пробел`
                                    }
                                },
                                {
                                    type: "date",
                                    title: "Дата рождения",
                                    var: "birthDate",
                                    validate: { required: false, validate: checkInputedAge },
                                    errors: {
                                        pattern: "Введите дату в формате ДД.ММ.ГГГГ",
                                        validate: "Допустимый возраст от 18 до 65 лет"
                                    }
                                }
                            ]
                        },
                        {
                            // title: 'Создать пользователя',
                            values: [
                                {
                                    title: "Контактный e-mail",
                                    var: "email",
                                    // defaultValue: "",
                                    validate: {
                                        required: true,
                                        maxLength: 60,
                                        pattern: interfaceCTX.emailValidationPattern
                                    },
                                    errors: {
                                        pattern: "Введите валидную почту",
                                        maxLength: "Введите не больше 60 символов"
                                    }
                                },
                                {
                                    type: "phone",
                                    title: "Номер телефона",
                                    caption: "+ 7 (9XX) XXX XX XX",
                                    var: "phone",
                                    // defaultValue: "",
                                    validate: {
                                        required: true
                                    },
                                    errors: {
                                        pattern: "Введите номер в формате + 7 (9XX) XXX XX XX"
                                    }
                                },
                                // {
                                //     type: 'select', title: 'Роль', var: 'role',
                                //     options: [
                                //         { label: 'Прораб', value: 'foreman' },
                                //         { label: 'Кадровик', value: 'hr' },
                                //         { label: 'Организатор', value: 'organizer' },
                                //         { label: 'Админ', value: 'admin' }
                                //     ],
                                //     validate: { required: true }
                                // },
                                {
                                    type: "other",
                                    value: () => (
                                        <div className="create-user-page-roles-settings">
                                            <div className="create-user-page-roles-header">Роль</div>
                                            <div className="create-user-page-roles">
                                                <div className="create-user-page-role">
                                                    <Checkbox
                                                        checked={!!roles.find(item => item.value === "admin")}
                                                        onChange={() => {
                                                            changeRole({ label: "Админ", value: "admin" });
                                                        }}
                                                    />
                                                    <div>Админ</div>
                                                </div>
                                                <div className="create-user-page-role">
                                                    <Checkbox
                                                        checked={!!roles.find(item => item.value === "recruiter")}
                                                        onChange={() => {
                                                            changeRole({ label: "Рекрутер", value: "recruiter" });
                                                        }}
                                                    />
                                                    <div>Рекрутер</div>
                                                </div>
                                                <div
                                                    className={`create-user-page-role${userCTX.user?.organisation.modulesStatus.hr !== "active" ? " create-user-page-role-off" : ""}`}>
                                                    {userCTX.user?.organisation.modulesStatus.hr !== "active" ?
                                                        <Icon icon={"lock"} className={"lock"} />
                                                        : <Checkbox
                                                            checked={!!roles.find(item => item.value === "hr")}
                                                            onChange={() => {
                                                                changeRole({ label: "Кадровик", value: "hr" });
                                                            }}
                                                        />
                                                    }
                                                    {userCTX.user?.organisation.modulesStatus.hr === "active"
                                                        ? <div>Кадровик</div>
                                                        : <RenderModuleNotPaid moduleName={"hr"}/>
                                                    }
                                                </div>
                                                <div
                                                    className={`create-user-page-role${userCTX.user?.organisation.modulesStatus.organizer !== "active" ? " create-user-page-role-off" : ""}`}>
                                                    {userCTX.user?.organisation.modulesStatus.organizer !== "active" ?
                                                        <Icon icon={"lock"} className={"lock"} />
                                                        : <Checkbox
                                                            checked={!!roles.find(item => item.value === "organizer")}
                                                            onChange={() => {
                                                                changeRole({
                                                                    label: "Организатор",
                                                                    value: "organizer"
                                                                });
                                                            }}
                                                            disabled={userCTX.user?.organisation.modulesStatus.organizer !== "active"}
                                                        />
                                                    }
                                                    {userCTX.user?.organisation.modulesStatus.organizer === "active"
                                                        ? <div>Организатор</div>
                                                        : <RenderModuleNotPaid moduleName={"organizer"}/>
                                                    }
                                                </div>
                                                <div
                                                    className={`create-user-page-role${userCTX.user?.organisation.modulesStatus.foreman !== "active" ? " create-user-page-role-off" : ""}`}>
                                                    {userCTX.user?.organisation.modulesStatus.foreman !== "active" ?
                                                        <Icon icon={"lock"} className={"lock"} />
                                                        : <Checkbox
                                                            checked={!!roles.find(item => item.value === "foreman")}
                                                            onChange={() => {
                                                                changeRole({ label: "Прораб", value: "foreman" });
                                                            }}
                                                            disabled={userCTX.user?.organisation.modulesStatus.foreman !== "active"}
                                                        />
                                                    }
                                                    {userCTX.user?.organisation.modulesStatus.foreman === "active"
                                                        ? <div>Прораб</div>
                                                        : <RenderModuleNotPaid moduleName={"foreman"}/>
                                                    }
                                                </div>
                                            </div>
                                            {error && <div className="create-user-page-roles-error">Выберите роль</div>}
                                        </div>
                                    )
                                }
                            ]
                        }
                    ]
                ]}
            />
            <FixedButtons
                length={4}
                buttons={[
                    {
                        label: "Создать пользователя",
                        primary: true,
                        onClick: () =>
                            createUserForm.current.dispatchEvent(
                                new Event("submit", {
                                    cancelable: true,
                                    bubbles: true
                                })
                            ),
                        disabled: isLoading
                    }
                ]}
            />
        </div>
    );
}
