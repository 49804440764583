import React from 'react'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { IBreadcrumbsLocationState } from '../../../custom-hooks/useBreadcrumbs'

export interface ICrumbProps {
	className?: string
	path: string
	title: string
	url: string
}

export const removeRemainingCrumbs = (state: IBreadcrumbsLocationState[], url: string): IBreadcrumbsLocationState[] => {
	const index = state.findIndex(({ url: route }) => route === url)
	// if(index.ts === state.length)
	return state.slice(0, index)
}

export const Crumb: React.FC<ICrumbProps> = ({ className, path, title, url }) => {
	const history = useHistory()
	const { state } = useLocation<IBreadcrumbsLocationState[]>()
	const match = useRouteMatch(path)

	const routeTo = (event: any) => {
		event.preventDefault()
		history.replace({
			pathname: url,
			state: removeRemainingCrumbs(state, url)
		})
	}

	return (
		<>
			{match && match.isExact ? (
				<div style={{ display: 'flex', margin: '2px 0' }}>
					<h2>{title}</h2>
				</div>
			) : (
				<div style={{ display: 'flex', margin: '2px 0' }}>
					<Link
						to={{
							pathname: url,
							state: removeRemainingCrumbs(state, url)
						}}
					>
						<h2 style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={routeTo}>
							{title}
						</h2>
					</Link>
					<h2 style={{ margin: '0 8px' }}>/</h2>
				</div>
			)}
		</>
	)
}
