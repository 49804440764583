import {Icon} from "@rmwc/icon";
import {IRaiting} from "./Raiting.types";
import './Raiting.style.sass'

export function Raiting({
    value, withNumber
}: IRaiting) {
    const raiting = Math.round(value * 2) / 2
    const stars = Array(5)
        .fill(undefined)
        .map((_, i) =>
            i < Math.floor(raiting) ? 'star' :
            i >= Math.ceil(raiting) ? 'star_outline' : 'star_half'
        )

    return (
        <div className={'raiting'}>
            {stars.map((icon, index) => (
                <Icon
                    key={'raiting-star-' + index}
                    icon={{icon: icon, size: 'xsmall'}}
                />
            ))}
            {withNumber &&
                <span className={'raiting-value'}>
                    {value}
                </span>
            }
        </div>
    )
}