import React, {useContext, useEffect, useState} from "react";
import {stepsData} from "./Instruction.service";
import {InterfaceContext} from "../../global/context.interface";
import {IInstructionState} from "./Instruction.types";
import "./Instruction.style.sass"
import {useHistory} from "react-router";
import {UserContext} from "../../global/context.user";
import {req} from "../../global/common";
import {Radio} from "@rmwc/radio";
import {sendMyTrackerMetric} from "../../global/metrics";


export const Instruction: React.FC = () => {
    const [steps, setSteps] = useState<typeof stepsData>([])
    const [instructionState, setInstructionState] = useState<IInstructionState>({
        step: 0,
        stepValue: null,
    } as IInstructionState)
    const interfaceCTX = useContext(InterfaceContext)
    const userCTX = useContext(UserContext)
    const history = useHistory()

    const closeInstruction = () => {
        req.post('/profile/training-complete')
            .then(() => {
                setInstructionState((prevState: IInstructionState) => ({
                    ...prevState,
                    step: 0
                }))
                interfaceCTX.setInstructionValue(false, -1)
            })
    }

    const closeInstructionConfirm = () => {
        if(instructionState.step === steps.length - 1) {
            closeInstruction()
        } else if (window.confirm('Вы уверены, что хотите пропустить туториал?')) {
            closeInstruction()
            history.push({
                pathname: '/profile',
                state: [{path: '/profile', url: '/profile', title: 'Профиль пользователя'}]
            })
        }
    }

    const sendStepInformation = (stepName: string, stepValue: null | boolean) => {
        req.post('/organisation/update-training', {[stepName]: stepValue})
            .then(() => {

            })
    }

    useEffect(() => {
        const currentSteps = stepsData.filter((item) => item.accessRoles.includes(userCTX.user?.currentRole as string))
        if (currentSteps.length === 0) {
            interfaceCTX.setInstructionValue(false, -1)
        } else {
            setSteps(currentSteps)
            if (history.location.pathname !== currentSteps[0].path && interfaceCTX.instructionValue.isOpen) {
                history.push({
                    pathname: currentSteps[0].path,
                    state: [{path: currentSteps[0].path, url: currentSteps[0].path, title: currentSteps[0].pageTitle}]
                })
            }
        }

    }, [])

    if (steps.length === 0 || !interfaceCTX.instructionValue.isOpen) return null

    return (
        <div className='instruction-component'>
            <div className='instruction-dialog'>
                <div>
                    <div className={'instruction-dialog-title'}>
                        <div>{"Обучение шаг " + (instructionState.step + 1) + " из " + steps.length}</div>
                    </div>
                    <div className={'instruction-dialog-content'}>
                        <div className={'instruction-step'}>
                            <div className={'instruction-step-title'}>
                                {steps[instructionState.step].stepTitle}
                            </div>
                            <ul className={'instruction-step-list'}>
                                {steps[instructionState.step].stepLIstItems.map((item: string) => (
                                    <li className={'instruction-step-list-item'} key={item}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                            <div className={'instruction-check'}>
                                <div className={'instruction-check-title'}>
                                    Была ли Вам полезна эта информация?
                                </div>
                                <div className={'instruction-check-variants'}>
                                    <div className={'instruction-check-variant'}>
                                        <Radio
                                            className={'instruction-check-radio'}
                                            checked={instructionState.stepValue === true}
                                            onClick={() => {
                                                setInstructionState( (prevState: IInstructionState) => ({
                                                    ...prevState,
                                                    stepValue: true
                                                }))
                                            }}
                                        />
                                        <div className={'instruction-check-radio-label'}>
                                            Да
                                        </div>
                                    </div>
                                    <div className={'instruction-check-variant'}>
                                        <Radio
                                            className={'instruction-check-radio'}
                                            checked={instructionState.stepValue === false}
                                            onClick={() => {
                                                setInstructionState( (prevState: IInstructionState) => ({
                                                    ...prevState,
                                                    stepValue: false
                                                }))
                                            }}
                                        />
                                        <div className={'instruction-check-radio-label'}>
                                            Нет
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'instruction-dialog-btns'}>
                        <div className={'instruction-dialog-btn-underlined'}
                             onClick={() => {
                                 closeInstructionConfirm()
                             }}
                        >
                            Пропустить все шаги
                        </div>
                        <div className={'instruction-dialog-btn'}
                             onClick={() => {
                                 if (instructionState.step < steps.length - 1) {
                                     const nextStep = steps[instructionState.step + 1]
                                     sendStepInformation(steps[instructionState.step].stepName, instructionState.stepValue)
                                     setInstructionState((prevState: IInstructionState) => {
                                         interfaceCTX.setInstructionValue(true, prevState.step + 1)
                                         return {
                                             stepValue: null,
                                             step: prevState.step + 1
                                         }
                                     })
                                     history.push({
                                         pathname: nextStep.path === '/public-profile/' ? `/public-profile/${userCTX.user?.organisation.id}/redact` : nextStep.path,
                                         state: [{path: nextStep.path, url: nextStep.path, title: nextStep.pageTitle}]
                                     })
                                 } else {
                                     sendStepInformation(steps[instructionState.step].stepName, instructionState.stepValue)
                                     closeInstructionConfirm()
                                 }
                             }}>
                            Далее
                        </div>
                    </div>
                </div>

            </div>
            <div className='instruction-dialog-scrim'
                 onClick={(e) => {
                     e.preventDefault()
                     e.stopPropagation()
                     closeInstructionConfirm()
                 }}
            />
        </div>

    )
}

