import { Checkbox } from '@rmwc/checkbox'
import '@rmwc/checkbox/styles'
import { SimpleDialog } from '@rmwc/dialog'
import { Elevation } from '@rmwc/elevation'
import { Icon } from '@rmwc/icon'
import { TextField } from '@rmwc/textfield'
import { PageTitle, FixedButtons } from 'components'
import { ProgressPage } from 'components'
import { req } from 'global/common'
import { InterfaceContext } from 'global/context.interface'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useHistory } from 'react-router-dom'
import { IMobilizeTemplateStep } from './EditTemplateSubpage.types'
import './EditTemplateSubpage.style.sass'

export function EditTemplateSubpage() {

    const history = useHistory()

    let _location = history.location.pathname.includes('demobilization')

    let isMobilizeTemplatePage = !history.location.pathname.includes('demobilization')

    const interfaceCTX = React.useContext(InterfaceContext)

    const [template, setTemplate] = React.useState(null as unknown as IMobilizeTemplateStep[])
    const [newTypeValue, setNewTypeValue] = React.useState({} as any)
    const [openDialog, setOpenDialog] = React.useState({
        delete: false,
        defaultTemplate: false
    })

    React.useEffect(() => {
        getTemplate()
        return () => {
            interfaceCTX.resetIsFormChanged()
        }
    }, [])

    function getTemplate() {
        let query = {
            type: isMobilizeTemplatePage ? 'mobilization' : 'demobilization',
        }
        req.get(`/admin/mobilization/get-template`, query)
            .then(({data}) => data.data.map((el: IMobilizeTemplateStep, index: number) => {
                let step = {
                    id: String(Math.random()),
                    type: el.type,
                    markedToDelete: false,
                    editMode: false
                }
                return step
            }))
            .then((data) => {
                setTemplate(data);
                setNewTypeValue(() => {
                    let newValues = {} as any
                    data.forEach((el: any) =>
                        newValues[el.id] = el.type
                    )
                    return newValues
                })
            })
    }

    function updateTemplate(stepsArr: IMobilizeTemplateStep[]) {
        let newTemplate = stepsArr.map((el: IMobilizeTemplateStep) => el.type)
        let body = Object.assign({data: newTemplate})
        req.post(`/admin/mobilization/update-template?type=${isMobilizeTemplatePage ? 'mobilization' : 'demobilization'}`, body)
            .then(() => {
                setTemplate(stepsArr)
                interfaceCTX.resetIsFormChanged()
            })
            .then(() => interfaceCTX.showMessage({
                body: 'Данные успешно сохранены',
                icon: 'done'
            }))
            .catch(() => interfaceCTX.showMessage({
                body: 'Не удалось сохранить. Обратитесь к администратору',
                icon: 'report'
            }))
    }

    function addNewStep() {
        interfaceCTX.checkIsFormChanged(true, 'settings')
        let newStep = {
            id: String(Math.random()),
            type: "Новое действие",
            markedToDelete: false,
            editMode: true,
        }

        let newArr = JSON.parse(JSON.stringify(template))

        newArr.push(newStep)

        setTemplate(newArr)
        setNewTypeValue({
            ...newTypeValue,
            [newStep.id]: "Новое действие"
        })
    }

    function deleteMarkedSteps() {
        interfaceCTX.checkIsFormChanged(true, 'settings')
        let unMarkedSteps = template.filter(step => step.markedToDelete == false)
        let stepsInEditMode = template.filter(step => step.editMode == true)
        if (template.length != unMarkedSteps.length && stepsInEditMode.length == 0) {
            updateTemplate(unMarkedSteps)
        }
    }

    function checkTemlateBeforeDelete() {
        let unMarkedSteps = template.filter(step => step.markedToDelete == false)
        let stepsInEditMode = template.filter(step => step.editMode == true)
        if (template.length == unMarkedSteps.length) {
            interfaceCTX.showMessage({
                body: 'Отметьте действия к удалению',
                icon: 'report'
            })
        } else if (stepsInEditMode.length > 0) {
            interfaceCTX.showMessage({
                body: 'Сохраните новые действия',
                icon: 'report'
            })
        } else {
            setOpenDialog({
                ...openDialog,
                delete: true
            })
        }
    }

    function addEditMode(elIndex: number) {
        let newArr = JSON.parse(JSON.stringify(template))
        newArr[elIndex].editMode = true
        setTemplate(newArr)
    }

    function defaultTemplate() {
        req.post(`/admin/mobilization/reset-template?type=${isMobilizeTemplatePage ? 'mobilization' : 'demobilization'}`)
            .then(() => getTemplate())
            .catch(() => interfaceCTX.showMessage({
                body: 'Восстановить по умолчанию не удалось. Обратитесь к администратору',
                icon: 'report'
            }))
    }

    function saveSteps(titleArr: string[]) {
        let newTemplate = titleArr;

        let isAnyEmplyValue = newTemplate.filter(el => el == "")

        if (isAnyEmplyValue.length > 0) {
            interfaceCTX.showMessage({
                body: 'Заполите пустые поля',
                icon: 'report'
            })
        } else {
            let target = template.map(step => {
                step.editMode = false;
                step.type = newTypeValue[step.id];
                return step
            })


            updateTemplate(target)
        }

    }

    let steps = template && template.map((step, index) => {

        return (
            <Draggable draggableId={`${step.id}`} index={index} key={step.id}>
                {(provided) => (
                    <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}
                        key={step.type + '_' + index + step.id} className="row mobilize-step">
                        <div
                            className="order cell"
                        >
                            {index + 1}
                        </div>
                        <div className="checkbox plan cell">

                            <Checkbox
                                checked={step.markedToDelete}
                                onChange={(e) => {
                                    interfaceCTX.checkIsFormChanged(true, 'settings')
                                    setTemplate((state: any) => state.map((el: any) => {
                                        if (step.type == el.type) {
                                            step.markedToDelete = !!e.currentTarget.checked
                                        }
                                        return el;
                                    }))
                                }}
                            />
                        </div>
                        <div onDoubleClick={() => addEditMode(index)} className="step-title cell">
                            {step.editMode
                                ?
                                <TextField
                                    maxLength={125}
                                    outlined
                                    className={'input'}
                                    label={"Введите новое значение"}
                                    defaultValue={step.type}
                                    onChange={(e) => {
                                        interfaceCTX.checkIsFormChanged(true, 'settings')
                                        if ((e.target as HTMLInputElement).value.length == 125) {
                                            interfaceCTX.showMessage({
                                                body: 'Вы ввели предельное допустимое число символов',
                                                icon: 'report'
                                            })
                                        }
                                        setNewTypeValue({
                                            ...newTypeValue,
                                            [step.id]: (e.target as HTMLInputElement).value
                                        })

                                    }}

                                />
                                :
                                step.type
                            }

                        </div>
                        <div
                            className="dnd-picker cell"
                        >
                            <Icon icon={"menu"}/>
                        </div>
                    </li>
                )}
            </Draggable>
        )
    })

    return (
        <div className="edit-mobilize-template-subpage">
            <PageTitle
                title={''}
                // back={'/mobilization'}
            >
                <div className='default-template-btn' onClick={() => setOpenDialog({
                    ...openDialog,
                    defaultTemplate: true
                })}>
                    Шаблон по умолчанию
                </div>

            </PageTitle>
            <ProgressPage
                state={template}
                render={() => (
                    <div className="edit-mobilize-template-inner">
                        <div className='mobilize-checkbox-list-wrapper'>
                            <ul className="mobilize-checkbox-list">
                                <li className="headings row">
                                    <div className="heading cell">
                                        Номер
                                    </div>

                                    <div className="heading cell">
                                        Выбранное
                                    </div>

                                    <div className="heading cell">
                                        Действия, подлежащие проверке
                                    </div>
                                    <div className="heading cell">

                                    </div>
                                </li>
                                <DragDropContext
                                    onDragStart={(initial) => {
                                        const {draggableId} = initial
                                        let newTemplate = [...template]
                                        newTemplate.map(v => {
                                            if (v.id === draggableId) {
                                                v.type = newTypeValue[draggableId]
                                            }
                                            return v
                                        })
                                        setTemplate(newTemplate)
                                    }}
                                    onDragEnd={(result) => {
                                        interfaceCTX.checkIsFormChanged(true, 'settings')
                                        const {destination, source, draggableId} = result

                                        if (!destination) {
                                            return
                                        }

                                        if (
                                            destination.droppableId === source.droppableId &&
                                            destination.index === source.index
                                        ) {
                                            return
                                        }

                                        const newTemplateOrder = [...template]
                                        // let draggedItem = {...newTemplateOrder[source.index.ts]}
                                        newTemplateOrder.splice(source.index, 1)
                                        newTemplateOrder.splice(destination.index, 0, template.filter(v => v.id == draggableId)[0])
                                        setTemplate(newTemplateOrder)
                                    }}
                                >
                                    <Droppable droppableId={"1"}>
                                        {(provided) => (
                                            <Elevation
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                {steps}

                                                {provided.placeholder}
                                            </Elevation>
                                        )}

                                    </Droppable>

                                </DragDropContext>
                            </ul>
                        </div>
                        <FixedButtons length={4}
                                      buttons={[
                                          {
                                              label: 'Сохранить изменения',
                                              primary: true,
                                              onClick: () => saveSteps(Object.values(newTypeValue))
                                          },
                                          {
                                              label: 'Добавить действие',
                                              primary: false,
                                              onClick: () => addNewStep()
                                          },
                                          {
                                              label: 'Удалить выбранное',
                                              primary: false,
                                              onClick: () => checkTemlateBeforeDelete()
                                          },

                                      ]}

                        />
                    </div>
                )}
            />
            <SimpleDialog
                open={openDialog.defaultTemplate}
                body={`Все введенные ранее действия будут удалены.
                        Вы действительно хотите восстановить шаблон по умолчанию?`}
                cancelLabel={'Нет'}
                acceptLabel={'Да'}
                onClosed={(e) => {
                    if (e.detail.action === 'accept') {
                        defaultTemplate()
                    }
                    setOpenDialog({
                        ...openDialog,
                        defaultTemplate: false
                    })
                }}
            />
            <SimpleDialog
                open={openDialog.delete}
                body={`Вы действительно хотите удалить отмеченные действия`}
                cancelLabel={'Нет'}
                acceptLabel={'Да'}
                onClosed={(e) => {
                    if (e.detail.action === 'accept') {
                        deleteMarkedSteps()
                    }
                    setOpenDialog({
                        ...openDialog,
                        delete: false
                    })
                    // setOpenDialog(false)
                }}
            />
        </div>
    )
}
