import clsx from 'clsx'
import { forwardRef, MutableRefObject } from 'react'
import { default as Select, GroupBase, Props, SelectInstance } from 'react-select'
import { SelectComponentType } from './FormSelect.types'
import { getSelectStyles } from './FormSelect.setvice'
import './FormSelect.style.sass'

export const FormSelect = forwardRef(
	<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
		{ className, styles, title, components, ...rest }: Props<Option, IsMulti, Group>,
		ref:
			| ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
			| MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
			| null
	): JSX.Element => {
		return (
			<div className={clsx('form-select', className)}>
				{title && <div className='form-field-title'>{title}</div>}
				<Select
					ref={ref}
					noOptionsMessage={() => 'Нет опций'}
					styles={{ ...getSelectStyles<Option, IsMulti, Group>(), ...styles }}
					components={{ ...components, IndicatorSeparator: () => null }}
					{...rest}
				/>
			</div>
		)
	}
) as SelectComponentType
