import "./messages-page.sass";
import { PageTitle } from "components";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ChatContext } from "../../global/context.chat";
import { IChatPage, IRoomMeta } from "./messages-page-def";
import { Button } from "@rmwc/button";
import { Elevation } from "@rmwc/elevation";
import { MatrixClient, PushRuleActionName, Room } from "matrix-js-sdk";
import { Rooms } from "./components/rooms/rooms";
import { useHistory } from "react-router-dom";
import { PushRuleKind } from "matrix-js-sdk/lib/@types/PushRules";
import { PushRuleAction, PushRuleCondition, RuleId } from "matrix-js-sdk/src/@types/PushRules";
import { Checkbox } from "@rmwc/checkbox";
import { useCustomEventListener } from "react-custom-events";
import { CircularProgress } from "@rmwc/circular-progress";
//@ts-ignore
import audio from "../../misc/audio/message.mp3";
import { parsedRoomsData } from "./messages-page-utils";
import { isSuperAdm } from "../../global/common";
import { UserContext } from "../../global/context.user";
import moderBlock from "../../misc/moderBlock.png"
import chatBan from "../../misc/chatBan.png"

export const MessagesPage: React.FC<IChatPage> = () => {
    const { client, isConnected, notificationSettings, setNotificationSettings, rooms } = useContext(ChatContext);
    const userCTX = React.useContext(UserContext);
    const history = useHistory();
    const [roomsList, setRoomsList] = useState<IRoomMeta[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setRoomsList(rooms);
    }, [isConnected, rooms]);

    const getArchivedRooms = (rooms: IRoomMeta[]) => {
        return rooms.length > 0 ? rooms.filter(item => item.isArchiveOrder) : [];
    };

    const getIgnoredRooms = (rooms: IRoomMeta[]) => {
        return rooms.length > 0 ? rooms.filter(item => item.isIgnored) : [];
    };

    const getAllRooms = (rooms: IRoomMeta[]) => {
        return rooms.length > 0 ? rooms.filter(item => !item.isIgnored && !item.isArchiveOrder) : [];
    };

    if (isSuperAdm(userCTX.user)) {
        return (
            <div className="messages-page">
                <div className={"screen-locked"}>
                    <img src={moderBlock}/>
                    <div className={"screen-locked-text"}>
                        Модератор не может писать пользователям.
                    </div>
                </div>
            </div>
        );
    }

    else if (userCTX.user?.organisation.confirmed === "rejected") {
        return (
            <div className="messages-page">
                <div className={"screen-locked"}>
                    <img src={chatBan}/>
                    <div className={"screen-locked-text"}>
                        Модерация не пройдена. Ожидайте.
                    </div>
                </div>
            </div>
        );
    }

    else return (
        <div className="messages-page">
            {loading &&
                <CircularProgress style={{ width: "150px", height: "150px" }}
                                  className={` ${loading && "circular-progress--center "}`} />
            }

            {!loading && history.location.pathname === "/messages/archive" &&
                <>
                    <div className="messages-page-header">
                        <PageTitle
                            title={"Архив сообщений"}
                            back={() => history.goBack()}
                        />
                    </div>
                    <Elevation z={4} className={`control-panel`}>
                        <div className="messages-page-list">
                            <Rooms list={getArchivedRooms(roomsList)} />
                        </div>
                    </Elevation>
                </>
            }
            {!loading && history.location.pathname === "/messages/ignored" &&
                <>
                    <div className="messages-page-header">
                        <PageTitle
                            title={"Черный список"}
                            back={() => history.goBack()}
                        />
                    </div>
                    <Elevation z={4} className={`control-panel`}>
                        <div className="messages-page-list">
                            <Rooms list={getIgnoredRooms(roomsList)} />
                        </div>
                    </Elevation>
                </>
            }
            {!loading && history.location.pathname === "/messages" &&
                <>
                    <div className="messages-page-header">
                        <PageTitle
                            title={"Чат"}
                            breadcrumbs={true}
                        />
                        <div style={{
                            width: "660px",
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <div className="messages-page-checkbox">
                                <Checkbox
                                    checked={notificationSettings}
                                    onChange={(e) => {
                                        //@ts-ignore
                                        client?.setPushRuleEnabled("global", "override" as PushRuleKind, ".m.rule.master", notificationSettings, (err, data) => {
                                            setNotificationSettings(!notificationSettings);
                                        });
                                    }}
                                />
                                <div className="messages-page-checkbox-text">
                                    Получать звуковые сообщения
                                </div>
                            </div>
                            <Button className=""
                                    disabled={false}
                                    raised={true}
                                    onClick={() => {
                                        history.push("/messages/archive");
                                    }}>

                                {"Архив"}
                            </Button>
                            <Button className=""
                                    disabled={false}
                                    raised={true}
                                    onClick={() => {
                                        history.push("/messages/ignored");
                                    }}>
                                {"Черный список"}
                            </Button>
                        </div>
                    </div>
                    <Elevation z={4} className={`control-panel`}>
                        <div className="messages-page-list">
                            <Rooms list={getAllRooms(roomsList)} />
                        </div>
                    </Elevation>
                </>

            }
        </div>
    );
};