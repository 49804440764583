import { IPeriodProps } from "./Period.types";
import './Period.style.sass'

export function Period(props: IPeriodProps) {
    return (
        <div className={`period ${props.className}`} >
            {!props.start && !props.end && !props.unlimited
                ?
                <div className="text no-dates">
                    Не установлен
                </div>
                :
                <>
                    {props.start
                        ?
                        <div className="text">
                            {props.start}
                        </div>
                        :
                        <div className="text">

                        </div>
                    }
                    {props.unlimited
                        ?
                        <div className="text">
                            {"Бессрочно"}
                        </div>
                        :
                        props.end &&
                        <div className="text">
                            {props.end}
                        </div>
                    }
                </>
            }
        </div>
    )
}