import { UserOrganisation, WorkerProfile } from ".";
import { UserRating } from "./user";
import { PermissionData, ProfessionData, ProfileAddress } from "./worker";

export type { Author };
export { User };

interface Author {
    id: number;
    avatar: string | null;
    contactEmail: string;
    contactPhone: string;
    firstName: string;
    lastName: string;
    middleName: string;
    organisation: UserOrganisation;
    roles: Role[];
    isDeleted: boolean;
}

class User {
    id: number;
    roles: Role[];
    firstName: string;
    lastName: string;
    middleName: string;
    currentRole?: string;
    organisation: UserOrganisation;
    phone: string;
    email: string | null;
    contactPhone: string | null;
    contactEmail: string | null;
    workerProfile: WorkerProfile | null;
    superAdmin: boolean;
    allRoles: Role[];
    chatCredentials: string;
    trainingCompleted: boolean;

    constructor(data: any) {
        this.id = data.id;
        this.roles = [data.currentRole ? data.currentRole : data.roles[0] ? data.roles[0] : "pre-worker"];
        this.firstName = data.firstName;
        this.middleName = data.middleName;
        this.lastName = data.lastName;
        this.organisation = data.organisation;
        this.phone = data.phone;
        this.email = data.email;
        this.currentRole = data.currentRole;
        this.contactPhone = data.contactPhone;
        this.contactEmail = data.contactEmail;
        this.workerProfile = data.workerProfile;
        this.superAdmin = data.superAdmin;
        this.allRoles = data.roles;
        this.chatCredentials = data.chatCredentials;
        this.trainingCompleted = data.trainingCompleted;
    }
}

export type Role = "worker" | "foreman" | "admin" | "hr" | "organizer" | "pre-worker" | "moderator" | "recruiter"

export interface UserProfile {
    actualAddress: {
        address: string
        index: string
    };
    anthropometry: {
        contractId: number | null
        bustGirth: null | number
        headGirth: null | number
        height: null | number
        hipsGirth: null | number
        waistGirth: null | number
        shoeSize: null | number
        gender?: "male" | "female" | null
    };
    avatar: {
        id: number
        path: string
        type: string
        pathPreview: string
    } | null;
    documents: {
        inn: string
        media:
            | {
            id: number
            path: string
            pathPreview: string
            type: string
        }[]
            | null
        passport: {
            date: string
            issuedBy: string
            number: string
            series: string
            subdivisionCode: string | null
        }
        snils: string
    };
    withoutExperience: null | boolean;
    experience:
        | {
        expMonths: number
        expYears: number
        production: string
        type: string
        unit: string
    }[]
        | null;
    grade: string;
    loyaltyStats: {
        total: number
    };
    workerLocation: {
        countryId: number | null
        countryName: string | null
        regionId: number | null
        regionName: string | null
    };
    mainProfession: {
        code: number
        id: number
        name: string
    } | null;
    permissionDocuments: {
        date: string
        id: number
        number: string
        path: string
    }[];
    permissions: PermissionData[] | null;
    personalInfo: {
        about: string | null
        birthDate: string
        citizenship: string
        contactEmail: string
        contactPhone: string
        contactEmailConfirmed?: boolean
        firstName: string
        lastName: string
        middleName: string
        workSearchSchedule: string | null
        workSearchStatus: string | null
    };
    qualificationDocuments: {
        date: string
        id: number
        number: string
        path: string
    }[];
    registrationAddress: ProfileAddress;
    subProfessions: ProfessionData[] | null;
    rating: UserRating;
    isDeleted: boolean;
    chatCredentials: string;
    deletedReason: string;
}

export interface PartialRequest {
    id: number;
    number: string;
    position: string;
    subdivision: string;
    expectedHiringDate: string;
    author: Author;
}

export interface Worker {
    demobilized: boolean;
    profile: Omit<Author, "avatar"> & {
        avatar: {
            id: number
            path: string
            type: string
        } | null
    };
    recordID: number;
    request: PartialRequest;
    workFrom: string;
    workTo: string;
    workUnlimited: boolean;
    position: string;
    subdivision: string;
    isDeleted: boolean;
}

export interface BrigadeAPI {
    brigade: { id: number; name: string; foreman: Author } | null;
    objects: {
        address: string
        dateEnd: string
        dateStart: string
        id: number
        name: string
    }[];
    workers: Worker[];
}

export interface Option {
    label: string;
    value: string;
}

export interface LocationState {
    path: string;
    url: string;
    title: string;
}
