export const columns = [
    {
        id: 0,
        title: "Выбрать",
        minWidth: '200px',
        sortType: 'NONE',
        fieldName: 'select'
    },
    {
        id: 1,
        title: "Профессия",
        minWidth: '250px',
        sortType: 'NONE',
        fieldName: 'professionName'
    },
    {
        id: 2,
        title: "Код профессии",
        minWidth: '250px',
        sortType: 'NONE',
        fieldName: 'professionCode'
    },
]
