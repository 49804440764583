import './documents-component.sass'
import React, {useContext} from 'react'
import {Props} from './documents-component-def'
import {Icon} from '@rmwc/icon'
import {InterfaceContext} from '../../../../global/context.interface'
import noScanIcon from '../../../../misc/no-document-scan-icon.svg'
import {EAcceptValues, IAcceptChangeResponse} from "../../dump/dump";
import {UserContext} from "../../../../global/context.user";

export function Documents({
                              docName,
                              docNumber,
                              docRegNum,
                              issueDate,
                              pathPreview,
                              path,
                              isDocumentStatus,
                              updateDocumentStatus,
                              documentType,
                              isVisibleProfileUpdate
                          }: Props) {

    let context = React.useContext(InterfaceContext);
    const userCTX = useContext(UserContext)

    let previewWrapperStyle = {}

    if (pathPreview) {
        previewWrapperStyle = {
            backgroundImage: `url('${pathPreview}')`,
        }
    } else {
        previewWrapperStyle = {
            backgroundImage: `url('${noScanIcon}')`,
            backgroundSize: '53px',
            backgroundColor: '#F5F5F8',
            cursor: 'default'
        }
    }

    return (
        <div className="document-wrapper">
            <div className="document-inner">
                {isVisibleProfileUpdate &&
                    <Icon className='edit_note'
                          icon={"edit_note"}/>}
                {docNumber &&
                    <div className="text">{docNumber}</div>
                }
                {issueDate &&
                    <div className="info-wrapper">
                        <div className="title">Дата выдачи</div>
                        <div className="text">{issueDate}</div>
                    </div>
                }
                {docName &&
                    <div className="info-wrapper">
                        <div className="title">{docName}</div>
                        <div className="text">{docRegNum}</div>
                    </div>
                }

                <div
                    onClick={() => context.getPreviewImgUrl((path as string))}
                    className="img-wrapper"
                    style={previewWrapperStyle}>
                    {pathPreview &&
                        <Icon icon={'zoom_in'} className="preview-loupe"></Icon>
                    }
                </div>
                {updateDocumentStatus && documentType && userCTX.user?.currentRole !== 'organizer' && userCTX.user?.currentRole !== 'moderator' &&
                    <div className='document-status'>
                        {isDocumentStatus === true ?
                            <div className='document-status-item'>
                                <Icon icon={'check_circle'} className="check_circle" style={{color: '#00E23F'}}/>
                                <div>
                                    Прошел проверку
                                </div>
                            </div>
                            : <div className='document-status-item document-status-pointer'
                                   onClick={() => {
                                       updateDocumentStatus(documentType, true)
                                   }}
                            >
                                <div>
                                    Подтвердить
                                </div>
                            </div>
                        }
                        {isDocumentStatus === false ?
                            <div className='document-status-item'>
                                <Icon icon={'info'} className="info" style={{color: '#ED0000'}}/>
                                <div>
                                    Не прошел проверку
                                </div>
                            </div>
                            : <div className='document-status-item document-status-pointer'
                                   onClick={() => {
                                       updateDocumentStatus(documentType, false)
                                   }}
                            >
                                <div>
                                    Отклонить
                                </div>
                            </div>
                        }
                    </div>}
            </div>
        </div>
    )
}

export function DocumentsUpdated({
                                     docName,
                                     docNumber,
                                     docRegNum,
                                     issueDate,
                                     pathPreview,
                                     path,
                                     toggleAcceptValueInUserDumpProfile,
                                     fieldName,
                                     style
                                 }: Props) {

    let context = React.useContext(InterfaceContext);
    const userCTX = useContext(UserContext)
    let previewWrapperStyle = {}

    if (pathPreview) {
        previewWrapperStyle = {
            backgroundImage: `url('${pathPreview}')`,
        }
    } else {
        previewWrapperStyle = {
            backgroundImage: `url('${noScanIcon}')`,
            backgroundSize: '53px',
            backgroundColor: '#F5F5F8',
            cursor: 'default'
        }
    }

    return (
        <div className="document-wrapper">
            <div className="document-inner">
                {docNumber &&
                    <div className="text">{docNumber}</div>
                }
                {issueDate &&
                    <div className="info-wrapper">
                        <div className="title">Дата выдачи</div>
                        <div className="text">{issueDate}</div>
                    </div>
                }
                {docName &&
                    <div className="info-wrapper">
                        <div className="title">{docName}</div>
                        <div className="text">{docRegNum}</div>
                    </div>
                }
                <div
                    onClick={() => context.getPreviewImgUrl((path as string))}
                    className="img-wrapper"
                    style={previewWrapperStyle}>
                    {pathPreview &&
                        <Icon icon={'zoom_in'} className="preview-loupe"></Icon>
                    }
                </div>
                {userCTX.user?.currentRole !== 'organizer' &&
                    <div className='version-buttons' style={style ? style : {}}>
                        <Icon className='done' icon={"done"}
                              onClick={() => {
                                  // @ts-ignore
                                  toggleAcceptValueInUserDumpProfile && toggleAcceptValueInUserDumpProfile({[fieldName]: EAcceptValues.ACCEPTED} as IAcceptChangeResponse)
                              }}
                        />
                        <Icon className='close' icon={"close"}
                              onClick={() => {
                                  // @ts-ignore
                                  toggleAcceptValueInUserDumpProfile && toggleAcceptValueInUserDumpProfile({[fieldName]: EAcceptValues.REFUSED} as IAcceptChangeResponse)
                              }}
                        />
                    </div>
                }
            </div>
        </div>
    )
}