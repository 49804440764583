import { AxiosPromise } from 'axios'
import { req } from 'global/common'
import { PublicUserProfile, UserProfile, UserRolesType, WorkerProfile, WorkerProfileFilling } from 'global/definitions'
import { UserProfileUpdateRequest, WorkerProfileUpdateRequest } from './profile-def'

export const profileApi = {
	getProfile: (): AxiosPromise<UserProfile> => {
		return req.get('/profile/get')
	},
	getPublicProfile: (userId: number): AxiosPromise<PublicUserProfile> => {
		return req.get(`/profile/${userId}/get`)
	},
	updateProfile: (body: UserProfileUpdateRequest): AxiosPromise<UserProfile> => {
		return req.post('/profile/update', body)
	},
	pickRole: (newRole: UserRolesType): AxiosPromise<undefined> => {
		return req.post('/profile/peek-role', newRole)
	},
	updateWorkerProfile: (body: WorkerProfileUpdateRequest): AxiosPromise<WorkerProfile> => {
		return req.post('/profile/worker/update', body)
	},
	getWorkerProfileFilling: (): AxiosPromise<WorkerProfileFilling> => {
		return req.get('/profile/worker/filling')
	},
	getPublicWorkerProfile: (userId: number): AxiosPromise<WorkerProfile> => {
		return req.get(`/profile/worker/${userId}/public-profile`)
	}
}
