import { ITableHeadingProps } from "./TableHeading.types";
import './TableHeading.style.sass'


export function TableHeading (props: ITableHeadingProps) {
    return (
        <div className={`table-heading cell ${props.className} ${props.devider && 'devider'}`} style={props.style}>
            {props.children}
        </div>
    )
}