import { AxiosPromise } from 'axios'
import { req } from 'global/common'
import { PaginatedDataResponse, ProfessionListResponse } from './info-def'

export const infoApi = {
	getProfessions: (
		limit?: number,
		offset?: number,
		search?: string,
		type?: string
	): AxiosPromise<ProfessionListResponse> => {
		return req.get('/info/professions', { limit, offset, search, type })
	},
	getFMS: (limit?: number, offset?: number, search?: string, type?: string): AxiosPromise<PaginatedDataResponse> => {
		return req.get('/info/fms', { limit, offset, search, type })
	}
}
