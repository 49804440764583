import { Stage } from "../../global/api";
import { format } from "date-fns";

const ordinalArray = [
    {
        value: 1,
        title: "pending"
    },
    {
        value: 2,
        title: "considering"
    },
    {
        value: 3,
        title: "job_offer"
    },
    {
        value: 4,
        title: "documents_preparation"
    },
    {
        value: 5,
        title: "accepted"
    },
    {
        value: 6,
        title: "rejected"
    },
    {
        value: 7,
        title: "rejected_by_worker"
    }
];

export const renderStepsList = (currentArray: Stage[], isOpen: boolean, t: any) => {
    if (!isOpen) return null;

    return (
        <div
            className={`responses-list`}
            onClick={event => {
                event.stopPropagation();
            }}
        >
            <div className={"responses-list-line"} />
            {currentArray
                .sort((a: any, b: any) => {
                    let aValue = ordinalArray.find(item => item.title === a.status);
                    let bValue = ordinalArray.find(item => item.title === b.status);
                    // @ts-ignore
                    return aValue.value - bValue.value;
                })
                .map(item => (
                    <div className={"responses-item"}>
                        <div
                            className={"responses-item-circle"}
                            style={{ backgroundColor: getColorState(item.status as string) }}
                        />
                        <div className={"responses-item-info"}>
                            <div className={"responses-item-info-state"}>{t(("vacancy." + item.status) as string)}</div>
                            {item.date && (
                                <div
                                    className={"responses-item-info-date"}>{format(new Date(item.date as string), "dd.MM.yyyy")}</div>
                            )}
                        </div>
                    </div>
                ))}
        </div>
    );
};

export const getColorState = (state: string | undefined) => {
    if (!state) return "";
    switch (state) {
        case "pending":
            return "#00A3FF";
        case "considering":
            return "#FFA800";
        case "documents_preparation":
            return "#EBFF00";
        case "accepted":
            return "#33FF00";
        case "rejected":
        case "rejected_by_worker":
            return "#9AA2B0";
        case "job_offer":
            return "#AD00FF";
        default:
            return "";
    }
};

export const getStepMessage = (step: string, position?: string | undefined, worker?: string, company?: string): string => {
    switch (step) {
        case "considering":
            return `Здравствуйте, ${worker}!\nМы рады пригласить вас на собеседование в  компанию ${company} на должность ${position}. Ваше резюме и опыт работы вызывают большой интерес, и мы хотели бы обсудить возможность сотрудничества.`;
        case "invite":
            return `Здравствуйте, ${worker}!\nМы рады сообщить вам, что после тщательного рассмотрения вашего резюме и проведения собеседования, мы хотели бы предложить вам должность в нашей организации. Ваш опыт, знания и навыки соответствуют требованиям для этой роли, и мы уверены, что вы принесете ценный вклад в нашу команду. Для подтверждения вашего решения, пожалуйста, нажмите кнопку "Принять" в карточке данной вакансии. При положительном решении, представитель компании ${company} свяжется с вами для дальнейших шагов по оформлению вашего приема на работу. Если данное предложение для вас не актуально - нажмите на кнопку "Отклонить" в карточке данной вакансии.`;
        case "accept":
            return `Здравствуйте, ${worker}!\nМы рады сообщить вам, что ваше устройство на работу по вакансии ${position} успешно завершено.`;
        case "decline":
            return `Здравствуйте, ${worker}!\nСообщаем, что ваша кандидатура не была принята на рассматриваемую вакансию ${position}. Мы благодарим вас за проявленный интерес к нашей компании и участие в процессе отбора. Желаем вам успехов в поиске работы и развитии карьеры. `;
        case "profile-request":
            return `Здравствуйте, ${worker}!\nМы обращаемся к вам, чтобы сообщить, что для продолжения процесса собеседования по вакансии ${position} необходимо заполнить раздел Опыт работы. Мы ценим вашу заинтересованность и хотим получить дополнительную информацию о вашем опыте, чтобы принять более обоснованное решение. Для того чтобы заполнить опыт работы, вам необходимо перейти в Ваш профиль и указать соответствующую информацию в разделе опыт работы.`;
        default:
            return "";
    }
};
