import { AxiosError } from 'axios'
import { FormSelect, PageTitle, WeekScroller } from 'components'
import { add, endOfISOWeek, format, getWeek, startOfISOWeek, sub } from 'date-fns'
import { shiftApi, ShiftListResponse } from 'global/api'
import { InterfaceContext } from 'global/context.interface'
import { ShortShiftResponse } from 'global/definitions'
import { ShiftInfo, useShiftStats, WeekStatsInfo } from 'pages/auth-as-worker'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { FilterShiftType } from './time-keeping-page-def'
import { shiftTypeOptions } from './time-keeping-page-utils'
import './time-keeping-page.sass'

export const TimeKeepingPage: FC = (): JSX.Element => {
	const { showMessage } = useContext(InterfaceContext)
	const [currentDay, setCurrentDay] = useState(format(new Date(), 'dd.MM.yyyy'))
	const [page, setPage] = useState(0)
	const [shifts, setShifts] = useState<ShortShiftResponse[]>([])
	const [currentShift, setCurrentShift] = useState<ShiftListResponse>()
	const [filter, setFilter] = useState<FilterShiftType>('all')
	const { shiftStats, isSuccess } = useShiftStats()

	useEffect(() => {
		shiftApi
			.getShifts(dateParams.dateStart, dateParams.dateEnd)
			.then(response => {
				setShifts(response.data.data)
			})
			.catch((e: AxiosError) => {
				showMessage({
					title: 'Ошибка',
					body: 'Ошибка при загрузке смен'
				})
			})
	}, [page])

	useEffect(() => {
		shiftApi
			.getShiftByDate(currentDay, true)
			.then(response => {
				setCurrentShift(response.data)
			})
			.catch((e: AxiosError) => {
				showMessage({
					title: 'Ошибка',
					body: 'Ошибка при загрузке смены'
				})
			})
	}, [currentDay])

	const dateParams = useMemo(
		() => ({
			dateStart: format(startOfISOWeek(sub(new Date(), { weeks: 1 - page })), 'dd.MM.yyyy'),
			dateEnd: format(endOfISOWeek(add(new Date(), { weeks: 1 + page })), 'dd.MM.yyyy')
		}),
		[page]
	)

	const setCurrentDayHandler = (day: string) => {
		setCurrentDay(day)
	}

	const setCurrentPageHandler = (page: number) => {
		setPage(page)
	}

	const dayClickHandler = () => {
		setCurrentDay(format(new Date(), 'dd.MM.yyyy'))
		setPage(0)
	}

	return (
		<div className='worker-time-keeping-page'>
			<PageTitle
				title={
					<>
						<h2 className='worker-time-keeping-page-title'>Учет рабочего времени</h2>
						<FormSelect
							className='worker-time-keeping-page-title-select'
							placeholder=''
							defaultValue={shiftTypeOptions[0]}
							options={shiftTypeOptions}
							isSearchable={false}
							onChange={option => option && setFilter(option.value)}
						/>
					</>
				}
			>
				<div className='worker-time-keeping-page-title-date' onClick={dayClickHandler}>
					{format(new Date(), 'dd.MM.yyyy')}
				</div>
			</PageTitle>
			<WeekScroller
				className='worker-time-keeping-page-week-scroller'
				currentPage={page}
				currentDay={currentDay}
				shifts={shifts}
				onSetCurrentPage={setCurrentPageHandler}
				onSetCurrentDay={setCurrentDayHandler}
			/>
			{isSuccess && (
				<WeekStatsInfo headerTitle={`Показатели за ${getWeek(new Date()) - 1} неделю`} stats={shiftStats} />
			)}
			{currentShift?.data.length !== 0 &&
				currentShift?.data.map(shift =>
					shift.brigades.map(
						({ brigade: { foreman, name, id }, objectResponse, shiftType, tracking }) =>
							(shiftType === filter || filter === 'all') && (
								<ShiftInfo
									key={id}
									foreman={foreman}
									name={name}
									shiftType={shiftType}
									object={objectResponse}
									tracking={tracking[0]}
								/>
							)
					)
				)}
		</div>
	)
}
