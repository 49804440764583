import { Icon } from '@rmwc/icon'
import { Divider, MessageInfoCard, PageTitle, ProgressPage, StepperCheckbox } from 'components'
import { PagesRoutes } from 'global/routes'
import {
	getSheduleInfo,
	RequestField,
	RequestInitialsField,
	RequestRangeField,
	useContract
} from 'pages/auth-as-worker'
import { FC, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { ContractPageUrlParams } from './contract-page-def'
import './contract-page.sass'

export const ContractPage: FC = (): JSX.Element => {
	const { workerRecordId } = useParams<ContractPageUrlParams>()
	const { contract, isSuccess } = useContract(+workerRecordId)
	const [currentStep, setCurrentStep] = useState<number>(-1)
	const { state } = useLocation()

	useEffect(() => {
		if (isSuccess && contract.mobilizationList) {
			setCurrentStep(contract.mobilizationList.data.map(s => s.completed).lastIndexOf(true))
		}
	}, [isSuccess])

	return (
		<div className='request-page'>
			<PageTitle title='Договор' breadcrumbs />
			<ProgressPage
				state={isSuccess}
				render={() => (
					<>
						{contract.mobilizationList && currentStep !== -1 ? (
							<MessageInfoCard>
								<div className='request-page-info-card-message'>
									<StepperCheckbox className='request-page-info-card-icon' checked />
									<span className='request-page-info-card-text'>
										{contract.mobilizationList.data[currentStep].type}
									</span>
								</div>
							</MessageInfoCard>
						) : (
							<MessageInfoCard icon='info_outline' message='Ожидайте начала организационных работ' />
						)}
						<div className='request-page-progress'>
							<Link
								className='request-page-progress-link'
								to={{ pathname: `${PagesRoutes.CONTRACT_LIST}/${contract.id}/employment-process`, state }}
							>
								Процесс мобилизации
								<Icon className='request-page-arrow' icon='arrow_forward_ios' />
							</Link>
						</div>
						<div className='request-page-card'>
							<RequestField className='request-page-field' title='Номер потребности' value={contract.requestNumber} />
							<Divider className='request-page-card-divider' type='horizontally' />
							<RequestField
								className='request-page-field'
								title='Дата заключения договора'
								value={contract.requestAcceptDate}
							/>
							<RequestField
								className='request-page-field'
								title='Наименование организации'
								value={contract.organisation.shortName}
							/>
							<Divider className='request-page-card-divider' type='horizontally' />
							<div className='request-page-card-table'>
								<div className='request-page-card-table-col'>
									<RequestField className='request-page-field' title='Должность кандидата' value={contract.position} />
									<Link className='request-page-field' to={`${PagesRoutes.USERS}/${contract.organizer?.id}`}>
										<RequestInitialsField
											valueClassName='contract-page-link-field'
											title='Ответственный за мобилизацию'
											firstName={contract.organizer?.firstName}
											middleName={contract.organizer?.middleName}
											lastName={contract.organizer?.lastName}
										/>
									</Link>
									<Link className='request-page-field' to={`${PagesRoutes.USERS}/${contract.supervisor?.id}`}>
										<RequestInitialsField
											valueClassName='contract-page-link-field'
											title='Непосредственный руководитель кандидата'
											firstName={contract.supervisor?.firstName}
											middleName={contract.supervisor?.middleName}
											lastName={contract.supervisor?.lastName}
										/>
									</Link>
									{contract.workTo ? (
										<RequestRangeField
											className='request-page-field'
											title='Срок действия договора'
											valueFrom={contract.workFrom}
											valueTo={contract.workTo}
										/>
									) : (
										<RequestField
											className='request-page-field'
											title='Срок действия договора'
											value={contract.workFrom}
										/>
									)}
									<RequestField
										className='request-page-field'
										title='График работы'
										value={getSheduleInfo(contract.schedule)}
									/>
								</div>
								<div className='request-page-card-table-col'>
									<RequestField
										className='request-page-field'
										title='Описание'
										value={contract.schedule.resetDescription}
									/>
									<RequestField className='request-page-field' title='Подразделение' value={contract.subdivision} />
									<RequestField className='request-page-field' title='Разряд' value={contract.grade} />
									<RequestField
										className='request-page-field'
										title='Табельный номер работника'
										value={contract.employeeIDNumber}
									/>
									<RequestField className='request-page-field' title='Бригада' value={contract.brigade?.name} />
								</div>
							</div>
						</div>
					</>
				)}
			/>
		</div>
	)
}
