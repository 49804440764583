import {Icon} from "@rmwc/icon";
import defaultAvatar from "../../../../misc/user-avatar-default.png";
import React, {useContext, useState} from "react";
import {useHistory} from "react-router";
import {CustomDialog} from "../../../../components/CustomDialog/CustomDialog";
import {Doc} from "../../../documents-page/documents-page";
import {download} from "../../messages-page-utils";
import {CircularProgress} from "@rmwc/circular-progress";
import {useAutoCloseDialog} from "../../../../custom-hooks/useAutoCloseDialog";
import {Filter, MatrixClient} from "matrix-js-sdk";
import {ChatContext} from "../../../../global/context.chat";

export const ChatPanelTop: React.FC<{anotherUser: { userId: string, avatarUrl: string, displayName: string } | null, id: string}> = ({anotherUser,id}) => {
    const history = useHistory()
    const [files, setFiles] = useState<any>([])
    const {client, sendIgnoredUsers, ignoredUsers} = useContext(ChatContext)

    const [isOpenHeadMenu, setIsOpenHeadMenu] = useState(false)
    const [isLoadingFiles, setIsLoadingFiles] = useState(false)
    const [isOpenFilesDialog, setIsOpenFilesDialog] = useState(false)

    const renderFiles = () => {
        let filesArray: any[] = []
        const currentRoom = client?.getRoom(id);
        if (!currentRoom) return
        //@ts-ignore
        const filter = new Filter(client?.getUserId())
        filter.setDefinition(
            {
                "room": {
                    "timeline": {
                        "contains_url": true,
                        "types": [
                            "m.room.message",
                        ],
                    },
                },
            },
        );
        setIsLoadingFiles(true)
        //@ts-ignore
        client?.createMessagesRequest(currentRoom?.roomId, null, 1000, 'b', filter)
            .then((data) => {
                setFiles(data.chunk.filter(item => !!item.content.url))
                setIsLoadingFiles(false)

            })
    }

    useAutoCloseDialog(() => {
        setIsOpenHeadMenu(false)
    }, [])
    return (
        <div className="page-title-component">
            <div className="title-wrapper">
                <Icon icon="arrow_back_ios_new"
                      className="pointer"
                      onClick={() => history.goBack()}
                />
                {anotherUser?.avatarUrl
                    ? <div className='chat-title-wrapper-avatar' style={{
                        backgroundImage: `url(${anotherUser?.avatarUrl})`,
                    }}/>
                    : <div className='chat-title-wrapper-avatar' style={{
                        backgroundImage: `url(${defaultAvatar})`,
                    }}/>}
                <h2 className="title">{anotherUser ? anotherUser?.displayName : ''}</h2>
                {isOpenHeadMenu &&
                    <div className='chat-head-menu-body'
                         onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                             e.stopPropagation()
                         }}>
                        <div className='chat-head-menu-items'>
                            <div className='chat-head-menu-item'
                                 onClick={() => {
                                     setIsOpenFilesDialog(true)
                                 }}>
                                <Icon className='icon description' icon={"file_present"}/>
                                <div>
                                    Показать вложения
                                </div>
                            </div>
                            <div className='chat-head-menu-item'
                                 onClick={() => {
                                     if (anotherUser?.userId) {
                                         sendIgnoredUsers(anotherUser?.userId, id, client as MatrixClient)
                                     }
                                 }}>
                                <Icon className='icon description' icon={"block"}/>
                                <div>
                                    {ignoredUsers.includes(anotherUser?.userId as string) ? 'Вернуть из ЧС' : 'Добавить в ЧС'}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Icon icon="more_horiz"
                      style={{cursor: 'pointer'}}
                      className="more_horiz" onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    e.stopPropagation()
                    renderFiles()
                    setIsOpenHeadMenu((prevState: boolean) => !prevState)
                }}/>
            </div>
            <CustomDialog
                dialogTitle={'Вложения'}
                isOpen={isOpenFilesDialog}
                onClose={() => {
                    setIsOpenFilesDialog(false)
                    setFiles([]);
                }}>
                {!isLoadingFiles
                    ? files.length > 0
                        ? files.map((item: any, index: number) => {
                            return (
                                <Doc
                                    key={item.content.body + index}
                                    name={item.content.body}
                                    mimetype={item.content.info.mimetype}
                                    path={client?.mxcUrlToHttp(item.content.url as string) as string}
                                    downloads={download}
                                />
                            )
                        })
                        : <div style={{marginTop: '65px'}}>
                            Нет вложений
                        </div>
                    : <CircularProgress style={{width: '100px', margin: '55px', height: '100px'}}
                                        className={`loader ${isLoadingFiles && 'open'}`}/>

                }
            </CustomDialog>
        </div>

    )
}