import React, {MutableRefObject, useContext, useEffect, useRef, useState} from "react";
import {Table} from "../Table/Table";
import { ProgressPage } from "../ProgressPage/ProgressPage";
import './DocumentsTable.style.sass'
import {Icon} from "@rmwc/icon";
import {columns} from "./DocumentsTable.service";
import {ERequestDocumentFields, IDocumentsData, IDocumentsTable} from "./DocumentsTable.types";
import {TableCell} from "../Table/TableCell/TableCell";
import {TableRow} from "../Table/TableRow/TableRow";
import {TextField} from "@rmwc/textfield";
import {Dialog, DialogContent, DialogTitle} from "@rmwc/dialog";
import {Button} from "@rmwc/button";
import {InterfaceContext} from "../../global/context.interface";
import {Doc} from "../../pages/documents-page/documents-page";
import {differenceInCalendarDays, parse} from "date-fns";
import { downloadFile } from "../../global/utils";


export const DocumentsTable: React.FC<IDocumentsTable> = ({
                                                  documents,
                                                  isAddNewDocument,
                                                  onChangeNewDocument,
                                                  uploadNewDocument,
                                                  deleteDocument,
                                                  startChangeDocument,
                                                  editedExistingDocument,
                                                  onChangeExistingDocument,
                                                  isEdited,
                                                  newDocument,
                                                  onChangeSortValues
                                              }) => {
    const [headCells, setHeadCells] = useState(columns)
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [over, setOver] = useState<boolean>(false);
    const availableFileFormats = ['docx', 'pdf', 'xlsx', 'rtf']
    const availableImageFormats = ['png', 'jpg']

    const interfaceCTX = useContext(InterfaceContext);

    const dateIsEnd = (date: string | null) => {
        if (!date) return {color: '#29a100'}
        const currentDate = new Date(new Date()).toLocaleString('ru-RU').split(',')[0].split('.')
        if (differenceInCalendarDays(parse(date, 'dd.MM.yyyy', new Date()).getTime(), parse(currentDate.join('.'), 'dd.MM.yyyy', new Date()).getTime()) <= 0) {
            return {color: '#d52121'}
        } else if (differenceInCalendarDays(parse(date, 'dd.MM.yyyy', new Date()).getTime(), parse(currentDate.join('.'), 'dd.MM.yyyy', new Date()).getTime()) <= 31) {
            return {color: '#E39820FF'}
        } else return {}
    }

    const onDrop = async (file: FileList) => {
        setOver(false)
        if (file.length > 1) {
            interfaceCTX.showMessage({
                body: 'Не более одного файла',
                icon: 'report',
            })
            return
        }

        const fileFormat = file[0].name.split('.').slice(-1).join()
        if (availableFileFormats.includes(fileFormat) || availableImageFormats.includes(fileFormat)) {
            setFile(file[0]);
        } else {
            interfaceCTX.showMessage({
                body: `Выберите файл формата ${[...availableFileFormats, ...availableImageFormats].join(', ')}`,
                icon: 'report',
            })
            return
        }
    }

    const fileFormat = (fileName: string) => {
        return fileName.split('.').slice(-1).join()
    }

    const formatDateToTextField = (date: string | number | { id: number; path: string; userId: number; organisationID: number; name: string } | undefined | null) => {
        if (typeof date !== 'string') return
        if (date.includes('-')) return date
        return [date.split('.')[2], date.split('.')[1], date.split('.')[0]].join('-')
    }

    useEffect(() => {
        if (uploadNewDocument) {
            setFile(null)
            inputRef.current.value = ''
        }
    }, [documents])

    const inputRef = useRef() as MutableRefObject<HTMLInputElement>

    const sortTable = (fieldName: string, sortType: 'ASC' | 'DESC') => {
        setHeadCells((prevState: any[]) => {
            return prevState.map((item: any) => {
                if (item.fieldName === fieldName && item.sortType === sortType) {
                    onChangeSortValues(fieldName, null)
                    return {...item, sortType: "NONE"}
                } else if (item.fieldName === fieldName) {
                    onChangeSortValues(fieldName, sortType)
                    return {...item, sortType: sortType}
                } else return {...item, sortType: 'NONE'}
            })
        })
    }

    const isSorted = (fieldName: string, sortType: string) => {
        const sortedBy = headCells.find((item: any) => item.fieldName === fieldName)
        if (sortedBy) return sortedBy.sortType === sortType
        else return false
    }

    return (
        <div className="documents-table">
            <ProgressPage
                state={[]}
                render={() =>
                    <>
                        <div
                            className="table-wrapper"
                        >
                            <Table className="documents-table-component">
                                <div className="headings heading undefined documents-table-header"
                                     style={{minWidth: 'auto'}}>
                                    {headCells.map((cell, index) => {
                                        return (
                                            <div key={cell.id} className={"devider documents-table-header-item"}
                                                 style={{minWidth: cell.minWidth}}>
                                                <div className="table-cell-inner documents-table-header-inner">
                                                    <div className='name'>{cell.title}</div>
                                                    <div className='expands'>
                                                        <Icon className={`icon expand `}
                                                              icon={"arrow_drop_up"}
                                                              style={{color: isSorted(cell.fieldName, 'ASC') ? 'red' : ''}}
                                                              onClick={(event: any) => {
                                                                  event.stopPropagation()
                                                                  sortTable(cell.fieldName, "ASC")
                                                              }}/>
                                                        <Icon className={`icon expand `}
                                                              icon={"arrow_drop_down"}
                                                              style={{color: isSorted(cell.fieldName, 'DESC') ? 'red' : ''}}
                                                              onClick={(event: any) => {
                                                                  event.stopPropagation()
                                                                  sortTable(cell.fieldName, 'DESC')
                                                              }}/>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })}
                                </div>
                                {isAddNewDocument && onChangeNewDocument && newDocument &&
                                    <TableRow type={"row"} className=''>
                                        {columns.map(item => {
                                            if (item.fieldType === 'text') {
                                                return (
                                                    <TableCell key={item.fieldName} devider style={{
                                                        minWidth: item.minWidth,
                                                    }}>
                                                        <TextField
                                                            maxLength={ item.fieldName === ERequestDocumentFields.DOCUMENT_NUMBER ? 20 : 128}
                                                            placeholder={item.placeholder}
                                                            outlined
                                                            value={newDocument[item.fieldName] as string}
                                                            required
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const value = e.target.value
                                                                if(value === " ") return
                                                                onChangeNewDocument(item.fieldName, value)
                                                            }}
                                                        />
                                                    </TableCell>
                                                )
                                            } else if (item.fieldType === "date") {
                                                return (
                                                    <TableCell key={item.fieldName} devider style={{
                                                        minWidth: item.minWidth,
                                                    }}>
                                                        <TextField
                                                            maxLength={10}
                                                            placeholder={item.placeholder}
                                                            type="date"
                                                            outlined
                                                            value={newDocument[item.fieldName] as string}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const value = e.target.value
                                                                if (value.length > 10) return
                                                                onChangeNewDocument(item.fieldName, value)
                                                            }}
                                                        />
                                                    </TableCell>
                                                )
                                            } else if (item.fieldType === 'file') {
                                                return (
                                                    <TableCell key={item.fieldName} devider style={{
                                                        minWidth: item.minWidth,
                                                    }}>
                                                        {newDocument.document
                                                            ? <>
                                                                <Icon
                                                                    icon={{icon: 'upload_file', size: 'xlarge'}}
                                                                />
                                                                <Icon className={`file_download`}
                                                                      style={{cursor: 'pointer', marginLeft: '10px'}}
                                                                      icon={"delete"}
                                                                      onClick={() => {
                                                                          onChangeNewDocument(item.fieldName, null)
                                                                          inputRef.current.value = ''
                                                                          setFile(null)
                                                                      }}/>
                                                            </>
                                                            : <Icon className={`file_download`}
                                                                    style={{cursor: 'pointer'}}
                                                                    icon={"file_download"}
                                                                    onClick={() => {
                                                                        setUploadModal(true)
                                                                    }}/>
                                                        }
                                                    </TableCell>
                                                )
                                            } else {
                                                return (
                                                    <TableCell key={item.fieldName} devider style={{
                                                        minWidth: item.minWidth,
                                                    }}>

                                                    </TableCell>
                                                )
                                            }
                                        })}
                                    </TableRow>
                                }
                                {documents && documents.map((document: IDocumentsData, index) => {
                                    if (document.isChanged === true && editedExistingDocument && onChangeExistingDocument) {
                                        return (
                                            <TableRow type={"row"} className='' key={document.documentName + index}>
                                                {columns.map(item => {
                                                    if (item.fieldType === 'text') {
                                                        return (
                                                            <TableCell key={item.fieldName} devider style={{
                                                                minWidth: item.minWidth,
                                                            }}>
                                                                <TextField //@ts-ignore
                                                                    value={editedExistingDocument[item.fieldName]}
                                                                    outlined
                                                                    maxLength={ item.fieldName === ERequestDocumentFields.DOCUMENT_NUMBER ? 20 : 128}
                                                                    required
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        const value = e.target.value
                                                                        if(value === ' ') return
                                                                        onChangeExistingDocument(item.fieldName, value)
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        )
                                                    } else if (item.fieldType === "date") {
                                                        return (
                                                            <TableCell key={item.fieldName} devider style={{
                                                                minWidth: item.minWidth,
                                                            }}>
                                                                <TextField
                                                                    value={formatDateToTextField(editedExistingDocument[item.fieldName])}
                                                                    type="date"
                                                                    outlined
                                                                    maxLength={10}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        const value = e.target.value
                                                                        if (value.length > 10) return
                                                                        onChangeExistingDocument(item.fieldName, value)
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        )
                                                    } else if (item.fieldType === 'file') {
                                                        return (
                                                            <TableCell key={item.fieldName} devider style={{
                                                                minWidth: item.minWidth,
                                                            }}>
                                                                {/*// @ts-ignore*/}
                                                                {!!editedExistingDocument.document?.path &&
                                                                    (availableImageFormats.includes(fileFormat(document.document.name))
                                                                            ? <>
                                                                                <div style={{
                                                                                    backgroundImage: `url(${document.document.path})`,
                                                                                    height: '100%',
                                                                                    width: '100%',
                                                                                    backgroundRepeat: 'no-repeat',
                                                                                    backgroundPosition: 'center',
                                                                                    backgroundSize: 'contain',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                     onClick={() => {
                                                                                         downloadFile(document.document.path, document.document.name)
                                                                                     }}
                                                                                />
                                                                                <Icon className={`file_download`}
                                                                                      style={{
                                                                                          cursor: 'pointer',
                                                                                          marginLeft: '10px'
                                                                                      }}
                                                                                      icon={"delete"}
                                                                                      onClick={() => {
                                                                                          onChangeExistingDocument(item.fieldName, null)
                                                                                      }}/>
                                                                            </>
                                                                            : availableFileFormats.includes(fileFormat(document.document.name)) &&
                                                                            <>
                                                                                <Icon
                                                                                    style={{cursor: 'pointer'}}
                                                                                    icon={{
                                                                                        icon: 'upload_file',
                                                                                        size: 'xlarge'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        downloadFile(document.document.path, document.document.name)
                                                                                    }}/>
                                                                                <Icon className={`file_download`}
                                                                                      style={{
                                                                                          cursor: 'pointer',
                                                                                          marginLeft: '10px'
                                                                                      }}
                                                                                      icon={"delete"}
                                                                                      onClick={() => {
                                                                                          onChangeExistingDocument(item.fieldName, null)
                                                                                          inputRef.current.value = ''
                                                                                          setFile(null)
                                                                                      }}/>
                                                                            </>
                                                                    )
                                                                }
                                                                {typeof editedExistingDocument.document === 'number' &&
                                                                    <>
                                                                        <Icon
                                                                            icon={{icon: 'upload_file', size: 'xlarge'}}
                                                                        />
                                                                        <Icon className={`file_download`}
                                                                              style={{
                                                                                  cursor: 'pointer',
                                                                                  marginLeft: '10px'
                                                                              }}
                                                                              icon={"delete"}
                                                                              onClick={() => {
                                                                                  onChangeExistingDocument(item.fieldName, null)
                                                                                  inputRef.current.value = ''
                                                                                  setFile(null)
                                                                              }}/>
                                                                    </>
                                                                }
                                                                {!editedExistingDocument.document &&
                                                                    <Icon className={`file_download`}
                                                                          style={{cursor: 'pointer'}}
                                                                          icon={"file_download"}
                                                                          onClick={() => {
                                                                              setUploadModal(true)
                                                                          }}/>
                                                                }
                                                            </TableCell>
                                                        )
                                                    } else {
                                                        return (
                                                            <TableCell key={item.fieldName} devider style={{
                                                                minWidth: item.minWidth,
                                                            }}>
                                                                {document[item.fieldName]}
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                            </TableRow>)
                                    } else return (
                                        <TableRow className=""
                                                  type="row"
                                                  key={`${document.documentName} ${index}`}>
                                            <TableCell devider style={{
                                                minWidth: columns[0].minWidth,
                                            }}>
                                                <div className='documents-table-cell documents-table-cell-text'>
                                                    {document.provisioningCenterName}
                                                </div>
                                                {deleteDocument && startChangeDocument && isEdited &&
                                                    <div className={'icon-buttons'}>
                                                        <Icon
                                                            className='icon delete_forever'
                                                            icon={"delete_forever"}
                                                            onClick={() => {
                                                                deleteDocument(document.id)
                                                            }}
                                                        />
                                                        <Icon
                                                            className='icon edit'
                                                            icon={"edit"}
                                                            onClick={() => {
                                                                startChangeDocument(document, index)
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </TableCell>
                                            <TableCell devider
                                                       style={{
                                                minWidth: columns[1].minWidth,
                                            }}
                                                       className='documents-table-cell-text'>
                                                {document.documentName}
                                            </TableCell>
                                            <TableCell devider
                                                       style={{
                                                minWidth: columns[2].minWidth,
                                            }}
                                                       className='documents-table-cell-text'>
                                                {document.documentNumber}
                                            </TableCell>
                                            <TableCell devider
                                                       style={{
                                                           minWidth: columns[3].minWidth,
                                                       }}
                                                       className='documents-table-cell-date'>
                                                {document.beginDate}
                                            </TableCell>
                                            <TableCell devider
                                                       style={{
                                                           minWidth: columns[4].minWidth,
                                                       }}
                                                       className='documents-table-cell-date'>
                                                <div style={dateIsEnd(document.endDate)}>
                                                    {document.endDate ? document.endDate : 'Бессрочный'}
                                                </div>
                                            </TableCell>
                                            <TableCell devider style={{
                                                minWidth: columns[5].minWidth,
                                            }}>
                                                {availableImageFormats.includes(fileFormat(document.document.name)) &&
                                                    <div style={{
                                                        backgroundImage: `url(${document.document.path})`,
                                                        height: '100%',
                                                        width: '100%',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'contain',
                                                        cursor: 'pointer'
                                                    }}
                                                         onClick={() => {
                                                             downloadFile(document.document.path, document.document.name)
                                                         }}
                                                    />
                                                }
                                                {availableFileFormats.includes(fileFormat(document.document.name)) &&
                                                    <Icon
                                                        style={{cursor: 'pointer'}}
                                                        icon={{icon: 'upload_file', size: 'xlarge'}}
                                                        onClick={() => {
                                                            downloadFile(document.document.path, document.document.name)
                                                        }}/>
                                                }
                                            </TableCell>
                                            <TableCell devider
                                                       style={{
                                                           minWidth: columns[6].minWidth,
                                                       }}
                                                       className='documents-table-cell-text'>
                                                {document.userIssuedDocument}
                                            </TableCell>
                                            <TableCell devider
                                                       style={{
                                                           minWidth: columns[7].minWidth,
                                                       }}
                                                       className='documents-table-cell-date'>
                                                {document.creationDate}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Table>
                        </div>
                    </>
                }
            />
            {uploadNewDocument && <Dialog open={uploadModal} onClose={() => {
                setFile(null);
                inputRef.current.value = ''
                setUploadModal(false)
            }}>
                <DialogTitle className={'modal-title'}>
                    Загрузка документов
                    <Icon icon={'close'} onClick={() => {
                        setFile(null);
                        inputRef.current.value = ''
                        setUploadModal(false)
                    }}/>
                </DialogTitle>
                <DialogContent className={'modal-content'}>
                    <>
                        <div className={'doc-container'}>
                            {file && <Doc key={file.name} name={file.name}/>}
                        </div>
                        <hr/>
                        <div
                            className={'dropzone' + (over ? ' dragover' : '')}
                            onDrop={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                onDrop(e.dataTransfer.files)
                            }}
                            onDragOver={(e) => e.preventDefault()}
                            onDragEnter={(e) => setOver(true)}
                            onDragExit={(e) => setOver(false)}>
                            <Icon icon={{icon: 'upload_file', size: 'xlarge'}}/>
                            <div
                                style={{textAlign: 'center'}}>{`Перетащите или выберите файлы для загрузки ${[...availableFileFormats, ...availableImageFormats].join(', ')}`}</div>
                            <input
                                type='file'
                                ref={inputRef}
                                onChange={(e) => onDrop(e.target.files ?? new FileList())}
                            />
                        </div>
                        <div className={'footer-btn'}>
                            <Button raised onClick={(event) => {
                                event.preventDefault()
                                uploadNewDocument(file, () => {
                                    setUploadModal(false)
                                })
                            }}>
                                Загрузить
                            </Button>
                        </div>
                    </>
                </DialogContent>
            </Dialog>
            }
        </div>
    )
}