import clsx from 'clsx'
import { FC } from 'react'
import { DividerProps } from './Divider.types'
import './Divider.style.sass'

export const Divider: FC<DividerProps> = ({ className, type = 'vertical', addOffsets, ...rest }): JSX.Element => {
	return (
		<div
			className={clsx(
				{
					divider: type === 'vertical',
					'divider-horizontally': type === 'horizontally',
					'divider-no-margin': !addOffsets
				},
				className
			)}
			{...rest}
		/>
	)
}
