import React from "react";
import {IDndTableBody} from "./ExtendedCustomTableBody.types";
import './ExtendedCustomTableBody.style.sass'
import { TableRow } from "components/Table/TableRow/TableRow";
import {IColumnSettings} from "../../ExtendedCustomTable.types";

export const ExtendedCustomTableBody: React.FC<IDndTableBody> = ({
                                                          columnSettings,
                                                          tableValues,
                                                          renderRow
                                                      }) => {

    const cellIsActive = (columnType: string, columnSettings: IColumnSettings[]) => {
        let color = '#FFFFFF'
        const activeElement = columnSettings.find(item => {
            return item.columnType === columnType && (item.sortType !== 'NONE' || item.filterType)
        })
        if (!!activeElement) {
            color = '#F2F5F9'
        }
        return color
    }

    if (tableValues) {
        return (
            <>
                {
                    tableValues.map((value: any, index) => {
                        return (
                            <TableRow className="extended-custom-table-row dnd-table-body"
                                      type="row"
                                      key={`${value.id} ${index}`}>
                                {renderRow(value, columnSettings, cellIsActive, index)}
                            </TableRow>
                        )
                    })
                }
            </>
        )

    } else return null
}