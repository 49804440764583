import { Icon } from "@rmwc/icon";
import React, { useContext, useState } from "react";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { InterfaceContext } from "../../global/context.interface";
import { UserContext } from "../../global/context.user";
import "./Feedback.style.sass";
import { UpdateHistory } from "../UpdateHistory";
import { SurveyListPage } from "../SurveyListPage";
import { req } from "../../global/common";

export function Feedback() {
    const userCTX = React.useContext(UserContext);
    const history = useHistory();
    const location = useLocation();
    const interfaceCTX = useContext(InterfaceContext);

    const [activePage, setActivePage] = React.useState("");
    const [isShowSettingsMenu, setIsShowSettingsMenu] = useState(false);
    const [survey, setSurvey] = React.useState(false);

    const getUpdateHistory = () => {
        req.get("/profile/survey/list")
            .then(({ data }) => {
                setSurvey(data.data.length > 0);
            });
    };

    React.useEffect(() => {
        getUpdateHistory();

        // Код ниже - вынужденная мера, чтобы заиметь доступ к стилям родителя только на этой странице
        let appContent = document.getElementsByClassName("app-content")[0];
        appContent.classList.add("no-padding");
        return () => {
            appContent.classList.remove("no-padding");
            setActivePage(location.pathname);
        };
        // ==========================================================================================
    }, []);

    React.useEffect(() => {
        if (location.pathname !== activePage) {
            setActivePage("/feedback");
        }
    }, [location]);

    return (
        <div className="feedback-page">
            <div className={`side-bar-wrapper ${isShowSettingsMenu ? "side-bar-wrapper-show" : ""}`}>
                <div className={`show-icon ${isShowSettingsMenu ? "show-icon-show" : ""}`}
                     onClick={() => {
                         setIsShowSettingsMenu(prevState => !prevState);
                     }}>
                    <Icon icon={isShowSettingsMenu ? "arrow_forward_ios" : "arrow_back_ios"} />
                </div>
                <div className="nav-section">
                    <a href={"mailto:" + interfaceCTX.getMailToArr()}
                       className={`nav-item-link ${isShowSettingsMenu ? "nav-item-link-show" : ""}`}>
                        <Icon icon={"support_agent"} />
                        <div
                            className={`nav-item-title ${isShowSettingsMenu ? "nav-item-title-show" : ""}`}>
                            Связаться с техподдержкой
                        </div>
                    </a>
                </div>
                <div className="nav-section">
                    <Link
                        to={`/feedback/update-history`}
                        className={`nav-item-link ${isShowSettingsMenu ? "nav-item-link-show" : ""} ${activePage === `/feedback/update-history` && "active"}`}
                        onClick={() => !interfaceCTX.isFormChanged.settings && setActivePage(`/feedback/update-history`)}
                    >
                        <Icon icon={"update"} />
                        <div
                            className={`nav-item-title ${isShowSettingsMenu ? "nav-item-title-show" : ""}`}>
                            Инструкции
                        </div>
                    </Link>
                </div>
                <div className="nav-section">
                    <div
                        className={`nav-item-link ${isShowSettingsMenu ? "nav-item-link-show" : ""}`}
                        onClick={() => {
                            interfaceCTX.setIsOnboardingOpen(true);
                        }}
                    >
                        <Icon icon={"school"} />
                        <div
                            className={`nav-item-title ${isShowSettingsMenu ? "nav-item-title-show" : ""}`}>
                            О приложении
                        </div>
                    </div>
                </div>
                <div className="nav-section">
                    <Link
                        to={`/feedback/survey-list`}
                        className={`nav-item-link ${isShowSettingsMenu ? "nav-item-link-show" : ""} ${activePage === `/feedback/survey-list` && "active"}`}
                        onClick={() => !interfaceCTX.isFormChanged.settings && setActivePage(`/feedback/survey-list`)}
                    >
                        <Icon icon={"question_mark"} />
                        <div
                            className={`nav-item-title ${isShowSettingsMenu ? "nav-item-title-show" : ""}`}>
                            Опрос
                        </div>
                        {survey && <div className={"nav-item-notification"} />}
                    </Link>
                </div>
            </div>
            <div className={`content-wrapper ${isShowSettingsMenu ? "content-wrapper-show" : ""}`}>
                <Switch>
                    <Route path={`/feedback/update-history`} exact>
                        <UpdateHistory />
                    </Route>
                    <Route path={`/feedback/survey-list`} exact>
                        <SurveyListPage />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}