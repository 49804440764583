import { req } from "global/common";
import { InterfaceContext } from "global/context.interface";
import { FormFieldWrapper } from "pages/auth-as-moderator/OrganizationProfile/components/PaymentHistory/Forms/components/FormFieldWrapper";
import {
	useState,
	useContext,
	createRef,
	RefObject,
	useEffect,
	PropsWithChildren,
} from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import {
	IInviteForm,
	ISelectOption,
	IVacancyToInvite,
} from "./InviteWorkerForm.def";
import Select, { GroupBase, OptionProps } from "react-select";
import { ModalWrapper } from "pages/auth-as-moderator/OrganizationProfile/components/PaymentHistory/Forms/components/ModalWrapper";
import "./InviteWorkerForm.styles.sass";
import { ChatContext } from "global/context.chat";
import { getStepMessage } from "pages/public-worker-profile/PublicWorkerProfilePage.service";
import { UserContext } from "global/context.user";
import { Icon } from "@rmwc/icon";
import { PagesRoutes } from "global/routes";
import { TariffCards } from "components/TariffCards/TariffCards";
import { useTranslation } from "react-i18next";

export function InviteWorkerForm({
	open,
	onClose,
	onSuccessInvite,
	workerProfile,
}: IInviteForm) {
	const { userId: workerID } = useParams() as { userId: string };
	const [vacancies, setVacancies] = useState<ISelectOption[] | null>(null);
	const [isOpenTariff, setIsOpenTariff] = useState(false);

	const interfaceCTX = useContext(InterfaceContext);
	const userCTX = useContext(UserContext);
	const chatCTX = useContext(ChatContext);
	const history: any = useHistory();
	const { t } = useTranslation("common");

	const methods = useForm({
		mode: "all",
	});

	const formRef = createRef() as RefObject<HTMLFormElement>;

	useEffect(() => {
		req
			.get("/organisation/worker/vacancies")
			.then(({ data }) =>
				setVacancies((prevState) => [
					...data.map((vacancy: IVacancyToInvite) => ({
						label: vacancy.position,
						value: vacancy.id,
						city: vacancy.city,
					})),
				])
			)
			.catch((e) => {
				if (e.response.data === "organisation.not_enough_contacts") {
					setIsOpenTariff(true);
				} else {
					interfaceCTX.showMessage({
						title: "Ошибка",
						body: t(e.response.data),
					});
				}
			});
	}, []);

	function onSave() {
		formRef.current!.dispatchEvent(
			new Event("submit", {
				cancelable: true,
				bubbles: true,
			})
		);
	}

	function onSubmit(formData: any) {
		const body = {
			vacancyId: formData.vacancyId.value,
			workerIds: [Number(workerID) || 0],
		};

		req
			.post("/organisation/worker/invite", body)
			.then(({ data }) => {
				onClose();
				onSuccessInvite();
				data.data.length > 0 &&
					chatCTX.sendMessage(
						workerProfile,
						getStepMessage(
							"considering",
							formData.vacancyId.label,
							workerProfile.personalInfo?.firstName,
							userCTX.user?.organisation?.shortName?.trim()
						)
					);
			})
			.catch((e) => {
				if (e.response.data === "organisation.not_enough_contacts") {
					setIsOpenTariff(true);
				} else {
					interfaceCTX.showMessage({
						title: "Ошибка",
						body: t(e.response.data),
					});
				}
			});
	}

	return (
		<>
			<ModalWrapper
				title="Пригласить рабочего"
				open={open}
				onSave={onSave}
				onClose={() => {
					onClose();
					setTimeout(() => methods.reset({ vacancyId: null }), 100);
				}}
				buttons={[
					{
						callback: () => onClose(),
						outlined: true,
						text: "Отменить",
					},
					{
						callback: () => onSave(),
						outlined: true,
						raised: true,
						disabled: !methods.formState.isValid,
						text: "Пригласить",
					},
				]}
			>
				<FormProvider {...methods}>
					<form
						className={"payment-history-form"}
						ref={formRef}
						onSubmit={methods.handleSubmit(onSubmit)}
					>
						<FormFieldWrapper label="Вакансии">
							<Controller
								name="vacancyId"
								control={methods.control}
								rules={{
									required: true,
								}}
								render={({ field }) => {
									return (
										<Select
											{...field}
											classNamePrefix={"select"}
											placeholder={"Выберите из списка"}
											noOptionsMessage={() => "Нет опций"}
											options={vacancies || undefined}
											isSearchable={false}
											menuPosition={"fixed"}
											menuShouldScrollIntoView={false}
											components={{
												Option: ({
													innerProps,
													data,
												}: PropsWithChildren<
													OptionProps<any, false, GroupBase<any>>
												>) => {
													console.log(data);
													return (
														<div {...innerProps} className="option-wrapper">
															<div className="option-title">{data.label}</div>
															<div className="option-subtitle">{data.city}</div>
														</div>
													);
												},
												MenuList: ({ innerProps, children }) => {
													return (
														<div {...innerProps}>
															{children}
															<Link
																to={{
																	pathname:
																		PagesRoutes.VACANCIES_LIST +
																		PagesRoutes.VACANCY_PAGE +
																		`/create`,
																	state: history.location.state,
																}}
																className={`sidebar-selector-link`}
															>
																<Icon icon={"add"} />
																<div className={`sidebar-selector-link-text`}>
																	Добавить вакансию
																</div>
															</Link>
														</div>
													);
												},
											}}
										/>
									);
								}}
							/>
						</FormFieldWrapper>
					</form>
				</FormProvider>
			</ModalWrapper>
			<TariffCards
				isOpen={isOpenTariff}
				onClose={() => {
					setIsOpenTariff(false);
				}}
			/>
		</>
	);
}
