import React, { FormEvent, useEffect, useRef, useState } from "react";
import {IArrayOfValues, IMenuFilter} from './MenuFilterSearch.types'
import './MenuFilterSearch.style.sass'
import {TextField} from '@rmwc/textfield'
import {Icon} from '@rmwc/icon'
import {useDebounceEffect} from 'custom-hooks'
import {Checkbox} from '@rmwc/checkbox'
import {req} from 'global/common'
import {useTranslation} from 'react-i18next'
import {ProgressPage} from 'components'

export const MenuFilterSearch: React.FC<IMenuFilter> = ({
                                                            isMenuFilterOpen,
                                                            onCloseMenuFilterOpen,
                                                            onChangeFilterValues,
                                                            columnType,
                                                            columnTitle,
                                                            isWithSearch,
                                                            currentFilter,
                                                            getApiUrl,
                                                            paginateFilter,
                                                            langPrefix
                                                  }) => {
    const [textValue, setTextValue] = useState<string>('')
    const {t} = useTranslation('common')
    const [filterValues, setFilterValues] = useState<string[]>(currentFilter ? currentFilter : [])
    const [isSelectAllFilterValues, setIsSelectAllFilterValues] = useState(false)
    const [arrayOfValues, setArrayOfValues] = useState<string[] | null>(null)
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(20);
    const [fetching, setFetching] = useState(false);
    const scroller = useRef<HTMLInputElement>(null);

    const scrollHandler = (e: React.UIEvent<HTMLElement>) => {
        //@ts-ignore
        if (e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + scroller.current.offsetHeight) < 100) {
            if(!arrayOfValues) return
            if (total > arrayOfValues.length) {
                setFetching(true);
            }
        }
    };

    const selectAllFilterValues = (isSelected: boolean) => {
        if (isSelected) {
            setFilterValues(arrayOfValues as string[]);
            setIsSelectAllFilterValues(true);
        } else if (!isSelected) {
            setFilterValues([]);
            setIsSelectAllFilterValues(false);
        }
    };

    const getPaginateValues = (typeCommand: "fetch" | "textChange") => {
        if (paginateFilter && !columnType.toLowerCase().includes("date")) {
            let newLimit = limit;
            if (typeCommand === "fetch") {
                newLimit = limit + 20;
                setLimit(prevState => prevState + 20);
            }
            req
                .get(`${getApiUrl}type=${columnType}&search=${encodeURIComponent(textValue)}&limit=${newLimit}&offset=`)
                .then(({ data }) => {
                    setArrayOfValues(data.data)
                    if (isSelectAllFilterValues) {
                        setFilterValues(data.data.filter((item: any) => !!item));
                    }
                    setTotal(data.total);
                    if (typeCommand === "fetch") {
                        setFetching(false);
                    }

                })
                .catch(() => {
                });
        }
    };

    useEffect( () => {
        if(arrayOfValues?.length === filterValues.length && arrayOfValues?.length !== 0) {
            setIsSelectAllFilterValues(true)
        } else {
            setIsSelectAllFilterValues(false)
        }
    }, [arrayOfValues])

    React.useEffect(() => {
        if (fetching) {
            getPaginateValues("fetch");
        }
    }, [fetching]);

    const renderFilterValue = (item: string) => {
        if (!!langPrefix) {
            return t(langPrefix + item);
        } else return item;
    };

    useDebounceEffect(() => {
        if (!columnType.toLowerCase().includes("date") && paginateFilter) {
            getPaginateValues("textChange");
        }
    }, 300, [textValue]);

    if (isMenuFilterOpen) {
        if(paginateFilter) {
            return (
                <div
                    className={`menu-filter`}
                    onClick={event => {
                        event.stopPropagation()
                    }}
                >
                    {isWithSearch &&
                        <div className='menu-filter-input'>
                            <TextField
                                value={textValue}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = e.target.value
                                    setTextValue(value)
                                }}
                                outlined
                                type='text'
                                style={{width: '100%'}}
                                icon='search'
                                maxLength={columnType === 'countTime' || columnType === 'countShifts' ? 5 : 160}
                            />
                        </div>
                    }
                    <div className='menu-filter-list'>
                        <div className='items' ref={scroller} onScroll={scrollHandler}>
                            <div className='item'>
                                <Checkbox
                                    className={isSelectAllFilterValues ? 'horosiy-checkbox' : 'off-checkbox'}
                                    checked={isSelectAllFilterValues}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                        selectAllFilterValues(!isSelectAllFilterValues)
                                    }}
                                />
                                <div className='item-text'>Выбрать все</div>
                            </div>
                            <ProgressPage
                                state={arrayOfValues}
                                render={() => (
                                    <>
                                        {arrayOfValues &&
                                            arrayOfValues.map(item => {
                                                return (
                                                    <div className='item' key={item}>
                                                        <Checkbox
                                                            key={item}
                                                            className={filterValues.includes(item) ? 'horosiy-checkbox' : 'off-checkbox'}
                                                            checked={filterValues ? filterValues.includes(item) : false}
                                                            onChange={(e: FormEvent<HTMLInputElement>) => {
                                                                setFilterValues((prevState: string[]) => {
                                                                    let alreadyHas = !!prevState && prevState.find(value => value === item)
                                                                    let newState: string[] = []
                                                                    if (alreadyHas) {
                                                                        newState = [...prevState.filter(value => value !== item)]
                                                                        setIsSelectAllFilterValues(false)
                                                                    } else {
                                                                        newState = [...prevState, item]
                                                                    }
                                                                    return newState
                                                                })
                                                            }}
                                                        />
                                                        <div className='item-text'>
                                                            {renderFilterValue(item)}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </>
                                )}
                            />
                        </div>
                        <div className='dialog-buttons'>
                            <div
                                className='dialog-btn'
                                onClick={() => {
                                    onChangeFilterValues(columnType, columnTitle, filterValues.length > 0 ? filterValues : null)
                                    onCloseMenuFilterOpen()
                                }}
                            >
                                <Icon className='done' icon={'done'}/>
                                <div>ПРИМЕНИТЬ</div>
                            </div>
                            <div
                                className='dialog-btn'
                                onClick={() => {
                                    setFilterValues([])
                                    onChangeFilterValues(columnType, columnTitle, null)
                                    setIsSelectAllFilterValues(false)
                                }}
                            >
                                <Icon className='close' icon={'close'}/>
                                <div>ОТМЕНИТЬ</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div
                    className={`menu-filter`}
                    onClick={event => {
                        event.stopPropagation()
                    }}
                >
                    {isWithSearch &&
                        <div className='menu-filter-input'>
                            <TextField
                                value={textValue}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = e.target.value
                                    setTextValue(value)
                                }}
                                outlined
                                type='text'
                                style={{width: '100%'}}
                                icon='search'
                                maxLength={columnType === 'countTime' || columnType === 'countShifts' ? 5 : 160}
                            />
                        </div>
                    }
                    <div className='menu-filter-list'>
                        <div className='items'>
                            <div className='item'>
                                <Checkbox
                                    className={isSelectAllFilterValues ? 'horosiy-checkbox' : 'off-checkbox'}
                                    checked={isSelectAllFilterValues}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                        selectAllFilterValues(!isSelectAllFilterValues)
                                    }}
                                />
                                <div className='item-text'>Выбрать все</div>
                            </div>
                            <ProgressPage
                                state={arrayOfValues}
                                render={() => (
                                    <>
                                        {arrayOfValues &&
                                            arrayOfValues.map(item => {
                                                return (
                                                    <div className='item' key={item}>
                                                        <Checkbox
                                                            key={item}
                                                            className={filterValues.includes(item) ? 'horosiy-checkbox' : 'off-checkbox'}
                                                            checked={filterValues ? filterValues.includes(item) : false}
                                                            onChange={(e: FormEvent<HTMLInputElement>) => {
                                                                setFilterValues((prevState: string[]) => {
                                                                    let alreadyHas = !!prevState && prevState.find(value => value === item)
                                                                    let newState: string[] = []
                                                                    if (alreadyHas) {
                                                                        newState = [...prevState.filter(value => value !== item)]
                                                                        setIsSelectAllFilterValues(false)
                                                                    } else {
                                                                        newState = [...prevState, item]
                                                                    }
                                                                    return newState
                                                                })
                                                            }}
                                                        />
                                                        <div className='item-text'>
                                                            {renderFilterValue(item)}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </>
                                )}
                            />
                        </div>
                        <div className='dialog-buttons'>
                            <div
                                className='dialog-btn'
                                onClick={() => {
                                    onChangeFilterValues(columnType, columnTitle, filterValues.length > 0 ? filterValues : null)
                                    onCloseMenuFilterOpen()
                                }}
                            >
                                <Icon className='done' icon={'done'}/>
                                <div>ПРИМЕНИТЬ</div>
                            </div>
                            <div
                                className='dialog-btn'
                                onClick={() => {
                                    setFilterValues([])
                                    onChangeFilterValues(columnType, columnTitle, null)
                                    setIsSelectAllFilterValues(false)
                                }}
                            >
                                <Icon className='close' icon={'close'}/>
                                <div>ОТМЕНИТЬ</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    } else return null
}
