import {IColumnSettings} from "../../../components/ExtendedCustomTable/ExtendedCustomTable.types";

export const workersSettings: IColumnSettings[] = [
    {
        id: 0,
        sortOrder: 0,
        visibility: true,
        minWidth: '250px',
        columnType: 'activeStatus',
        sortType: 'NONE',
        filterType: null,
        columnLang: true,
        title: "Статус активности",
    },
    {
        id: 1,
        sortOrder: 1,
        visibility: true,
        minWidth: '250px',
        columnType: 'fullName',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "ФИО работника",
    },
    {
        id: 2,
        sortOrder: 2,
        visibility: true,
        minWidth: '250px',
        columnType: 'baseProfession',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Базовая профессия",
    },
    {
        id: 3,
        sortOrder: 3,
        visibility: true,
        minWidth: '250px',
        columnType: 'email',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "E-mail",
    },
    {
        id: 4,
        sortOrder: 4,
        visibility: true,
        minWidth: '250px',
        columnType: 'currentContract',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Текущий договор в организации",
    },
    {
        id: 5,
        sortOrder: 5,
        visibility: true,
        minWidth: '250px',
        columnType: 'responsesInProcessCount',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Отклики на стадии переговоров",
    },
    {
        id: 6,
        sortOrder: 6,
        visibility: true,
        minWidth: '250px',
        columnType: 'contractCount',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Количество действующих договоров",
    },
    {
        id: 7,
        sortOrder: 7,
        visibility: true,
        minWidth: '250px',
        columnType: 'contactPhone',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Контактный телефон",
    },
    {
        id: 8,
        sortOrder: 8,
        visibility: true,
        minWidth: '250px',
        columnType: 'registrationDate',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Дата регистрации в системе",
    },
    {
        id: 9,
        sortOrder: 9,
        visibility: true,
        minWidth: '250px',
        columnType: 'deletedDate',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Дата удаления из системы",
    },
    {
        id: 10,
        sortOrder: 10,
        visibility: true,
        minWidth: '250px',
        columnType: 'additionalProfession',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Дополнительная профессия",
    },
]

export const getWorkerStatusColor = (status: string) => {
    switch (status) {
        case 'user_delete':
            return '#FF0000'
        case 'user_active':
            return '#00E23F'
        default:
            return '#FF0000'
    }
}
