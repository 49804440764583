import { PageTitle, ProgressPage, FixedButtons, Form } from 'components'
import parse from 'date-fns/parse'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { req } from 'global/common'
import { InterfaceContext } from 'global/context.interface'
import { CreateObjectPageParams, ObjectFromAPI } from './create-object-def'

export default function CreateObjectPage() {
	const interfaceCTX = React.useContext(InterfaceContext)
	const formRef = React.createRef() as React.MutableRefObject<HTMLFormElement>
	const { t } = useTranslation('common')
	const history = useHistory()

	const { searchID } = useParams<CreateObjectPageParams>()

	const [loaded, setLoaded] = React.useState<boolean>(null!)
	const [object, setObject] = React.useState<ObjectFromAPI>(null!)
	const [objectList, setObjectList] = React.useState<ObjectFromAPI[]>(null!)

	React.useEffect(() => {
		if (searchID) {
			req
				.get(`/objects/${searchID}/get`)
				.then(({ data }) => {
					setObject(data)
					setLoaded(true)
				})
				.catch(() => setLoaded(false))
		} else {
			setLoaded(true)
		}
		req.get(`/objects/list?limit=0&offset=0`).then(({ data }) => {
			setObjectList(data.data)
		})
	}, [searchID])

	function isObjectNameValid(value: string) {
		if (searchID) {
			if (value == object?.name) {
				return true
			} else {
				if (objectList?.every(obj => obj.name != value)) {
					return true
				} else {
					return false
				}
			}
		} else {
			if (objectList?.every(obj => obj.name != value)) {
				return true
			} else {
				return false
			}
		}
	}

	const onSubmit = (submitData: any) => {
		let dateEndParsed = parse(submitData.dateEnd, 'dd.MM.yyyy', new Date())
		let dateStartParsed = parse(submitData.dateStart, 'dd.MM.yyyy', new Date())

		if (dateStartParsed <= dateEndParsed) {
			if (searchID) {
				req
					.post(`/objects/${searchID}/update`, submitData)
					.then(() => {
						interfaceCTX
							.showMessage({
								body: 'Объект успешно сохранён',
								icon: 'done'
							})
							.then(() => history.goBack())
					})
					.catch(e => {
						interfaceCTX.showMessage({
							title: 'Ошибка',
							body: t('error.' + e.response.data)
						})
					})
			} else {
				req
					.post(`/objects/create`, submitData)
					.then(() => {
						interfaceCTX
							.showMessage({
								body: 'Объект успешно создан',
								icon: 'done'
							})
							.then(() => history.goBack())
					})
					.catch(e => {
						interfaceCTX.showMessage({
							title: 'Ошибка',
							body: t('error.' + e.response.data)
						})
					})
			}
		} else if (
			(dateStartParsed as unknown as string) == 'Invalid Date' ||
			(dateEndParsed as unknown as string) == 'Invalid Date'
		) {
			req.get(`/objects/list?limit=1&offset=0`).then(() =>
				interfaceCTX.showMessage({
					body: 'Неверный формат даты. Проверьте правильно ли указан день и/или месяц',
					icon: 'report'
				})
			)
		} else {
			req.get(`/objects/list?limit=1&offset=0`).then(() =>
				interfaceCTX.showMessage({
					body: `Дата окончания работ не может быть меньше даты начала`,
					icon: 'report'
				})
			)
		}
	}

	return (
		<ProgressPage
			state={loaded}
			className={'create-object-page'}
			render={() => (
				<>
					<PageTitle
						title={searchID ? 'Редактирование объекта' : 'Создание объекта'}
						// back={'/objects'}
						breadcrumbs={true}
					/>
					<Form
						name={'createObject'}
						onSubmit={onSubmit}
						useRef={formRef}
						data={[
							[
								{
									values: [
										{
											title: 'Название объекта',
											colSpan: 2,
											var: 'name',
											validate: { required: true, pattern: /^[\w\W\S\s]{1,60}$/, validate: isObjectNameValid },
											errors: {
												pattern: 'Введите не более 60 символов',
												validate: 'Объект с таким названием уже существует. Переименуйте объект'
											},
											defaultValue: object?.name
										},
										{
											title: 'Адрес объекта',
											colSpan: 2,
											var: 'address',
											validate: { required: true, pattern: /^[\w\W\S\s]{1,120}$/ },
											errors: { pattern: 'Введите не более 120 символов' },
											defaultValue: object?.address
										},
										{
											type: 'period',
											title: 'Период работ',
											values: [
												{
													label: 'с',
													var: 'dateStart',
													defaultValue: object?.dateStart,
													validate: { required: true },
													errors: { pattern: 'Введите дату в формате ДД.ММ.ГГГГ' }
												},
												{
													label: 'до',
													var: 'dateEnd',
													defaultValue: object?.dateEnd,
													validate: {
														required: true,
														pattern: /^(0?[1-9]|[12][0-9]|3[01])[\/\.](0?[1-9]|1[012])[\/\.]\d{4}$/
													},
													errors: { pattern: 'Введите дату в формате ДД.ММ.ГГГГ' }
												}
											]
										}
									]
								}
							]
						]}
					/>
					<FixedButtons
						length={3}
						buttons={[
							{
								label: searchID ? 'Сохранить' : 'Создать',
								primary: true,
								onClick: () => formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
							}
						]}
					/>
				</>
			)}
		/>
	)
}
