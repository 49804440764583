import { Icon } from '@rmwc/icon'
import { IBreadcrumbsLocationState, useBreadcrumbs } from 'custom-hooks'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Crumb } from './components/Crumb'
import './PageTitle.style.sass'
import {IPageTitle} from "./PageTitle.types";


export const PageTitle = ({ title, back, children, customBack, breadcrumbs }: IPageTitle): JSX.Element => {
	const location = useLocation<IBreadcrumbsLocationState[]>()
	const { state } = location
	useBreadcrumbs(title as string)

	return (
		<div className='page-title-component'>
			<div className='title-wrapper-b'>
				{customBack && customBack}
				{!back || back == null ? null : back instanceof Function ? (
					<Icon icon='arrow_back_ios_new' className='pointer' onClick={() => back()} />
				) : (
					<Link to={back!}>
						<Icon icon='arrow_back_ios_new' className='pointer' />
					</Link>
				)}
				{React.isValidElement(title) ? (
					title
				) : breadcrumbs && state ? (
					state.map(crumb => crumb.url && <Crumb {...crumb} key={crumb.url} />)
				) : (
					<h2 className='title'>{title}</h2>
				)}
			</div>

			{children && <div className='children'>{children}</div>}
		</div>
	)
}
