import { FC } from 'react'
import { RequestField } from '../request-field'
import { RequestInitialsFieldProps } from './request-initials-field-def'

export const RequestInitialsField: FC<RequestInitialsFieldProps> = ({
	className,
	title,
	firstName,
	middleName,
	lastName,
	...rest
}): JSX.Element => {
	if (firstName) {
		return (
			<RequestField
				className={className}
				title={title}
				value={`${lastName} ${firstName} ${middleName ?? ''}`}
				{...rest}
			/>
		)
	}

	return <></>
}
