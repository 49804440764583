import { INeedTemplateDataRow } from './NeedTemplate.types'

export const dataColumns: INeedTemplateDataRow[][] = [
	[
		{
			title: 'Должность кандидата',
			name: 'position',
			isAbleToBeHidden: false,
			isAbleToBeUnRequired: false
		},
		{
			title: 'Разряд',
			name: 'grade',
			isAbleToBeHidden: true,
			isAbleToBeUnRequired: true
		},
		{
			title: 'Категория',
			name: 'category',
			isAbleToBeHidden: true,
			isAbleToBeUnRequired: true
		},
		{
			title: 'График работы',
			name: 'workSchedule',
			isAbleToBeHidden: false,
			isAbleToBeUnRequired: false
		},
		{
			title: 'Место производства работ',
			name: 'city',
			isAbleToBeHidden: false,
			isAbleToBeUnRequired: false
		},
		{
			title: 'Адрес места работы',
			name: 'address',
			isAbleToBeHidden: true,
			isAbleToBeUnRequired: true
		}
	],
	[
		{
			title: 'Заработная плата (на руки), руб',
			name: 'salaryFullNet',
			isAbleToBeHidden: true,
			isAbleToBeUnRequired: true
		},
		{
			title: 'Предполагаемый срок действия договора',
			name: 'period',
			isAbleToBeHidden: false,
			isAbleToBeUnRequired: false
		},
		{
			title: 'Период испытательного срока, месяцев',
			name: 'probationDurationMonths',
			isAbleToBeHidden: true,
			isAbleToBeUnRequired: true
		},
		{
			title: 'Заработная плата на испытательный срок (на руки), руб',
			name: 'salaryProbationNet',
			isAbleToBeHidden: true,
			isAbleToBeUnRequired: true
		},
		{
			title: 'Функциональные обязанности',
			name: 'responsibilities',
			isAbleToBeHidden: false,
			isAbleToBeUnRequired: false
		},
		{
			title: 'Дополнительная информация',
			name: 'additionalInfo',
			isAbleToBeHidden: true,
			isAbleToBeUnRequired: true
		}
	]
]

export class DataToSend {
	required: {
		[key: string]: boolean
	}
	hidden: {
		[key: string]: boolean
	}

	constructor(data: any) {
		this.required = {}
		this.hidden = {}
		let availableFields: any[] = []
		dataColumns.map(
			column =>
				(availableFields = availableFields.concat(
					column.filter(field => field.isAbleToBeHidden && field.isAbleToBeHidden)
				))
		)
		availableFields.map(field => {
			this.required[field.name] = data[field.name + '_required']
			this.hidden[field.name] = !data[field.name + '_hidden']
		})
	}
}
