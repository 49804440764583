import {ITableCellProps} from "./TableCell.types";
import './TableCell.style.sass'

export function TableCell({className, devider, style, children, index}: ITableCellProps) {
    return (
        <div
            className={`table-cell ${className} ${devider && 'devider'}`}
            style={style}>
            <div className="table-cell-inner" style={ index === 0 ? {width: '30px'} : {}}>
                {children}
            </div>
        </div>
    )
}