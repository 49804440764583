import React, { FC, useCallback } from 'react'
import NumberFormat from 'react-number-format'
import { Controller } from 'react-hook-form'
import { FormFieldLabel } from '../form-field-label/form-field-label'
import { FormField } from '../form-field/form-field'
import { WorkerProfileImage } from '../worker-profile-image/worker-profile-image'
import { PermissionDocumentsFieldProps } from './permission-documents-field-def'
import './permission-documents-field.sass'
import { QualificationDocumentsFieldProps } from '../qualification-documents-field/qualification-documents-field-def'
import { ErrorWrapper } from '../error-wrapper/error-wrapper'
import parse from 'date-fns/parse'
import { differenceInCalendarDays } from 'date-fns'

export const PermissionDocumentsField: FC<PermissionDocumentsFieldProps> = ({
	value,
	onChange,
	control
}) => {
	const handleAddDocument = useCallback(() => {
		if (!value || value.length >= 10) {
			return
		}
		onChange?.([
			...value,
			{ id: -1 * Date.now() } as NonNullable<QualificationDocumentsFieldProps['value']>[number]
		])
	}, [value, onChange])

	const handleDeleteDocument = useCallback(
		documentIdx => {
			onChange?.(value?.filter((_, idx) => idx !== documentIdx))
		},
		[value, onChange]
	)

	return (
		<FormField
			value={value}
			title='Допуски, подтверждающий документ'
			caption={!value?.length ? '(не обязательно)' : undefined}
			label='Добавить документ'
			onClick={handleAddDocument}
			renderItem={(document, index) => {
				return (
					<div className='permission-documents-field-item'>
						<FormFieldLabel title='Номер' value={index} onDelete={handleDeleteDocument} />
						<Controller
							name={`permissionDocuments.${index}.number`}
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Поле обязательно к заполнению'
								},
								maxLength: {
									value: 30,
									message: 'Требуется ввести не более 30 символов'
								}
							}}
							render={({ field, fieldState }) => {
								return (
									<ErrorWrapper error={fieldState.error}>
										{/*//@ts-ignore*/}
										<NumberFormat {...field} className='permission-documents-number-field' />
									</ErrorWrapper>
								)
							}}
						/>

						<Controller
							name={`permissionDocuments.${index}.date`}
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Поле обязательно к заполнению'
								},
								pattern: {
									value: /^(0?[1-9]|[12][0-9]|3[01])[\/\.](0?[1-9]|1[012])[\/\.]\d{4}$/,
									message: 'Введите данные в требуемой форме'
								},
								validate: value => {
									const currentDate = new Date(new Date()).toLocaleString('ru-RU').split(',')[0]
									if (
										(parse(value, 'dd.MM.yyyy', new Date()) as unknown as string) == 'Invalid Date'
									) {
										return 'Неверный формат даты. Проверьте правильно ли указан день и/или месяц'
									}
									if (
										differenceInCalendarDays(
											parse(value, 'dd.MM.yyyy', new Date()).getTime(),
											parse(currentDate, 'dd.MM.yyyy', new Date()).getTime()
										) >= 1
									) {
										return 'Укажите действительную дату выдачи документа'
									}
									return true
								}
							}}
							render={({ field, fieldState }) => {
								return (
									<ErrorWrapper error={fieldState.error}>
										<h4 style={{ margin: '24px 0 14px' }}>Дата выдачи</h4>
										{/*//@ts-ignore*/}
										<NumberFormat
											{...field}
											className='permission-documents-number-field'
											format={'##.##.####'}
											placeholder='ДД.ММ.ГГГГ'
											mask={['Д', 'Д', 'М', 'М', 'Г', 'Г', 'Г', 'Г']}
										/>
									</ErrorWrapper>
								)
							}}
						/>
						<Controller
							name={`permissionDocuments.${index}.path`}
							control={control}
							render={({ field: { name, onChange } }) => (
								<WorkerProfileImage
									id={name}
									image={document}
									onImageDelete={() => {
										onChange(null)
									}}
									onImageChange={file => {
										onChange(URL.createObjectURL(file))
									}}
									className='permission-documents-image-field'
								/>
							)}
						/>
					</div>
				)
			}}
		/>
	)
}
