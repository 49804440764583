import { CustomDialog, FixedButtons, ProgressPage } from "../../../../../../components";
import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import "./VersionsHistoryItem.style.sass";
import { TextField } from "@rmwc/textfield";
import { useTranslation } from "react-i18next";
import { InterfaceContext } from "../../../../../../global/context.interface";
import { req } from "../../../../../../global/common";
import { IMedia, IVersionsHistoryItem } from "./VersionsHistoryItem.types";
import NumberFormat from "react-number-format";
import { Icon } from "@rmwc/icon";

export const VersionsHistoryItem: React.FC<{ platform: "mobile" | "web" }> = ({ platform }) => {
    const history = useHistory();
    const { t } = useTranslation("common");
    const [isRedact, setIsRedact] = useState(false);
    const [newFiles, setNewFiles] = useState<{ id: number, file: File, url: string }[]>([]);
    const [deletedFiles, setDeletedFiles] = useState<IMedia[]>([]);
    const interfaceCTX = useContext(InterfaceContext);
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
    const [versionItem, setVersionItem] = useState<IVersionsHistoryItem>(null as unknown as IVersionsHistoryItem);
    const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
    const [isLoading, setIsLoading] = useState(false);

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            const newFile = e.target.files[0];
            if (newFile.size > 5000000) {
                inputRef.current.value = "";
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: "Максимальный вес файла: 5мб"
                });
                return;
            } else if (!(newFile.type.includes("svg") || newFile.type.includes("png"))) {
                inputRef.current.value = "";
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: "Форматы файла: svg, png"
                });
                return;
            } else {
                let formData = new FormData();
                formData.append("file", newFile);
                const reader = new FileReader();
                reader.addEventListener("load", async () => {
                    let image = new Image();
                    image.src = !!reader.result ? reader.result.toString() : "";
                    let timer = setTimeout(() => {
                        let proportion = image.height / 1.75;
                        if (platform === "mobile" && (proportion < image.width - 2 || proportion > image.width + 2)) {
                            interfaceCTX.showMessage({
                                title: "Ошибка",
                                body: "Допустимое соотношение сторон 1х1,75"
                            });
                            clearTimeout(timer);
                            return;
                        }
                        if ((image.width !== 1097 || image.height !== 1920) && platform === "mobile") {
                            interfaceCTX.showMessage({
                                title: "Ошибка",
                                body: "Допустимое разрешение 1097x1920 пикс."
                            });
                            clearTimeout(timer);
                            return;
                        }
                        if ((image.width !== 1920 || image.height !== 1415) && platform === "web") {
                            interfaceCTX.showMessage({
                                title: "Ошибка",
                                body: "Допустимое разрешение 1920х1415 пикс."
                            });
                            clearTimeout(timer);
                            return;
                        }
                        clearTimeout(timer);
                        setNewFiles(prevState => ([...prevState, {
                            id: Math.random(),
                            // @ts-ignore
                            file: formData as File,
                            url: !!reader.result ? reader.result.toString() : ""
                        }]));
                    }, 100);

                });
                interfaceCTX.checkIsFormChanged(true, "settings");
                reader.readAsDataURL(newFile);
                inputRef.current.value = "";
            }
        }
    }

    const deleteFiles = async (mediaArray: IMedia[]) => {
        await req.post("/moderator/versions/delete-img", {
            ids: mediaArray.map(item => item.id)
        })
            .then(({ data }) => {

            });
    };

    const loadFiles = async (newFiles: { id: number, file: File, url: string }[]) => {
        return newFiles.map(item => {
            return new Promise<IMedia>(function(resolve, reject) {
                req.post("/moderator/versions/add-img", item.file)
                    .then(({ data }) => {
                        resolve(data);
                    });
            });
        });

    };

    const changeVersionItem = async () => {
        try {
            setIsLoading(true);
            if (deletedFiles.length > 0) {
                await deleteFiles(deletedFiles);
            }
            const uploadedFiles = await Promise.all(await loadFiles(newFiles));
            const payloadData = {
                ...versionItem,
                versionNumber: versionItem.versionNumber.includes(".") ? versionItem.versionNumber : versionItem.versionNumber[0] + "." + versionItem.versionNumber.slice(1),
                media: [...versionItem.media, ...uploadedFiles].map((item, index) => ({
                    ...item,
                    id: item.id,
                    position: index + 1
                }))
            };
            req.post("/moderator/organisation/version-description", payloadData)
                .then(({ data }) => {
                    setNewFiles([]);
                    setDeletedFiles([]);
                    setVersionItem(data);
                    setIsRedact(false);
                    setIsLoading(false);
                    interfaceCTX.checkIsFormChanged(false, "settings");
                    interfaceCTX.showMessage({
                        body: "Версия успешно сохранена"
                    });
                    if (!versionItem.id) {
                        history.push({
                            pathname: `${platform}-redact`,
                            state: [{ id: data.id }]
                        });
                    }
                });
        } catch (err) {
            setIsLoading(false);
        }
    };

    const isSelectMode = () => {
        if (Array.isArray(history.location.state)) {
            const currentVersion = history.location.state.find((item: any) => item.id);
            if (currentVersion && currentVersion.id) {
                req.get(`/profile/changelog-info?versionId=${currentVersion.id}`)
                    .then(({ data }) => {
                        setVersionItem(data);
                    });
            } else {
                setIsRedact(true);
                setVersionItem({
                    body: "",
                    media: [],
                    platformType: platform,
                    title: "",
                    versionNumber: ""
                });
            }
        }
    };

    useEffect(() => {
        isSelectMode();
        return () => {
            interfaceCTX.checkIsFormChanged(false, "settings");
        };
    }, []);

    const deleteVersion = () => {
        setIsLoading(true);
        req.delete(`/moderator/versions/delete?id=${versionItem.id}`)
            .then(({ data }) => {
                setIsOpenDeleteDialog(false);
                history.push({
                    pathname: `${platform}-list`
                });
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className={"versions-history-redact"}>
            <ProgressPage
                state={versionItem}
                render={() => (
                    <>
                        <div className={"versions-history-redact-top"}>
                            <FixedButtons
                                className={"buttons"}
                                length={2}
                                buttons={[
                                    {
                                        label: "Удалить",
                                        primary: false,
                                        disabled: false,
                                        outlined: true,
                                        hide: isRedact,
                                        onClick: () => {
                                            setIsOpenDeleteDialog(true);
                                        }
                                    },
                                    {
                                        label: "Редактировать",
                                        primary: true,
                                        disabled: false,
                                        hide: isRedact,
                                        onClick: () => {
                                            setIsRedact(true);
                                        }
                                    }
                                ]}
                            />
                        </div>
                        <CustomDialog
                            isOpen={isOpenDeleteDialog}
                            onClose={() => setIsOpenDeleteDialog(false)}
                            dialogTitle={"Удалить это обновление?"}
                            buttons={[
                                {
                                    callback: () => {
                                        deleteVersion();
                                    },
                                    outlined: false,
                                    raised: false,
                                    disabled: isLoading,
                                    text: "Удалить"
                                },
                                {
                                    callback: () => {
                                        setIsOpenDeleteDialog(false);
                                        setIsRedact(true);
                                    },
                                    outlined: true,
                                    raised: true,
                                    disabled: isLoading,
                                    text: "Редактировать"
                                }
                            ]}
                        >

                            <div className={"dialog-delete-body"}>
                                Вы не сможете восстановить это обновление.
                            </div>
                        </CustomDialog>
                        <div className={"versions-history-redact-body"}>
                            <div className={"versions-history-redact-field"}>
                                <div className={"versions-history-redact-field-headline"}>
                                    Версия
                                </div>
                                {isRedact
                                    ? <NumberFormat
                                        maxLength={125}
                                        style={{ width: "100%" }}
                                        className={"versions-history-redact-field-textfield"}
                                        placeholder="_.__"
                                        format="#.##" mask="-"
                                        value={versionItem?.versionNumber}
                                        onValueChange={v => {
                                            const value = v.value;
                                            interfaceCTX.checkIsFormChanged(true, "settings");
                                            if (value === " ") return;
                                            setVersionItem((prevState: IVersionsHistoryItem) => ({
                                                ...prevState,
                                                versionNumber: value
                                            }));
                                        }}
                                    />
                                    : <div className={"versions-history-view-textfield"}>
                                        {versionItem?.versionNumber}
                                    </div>
                                }
                            </div>
                            <div className={"versions-history-redact-field"}>
                                <div className={"versions-history-redact-field-headline"}>
                                    Заголовок обновления
                                </div>
                                {isRedact
                                    ? <TextField
                                        maxLength={150}
                                        outlined
                                        style={{ width: "100%" }}
                                        className={"versions-history-redact-field-textfield"}
                                        value={versionItem?.title}
                                        onChange={(e) => {
                                            const value = e.currentTarget.value;
                                            interfaceCTX.checkIsFormChanged(true, "settings");
                                            if (value === " ") return;
                                            setVersionItem((prevState: IVersionsHistoryItem) => ({
                                                ...prevState,
                                                title: value
                                            }));
                                        }}
                                    />
                                    : <div className={"versions-history-view-textfield"}>
                                        {versionItem?.title}
                                    </div>
                                }
                            </div>
                            <div className={"versions-history-redact-field"}>

                                {isRedact
                                    ? <>
                                        <div className={"versions-history-redact-field-headline"}>
                                            Описание
                                        </div>
                                        <TextField
                                            maxLength={1500}
                                            outlined
                                            textarea
                                            style={{ width: "100%" }}
                                            className={"versions-history-redact-field-textarea"}
                                            value={versionItem?.body}
                                            rows={3}
                                            onChange={(e) => {
                                                const value = e.currentTarget.value;
                                                interfaceCTX.checkIsFormChanged(true, "settings");
                                                if (value === " ") return;
                                                setVersionItem((prevState: IVersionsHistoryItem) => ({
                                                    ...prevState,
                                                    body: value
                                                }));
                                            }}
                                        />
                                    </>
                                    : versionItem.body &&
                                    <>
                                        <div className={"versions-history-redact-field-headline"}>
                                            Описание
                                        </div>
                                        <div className={"versions-history-view-textarea"}>
                                            {versionItem?.body}
                                        </div>
                                    </>
                                }
                            </div>
                            <div className={"versions-history-redact-field"}>
                                {(isRedact || (versionItem.media.length > 0 || newFiles.length > 0)) &&
                                    <div className={"versions-history-redact-field-headline"}>
                                        Прикрепленные файлы
                                    </div>
                                }
                                <div className={"versions-history-redact-files"}>
                                    {versionItem.media.map(item => {
                                        return (
                                                <div
                                                    className={`versions-history-redact-file versions-history-redact-file-loaded ${platform === "web" ? "web-version" : "mobile-version"}`}
                                                    style={{ backgroundImage: `url(${item.path})` }} key={item.id}>
                                                    {isRedact && <Icon
                                                        className="close"
                                                        icon={{ icon: "close", size: "large" }}
                                                        onClick={() => {
                                                            interfaceCTX.checkIsFormChanged(true, "settings");
                                                            setDeletedFiles(prevState => ([...prevState, item]));
                                                            setVersionItem(prevState => ({
                                                                ...prevState,
                                                                media: prevState.media.filter(value => value.id !== item.id)
                                                            }));
                                                        }}
                                                    />
                                                    }
                                                    <Icon icon={"zoom_in"} className={`zoom_in zoom_in_${platform === "web" ? "web" : "mobile"}`}
                                                          onClick={() => interfaceCTX.getPreviewImgUrl(item.path)} />
                                                </div>
                                        );
                                    })}
                                    {newFiles.map(item => {
                                        return (
                                                <div
                                                    className={`versions-history-redact-file versions-history-redact-file-loaded ${platform === "web" ? "web-version" : "mobile-version"}`}
                                                    style={{ backgroundImage: `url(${item.url})` }}
                                                    key={item.id}>
                                                    <Icon
                                                        className="close"
                                                        icon={{ icon: "close", size: "large" }}
                                                        onClick={() => {
                                                            interfaceCTX.checkIsFormChanged(true, "settings");
                                                            setNewFiles(prevState => prevState.filter(value => value.id !== item.id));
                                                        }}
                                                    />
                                                    <Icon icon={"zoom_in"} className={`zoom_in zoom_in_${platform === "web" ? "web" : "mobile"}`}
                                                          onClick={() => interfaceCTX.getPreviewImgUrl(item.url)} />
                                                </div>
                                        );
                                    })}
                                    {isRedact && !(versionItem.media.length + newFiles.length + 1 > 18) &&
                                        <div
                                            className={`versions-history-redact-files-upload versions-history-redact-file ${platform === "web" ? "web-version-upload" : "mobile-version-upload"}`}>
                                            <Icon className="add" icon={{ icon: "add", size: "xlarge" }} />
                                            <div className={"versions-history-redact-files-upload-description"}>
                                                <div>{platform === "mobile" ? "Мин. размер 1097x1920 рх" : "Мин. размер 1920х1415 рх"}</div>
                                                <div>Максимальный вес 5 МВ</div>
                                                <div>Формат файла: SVG, PNG</div>
                                            </div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                ref={inputRef}
                                                onChange={onSelectFile}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={"versions-history-redact-bottom"}>
                            <FixedButtons
                                length={2}
                                className={"buttons"}
                                buttons={[
                                    {
                                        label: "Сохранить",
                                        className: "button",
                                        onClick: () => {
                                            changeVersionItem();
                                        },
                                        primary: true,
                                        disabled: ((versionItem.versionNumber === "" || versionItem.title === "" || !(versionItem.media.length !== 0 || newFiles.length !== 0)) || isLoading),
                                        hide: !isRedact
                                    },
                                    {
                                        label: "Отменить",
                                        className: "button",
                                        onClick: () => {
                                            history.push(`${platform}-list`);
                                        },
                                        primary: false,
                                        hide: !isRedact
                                    }
                                ]}
                            />
                        </div>
                    </>
                )}
            />
        </div>
    );
};