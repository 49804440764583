import { AxiosError } from 'axios'
import {
	MyReviewResponseList,
	ReviewCandidatesList,
	ReviewCreateParam,
	ReviewResponse,
	reviewsApi,
	ReviewUpdateParam
} from 'global/api'
import { InterfaceContext } from 'global/context.interface'
import { MyReviewItem, ReviewCandidate } from 'global/definitions'
import { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isReview } from '../pending-review-utils'
import { PendingReviewCardModeType, PendingReviewContext } from './PendingReviewContext'

export const PendingReviewContextProvider: FC = ({ children }): JSX.Element => {
	const { t } = useTranslation('common')
	const { showMessage } = useContext(InterfaceContext)
	const [reviews, setReviews] = useState<MyReviewResponseList | ReviewCandidatesList>({ data: [], total: 0 })
	const [mode, setMode] = useState<PendingReviewCardModeType>('create')
	const [selectedReview, setSelectedReview] = useState<ReviewCandidate | MyReviewItem>({} as ReviewCandidate)
	const [isDirty, setIsDirty] = useState(false)

	useEffect(() => {
		setSelectedReview({} as ReviewCandidate)
	}, [reviews])

	const onCreateReview = (userId: number, values: ReviewCreateParam) => {
		reviewsApi
			.sendReviewOnTheUser(userId, values)
			.then(() => {
				const filteredData = reviews.data.filter(
					review =>
						review.contract.id !== selectedReview.contract.id ||
						review.profile.id !== selectedReview.profile.id ||
						review.profile.currentRole !== selectedReview.profile.currentRole
				)
				setReviews({ total: reviews.total - 1, data: filteredData })
			})
			.catch((e: AxiosError) => {
				showMessage({ title: 'Ошибка', body: t('review.create' + e.response?.data) })
			})
	}

	const onUpdateReview = (values: ReviewUpdateParam) => {
		isReview(selectedReview) &&
			reviewsApi
				.updateMyReview(selectedReview.review.id, {
					comment: values.comment,
					score: values.score
				})
				.then(response => {
					const updateData = reviews.data.map(review =>
						isReview(review) && review.review.id === response.data.id ? { ...review, review: response.data } : review
					)
					setReviews({ ...reviews, data: updateData })
				})
				.catch((e: AxiosError) => {
					showMessage({ title: 'Ошибка', body: t('review.update' + e.response?.data) })
				})
	}

	const onDeleteReview = () => {
		isReview(selectedReview) &&
			reviewsApi
				.deleteMyReview(selectedReview.review.id)
				.then(() => {
					const filterData = reviews.data.filter(
						review => isReview(review) && review.review.id !== selectedReview.review.id
					)
					setReviews({ total: reviews.total - 1, data: filterData })
				})
				.catch((e: AxiosError) => {
					showMessage({ title: 'Ошибка', body: t('review.update' + e.response?.data) })
				})
	}

	return (
		<PendingReviewContext.Provider
			value={{
				reviews,
				selectedReview,
				mode,
				isDirty,
				setReviews,
				setIsDirty,
				setSelectedReview,
				setMode,
				onCreateReview,
				onUpdateReview,
				onDeleteReview
			}}
		>
			{children}
		</PendingReviewContext.Provider>
	)
}
