import { getFormattedDeclinableDate } from 'global/utils'
import { FC, useCallback } from 'react'
import { InfoBlock, SpanButtons } from 'components'
import { Document } from '../documents/components/document/document'
import { ProfessionalExperienceSubPageProps } from './professional-experience-def'
import './professional-experience.sass'

export const ProfessionalExperienceSubPage: FC<ProfessionalExperienceSubPageProps> = ({
	workerProfile,
	onClickEditButtonTabs,
	isDisabledBlock
}) => {
	const { mainProfession, grade, subProfessions, qualificationDocuments, permissions } = workerProfile

	const handleClick = useCallback(() => {
		onClickEditButtonTabs('professional-experience')
	}, [])

	return (
		<div className='professional-exp-subpage-wrapper'>
			<div className='buttons-edit'>
				<SpanButtons
					data={[
						{
							label: 'Редактировать',
							onClick: handleClick,
							disabled: isDisabledBlock
						}
					]}
				/>
			</div>

			<div className='profession-info-wrapper'>
				<ul className='profession-info-list'>
					<li className='profession-info-item'>
						{!!mainProfession && <InfoBlock title='Базовая профессия' content={mainProfession?.name} />}
					</li>
					<li className='profession-info-item'>{!!grade && <InfoBlock title='Разряд' content={grade} />}</li>

					<li className='profession-info-item'>
						{!!subProfessions?.length && (
							<InfoBlock
								title='Дополнительная профессия'
								content={
									<ul className='additional-professions-list'>
										{subProfessions?.map(prof => (
											<li key={prof?.id} className='additional-profession'>
												{prof?.name}
											</li>
										))}
									</ul>
								}
							/>
						)}
					</li>
				</ul>
			</div>
			{!!qualificationDocuments?.length && (
				<div className='section'>
					<div className='section-title'>Удостоверения, подтверждающие квалификацию</div>

					<div className='documents-list'>
						{qualificationDocuments?.map((doc: any, index: number) => (
							<Document
								key={doc.id + '_' + index}
								docNumber={doc.number}
								issueDate={doc.date}
								pathPreview={!!doc?.pathPreview && doc.pathPreview}
								path={!!doc?.path && doc.path}
							/>
						))}
					</div>
				</div>
			)}
			{!!workerProfile?.permissions?.length && (
				<div className='section'>
					<InfoBlock
						title='Допуски'
						content={
							permissions?.map(({ name, id }) => (
								<p key={id} className='allowance-text'>
									{name}
								</p>
							)) || ([] as any)
						}
					/>
				</div>
			)}
			{!!workerProfile?.permissionDocuments?.length && (
				<div className='section'>
					<div className='section-title'>Допуски, подтвеждающий документ</div>
					<div className='documents-list'>
						{workerProfile?.permissionDocuments?.map((doc: any, index: number) => (
							<Document
								key={doc.id + '_' + index}
								docNumber={doc.number}
								issueDate={doc.date}
								pathPreview={doc.pathPreview && doc.pathPreview}
								path={doc.path && doc.path}
							/>
						))}
					</div>
				</div>
			)}
			{(!!workerProfile?.experience?.length || workerProfile?.withoutExperience) && (
				<div className='experience-wrapper section'>
					<div className='title'>Опыт работы</div>
					{workerProfile.experience && (
						<ul className='experience-card-list'>
							{workerProfile.experience.map((exp: any, index: number) => {
								return (
									<li key={exp.type + ' ' + index} className='experience'>
										<div className='experience-inner'>
											<ul className='experience-list'>
												<li className='experience-item'>
													<InfoBlock title='Наименование производства' content={exp.production} />
												</li>
												<li className='experience-item'>
													<InfoBlock title='Наименование агрегата' content={exp.unit} />
												</li>
												<li className='experience-item'>
													<InfoBlock title='Тип работ' content={exp.type} />
												</li>
												<li className='experience-item'>
													<InfoBlock
														title='Опыт работы'
														content={getFormattedDeclinableDate(exp.expYears, exp.expMonths)}
													/>
												</li>
											</ul>
										</div>
									</li>
								)
							})}
						</ul>
					)}
					{workerProfile.withoutExperience && <span>Без опыта работы</span>}
				</div>
			)}
		</div>
	)
}
