import React, {useEffect, useState} from 'react'
import {PageTitle, TabButtons} from 'components'

import {PublicProfilePage} from "./components/PublicProfile/PublicProfile";
import {InterfaceContext} from "../../../global/context.interface";
import {PaymentHistory} from "./components/PaymentHistory/PaymentHistory";
import {CurrentTariff} from "../../../components/CurrentTariff";
import {PublicProfile} from "../../public-profile/components/PublicProfilePage/public-profile-def";
import {req} from "../../../global/common";
import {useParams} from "react-router-dom";
import {ConnectedModules} from "./components/ConnectedModules/ConnectedModules";
import { PageHeader } from 'components/PageHeader/PageHeader';

export function OrganizationProfile() {
    const interfaceCTX = React.useContext(InterfaceContext)
    const {orgID, key} = useParams() as { orgID: string; key: string }

    let formChanged = Object.values(interfaceCTX.isFormChanged).filter(v => v == true).length != 0

    const tabClick = () => {
        if (formChanged) {
            if (window.confirm('Все несохраненные данные будут удалены. Вы уверены?')) {
                interfaceCTX.resetIsFormChanged()
                return true
            } else return false
        } else return true
    }

    const [coins, setCoins] = useState<number | undefined>( undefined)


    useEffect(() => {
        req.post(`/moderation/get`, {
            key: key
        })
            .then(({data}) => {
                setCoins(new PublicProfile(data).workCoins)
            })
    }, [])

    return (
        <div className={'organization-profile-moderator'}>
            <PageHeader>
                <PageTitle title={'Профиль организации'} breadcrumbs={true}/>
                {coins !== undefined && <CurrentTariff organizationID={orgID} />}
            </PageHeader>
            <TabButtons
                data={{
                    'Профиль': () => <PublicProfilePage/>,
                    'История платежей': () => <PaymentHistory/>,
                    'Подключенные модули': () => <ConnectedModules/>,
                }}
                embed={[true, true, true, true, false, true]}
                tabClick={tabClick}
                whiteBackground={false}
            />

        </div>
    )
}