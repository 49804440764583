import { useProfessions } from 'pages/auth-as-worker/hooks'
import { ChangeEvent, FC, useCallback, useRef, useState } from 'react'
import { FormFieldLabel } from '../form-field-label/form-field-label'
import { FormField } from '../form-field/form-field'
import { RadioOptionsDialog } from '../radio-options-dialog/radio-options-dialog'
import { MainProfessionFieldProps } from './main-profession-field-def'

export const MainProfessionField: FC<MainProfessionFieldProps> = ({ value, onChange }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [queryParams, setQueryParams] = useState({ limit: 99, offset: 0, search: '' })
	const { professions, total } = useProfessions(queryParams.limit, queryParams.offset, queryParams.search)
	const ref = useRef<HTMLDivElement>(null)

	const openOptionDialogHandler = useCallback(() => {
		setIsOpen(true)
	}, [])

	const closeOptionDialogHandler = () => {
		setQueryParams({ ...queryParams, search: '' })
		setIsOpen(false)
	}

	const handleDeleteValue = useCallback(() => {
		onChange?.({ id: 0, name: '' })
	}, [onChange])

	const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
		setQueryParams({ ...queryParams, offset: 0, search: e.target.value })
	}

	const scrollHandler = () => {
		if (ref.current && queryParams.offset <= total) {
			if (
				ref.current.scrollTop > 0 &&
				ref.current.scrollTop + ref.current.offsetHeight > ref.current.scrollHeight - 1
			) {
				setQueryParams({ ...queryParams, offset: queryParams.offset + queryParams.limit })
			}
		}
	}

	return (
		<>
			<FormField
				value={value || 0}
				label={value?.id !== 0 && value !== undefined ? 'Сменить базовую профессию' : 'Выбрать базовую профессию'}
				onClick={openOptionDialogHandler}
				renderItem={mainProfession => {
					if (mainProfession && mainProfession.id !== 0) {
						return <FormFieldLabel title={mainProfession.name} onDelete={handleDeleteValue} />
					} else {
						return <></>
					}
				}}
			/>
			<RadioOptionsDialog
				ref={ref}
				title='Базовая профессия'
				search={queryParams.search}
				defaultValue={{ id: value?.id || 0, name: value?.name || '' }}
				options={professions.map(p => ({ name: p.name, value: p.id }))}
				hasSearch={true}
				open={isOpen}
				onChangeSearch={inputHandler}
				onClose={closeOptionDialogHandler}
				onSuccess={onChange}
				onListScroll={scrollHandler}
			/>
		</>
	)
}
