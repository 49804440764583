import { IOrganizationProfileDataRow } from "./PublicOrganizationProfile.types";

export const dataColumns: IOrganizationProfileDataRow[][] =
    [
        [
            {
                title: "Полное наименование",
                name: "fullName",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Краткое наименование",
                name: "shortName",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Юридический адрес",
                name: "addressRegistration",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Фактический адрес",
                name: "addressActual",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Контактный телефон",
                name: "phone",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Контактный e-mail",
                name: "email",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Сайт",
                name: "www",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Twitter",
                name: "socialTwitter",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            // {
            //     title: "Facebook",
            //     name: "socialFacebook",
            //     isAbleToBeHidden: true,
            //     isAbleToBeUnRequired: true
            // },
            {
                title: "Telegram",
                name: "socialTelegram",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "О компании",
                name: "about",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
        ],
        [
            {
                title: "Основной вид деятельности",
                name: "specialization",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Дополнительный вид деятельности",
                name: "subSpecializations",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "ОГРН",
                name: "ogrn",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "ИНН",
                name: "inn",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "КПП",
                name: "kpp",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Р/С",
                name: "checkingAccount",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "К/С",
                name: "correspondentAccount",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Наименование банка",
                name: "bankName",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "БИК",
                name: "bankCode",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },

        ]
    ]


export class DataToSend {
    required: {
        [key: string]: string
    }
    hidden: {
        [key: string]: string
    }

    constructor(data: any) {
        this.required = {}
        this.hidden = {}
        let availableFields: any[] = []
        dataColumns.map(column => availableFields = availableFields.concat(column.filter(field => field.isAbleToBeHidden && field.isAbleToBeHidden)))
        availableFields.map(field => {
            this.required[field.name] = data[field.name + "_required"]
            this.hidden[field.name] = data[field.name + "_hidden"]
        })
    }
}