import React, { useEffect, useState } from "react";
import "./VacancyCard.style.sass";
import { useTranslation } from "react-i18next";
import { Icon } from "@rmwc/icon";
import { Button } from "@rmwc/button";
import { IVacancyItem } from "../../VacanciesList.types";
import { InterfaceContext } from "../../../../global/context.interface";
import { UserContext } from "../../../../global/context.user";
import { PagesRoutes } from "../../../../global/routes";
import { useHistory } from "react-router-dom";
import { ExtensionVacancy } from "../../../../components/ExtensionVacancy/ExtensionVacancy";
import { Tooltip } from "@rmwc/tooltip";
import { isSuperAdm } from "../../../../global/common";

export const VacancyCard: React.FC<IVacancyItem> = ({
                                                        id,
                                                        city,
                                                        daysBeforeTheEndPublication,
                                                        vacancyState,
                                                        position,
                                                        author,
                                                        datePublication,
                                                        dateWithdrawnFromPublicationAt,
                                                        dateCreatedAt,
                                                        dateDeletedAt,
                                                        responseCount,
                                                        refreshData,
                                                        sinceLastView
                                                    }) => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const [isOpenDialogPublish, setIsOpenDialogPublish] = useState(false);
    const interfaceCTX = React.useContext(InterfaceContext);
    const userCTX = React.useContext(UserContext);
    const [daysBeforeTheEnd, setDaysBeforeTheEnd] = useState<number>(0);

    const renderStatus = (status: string) => {
        if (status === "saved") {
            return "#EBFF00";
        } else if (status === "published") {
            return "#33FF00";
        } else if (status === "withdrawn_from_publication") {
            return "#ED0000";
        } else if (status === "deleted") {
            return "#9AA2B0";
        }
    };

    const updateDaysBeforeTheEnd = () => {
        setDaysBeforeTheEnd(prevState => prevState + 30);
        refreshData();
    };

    const declOfNum = (n: number, text_forms: string[]) => {
        n = Math.abs(n) % 100;
        let n1 = n % 10;
        if (n > 10 && n < 20) {
            return text_forms[2];
        }
        if (n1 > 1 && n1 < 5) {
            return text_forms[1];
        }
        if (n1 == 1) {
            return text_forms[0];
        }
        return text_forms[2];
    };

    const renderInfo = (status: string) => {
        if (status === "saved") {
            return (
                <div className={"vacancy-card-item-top-info"}>
                    Создано {dateCreatedAt}
                </div>
            );
        } else if (status === "published") {
            return (
                <div className={"vacancy-card-item-top-info"}>
                    <div>
                        До окончания
                        публикации {daysBeforeTheEnd + declOfNum(daysBeforeTheEnd, [" день", " дня", " дней"])}
                    </div>
                    {!isSuperAdm(userCTX.user) &&
                        <div
                            className={"vacancy-card-item-top-info-btn"}
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsOpenDialogPublish(true);
                            }}
                        >
                            Продлить
                        </div>
                    }
                </div>
            );
        } else if (status === "withdrawn_from_publication") {
            return (
                <div className={"vacancy-card-item-top-info"}>
                    Снята с публикации {dateWithdrawnFromPublicationAt}
                </div>
            );
        } else if (status === "deleted") {
            return (
                <div className={"vacancy-card-item-top-info"}>
                    Дата удаления {dateDeletedAt}
                </div>
            );
        }
    };

    const renderStatusDescription = (status: string) => {
        let text: string = "";
        if (status === "saved") {
            text = "Вакансия сохранена в режиме черновика, ее могут видеть другие пользователи организации";
        } else if (status === "published") {
            text = "Вакансия находится в публичном доступе, соискатели могут на нее откликнуться";
        } else if (status === "withdrawn_from_publication") {
            text = "Срок публикации вакансии завершился, она будет доступна соискателям после продления ";
        } else if (status === "deleted") {
            text = "Вакансия удалена без возможности восстановления";
        }
        return (
            <Tooltip
                content={
                    <div style={{ width: "350px" }}>
                        {text}
                    </div>
                }
                activateOn={["hover"]}
            >
                <Icon icon={"info_outline"} />
            </Tooltip>
        );
    };

    useEffect(() => {
        setDaysBeforeTheEnd(daysBeforeTheEndPublication);
    }, []);

    return (
        <div className={"vacancy-card-item"}
             onClick={() => {
                 history.push({
                     pathname: PagesRoutes.VACANCIES_LIST + PagesRoutes.VACANCY_PAGE + `/${id}`,
                     state: history.location.state
                 });
             }}>
            <ExtensionVacancy
                id={id}
                updateDaysBeforeTheEnd={updateDaysBeforeTheEnd}
                isOpenDialogPublish={isOpenDialogPublish}
                setIsOpenDialogPublish={setIsOpenDialogPublish}
            />
            <div className={"vacancy-card-item-top"}>
                {renderInfo(vacancyState)}
                <div className={"vacancy-card-item-top-status"}>
                    <div className={"vacancy-card-item-top-circle"}
                         style={{ backgroundColor: renderStatus(vacancyState) }} />
                    <div className={"vacancy-card-item-top-title"}>
                        {t("vacancy." + vacancyState)}
                    </div>
                    {renderStatusDescription(vacancyState)}
                </div>
            </div>
            <div className={"vacancy-card-item-body"}>
                <div className={"vacancy-card-item-body-position"}>
                    {position}
                </div>
                <div className={"vacancy-card-item-body-author"}>
                    {author}
                </div>
                <div className={"vacancy-card-item-body-city"}>
                    <Icon icon={"home_work"} />
                    <div
                        className={`${city ? "vacancy-card-item-body-city-title" : "vacancy-card-item-body-city-title-none"}`}>
                        {city ? city : "Город не указан"}
                    </div>
                </div>
            </div>
            <div className={"vacancy-card-item-footer"}
                 style={vacancyState === "saved" ? {cursor: "default"} : {}}
                 onClick={(event) => {
                     if (vacancyState === "saved" || !responseCount)  {
                         event.stopPropagation();
                     }
                 }}
            >
                {vacancyState !== "deleted" &&
                    <Button
                        raised
                        disabled={vacancyState === "saved" || (!responseCount && vacancyState !== "withdrawn_from_publication") || (vacancyState === "withdrawn_from_publication" && isSuperAdm(userCTX.user))}
                        className={"vacancy-card-item-footer-btn"}
                        onClick={(event) => {
                            event.stopPropagation();
                            if (vacancyState === "withdrawn_from_publication") {
                                setIsOpenDialogPublish(true);
                            } else {
                                history.push({
                                    pathname: PagesRoutes.VACANCIES_LIST + "/response-list/" + id,
                                    state: history.location.state
                                });
                            }
                        }}
                    >
                        {vacancyState === "withdrawn_from_publication"
                            ? <div>Продлить</div>
                            : !responseCount ? "Пока нет откликов"
                            : <div style={{display: "flex"}}>
                                Смотреть отклики&nbsp;
                                {!sinceLastView && !!responseCount && `(${responseCount})`}
                                {!!sinceLastView && !!responseCount &&
                                    <div style={{display: "flex"}}>
                                        {` (${responseCount}`}
                                        <div style={{color: "#FFA800", display: "flex"}}>&nbsp;{`+${sinceLastView}`}</div>)
                                    </div>
                                }
                            </div>
                        }
                    </Button>
                }
            </div>
        </div>
    );
};