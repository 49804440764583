import { number } from "prop-types";

export const numberOfMonthsOfSubscription = [
    {
        value: null,
        label: '--Выбрать--',
    },
    {
        value: '1',
        label: 'Месяц',
    },
    {
        value: '3',
        label: 'Три месяца',
    },
    {
        value: '12',
        label: 'Год',
    },
    {
        value: 'other',
        label: 'Другое',
    },
]

export const reasonForStoppingTheModule = [
    {
        value: null,
        label: '--Выбрать--',
    },
    {
        value: 'Не оплачена подписка в срок',
        label: 'Не оплачена подписка в срок',
    },
    {
        value: 'Отказ от подписки',
        label: 'Отказ от подписки',
    },
    {
        value: 'other',
        label: 'Другая причина',
    },
]

export const reasonAddingPackage = [
    {
        value: null,
        label: '--Выбрать--',
    },
    {
        value: 'receiptPayment',
        label: 'Поступление оплаты',
    },
    {
        value: 'promo',
        label: 'Промо',
    },

]

export const defaultModuleItemValue = {
    isOpen: false,
    price: 0,
    reason: null,
    moduleType: null,
    rationale: null
}

export const pickColor = (status: string) => {
    if (status === 'suspended') {
        return {
            cursor: 'pointer',
            backgroundColor: '#FFA8001A'
        }
    }
    if (status === 'disable') {
        return {
            backgroundColor: '#ED00001A'
        }
    }
    if (status === 'active') {
        return {
            backgroundColor: '#008E1F1A'
        }
    }
}