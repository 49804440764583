import { MyReviewResponseList, ReviewCandidatesList, ReviewCreateParam, ReviewUpdateParam } from 'global/api'
import { MyReviewItem, ReviewCandidate } from 'global/definitions'
import { createContext } from 'react'

export type PendingReviewCardModeType = 'create' | 'update'

export interface PendingReviewContextProps {
	reviews: MyReviewResponseList | ReviewCandidatesList
	selectedReview: ReviewCandidate | MyReviewItem
	mode: PendingReviewCardModeType
	isDirty: boolean
	setIsDirty: (isDirty: boolean) => void
	setReviews: (reviews: MyReviewResponseList | ReviewCandidatesList) => void
	setSelectedReview: (review: ReviewCandidate | MyReviewItem) => void
	setMode: (mode: PendingReviewCardModeType) => void
	onCreateReview: (userId: number, values: ReviewCreateParam) => void
	onUpdateReview: (values: ReviewUpdateParam) => void
	onDeleteReview: (reviewId: number) => void
}

export const PendingReviewContext = createContext<PendingReviewContextProps>({} as PendingReviewContextProps)
