import { ErrorPage, OrganizationRegistration, Signin } from 'pages'
import { Route } from './routes-def'
import { initialWebRoles } from './routes-utils'
import {ProfilePage} from "../../pages/ProfilePage/ProfilePage";
import {PublicProfile} from "../../pages/public-profile/PublicProfilePage";
import {UpdateHistory} from "../../pages/UpdateHistory";
import { SurveyListPage } from "../../pages/SurveyListPage";
import { Feedback } from "../../pages/Feedback";

export const commonRoutes: Route[] = [
	// { title: '7357', path: '/brigade-render', component: <TestPage />, navBar: false },
	{
		title: 'Вход',
		path: '/signin',
		component: <Signin />,
		navBar: false
	},
	{
		title: 'Вход',
		path: '/organization-signin',
		component: <OrganizationRegistration />,
		navBar: false
	},
	{
		title: 'Профиль',
		path: '/profile/',
		component: <ProfilePage />,
		navBar: false,
		icon: 'account_circle',
		availableRoles: initialWebRoles
	},
	{
		title: 'Профиль организации',
		path: '/public-profile/:searchID?/:status?',
		component: <PublicProfile />,
		navBar: false,
		icon: 'home',
		availableRoles: initialWebRoles
	},
	{
		title: 'Инструкции',
		path: '/update-history',
		component: <UpdateHistory />,
		navBar: false,
		icon: 'home',
		availableRoles: [...initialWebRoles, 'worker']
	},
	{
		title: 'Опрос',
		path: '/survey-list',
		component: <SurveyListPage />,
		navBar: false,
		icon: 'home',
		availableRoles: [...initialWebRoles, 'worker']
	},
	{
		title: 'Помощь',
		path: '/feedback',
		component: <Feedback />,
		navBar: false,
		icon: 'home',
		availableRoles: [...initialWebRoles, 'worker']
	},
	{
		title: 'Страница не найдена',
		path: '/404',
		component: <ErrorPage code='404' />,
		navBar: false,
		availableRoles: initialWebRoles
	}
]
