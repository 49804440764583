import { Button } from '@rmwc/button'
import { CircularProgress } from '@rmwc/circular-progress'
import clsx from 'clsx'
import { requestsApi } from 'global/api'
import { InterfaceContext } from 'global/context.interface'
import { getFormattedCurrency } from 'global/utils'
import { PagesRoutes } from 'global/routes'
import { FC, MouseEvent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { RequestCardProps } from './request-card-def'
import './request-card.sass'

export const RequestCard: FC<RequestCardProps> = ({ className, request }): JSX.Element => {
	const { showMessage } = useContext(InterfaceContext)
	const { t } = useTranslation('common')
	const history = useHistory()
	const [requestData, setRequestData] = useState(request)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		setRequestData(request)
	}, [request])

	const responseHandler = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		e.preventDefault()
		setIsLoading(true)

		switch (requestData.state) {
			case 'open':
			case 'cancelled':
				requestsApi
					.applyToTheRequest(request.id)
					.then(response => {
						setRequestData(response.data)
						setIsLoading(false)
					})
					.catch(e => {
						setIsLoading(false)
						showMessage({
							title: 'Ошибка',
							body: t('profile.worker.' + e.response.data)
						})
					})
				break
			default:
				requestsApi.cancelRequest(request.id).then(response => {
					setRequestData(response.data)
					setIsLoading(false)
				})
		}
	}

	return (
		<Link
			to={{
				pathname: PagesRoutes.WORKER_NEED_LIST + '/' + requestData.id,
				state: history.location.state
			}}
		>
			<div className={clsx('request-card', className)}>
				<div className='request-card-publish-date'>Опубликовано {requestData.date}</div>
				<div className='request-card-position'>{requestData.position}</div>
				<div className='request-card-salary'>{getFormattedCurrency(requestData.salaryFullNet || 0)}</div>
				<div className='request-card-city'>{requestData.city}</div>
				<div className='request-card-organisation'>{requestData.organisation}</div>
				<div className='request-card-need-field'>
					<div className='request-card-need-field-label'>Срок действия договора</div>
					<div className='request-card-need-field-value'>
						{requestData.workFrom} - {requestData.workTo}
					</div>
				</div>
				<div className='request-card-shedule'>{requestData.schedule?.name}</div>
				<div
					className={clsx('request-card-responsibilities', {
						'request-card-responsibilities-no-responsed': requestData.respondedAt
					})}
				>
					{request.responsibilities}
				</div>
				{requestData.respondedAt &&
					requestData.state !== 'cancelled' &&
					request.state !== 'accepted' &&
					request.state !== 'rejected' && (
						<div className={'request-card-responde-at'}>
							<div>Отклик направлен</div>
							<div>{requestData.respondedAt}</div>
						</div>
					)}
				{request.state !== 'rejected' && request.state !== 'accepted' && (
					<Button
						className={clsx('request-card-button', {
							'request-card-button-outlined': requestData.state !== 'open' && requestData.state !== 'cancelled'
						})}
						icon={
							isLoading && (
								<CircularProgress
									className={clsx({
										'request-card-button-circular-progress-white':
											requestData.state === 'open' || requestData.state === 'cancelled'
									})}
								/>
							)
						}
						raised
						onClick={responseHandler}
					>
						{requestData.state === 'open' || requestData.state === 'cancelled' ? 'Откликнуться' : 'Отозвать отклик'}
					</Button>
				)}
			</div>
		</Link>
	)
}
