import { RequestStatusType } from "./worker-request-list-def"

export const getPageTitleByStatus = (status: RequestStatusType | null) => {
	switch (status) {
		case 'pending':
			return 'Направлено откликов'
		case 'consideration':
			return 'На стадии переговоров'
		case 'rejected':
			return 'Отказ в приеме'
		default:
			return 'Список потребностей'
	}
}
