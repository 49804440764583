export function stringToURL(value:string) {
    if (value.includes('http') || value.includes('https')) {
        if (value.includes('www')) {
            return value
        }
        return value
    }
    else {
        value = "http://" + value
        if (value.includes('www')) {
            return value
        }
        else {
            return value
        }
    }
}