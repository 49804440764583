import React, { FC, useCallback } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { Dialog } from "@rmwc/dialog";
import { Icon } from "@rmwc/icon";
import { DialogExitProps } from "./exit-dialog-def";
import "./exit-dialog.sass";
import { Button } from "@rmwc/button";

export const ExitDialog: FC<DialogExitProps> = ({
  onSuccess,
  onClose,
  open,
}) => {
  const handleClickSuccess = useCallback(() => {
    onSuccess(open);
  }, [onSuccess, open]);

  return (
    <Dialog open={open >= 0} onClose={onClose}>
      <div className="dialog-exit-wrapper">
        <div className="dialog-exit-title">
          <h3>Выход</h3>
          <Icon icon="close" className="icon-close" onClick={onClose} />
        </div>
        <div className="dialog-exit-content">
          Вы уверены что хотите выйти не сохранив данные?
        </div>

        <div className="dialog-exit-buttons-wrapper">
          <Button
            type="button"
            outlined
            className="dialog-exit-button"
            label="Нет"
            onClick={onClose}
          />
          <Button
            type="button"
            unelevated
            className="dialog-exit-button"
            label="Да"
            onClick={handleClickSuccess}
          />
        </div>
      </div>
    </Dialog>
  );
};
