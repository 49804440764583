import { CircularFillingProgress } from 'components'
import { PagesRoutes } from 'global/routes'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { WorkerProfileFillingStatisticsProps } from './worker-profile-filling-statistics-def'
import './worker-profile-filling-statistics.sass'

export const WorkerProfileFillingStatistics: FC<WorkerProfileFillingStatisticsProps> = ({
	profileFilling
}): JSX.Element => {
	return (
		<>
			<Link to={PagesRoutes.WORKER_PROFILE + '?tab=0'}>
				<div className='worker-request-list-profile-info-card'>
					<CircularFillingProgress
						className='profile-filling-card-progress'
						size={58}
						progress={profileFilling.personal}
					/>
					<span className='profile-filling-card-title'>Данные Работника</span>
				</div>
			</Link>
			<Link to={PagesRoutes.WORKER_PROFILE + '?tab=2'}>
				<div className='worker-request-list-profile-info-card'>
					<CircularFillingProgress className='profile-filling-card-progress' size={58} progress={profileFilling.docs} />
					<span className='profile-filling-card-title'>Личные Документы</span>
				</div>
			</Link>
			<Link to={PagesRoutes.WORKER_PROFILE + '?tab=1'}>
				<div className='worker-request-list-profile-info-card'>
					<CircularFillingProgress
						className='profile-filling-card-progress'
						size={58}
						progress={profileFilling.experience}
					/>
					<span className='profile-filling-card-title'>Опыт работы</span>
				</div>
			</Link>
		</>
	)
}
