import { IVacancyData } from "./VacancyPage.types";
import React from "react";
import parse from "date-fns/parse";
import { differenceInCalendarDays, differenceInDays } from "date-fns";

export const formatVacancyData = (data: any, setVacancyData: React.Dispatch<React.SetStateAction<IVacancyData>>) => {
    setVacancyData(prevState => ({
        ...prevState,
        id: data.id,
        number: data.number,
        additionalInfo: data.additionalInfo ? data.additionalInfo : "",
        address: data.address ? data.address : "",
        category: data.category ? data.category : "",
        city: data.city ? data.city : "",
        grade: data.grade ? data.grade : "",
        isIndefiniteContract: data.isIndefiniteContract ? data.isIndefiniteContract : "",
        position: data.position ? data.position : "",
        probation: data.probation ? data.probation : "",
        probationDurationMonths: data.probationDurationMonths ? data.probationDurationMonths : "",
        responsibilities: data.responsibilities ? data.responsibilities : "",
        salaryFullNet: data.salaryFullNet ? data.salaryFullNet : "",
        salaryProbationNet: data.salaryProbationNet ? data.salaryProbationNet : "",
        workFrom: data.workFrom ? data.workFrom : "",
        workSchedule: data.workSchedule ? data.workSchedule.id : "",
        workTo: data.workTo ? data.workTo : "",
        organisationName: data.organisationName,
        vacancyState: data.vacancyState ? data.vacancyState : "",
        daysBeforeTheEndPublication: data.daysBeforeTheEndPublication,
        responseCount: data.responseCount,
        dateWithdrawnFromPublicationAt: data.dateWithdrawnFromPublicationAt,
        sinceLastView: data.sinceLastView,
        candidatesCount: data.candidatesCount
    }));
}

export const checkRequestData = (requestData: any, interfaceCTX: any) => {
    let isError: boolean
    let dateEndParsed = parse(requestData.workTo, "dd.MM.yyyy", new Date());
    let dateStartParsed = parse(requestData.workFrom, "dd.MM.yyyy", new Date());
    const currentDate = new Date(new Date()).toLocaleString("ru-RU").split(",")[0].split(".");
    if (differenceInCalendarDays(parse(requestData.workFrom, "dd.MM.yyyy", new Date()).getTime(), parse(currentDate.join("."), "dd.MM.yyyy", new Date()).getTime()) <= 0) {
        interfaceCTX.showMessage({
            body: `Дата начала договора не может быть раньше следующего дня`,
            icon: "report"
        });
        isError = true
    } else if (requestData.workTo && differenceInDays(dateEndParsed, dateStartParsed) < 14) {
        interfaceCTX.showMessage({
            title: "Ошибка",
            body: "Минимальный срок действия договора - 14 дней",
            icon: "report"
        });
        isError = true
    } else isError = false
    return isError
}


export const getSchedule = (id?: string) => {
    const options = [
        { label: "№1 - обычный", value: "1" },
        { label: "№2 - сокращённый", value: "2" },
        { label: "№3 - сменный", value: "3" },
        { label: "№4 - сменный", value: "4" },
        { label: "№5 - вахта", value: "5" }
    ];
    if (id) return options.find(v => v.value === id);
    return options;
};

export const scheduleDescription = (value: any) => {
    let rt;
    switch (value) {
        case "1":
            rt = "Пятидневная рабочая неделя по 8 часов в день, № 40 ч.";
            break;
        case "2":
            rt = "Сокращённая пятидневная рабочая неделя по 7 часов в день, № 36 ч.";
            break;
        case "3":
            rt = "2х-сменный, 4х-бригадный. Продолжительность рабочей смены 11,5 часов (день/ночь/48)";
            break;
        case "4":
            rt = "1-сменный, 2х-бригадный. Продолжительность рабочей смены 11,5 часов (2x2)";
            break;
        case "5":
            rt = "Вахтовый. Продолжительность рабочей смены 11,5 часов (месяц через месяц)";
            break;
    }
    if (!rt) return null;
    return (
        <>
            <div className={"vacancy-page-body-view-title"}>Описание</div>
            <div className={"vacancy-page-body-view-value"}>{rt}</div>
        </>
);
};

export const renderScheduleDescription = (value: any) => {
    let rt;
    switch (value) {
        case "1":
            rt = "Пятидневная рабочая неделя по 8 часов в день, № 40 ч.";
            break;
        case "2":
            rt = "Сокращённая пятидневная рабочая неделя по 7 часов в день, № 36 ч.";
            break;
        case "3":
            rt = "2х-сменный, 4х-бригадный. Продолжительность рабочей смены 11,5 часов (день/ночь/48)";
            break;
        case "4":
            rt = "1-сменный, 2х-бригадный. Продолжительность рабочей смены 11,5 часов (2x2)";
            break;
        case "5":
            rt = "Вахтовый. Продолжительность рабочей смены 11,5 часов (месяц через месяц)";
            break;
    }
    if (!rt) return null;
    return rt
};