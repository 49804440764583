import { ITableProps } from './Table.types'
import './Table.style.sass'
import React from 'react'


export function Table(props: ITableProps) {

    return (
        props.tag == "form" 
        ?
        <form ref={props.useRef} onSubmit={props.onSubmit} className={`table ${props.className}`} style={props.style}>
            {props.children}
        </form>
        :
        <div className={`table ${props.className}`} style={props.style}>
            {props.children}
        </div>
    )
}
