import React, {useState} from "react";
import {IRoomMeta, IRooms} from "../../messages-page-def";
import {useAutoCloseDialog} from "../../../../custom-hooks/useAutoCloseDialog";
import {Room} from "../room/room";

export const Rooms: React.FC<IRooms> = ({list}) => {
    const [isOpenRoomMenu, setIsOpenRoomMenu] = useState<string | null>(null)
    useAutoCloseDialog(() => {
        setIsOpenRoomMenu(null)
    }, [])
    return (
        <>
            {list.map((room: IRoomMeta, i) =>
                <Room
                    key={`room-${room.roomId}`}
                    room={room}
                    isOpenRoomMenu={isOpenRoomMenu}
                    setIsOpenRoomMenu={setIsOpenRoomMenu}
                />
            )}
        </>
    )
};