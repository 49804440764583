import { FC } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import { ErrorWrapperProps } from './error-wrapper-def'
import './error-wrapper.sass'

export const ErrorWrapper: FC<ErrorWrapperProps> = ({ error, children }) => {
	const message = JSON.stringify(error?.message)?.replace(/"/g, ' ')

	return (
		<div>
			{children}
			{error && <span className='error-wrapper-message'>{message}</span>}
		</div>
	)
}
