import { contractsApi, ShortContractResponseList } from 'global/api'
import { InterfaceContext } from 'global/context.interface'
import { useContext, useEffect, useState } from 'react'

export const useContracts = () => {
	const { scrollTop, offsetHeight, scrollHeight } = useContext(InterfaceContext)
	const [data, setData] = useState<ShortContractResponseList>({ data: [], total: 0 })
	const [queryParams, setQueryParams] = useState({ limit: 30, offset: 0 })
	const [responseStatus, setResponseStatus] = useState<{
		isSuccess: boolean
		isFetching: boolean | null
		isUploading: boolean
	}>({
		isSuccess: false,
		isFetching: null,
		isUploading: false
	})

	useEffect(() => {
		setResponseStatus({ ...responseStatus, isUploading: false })

		contractsApi
			.getContracts(queryParams.limit, queryParams.offset, 'all')
			.then(response => {
				setData(response.data)
				setResponseStatus({
					isSuccess: true,
					isFetching: response.data.total !== 0,
					isUploading: true
				})
			})
			.catch(e => {
				setResponseStatus({
					isSuccess: false,
					isFetching: false,
					isUploading: false
				})
			})
	}, [])

	useEffect(() => {
		if (scrollTop > 0 && scrollTop + offsetHeight > scrollHeight - 10) {
			setQueryParams({ ...queryParams, offset: queryParams.offset + queryParams.limit })
		}
	}, [scrollTop])

	return {
		contracts: data.data,
		total: data.total,
		isFetching: responseStatus.isFetching,
		isSuccess: responseStatus.isSuccess,
		isUploading: responseStatus.isUploading
	}
}
