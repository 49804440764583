import React, {MutableRefObject, useContext, useEffect, useRef, useState} from "react";
import {TextField} from "@rmwc/textfield";
import {ChatContext} from "../../../../global/context.chat";
import {IconButton} from "@rmwc/icon-button";
import {Icon} from "@rmwc/icon";
import {CustomDialog} from "../../../../components/CustomDialog/CustomDialog";
import {Doc} from "../../../documents-page/documents-page";
import {emitCustomEvent, useCustomEventListener} from "react-custom-events";
import {MatrixEvent} from "matrix-js-sdk";
import {readLastMessage} from "../../messages-page-utils";

export const ChatPanelBottom: React.FC<{ roomId: string, }> = ({roomId}) => {
    const [message, setMessage] = useState('')
    const {client} = useContext(ChatContext)
    const inputRef = useRef() as MutableRefObject<HTMLInputElement>
    const [isOpenFileLoaderDialog, setIsOpenFileLoaderDialog] = useState(false)
    const [isStartSending, setIsStartSending] = useState(false)
    const [isRedactionMessage, setIsRedactionMessage] = useState(false)
    const [file, setFile] = useState<{
        body: string,
        size: number,
        mimetype: string
        url: string
    } | null>(null)
    const [redactionMessage, setRedactionMessage] = useState<{
        eventId: string
        message: string
    } | null>(null)

    const sendRedactMessage = () => {
        if (!!message && isStartSending) {
            setIsStartSending(false)
            let content = {
                body: message, //Новый текст сообщения
                msgtype: 'm.text',
                "m.new_content": {
                    body: message, //Новый текст сообщения
                    msgtype: 'm.text',
                },
                "m.relates_to": {
                    rel_type: "m.replace",
                    event_id: redactionMessage?.eventId, //id редактируемого эвента
                }
            }
            client?.sendEvent(roomId, "m.room.message", content, "").then((res) => {
                setMessage('')
                setRedactionMessage(null)
                setIsRedactionMessage(false)
                emitCustomEvent("m.room.message.redact", {body: message, eventId: redactionMessage?.eventId})
            })
        }
    }

    useCustomEventListener('chat-message-redaction', (messageData: { eventId: string, message: string }) => {
        setRedactionMessage(messageData)
        setIsRedactionMessage(true)
        setMessage(messageData.message)
    })

    useCustomEventListener('chat-message-delete', (eventId: string) => {
        if (redactionMessage && redactionMessage.eventId === eventId) {
            stopRedactionMessage()
        }
    })

    const stopRedactionMessage = () => {
        setRedactionMessage(null)
        setIsRedactionMessage(false)
        setMessage('')
    }

    const sendMessage = () => {
        readLastMessage(client, roomId)
        if (!!message && isStartSending) {
            let text = message
            setIsStartSending(false)
            setMessage('')
            let content = {
                body: text,
                msgtype: "m.text"
            }
            client?.sendEvent(roomId, "m.room.message", content, "").then((res) => {

            })
        }
    }

    const [isStartSendingFile, setIsStartSendingFile] = useState(false)

    const sendFile = () => {
        if (!!file && isStartSendingFile) {
            setIsStartSendingFile(false)
            let content = {
                body: file.body, //Название файла
                info: {
                    size: file.size, //Размер файла
                    mimetype: file.mimetype //Тип файла
                },
                msgtype: "m.file",
                url: file.url//URL полученный из client.uploadContent
            }
            client?.sendEvent(roomId, "m.room.message", content, "").then((res) => {
                setFile(null)
                inputRef.current.value = ''
                setIsOpenFileLoaderDialog(false)
            })
        }
    }

    useEffect(() => {
        return () => {
            client?.sendTyping(roomId, false, 0)
        }
    }, [])

    return (
        <div className='chat-panel-bottom'>
            {isRedactionMessage &&
                <Icon className='edit_off'
                      icon={"edit_off"}
                      onClick={() => {
                          stopRedactionMessage()
                      }}/>
            }
            <TextField
                className='chat-panel-input'
                outlined
                maxLength={512}
                rows={2}
                type="search"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // client?.sendTyping(roomId, e.target.value.length > 0, 0)
                    if (e.target.value === ' ') {
                        return
                    } else {
                        setMessage(e.target.value)
                        !isStartSending && setIsStartSending(true)
                    }
                }}
                value={message}
                onKeyDown={(event: any) => {
                    if (event.key === 'Enter') {
                        if (isRedactionMessage) {
                            sendRedactMessage()
                        } else {
                            sendMessage()
                        }
                    }
                }}
            />
            <input
                className='chat-file-input'
                type='file'
                ref={inputRef}
                onChange={(e) => {
                    if (e.target.files) {
                        client?.uploadContent(e.target.files[0])
                            .then((data) => {
                                setFile({
                                    //@ts-ignore
                                    body: e.target.files[0].name,
                                    //@ts-ignore
                                    mimetype: e.target.files[0].type,
                                    //@ts-ignore
                                    size: e.target.files[0].size,
                                    url: data
                                })
                                setIsOpenFileLoaderDialog(true)
                                setIsStartSendingFile(true)
                            })
                    }
                }}
            />
            <IconButton className='send'
                        icon={"attach_file"}
                        onClick={() => {
                            if (inputRef && inputRef.current) {
                                inputRef.current?.click()
                            }
                        }}>
            </IconButton>
            <Icon className='send'
                  icon={"send"}
                  onClick={() => {
                      if (isRedactionMessage) {
                          sendRedactMessage()
                      } else {
                          sendMessage()
                      }
                  }}/>
            <CustomDialog
                dialogTitle={'Подтвердите файл'}
                buttons={[
                    {
                        text: 'Отправить',
                        callback: () => sendFile(),
                        raised: true
                    }
                ]}
                isOpen={isOpenFileLoaderDialog}
                onClose={() => {
                    setFile(null);
                    inputRef.current.value = ''
                    setIsOpenFileLoaderDialog(false)
                }}
            >
                {file && <Doc
                    name={file.body}
                    mimetype={file.mimetype}
                    path={client?.mxcUrlToHttp(file.url as string) as string}
                />}
            </CustomDialog>
        </div>
    )
}