import { PageTitle, ProgressPage } from 'components'
import { WorkerRequestListRequest } from 'global/api'
import { FC, useMemo } from 'react'
import { RequestList, useRequests } from 'pages/auth-as-worker'
import { getPageTitleByStatus } from '../worker-request-list'
import { ResponseInfoListProps } from './response-info-list-def'
import './response-info-list.sass'

export const ResponseInfoListPage: FC<ResponseInfoListProps> = ({ requestStatus }): JSX.Element => {
	const filterValues: Partial<WorkerRequestListRequest> = useMemo(
		() => ({
			city: null,
			organisation: null,
			salaryNetMin: null,
			salaryNetMax: null,
			subProfessions: null,
			type: 'opds',
			mainProfession: undefined
		}),
		[]
	)
	const { pendingRequests, considerationRequests, rejectedRequests, isFetching, isSuccess } = useRequests(filterValues)

	return (
		<div>
			<ProgressPage
				state={isFetching}
				render={() => (
					<>
						{isSuccess && (
							<>
								<PageTitle title={getPageTitleByStatus(requestStatus)} breadcrumbs />
								{requestStatus === 'pending' && <RequestList requests={pendingRequests} />}
								{requestStatus === 'consideration' && <RequestList requests={considerationRequests} />}
								{requestStatus === 'rejected' && <RequestList requests={rejectedRequests} />}
							</>
						)}
					</>
				)}
			/>
		</div>
	)
}
