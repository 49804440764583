import { AxiosPromise } from 'axios'
import { req } from 'global/common'
import {
	MyReviewResponseList,
	PersonReviewResponseList,
	ReviewCandidatesList,
	ReviewCreateParam,
	ReviewResponse,
	ReviewUpdateParam
} from './reviews-def'

export const reviewsApi = {
	getSummaryListOfReviews: (
		organisationId: number,
		limit?: number,
		offset?: number,
		canEdit?: boolean
	): AxiosPromise<PersonReviewResponseList> => {
		return req.get(`/reviews/organisation/${organisationId}/list`, { limit, offset, canEdit })
	},
	getReviewListPerUser: (
		userId: number,
		limit?: number,
		offset?: number,
		canEdit?: boolean
	): AxiosPromise<PersonReviewResponseList> => {
		return req.get(`/reviews/person/${userId}/list`, { limit, offset, canEdit })
	},
	sendReviewOnTheUser: (userId: number, body: ReviewCreateParam): AxiosPromise<ReviewResponse> => {
		return req.post(`/reviews/person/${userId}/create`, body)
	},
	updateMyReview: (reviewId: number, body: ReviewUpdateParam): AxiosPromise<ReviewResponse> => {
		return req.post(`/reviews/review/${reviewId}/update`, body)
	},
	deleteMyReview: (reviewId: number): AxiosPromise => {
		return req.delete(`/reviews/review/${reviewId}/delete`)
	},
	getMyReviewList: (limit: number, offset: number, name?: string): AxiosPromise<MyReviewResponseList> => {
		return req.get('/reviews/my/list', { limit, offset, name })
	},
	getListOfUsersNotReviewed: (limit: number, offset: number, position?: string): AxiosPromise<ReviewCandidatesList> => {
		return req.get('/reviews/my/candidates', { limit, offset, position })
	}
}
