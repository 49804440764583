import { IconButton } from '@rmwc/icon-button'
import { TextField } from '@rmwc/textfield'
import { PagesRoutes } from 'global/routes'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
	Period,
	Table,
	NameAndStatus,
	Avatar,
	PageTitle,
	ProgressPage,
	Spoiler,
	TabButtons,
	ModuleBlockNotification
} from "components";
import { TableCell } from '../../components/Table/TableCell/TableCell'
import { TableHeading } from '../../components/Table/TableHeading/TableHeading'
import { TableRow } from '../../components/Table/TableRow/TableRow'
import { authorName, req } from '../../global/common'
import { Author } from '../../global/definitions/definitions'
import { WorkerList } from './Personnel.types'
import { WorkerObject } from './Personnel.service'
import './Personnel.style.sass'
import { UserContext } from "../../global/context.user";

interface WorkerByBrigade {
	brigade: { name: string; id: number }
	workers: WorkerObject[]
	foreman: Author | null
}

export function Personnel() {
	const userCTX = React.useContext(UserContext);
	const [listInBrigades, setListInBrigades] = useState<WorkerList[]>(null!)
	const [listWithoutBrigades, setListWithoutBrigades] = useState<WorkerList[]>(null!)
	const [loaded, setLoaded] = useState<boolean>(null!)
	const [find, setFind] = useState<string>('')
	const [workersListGlobal, setWorkersListGlobal] = React.useState({
		inBrigades: [] as any[],
		outOfBrigade: [] as any[]
	})
	const [workersList, setWorkersList] = useState<WorkerObject[] | any>(null!)
	const [workersByBrigades, setWorkersByBrigades] = useState<WorkerByBrigade[] | any>(null!)
	const [reset, setReset] = useState<boolean>(false)
	const [isHeaderFixed, setIsHeaderFixed] = useState(false)
	const history = useHistory()
	let timeout: NodeJS.Timeout

	useEffect(() => {
		Promise.all([req.get('/hr/workers/list-in-brigades'), req.get('/hr/workers/list-without-brigades')])
			.then(lists => {
				// Пришлось добавить "workersListGlobal", чтобы всегда в стейте хранился полный список всех работяг (и в бригаде, и без), потому что при срабатывании filter,
				// на стейте, из него удаляются все не попадающие под запрос работяги, и при обновлении (изменении запроса), он ищет не в полном списке,
				// а в уже отфильтрованном, и не отрабатывает, как ожидается
				// Исправлял этот баг https://7winds.flowlu.ru/_module/projectissues/view/item_view/1366
				setWorkersListGlobal({
					inBrigades: lists[0].data['data'],
					outOfBrigade: lists[1].data['data'][0].workers
				})
				setListInBrigades(lists[0].data['data'])
				setListWithoutBrigades(lists[1].data['data'])
			})
			.catch(() => setLoaded(false))
	}, [])

	function scroll(e: Event) {
		// Предотвращает торможение страницы при скролле
		// if ((e.target as HTMLDivElement).scrollTop < 250) {
		//   e.target
		if ((e.target as HTMLInputElement).scrollTop > 200) {
			setIsHeaderFixed(true)
		} else {
			setIsHeaderFixed(false)
		}
		// }
		// else {
		//   e.target?.removeEventListener('scroll', scroll)
		// }
	}

	useEffect(() => {
		let appContent = document.getElementsByClassName('app-content')[0]
		if (appContent.scrollTop < 250) {
			appContent.addEventListener('scroll', scroll)
		} else {
			appContent.removeEventListener('scroll', scroll)
		}

		return () => {
			appContent.removeEventListener('scroll', scroll)
		}
	}, [])

	useEffect(() => {
		let sorted = listInBrigades
			? listInBrigades
					.filter(w => w.workers.length !== 0 && w.brigade !== null)
					.map(w => ({
						brigade: w.brigade ?? null,
						workers: w.workers.map(ww => new WorkerObject(ww)),
						foreman: w.brigade?.foreman
					}))
			: null
		setWorkersByBrigades(sorted)
	}, [listInBrigades, reset])

	useEffect(() => {
		let sorted = listWithoutBrigades
			? listWithoutBrigades
					.filter(w => w.workers.length !== 0)
					.map(w => w.workers)
					.flat()
					.map(w => new WorkerObject(w))
			: null
		setWorkersList(sorted)
	}, [listWithoutBrigades, reset])

	// Пришлось добавить "workersListGlobal", чтобы всегда в стейте хранился полный список всех работяг (и в бригаде, и без),
	// чтобы исправить этот баг https://7winds.flowlu.ru/_module/projectissues/view/item_view/1366
	useEffect(() => {
		if (find !== '') {
			setWorkersList((state: WorkerObject[]) =>
				workersListGlobal.outOfBrigade
					.map(w => new WorkerObject(w))
					.filter(w => w.lastName.toLowerCase().includes(find.toLowerCase()))
			)
			setWorkersByBrigades((state: WorkerByBrigade[]) =>
				workersListGlobal.inBrigades
					.map(w => ({
						brigade: w.brigade ?? null,
						workers: w.workers.map((ww: any) => new WorkerObject(ww)),
						foreman: w.brigade?.foreman
					}))
					.filter(
						w =>
							w.workers.filter(
								(ww: any) => ww.lastName != null && ww.lastName.toLowerCase().includes(find.toLowerCase())
							).length !== 0
					)
			)
		} else setReset((state: boolean) => !state)
	}, [find])

	if (!loaded && workersList && workersByBrigades) setLoaded(true)

	return (
		<div className='workers-page'>
			<PageTitle title={'Персонал'} breadcrumbs={true} />
			{userCTX.user?.organisation.modulesStatus.foreman !== "active" &&
				<ModuleBlockNotification moduleType={"foreman"} />}
			<ProgressPage
				state={loaded}
				className={`workers ${userCTX.user?.organisation.modulesStatus.foreman !== "active"
					? "module-blocked noSelect"
					: ""}`}
				alertTitle={'Нет данных'}
				render={() => (
					<>
						<TabButtons
							staticHeader={
								<>
									<div className={'search mdc-elevation--z3'}>
										<TextField
											style={{ width: '100%' }}
											outlined
											label='Поиск по фамилии'
											maxLength={160}
											trailingIcon='search'
											onChange={(e: any) => {
												clearTimeout(timeout)
												timeout = setTimeout(() => setFind((e.target as HTMLInputElement).value), 300)
											}}
										/>
									</div>
									<Table>
										<TableRow type='heading'>
											<TableHeading devider style={{ width: '12%' }}>
												ФОТО
											</TableHeading>
											<TableHeading devider style={{ width: '28%' }}>
												ФИО
											</TableHeading>
											<TableHeading devider style={{ width: '15%' }}>
												Должность
											</TableHeading>
											<TableHeading devider style={{ width: '15%' }}>
												Договор
											</TableHeading>
											<TableHeading devider style={{ width: '15%' }}>
												Срок действия договора
											</TableHeading>
											<TableHeading devider style={{ width: '15%' }}>
												Потребность
											</TableHeading>
										</TableRow>
									</Table>
								</>
							}
							data={{
								'Не в составе бригад': () => (
									<>
										<Table>
											{workersList &&
												workersList.map((worker: any, index: number) => (
													<TableRow type='row' key={index} isProfileDeleted={worker.isDeleted}>
														<TableCell devider style={{ width: '12%', padding: 0 }}>
															<Avatar
																isProfileDeleted={worker.isDeleted}
																linkProps={{
																	pathname:
																		worker.isDeleted && !authorName(worker).includes('null')
																			? PagesRoutes.WORKERS +
																			  PagesRoutes.PERSONAL +
																			  PagesRoutes.WORKER_CARD +
																			  `/${worker.id}/${worker.requestID}`
																			: PagesRoutes.WORKERS +
																			  PagesRoutes.PERSONAL +
																			  PagesRoutes.PUBLIC_PROFILE +
																			  `/${worker.id}/${worker.recordID}`,
																	state: history.location.state
																}}
																// pathToProfile={worker.isDeleted && !authorName(worker).includes('null')
																//     ? `/public-worker-profile/dump/${worker.id}/${worker.requestID}`
																//     : `/public-worker-profile/${worker.id}/${worker.recordID}`
																// }
																// pathToProfile={`/public-worker-profile/${worker.id}/${worker.recordID}`}
																prevPath={history.location.pathname}
																previewHref={worker.avatar?.pathPreview || worker.avatar}
															/>
														</TableCell>
														<TableCell devider style={{ width: '28%' }}>
															<NameAndStatus
																authorName={worker.name}
																isProfileDeleted={worker.isDeleted}
																// status={worker.state}
																// tooltip
															/>
														</TableCell>
														<TableCell
															devider
															style={{
																width: '15%',
																fontWeight: 500,
																fontSize: '16px',
																letterSpacing: '0.3px',
																textAlign: 'center'
															}}
														>
															{worker.position}
														</TableCell>
														<TableCell devider style={{ width: '15%', fontSize: '30px !important' }}>
															{worker.isDeleted ? (
																<IconButton
																	style={{
																		opacity: worker.isDeleted ? 0.38 : 1,
																		fontSize: '35px',
																		width: '70px',
																		height: '70px',
																		color: 'rgba(18, 50, 79, 1)'
																	}}
																	disabled={worker.isDeleted}
																	icon='description'
																	className='contract-icon'
																/>
															) : (
																<Link
																	className='contract-link'
																	style={{ color: 'rgb(53, 76, 98)' }}
																	to={{
																		pathname:
																			PagesRoutes.WORKERS +
																			PagesRoutes.PERSONAL +
																			PagesRoutes.CONTRACT_CARD +
																			`/${worker.recordID}`,
																		state: history.location.state
																	}}
																>
																	<IconButton
																		disabled={worker.isDeleted}
																		icon='description'
																		className='contract-icon'
																		style={{
																			fontSize: '35px',
																			width: '70px',
																			height: '70px',
																			color: 'rgba(18, 50, 79, 1)'
																		}}
																	/>
																</Link>
															)}
														</TableCell>
														<TableCell devider style={{ width: '15%' }}>
															<Period start={worker.workFrom} end={worker.workTo} unlimited={worker.workUnlimited} />
														</TableCell>
														<TableCell
															devider
															style={{
																width: '15%',
																fontWeight: 500,
																fontSize: '22px',
																letterSpacing: '0.3px'
															}}
														>
															{worker.requestNumber}
														</TableCell>
													</TableRow>
												))}
										</Table>
									</>
								),
								'В составе бригад': () => (
									<div className='page-content'>
										{workersByBrigades && workersByBrigades.length > 0 ? (
											workersByBrigades.map((w: any) => (
												<Spoiler
													key={w.brigade.id}
													title={w.brigade.name}
													caption={`(${w.workers.length})`}
													subTitle={w.foreman ? 'Прораб' : undefined}
													subText={
														w.foreman
															? `${w.foreman.lastName} ${w.foreman.firstName.slice(0, 1)}. ${w.foreman.middleName ? w.foreman.middleName[0] + "." : ""}`
															: undefined
													}
													render={() => (
														<Table className={'with-brigades'}>
															{w.workers &&
																w.workers.map((worker: any, index: number) => (
																	<TableRow isProfileDeleted={worker.isDeleted} type='row' key={index}>
																		<TableCell devider style={{ width: '12%', padding: 0 }}>
																			<Avatar
																				isProfileDeleted={worker.isDeleted}
																				linkProps={{
																					pathname:
																						worker.isDeleted && !authorName(worker).includes('null')
																							? PagesRoutes.WORKERS +
																							  PagesRoutes.PERSONAL +
																							  PagesRoutes.WORKER_CARD +
																							  `/${worker.id}/${worker.requestID}`
																							: PagesRoutes.WORKERS +
																							  PagesRoutes.PERSONAL +
																							  PagesRoutes.PUBLIC_PROFILE +
																							  `/${worker.id}/${worker.recordID}`,
																					state: history.location.state
																				}}
																				// pathToProfile={worker.isDeleted && !authorName(worker).includes('null')
																				//     ? `/public-worker-profile/dump/${worker.id}/${worker.requestID}`
																				//     : `/public-worker-profile/${worker.id}/${worker.recordID}`
																				// }
																				// pathToProfile={`/public-worker-profile/${worker.id}/${worker.recordID}`}
																				prevPath={history.location.pathname}
																				previewHref={worker.avatar?.pathPreview || worker.avatar}
																			/>
																		</TableCell>
																		<TableCell devider style={{ width: '28%' }}>
																			<NameAndStatus
																				authorName={worker.name}
																				isProfileDeleted={worker.isDeleted}
																				// status={worker.state}
																				// tooltip
																			/>
																		</TableCell>
																		<TableCell
																			devider
																			style={{
																				width: '15%',
																				fontWeight: 500,
																				fontSize: '16px',
																				letterSpacing: '0.3px',
																				textAlign: 'center'
																			}}
																		>
																			{worker.position}
																		</TableCell>
																		<TableCell
																			devider
																			style={{
																				width: '15%',
																				fontSize: '30px !important'
																			}}
																		>
																			{worker.isDeleted ? (
																				<IconButton
																					style={{
																						opacity: worker.isDeleted ? 0.38 : 1,
																						fontSize: '35px',
																						width: '70px',
																						height: '70px',
																						color: 'rgba(18, 50, 79, 1)'
																					}}
																					disabled={worker.isDeleted}
																					icon='description'
																					className='contract-icon'
																				/>
																			) : (
																				<Link
																					className='contract-link'
																					style={{ color: 'rgb(53, 76, 98)' }}
																					to={{
																						pathname:
																							PagesRoutes.WORKERS +
																							PagesRoutes.PERSONAL +
																							PagesRoutes.CONTRACT_CARD +
																							`/${worker.recordID}`,
																						state: history.location.state
																					}}
																				>
																					<IconButton
																						disabled={worker.isDeleted}
																						icon='description'
																						className='contract-icon'
																						style={{
																							fontSize: '35px',
																							width: '70px',
																							height: '70px',
																							color: 'rgba(18, 50, 79, 1)'
																						}}
																					/>
																				</Link>
																			)}
																		</TableCell>
																		<TableCell devider style={{ width: '15%' }}>
																			<Period
																				start={worker.workFrom}
																				end={worker.workTo}
																				unlimited={worker.workUnlimited}
																			/>
																		</TableCell>
																		<TableCell
																			devider
																			style={{
																				width: '15%',
																				fontWeight: 500,
																				fontSize: '22px',
																				letterSpacing: '0.3px'
																			}}
																		>
																			{worker.requestNumber}
																		</TableCell>
																	</TableRow>
																))}
														</Table>
													)}
												/>
											))
										) : (
											<div className='no-data-notice'>{find != '' ? 'Прорабы пока не сформировали бригады' : ''}</div>
										)}
									</div>
								)
							}}
						/>
					</>
				)}
			/>
		</div>
	)
}
