import React, {useContext, useMemo} from 'react'
import {TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarTitle, TopAppBarFixedAdjust} from '@rmwc/top-app-bar'
import {UserContext} from '../../global/context.user'
import {Avatar} from '@rmwc/avatar'
import '@rmwc/avatar/styles'
import defaultAvatar from '../../misc/user-avatar-default.png'
import {IconButton} from '@rmwc/icon-button'
import {authorName, req} from '../../global/common'
import {useTranslation} from 'react-i18next'
import {Icon} from '@rmwc/icon'
import ICON from '../../misc/topbar-logout.png'
import LOGO from '../../misc/topbar-logo.png'
import '@rmwc/top-app-bar/styles'
import './Topbar.style.sass'
import {SimpleDialog} from '@rmwc/dialog'
import {InterfaceContext} from '../../global/context.interface'
import Select from 'react-select'
import {Link, Redirect, useHistory} from 'react-router-dom'
import {Divider, Raiting} from 'components'
import {ChatContext} from '../../global/context.chat'

export default function Topbar() {
    const {t} = useTranslation('common')
    const chatCTX = useContext(ChatContext)
    const userCTX = React.useContext(UserContext)
    const interfaceCTX = React.useContext(InterfaceContext)
    const [openDialog, setOpenDialog] = React.useState(false)
    const [organizationRate, setOrganizationRate] = React.useState(0)
    const history = useHistory()

    const pathPreview = userCTX?.user?.workerProfile?.avatar?.pathPreview || ''

    React.useEffect(() => {
        if (userCTX.user && userCTX.user?.currentRole !== 'moderator') {
            req
                .get(`/reviews/organisation/${userCTX.user?.organisation?.id}/list?limit=0&offset=0`)
                .then(data => data.data.average && setOrganizationRate(data.data.average))
                .catch(() =>
                    interfaceCTX.showMessage({
                        icon: 'report',
                        body: 'Рейтинг организации загрузить не удалось. Обратитесь к администратору'
                    })
                )
        }
    }, [])

    const isShowOrgInfoUserByRoles = useMemo(
        () => !userCTX?.user?.roles?.includes('worker') || !userCTX?.user?.roles?.length,
        [userCTX]
    )

    const renderRightMenuForRole = () => {
        if (!userCTX.user) return <></>
        if (userCTX.user?.roles.includes('worker') || userCTX.user?.roles.length === 0) {
            return (
                <>
                    <Divider/>

                    {!!userCTX.user && (
                        <div className='info-block'>
                            <div className='avatar-user'>
                                <Avatar src={pathPreview ? pathPreview : defaultAvatar} size='xlarge' square/>
                            </div>
                            <div
                                className={`user-info ${
                                    interfaceCTX.instructionValue.isOpen && interfaceCTX.instructionValue.step === 10
                                        ? 'topbar-active-block'
                                        : ''
                                }`}
                            >
                                <h3 className={'name'}>
                                    {userCTX.user?.firstName != null ? (
                                        <Link to={'/worker-profile'}
                                              className='topbar-link'>
                                            <Icon icon={{icon: 'person', size: 'small'}}/>
                                            <div>{authorName(userCTX.user)}</div>
                                        </Link>
                                    ) : (
                                        'Неизвестный пользователь'
                                    )}
                                </h3>
                            </div>
                        </div>
                    )}
                </>
            )
        } else if (userCTX.user?.roles.includes('moderator')) {
            return (
                <>
                    <Divider/>
                    {!!userCTX.user && (
                        <div className='info-block'>
                            <div
                                className={`user-info ${
                                    interfaceCTX.instructionValue.isOpen && interfaceCTX.instructionValue.step === 10
                                        ? 'topbar-active-block'
                                        : ''
                                }`}
                            >
                                <h3 className={'name'}>
                                    {userCTX.user?.firstName != null ? (
                                        <div className='topbar-link' style={{cursor: 'default'}}>
                                            <Icon icon={{icon: 'person', size: 'small'}}/>
                                            <div>{authorName(userCTX.user)}</div>
                                        </div>
                                    ) : (
                                        'Неизвестный пользователь'
                                    )}
                                </h3>
                            </div>
                        </div>
                    )}
                </>
            )
        } else {
            return (
                <>
                    {!!userCTX.user && (
                        <div className='info-block'>
                            <div
                                className={`user-info ${
                                    interfaceCTX.instructionValue.isOpen && interfaceCTX.instructionValue.step === 10
                                        ? 'topbar-active-block'
                                        : ''
                                }`}
                            >
                                <h3 className={'name'}>
                                    {userCTX.user?.firstName != null ? (
                                        <Link to={!isShowOrgInfoUserByRoles ? '/worker-profile' : '/profile'}
                                              className='topbar-link'>
                                            <Icon icon={{icon: 'person', size: 'small'}}/>
                                            <div>{authorName(userCTX.user)}</div>
                                        </Link>
                                    ) : (
                                        'Неизвестный пользователь'
                                    )}
                                </h3>
                                {((userCTX.user.allRoles.length == 2 && userCTX.user.allRoles.includes('foreman')) ||
                                userCTX.user.allRoles.length <= 1 ? (
                                    <span className={'position'}>
                    						{userCTX.user.allRoles.length > 0 ? t('role.' + userCTX.user.roles[0]) : 'Неизвестная роль'}
                    					</span>
                                ) : (
                                    <Select
                                        className={'role-select react-select-component'}
                                        classNamePrefix={'select'}
                                        value={{
                                            label: t('role.' + userCTX.user.currentRole),
                                            value: userCTX.user.currentRole
                                        }}
                                        onChange={e => {
                                            if (
                                                e!.value != userCTX.user!.roles[0] &&
                                                window.confirm(
                                                    `Страница будет перезагружена. Вы уверены, что хотите сменить роль на ${t(
                                                        'role.' + e!.value
                                                    )}?`
                                                )
                                            ) {
                                                req
                                                    .post('/profile/peek-role', {newRole: e!.value})
                                                    .then(() => {
                                                        history.go(0)
                                                    })
                                                    .catch(e => {
                                                        interfaceCTX.showMessage({
                                                            title: 'Ошибка',
                                                            body: t(e.response.data)
                                                        })
                                                    })
                                            }
                                        }}
                                        isSearchable={false}
                                        defaultValue={{
                                            label:
                                                userCTX.user.roles.length > 0 ? t('role.' + userCTX.user.roles[0]) : 'Неизвестная роль',
                                            value: userCTX.user.roles[0]
                                        }}
                                        options={
                                            userCTX && userCTX.user.allRoles.length > 0
                                                ? userCTX.user.allRoles
                                                    .map(role => {
                                                        return {
                                                            label: t('role.' + role),
                                                            value: role
                                                        }
                                                    })
                                                    .filter(role => role.value != 'foreman')
                                                : []
                                        }
                                    />
                                ))}
                            </div>
                            <div className={'org-info'}>
                                <Divider/>
                                <div
                                    className={`org-info-block ${
                                        interfaceCTX.instructionValue.isOpen && interfaceCTX.instructionValue.step === 11
                                            ? 'topbar-active-block'
                                            : ''
                                    }`}
                                >
                                    {userCTX.user.organisation?.logoPreview ? (
                                        <div
                                            className={'top-avatar'}
                                            style={{
                                                backgroundImage: `url(${userCTX.user.organisation?.logo})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                backgroundSize: 'contain'
                                            }}
                                        />
                                    ) : (
                                        <div className={'top-avatar'}>
                                            <Icon icon={'description'}/>
                                        </div>
                                    )}

                                    <div className={'block'}>
                                        <h3 className={'name'}>
                                            <Link className='topbar-link'
                                                  to={`/public-profile/${userCTX.user?.organisation?.id}?`}>
                                                <Icon icon={{icon: 'home', size: 'small'}}/>
                                                <div
                                                    style={{width: '100%'}}>{userCTX.user.organisation?.shortName}</div>
                                            </Link>
                                        </h3>
                                        <Raiting value={organizationRate} withNumber/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )
        }
    }

    return (
        <>
            <TopAppBar id='topbar'>
                <TopAppBarRow>
                    <TopAppBarSection>
                        <TopAppBarTitle>
                            <img src={LOGO} alt={'pragma'} style={{height: '50px'}}/>
                        </TopAppBarTitle>
                    </TopAppBarSection>
                    <TopAppBarSection alignEnd>
                        {renderRightMenuForRole()}
                        {userCTX.user &&
                            <IconButton icon={ICON} className={'logout'} onClick={() => setOpenDialog(true)}/>}
                    </TopAppBarSection>
                </TopAppBarRow>
            </TopAppBar>
            <TopAppBarFixedAdjust/>
            <SimpleDialog
                open={openDialog}
                body={'Вы действительно хотите выйти из приложения?'}
                cancelLabel={'Нет'}
                acceptLabel={'Да'}
                onClosed={e => {
                    setOpenDialog(false)
                    if (e.detail.action === 'accept') {
                        interfaceCTX.setInstructionValue(false, -1)
                        localStorage.clear()
                        sessionStorage.clear()
                        chatCTX.disconnect()
                        userCTX.setUser(null)
                        if (userCTX.user?.currentRole === 'moderator' || (userCTX.user?.lastName === "Workforce" && userCTX.user?.email?.includes("@workforce.su"))) {
                            history.push('/signin-moderator')
                        }
                    }
                }}
            />
        </>
    )
}
