import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Icon} from '@rmwc/icon'
import {SimpleDialog} from '@rmwc/dialog'
import {CompanyAvatarProps} from './company-avatar-def'
import {AvatarEditor} from 'components/AvatarEditor/AvatarEditor'
import {req} from "../../../../../global/common";
import {InterfaceContext} from "../../../../../global/context.interface";
import {
    PublicProfile,
    PublicProfileSend
} from "../../../../public-profile/components/PublicProfilePage/public-profile-def";

export function CompanyAvatar({image, viewMode}: CompanyAvatarProps) {
    const {t} = useTranslation('common')
    const {orgID, key} = useParams() as { orgID: string; key: string }
    const interfaceCTX = React.useContext(InterfaceContext)
    const [imageState, setImageState] = React.useState<string | undefined>(image)
    const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false)
    const [openUploadDialog, setOpenUploadDialog] = React.useState(false)
    const [renderToPortal, setRenderToPortal] = React.useState(true)
    const [imgSrc, setImgSrc] = useState("");

    React.useEffect(() => {
        req
            .post(`/moderation/get`, {
                key: key
            })
            .then(({data}) => {
                setImageState(data.logo)
            })
            .catch(() =>
                interfaceCTX.showMessage({
                    body: 'Не удалось загрузить лого компании',
                    icon: 'report'
                })
            )
    }, [orgID])

    const onImageUpdate = (file: Blob) => {
        const params = new FormData()
        params.append('file', file)

        req
            .post(`/organisation/${orgID}/upload-logo`, params)
            .then(({data}) => {
                setImageState(data.logo)
            })
            .then(() => interfaceCTX.refreshTopBar())
            .catch(e =>
                interfaceCTX.showMessage({
                    body: 'Не удается загрузить логотип',
                    icon: 'report'
                })
            )
    }

    const onImageSave = (file: any) => {
        const params = new FormData()
        params.append('file', file)

        req
            .post(`/moderation/upload-logo?key=${key}`, params)
            .then(({data}) => {
                // req.post(`/moderation/get`, {
                // 		key: key
                // 	})
                // 	.then(({ data }) => {
                // 		req
                // 			.post(`/moderation/update`, {
                // 				moderationRequest: {
                // 					accessKey: key,
                // 					organisationId: Number(orgID)
                // 				},
                // 				organisationUpdateRequest: new PublicProfileSend(data)
                // 			})
                // 			.then(() => {
                setImageState(imgSrc)
                setOpenUploadDialog(false)
                // 		})
                // })
            })
            .catch(e =>
                interfaceCTX.showMessage({
                    body: 'Не удается загрузить логотип',
                    icon: 'report'
                })
            )
    }

    const onImageDelete = () => {
        req
            .delete(`/moderation/delete-logo?key=${key}`)
            .then(() => {
                setImageState(undefined)
                interfaceCTX.showMessage({
                    body: 'Логотип удален',
                    icon: 'done'
                })
            })
            .catch(e => {
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response.data)
                })
            })
    }

    return (
        <div className={'avatar-container'}>
            <div
                className='image-input avatar-block--contain'
                style={{
                    backgroundImage: `url(${imageState})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain'
                }}
            >
                {!viewMode && !imageState && (
                    <div className='avatar-upload' onClick={() => setOpenUploadDialog(true)}>
                        <Icon className='add-photo' icon='add_a_photo' onClick={() => interfaceCTX.openInput()}/>
                    </div>
                )}
            </div>
            {!viewMode && imageState &&
                <Icon className='cancel' icon='cancel' onClick={() => setOpenRemoveDialog(true)}/>}
            <AvatarEditor
                onImageSave={onImageSave}
                setImageSrc={setImgSrc}
                aspectRatio={0}
                isOpen={openUploadDialog}
                setIsOpen={setOpenUploadDialog}
            />
            <SimpleDialog
                renderToPortal={renderToPortal}
                open={openRemoveDialog}
                body={
                    <div>
                        Логотип будет удален безвозвратно
                        <br/>
                        Вы уверены?
                    </div>
                }
                cancelLabel={'Нет'}
                acceptLabel={'Да'}
                onClosed={e => {
                    e.preventDefault()
                    if (e.detail.action === 'accept') {
                        onImageDelete()
                        setOpenRemoveDialog(false)
                    } else {
                        setOpenRemoveDialog(false)
                    }
                }}
            />
        </div>
    )
}
