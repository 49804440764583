import "./EmployeeTrainingItem.style.sass";
import { isSuperAdm, req } from "../../../../global/common";
import React, { useContext, useEffect, useState } from "react";
import { IEmployeeTraining } from "../../EmployeeTraining.types";
import { InterfaceContext } from "../../../../global/context.interface";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { PageTitle, ProgressPage } from "../../../../components";
import { Button } from "@rmwc/button";
import { UserContext } from "../../../../global/context.user";

export const EmployeeTrainingItem = () => {
    const { id } = useParams<{ id: string }>();
    const userCTX = useContext(UserContext);
    const [marketplaceData, setMarketplaceData] = useState<IEmployeeTraining>({} as IEmployeeTraining);
    const interfaceCTX = useContext(InterfaceContext);
    const [isLoading, setIsLoading] = useState<boolean | null>(null);
    const { t } = useTranslation("common");

    const getMarketplaceData = () => {
        setIsLoading(null);
        req.get(`/marketplace/web/${id}/get`)
            .then(({ data }) => {
                setMarketplaceData(data);
                setIsLoading(true);
            })
            .catch((e) => {
                setIsLoading(false);
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            });
    };

    const signUpForCourse = () => {
        req.get(`/marketplace/web/${id}/apply`)
            .then(({ data }) => {
                interfaceCTX.showMessage({
                    body: "Заказ принят, Вашу заявку рассмотрят в ближайшее время.",
                    icon: "info"
                });
                setMarketplaceData(prevState => ({
                    ...prevState,
                    ordered: true
                }));
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            });
    };

    useEffect(() => {
        getMarketplaceData();
    }, []);

    return (
        <div className={"employee-training-item"}>
            <PageTitle title={"Обучение"} breadcrumbs={true} />
            <ProgressPage
                state={isLoading}
                render={() => (
                    <>
                        <div className={"employee-training-top employee-training-container"}>
                            <div className={"employee-training-avatar"}>
                                <img src={marketplaceData.companyAvatar} />
                            </div>
                            <div className={"employee-training-info"}>
                                <div className={"employee-training-service"}>
                                    {marketplaceData.service}
                                </div>
                                <div className={"employee-training-companyName"}>
                                    {marketplaceData.companyName}
                                </div>
                                <div className={"employee-training-hz"}>
                                    {marketplaceData.tagline}
                                </div>
                            </div>
                        </div>
                        <div className={"employee-training-body employee-training-container"}>
                            <div className={"employee-training-description"}>
                                <ReactMarkdown children={marketplaceData.description}/>
                            </div>
                            <div className={"employee-training-servicePrice"}>
                                {marketplaceData.servicePrice}
                            </div>
                            <div className={"employee-training-button"}>
                                {marketplaceData.ordered
                                    ? <div className={"employee-training-ordered"}>
                                        Заявка отправлена
                                    </div>
                                    : <Button
                                        raised
                                        disabled={isSuperAdm(userCTX.user)}
                                        onClick={event => {
                                            signUpForCourse();
                                        }}
                                    >
                                        записаться на курс
                                    </Button>
                                }
                            </div>
                        </div>
                    </>
                )}
            />
        </div>
    );
};