import { Icon } from '@rmwc/icon'
import clsx from 'clsx'
import { FC } from 'react'
import { ProgressCheckboxProps } from './StepperCheckbox.types'
import './StepperCheckbox.style.sass'

export const StepperCheckbox: FC<ProgressCheckboxProps> = ({
	className,
	checked = false,
	isActive = false
}): JSX.Element => {
	return (
		<span className={clsx('progress-checkbox', { 'progress-checkbox-active': isActive }, className)}>
			{checked && <Icon className='progress-checkbox-check' icon='done' />}
		</span>
	)
}
