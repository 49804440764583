export const columns = [
    {
        id: 0,
        title: "ФИО",
        minWidth: "326px",
        flex: "1 0 326px",
        sortType: "NONE",
        fieldName: "fullname",
        isSorted: false,
        isFiltered: true,
        langPrefix: ""
    },
    {
        id: 1,
        title: "Дата отклика",
        minWidth: "326px",
        flex: "1 0 326px",
        sortType: "DESC",
        fieldName: "responseDate",
        isSorted: true,
        isFiltered: false,
        langPrefix: ""
    },
    {
        id: 2,
        title: "Статус",
        minWidth: "326px",
        flex: "1 0 326px",
        sortType: "NONE",
        fieldName: "state",
        isSorted: true,
        isFiltered: true,
        langPrefix: "vacancy."
    },
    {
        id: 3,
        title: "Чат",
        minWidth: "188px",
        flex: "0 0 188px",
        sortType: "NONE",
        fieldName: "chatLogin",
        isSorted: false,
        isFiltered: false,
        langPrefix: ""
    }
];
