import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import "./ConnectedModules.style.sass";
import { req } from "../../../../../global/common";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@rmwc/circular-progress";
import { CustomDialog } from "../../../../../components";
import Select from "react-select";
import {
    defaultModuleItemValue,
    reasonAddingPackage,
    numberOfMonthsOfSubscription, reasonForStoppingTheModule, pickColor
} from "./ConnectedModules.service";
import { InterfaceContext } from "../../../../../global/context.interface";
import { useTranslation } from "react-i18next";
import { TextField } from "@rmwc/textfield";
import { IModuleItem, SuspendedModuleItem, IUpdateModuleData } from "./ConnectedModules.types";
import { Icon } from "@rmwc/icon";
import { Tooltip } from "@rmwc/tooltip";
import parse from "date-fns/parse";
import { add, addDays, addMonths, differenceInCalendarDays, format } from "date-fns";

export const ConnectedModules = () => {
    const { orgID, key } = useParams() as { orgID: string; key: string };
    const [activateDialogValues, setActivateDialogValues] = useState<{ isOpen: boolean, moduleType?: string, prevEndDate?: string }>({ isOpen: false });
    const [stopModuleDialog, setStopModuleDialog] = useState<SuspendedModuleItem>({
        isOpen: false
    });
    const [modules, setModules] = useState<IModuleItem[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSaveBtn, setIsLoadingSaveBtn] = useState(false);
    const interfaceCTX = useContext(InterfaceContext);
    const { t } = useTranslation("common");
    const [updateModuleValue, setUpdateModuleValue] = useState<IUpdateModuleData>(defaultModuleItemValue);
    const [paymentDataFormats, setPaymentDataFormats] = useState<{
        endDate: null | string
        reason: null | string
    }>({ endDate: null, reason: null });
    const [imageData, setImageData] = useState<{ src: string, type: string }>({ src: "", type: "" });
    const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            const newFile = e.target.files[0];
            if (newFile.size > 5000000) {
                inputRef.current.value = "";
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: "Максимальный вес файла: 5мб"
                });
                return;
            } else if (!(["jpg", "jpeg", "png", "pdf"].includes(newFile.type.split("/")[1]))) {
                inputRef.current.value = "";
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: "Форматы файла: JPG, JPEG, PNG, PDF"
                });
                return;
            }
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImageData({
                    src: !!reader.result ? reader.result.toString() : "",
                    // @ts-ignore
                    type: newFile.type
                });
            });
            let formData = new FormData();
            formData.append("file", newFile);
            setUpdateModuleValue(prevState => ({
                ...prevState,
                // @ts-ignore
                file: newFile
            }));
            reader.readAsDataURL(newFile);
            inputRef.current.value = "";
        }
    }

    const handleDeleteFile = () => {
        setImageData({
            src: "",
            type: ""
        });
        setUpdateModuleValue(prevState => ({
            ...prevState,
            file: null
        }));
    };

    const saveUpdatedModuleValues = () => {
        if (!updateModuleValue.endDate) return;
        let newEndDate = updateModuleValue.endDate.includes(".")
            ? updateModuleValue.endDate
            : new Date(updateModuleValue.endDate).toLocaleString("ru-RU").split(", ")[0];
        const currentDate = new Date(new Date()).toLocaleString("ru-RU").split(",")[0];
        if (updateModuleValue.text === "Подключить" && differenceInCalendarDays(parse(newEndDate, "dd.MM.yyyy", new Date()).getTime(), parse(currentDate, "dd.MM.yyyy", new Date()).getTime()) <= 0) {
            interfaceCTX.showMessage({
                title: "Ошибка",
                body: `Дата подключения модуля не может быть раньше ${format(addDays(new Date(), 1), "dd.MM.yyyy")}`
            });
            return;
        }
        if (updateModuleValue.prevEndDate && differenceInCalendarDays(parse(newEndDate, "dd.MM.yyyy", new Date()).getTime(), parse(updateModuleValue.prevEndDate as string, "dd.MM.yyyy", new Date()).getTime()) <= 0) {
            interfaceCTX.showMessage({
                title: "Ошибка",
                body: `Новая дата не может быть раньше ${format(addDays(parse(updateModuleValue.prevEndDate as string, "dd.MM.yyyy", new Date()), 1), "dd.MM.yyyy")}`
            });
            return;
        }
        setIsLoadingSaveBtn(true);
        let formData = new FormData();
        const payloadData = {
            ...updateModuleValue,
            organisationId: +orgID,
            endDate: newEndDate
        };
        Object.keys(payloadData).forEach(item => {
            // @ts-ignore
            formData.append(item, payloadData[item]);
        });
        req.post(`/moderator/modules/change?moduleType=${updateModuleValue.moduleType}&status=active`, formData)
            .then(({ data }) => {
                closeDialog();
                getModules();
                interfaceCTX.showMessage({
                    body: `${t("connected_modules.module." + updateModuleValue.moduleType)} ${updateModuleValue.text === "Подключить" ? "подключен" : "продлен"}`
                });
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            })
            .finally(() => {
                setIsLoadingSaveBtn(false);
            });
    };

    const stopModuleItem = () => {
        if (!stopModuleDialog.rationale) return;
        setIsLoadingSaveBtn(true);
        let formData = new FormData();
        const payloadData = {
            organisationId: +orgID,
            rationale: stopModuleDialog.rationale,
            endDate: stopModuleDialog.prevEndDate
        };
        Object.keys(payloadData).forEach(item => {
            // @ts-ignore
            formData.append(item, payloadData[item]);
        });
        req.post(`/moderator/modules/change?moduleType=${stopModuleDialog.moduleType}&status=suspended`, formData)
            .then(({ data }) => {
                setStopModuleDialog({
                    isOpen: false
                });
                getModules();
                interfaceCTX.showMessage({
                    body: `${t("connected_modules.module." + stopModuleDialog.moduleType)} приостановлен`
                });
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            })
            .finally(() => {
                setIsLoadingSaveBtn(false);
            });
    };

    const getModules = () => {
        setIsLoading(true);
        req.get(`/moderator/modules/get?organisationId=${orgID}`)
            .then(({ data }) => {
                let sortData = data.filter((item: any) => item.moduleType !== "База рабочих").map((item: any, index: number) => {
                    if (item.moduleType === "admin") {
                        return { ...item, order: 1 };
                    } else if (item.moduleType === "recruiter") {
                        return { ...item, order: 2 };
                    } else if (item.moduleType === "hr") {
                        return { ...item, order: 3 };
                    } else if (item.moduleType === "organizer") {
                        return { ...item, order: 4 };
                    } else if (item.moduleType === "foreman") {
                        return { ...item, order: 5 };
                    } else if (item.moduleType === "worker_base") {
                        return { ...item, order: 6 };
                    } else {
                        return { ...item, order: index + 1 };
                    }
                }).sort((a: any, b: any) => a.order - b.order);
                setModules(sortData);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const closeDialog = () => {
        setUpdateModuleValue({ isOpen: false });
        setImageData({
            src: "",
            type: ""
        });
        setUpdateModuleValue(defaultModuleItemValue);
        setPaymentDataFormats({
            reason: null,
            endDate: null
        });
    };

    const isDisabledSaveButton = (moduleValue: IUpdateModuleData) => {
        if (!moduleValue.endDate) {
            return true;
        } else if (!moduleValue.reason) {
            return true;
        } else if (isLoadingSaveBtn) {
            return true;
        } else if (paymentDataFormats.reason === "promo" ? !moduleValue.rationale : !(moduleValue.price && moduleValue.file)) {
            return true;
        } else return false;
    };

    const confirmModuleConnections = () => {
        setIsLoadingSaveBtn(true);
        let formData = new FormData();
        const payloadData = {
            organisationId: +orgID,
            endDate: activateDialogValues.prevEndDate
        };
        Object.keys(payloadData).forEach(item => {
            // @ts-ignore
            formData.append(item, payloadData[item]);
        });
        req.post(`/moderator/modules/change?moduleType=${activateDialogValues.moduleType}&status=active`, formData)
            .then(({ data }) => {
                setActivateDialogValues({ isOpen: false });
                getModules();
                interfaceCTX.showMessage({
                    body: `${t("connected_modules.module." + activateDialogValues.moduleType)} подключен`
                });
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            })
            .finally(() => {
                setIsLoadingSaveBtn(false);
            });
    };

    useEffect(() => {
        getModules();
    }, []);

    return (
        <div className={"connected-modules"}>
            <CustomDialog
                isOpen={updateModuleValue.isOpen}
                onClose={() => closeDialog()}
                dialogTitle={`${updateModuleValue.text} подписку модуля`}
                buttons={[{
                    callback: () => saveUpdatedModuleValues(),
                    outlined: true,
                    raised: true,
                    disabled: isDisabledSaveButton(updateModuleValue),
                    text: updateModuleValue.text ? updateModuleValue.text : ""
                }]}
            >
                {updateModuleValue.isOpen && <div className={"connected-modules-dialog"}>
                    <div className={"connected-modules-select connected-modules-field"}>
                        <div className={"connected-modules-headline"}>
                            Период подписки до
                        </div>
                        <Select
                            className={"connected-modules-select-field"}
                            classNamePrefix={"select"}
                            //@ts-ignore
                            options={numberOfMonthsOfSubscription}
                            defaultValue={{
                                value: null,
                                label: "--Выбрать--"
                            }}
                            onChange={evt => {
                                if (!evt) return;
                                const currentDate = new Date(new Date()).toLocaleString("ru-RU").split(",")[0].split(".");
                                if (evt.value === "other" || evt.value === null) {
                                    setUpdateModuleValue((prevState) => ({
                                        ...prevState,
                                        endDate: evt.value === "other" ? null : evt.value
                                    }));
                                } else if (updateModuleValue.prevEndDate && differenceInCalendarDays(parse(updateModuleValue.prevEndDate, "dd.MM.yyyy", new Date()).getTime(), parse(currentDate.join("."), "dd.MM.yyyy", new Date()).getTime()) > 0) {
                                    if (evt.value === "1") {
                                        setUpdateModuleValue((prevState) => ({
                                            ...prevState,
                                            endDate: format(addMonths(parse(updateModuleValue.prevEndDate as string, "dd.MM.yyyy", new Date()), 1), "dd.MM.yyyy")
                                        }));
                                    } else if (evt.value === "3") {
                                        setUpdateModuleValue((prevState) => ({
                                            ...prevState,
                                            endDate: format(addMonths(parse(updateModuleValue.prevEndDate as string, "dd.MM.yyyy", new Date()), 3), "dd.MM.yyyy")
                                        }));
                                    } else if (evt.value === "12") {
                                        setUpdateModuleValue((prevState) => ({
                                            ...prevState,
                                            endDate: format(addMonths(parse(updateModuleValue.prevEndDate as string, "dd.MM.yyyy", new Date()), 12), "dd.MM.yyyy")
                                        }));
                                    }
                                } else {
                                    if (evt.value === "1") {
                                        setUpdateModuleValue((prevState) => ({
                                            ...prevState,
                                            endDate: format(addMonths(new Date(), 1), "dd.MM.yyyy")
                                        }));
                                    } else if (evt.value === "3") {
                                        setUpdateModuleValue((prevState) => ({
                                            ...prevState,
                                            endDate: format(addMonths(new Date(), 3), "dd.MM.yyyy")
                                        }));
                                    } else if (evt.value === "12") {
                                        setUpdateModuleValue((prevState) => ({
                                            ...prevState,
                                            endDate: format(addMonths(new Date(), 12), "dd.MM.yyyy")
                                        }));
                                    }
                                }
                                setPaymentDataFormats(prevState => ({
                                    ...prevState,
                                    endDate: evt.value
                                }));
                            }}
                            isSearchable={false}
                            menuPosition={"fixed"}
                            menuShouldScrollIntoView={false}
                        />
                    </div>
                    {paymentDataFormats.endDate === "other" &&
                        <>
                            <div className={"connected-modules-select connected-modules-field"}>
                                <div className={"connected-modules-headline"}>
                                    Дата окончания подписки
                                </div>
                                <TextField
                                    outlined
                                    maxLength={10}
                                    type="date"
                                    className={"connected-modules-select-field"}
                                    value={updateModuleValue.endDate ? updateModuleValue.endDate : ""}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = e.target.value;
                                        if (value === " ") return;
                                        if (value.length > 10) return;
                                        setUpdateModuleValue(prevState => ({
                                            ...prevState,
                                            endDate: value
                                        }));
                                    }}
                                />
                            </div>

                        </>
                    }
                    <div className={"connected-modules-select connected-modules-field"}>
                        <div className={"connected-modules-headline"}>
                            {`Основание ${updateModuleValue.text === "Подключить" ? "подключения" : "продления"} подписки`}
                        </div>
                        <Select
                            className={"connected-modules-select-field"}
                            classNamePrefix={"select"}
                            //@ts-ignore
                            options={reasonAddingPackage}
                            defaultValue={{
                                value: null,
                                label: "--Выбрать--"
                            }}
                            onChange={evt => {
                                if (!evt) return;
                                setUpdateModuleValue((prevState) => {
                                    if (evt.value === "promo") {
                                        setImageData({
                                            src: "",
                                            type: ""
                                        });
                                        return ({
                                            ...prevState,
                                            reason: evt.label,
                                            price: 0,
                                            file: null
                                        });
                                    } else {
                                        return ({
                                            ...prevState,
                                            reason: evt.label,
                                            rationale: ""
                                        });
                                    }
                                });
                                setPaymentDataFormats(prevState => ({
                                    ...prevState,
                                    reason: evt.value
                                }));
                            }}
                            isSearchable={false}
                            menuPosition={"fixed"}
                            menuShouldScrollIntoView={false}
                        />
                    </div>
                    {paymentDataFormats.reason === "promo" &&
                        <>
                            <div className={"connected-modules-select connected-modules-field"}>
                                <div className={"connected-modules-headline"}>
                                    Обоснование
                                </div>
                                <TextField
                                    outlined
                                    maxLength={100}
                                    className={"connected-modules-select-field"}
                                    value={updateModuleValue.rationale ? updateModuleValue.rationale : ""}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = e.target.value;
                                        if (value === " ") return;
                                        if (value.includes("+") || value.includes("-") || value.includes(".")) return;
                                        setUpdateModuleValue(prevState => ({
                                            ...prevState,
                                            rationale: value
                                        }));
                                    }}
                                />
                            </div>
                        </>
                    }
                    {paymentDataFormats.reason === "receiptPayment" &&
                        <>
                            <div className={"connected-modules-select connected-modules-field"}>
                                <div className={"connected-modules-headline"}>
                                    Сумма оплаты
                                </div>
                                <TextField
                                    outlined
                                    maxLength={30}
                                    className={"connected-modules-select-field"}
                                    value={updateModuleValue.price}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = e.target.value;
                                        if (value === " ") return;
                                        if (!Number(value) && value !== "") return;
                                        if (value.includes("+") || value.includes("-") || value.includes(".")) return;
                                        setUpdateModuleValue(prevState => ({
                                            ...prevState,
                                            price: +value
                                        }));
                                    }}
                                />
                                <div className={"connected-modules-file"}>
                                    {imageData.type !== ""
                                        ? <div className="connected-modules-file-loaded">
                                            {imageData.type.includes("pdf")
                                                ? <Icon icon={{ icon: "picture_as_pdf", size: "xlarge" }}
                                                        className={"description"} />
                                                : <>
                                                    <div style={{ backgroundImage: `url(${imageData.src})` }} />
                                                    <Icon icon={"zoom_in"} className="zoom_in"
                                                          onClick={() => interfaceCTX.getPreviewImgUrl(imageData.src)} />
                                                </>
                                            }
                                            <Icon className="close" icon="close" onClick={handleDeleteFile} />

                                        </div>
                                        : <div className="connected-modules-file-upload">
                                            <div />
                                            <Icon className="add_a_photo" icon={"add_a_photo"} />
                                            <input
                                                type="file"
                                                // accept="image/*"
                                                ref={inputRef}
                                                onChange={onSelectFile}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>}
            </CustomDialog>
            <CustomDialog
                isOpen={stopModuleDialog.isOpen}
                onClose={() => setStopModuleDialog({ isOpen: false })}
                dialogTitle={"Вы хотите приостановить модуль?"}
                buttons={[{
                    callback: () => stopModuleItem(),
                    outlined: true,
                    raised: true,
                    disabled: !stopModuleDialog?.rationale?.length || isLoadingSaveBtn,
                    text: "Приостановить"
                }]}
            >
                {stopModuleDialog.isOpen &&
                    <div className={`connected-modules-dialog connected-modules-dialog-suspended`}>
                        <div className={"connected-modules-select connected-modules-field"}>
                            <div className={"connected-modules-headline"}>
                                Укажите причину приостановки модуля
                            </div>
                            <Select
                                className={"connected-modules-select-field"}
                                classNamePrefix={"select"}
                                //@ts-ignore
                                options={reasonForStoppingTheModule}
                                defaultValue={{
                                    value: null,
                                    label: "--Выбрать--"
                                }}
                                onChange={evt => {
                                    if (!evt) return;
                                    setStopModuleDialog(prevState => {
                                        if (evt.value === "other") {
                                            return {
                                                ...prevState,
                                                rationale: "",
                                                isAnyReason: true
                                            };
                                        } else {
                                            return {
                                                ...prevState,
                                                rationale: evt.value,
                                                isAnyReason: false
                                            };
                                        }
                                    });
                                }}
                                isSearchable={false}
                                menuPosition={"fixed"}
                                menuShouldScrollIntoView={false}
                            />
                        </div>
                        {stopModuleDialog.isAnyReason &&
                            <div className={"connected-modules-select connected-modules-field"}>
                                <div className={"connected-modules-headline"}>
                                    Комментарий
                                </div>
                                <TextField
                                    outlined
                                    maxLength={100}
                                    className={"connected-modules-select-field"}
                                    value={stopModuleDialog.rationale ? stopModuleDialog.rationale : ""}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = e.target.value;
                                        if (value === " ") return;
                                        if (value.includes("+") || value.includes("-") || value.includes(".")) return;
                                        setStopModuleDialog(prevState => {
                                            return {
                                                ...prevState,
                                                rationale: value
                                            };
                                        });
                                    }}
                                />
                            </div>
                        }
                    </div>}
            </CustomDialog>
            <CustomDialog
                isOpen={activateDialogValues.isOpen}
                onClose={() => setActivateDialogValues({ isOpen: false })}
                dialogTitle={"Вы хотите подключить модуль?"}
                buttons={[{
                    callback: () => {
                        confirmModuleConnections();
                    },
                    outlined: true,
                    raised: true,
                    disabled: isLoadingSaveBtn,
                    text: "Да, подключить"
                }]}
            >
                <div className={"custom-dialog-module-activate"}>
                    Подключение модуля позволит организации использовать функции данного модуля.
                </div>
            </CustomDialog>
            <div className={"connected-modules-wrapper"}>
                <div className={"connected-modules-header"}>
                    <div className={"connected-modules-row"}>
                        <div className={"connected-modules-module connected-modules-cell"}>
                            Модуль
                        </div>
                        <div className={"connected-modules-status connected-modules-cell"}>
                            Статус
                        </div>
                        <div className={"connected-modules-endDate connected-modules-cell"}>
                            Срок окончания
                        </div>
                        <div className={"connected-modules-reason connected-modules-cell"}>
                            Основание
                        </div>
                        <div className={"connected-modules-action connected-modules-cell"}>
                            Действие
                        </div>
                        <div className={"connected-modules-subscription connected-modules-cell"}>
                            Подписка
                        </div>
                    </div>
                </div>
                <div className={"connected-modules-body"}>
                    {modules.length === 0
                        ? <div className={"connected-modules-empty"}>
                            {isLoading
                                ? <CircularProgress
                                    className="circular-progress connected-modules-empty"
                                    style={{}} />
                                : ""
                            }
                        </div>
                        : modules.map((item) => {
                            return (
                                <div className={"connected-modules-row"} key={item.id}>
                                    <div className={"connected-modules-module connected-modules-cell"}>
                                        {t("connected_modules.module." + item.moduleType)}
                                    </div>
                                    <div
                                        className={"connected-modules-status connected-modules-cell"}>
                                        {item.status === "suspended"
                                            ? <Tooltip content={item.rationale}>
                                                <div style={pickColor(item.status)}>
                                                    {t("connected_modules.status." + item.status)}
                                                </div>
                                            </Tooltip>
                                            : <div style={pickColor(item.status)}>
                                                {t("connected_modules.status." + item.status)}
                                            </div>
                                        }
                                    </div>
                                    <div className={"connected-modules-endDate connected-modules-cell"}>
                                        {item.endDate && item.status !== "disable" ? `До ${item.endDate}` : ""}
                                        {["admin"].includes(item.moduleType) && "Неограниченная"}
                                    </div>
                                    <div className={"connected-modules-reason connected-modules-cell"}>
                                        {item.reason}
                                    </div>
                                    <div
                                        className={"connected-modules-action connected-modules-cell"}
                                    >
                                        {/*{*/}
                                        {/*    !["hr", "admin", "organizer"].includes(item.moduleType) &&*/}
                                        {/*    <>*/}
                                        {/*        {["suspended"].includes(item.status) &&*/}
                                        {/*            <Icon*/}
                                        {/*                icon={{ icon: "play_arrow" }}*/}
                                        {/*                className={"connected-modules-cell-btn"}*/}
                                        {/*                onClick={() => {*/}
                                        {/*                    setActivateDialogValues(prevState => ({*/}
                                        {/*                        ...prevState,*/}
                                        {/*                        moduleType: item.moduleType,*/}
                                        {/*                        isOpen: true,*/}
                                        {/*                        prevEndDate: item.endDate*/}
                                        {/*                    }));*/}
                                        {/*                }}*/}
                                        {/*            />*/}
                                        {/*        }*/}
                                        {/*        {item.status === "active" &&*/}
                                        {/*            <Icon*/}
                                        {/*                icon={{ icon: "pause" }}*/}
                                        {/*                className={"connected-modules-cell-btn"}*/}
                                        {/*                onClick={() => {*/}
                                        {/*                    setStopModuleDialog(prevState => ({*/}
                                        {/*                        ...prevState,*/}
                                        {/*                        moduleType: item.moduleType,*/}
                                        {/*                        isOpen: true,*/}
                                        {/*                        prevEndDate: item.endDate*/}
                                        {/*                    }));*/}
                                        {/*                }}*/}
                                        {/*            />*/}
                                        {/*        }*/}
                                        {/*    </>*/}
                                        {/*}*/}
                                    </div>
                                    <div className={"connected-modules-subscription connected-modules-cell"}>
                                        {/*{*/}
                                        {/*    !["hr", "admin", "organizer"].includes(item.moduleType) &&*/}
                                        {/*    <div*/}
                                        {/*        onClick={() => {*/}
                                        {/*            setUpdateModuleValue(prevState => ({*/}
                                        {/*                ...prevState,*/}
                                        {/*                moduleType: item.moduleType,*/}
                                        {/*                prevEndDate: item.endDate ? item.endDate : null,*/}
                                        {/*                isOpen: true,*/}
                                        {/*                text: item.status === "disable" ? "Подключить" : "Продлить"*/}
                                        {/*            }));*/}
                                        {/*        }}*/}
                                        {/*        className={"connected-modules-subscription-btn"}*/}
                                        {/*    >*/}
                                        {/*        {item.status === "disable" ? "Подключить" : "Продлить"}*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                    </div
                                    >
                                </div>
                            );
                        })}
                </div>
            </div>

        </div>
    );
};