import {ERequestDocumentFields} from "./DocumentsTable.types";

export const columns = [
    {
        id: 0,
        title: "Центр дополнительной профессиональной подготовки",
        placeholder: 'Введите название центра',
        minWidth: '300px',
        fieldType: 'text',
        sortType: 'NONE',
        fieldName: ERequestDocumentFields.PROVISIONING_CENTER_NAME
    },
    {
        id: 1,
        title: "Наименование",
        placeholder: 'Введите наименование',
        minWidth: '250px',
        fieldType: 'text',
        sortType: 'NONE',
        fieldName: ERequestDocumentFields.DOCUMENT_NAME
    },
    {
        id: 2,
        title: "Номер",
        placeholder: 'Введите номер',
        minWidth: '250px',
        fieldType: 'text',
        sortType: 'NONE',
        fieldName: ERequestDocumentFields.DOCUMENT_NUMBER
    },
    {
        id: 3,
        title: "Дата выдачи",
        placeholder: 'Введите дату',
        minWidth: '190px',
        fieldType: 'date',
        sortType: 'NONE',
        fieldName: ERequestDocumentFields.BEGIN_DATE
    },
    {
        id: 4,
        title: "Срок действия",
        placeholder: 'Введите дату',
        minWidth: '190px',
        fieldType: 'date',
        sortType: 'ASC',
        fieldName: ERequestDocumentFields.END_DATE
    },
    {
        id: 5,
        title: "Документ",
        minWidth: '190px',
        fieldType: 'file',
        sortType: 'NONE',
        fieldName: ERequestDocumentFields.DOCUMENT
    },
    {
        id: 6,
        title: "Пользователь",
        minWidth: '250px',
        fieldType: 'none',
        sortType: 'NONE',
        fieldName: ERequestDocumentFields.USER_ISSUED_DOCUMENT
    },
    {
        id: 7,
        title: "Дата изменения",
        minWidth: '190px',
        fieldType: 'none',
        sortType: 'NONE',
        fieldName: ERequestDocumentFields.CREATION_DATE
    },
]