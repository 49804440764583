import { Icon } from "@rmwc/icon";
import Select from "react-select";
import { TextField } from "@rmwc/textfield";
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import { reasonAddingPackage } from "../../PaymentHistory.service";
import { FormFieldWrapper } from "./FormFieldWrapper";
import { RefObject, createRef, useContext, useEffect, useState } from "react";
import { InterfaceContext } from "global/context.interface";
import { ValidationErrorMessage } from "./ValidationErrorMessage";

export function ReasonFieldSwitcher() {
	const interfaceCTX = useContext(InterfaceContext);
	const formContext = useFormContext();

	const [showPaymentDetails, setShowPaymentDetails] = useState<
		boolean | undefined
	>(undefined);

	const [imageData, setImageData] = useState<{ src: string; type: string }>({
		src: "",
		type: "",
	});

	const inputFileRef = createRef() as RefObject<HTMLInputElement>;

	useEffect(() => {
		resetImageData();
		formContext.resetField("fileInput");
		formContext.resetField("file");
		formContext.resetField("price");
		formContext.resetField("rationale");
	}, [showPaymentDetails]);

	useEffect(() => {
		swithReason();
	}, [formContext.getValues("reason")]);

	useEffect(() => {
		console.log(formContext.getValues());
	}, [formContext.formState.isValidating]);

	function swithReason() {
		switch (formContext.getValues("reason")?.value) {
			case "receiptPayment":
				setShowPaymentDetails(true);
				break;
			case "promo":
				setShowPaymentDetails(false);
				break;
			case undefined:
				setShowPaymentDetails(undefined);
				break;
		}
	}

	function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
		console.log(e.target.files);
		if (e.target.files && e.target.files.length > 0) {
			const newFile = e.target.files[0];
			if (newFile.size > 5000000) {
				inputFileRef.current!.value = "";
				interfaceCTX.showMessage({
					title: "Ошибка",
					body: "Максимальный вес файла: 5мб",
				});
				return;
			} else if (
				!["jpg", "jpeg", "png", "pdf"].includes(newFile.type.split("/")[1])
			) {
				inputFileRef.current!.value = "";
				interfaceCTX.showMessage({
					title: "Ошибка",
					body: "Форматы файла: JPG, JPEG, PNG, PDF",
				});
				return;
			}
			const reader = new FileReader();

			reader.addEventListener("load", () => {
				formContext.setValue("file", newFile);
				setImageData({
					src: !!reader.result ? reader.result.toString() : "",
					type: newFile.type,
				});
			});
			reader.readAsDataURL(newFile);
		}
	}

	const handleDeleteFile = () => {
		setImageData({
			src: "",
			type: "",
		});
		formContext.reset({
			...formContext.getValues(),
			fileInput: undefined,
			file: undefined,
		});
		console.log(formContext.getValues());
	};

	function resetImageData() {
		setImageData((prevState) => ({
			...prevState,
			src: "",
			type: "",
		}));
	}

	return (
		<>
			<FormFieldWrapper label="Основания добавления">
				<Controller
					name="reason"
					control={formContext.control}
					rules={{
						required: true,
					}}
					render={({ field }) => {
						return (
							<Select
								{...field}
								key={field.value}
								className={"payment-history-select-field"}
								classNamePrefix={"select"}
								placeholder={"Выберите из списка"}
								options={reasonAddingPackage}
								isSearchable={false}
								menuPosition={"fixed"}
								menuShouldScrollIntoView={false}
							/>
						);
					}}
				/>
			</FormFieldWrapper>

			{showPaymentDetails === true && (
				<>
					<FormFieldWrapper label="Сумма оплаты">
						<Controller
							name="price"
							control={formContext.control}
							rules={{
								required: true,
								validate: {
									max: (v) =>
										Number(v.replace(" руб.", "").trim()) <= 999999999 ||
										"Максимум 99999999",
								},
							}}
							render={({ field, fieldState }) => {
								return (
									<>
										<NumberFormat
											{...field}
											customInput={TextField}
											outlined
											allowNegative={false}
											suffix={" руб."}
											focus={fieldState.error?.message}
										/>
										<ValidationErrorMessage
											message={fieldState.error?.message || ""}
										/>
									</>
								);
							}}
						/>
					</FormFieldWrapper>
					<div className={"payment-history-file"}>
						{imageData.type !== "" ? (
							<div className="payment-history-file-loaded">
								{imageData.type.includes("pdf") ? (
									<Icon
										icon={{ icon: "picture_as_pdf", size: "xlarge" }}
										className={"description"}
									/>
								) : (
									<>
										<div style={{ backgroundImage: `url(${imageData.src})` }} />
										<Icon
											icon={"zoom_in"}
											className="zoom_in"
											onClick={() =>
												interfaceCTX.getPreviewImgUrl(imageData.src)
											}
										/>
									</>
								)}
								<Icon
									className="close"
									icon="close"
									onClick={handleDeleteFile}
								/>
							</div>
						) : (
							<div className="payment-history-file-upload">
								<div />
								<Icon className="add_a_photo" icon={"add_a_photo"} />
								<Controller
									name="fileInput"
									control={formContext.control}
									rules={{
										required: true,
									}}
									render={({ field }) => {
										return (
											<input
												{...field}
												type="file"
												accept="image/*"
												ref={inputFileRef}
												onChange={(e) => {
													onSelectFile(e);
													field.onChange(e);
												}}
											/>
										);
									}}
								/>
							</div>
						)}
					</div>
				</>
			)}
			{showPaymentDetails === false && (
				<FormFieldWrapper label="Обоснование промо">
					<Controller
						name="rationale"
						control={formContext.control}
						rules={{
							required: true,
						}}
						render={({ field }) => {
							return <TextField {...field} outlined />;
						}}
					/>
				</FormFieldWrapper>
			)}
		</>
	);
}
