import React, { useEffect, useState } from "react";
import "./VacancyPage.style.sass";
import { useHistory, useParams } from "react-router-dom";
import { ISettingsVacancy, IVacancyData, IVacancyResponse } from "./VacancyPage.types";
import { CustomDialog, FixedButtons, PageTitle, ProgressPage } from "../../components";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../global/context.user";
import { InterfaceContext } from "../../global/context.interface";
import { ExtensionVacancy } from "../../components/ExtensionVacancy/ExtensionVacancy";
import { TextField } from "@rmwc/textfield";
import Select from "react-select";
import { isSuperAdm, req } from "../../global/common";
import Async from "react-select/async";
import { Checkbox } from "@rmwc/checkbox";
import {
    checkRequestData,
    formatVacancyData,
    getSchedule,
    renderScheduleDescription
} from "./VacancyPage.service";
import { PagesRoutes } from "../../global/routes";
import NumberFormat from "react-number-format";
import { Controller, useForm } from "react-hook-form";
import parse from "date-fns/parse";
import { VacancyCardPreview } from "./components/VacancyCardPreview";
import { declOfNum } from "../../global/utils";
import { PageHeader } from "components/PageHeader/PageHeader";
import { CurrentTariff } from "components/CurrentTariff/CurrentTariff";

export const VacancyPage: React.FC = () => {
    const [dataReceived, setDataReceived] = useState<boolean | null>(null);
    const [vacancyData, setVacancyData] = useState<IVacancyData>({} as IVacancyData);
    const [vacancySettings, setVacancySettings] = useState<ISettingsVacancy>({} as ISettingsVacancy);

    const [isOpenDialogPublish, setIsOpenDialogPublish] = useState(false);
    const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(false);
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
    const [isOpenPublishDialog, setIsOpenPublishDialog] = useState(false);
    const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
    const [isRedact, setIsRedact] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isAmocrmLoading, setIsAmocrmLoading] = useState(false);

    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation("common");
    const history = useHistory();

    const userCTX = React.useContext(UserContext);
    const interfaceCTX = React.useContext(InterfaceContext);

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: vacancyData
    });

    const updateDaysBeforeTheEnd = () => {
        setVacancyData(prevState => ({
            ...prevState,
            daysBeforeTheEndPublication: prevState.daysBeforeTheEndPublication + 30,
            vacancyState: "published"
        }));
    };

    const isDisableRedactButton = () => {
        return vacancyData.vacancyState === "saved" ||
            (vacancyData.vacancyState === "published" && vacancyData.responseCount === 0);
    };

    const renderRedactField = (fieldName: keyof IVacancyData, maxLength: number = 30) => {
        let component;
        if (["category", "address"].includes(fieldName)) {
            component = (
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={vacancyData[fieldName] as string}
                    rules={{
                        required: {
                            // @ts-ignore
                            value: vacancySettings.requiredResponse[fieldName],
                            message: "Поле обязательно к заполнению"
                        },
                        validate: v => {
                            if (!v || typeof v !== "string") return;
                            let trimmedValue = v.trim();
                            if (v.length > 0 && trimmedValue == "") {
                                return "Поле не должно состоять только из пробелов";
                            } else {
                                return true;
                            }
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                            outlined
                            maxLength={maxLength}
                            placeholder={"Введите текст"}
                            className={"vacancy-page-body-redact-textfield"}
                            defaultValue={vacancyData[fieldName] as string}
                            required={false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                if (value === " ") return;
                                interfaceCTX.checkIsFormChanged(true, "vacancy");
                                setVacancyData(prevState => ({
                                    ...prevState,
                                    [fieldName]: value
                                }));
                                onChange(e);
                            }}
                        />
                    )}
                />
            );
        } else if (["position"].includes(fieldName)) {
            const promiseOptions = (input: string) => {
                return req
                    .get("/info/professions", {
                        limit: 80,
                        offset: 0,
                        search: input
                    })
                    .then(({ data }) => {
                        return data.data.map((v: any) => ({ label: v.name, value: v.name }));
                    });
            };
            component = (
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={vacancyData.position ? vacancyData.position : null}
                    rules={{
                        required: {
                            value: vacancySettings.requiredResponse.position,
                            message: "Поле обязательно к заполнению"
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Async
                            className={"vacancy-page-body-redact-select"}
                            classNamePrefix={"select"}
                            loadOptions={promiseOptions}
                            placeholder={"Выберите из списка"}
                            cacheOptions
                            defaultOptions
                            noOptionsMessage={() => "Нет опций"}
                            defaultValue={vacancyData.position ? {
                                value: vacancyData.position,
                                label: vacancyData.position
                            } : null}
                            onChange={evt => {
                                if (!evt) return;
                                interfaceCTX.checkIsFormChanged(true, "vacancy");
                                setVacancyData(prevState => ({
                                    ...prevState,
                                    // @ts-ignore
                                    position: evt.value
                                }));
                                onChange(evt.value);
                            }}
                        />
                    )}
                />
            );
        } else if (["responsibilities", "additionalInfo"].includes(fieldName)) {
            component = (
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={vacancyData[fieldName] as string}
                    rules={{
                        required: {
                            //@ts-ignore
                            value: vacancySettings.requiredResponse[fieldName],
                            message: "Поле обязательно к заполнению"
                        },
                        validate: v => {
                            if (!v || typeof v !== "string") return;
                            let trimmedValue = v.trim();
                            if (v.length > 0 && trimmedValue == "") {
                                return "Поле не должно состоять только из пробелов";
                            } else {
                                return true;
                            }
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                            outlined
                            maxLength={maxLength}
                            placeholder={"Введите текст"}
                            textarea={true}
                            rows={3}
                            className={"vacancy-page-body-redact-textfield"}
                            defaultValue={vacancyData[fieldName] as string}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                if (value === " ") return;
                                interfaceCTX.checkIsFormChanged(true, "vacancy");
                                setVacancyData(prevState => ({
                                    ...prevState,
                                    [fieldName]: value
                                }));
                                onChange(e);
                            }}
                        />
                    )}
                />
            );
        } else if (["grade", "salaryFullNet", "probationDurationMonths", "salaryProbationNet"].includes(fieldName)) {
            component = (
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={vacancyData[fieldName] as string}
                    rules={{
                        required: {
                            // @ts-ignore
                            value: vacancySettings.requiredResponse[fieldName],
                            message: "Поле обязательно к заполнению"
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                            outlined
                            maxLength={maxLength}
                            placeholder={"Введите число"}
                            className={"vacancy-page-body-redact-textfield"}
                            defaultValue={vacancyData[fieldName] as number}
                            value={vacancyData[fieldName] as number}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                if (value === " ") return;
                                if (!Number(value) && value !== "") return;
                                if (value.includes("+") || value.includes("-") || value.includes(".")) return;
                                if (fieldName === "probationDurationMonths" && +value > 12) return;
                                interfaceCTX.checkIsFormChanged(true, "vacancy");
                                setVacancyData(prevState => ({
                                    ...prevState,
                                    [fieldName]: value ? +value : ""
                                }));
                                onChange(value ? +value : "");
                            }}
                        />
                    )}
                />
            );
        } else if (["workSchedule"].includes(fieldName)) {

            const CustomOption = ({ innerProps, isDisabled, data }: { innerProps: any, isDisabled: any, data: any }) =>
                !isDisabled ? (
                    <div {...innerProps} className={`vacancy-page-body-redact-select-option`}>
                        <div className={"vacancy-page-body-redact-select-option-headline"}>
                            {data.label}
                        </div>
                        <div className={"vacancy-page-body-redact-select-option-text"}>
                            {renderScheduleDescription(data.value)}
                        </div>
                    </div>
                ) : null;
            component = (
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={vacancyData.workSchedule ? vacancyData.workSchedule : null}
                    rules={{
                        required: {
                            // @ts-ignore
                            value: vacancySettings.requiredResponse.workSchedule,
                            message: "Поле обязательно к заполнению"
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                            className={"vacancy-page-body-redact-select"}
                            classNamePrefix={"select"}
                            placeholder={"Выберите из списка"}
                            options={getSchedule() as { label: string; value: string }[]}
                            noOptionsMessage={() => "Нет опций"}
                            components={{ Option: CustomOption }}
                            defaultValue={vacancyData.workSchedule
                                ? (getSchedule(String(vacancyData.workSchedule)) as { label: string; value: string })
                                : undefined}
                            onChange={evt => {
                                if (!evt) return;
                                setVacancyData(prevState => ({
                                    ...prevState,
                                    workSchedule: +evt.value as number
                                }));
                                onChange(+evt.value);
                            }}
                        />
                    )}
                />
            );
        } else if (["city"].includes(fieldName)) {
            const promiseOptions = (input: string) => {
                return req
                    .get("/info/cities", {
                        limit: 200,
                        offset: 0,
                        search: input
                    })
                    .then(({ data }) => {
                        return data.data.map((v: any) => ({ label: v, value: v }));
                    });
            };

            component = (
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={vacancyData.city ? vacancyData.city : null}
                    rules={{
                        required: {
                            value: vacancySettings.requiredResponse.city,
                            message: "Поле обязательно к заполнению"
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Async
                            className={"vacancy-page-body-redact-select"}
                            classNamePrefix={"select"}
                            loadOptions={promiseOptions}
                            noOptionsMessage={() => "Нет опций"}
                            cacheOptions
                            defaultOptions
                            placeholder={"Начните вводить название города"}
                            defaultValue={vacancyData.city ? {
                                value: vacancyData.city,
                                label: vacancyData.city
                            } : null}
                            onChange={evt => {
                                if (!evt) return;
                                setVacancyData(prevState => ({
                                    ...prevState,
                                    // @ts-ignore
                                    city: evt.value
                                }));
                                onChange(evt.value);
                            }}
                        />
                    )}
                />
            );
        } else if (["period"].includes(fieldName)) {
            component = (
                <div className={"vacancy-page-body-redact-dates"}>
                    <div className={"vacancy-page-body-redact-check"}>
                        <Controller
                            control={control}
                            name={"workTo"}
                            defaultValue={vacancyData.workTo}
                            rules={{
                                validate: v => {
                                    if (!v || typeof v !== "string") return;
                                    if (!vacancySettings.requiredResponse.period && (parse(v, "dd.MM.yyyy", new Date()) as unknown as string) == "Invalid Date") {
                                        return "Неверный формат даты. Проверьте правильно ли указан день и/или месяц";
                                    } else {
                                        return true;
                                    }
                                }
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Checkbox
                                    className={vacancyData.isIndefiniteContract ? "horosiy-checkbox" : "off-checkbox"}
                                    checked={vacancyData.isIndefiniteContract}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                        interfaceCTX.checkIsFormChanged(true, "vacancy");
                                        let workTo: null | string;
                                        if (vacancyData.isIndefiniteContract) {
                                            workTo = "";
                                        } else {
                                            workTo = null;
                                        }
                                        setVacancyData(prevState => ({
                                            ...prevState,
                                            workTo: workTo,
                                            isIndefiniteContract: !prevState.isIndefiniteContract
                                        }));
                                        onChange("");
                                    }}
                                />
                            )}
                        />
                        <div className={"vacancy-page-body-redact-check-text"}>
                            Бессрочный договор
                        </div>
                    </div>
                    <div className={"vacancy-page-body-redact-dates-wrapper"}>
                        <div className={"vacancy-page-body-redact-date-wrapper"}>
                            <Controller
                                control={control}
                                name={"workFrom"}
                                defaultValue={vacancyData.workFrom}
                                rules={{
                                    required: {
                                        // @ts-ignore
                                        value: vacancySettings.requiredResponse.period,
                                        message: "Поле обязательно к заполнению"
                                    },
                                    validate: v => {
                                        if (!v || typeof v !== "string") return;
                                        if (vacancySettings.requiredResponse.period && (parse(v, "dd.MM.yyyy", new Date()) as unknown as string) == "Invalid Date") {
                                            return "Неверный формат даты. Проверьте правильно ли указан день и/или месяц";
                                        } else {
                                            return true;
                                        }
                                    }
                                }}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <NumberFormat
                                        className={"vacancy-page-body-redact-date"}
                                        defaultValue={vacancyData.workFrom as string}
                                        format={"##.##.####"}
                                        type={"text"}
                                        placeholder="Дата начала дд.мм.гггг"
                                        mask={["Д", "Д", "М", "М", "Г", "Г", "Г", "Г"]}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = e.target.value;
                                            if (value === " " || value.length > 10) return;
                                            setVacancyData(prevState => ({
                                                ...prevState,
                                                workFrom: value
                                            }));
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                            <div className={"vacancy-page-body-redact-error"}>
                                {errors["workFrom"]?.message}
                            </div>
                        </div>
                        <div className={"vacancy-page-body-redact-date-wrapper"}>
                            <Controller
                                control={control}
                                name={"workTo"}
                                defaultValue={vacancyData.workTo}
                                rules={{
                                    required: {
                                        value: !vacancyData.isIndefiniteContract,
                                        message: "Поле обязательно к заполнению"
                                    },
                                    validate: v => {
                                        if (!v || typeof v !== "string") return;
                                        if ((parse(v, "dd.MM.yyyy", new Date()) as unknown as string) == "Invalid Date") {
                                            return "Неверный формат даты. Проверьте правильно ли указан день и/или месяц";
                                        } else {
                                            return true;
                                        }
                                    }
                                }}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <NumberFormat
                                        className={"vacancy-page-body-redact-date"}
                                        disabled={vacancyData.isIndefiniteContract}
                                        defaultValue={vacancyData.workTo as string}
                                        value={vacancyData.workTo as string}
                                        format={"##.##.####"}
                                        type={"text"}
                                        placeholder={"Дата окончания дд.мм.гггг"}
                                        mask={["Д", "Д", "М", "М", "Г", "Г", "Г", "Г"]}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = e.target.value;
                                            if (value === " " || value.length > 10) return;
                                            setVacancyData(prevState => ({
                                                ...prevState,
                                                workTo: value
                                            }));
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                            <div className={"vacancy-page-body-redact-error"}>
                                {!vacancyData.isIndefiniteContract && errors["workTo"]?.message}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className={"vacancy-page-body-redact-item"}>
                <div className={"vacancy-page-body-redact-title"}>
                    {t("vacancy.fields." + fieldName)}
                    {
                        // @ts-ignore
                        vacancySettings.requiredResponse[fieldName] && <div>*</div>
                    }
                </div>
                {component}
                {fieldName !== "period" &&
                    <div className={"vacancy-page-body-redact-error"}>
                        {errors[fieldName]?.message}
                    </div>
                }
            </div>
        );
    };

    const renderViewField = (fieldName: keyof IVacancyData) => {
        let field: any;
        if (fieldName === "workFrom") {
            field = `${vacancyData.workFrom} ${vacancyData.workTo ? "- " + vacancyData.workTo : "- бессрочный"}`;
        } else if (fieldName === "workSchedule") {
            //@ts-ignore
            field = vacancyData.workSchedule ? getSchedule(vacancyData.workSchedule.toString()).label + " - " + renderScheduleDescription(vacancyData.workSchedule.toString()) : "Информация не указана";
        } else {
            field = vacancyData[fieldName] ? vacancyData[fieldName] : "Информация не указана";
        }
        return (
            <>
                <div className={"vacancy-page-body-view-item"}>
                    <div className={"vacancy-page-body-view-title"}>
                        {t("vacancy.fields." + fieldName)}
                    </div>
                    <div className={"vacancy-page-body-view-value"}>
                        {field}
                    </div>
                </div>
                {/*{fieldName === "workSchedule" && vacancyData.workSchedule &&*/}
                {/*    <div className={"vacancy-page-body-view-item"}>*/}
                {/*        {scheduleDescription(vacancyData.workSchedule.toString())}*/}
                {/*    </div>*/}
                {/*}*/}
            </>
        );
    };

    const publishVacancy = (requestData: any) => {
        if (checkRequestData(requestData, interfaceCTX)) return;
        if (requestData) {
            requestData = vacancyData;
        } else if (!requestData.workTo) {
            requestData.isIndefiniteContract = true;
        }
        setIsOpenPublishDialog(false);
        setIsLoading(true);
        req.post(`/vacancy/organisation/set${id && id !== "create" ? "?vacancyId=" + id : ""}`, requestData)
            .then(({ data }) => {
                interfaceCTX.checkIsFormChanged(false, "vacancy");
                req.post(`/vacancy/organisation/${data.id}/publish`)
                    .then(({ data }) => {
                        formatVacancyData(data, setVacancyData);
                        interfaceCTX.setRefreshCurrentTariff()
                        setVacancyData(prevState => ({
                            ...prevState,
                            vacancyState: "published"
                        }));
                        setIsRedact(false);
                        interfaceCTX.showMessage({
                            body: "Вакансия успешно опубликована",
                            icon: "done"
                        });

                        history.push({
                            pathname: PagesRoutes.VACANCIES_LIST,
                            state: [{ path: "/vacancies", url: "/vacancies", title: "Список вакансий" }]
                        });

                    })
                    .catch(e => {
                        if (e.response.data === "vacancy.need_more_gold") {
                            setIsOpenErrorDialog(true);
                        } else {
                            interfaceCTX.showMessage({
                                title: "Ошибка",
                                body: t(
                                    e.response.data.includes("dayOfMonth")
                                        ? "Неверный формат даты. Проверьте правильно ли указан день и/или месяц"
                                        : e.response.data
                                )
                            });
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
                if (id === "create") {
                    history.push({
                        pathname: PagesRoutes.VACANCIES_LIST + PagesRoutes.VACANCY_PAGE + `/${data.id}`,
                        state: [{ path: "/vacancies", url: "/vacancies", title: "Список вакансий" }]
                    });
                }
                formatVacancyData(data, setVacancyData);
            })
            .catch(e => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t(
                        e.response.data.includes("dayOfMonth")
                            ? "Неверный формат даты. Проверьте правильно ли указан день и/или месяц"
                            : e.response.data
                    )
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const saveVacancy = (requestData: any) => {
        if (checkRequestData(requestData, interfaceCTX)) return;
        if (!requestData.workTo) {
            requestData.isIndefiniteContract = true;
        }
        setIsLoading(true);
        req.post(`/vacancy/organisation/set${id && id !== "create" ? "?vacancyId=" + id : ""}`, requestData)
            .then(({ data }) => {
                interfaceCTX.checkIsFormChanged(false, "vacancy");
                // @ts-ignore
                if (id === "create") {
                    history.push({
                        pathname: PagesRoutes.VACANCIES_LIST + PagesRoutes.VACANCY_PAGE + `/${data.id}`,
                        state: [{ path: "/vacancies", url: "/vacancies", title: "Список вакансий" }]
                    });
                }
                setIsRedact(false);
                formatVacancyData(data, setVacancyData);
            })
            .catch(e => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t(
                        e.response.data.includes("dayOfMonth")
                            ? "Неверный формат даты. Проверьте правильно ли указан день и/или месяц"
                            : e.response.data
                    )
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const deleteVacancy = () => {
        setIsLoading(true);
        req.delete(`/vacancy/organisation/${id}/delete`)
            .then(({ data }) => {
                interfaceCTX.showMessage({
                    body: "Вакансия успешно удалена",
                    icon: "done"
                });
                history.push({
                    pathname: PagesRoutes.VACANCIES_LIST,
                    state: [{
                        path: PagesRoutes.VACANCIES_LIST,
                        url: PagesRoutes.VACANCIES_LIST,
                        title: "Список вакансий"
                    }]
                });
            })
            .catch(e => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t(e.response.data)
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const amocrmCreateTask = () => {
        setIsAmocrmLoading(true);
        req.post("/amocrm/create_task", {
            workforceUserId: userCTX.user?.id
        }).then(({ data }) => {
            req.get("/organisation/request-coin")
                .then(({ data }) => {

                });
            if (data.done) {
                interfaceCTX.showMessage({
                    body: "Запрос на пополнение отправлен",
                    icon: "done"
                });
            }
        }).finally(() => {
            setIsAmocrmLoading(false);
        });
    };

    useEffect(() => {
        if (id && id !== "create") {
            req.get(`/vacancy/organisation/${id}`)
                .then(({ data }) => {
                    req.get(`/vacancy/organisation/fields-settings/get?vacancyId=${id}`)
                        .then(({ data }) => {
                            setVacancySettings(data);
                        })
                        .then(() => {
                            setIsRedact(false);
                            formatVacancyData(data, setVacancyData);
                            setDataReceived(true);
                        });
                })
                .catch(e => {
                    setDataReceived(false);
                    interfaceCTX.showMessage({
                        title: "Ошибка",
                        body: t(e.response.data)
                    });
                });
        } else {
            req.get(`/vacancy/organisation/fields-settings/get`)
                .then(({ data }) => {
                    setVacancySettings(data);
                    setVacancyData(prevState => ({
                        ...prevState,
                        vacancyState: "create",
                        additionalInfo: "",
                        address: "",
                        category: "",
                        city: "",
                        grade: "",
                        isIndefiniteContract: false,
                        position: "",
                        probation: false,
                        probationDurationMonths: "",
                        responsibilities: "",
                        salaryFullNet: "",
                        salaryProbationNet: "",
                        workFrom: "",
                        workSchedule: "",
                        workTo: ""
                    }));
                    setIsRedact(true);
                    setDataReceived(true);
                });
        }
    }, [id]);

    return (
        <div className={"vacancy-page"}>
            <ProgressPage
                state={dataReceived}
                render={() => (
                    <div>
                        <ExtensionVacancy
                            id={+id}
                            updateDaysBeforeTheEnd={updateDaysBeforeTheEnd}
                            isOpenDialogPublish={isOpenDialogPublish}
                            setIsOpenDialogPublish={setIsOpenDialogPublish}
                        />
                        <PageHeader>
                            <PageTitle
                                title={`Вакансия ${vacancyData.number ? vacancyData.number : ""}`}
                                breadcrumbs={true}
                            />
                            <CurrentTariff />
                            <CustomDialog
                                isOpen={isOpenErrorDialog}
                                onClose={() => setIsOpenErrorDialog(false)}
                                dialogTitle={"Пополнить баланс"}
                                buttons={[
                                    {
                                        callback: () => {
                                            amocrmCreateTask();
                                        },
                                        raised: true,
                                        disabled: isAmocrmLoading,
                                        text: "ПОПОЛНИТЬ БАЛАНС"
                                    }
                                ]}
                            >
                                <div style={{
                                    padding: "20px 4px",
                                    width: "642px",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "19px",
                                    textAlign: "center",
                                    color: "#12324F"
                                }}>
                                    Для размещения вакансий, необходимо приобрести возможность публиковать объявления,
                                    на которые потом смогут откликаться соискатели. Нажмите кнопку "Пополнить баланс" и
                                    менеджер свяжется с вами в ближайшее время.
                                </div>
                            </CustomDialog>
                            <CustomDialog
                                isOpen={isOpenDeleteDialog}
                                onClose={() => setIsOpenDeleteDialog(false)}
                                dialogTitle={"Удалить эту вакансию?"}
                                buttons={[
                                    {
                                        text: "Отмена",
                                        callback: () => {
                                            setIsOpenDeleteDialog(false);
                                        },
                                        outlined: true
                                    },
                                    {
                                        text: "Удалить",
                                        callback: () => {
                                            deleteVacancy();
                                        },
                                        raised: true
                                    }
                                ]}
                            >
                                <div style={{
                                    padding: "20px 50px",
                                    width: "500px",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "19px",
                                    textAlign: "center",
                                    color: "#12324F"
                                }}>
                                    После удаления карточку вакансии невозможно будет восстановить
                                </div>
                            </CustomDialog>
                            <CustomDialog
                                isOpen={isOpenPublishDialog}
                                onClose={() => setIsOpenPublishDialog(false)}
                                dialogTitle={"Опубликовать вакансию?"}
                                buttons={[
                                    {
                                        text: "Отмена",
                                        callback: () => {
                                            setIsOpenPublishDialog(false);
                                        },
                                        outlined: true
                                    },
                                    {
                                        text: "Опубликовать",
                                        callback: () => {
                                            handleSubmit(publishVacancy)();
                                        },
                                        raised: true
                                    }
                                ]}
                            >
                                <div style={{
                                    padding: "20px 50px",
                                    width: "500px",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "19px",
                                    textAlign: "center",
                                    color: "#12324F"
                                }}>
                                    С Вас будет списана 1 публикация
                                </div>
                            </CustomDialog>
                            <VacancyCardPreview
                                vacancyData={vacancyData}
                                vacancySettings={vacancySettings}
                                isOpen={isOpenPreviewDialog}
                                onClose={() => setIsOpenPreviewDialog(false)}
                            />
                        </PageHeader>
                        <div className={"vacancy-page-top"}>
                            {!["create", "deleted", "saved", "withdrawn_from_publication"].includes(vacancyData.vacancyState) &&
                                <div className={"vacancy-page-top-extend"}>
                                    <div className={"vacancy-page-top-title"}>
                                        Срок публикации истекает
                                        через {vacancyData.daysBeforeTheEndPublication + declOfNum(vacancyData.daysBeforeTheEndPublication, [" день", " дня", " дней"])}.
                                    </div>
                                    {!isSuperAdm(userCTX.user) &&
                                        <div className={"vacancy-page-top-btn"}
                                             onClick={() => {
                                                 setIsOpenDialogPublish(true);
                                             }}>
                                            Продлить
                                        </div>
                                    }
                                </div>
                            }
                            {vacancyData.vacancyState === "withdrawn_from_publication" &&
                                <div className={"vacancy-page-top-extend"}>
                                    <div className={"vacancy-page-top-title"}>
                                        Снята с публикации {vacancyData.dateWithdrawnFromPublicationAt}
                                    </div>
                                    {!isSuperAdm(userCTX.user) &&
                                        <div className={"vacancy-page-top-btn"}
                                             onClick={() => {
                                                 setIsOpenDialogPublish(true);
                                             }}>
                                            Продлить
                                        </div>
                                    }
                                </div>
                            }
                            <div className={"vacancy-page-top-item"}>
                                <div className={"vacancy-page-top-title"}>
                                    Статус
                                </div>
                                <div className={"vacancy-page-top-text"}>
                                    {t("vacancy." + vacancyData.vacancyState)}
                                </div>
                            </div>
                            <div className={"vacancy-page-top-item"}>
                                <div className={"vacancy-page-top-title"}>
                                    Наименование организации
                                </div>
                                <div className={"vacancy-page-top-text"}>
                                    {vacancyData.organisationName ? vacancyData.organisationName : userCTX.user?.organisation.fullname}
                                </div>
                            </div>
                        </div>
                        {isRedact ?
                            <div className={"vacancy-page-body-redact"}>
                                <div className={"vacancy-page-body-redact-container"}>
                                    <div className={"vacancy-page-body-redact-block"}>
                                        <div className={"vacancy-page-body-redact-block-headline"}>
                                            Основная информация
                                        </div>
                                        {!vacancySettings.hiddenResponse.position &&
                                            renderRedactField("position")}
                                        {!vacancySettings.hiddenResponse.grade &&
                                            renderRedactField("grade", 8)}
                                        {!vacancySettings.hiddenResponse.category &&
                                            renderRedactField("category", 10)}
                                        {!vacancySettings.hiddenResponse.workSchedule &&
                                            renderRedactField("workSchedule")}
                                        {!vacancySettings.hiddenResponse.city &&
                                            renderRedactField("city")}
                                        {!vacancySettings.hiddenResponse.address &&
                                            renderRedactField("address", 200)}
                                    </div>
                                    <div className={"vacancy-page-body-redact-block"}>
                                        <div className={"vacancy-page-body-redact-block-headline"}>
                                            Договор
                                        </div>
                                        {!vacancySettings.hiddenResponse.salaryFullNet &&
                                            renderRedactField("salaryFullNet", 10)}
                                        {!vacancySettings.hiddenResponse.period &&
                                            renderRedactField("period")}
                                        {(!vacancySettings.hiddenResponse.probationDurationMonths ||
                                                !vacancySettings.hiddenResponse.salaryProbationNet) &&
                                            <div className={"vacancy-page-body-redact-item"}>
                                                <div className={"vacancy-page-body-redact-check"}>
                                                    <Controller
                                                        control={control}
                                                        name={"probation"}
                                                        defaultValue={vacancyData.probation}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <Checkbox
                                                                className={vacancyData.probation ? "horosiy-checkbox" : "off-checkbox"}
                                                                checked={vacancyData.probation}
                                                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                    let probationDurationMonths: null | string,
                                                                        salaryProbationNet: null | string;
                                                                    if (vacancyData.probation) {
                                                                        probationDurationMonths = "";
                                                                        salaryProbationNet = "";
                                                                    } else {
                                                                        probationDurationMonths = null;
                                                                        salaryProbationNet = null;
                                                                    }
                                                                    setVacancyData(prevState => ({
                                                                        ...prevState,
                                                                        probation: !prevState.probation,
                                                                        probationDurationMonths: probationDurationMonths,
                                                                        salaryProbationNet: salaryProbationNet
                                                                    }));
                                                                    onChange(!vacancyData.probation);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <div className={"vacancy-page-body-redact-check-text"}>
                                                        Без испытательного срока
                                                    </div>
                                                </div>
                                            </div>}
                                        {!vacancySettings.hiddenResponse.probationDurationMonths &&
                                            !vacancyData.probation &&
                                            renderRedactField("probationDurationMonths", 2)}
                                        {!vacancySettings.hiddenResponse.salaryProbationNet &&
                                            !vacancyData.probation &&
                                            renderRedactField("salaryProbationNet", 10)}
                                    </div>
                                    <div className={"vacancy-page-body-redact-block"}>
                                        <div className={"vacancy-page-body-redact-block-headline"}>
                                            Дополнительно
                                        </div>
                                        {!vacancySettings.hiddenResponse.responsibilities &&
                                            renderRedactField("responsibilities", 1000)}
                                        {!vacancySettings.hiddenResponse.additionalInfo &&
                                            renderRedactField("additionalInfo", 1000)}
                                    </div>
                                </div>
                            </div>
                            : <div className={"vacancy-page-body-view"}>
                                <div className={"vacancy-page-body-view-wrapper"}>
                                    <div className={"vacancy-page-body-view-headline"}>
                                        Основная информация
                                    </div>
                                    {!vacancySettings.hiddenResponse.position &&
                                        vacancyData.position &&
                                        renderViewField("position")}
                                    {!vacancySettings.hiddenResponse.grade &&
                                        vacancyData.grade &&
                                        renderViewField("grade")}
                                    {!vacancySettings.hiddenResponse.category &&
                                        vacancyData.category &&
                                        renderViewField("category")}
                                    {!vacancySettings.hiddenResponse.workSchedule &&
                                        vacancyData.workSchedule &&
                                        renderViewField("workSchedule")}
                                    {!vacancySettings.hiddenResponse.city &&
                                        vacancyData.city &&
                                        renderViewField("city")}
                                    {!vacancySettings.hiddenResponse.address &&
                                        vacancyData.address &&
                                        renderViewField("address")}
                                </div>
                                <div className={"vacancy-page-body-view-wrapper"}>
                                    <div className={"vacancy-page-body-view-headline"}>
                                        Договор
                                    </div>
                                    {!vacancySettings.hiddenResponse.salaryFullNet &&
                                        vacancyData.salaryFullNet &&
                                        renderViewField("salaryFullNet")}
                                    {!vacancySettings.hiddenResponse.period &&
                                        vacancyData.workFrom &&
                                        renderViewField("workFrom")}
                                    {!vacancySettings.hiddenResponse.probationDurationMonths &&
                                        vacancyData.probationDurationMonths &&
                                        renderViewField("probationDurationMonths")}
                                    {!vacancySettings.hiddenResponse.salaryProbationNet &&
                                        vacancyData.salaryProbationNet &&
                                        renderViewField("salaryProbationNet")}
                                </div>
                                <div className={"vacancy-page-body-view-wrapper"}>
                                    <div className={"vacancy-page-body-view-headline"}>
                                        Дополнительно
                                    </div>
                                    {!vacancySettings.hiddenResponse.responsibilities &&
                                        vacancyData.responsibilities &&
                                        renderViewField("responsibilities")}
                                    {!vacancySettings.hiddenResponse.additionalInfo &&
                                        vacancyData.additionalInfo &&
                                        renderViewField("additionalInfo")}
                                </div>
                            </div>
                        }
                        <div className={"vacancy-page-bottom"}>
                            {!isSuperAdm(userCTX.user) &&
                                <FixedButtons
                                    className={"buttons"}
                                    length={3}
                                    buttons={[
                                        {
                                            label: "Редактировать",
                                            disabled: !isDisableRedactButton() || isLoading,
                                            className: "button",
                                            primary: true,
                                            onClick: () => setIsRedact(true),
                                            hide: isRedact
                                        },
                                        {
                                            label: <div style={{ display: "flex" }}>
                                                {!vacancyData.responseCount && "Пока нет откликов"}
                                                {!vacancyData.sinceLastView && !!vacancyData.responseCount && `Смотреть отклики (${vacancyData.responseCount})`}
                                                {!!vacancyData.sinceLastView && !!vacancyData.responseCount &&
                                                    <div style={{ display: "flex" }}>
                                                        {`Смотреть отклики (${vacancyData.responseCount}`}
                                                        <div style={{
                                                            color: "#FFA800",
                                                            display: "flex"
                                                        }}>&nbsp;{`+${vacancyData.sinceLastView}`}</div>
                                                        )
                                                    </div>
                                                }
                                            </div>,
                                            className: "button",
                                            disabled: isLoading || vacancyData.responseCount === 0,
                                            primary: true,
                                            onClick: () => {
                                                history.push({
                                                    pathname: PagesRoutes.VACANCIES_LIST + "/response-list/" + id,
                                                    state: history.location.state
                                                });
                                            },
                                            hide: vacancyData.vacancyState === "create" || vacancyData.vacancyState === "saved" || isRedact
                                        },
                                        {
                                            label: `Опубликовать (доступно ${userCTX.user?.organisation?.tariff?.organisationPublications || 0})`,
                                            className: "button",
                                            primary: true,
                                            onClick: () => {
                                                setIsOpenPublishDialog(true);
                                            },
                                            hide: vacancyData.vacancyState !== "saved",
                                            tooltip: !userCTX.user?.organisation.canPublish ? "Необходимо пройти модерацию" : undefined,
                                            disabled: isLoading || !userCTX.user?.organisation.canPublish
                                            // && vacancyData.vacancyState !== "create"
                                        },
                                        {
                                            label: "Сохранить",
                                            className: "button",
                                            disabled: isLoading,
                                            onClick: () => {
                                                handleSubmit(saveVacancy)();
                                            },
                                            hide: !isRedact
                                        },
                                        {
                                            label: "Удалить",
                                            className: "button",
                                            disabled: isLoading,
                                            onClick: () => {
                                                setIsOpenDeleteDialog(true);
                                            },
                                            hide: (vacancyData.vacancyState !== "create" && vacancyData.vacancyState !== "saved") || !vacancyData.id
                                        },
                                        {
                                            label: "Предпросмотр",
                                            className: "button",
                                            disabled: isLoading,
                                            onClick: () => {
                                                setIsOpenPreviewDialog(true);
                                            }
                                        },
                                        {
                                            label: `Пригласить рабочих (${vacancyData.candidatesCount})`,
                                            className: "button",
                                            primary: true,
                                            disabled: isLoading,
                                            onClick: () => {
                                                history.push("/workers-database");
                                            },
                                            hide: vacancyData.vacancyState === "create" || vacancyData.vacancyState === "saved" || isRedact
                                        }
                                    ]}
                                />
                            }
                        </div>
                    </div>
                )} />
        </div>
    );
};