import React, {useState} from "react";
import {Route} from "react-router-dom";
import {GroupChanging} from "./components/group-changing/group-changing";
import {GroupsList} from "./components/groups-list/groups-list";
import './professions-groups.sass'
import {useHistory} from "react-router";

export const ProfessionsGroups = () => {
    const history = useHistory()

    return (
        <div className={'professions-groups'}>
            {history.location.pathname.includes('redact-group') &&
                <GroupChanging/>
            }
            {history.location.pathname.includes('groups') &&
                <GroupsList/>
            }
        </div>
    )
}