import React, { useContext, useEffect, useState } from "react";
import { ISurveyListItem } from "./SurveyListPage.types";
import { req } from "../../global/common";
import { InterfaceContext } from "../../global/context.interface";
import { useTranslation } from "react-i18next";
import { PageTitle, ProgressPage } from "../../components";
import { useHistory } from "react-router";
import "./SurveyListPage.style.sass";
import { Button } from "@rmwc/button";
import { stringToURL } from "../../components/Form/Form.service";


export const SurveyListPage = () => {
    const [surveyList, setSurveyList] = useState<ISurveyListItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean | null>(null);
    const interfaceCTX = useContext(InterfaceContext);
    const { t } = useTranslation("common");
    const history = useHistory();

    const getUpdateHistory = () => {
        setIsLoading(null);
        req.get("/profile/survey/list")
            .then(({ data }) => {
                setSurveyList(data.data);
                if (data.data.length === 0) {
                    setIsLoading(false);
                } else {
                    setIsLoading(true);
                }
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            });
    };

    const confirmViewingSurvey = (id: number) => {
        setIsLoading(null);
        req.get(`/profile/survey/approve?surveyId=${id}`)
            .then(({ data }) => {
                getUpdateHistory();
                history.go(0);
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getUpdateHistory();
    }, []);

    return (
        <div className={"survey-list-page"}>
            <PageTitle title={"Опрос"} breadcrumbs={true} />
            <ProgressPage
                state={isLoading}
                render={() => (
                    <>
                        <div className={"survey-list-page-body"}>
                            {surveyList.map((item) =>
                                <div className={"survey-list-page-item"}
                                     key={item.id}
                                >
                                    <div className={"survey-list-page-item-name"}>
                                        {item.topic}
                                    </div>
                                    <div className={"survey-list-page-item-description"}>
                                        {item.text}
                                    </div>
                                    <div className={"survey-list-page-item-bottom"}>
                                        <div className={"survey-list-page-item-dateTo"}>
                                            Доступен до {item.dateTo}
                                        </div>
                                        <a target={"_blank"} href={stringToURL(item.link)}>
                                            <Button
                                                raised={true}
                                                className={"survey-list-page-item-button"}
                                                onClick={event => {
                                                    confirmViewingSurvey(item.id);
                                                }}
                                            >
                                                Пройти опрос
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )} />
        </div>
    );
};