import './PaidVacanciesHistory.style.sass'
import React from "react";
import {IPaidVacanciesHistory} from "./PaidVacanciesHistory.types";
import {Link} from "react-router-dom";
import {PagesRoutes} from "../../../../global/routes";
import {useHistory} from "react-router";

export const PaidVacanciesHistory: React.FC<IPaidVacanciesHistory> = ({balanceHistory}) => {
    const history = useHistory()

    return (
        <div className={'paid-vacancies-history'}>
            <div className={'paid-vacancies-history-wrapper'}>
                <div className={'paid-vacancies-history-header'}>
                    <div className={'paid-vacancies-history-header-row'}>
                        <div className={'paid-vacancies-history-date paid-vacancies-history-first-cell'}>
                            Дата изменения
                        </div>
                        <div className={'paid-vacancies-history-service paid-vacancies-history-middle-cell'}>
                            Услуга
                        </div>
                        <div className={'paid-vacancies-history-need paid-vacancies-history-last-cell'}>
                            Вакансия
                        </div>
                    </div>
                </div>
                <div className={'paid-vacancies-history-body'}>
                    {balanceHistory.length === 0
                        ? <div className={'paid-vacancies-history-empty'}>
                            Нет истории изменений
                        </div>
                        : balanceHistory.map((item) => {
                            return (
                                <div className={'paid-vacancies-history-body-row'} key={item.request.id}>
                                    <div className={'paid-vacancies-history-date paid-vacancies-history-first-cell'}>
                                        {item.createdAt}
                                    </div>
                                    <div
                                        className={'paid-vacancies-history-service paid-vacancies-history-middle-cell'}>
                                        {item.service}
                                    </div>
                                    <div className={'paid-vacancies-history-need paid-vacancies-history-last-cell'}>
                                        <Link to={{
                                            pathname: PagesRoutes.VACANCIES_LIST + PagesRoutes.VACANCY_PAGE + '/' + item.request.id,
                                            state: [{path: '/vacancies', url: '/vacancies', title: 'Список вакансий'}]
                                        }}>{item.request.position}</Link>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}
