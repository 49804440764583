import React from "react";
import { PageTitle, TabButtons } from "components";
import PublicProfilePage from "./components/PublicProfilePage/public-profile";
import { CurrentTariff } from "../../components/CurrentTariff";
import { InterfaceContext } from "../../global/context.interface";
import { PaymentHistory } from "./components/PaymentHistory/PaymentHistory";
import { UserContext } from "../../global/context.user";
import { useParams } from "react-router-dom";
import { Params } from "./components/PublicProfilePage/public-profile-def";
import { PageHeader } from "components/PageHeader/PageHeader";

export function PublicProfile() {
    const interfaceCTX = React.useContext(InterfaceContext);
    const userCTX = React.useContext(UserContext);
    const { searchID, status } = useParams() as Params;

    let formChanged = Object.values(interfaceCTX.isFormChanged).filter(v => v == true).length != 0;

    const tabClick = () => {
        if (formChanged) {
            if (window.confirm("Все несохраненные данные будут удалены. Вы уверены?")) {
                interfaceCTX.resetIsFormChanged();
                return true;
            } else return false;
        } else return true;
    };

    return (
        <div className={"organization-profile"}>
            <PageHeader>
                <PageTitle title={"Профиль организации"} breadcrumbs={true} />
                {userCTX.user?.currentRole !== "organizer" &&
                    <CurrentTariff />}
            </PageHeader>

            <TabButtons
                whiteBackground={false}
                data={
                    userCTX.user?.organisation.id && userCTX.user?.organisation?.id.toString() === searchID
                        ? {
                            "Профиль": () => <PublicProfilePage />,
                            "История платежей": () => <PaymentHistory />
                        }
                        : {
                            "Профиль": () => <PublicProfilePage />
                        }
                }
                embed={[true, true, true, true, false, true]}
                tabClick={tabClick}
            />

        </div>
    );
}