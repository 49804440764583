import React, { FC, useCallback } from "react";
import { InfoBlock, SpanButtons } from "components";
import { UserProfile } from "../../../../../global/definitions/definitions";
import { GarmentsSubPageProps } from "./garmens-def";
import "./garments.sass";

export const GarmentsSubPage: FC<GarmentsSubPageProps> = ({
  anthropometry,
  setIsOpenEditWorkerProfileForm,
  isDisabledBlock,
}) => {
  const { headGirth, waistGirth, hipsGirth, height, shoeSize, bustGirth } =
    anthropometry || ({} as UserProfile["anthropometry"]);

  const handleClick = useCallback(() => {
    setIsOpenEditWorkerProfileForm("anthropometry");
  }, []);

  return (
    <div className="garments-page-wrapper">
      <div className="garments-page-list">
        {!!headGirth && (
          <InfoBlock title="Размер головы, см" content={headGirth || ""} />
        )}
        {!!bustGirth && (
          <InfoBlock
            title="Обхват груди, см"
            content={bustGirth?.toString() || ""}
          />
        )}
        {!!waistGirth && (
          <InfoBlock
            title="Обхват талии, см"
            content={waistGirth?.toString() || ""}
          />
        )}
      </div>

      <div className="garments-page-list">
        {!!height && (
          <InfoBlock title="Рост, см" content={height?.toString() || ""} />
        )}

        {!!shoeSize && (
          <InfoBlock
            title="Размер ноги, см"
            content={shoeSize?.toString() || ""}
          />
        )}
      </div>
      <div>
        <SpanButtons
          data={[
            {
              label: "Редактировать",
              onClick: handleClick,
              disabled: isDisabledBlock,
            },
          ]}
        />
      </div>
    </div>
  );
};
