import {DependencyList, useEffect} from "react";

export const useAutoCloseDialog = (fn: () => void, deps?: DependencyList,) => {
    useEffect(() => {
        function closeSearch(e: any) {
            if (document.activeElement?.tagName == "BODY") {
                fn.apply(undefined, deps as [])
            }
        }

        document.getElementsByTagName('body')[0].addEventListener('click', closeSearch)
        return () => {
            document.getElementsByTagName('body')[0].removeEventListener('click', closeSearch)
        }
    }, deps)
}