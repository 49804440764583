import { Icon } from '@rmwc/icon'
import { AvatarEditor } from 'components/AvatarEditor/AvatarEditor'
import { attrList } from 'global/common'
import { InterfaceContext } from 'global/context.interface'
import { useCallback, useContext, useState } from 'react'
import { WorkerImageProps } from './worker-profile-image-def'
import './worker-profile-image.sass'

export const WorkerProfileImage = <TAvatar extends { id?: number; path?: string; type?: string }>({
	id,
	image,
	onImageChange,
	onImageDelete,
	withCrop,
	className,
	style
}: WorkerImageProps<TAvatar>) => {
	const interfaceCTX = useContext(InterfaceContext)
	const [openCrop, setOpenCrop] = useState(false)

	const handleOnAvatarUpdate = useCallback(file => {
		if (!file) return
		onImageChange?.(file)
		setOpenCrop(false)
	}, [])

	const handleSelectFile = useCallback(() => {
		if (withCrop) {
			setOpenCrop(true)
		} else {
			interfaceCTX.openInput()
		}
	}, [withCrop, interfaceCTX])

	const handleDeleteFile = useCallback(() => {
		onImageDelete?.(image)
	}, [onImageDelete, image])

	return (
		<div className={`avatar-container ${className}`} style={style && style}>
			<div
				className='image-input'
				{...attrList([
					'style',
					image?.path && {
						backgroundImage: `url(${image.path})`,
						backgroundSize: 'contain',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center'
					}
				])}
			>
				{!image?.path && (
					<label htmlFor={id} onClick={handleSelectFile} style={{ width: '100%', height: '100%' }}>
						<Icon className='add-photo' icon='add_a_photo' />
					</label>
				)}
				{withCrop && (
					<AvatarEditor onImageSave={handleOnAvatarUpdate} aspectRatio={1} isOpen={openCrop} setIsOpen={setOpenCrop} />
				)}

				{!withCrop && (
					<input
						id={id}
						className='hidden-input'
						type='file'
						accept='image/*'
						onChange={e => {
							handleOnAvatarUpdate(e?.target?.files?.[0])
						}}
					/>
				)}
			</div>

			{image?.path && <Icon className='cancel' icon='close' onClick={handleDeleteFile} />}
		</div>
	)
}
