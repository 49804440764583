import "./ModuleBlockNotification.style.sass";
import React, { useContext, useState } from "react";
import { IModuleBlockNotification } from "./ModuleBlockNotification.types";
import { Button } from "@rmwc/button";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "../CustomDialog";
import { getModuleTypeText } from "./ModuleBlockNotification.data";
import { InterfaceContext } from "global/context.interface";
import { req } from "../../global/common";

export const ModuleBlockNotification: React.FC<IModuleBlockNotification> = ({ moduleType }) => {
    const { t } = useTranslation("common");
    const { showMessage } = useContext(InterfaceContext);
    const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);

    const createTaskToAMOCRM = () => {
        setIsLoadingBtn(true);
        const findRoleByModuleType = () => {
            if (["objects", "timesheet", "time_keeping"].includes(moduleType)) {
                return "foreman";
            } else if (["mobilization"].includes(moduleType)) {
                return "organizer";
            } else if (["need", "worker"].includes(moduleType)) {
                return "hr";
            }
        };
        req.post(`/organisation/modules/request?moduleType=${findRoleByModuleType()}`)
            .then(() => {
                setIsOpenInfoModal(true)
                // showMessage({
                //     body: `Заявка на подключение модуля отправлена`,
                //     icon: "done"
                // });
            })
            .catch(e => {
                showMessage({
                    title: "Ошибка",
                    body: "",
                    icon: "report"
                });
            })
            .finally(() => {
                setIsLoadingBtn(false);
            });
    };

    return (
        <div className={"module-block-notification"}>
            <div className={"module-block-notification-role"}>{t("connected_modules.module." + moduleType)}</div>
            <div className={"module-block-notification-body"}>{getModuleTypeText(moduleType)}</div>
            <Button
                className={"module-block-notification-btn"}
                type="button"
                raised={true}
                disabled={isLoadingBtn}
                onClick={() => {
                    createTaskToAMOCRM();
                }}
            >
                Подключить модуль
            </Button>
            <CustomDialog
                dialogTitle={"Запрос о подключении модуля"}
                buttons={[{ text: "ок", raised: true, callback: () => setIsOpenInfoModal(false) }]}
                isOpen={isOpenInfoModal}
                onClose={() => setIsOpenInfoModal(false)}
            >
                <div className={"module-block-notification-dialog"}>
                    <span>Ваш запрос был передан на обработку менеджеру, который свяжется с Вами в ближайшее время</span>
                </div>
            </CustomDialog>
        </div>
    );
};
