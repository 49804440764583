import { IFilterOrSortingValues, PageTitle, ProgressPage } from "../../components";
import "./WorkersDatabase.styles.sass";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Icon } from "@rmwc/icon";
import { Button } from "@rmwc/button";
import { req } from "../../global/common";
import Async from "react-select/async";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { ITableHeaderSettings, IWorkersData } from "./WorkersDatabase.types";
import { BodyScrollEndEvent, GetRowIdParams, ICellRendererParams, IHeaderParams } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { tableHeaderSettings } from "./WorkersDatabase.service";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Checkbox } from "@rmwc/checkbox";
import { PagesRoutes } from "../../global/routes";
import { reformatToMask } from "../auth-as-worker";
import {
    TCurrentFilterValues
} from "../auth-as-moderator/settings/professions-groups/components/groups-list/groups-list-def";
import { DialogMenuFilter } from "../../components/DialogMenuFilter";
import { UserContext } from "../../global/context.user";
import { InterfaceContext } from "../../global/context.interface";
import { declOfNum } from "../../global/utils";
import { getStepMessage } from "../public-worker-profile/PublicWorkerProfilePage.service";
import { ChatContext } from "../../global/context.chat";
import { TariffCards } from "../../components/TariffCards/TariffCards";
import { CurrentTariff } from "components/CurrentTariff";
import { PageHeader } from "components/PageHeader/PageHeader";
import { IVacancyToInvite } from "components/InviteWorkerForm/InviteWorkerForm.def";

export const WorkersDatabase = () => {
    const [selectedVacancy, setSelectedVacancy] = useState<{ position: string, id: number, organisationName: string } | null>(null);
    const [rowData, setRowData] = useState<IWorkersData[] | null>(null);
    const { t } = useTranslation("common");
    const history = useHistory();
    const gridRef = useRef<AgGridReact>(null);
    const [headCells, setHeadCells] = useState<ITableHeaderSettings[]>(tableHeaderSettings);
    const [currentFilter, setCurrentFilter] = useState<TCurrentFilterValues>([]);
    const [checkedIds, setCheckedIds] = useState<number[]>([]);
    const [filterOrSortValues, setFilterOrSortValues] = useState<IFilterOrSortingValues>({
        filter: {},
        sort: {}
    });
    const [localParams, setLocalParams] = useState({ offset: 0, limit: 20 });
    const [fetch, setFetch] = useState(false);
    const [isFirstStart, setIsFirstStart] = useState(false);
    const [total, setTotal] = useState(0);
    const userCTX = useContext(UserContext);
    const [openProfessionInfoIds, setOpenProfessionInfoIds] = useState<number[]>([]);
    const [openDialogValue, setOpenDialogValue] = useState("");
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [contactCount, setContactCount] = useState();
    const interfaceCTX = useContext(InterfaceContext);
    const chatCTX = useContext(ChatContext);
    const [isOpenTariff, setIsOpenTariff] = useState(false);

    const CustomOption = ({ innerProps, isDisabled, data }: { innerProps: any, isDisabled: any, data: any }) =>
        !isDisabled ? (
            data.position === "1"
                ? <Link to={{
                    pathname: PagesRoutes.VACANCIES_LIST + PagesRoutes.VACANCY_PAGE + `/create`,
                    state: history.location.state
                }} className={`sidebar-selector-link`}>
                    <Icon icon={"add"} />
                    <div className={`sidebar-selector-link-text`}>
                        Добавить вакансию
                    </div>
                </Link>
                : <div {...innerProps} className={`sidebar-selector-option`}>
                    <div className={"sidebar-selector-option-headline"}>
                        {data.position}
                    </div>
                    <div className={"sidebar-selector-option-text"}>
                        {data.city}
                    </div>
                </div>
        ) : null;

    const isSorted = (columnType: string, sortType: "ASC" | "DESC") => {
        const sortedBy = headCells.find((item) => item.columnType === columnType);
        if (sortedBy) return sortedBy?.sortType === sortType;
        else return false;
    };

    const sortTable = (title: string, columnType: string, sortType: "ASC" | "DESC" | "NONE") => {
        setHeadCells((prevState) => {
            return prevState.map((item) => {
                if (item.columnType === columnType && item.sortType === sortType) {
                    onChangeSortValues(title, columnType, "NONE");
                    return { ...item, sortType: "NONE" };
                } else if (item.columnType === columnType) {
                    onChangeSortValues(title, columnType, sortType);
                    return { ...item, sortType: sortType };
                } else return { ...item, sortType: "NONE" };
            });
        });
    };

    const onChangeSortValues = (title: string, fieldName: string, sortType: "ASC" | "DESC" | "NONE") => {
        const alreadyExists = currentFilter.length > 0 && currentFilter.find(item => item.fieldName === fieldName);
        if (alreadyExists) {
            setCurrentFilter(prevState => {
                return [...prevState].map(item => {
                    if (item.fieldName === fieldName) {
                        return { ...item, sortType: sortType };
                    } else return { ...item, sortType: "NONE" };
                });
            });
        } else {
            setCurrentFilter(prevState => {
                const result: TCurrentFilterValues = [...prevState].map(item => ({ ...item, sortType: "NONE" }));
                return [...result, { title: title, fieldName: fieldName, sortType: sortType, filterValue: null }];
            });
        }
        setFilterOrSortValues(prevState => {
            let newSettings;
            newSettings = {
                ...prevState,
                sort: {
                    [fieldName]: sortType === "NONE" ? null : sortType
                }
            };
            send(newSettings);
            return newSettings;
        });
    };

    const promiseOptions = (input: string) =>
        req
            .get("/organisation/worker/vacancies")
            .then(({ data }) =>
                [...data, {position: "1"}]
            )

    const getCurrentFilterValues = (fieldName: string) => {
        const result = currentFilter.find(item => item.fieldName === fieldName)?.filterValue;
        if (!!result) {
            return result;
        } else return [];
    };

    const onChangeFilterValues = (fieldName: string, title: string, filterValue: string[] | null) => {
        const alreadyExists = currentFilter.length > 0 && currentFilter.find(item => item.fieldName === fieldName);
        if (alreadyExists) {
            setCurrentFilter(prevState => {
                return [...prevState].map(item => {
                    if (item.fieldName === fieldName) {
                        return { ...item, filterValue: filterValue };
                    } else return { ...item };
                });
            });
        } else {
            setCurrentFilter(prevState => {
                const result: TCurrentFilterValues = [...prevState];
                return [...result, { title: title, fieldName: fieldName, sortType: "NONE", filterValue: filterValue }];
            });
        }
        setFilterOrSortValues(prevState => {
            let newSettings;
            newSettings = {
                ...prevState,
                filter: {
                    ...prevState.filter,
                    [fieldName]: filterValue
                }
            };
            send(newSettings);
            return newSettings;
        });
    };

    const pickColor = (status: string) => {
        if (status === "Не работаю, рассматриваю предложения") {//Не работаю",
            return {
                backgroundColor: "rgba(51, 255, 0, 1)"
            };
        }
        if (status === "Трудоустроен, рассматриваю предложения") {//"Работаю",
            return {
                backgroundColor: "rgba(235, 255, 0, 1)"
            };
        }
        if (status === "Трудоустроен, не рассматриваю предложения") {//Не ищу работу"
            return {
                backgroundColor: "rgba(255, 1, 1, 1)"
            };
        }
    };

    const columnDefs = headCells.sort((a: ITableHeaderSettings, b: ITableHeaderSettings) => a.id - b.id).map(item => {
        if (item.columnType === "check") {
            return {
                field: item.columnType,
                flex: item.minWidth,
                headerName: item.title,
                suppressMovable: true,
                headerComponent: (data: IHeaderParams<IWorkersData, any>) => {
                    let checked = checkedIds.length === total;
                    return (
                        <div className={"workers-database-header-cell"}>
                            <div className="workers-database-header-cell-inner"
                                style={{ padding: "0" }}
                            >
                                <Checkbox
                                    checked={checked}
                                    onClick={() => {
                                        if (checked) {
                                            setCheckedIds([]);
                                        } else {
                                            checkAllIds();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    );
                },
                cellRenderer: (p: ICellRendererParams<IWorkersData, any, any>) => {
                    if (!p.data) return <></>;
                    let checked = !!checkedIds.find((item) => p.data?.id === item);
                    return (
                        <div
                            className={"checkbox-cell"}
                        >
                            <Checkbox
                                className={checked ? "horosiy-checkbox" : "off-checkbox"}
                                checked={checked}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    if (checked) {
                                        setCheckedIds(prevState => prevState.filter(item => item !== p.data?.id));
                                    } else {
                                        setCheckedIds(prevState => [...prevState, p.data?.id as number]);
                                    }
                                }}

                            />
                        </div>
                    );
                }
            };
        } else if (item.columnType === "fullName") {
            return {
                field: item.columnType,
                flex: item.minWidth,
                headerName: item.title,
                suppressMovable: true,
                headerComponent: (data: IHeaderParams<IWorkersData, any>) => (
                    <div className={"workers-database-header-cell"}>
                        <div className="workers-database-header-cell-inner" style={{ position: "relative" }}>
                            <div className="name">{item.title}</div>
                            <Icon className={`icons`}
                                icon={"more_horiz"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setOpenDialogValue(prevState => prevState === item.columnType ? "" : item.columnType);
                                }} />
                            <DialogMenuFilter
                                isMenuFilterOpen={openDialogValue === item.columnType}
                                onCloseMenuFilter={() => {
                                    setOpenDialogValue("");
                                }}
                                paginateFilter={true}
                                onChangeFilterValues={onChangeFilterValues}
                                uniqueValues={[]}
                                columnTitle={item.title}
                                isFirst={true}
                                columnType={item.columnType}
                                currentFilter={getCurrentFilterValues(item.columnType)}
                                getApiUrl={`/organisation/worker/base/unique`}
                            />
                        </div>
                    </div>
                ),
                cellRenderer: (p: ICellRendererParams<IWorkersData, any, any>) => {
                    if (!p.data) return <></>;
                    return (
                        <div
                            className={"workers-database-table-cell"}
                            style={{
                                background: cellIsActive(item.columnType, tableHeaderSettings)
                            }}
                        >
                            <Link
                                className={"worker-name"}
                                to={{
                                    pathname: "/workers-list" + PagesRoutes.PUBLIC_PROFILE + `/${p.data.id}${selectedVacancy?.id ? "/" + selectedVacancy?.id : ""}`,
                                    state: history.location.state
                                }}
                            >
                                {p.data.fullName}
                            </Link>
                        </div>
                    );
                }
            };
        } else if (item.columnType === "baseProfession") {
            return {
                field: item.columnType,
                flex: item.minWidth,
                headerName: item.title,
                suppressMovable: true,
                headerComponent: (data: IHeaderParams<IWorkersData, any>) => (
                    <div className={"workers-database-header-cell"}>
                        <div className="workers-database-header-cell-inner">
                            <div className="name">{item.title}</div>
                            <Icon className={`icons`}
                                icon={"more_horiz"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setOpenDialogValue(prevState => prevState === item.columnType ? "" : item.columnType);
                                }} />
                            <DialogMenuFilter
                                isMenuFilterOpen={openDialogValue === item.columnType}
                                onCloseMenuFilter={() => {
                                    setOpenDialogValue("");
                                }}
                                paginateFilter={true}
                                onChangeFilterValues={onChangeFilterValues}
                                uniqueValues={[]}
                                columnTitle={item.title}
                                columnType={item.columnType}
                                currentFilter={getCurrentFilterValues(item.columnType)}
                                getApiUrl={`/organisation/worker/base/unique`}
                            />
                        </div>
                    </div>
                ),
                cellRenderer: (p: ICellRendererParams<IWorkersData, any, any>) => {
                    if (!p.data?.id) return <></>;
                    const id: number = p.data.id;
                    return (
                        <div
                            className={"workers-database-table-cell"}
                            style={{
                                background: cellIsActive(item.columnType, tableHeaderSettings)
                            }}
                        >
                            <div className={"base-profession"}>
                                {p.data.professions.length > 0
                                    ? <div className={"base-profession-layout"}>
                                        {openProfessionInfoIds.includes(id)
                                            ? <>
                                                <div className={"base-profession-item"}>
                                                    <div className={"base-profession-item-text"}>
                                                        {p.data.baseProfession}
                                                    </div>
                                                </div>
                                                {p.data.professions.map(item => (
                                                    <div className={"base-profession-item"}>
                                                        <div className={"base-profession-item-text"}>
                                                            {item}
                                                        </div>
                                                    </div>
                                                ))
                                                }
                                            </>
                                            : <>
                                                <div className={"base-profession-item"}>
                                                    <div className={"base-profession-item-text"}>
                                                        {p.data.baseProfession}
                                                        <br />
                                                        и &nbsp;
                                                        <span
                                                            className={"base-profession-btn"}
                                                            onClick={() => {
                                                                setOpenProfessionInfoIds((prevState) => ([...prevState, id]));
                                                            }}
                                                        >
                                                            еще {p.data.professions.length}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    : 
                                    <div className={"base-profession-item"}>
                                        <div className={"base-profession-item-text"}>
                                            {p.data.baseProfession}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    );
                }
            };
        } else if (item.columnType === "workSearchStatus") {
            return {
                field: item.columnType,
                flex: item.minWidth,
                headerName: item.title,
                suppressMovable: true,
                headerComponent: (data: IHeaderParams<IWorkersData, any>) => (
                    <div className={"workers-database-header-cell"}>
                        <div className="workers-database-header-cell-inner">
                            <div className="expands">
                                <Icon className={`icons expand `}
                                    icon={"arrow_drop_up"}
                                    style={{ color: isSorted(item.columnType, "ASC") ? "red" : "" }}
                                    onClick={(event) => {
                                        sortTable(item.title, item.columnType, "ASC");
                                    }} />
                                <Icon className={`icons expand `}
                                    icon={"arrow_drop_down"}
                                    style={{ color: isSorted(item.columnType, "DESC") ? "red" : "" }}
                                    onClick={(event) => {
                                        sortTable(item.title, item.columnType, "DESC");
                                    }} />
                            </div>
                            <div className="name">{item.title}</div>
                            <Icon className={`icons`}
                                icon={"more_horiz"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setOpenDialogValue(prevState => prevState === item.columnType ? "" : item.columnType);
                                }} />
                            <DialogMenuFilter
                                isMenuFilterOpen={openDialogValue === item.columnType}
                                onCloseMenuFilter={() => {
                                    setOpenDialogValue("");
                                }}
                                isWithSearch={false}
                                onChangeFilterValues={onChangeFilterValues}
                                uniqueValues={[
                                    "Не работаю, рассматриваю предложения",
                                    "Трудоустроен, рассматриваю предложения",
                                    "Трудоустроен, не рассматриваю предложения"
                                ]}
                                columnTitle={item.title}
                                columnType={item.columnType}
                                currentFilter={getCurrentFilterValues(item.columnType)}
                            />
                        </div>
                    </div>
                ),
                cellRenderer: (p: ICellRendererParams<IWorkersData, any, any>) => {
                    if (!p.data) return <></>;
                    return (
                        <div
                            className={"workers-database-table-cell"}
                            style={{
                                background: cellIsActive(item.columnType, tableHeaderSettings)
                            }}
                        >
                            <div className={"work-search-status"}>
                                <div
                                    className={"work-search-status-circle"}
                                    style={pickColor(p.data.workSearchStatus)}
                                />
                                <div
                                    className={"work-search-status-text"}
                                >
                                    {p.data.workSearchStatus}
                                </div>
                            </div>
                        </div>
                    );
                }
            };
        } else if (item.columnType === "hasContact") {
            return {
                field: item.columnType,
                flex: item.minWidth,
                headerName: item.title,
                suppressMovable: true,
                headerComponent: (data: IHeaderParams<IWorkersData, any>) => (
                    <div className={"workers-database-header-cell"}>
                        <div className="workers-database-header-cell-inner">
                            <div className="name">{item.title}</div>
                            <Icon className={`icons`}
                                icon={"more_horiz"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setOpenDialogValue(prevState => prevState === item.columnType ? "" : item.columnType);
                                }} />
                            <DialogMenuFilter
                                isMenuFilterOpen={openDialogValue === item.columnType}
                                onCloseMenuFilter={() => {
                                    setOpenDialogValue("");
                                }}
                                isWithSearch={false}
                                onChangeFilterValues={onChangeFilterValues}
                                uniqueValues={["bought", "not_bought"]}
                                columnTitle={item.title}
                                columnType={item.columnType}
                                currentFilter={getCurrentFilterValues(item.columnType)}
                            />
                        </div>
                    </div>
                ),
                cellRenderer: (p: ICellRendererParams<IWorkersData, any, any>) => {
                    if (!p.data) return <></>;
                    return (
                        <div
                            className={"workers-database-table-cell"}
                            style={{
                                background: cellIsActive(item.columnType, tableHeaderSettings)
                            }}
                        >
                            {p.data.phone || p.data.email
                                ? <div className={"contact-information"}>
                                    <div className={"contact-information-item"}>
                                        <Icon icon={"call"} />
                                        <div className={"contact-information-value"}>
                                            {reformatToMask(p.data.phone as string)}
                                        </div>
                                    </div>
                                    <div className={"contact-information-item"}>
                                        <Icon icon={"mail"} />
                                        <a className={"contact-information-value"}
                                            href={`mailto:${p.data.email}`}
                                            style={{
                                                pointerEvents: `${p.data.email ? "auto" : "none"}`,
                                                textDecoration: `${p.data.email ? "underline" : "none"}`,
                                                color: "#354C62"
                                            }}
                                        >
                                            {p.data.email ? p.data.email : "Не указано"}
                                        </a>
                                    </div>
                                </div>
                                : <div>
                                    <Button
                                        disabled={isLoadingBtn || p.data.workSearchStatus === "Трудоустроен, не рассматриваю предложения"}
                                        outlined
                                        onClick={() => {
                                            showContacts([p.data?.id as number]);
                                        }}
                                    >
                                        Показать контакты
                                    </Button>
                                </div>
                            }
                        </div>
                    );
                }
            };
        } else {
            return {
                field: item.columnType,
                flex: item.minWidth,
                headerName: item.title,
                suppressMovable: true,
                headerComponent: (data: IHeaderParams<IWorkersData, any>) => (
                    <div className={"workers-database-header-cell"}>
                        <div className="workers-database-header-cell-inner">
                            <div className="name">{item.title}</div>
                            <Icon className={`icons`}
                                icon={"more_horiz"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setOpenDialogValue(prevState => prevState === item.columnType ? "" : item.columnType);
                                }} />
                            <DialogMenuFilter
                                isMenuFilterOpen={openDialogValue === item.columnType}
                                onCloseMenuFilter={() => {
                                    setOpenDialogValue("");
                                }}
                                paginateFilter={true}
                                onChangeFilterValues={onChangeFilterValues}
                                uniqueValues={[]}
                                columnTitle={item.title}
                                columnType={item.columnType}
                                currentFilter={getCurrentFilterValues(item.columnType)}
                                getApiUrl={`/organisation/worker/base/unique`}
                            />
                        </div>
                    </div>
                ),
                cellRenderer: (p: ICellRendererParams<IWorkersData, any, any>) => {
                    if (!p.data) return <></>;
                    return (
                        <div
                            className={"workers-database-table-cell"}
                            style={{
                                background: cellIsActive(item.columnType, tableHeaderSettings)
                            }}
                        >
                            <div
                                className={"workers-database-table-cell-inner"}
                            >
                                {//@ts-ignore
                                    p.data[item.columnType] ? p.data[item.columnType] : "Неизвестно"
                                }
                            </div>
                        </div>
                    );
                }
            };
        }
    });

    const cellIsActive = (columnType: string, columnSettings: ITableHeaderSettings[]) => {
        let color = "#FFFFFF";
        const activeElement = columnSettings.find(item => {
            return item.columnType === columnType && (item.sortType !== "NONE" || item.filterType);
        });
        if (!!activeElement) {
            color = "#F2F5F9";
        }
        return color;
    };

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params.data.id;
    }, []);

    const checkAllIds = () => {
        req.post("/organisation/worker/base", filterOrSortValues)
            .then(({ data }) => {
                setCheckedIds(data.data.map((item: any) => item.id));
            });
    };

    const send = (filter?: any) => {
        let url;
        if (filter) {
            url = `/organisation/worker/base?limit=${localParams.limit}&offset=0`;
            setLocalParams((prevState => ({
                ...prevState,
                offset: 0
            })));
        } else {
            url = `/organisation/worker/base?limit=${localParams.limit}&offset=${localParams.offset}`;
        }
        req
            .post(url, filter ? filter : filterOrSortValues)
            .then(({ data }) => {
                setRowData(prevState => {
                    const newData = data.data;
                    if (prevState && !filter) {
                        return [
                            ...prevState, ...newData
                        ];
                    } else {
                        setCheckedIds([]);
                        return newData;
                    }
                });
                // setContactCount(data.contactCount)
                setTotal(data.total);
            })
            .catch(() => {
            })
            .finally(() => {
                setFetch(false);
                setIsFirstStart(true);
            });
    };

    const scrollHandlerFoAG = (e: BodyScrollEndEvent<any, any>) => {
        const bufferRowFromEndCount = 1;
        const rowHeight = e.api.getSizesForCurrentTheme().rowHeight;
        const bufferHeight = bufferRowFromEndCount * rowHeight;
        const gridHeight = e.api.getDisplayedRowCount() * rowHeight;
        const gridEdge = gridHeight - bufferHeight;
        const scrollPosition = e.api.getVerticalPixelRange().bottom;
        const paginationShouldContinue = ((scrollPosition >= gridEdge) && gridEdge > 0) && (localParams.offset <= total) && !fetch && isFirstStart;

        if (paginationShouldContinue) {
            setLocalParams(prevState => ({
                ...prevState,
                offset: prevState.offset += prevState.limit
            }));
            setFetch(true);
            send();
        }
    };

    const renderFilterValue = (filterValue: null | string[], fieldName: string) => {
        let values: string = "";
        if (!!filterValue) {
            if (["workSearchStatus", "hasContact"].includes(fieldName)) {
                values = filterValue.map(item => t("menu_filter." + item)).join(", ");
                return values;
            } else {
                values = filterValue.join(", ");
            }
            return values;
        }
        return values;
    };

    const deleteFilter = (fieldName: string) => {
        setCurrentFilter(prevState => {
            return [...prevState].map(item => {
                if (item.fieldName === fieldName) {
                    return { ...item, filterValue: null };
                } else return { ...item };
            });
        });
        setFilterOrSortValues(prevState => {
            let newSettings;
            newSettings = {
                ...prevState,
                filter: {
                    ...prevState.filter,
                    [fieldName]: null
                }
            };
            send(newSettings);
            return newSettings;
        });
    };

    const inviteSelected = () => {
        setIsLoadingBtn(true);
        req.post("/organisation/worker/invite", {
            vacancyId: selectedVacancy?.id,
            workerIds: checkedIds
        })
            .then(({ data }) => {
                setRowData(prevState => {
                    if (!prevState) return null;
                    return prevState?.map((item) => {
                        let newData = data.data.find((resItem: any) => resItem.id === item.id);
                        if (newData) {
                            return newData;
                        } else {
                            return item;
                        }
                    });
                });
                data.data.forEach((item: any) => {
                    chatCTX.sendMessage(item, getStepMessage("considering", selectedVacancy?.position?.trim(), item?.fullName?.split(' ')[1] || "", userCTX.user?.organisation?.fullname?.trim()));
                });
                setCheckedIds([]);
                // setContactCount(data.contactCount);
                // userCTX.changeContactsCount(data.contactCount);
                interfaceCTX.setRefreshCurrentTariff()
            })
            .catch(e => {
                if (e.response.data === "organisation.not_enough_contacts") {
                    setIsOpenTariff(true);
                } else {
                    interfaceCTX.showMessage({
                        title: "Ошибка",
                        body: t(e.response.data)
                    });
                }
            })
            .finally(() => {
                setIsLoadingBtn(false);

            });
    };

    const showContacts = (ids: number[]) => {
        setIsLoadingBtn(true);
        req.post("/organisation/worker/contacts", {
            contactsIds: ids
        })
            .then(({ data }) => {
                setRowData(prevState => {
                    if (!prevState) return null;
                    return prevState?.map((item) => {
                        let newData = data.data.find((resItem: any) => resItem.id === item.id);
                        if (newData) {
                            return newData;
                        } else {
                            return item;
                        }
                    });
                });
                setCheckedIds([]);
                // setContactCount(data.contactCount);
                // userCTX.changeContactsCount(data.contactCount);
                interfaceCTX.setRefreshCurrentTariff()
            })
            .catch(e => {
                if (e.response.data === "organisation.not_enough_contacts") {
                    setIsOpenTariff(true);
                } else {
                    interfaceCTX.showMessage({
                        title: "Ошибка",
                        body: t(e.response.data)
                    });
                }
            })
            .finally(() => {
                setIsLoadingBtn(false);
            });
    };

    React.useEffect(() => {
        function closeSearch(e: any) {
            setOpenDialogValue("");
            if (document.activeElement?.tagName == "BODY") {
                setOpenDialogValue("");
            }
        }

        document.getElementsByTagName("body")[0].addEventListener("click", closeSearch);
        return () => {
            document.getElementsByTagName("body")[0].removeEventListener("click", closeSearch);
        };
    }, []);

    useEffect(() => {
        send();
    }, []);

    useEffect(() => {
        setTimeout(() => interfaceCTX.setRefreshCurrentTariff())
    }, [])

    return (
        <div className={"workers-database-page"}>
            <PageHeader>
                <PageTitle
                    title={`База рабочих`}
                    breadcrumbs={true}
                />
                <CurrentTariff />
            </PageHeader>
            <TariffCards
                isOpen={isOpenTariff}
                onClose={() => {
                    setIsOpenTariff(false);
                }}
            />
            <ProgressPage
                state={rowData}
                render={() => (
                    <div className={"workers-database-page-wrapper"}>
                        <div className={"workers-database-top"}>
                            <div className={"workers-database-top-total-count"}>
                                Найдено: {total} {declOfNum(total, [" человек", " человека", " человек"])}
                            </div>
                            <div className={"workers-database-top-filters"}>
                                {currentFilter.map((item, index) => {
                                    if (item.filterValue) {
                                        return (
                                            <div className="filters-item">
                                                <div className="filters-item-text">{item.title}:</div>
                                                <div className="type">
                                                    {renderFilterValue(item.filterValue, item.fieldName)}
                                                </div>
                                                <Icon
                                                    className="cancel"
                                                    icon={"close"}
                                                    onClick={() => deleteFilter(item.fieldName)}
                                                />
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                        <div className="workers-database-body">
                            <div className={"workers-database-table-wrapper"}>
                                <AgGridReact
                                    // className={"workers-database-table"}
                                    ref={gridRef}
                                    onBodyScrollEnd={scrollHandlerFoAG}
                                    rowHeight={90}
                                    getRowId={getRowId}
                                    rowData={rowData}
                                    gridOptions={{
                                        defaultColDef: {
                                            autoHeight: true,
                                        }
                                    }}
                                    animateRows
                                    columnDefs={columnDefs}
                                    overlayLoadingTemplate={"<span class=\"ag-overlay-loading-center\">Загружается</span>"}
                                    overlayNoRowsTemplate={"<span style=\"padding: 10px; border: 2px solid #444;\">Нет данных</span>"}
                                />
                            </div>
                            <div className={"workers-database-sidebar"}>
                                <div className={"workers-database-sidebar-layout"}>
                                    <div className={"workers-database-sidebar-total-count sidebar-item"}>
                                        Всего выбрано: {checkedIds.length} чел.
                                    </div>
                                    {/* <div className={"workers-database-sidebar-contacts sidebar-item"}>
                                        <div
                                            className={`contacts ${!!contactCount ? "" : "contacts-error"}`}>
                                            <div className={"contacts-text"}>
                                                Осталось контактов:
                                            </div>
                                            &nbsp;
                                            <div className={"contacts-count"}>
                                                {contactCount}
                                            </div>
                                        </div>
                                        <Icon icon={"error_outline"} />
                                    </div> */}
                                    <div className={"workers-database-sidebar-selector sidebar-item"}>
                                        <div className={""}>
                                            Выберите вакансию из списка
                                        </div>
                                        <Async
                                            className={"sidebar-selector"}
                                            classNamePrefix={"select"}
                                            placeholder={"Выберите из списка"}
                                            noOptionsMessage={() => "Нет опций"}
                                            components={{ Option: CustomOption }}
                                            value={selectedVacancy ? {
                                                value: selectedVacancy.position,
                                                label: selectedVacancy.position
                                            } : null}
                                            loadOptions={promiseOptions}
                                            cacheOptions
                                            defaultOptions
                                            onChange={(evt) => {
                                                if (!evt) return;
                                                //@ts-ignore
                                                if (evt.position === "1") return;
                                                //@ts-ignore
                                                setSelectedVacancy(evt);
                                            }}
                                        />
                                        {!selectedVacancy && !!checkedIds.length &&
                                            <div
                                                className={"sidebar-item-err"}
                                            >
                                                Выберите вакансию на которую хотите пригласить соискателей
                                            </div>
                                        }
                                    </div>
                                    <div className={"workers-database-sidebar-buttons sidebar-item"}>
                                        <Button
                                            raised
                                            disabled={!selectedVacancy || isLoadingBtn || !checkedIds.length}
                                            onClick={() => {
                                                inviteSelected();
                                            }}
                                        >
                                            Пригласить выбранных
                                        </Button>
                                        <Button
                                            disabled={isLoadingBtn || !checkedIds.length}
                                            outlined
                                            onClick={() => {
                                                showContacts(checkedIds);
                                            }}
                                        >
                                            Показать контакты
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )} />
        </div>
    );
};