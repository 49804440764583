import { Icon } from '@rmwc/icon'
import clsx from 'clsx'
import { FC, useState } from 'react'
import { AccordionProps } from './Accordion.types'
import './Accordion.style.sass'

export const Accordion: FC<AccordionProps> = ({ className, headerTitle, children, ...rest }): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div
			className={clsx('accordion', className, {
				'accordion--active': isOpen
			})}
			{...rest}
		>
			<div className='accordion-title-wrapper' onClick={() => setIsOpen(!isOpen)}>
				<div className='accordion-title'>{headerTitle}</div>
				<Icon
					className={clsx('accordion-arrow', {
						'accordion-arrow--active': isOpen
					})}
					icon='expand_more'
				/>
			</div>
			{isOpen && children}
		</div>
	)
}
