import { ContractResponse, contractsApi } from 'global/api'
import { InterfaceContext } from 'global/context.interface'
import { useContext, useEffect, useState } from 'react'

export const useContract = (workerRecordId: number) => {
	const { showMessage } = useContext(InterfaceContext)
	const [contract, setContract] = useState<ContractResponse>({} as ContractResponse)
	const [isLoading, setIsLoading] = useState(false)
	const [isSuccess, setIsSuccess] = useState<boolean | null>(null)

	useEffect(() => {
		setIsLoading(true)
		contractsApi
			.getContractById(workerRecordId)
			.then(response => {
				setContract(response.data)
				setIsSuccess(true)
				setIsLoading(false)
			})
			.catch(e => {
				setIsSuccess(false)
				setIsLoading(false)
				showMessage({
					body: 'Ошибка при загрузке договора'
				})
			})
	}, [])

	return { contract, setContract, isLoading, isSuccess }
}
