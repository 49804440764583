import {IColumnSettings} from "../../../components/ExtendedCustomTable/ExtendedCustomTable.types";

export const organizationSettings: IColumnSettings[] = [
    {
        id: 0,
        sortOrder: 0,
        visibility: true,
        minWidth: '250px',
        columnType: 'fullName',
        sortType: 'ASC',
        filterType: null,
        columnLang: false,
        title: "Наименование организации",
    },
    {
        id: 1,
        sortOrder: 1,
        visibility: true,
        minWidth: '250px',
        columnType: 'inn',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "ИНН организации",
    },
    {
        id: 2,
        sortOrder: 2,
        visibility: true,
        minWidth: '250px',
        columnType: 'countActiveRequests',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Количество активных потребностей",
    },
    {
        id: 3,
        sortOrder: 3,
        visibility: true,
        minWidth: '250px',
        columnType: 'countOpenVacancies',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Количество открытых вакансий",
    },
    {
        id: 4,
        sortOrder: 4,
        visibility: true,
        minWidth: '250px',
        columnType: 'countCurrentContracts',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Количество действующих договоров",
    },
    {
        id: 5,
        sortOrder: 5,
        visibility: true,
        minWidth: '250px',
        columnType: 'countActiveUsers',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Количество активных пользователей",
    },
    {
        id: 6,
        sortOrder: 6,
        visibility: true,
        minWidth: '250px',
        columnType: 'registrationDate',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Дата регистрации организации",
    },
    {
        id: 7,
        sortOrder: 7,
        visibility: true,
        minWidth: '250px',
        columnType: 'workCoins',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Оплаченные потребности",
    },
    {
        id: 8,
        sortOrder: 8,
        visibility: true,
        minWidth: '250px',
        columnType: 'spentWorkCoins',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Суммарное использование размещений",
    },
    {
        id: 9,
        sortOrder: 9,
        visibility: true,
        minWidth: '250px',
        columnType: 'module',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Активные модули",
    },
    {
        id: 10,
        sortOrder: 10,
        visibility: true,
        minWidth: '250px',
        columnType: 'moderationStatus',
        sortType: 'NONE',
        filterType: null,
        columnLang: true,
        title: "Статус модерации",
    },
    {
        id: 11,
        sortOrder: 11,
        visibility: true,
        minWidth: '250px',
        columnType: 'tariffStartDate',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Дата начала действия тарифа",
    },
    {
        id: 12,
        sortOrder: 12,
        visibility: true,
        minWidth: '250px',
        columnType: 'tariffEndDate',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Дата окончания действия тарифа",
        dateUnlimited: true
    },
    {
        id: 13,
        sortOrder: 13,
        visibility: true,
        minWidth: '250px',
        columnType: 'tariffUntilDate',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Осталось дней",
    },
]

export const getOrganizationStatusColor = (status: string) => {
    switch (status) {
        case 'expectation':
            return '#FFA800'
        case 'rejected':
            return '#FF0000'
        case 'accepted':
            return '#00E23F'
        case 're_moderation':
            return '#00E0FF'
        default:
            return '#FF0000'
    }
}
