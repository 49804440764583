import { PageTitle, ProgressPage } from 'components'
import { EmploymentProcessStep, useContract } from 'pages/auth-as-worker'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { ContractEmploymentProcessProps, MobilizationProcessUrlParams } from './contract-employment-process-def'
import './contract-employment-process.sass'

export const ContractEmploymentProcess: FC<ContractEmploymentProcessProps> = (): JSX.Element => {
	const { workerRecordId } = useParams<MobilizationProcessUrlParams>()
	const { contract, isSuccess } = useContract(+workerRecordId)

	return (
		<div className='employment-process-page'>
			<PageTitle title='Процесс мобилизации' breadcrumbs />
			<ProgressPage
				state={isSuccess}
				render={() => (
					<div className='employment-process-page-employment-card'>
						{contract.mobilizationList?.data.map(step => (
							<EmploymentProcessStep
								key={step.type}
								className='employment-process-page-employment-card-item'
								titleClassName='employment-process-page-employment-card-item-title'
								title={step.type}
								checked={step.completed}
								isActive={false}
							/>
						))}
					</div>
				)}
			/>
		</div>
	)
}
