import { Icon } from '@rmwc/icon'
import {SpanButtons, Form} from 'components'
import { InterfaceContext } from 'global/context.interface'
import { checkInputedAge, reformatToMask } from 'pages/auth-as-worker/auth-as-worker-utils'
import React, { FC, MutableRefObject, useCallback, useRef } from 'react'
import { WorkerProfileImage } from '../worker-profile-image/worker-profile-image'
import { PersonalInfoFormProps } from './personal-info-form-def'
import './personal-info-form.sass'

export const PersonalInfoForm: FC<PersonalInfoFormProps> = ({
	avatar,
	lastName,
	firstName,
	middleName,
	contactEmail,
	contactPhone,
	birthDate,
	contactEmailConfirmed,
	onSubmit,
	onCancel
}) => {
	const interfaceCTX = React.useContext(InterfaceContext)
	const formRef = useRef() as MutableRefObject<HTMLFormElement>

	const handleCancel = useCallback(() => {
		onCancel?.()
	}, [onCancel])

	const handleSubmit = useCallback(
		values => {
			const { avatar, ...personalInfo } = values || {}
			onSubmit?.({
				avatar,
				personalInfo
			})
		},
		[onSubmit]
	)

	return (
		<Form
			className='worker-profile-form'
			name='workerProfile'
			onSubmit={handleSubmit}
			viewMode={false}
			useRef={formRef}
			data={[
				[
					{
						values: [
							{
								type: 'other',
								colSpan: -1,
								watchVar: 'avatar',
								defaultValue: avatar,
								value: (_, currentAvatar, { setValue }) => {
									return (
										<div className={'form-header'}>
											<WorkerProfileImage
												id='worker-profile-avatar'
												onImageChange={file => {
													setValue(`avatar.path`, file && URL.createObjectURL(file))
												}}
												onImageDelete={() => {
													setValue(`avatar.path`, undefined)
												}}
												image={currentAvatar}
												withCrop={true}
											/>
											<div className='worker-profile-buttons-save'>
												<div className='button-save'>
													<SpanButtons
														data={[
															{
																label: 'Не сохранять',
																onClick: handleCancel
															}
														]}
													/>
												</div>
												<SpanButtons
													data={[
														{
															label: 'Сохранить изменения',
															onClick: () => {
																formRef.current.dispatchEvent(
																	new Event('submit', {
																		cancelable: true,
																		bubbles: true
																	})
																)
															}
														}
													]}
												/>
											</div>
										</div>
									)
								}
							}
						]
					},
					{
						values: [
							{
								className: '',
								title: 'Фамилия',
								defaultValue: lastName,
								var: 'lastName',
								validate: {
									required: true,
									maxLength: 30,
									pattern: interfaceCTX.fioValidationPattern
								},
								errors: {
									required: 'Укажите Вашу фамилию',
									maxLength: 'Введите не больше 30 символов',
									pattern: `Только кириллица, знаки "-", "_" и пробел`
								}
							},
							{
								title: 'Имя',
								defaultValue: firstName,
								var: 'firstName',
								validate: {
									required: true,
									maxLength: 30,
									pattern: interfaceCTX.fioValidationPattern
								},
								errors: {
									required: 'Укажите Ваше имя',
									maxLength: 'Введите не больше 30 символов',
									pattern: `Только кириллица, знаки "-", "_" и пробел`
								}
							},
							{
								title: 'Отчество',
								defaultValue: middleName,
								var: 'middleName',
								validate: {
									maxLength: 30,
									pattern: interfaceCTX.fioValidationPattern
								},
								errors: {
									maxLength: 'Введите не больше 30 символов',
									pattern: `Только кириллица, знаки "-", "_" и пробел`
								}
							}
						]
					},
					{
						values: [
							{
								type: 'phone',
								title: 'Контактный номер телефона',
								var: 'contactPhone',
								defaultValue: reformatToMask(contactPhone),
								validate: {
									required: true
								},
								errors: {
									required: 'Укажите Ваш контактный телефон',
									pattern: 'Укажите корректный номер телефона'
								}
							},
							{
								title: 'Контактный e-mail',
								link: 'mailto',
								defaultValue: contactEmail,
								var: 'contactEmail',
								validate: {
									required: true,
									maxLength: 60,
									pattern: interfaceCTX.emailValidationPattern
								},
								errors: {
									required: 'Укажите Ваш Email',
									pattern: 'Укажите корректный Email',
									maxLength: 'Введите не больше 60 символов'
								}
							},
							{
								type: 'other',
								watchVar: 'contactEmailConfirmed',
								defaultValue: contactEmailConfirmed,
								value: (_, currentContactEmailConfirmed) =>
									!currentContactEmailConfirmed ? (
										<div className='confirm-email-block'>
											<Icon style={{ color: 'red' }} icon='error_outline' />
											<span className='confirm-email-text'>E-mail не подтвержден</span>
											{/*TODO*/}
											{/*<SpanButtons*/}
											{/*  data={[*/}
											{/*    {*/}
											{/*      label: "Подтвердить",*/}
											{/*      onClick: () => {},*/}
											{/*    },*/}
											{/*  ]}*/}
											{/*/>*/}
										</div>
									) : (
										<div />
									)
							}
						]
					}
				]
			]}
		/>
	)
}
