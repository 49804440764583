import {IColumnSettings} from "../../../components/ExtendedCustomTable/ExtendedCustomTable.types";

export const needsAnalyticsSettings: IColumnSettings[] = [
    {
        id: 0,
        sortOrder: 0,
        visibility: true,
        minWidth: '250px',
        columnType: 'requestNumber',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "№ потребности",
    },
    {
        id: 1,
        sortOrder: 1,
        visibility: true,
        minWidth: '250px',
        columnType: 'profession',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Профессия",
    },
    {
        id: 2,
        sortOrder: 2,
        visibility: true,
        minWidth: '250px',
        columnType: 'professionGroup',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Группа профессиий",
    },
    {
        id: 3,
        sortOrder: 3,
        visibility: true,
        minWidth: '250px',
        columnType: 'city',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Место производства работ",
    },
    {
        id: 4,
        sortOrder: 4,
        visibility: true,
        minWidth: '250px',
        columnType: 'schedule',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "График работ",
    },
    {
        id: 5,
        sortOrder: 5,
        visibility: true,
        minWidth: '250px',
        columnType: 'requestProfessionCount',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Количество размещенных потребностей",
    },
    {
        id: 6,
        sortOrder: 6,
        visibility: true,
        minWidth: '250px',
        columnType: 'quantity',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Количество размещенных вакансий",
    },
]
