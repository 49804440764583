import {Author} from "../../global/definitions/definitions";
import {toTypeOrUndefined} from "../../global/common";
export type {Params, FormNeed, Mode}
export {Need, FormNeedSend}

interface Params {searchID: string}
type Mode = 'view' | 'edit'

interface History {
    date: string,
    author: Author,
    state: string
}

class Need {
    id: number
    state: string
    author: Author
    additionalComments: string
    address: string
    ageMax?: string
    ageMin?: string
    category: string
    city: string
    date: string
    publicationDate: string
    workFrom: string
    workTo: string
    education: string
    gender: string
    grade: string
    hiringReason: string
    history: History[]
    number: string
    organisation: string
    organizer: Author | null
    position: string
    probationDuration?: string
    quantity?: string
    responsibilities: string
    salaryFullGross?: string
    salaryFullNet?: string
    salaryProbationGross?: string
    salaryProbationNet?: string
    schedule: {
        id: number
        name: string
        resetDescription: string
        shortName: string
        workHoursPerMonth: number
    }
    subdivision: string
    probation: boolean
    subordinates: string
    supervisor: Author | null
    responses: {
        [key: string]: number
    }
    settings: {
        required: {
            [key : string]: boolean
        },
        hidden: {
            [key : string]: boolean
        }
    }
    responsibleForThePreparationOfPersonnelDocuments: Author | null
    employmentDecisionMaker: Author | null
    daysBeforeTheEndPublication: number

    constructor(data: any) {
        this.id = data.id
        this.state = data.state
        this.additionalComments = data.additionalComments
        this.address = data.address
        this.ageMax = toTypeOrUndefined(data.ageMax, String)
        this.ageMin = toTypeOrUndefined(data.ageMin, String)
        this.category = data.category
        this.city = data.city
        this.date = data.date
        this.workFrom = data.workFrom
        this.workTo = data.workTo
        this.education = data.education
        this.gender = data.gender
        this.grade = data.grade
        this.hiringReason = data.hiringReason
        this.history = data.history
        this.publicationDate = data.publicationDate
        this.number = data.number
        this.organisation = data.organisation
        this.organizer = data.organizer
        this.position = data.position
        this.probation = !data.probation
        this.probationDuration = toTypeOrUndefined(data.probationDuration, String)
        this.quantity = toTypeOrUndefined(data.quantity, String)
        this.responsibilities = data.responsibilities
        this.salaryFullGross = toTypeOrUndefined(data.salaryFullGross, String)
        this.salaryFullNet = toTypeOrUndefined(data.salaryFullNet, String)
        this.salaryProbationGross = toTypeOrUndefined(data.salaryProbationGross, String)
        this.salaryProbationNet = toTypeOrUndefined(data.salaryProbationNet, String)
        this.schedule = data.schedule
        this.subdivision = data.subdivision
        this.subordinates = data.subordinates
        this.supervisor = data.supervisor
        this.settings = data.settings
        this.responsibleForThePreparationOfPersonnelDocuments = data.responsibleForThePreparationOfPersonnelDocuments
        this.employmentDecisionMaker = data.employmentDecisionMaker
        this.daysBeforeTheEndPublication = data.daysBeforeTheEndPublication

        this.author = data.history.length && (data.history as History[])[0].author
        const responses = Object.assign({}, ...data.responses.map((v: any) => ({[v.type]: v.count})))
        this.responses = {
            total: (Object.values(responses) as number[]).reduce((a, b) => a + b, 0),
            ...responses
        }
    }
}

interface FormNeed {
    additionalComments: string
    address: string
    category: string
    city: {label: string, value: string}
    education: string
    gender: {label: string, value: string} | undefined
    ageMax: string
    ageMin: string
    grade: string
    hiringReason: string
    // interviewers: {label: string, value: number} | undefined
    organizer: {label: string, value: number}
    position: {label: string, value: string}
    probation: boolean
    probationDuration: string
    quantity: string
    responsibilities: string
    salaryFullGross: string
    salaryFullNet: string
    salaryProbationGross: string
    salaryProbationNet: string
    schedule: {label: string, value: string}
    subdivision: string
    subordinates: string
    supervisor: {label: string, value: number}
    responsibleForThePreparationOfPersonnelDocuments: {label: string, value: number}
    employmentDecisionMaker: {label: string, value: number}
    state?: string
    workFrom: string
    workTo: string
}

class FormNeedSend {
    additionalComments?: string
    address?: string
    ageMax?: number
    ageMin?: number
    category?: string
    city?: string
    education?: string
    gender?: string
    grade?: string
    hiringReason?: string
    // interviewersID?: number
    organizerID?: number
    position?: string
    probation: boolean
    probationDuration?: number
    quantity?: number
    responsibilities?: string
    salaryFullGross?: number
    salaryFullNet?: number
    salaryProbationGross?: number
    salaryProbationNet?: number
    scheduleID?: number
    subdivision?: string
    subordinates?: string
    supervisorID?: number
    responsibleForThePreparationOfPersonnelDocuments?: number
    employmentDecisionMaker?: number
    workFrom: string
    workTo: string

    constructor(data: FormNeed) {
        this.additionalComments = data.additionalComments
        this.address = data.address
        this.workFrom = data.workFrom
        this.workTo = data.workTo
        this.ageMax = toTypeOrUndefined(data.ageMax, Number)
        this.ageMin = toTypeOrUndefined(data.ageMin, Number)
        this.category = data.category
        this.city = data.city?.label
        this.education = data.education
        this.gender = data.gender?.label
        this.grade = data.grade
        this.hiringReason = data.hiringReason
        // this.interviewersID = data.interviewers?.value
        this.organizerID = data.organizer?.value
        this.position = data.position?.label
        this.probation = !data.probation
        this.probationDuration = toTypeOrUndefined(data.probationDuration, Number)
        this.quantity = toTypeOrUndefined(data.quantity, Number)
        this.responsibilities = data.responsibilities
        this.salaryFullGross = toTypeOrUndefined(data.salaryFullGross, Number)
        this.salaryFullNet = toTypeOrUndefined(data.salaryFullNet, Number)
        this.salaryProbationGross = toTypeOrUndefined(data.salaryProbationGross, Number)
        this.salaryProbationNet = toTypeOrUndefined(data.salaryProbationNet, Number)
        this.scheduleID = toTypeOrUndefined(data.schedule?.value, Number)
        this.subdivision = data.subdivision
        this.subordinates = data.subordinates
        this.supervisorID = data.supervisor?.value
        this.responsibleForThePreparationOfPersonnelDocuments = data.responsibleForThePreparationOfPersonnelDocuments?.value
        this.employmentDecisionMaker = data.employmentDecisionMaker?.value
    }
}