import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { IArrayOfValues, IDialogFilter } from "./MenuFilter.types";
import "./MenuFilter.style.sass";
import { TextField } from "@rmwc/textfield";
import { Icon } from "@rmwc/icon";
import { newDateValues } from "./MenuFilter.service";
import { Tooltip } from "@rmwc/tooltip";
import { useDebounceEffect } from "custom-hooks";
import { Checkbox, CheckboxProps } from "@rmwc/checkbox";
import { Button } from "@rmwc/button";
import { req } from "global/common";
import { useTranslation } from "react-i18next";
import { ProgressPage } from "components";

export const MenuFilter: React.FC<IDialogFilter> = ({
    isVisibleSettingsOpen,
    columnSettings,
    toggleIsVisibleColumn,
    columnType,
    columnTitle,
    dateUnlimited,
    onChangeFilterValues,
    filterType,
    getUniqueColumnValuesURL,
    customFilterValues,
    columnLang,
    toggleIsVisibleSettings,
    paginateFilter
}) => {
    const [dateValues, setDateValues] = useState<string[] | null>(
        filterType && columnType.toLowerCase().includes("date")
            ? newDateValues(filterType)
            : ["", ""]
    );
    const [textValue, setTextValue] = useState<string>("");
    const { t } = useTranslation("common");
    const [filterValues, setFilterValues] = useState<string[]>(!!filterType ? filterType : []);
    const [isSelectAllFilterValues, setIsSelectAllFilterValues] = useState(false);
    const [arrayOfValues, setArrayOfValues] = useState<IArrayOfValues>(null as unknown as IArrayOfValues);
    const [isLoaded, setIsLoaded] = useState(false);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(20);
    const [fetching, setFetching] = useState(false);
    const scroller = useRef<HTMLInputElement>(null);

    const scrollHandler = (e: React.UIEvent<HTMLElement>) => {
        //@ts-ignore
        if (e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + scroller.current.offsetHeight) < 100) {
            if (total > arrayOfValues.statics.length) {
                setFetching(true);
            }
        }
    };

    const checkIsLast = () => {
        let count: number = 0;
        let order: number = 99;
        for (const column of columnSettings) {
            if (column.visibility) {
                count += 1;
                order = column.sortOrder;
            }
        }
        return { order, isLast: count === 1 };
    };

    const selectAllFilterValues = (isSelected: boolean) => {
        if (isSelected) {
            setFilterValues(arrayOfValues.statics);
            setIsSelectAllFilterValues(true);
        } else if (!isSelected) {
            setFilterValues([]);
            setIsSelectAllFilterValues(false);
        }
    };

    React.useEffect(() => {
        if (columnLang || (!paginateFilter && !columnType.toLowerCase().includes("date")) || columnType === "tariffUntilDate") {
            req
                .get(`${getUniqueColumnValuesURL}?type=${columnType}`)
                .then(({ data }) => {
                    setArrayOfValues((prevState: IArrayOfValues) => ({
                        ...prevState,
                        statics: data.data.filter((item: any) => !!item),
                        mutable: data.data.filter((item: any) => !!item)
                    }));
                    setIsLoaded(true);
                })
                .catch(() => {
                });
        }
    }, []);

    const getPaginateValues = (typeCommand: "fetch" | "textChange") => {
        if (paginateFilter && !columnType.toLowerCase().includes("date") && !columnLang) {
            let newLimit = limit;
            if (typeCommand === "fetch") {
                newLimit = limit + 20;
                setLimit(prevState => prevState + 20);
            }
            req
                .get(`${getUniqueColumnValuesURL}?type=${columnType}&search=${encodeURIComponent(textValue)}&limit=${newLimit}&offset=`)
                .then(({ data }) => {
                    setArrayOfValues((prevState: IArrayOfValues) => ({
                        ...prevState,
                        statics: data.data.filter((item: any) => !!item),
                        mutable: data.data.filter((item: any) => !!item)
                    }));
                    if (isSelectAllFilterValues) {
                        setFilterValues(data.data.filter((item: any) => !!item));
                    }
                    setTotal(data.total);
                    setIsLoaded(true);
                    if (typeCommand === "fetch") {
                        setFetching(false);
                    }

                })
                .catch(() => {
                });
        }
    };

    React.useEffect(() => {
        if (fetching) {
            getPaginateValues("fetch");
        }
    }, [fetching]);

    const searchFilterValues = (value: string) => {
        if (value !== "") {
            setArrayOfValues((prevState: IArrayOfValues) => ({
                ...prevState,
                mutable: prevState.statics.filter(item => !!item && item.toLowerCase().includes(value.toLowerCase()))
            }));
        } else {
            setArrayOfValues((prevState: IArrayOfValues) => ({
                ...prevState,
                mutable: prevState.statics
            }));
        }
    };

    const renderFilterValue = (item: string) => {
        if (customFilterValues[columnType]) {
            return customFilterValues[columnType](item);
        } else if (columnLang) {
            return t("extended_custom_table." + item);
        } else return item;
    };

    useDebounceEffect(() => {
        if (!columnType.toLowerCase().includes("date") && arrayOfValues && !paginateFilter) {
            searchFilterValues(textValue);
        } else if (!columnType.toLowerCase().includes("date") && paginateFilter) {
            getPaginateValues("textChange");
        }
    }, 300, [textValue]);

    if (isVisibleSettingsOpen) {
        if (paginateFilter && !columnLang && !columnType.toLowerCase().includes("date") && !customFilterValues[columnType]) {
            return (
                <div
                    className={`dialog-filter${checkIsLast().isLast ? "-last" : ""}`}
                    onClick={event => {
                        event.stopPropagation();
                    }}
                >
                    <div className="dialog-filter-input">
                        <TextField
                            value={textValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                setTextValue(value);
                            }}
                            outlined
                            type="text"
                            style={{ width: "100%" }}
                            icon="search"
                            maxLength={columnType === "countTime" || columnType === "countShifts" ? 5 : 160}
                        />
                    </div>
                    <div className="dialog-filter-list">
                        <div className="items" ref={scroller} onScroll={scrollHandler}>
                            <div className="item">
                                <Checkbox
                                    className={isSelectAllFilterValues ? "horosiy-checkbox" : "off-checkbox"}
                                    checked={isSelectAllFilterValues}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                        selectAllFilterValues(!isSelectAllFilterValues);
                                    }}
                                />
                                <div className="item-text">Выбрать все</div>
                            </div>
                            <ProgressPage
                                state={arrayOfValues}
                                render={() => (
                                    <>
                                        {arrayOfValues.mutable &&
                                            arrayOfValues.mutable.map(item => {
                                                return (
                                                    <div className="item" key={item}>
                                                        <Checkbox
                                                            key={item}
                                                            className={filterValues.includes(item) ? "horosiy-checkbox" : "off-checkbox"}
                                                            checked={filterValues ? filterValues.includes(item) : false}
                                                            onChange={(e: FormEvent<HTMLInputElement>) => {
                                                                setFilterValues((prevState: string[]) => {
                                                                    let alreadyHas = !!prevState && prevState.find(value => value === item);
                                                                    let newState: string[] = [];
                                                                    if (alreadyHas) {
                                                                        newState = [...prevState.filter(value => value !== item)];
                                                                        setIsSelectAllFilterValues(false);
                                                                    } else {
                                                                        newState = [...prevState, item];
                                                                    }
                                                                    return newState;
                                                                });
                                                            }}
                                                        />
                                                        <div className="item-text">
                                                            {renderFilterValue(item)}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </>
                                )}
                            />
                        </div>
                        <div className="dialog-buttons">
                            <div
                                className="dialog-btn"
                                onClick={() => {
                                    toggleIsVisibleSettings("none");
                                    onChangeFilterValues(columnType, columnTitle, filterValues.length > 0 ? filterValues : null);
                                }}
                            >
                                <Icon className="done" icon={"done"} />
                                <div>ПРИМЕНИТЬ</div>
                            </div>
                            <div
                                className="dialog-btn"
                                onClick={() => {
                                    if (Array.isArray(filterType) && filterType.length > 0) {
                                        setIsSelectAllFilterValues(false);
                                        setFilterValues([]);
                                        onChangeFilterValues(columnType, columnTitle, null);
                                    } else if (filterValues.length > 0) {
                                        setIsSelectAllFilterValues(false);
                                        setFilterValues([]);
                                    }
                                }}
                            >
                                <Icon className="close" icon={"close"} />
                                <div>ОТМЕНИТЬ</div>
                            </div>
                        </div>
                    </div>
                    {!checkIsLast().isLast && (
                        <div
                            className="dialog-button"
                            onClick={() => {
                                toggleIsVisibleColumn(false, columnType);
                            }}
                        >
                            <Icon className="icon visibility_off" icon={"visibility_off"} />
                            <div>СКРЫТЬ СТОЛБЕЦ</div>
                        </div>
                    )}
                </div>
            );
        } else return (
            <div
                className={`dialog-filter${checkIsLast().isLast ? "-last" : ""}`}
                onClick={event => {
                    event.stopPropagation();
                }}
            >
                <div className="dialog-filter-input">
                    {!columnType.toLowerCase().includes("date") && !columnLang && !customFilterValues[columnType] && (
                        <TextField
                            value={textValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                setTextValue(value);
                            }}
                            outlined
                            type="text"
                            style={{ width: "100%" }}
                            icon="search"
                            maxLength={columnType === "countTime" || columnType === "countShifts" ? 5 : 160}
                        />
                    )}
                    {columnType.toLowerCase().includes("date") && columnType !== "tariffUntilDate" &&
                        <div className={"dialog-date" + (dateUnlimited ? " date-unlimited" : "")}>
                            {/* <div className="dialog-date"> */}
                            <div className="dialog-date-fields">
                                {console.log(dateValues)}
                                {dateUnlimited &&
                                    <Checkbox
                                        checked={dateValues?.includes("Бессрочно") || dateValues?.includes("--Бессрочно")}
                                        label="Бессрочно"
                                        onChange={(e: ChangeEvent<any>) => {
                                            if (e.currentTarget.checked) {
                                                setDateValues(prevState => ["", ""])
                                                setDateValues(prevState => ["Бессрочно"])
                                            }
                                            else {
                                                setDateValues(prevState => null)
                                            }
                                        }}
                                    />
                                }
                                <TextField
                                    disabled={dateValues?.includes("Бессрочно") || dateValues?.includes("--Бессрочно")}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e.target.value.length > 10) return;
                                        setDateValues((prevState) => {
                                            const newState = [...prevState || []];
                                            newState[0] = e.target.value;
                                            return newState;
                                        });
                                    }}
                                    value={dateValues ? dateValues[0] : ""}
                                    outlined
                                    placeholder="ДД.ММ.ГГГГ"
                                    type="date"
                                />
                                <TextField
                                    disabled={dateValues?.includes("Бессрочно") || dateValues?.includes("--Бессрочно")}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e.target.value.length > 10) return;
                                        setDateValues((prevState) => {
                                            const newState = [...prevState || []];
                                            newState[1] = e.target.value;
                                            return newState;
                                        });
                                    }}
                                    value={dateValues ? dateValues[1] : ""}
                                    outlined
                                    placeholder="ДД.ММ.ГГГГ"
                                    type="date"
                                />

                            </div>
                            <Tooltip content="Фильтровать">
                                <Icon
                                    className="icon filter_alt"
                                    icon={"filter_alt"}
                                    onClick={() => {
                                        if (dateValues) {
                                            if (dateValues[0].length > 0 && dateValues[1]?.length > 0 && !dateValues?.includes("Бессрочно")) {
                                                const newValues = [];
                                                newValues[0] = [
                                                    dateValues[0].split("-")[2],
                                                    dateValues[0].split("-")[1],
                                                    dateValues[0].split("-")[0]
                                                ].join(".");
                                                newValues[1] = [
                                                    dateValues[1].split("-")[2],
                                                    dateValues[1].split("-")[1],
                                                    dateValues[1].split("-")[0]
                                                ].join(".");
                                                onChangeFilterValues(columnType, columnTitle, newValues);
                                            }
                                        }
                                        if (dateValues?.includes("Бессрочно") || dateValues == null) {
                                            onChangeFilterValues(columnType, columnTitle, dateValues);
                                        }
                                    }}
                                />
                            </Tooltip>
                        </div>
                    }
                </div>
                {(!columnType.toLowerCase().includes("date") || columnType === 'tariffUntilDate') && (
                    <div className="dialog-filter-list">
                        <div className="items">
                            <div className="item">
                                <Checkbox
                                    className={isSelectAllFilterValues ? "horosiy-checkbox" : "off-checkbox"}
                                    checked={isSelectAllFilterValues}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                        selectAllFilterValues(!isSelectAllFilterValues);
                                    }}
                                />
                                <div className="item-text">Выбрать все</div>
                            </div>
                            <ProgressPage
                                state={arrayOfValues}
                                render={() => (
                                    <>
                                        {arrayOfValues.mutable &&
                                            arrayOfValues.mutable.map(item => {
                                                return (
                                                    <div className="item" key={item}>
                                                        <Checkbox
                                                            key={item}
                                                            className={filterValues.includes(item) ? "horosiy-checkbox" : "off-checkbox"}
                                                            checked={filterValues ? filterValues.includes(item) : false}
                                                            onChange={(e: FormEvent<HTMLInputElement>) => {
                                                                setFilterValues((prevState: string[]) => {
                                                                    let alreadyHas = !!prevState && prevState.find(value => value === item);
                                                                    let newState: string[] = [];
                                                                    if (alreadyHas) {
                                                                        newState = [...prevState.filter(value => value !== item)];
                                                                        setIsSelectAllFilterValues(false);
                                                                    } else {
                                                                        newState = [...prevState, item];
                                                                    }
                                                                    return newState;
                                                                });
                                                            }}
                                                        />
                                                        <div className="item-text">
                                                            {renderFilterValue(item)}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </>
                                )}
                            />
                        </div>
                        <div className="dialog-buttons">
                            <div
                                className="dialog-btn"
                                onClick={() => {
                                    toggleIsVisibleSettings("none");
                                    onChangeFilterValues(columnType, columnTitle, filterValues.length > 0 ? filterValues : null);
                                }}
                            >
                                <Icon className="done" icon={"done"} />
                                <div>ПРИМЕНИТЬ</div>
                            </div>
                            <div
                                className="dialog-btn"
                                onClick={() => {
                                    if (Array.isArray(filterType) && filterType.length > 0) {
                                        setIsSelectAllFilterValues(false);
                                        setFilterValues([]);
                                        onChangeFilterValues(columnType, columnTitle, null);
                                    } else if (filterValues.length > 0) {
                                        setIsSelectAllFilterValues(false);
                                        setFilterValues([]);
                                    }
                                }}
                            >
                                <Icon className="close" icon={"close"} />
                                <div>ОТМЕНИТЬ</div>
                            </div>
                        </div>
                    </div>
                )}
                {!checkIsLast().isLast && (
                    <div
                        className="dialog-button"
                        onClick={() => {
                            toggleIsVisibleColumn(false, columnType);
                        }}
                    >
                        <Icon className="icon visibility_off" icon={"visibility_off"} />
                        <div>СКРЫТЬ СТОЛБЕЦ</div>
                    </div>
                )}
            </div>
        );
    } else return null;
};
