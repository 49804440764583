import { Button } from '@rmwc/button'
import { Icon } from '@rmwc/icon'
import { Divider, MessageInfoCard, PageTitle, ProgressPage, StepperCheckbox } from 'components'
import { requestsApi } from 'global/api'
import { InterfaceContext } from 'global/context.interface'
import { UserContext } from 'global/context.user'
import { PagesRoutes } from 'global/routes'
import {
	RequestField,
	RequestInitialsField,
	RequestRangeField,
	useProfileFilling,
	useRequest
} from 'pages/auth-as-worker'
import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useParams } from 'react-router-dom'
import { NeedRequestUrlParams } from './request-page-def'
import { getRequestDateMessage, getRequestStatusMessage, getSheduleInfo } from './request-page-utils'
import './request-page.sass'

export const RequestPage: FC = (): JSX.Element => {
	const { requestId } = useParams<NeedRequestUrlParams>()
	const { showMessage } = useContext(InterfaceContext)
	const { userHasRole } = useContext(UserContext)
	const { t } = useTranslation('common')
	const { state } = useLocation()
	const { request, setRequest, isSuccess } = useRequest(+requestId)
	const { isProfileFilling, isSuccess: isProfileFillingSuccess } = useProfileFilling()

	const clickHandler = () => {
		switch (request.state) {
			case 'open':
			case 'cancelled':
				requestsApi
					.applyToTheRequest(request.id)
					.then(response => {
						setRequest(response.data)
					})
					.catch(e => {
						showMessage({
							title: 'Ошибка',
							body: t('profile.worker.' + e.response.data)
						})
					})
				break
			case 'documents_awaiting':
				requestsApi.submitDocuments(request.id).then(response => {
					setRequest(response.data)
				})
				break
			case 'signature_awaiting':
				requestsApi.requestDocuments(request.id).then(response => {
					setRequest(response.data)
				})
		}
	}

	const sendDocumentsClickHandler = () => {
		requestsApi.sendDocuments(request.id).then(response => {
			setRequest(response.data)
		})
	}

	const cancelRequestClickHandler = () => {
		requestsApi
			.cancelRequest(request.id)
			.then(response => {
				setRequest(response.data)
			})
			.catch(e => {
				showMessage({
					title: 'Ошибка',
					body: t('profile.worker.' + e.response.data)
				})
			})
	}

	return (
		<div className='request-page'>
			<PageTitle title='Потребность' breadcrumbs />
			<ProgressPage
				state={isSuccess}
				render={() => (
					<>
						{isProfileFillingSuccess && (
							<>
								{!isProfileFilling && request.state === 'open' && (
									<MessageInfoCard
										message='Для повышения шанса на трудоустройство заполните свой профиль'
										icon='info_outline'
									/>
								)}
								{isProfileFilling && request.state === 'open' && (
									<MessageInfoCard
										message='Внимательно ознакомьтесь с требованиями в потребности, если она вам подходит нажмите откликнуться'
										icon='info_outline'
									/>
								)}
								{request.state !== 'open' && (
									<MessageInfoCard>
										<>
											<div className='request-page-info-card-message'>
												<StepperCheckbox className='request-page-info-card-icon' isActive />
												<span className='request-page-info-card-text'>{getRequestStatusMessage(request.state)}</span>
											</div>
											<span className='request-page-info-card-responde-time'>
												{`${getRequestDateMessage(request.state)} ${request.respondedAt}`}
											</span>
										</>
									</MessageInfoCard>
								)}
							</>
						)}
						<div className='request-page-progress'>
							<Link
								className='request-page-progress-link'
								to={{ pathname: `${PagesRoutes.WORKER_NEED_LIST}/${request.id}/employment-process`, state }}
							>
								Процесс трудоустройства
							</Link>
							<Icon className='request-page-arrow' icon='arrow_forward_ios' />
						</div>
						<div className='request-page-card'>
							<RequestField className='request-page-field' title='Статус' value='Опубликована' />
							<Divider className='request-page-card-divider' type='horizontally' />
							<RequestField className='request-page-field' title='Номер заявки' value={request.number} />
							<RequestField className='request-page-field' title='Дата заявки' value={request.date} />
							<RequestField
								className='request-page-field'
								title='Наименование организации'
								value={request.organisation}
							/>
							<Divider className='request-page-card-divider' type='horizontally' />
							<div className='request-page-card-table'>
								<div className='request-page-card-table-col'>
									<RequestField className='request-page-field' title='Должность кандидата' value={request.position} />
									{!userHasRole('worker') && (
										<RequestField className='request-page-field' title='Количество вакансий' value={request.quantity} />
									)}
									<RequestField className='request-page-field' title='Разряд' value={request.grade} />
									<RequestField
										className='request-page-field'
										title='Подразделение, в котором открыта вакансия'
										value={request.subdivision}
									/>
									<Link
										className='request-page-field'
										to={`${PagesRoutes.USERS}/${request.responsibleForThePreparationOfPersonnelDocuments?.id}`}
									>
										<RequestInitialsField
											valueClassName='request-page-field-link'
											title='Ответственный за оформление кадровых документов'
											firstName={request.responsibleForThePreparationOfPersonnelDocuments?.firstName}
											middleName={request.responsibleForThePreparationOfPersonnelDocuments?.middleName}
											lastName={request.responsibleForThePreparationOfPersonnelDocuments?.lastName}
										/>
									</Link>
									<Link className='request-page-field' to={`${PagesRoutes.USERS}/${request.organizer?.id}`}>
										<RequestInitialsField
											valueClassName='request-page-field-link'
											title='Ответственный за мобилизацию'
											firstName={request.organizer?.firstName}
											middleName={request.organizer?.middleName}
											lastName={request.organizer?.lastName}
										/>
									</Link>
									<Link className='request-page-field' to={`${PagesRoutes.USERS}/${request.supervisor?.id}`}>
										<RequestInitialsField
											valueClassName='request-page-field-link'
											title='Непосредственный руководитель кандидата'
											firstName={request.supervisor?.firstName}
											middleName={request.supervisor?.middleName}
											lastName={request.supervisor?.lastName}
										/>
									</Link>
									<Link className='request-page-field' to={`${PagesRoutes.USERS}/${request.employmentDecisionMaker?.id}`}>
										<RequestInitialsField
											valueClassName='request-page-field-link'
											title='Принимающий решение о трудоустройстве'
											firstName={request.employmentDecisionMaker?.firstName}
											middleName={request.employmentDecisionMaker?.middleName}
											lastName={request.employmentDecisionMaker?.lastName}
										/>
									</Link>
									<RequestField
										className='request-page-field'
										title='Количество и наименование должностей в подчинении кандидата'
										value={request.grade}
									/>
									<RequestRangeField
										className='request-page-field'
										title='Предполагаемый срок действия договора'
										valueFrom={request.workFrom}
										valueTo={request.workTo}
									/>
									<RequestField
										className='request-page-field'
										title='Функциональные обязанности'
										value={request.responsibilities}
									/>
									<RequestField className='request-page-field' title='Адрес места работы' value={request.address} />
								</div>
								<div className='request-page-card-table-col'>
									<RequestField
										className='request-page-field'
										title='Место проведения работ, город РФ'
										value={request.city}
									/>
									<RequestField
										className='request-page-field'
										title='Причины появления потребности'
										value={request.hiringReason}
									/>
									<RequestField
										className='request-page-field'
										title='График работы'
										value={getSheduleInfo(request.schedule)}
									/>
									<RequestField
										className='request-page-field'
										title='Период испытательного срока, месяцев'
										value={request.probationDuration}
									/>
									<RequestField
										className='request-page-field'
										title='Заработная плата (на руки), руб.'
										value={request.salaryFullNet}
									/>
									<RequestField
										className='request-page-field'
										title='Заработная плата на испытательный срок (на руки), руб.'
										value={request.salaryProbationNet}
									/>
									<RequestRangeField
										className='request-page-field'
										title='Возраст кандидата, лет'
										valueFrom={request.ageMin}
										valueTo={request.ageMax}
									/>
									<RequestField className='request-page-field' title='Пол' value={request.gender} />
									<RequestField className='request-page-field' title='Образование' value={request.education} />
									<RequestField
										className='request-page-field'
										title='Дополнительная информация'
										value={request.additionalComments}
									/>
								</div>
							</div>
						</div>
						<div className='request-page-actions'>
							{request.state !== 'rejected' && request.state !== 'accepted' && (
								<>
									{request.state === 'signature_awaiting' && (
										<>
											<Button className='request-page-button' raised onClick={sendDocumentsClickHandler}>
												Направить согласие
											</Button>
											<Button className='request-page-button' outlined onClick={clickHandler}>
												Документы не получены
											</Button>
										</>
									)}
									{request.state === 'documents_awaiting' && (
										<>
											<Button
												className='request-page-button'
												style={{ maxWidth: '430px' }}
												raised
												onClick={clickHandler}
											>
												«Личные документы» заполнены
											</Button>
											<Link to={PagesRoutes.WORKER_PROFILE} style={{ maxWidth: '420px', width: '100%' }}>
												<Button
													className='request-page-button'
													style={{ maxWidth: 'inherit', width: 'inherit' }}
													outlined
												>
													Перейти в «Личные документы»
												</Button>
											</Link>
										</>
									)}
									{request.state === 'open' || request.state === 'cancelled' ? (
										<Button className='request-page-button' raised onClick={clickHandler}>
											Откликнуться
										</Button>
									) : (
										<Button className='request-page-button' outlined onClick={cancelRequestClickHandler}>
											Отозвать отклик
										</Button>
									)}
								</>
							)}
						</div>
					</>
				)}
			/>
		</div>
	)
}
