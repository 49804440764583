import {attrList} from 'global/common'

import {Icon} from '@rmwc/icon'
import {SpanButtons, InfoBlock} from 'components'
import {Elevation} from '@rmwc/elevation'
import React, {FC} from 'react'
import {PersonalInfoProps} from './personal-info-def'
import {reformatToMask} from 'pages/auth-as-worker/auth-as-worker-utils'

export const PersonalInfo: FC<PersonalInfoProps> = ({
                                                        isDisabled,
                                                        onEdit,
                                                        avatar,
                                                        lastName,
                                                        firstName,
                                                        middleName,
                                                        birthDate,
                                                        contactPhone,
                                                        contactEmail,
                                                        contactEmailConfirmed
                                                    }) => {
    return (
        <Elevation z={3} className={isDisabled ? 'worker-panel opacity' : 'worker-panel'}>
            <div className='inner-container'>
                <div className='worker-info-wrapper'>
                    {!!avatar?.path ? (
                        <div
                            className={'image-input'}
                            {...attrList([
                                'style',
                                {
                                    backgroundImage: `url(${avatar?.path})`
                                }
                            ])}
                        />
                    ) : (
                        <div className='worker-avatar-default'/>
                    )}

                    <div className='worker-contacts-lists'>
                        <>
                            <ul className='worker-contacts-list personal-data'>
                                <li>
                                    <InfoBlock title='Фамилия' content={lastName || ''}/>
                                </li>
                                <li>
                                    <InfoBlock title='Имя' content={firstName || ''}/>
                                </li>
                                {middleName && (
                                    <li>
                                        <InfoBlock title='Отчество' content={middleName || ''}/>
                                    </li>
                                )}
                            </ul>

                            <ul className='worker-contacts-list'>

                                <li className='info-item'>
                                    <InfoBlock
                                        title='Контактный телефон'
                                        content={
                                            <a style={isDisabled ? {cursor: 'default'} : {}}
											   className='telephone'
                                               href={isDisabled ? undefined : `tel:${contactPhone}`}>
                                                {reformatToMask(contactPhone)}
                                            </a>
                                        }
                                    />
                                </li>
                                <li className='info-item email'>
                                    <InfoBlock
                                        title='Контактный e-mail'
                                        content={
                                            <a
                                                style={isDisabled ? {cursor: 'default'} : {}}
                                                className='text email'
                                                href={isDisabled ? undefined : `mailto:${contactEmail}`}>
                                                {contactEmail}
                                            </a>
                                        }
                                    />
                                    <div className='confirm-email-block'>
                                        {contactEmailConfirmed ? (
                                            <Icon style={{color: '#00E23F'}} icon='check_circle_outline'/>
                                        ) : (
                                            <Icon style={{color: 'red'}} icon='error_outline'/>
                                        )}
                                        <span className='confirm-email-text'>
											E-mail {!contactEmailConfirmed && 'не '}
                                            подтвержден
										</span>
                                    </div>
                                </li>
                            </ul>
                        </>

                        <div>
                            <SpanButtons
                                data={[
                                    {
                                        label: 'Редактировать',
                                        onClick: onEdit,
                                        disabled: isDisabled,
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Elevation>
    )
}
