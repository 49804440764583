import { Accordion } from 'components'
import { FC } from 'react'
import { WeekStatsInfoProps } from './week-stats-info-def'
import './week-stats-info.sass'

export const WeekStatsInfo: FC<WeekStatsInfoProps> = ({ headerTitle, stats }): JSX.Element => {
	return (
		<Accordion className='week-stats-info' headerTitle={headerTitle}>
			<div className='week-stats-info-item'>
				<div>Отработанные часы за неделю</div>
				<div>{stats.week.hours}</div>
			</div>
			<div className='week-stats-info-item'>
				<div>Отработанные часы за месяц</div>
				<div>{stats.month.hours}</div>
			</div>
			<div className='week-stats-info-item'>
				<div>Количество смен за неделю</div>
				<div>{stats.week.shifts}</div>
			</div>
			<div className='week-stats-info-item'>
				<div>Количество смен за месяц</div>
				<div>{stats.month.shifts}</div>
			</div>
		</Accordion>
	)
}
