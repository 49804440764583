import React from "react";
import "./ExtendedCustomTableHeader.style.sass";
import { IDragAndDropCells } from "./ExtendedCustomTableHeader.types";
import { Icon } from "@rmwc/icon";
import { IColumnSettings, ISortingAndFilteringArray } from "../../ExtendedCustomTable.types";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { MenuFilter } from "../MenuFilter/MenuFilter";

export const ExtendedCustomTableHeader = ({
                                              newOrderColumns,
                                              columnSettings,
                                              toggleIsVisibleSettings,
                                              isVisibleSettingsOpen,
                                              toggleIsVisibleColumn,
                                              onChangeSortValues,
                                              sortingAndFilteringArray,
                                              onChangeFilterValues,
                                              getUniqueColumnValuesURL,
                                              customFilterValues,
                                              sortingAndFilteringOptions,
                                              numbered,
                                              paginateFilter
                                          }: IDragAndDropCells) => {

    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list, (item) => ({ ...item }));
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        for (let i = 0; i < result.length; i++) {
            result[i].sortOrder = i;
        }
        return result.sort((a, b) => a.sortOrder - b.sortOrder);
    };

    function dropHandler(event: DropResult) {
        let { source, destination } = event;
        if (destination) {
            if (!numbered) {
                newOrderColumns(
                    reorder(columnSettings, source.index, destination.index)
                );
            } else if (source.index !== 0 && destination?.index !== 0) { //Исключает первый столбец с нумерацией
                if (destination) {
                    newOrderColumns(
                        reorder(columnSettings, source.index, destination.index)
                    );
                }
            }
        }
    }

    const getItemStyle = (isDragging: any, draggableStyle: any, currentSortedColumns: ISortingAndFilteringArray[], columnType: string, index: number) => {
        if (numbered && index === 0) {
            return {
                width: "40px",
                background: isDragging ? "#F1F1F1" : isActive(currentSortedColumns, columnType) ? "#F2F5F9" : "#FFFFFF",
                ...draggableStyle
            };
        } else {
            return {
                userSelect: "none",
                background: isDragging ? "#F1F1F1" : isActive(currentSortedColumns, columnType) ? "#F2F5F9" : "#FFFFFF",
                ...draggableStyle
            };
        }
    };

    const isSorted = (currentSortedColumns: ISortingAndFilteringArray[], columnType: string, sortType: string) => {
        const sortedBy = currentSortedColumns.find(item => item.columnType === columnType);
        if (sortedBy) return sortedBy.sortType === sortType;
        else return false;
    };

    const isFiltered = (currentSortedColumns: ISortingAndFilteringArray[], columnType: string) => {
        const filterBy = currentSortedColumns.find(item => item.columnType === columnType);
        if (filterBy) return filterBy.filterType !== null;
        else return false;
    };

    const isActive = (currentSortedColumns: ISortingAndFilteringArray[], columnType: string) => {
        const sortedBy = currentSortedColumns.find(item => item.columnType === columnType);
        if (sortedBy) {
            return sortedBy.sortType !== "NONE" || sortedBy.filterType;
        } else return false;
    };

    const isRenderFilter = (columnType: string) => {
        let columnOption = sortingAndFilteringOptions.find(item => item.columnType === columnType)
        if (columnOption) {
            return columnOption.isFiltering !== false;
        } else return true;
    };

    return (
        <DragDropContext
            onDragEnd={((event, ev) => dropHandler(event))}
        >
            <Droppable droppableId={"extendedCustomTable"} direction="horizontal">
                {(provided) => (
                    <div className="headings heading undefined drag_and_drop_cells"
                         style={{ minWidth: "auto" }}
                         ref={provided.innerRef}
                         {...provided.droppableProps}>

                        {columnSettings.map((cell, index) => {
                            console.log(cell)
                                if (cell.visibility) {
                                    return (
                                        <Draggable draggableId={`${cell.sortOrder}`} index={index} key={cell.sortOrder}>
                                            {(provided, snapshot) => (
                                                <div
                                                    key={cell.sortOrder}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    style={
                                                        {
                                                            minWidth: cell.minWidth,
                                                            ...getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style,
                                                                sortingAndFilteringArray, cell.columnType, index
                                                            )
                                                        }
                                                    }
                                                    className={"devider drag_and_drop"}
                                                >
                                                    <div className="table-cell-inner drag_and_drop-inner"
                                                         style={numbered && index === 0 ? { width: "30px" } : {}}>
                                                        {(numbered && index === 0)
                                                            ? <></>
                                                            : <div className="expands">
                                                                <Icon className={`icon expand `}
                                                                      style={{ color: isSorted(sortingAndFilteringArray, cell.columnType, "ASC") ? "red" : "" }}
                                                                      icon={"arrow_drop_up"}
                                                                      onClick={(event: any) => {
                                                                          event.stopPropagation();
                                                                          onChangeSortValues(cell.columnType, cell.title, "ASC");
                                                                      }} />
                                                                <Icon className={`icon expand `}
                                                                      style={{ color: isSorted(sortingAndFilteringArray, cell.columnType, "DESC") ? "red" : "" }}
                                                                      icon={"arrow_drop_down"}
                                                                      onClick={(event: any) => {
                                                                          event.stopPropagation();
                                                                          onChangeSortValues(cell.columnType, cell.title, "DESC");
                                                                      }} />
                                                            </div>
                                                        }
                                                        <div className="name">{cell.title}</div>
                                                        {(numbered && index === 0)
                                                            ? <></>
                                                            : <div className="icons">
                                                                {isFiltered(sortingAndFilteringArray, cell.columnType)
                                                                    ?
                                                                    <Icon className="icon filter_alt" icon={"filter_alt"} />
                                                                    : <div className="plug" />
                                                                }
                                                                {isRenderFilter(cell.columnType) &&
                                                                    <Icon className="icon more_horiz"
                                                                          icon={"more_horiz"}
                                                                          onClick={(event: any) => {
                                                                              event.stopPropagation();
                                                                              toggleIsVisibleSettings(isVisibleSettingsOpen === cell.columnType ? "none" : cell.columnType);
                                                                          }} />
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        isVisibleSettingsOpen === cell.columnType &&
                                                        <MenuFilter
                                                            toggleIsVisibleSettings={toggleIsVisibleSettings}
                                                            isVisibleSettingsOpen={isVisibleSettingsOpen}
                                                            columnSettings={columnSettings}
                                                            toggleIsVisibleColumn={toggleIsVisibleColumn}
                                                            columnType={cell.columnType}
                                                            columnTitle={cell.title}
                                                            columnLang={cell.columnLang}
                                                            dateUnlimited={cell.dateUnlimited}
                                                            getUniqueColumnValuesURL={getUniqueColumnValuesURL}
                                                            onChangeFilterValues={onChangeFilterValues}
                                                            customFilterValues={customFilterValues}
                                                            filterType={typeof cell.filterType === "string" ? JSON.parse(cell.filterType) : null}
                                                            paginateFilter={paginateFilter}
                                                        />
                                                    }
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                }
                            }
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};