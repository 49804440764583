import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { TableCell } from "../../../components/Table/TableCell/TableCell";
import { INeedsAnalyticsItem } from "./NeedsAnalytics.types";
import { needsAnalyticsSettings } from "./NeedsAnalytics.service";
import "./NeedsAnalytics.style.sass";
import {
    ExtendedCustomTable,
    IColumnSettings,
    IExtendedCustomTableApiURLS,
    ISortingAndFilteringArray
} from "../../../components";

export const NeedsAnalytics = () => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const apiURLS: IExtendedCustomTableApiURLS = {
        getTableValues: "/moderator/requests/list",
        getUniqueColumnValues: "/moderator/requests/unique",
        getTableSettings: "/moderator/requests/table/settings/get",
        saveTableSettings: "/moderator/requests/table/settings/set"
    };

    const renderFilterValue = (
        filterValue: ISortingAndFilteringArray["filterType"],
        columnType: IColumnSettings["columnType"]
    ) => {
        let values: string = "";
        if (!!filterValue) {
            if (columnType === "moderationStatus") {
                values = filterValue.map(item => t("extended_custom_table." + item)).join(", ");
                return values;
            } else if (columnType.toLowerCase().includes("date")) {
                values = filterValue.join(" - ");
            } else {
                values = filterValue.join(", ");
            }
            return values;
        }
        return values;
    };

    const renderRow = (tableValue: any, columnSettings: IColumnSettings[], cellIsActive: (columnType: string, columnSettings: IColumnSettings[]) => string) => {
        return columnSettings.map((column, index) => {
            if (column.columnType === "requestNumber" && column.visibility) {
                return (
                    <TableCell key={column.columnType + index} devider
                               style={{
                                   minWidth: column.minWidth,
                                   background: cellIsActive(column.columnType, columnSettings),
                                   height: "100%"
                               }}>
                        <Link className={"organization-name"}
                              to={{
                                  pathname: "/needs/need/" + tableValue.id,
                                  state: history.location.state
                              }}>{tableValue.requestNumber}</Link>
                    </TableCell>
                );
            } else if (column.columnType && column.visibility) {
                return (
                    <TableCell key={column.columnType + index} devider
                               style={{
                                   minWidth: column.minWidth,
                                   background: cellIsActive(column.columnType, columnSettings),
                                   height: "100%"
                               }}>
                        {tableValue[column.columnType]}
                    </TableCell>
                );
            } else return <></>;
        });
    };

    const reorderData = (columnSettings: IColumnSettings[], thereIs: (value: any) => any, tableValues: any[]) => {
        return tableValues.map((value: INeedsAnalyticsItem, index) => {
            const item: any = {} as INeedsAnalyticsItem;
            columnSettings.forEach((column) => {
                if (column.columnType && column.visibility) {
                    //@ts-ignore
                    return item[column.columnType] = value[column.columnType] ? value[column.columnType] : "";
                }
            });
            return item;
        });
    };

    return (

        <>
            <ExtendedCustomTable
                rootClassName={"needs-analytics-page"}
                renderFilterValue={renderFilterValue}
                renderRow={renderRow}
                apiURLS={apiURLS}
                settings={needsAnalyticsSettings}
                pageTitle={"Потребности"}
                formattedTableValuesForEXEL={reorderData}
                paginateFilter={true}
            />
        </>
    );
};
