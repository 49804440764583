import { ITableRowProps } from "./TableRow.types";
import './TableRow.style.sass'


export function TableRow (props: ITableRowProps) {
    return (
        <div className={`table-row ${props.className}  ${props.type} ${props.isProfileDeleted && 'disabled'}`} style={props.style} onClick={props.onClick} ref={props.ref}>
            {props.children}
        </div>
    )
}