import { ChatContext } from "global/context.chat";
import { InterfaceContext } from "global/context.interface";
import { UserContext } from "global/context.user";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import "./RespondedWorkers.style.sass";
import {
    IFilterOrSortingValues,
    LoadingProgress,
    PageTitle,
    ProgressPage,
    Table, TableCell,
    TableRow
} from "../../components";
import { IRespondedWorkerData, IRespondedWorkers } from "./RespondedWorkers.types";
import { req } from "../../global/common";
import {
    TCurrentFilterValues
} from "../auth-as-moderator/settings/professions-groups/components/groups-list/groups-list-def";
import { Icon } from "@rmwc/icon";
import { CircularProgress } from "@rmwc/circular-progress";
import { useAutoCloseDialog } from "../../custom-hooks";
import { columns } from "./RespondedWorkers.service";
import { MenuFilterSearch } from "../../components/MenuFilterSearch";
import { format } from "date-fns";
import { Button } from "@rmwc/button";
import { Tooltip } from "@rmwc/tooltip";
import { PagesRoutes } from "../../global/routes";

export const RespondedWorkers = () => {
    const history = useHistory();
    const userCTX = useContext(UserContext);
    const chatCTX = useContext(ChatContext);
    const { id } = useParams<{ id: string }>();
    const interfaceCTX = useContext(InterfaceContext);
    const { t } = useTranslation("common");
    const [respondedWorkersData, setRespondedWorkersData] = useState<IRespondedWorkers>({} as IRespondedWorkers);
    const [dataReceived, setDataReceived] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [headCells, setHeadCells] = useState(columns);
    const [currentFilter, setCurrentFilter] = useState<TCurrentFilterValues>([{
        fieldName: "responseDate",
        sortType: "DESC",
        title: "Дата отклика",
        filterValue: null
    }]);
    const [filterOrSortValues, setFilterOrSortValues] = useState<IFilterOrSortingValues>({
        filter: {},
        sort: {
            responseDate: "DESC"
        }
    });
    const [limit, setLimit] = useState(30);
    const [isMenuFilterOpen, setIsMenuFilterOpen] = useState("");

    const isSorted = (fieldName: string, sortType: "ASC" | "DESC") => {
        const sortedBy = headCells.find((item: any) => item.fieldName === fieldName);
        return sortedBy?.sortType === sortType;
    };

    const sortTable = (title: string, fieldName: string, sortType: "ASC" | "DESC" | "NONE") => {
        setHeadCells((prevState: any[]) => {
            return prevState.map((item: any) => {
                if (item.fieldName === fieldName && item.sortType === sortType) {
                    onChangeSortValues(title, fieldName, "NONE");
                    return { ...item, sortType: "NONE" };
                } else if (item.fieldName === fieldName) {
                    onChangeSortValues(title, fieldName, sortType);
                    return { ...item, sortType: sortType };
                } else return { ...item, sortType: "NONE" };
            });
        });
    };

    const onChangeSortValues = (title: string, fieldName: string, sortType: "ASC" | "DESC" | "NONE") => {
        const alreadyExists = currentFilter.length > 0 && currentFilter.find(item => item.fieldName === fieldName);
        if (alreadyExists) {
            setCurrentFilter(prevState => {
                return [...prevState].map(item => {
                    if (item.fieldName === fieldName) {
                        return { ...item, sortType: sortType };
                    } else return { ...item, sortType: "NONE" };
                });
            });
        } else {
            setCurrentFilter(prevState => {
                const result: TCurrentFilterValues = [...prevState].map(item => ({ ...item, sortType: "NONE" }));
                return [...result, { title: title, fieldName: fieldName, sortType: sortType, filterValue: null }];
            });
        }
        setFilterOrSortValues(prevState => ({
            ...prevState,
            sort: {
                [fieldName]: sortType === "NONE" ? null : sortType
            }
        }));
    };

    const onChangeFilterValues = (fieldName: string, title: string, filterValue: string[] | null) => {
        const alreadyExists = currentFilter.length > 0 && currentFilter.find(item => item.fieldName === fieldName);
        if (alreadyExists) {
            setCurrentFilter(prevState => {
                return [...prevState].map(item => {
                    if (item.fieldName === fieldName) {
                        return { ...item, filterValue: filterValue };
                    } else return { ...item };
                });
            });
        } else {
            setCurrentFilter(prevState => {
                const result: TCurrentFilterValues = [...prevState];
                return [...result, { title: title, fieldName: fieldName, sortType: "NONE", filterValue: filterValue }];
            });
        }
        setFilterOrSortValues(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [fieldName]: filterValue
            }
        }));
    };

    const getCurrentFilterValues = (fieldName: string) => {
        const result = currentFilter.find(item => item.fieldName === fieldName)?.filterValue;
        if (!!result) {
            return result;
        } else return [];
    };

    const scroller = useRef<HTMLInputElement>(null);

    const scrollHandler = (e: React.UIEvent<HTMLElement>) => {
        //@ts-ignore
        if (e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + scroller.current.offsetHeight) < 100) {
            if (!isLoading && respondedWorkersData.total > respondedWorkersData.data.length) {
                getRespondedWorkers("paginate");
            }
        }
    };

    const renderState = (state: string) => {
        let color: string = "";
        let text: string = "";
        if (state === "pending") {
            color = "#00A3FF";
        } else if (state === "considering") {   
            color = "#FFA800";
        } else if (state === 'job_offer') {
            color = '#AD00FF';
        } else if (state === "documents_preparation") {
            color = "#EBFF00";
        } else if (state === "accepted") {
            color = "#33FF00";
        } else if (state === "rejected" || state === "rejected_by_worker") {
            color = "#9AA2B0";
        }

        if (state === "pending") {
            text = "Соискатель оставил отклик на вакансию";
        } else if (state === "considering") {
            text = "Соискатель был приглашен на собеседование";
        } else if (state === 'job_offer') {
            text = 'Работа предложена, ожидайте ответа'
        } else if (state === "documents_preparation") {
            text = "Соискатель одобрен на должность";
        } else if (state === "accepted") {
            text = "Соискатель оформлен на должность";
        } else if (state === "rejected") {
            text = "Отказ соискателю";
        } else if (state === "rejected_by_worker") {
            text = "Соискатель отклонил предложение о работе";
        }

        return (
            <div className={"responded-workers-page-state"}>
                <div className={"responded-workers-page-state-wrapper"}>
                    <div
                        className={"responded-workers-page-state-circle"}
                        style={{ backgroundColor: color }}
                    />
                    <div>
                        {t("vacancy." + state)}
                    </div>
                </div>
                <Tooltip
                    align={"bottomRight"}
                    content={
                        <div style={{ width: "350px" }}>
                            {text}
                        </div>
                    }
                    activateOn={["hover"]}
                >
                    <Icon icon={"info_outline"} />
                </Tooltip>
            </div>
        );
    };

    const isDisabledMessageButton = (state: string) => {
        if (["pending", "accepted", "rejected"].includes(state)) {
            return true;
        } else if (["considering", "documents_preparation"].includes(state)) {
            return false;
        }
    };

    useAutoCloseDialog(() => {
        setIsMenuFilterOpen("");
    }, []);

    const getRespondedWorkers = (mode: "paginate" | "default") => {
        setIsLoading(true);
        let newLimit: number
        if(mode === "paginate") {
            newLimit = limit + 30;
        } else {
            newLimit = limit
        }
        req.post(`/vacancy/organisation/response-list?limit=${newLimit}&offset=0&vacancyId=${id}`, filterOrSortValues)
            .then(({ data }) => {
                let rooms = chatCTX.rooms;
                // initialSortingOfResponses(data.data)
                setLimit(newLimit);
                setRespondedWorkersData({
                    ...data,
                    data: data.data.map((item: any) => {
                        let currentRoom = rooms.find(room => room.userId === item.chatLogin);
                        if(currentRoom && currentRoom.count > 0) {
                            return {
                                ...item,
                                isNotification: true
                            }
                        } else return {
                            ...item,
                            isNotification: false
                        }
                    })
                });
                setDataReceived(true);
            })
            .catch(e => {
                setDataReceived(false);
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t(e.response.data)
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getRespondedWorkers("default");
    }, [filterOrSortValues]);

    return (
        <div className={"responded-workers-page"}>
            {isLoading && <LoadingProgress />}
            <PageTitle
                title={"Отклики"}
                breadcrumbs={true}
            />
            <ProgressPage
                state={dataReceived}
                render={() => (
                    <>
                        <div className={"responded-workers-top"}>
                            <div className={"responded-workers-top-item"}>
                                <div className={"responded-workers-top-title"}>
                                    Дата создания
                                </div>
                                <div className={"responded-workers-top-text"}>
                                    {respondedWorkersData.vacancyInfo.vacancyCreatedDate}
                                </div>
                            </div>
                            <div className={"responded-workers-top-item"}>
                                <div className={"responded-workers-top-title"}>
                                    Вакансия на должность
                                </div>
                                <div className={"responded-workers-top-text"}>
                                    {respondedWorkersData.vacancyInfo.position}
                                </div>
                            </div>
                            <div className={"responded-workers-top-item"}>
                                <div className={"responded-workers-top-title"}>
                                    Место производства
                                </div>
                                <div className={"responded-workers-top-text"}>
                                    {respondedWorkersData.vacancyInfo.city}
                                </div>
                            </div>
                        </div>
                        <div className="custom-table-wrapper" ref={scroller} onScroll={scrollHandler}>
                            <Table className="custom-table">
                                <div className="headings heading custom-table-header">
                                    {headCells.map((cell, index) => {
                                        return (
                                            <div key={cell.id} className={"devider custom-table-header-item"}
                                                 style={{
                                                     flex: cell.flex,
                                                     minWidth: cell.minWidth
                                                 }}>
                                                <div className="table-cell-inner custom-table-header-inner">
                                                    {cell.isSorted &&
                                                        <div className="expands">
                                                            <Icon className={`icons expand `}
                                                                  icon={"arrow_drop_up"}
                                                                  style={{ color: isSorted(cell.fieldName, "ASC") ? "red" : "" }}
                                                                  onClick={(event) => {
                                                                      sortTable(cell.title, cell.fieldName, "ASC");
                                                                  }} />
                                                            <Icon className={`icons expand `}
                                                                  icon={"arrow_drop_down"}
                                                                  style={{ color: isSorted(cell.fieldName, "DESC") ? "red" : "" }}
                                                                  onClick={(event) => {
                                                                      sortTable(cell.title, cell.fieldName, "DESC");
                                                                  }} />
                                                        </div>
                                                    }
                                                    <div className="name">{cell.title}</div>
                                                    {cell.isFiltered &&
                                                        <>
                                                            <Icon className={`icons`}
                                                                  icon={"more_horiz"}
                                                                  onClick={(event) => {
                                                                      event.stopPropagation();
                                                                      setIsMenuFilterOpen(prevState => prevState === cell.fieldName ? "" : cell.fieldName);
                                                                  }} />
                                                            {isMenuFilterOpen === cell.fieldName &&
                                                                <MenuFilterSearch
                                                                    isMenuFilterOpen={isMenuFilterOpen}
                                                                    onCloseMenuFilterOpen={() => {
                                                                        setIsMenuFilterOpen("");
                                                                    }}
                                                                    paginateFilter={true}
                                                                    isWithSearch={!cell.langPrefix}
                                                                    langPrefix={cell.langPrefix}
                                                                    onChangeFilterValues={onChangeFilterValues}
                                                                    columnTitle={cell.title}
                                                                    columnType={cell.fieldName}
                                                                    currentFilter={getCurrentFilterValues(cell.fieldName)}
                                                                    getApiUrl={`/vacancy/organisation/unique?vacancyId=${id}&`}
                                                                />
                                                            }
                                                        </>
                                                    }
                                                </div>

                                            </div>
                                        );
                                    })}
                                </div>
                                {respondedWorkersData.data.map((workerData: IRespondedWorkerData, index) => (
                                    <TableRow type="row" key={`${workerData.id} ${index}`}
                                              style={{ minHeight: "65px" }}>
                                        <TableCell
                                            devider
                                            style={{
                                                flex: columns[0].flex,
                                                minWidth: columns[3].minWidth
                                            }}
                                        >
                                            <Link className={"responded-workers-page-link"} to={{
                                                pathname: PagesRoutes.VACANCIES_LIST + PagesRoutes.PUBLIC_PROFILE + `/${workerData.workerId}/${id}/${workerData.id}`,
                                                state: history.location.state,
                                                search: '?reviews=1',
                                            }}>
                                                {workerData.fullname}
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            devider
                                            style={{
                                                flex: columns[1].flex,
                                                minWidth: columns[3].minWidth
                                            }}
                                        >
                                            {format(new Date(workerData.responseDate), "dd.MM.yyyy")}
                                        </TableCell>
                                        <TableCell
                                            devider
                                            style={{
                                                flex: columns[2].flex,
                                                minWidth: columns[3].minWidth
                                            }}
                                        >
                                            {renderState(workerData.state)}
                                        </TableCell>
                                        <TableCell
                                            devider
                                            style={{
                                                flex: columns[3].flex,
                                                minWidth: columns[3].minWidth
                                            }}
                                        >
                                            {isDisabledMessageButton(workerData.state) ?
                                                <Button
                                                    raised={true}
                                                    disabled={true}
                                                >
                                                    перейти в чат
                                                </Button>
                                                :
                                                <Button
                                                    onClick={() => {
                                                        chatCTX.sendMessage({ chatCredentials: workerData.chatLogin });
                                                    }}
                                                    outlined={true}
                                                >
                                                    перейти в чат
                                                    {workerData.isNotification && <div className={"responded-workers-page-btn"}/>}
                                                </Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                                }
                                {respondedWorkersData.data.length === 0
                                    && <div className={"responded-workers-no-data"}>Откликов нет</div>
                                }
                            </Table>
                        </div>
                    </>
                )}>
            </ProgressPage>
        </div>
    );
};
