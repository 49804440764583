import "./professional-experience.sass";
import { Props } from "./professional-experience-def";
import { Documents, DocumentsUpdated } from "../../components/documents-component/documents-component";
import { InfoBlock } from "../../../../components/InfoBlock/InfoBlock";
import { Icon } from "@rmwc/icon";
import React, { useContext, useState } from "react";
import { getValue } from "../../dump/dump-utils";
import { EAcceptValues } from "../../dump/dump";
import { UserContext } from "../../../../global/context.user";
import { getFormattedDeclinableDate } from "global/utils";
import { Button } from "@rmwc/button";
import { authorName, req } from "../../../../global/common";
import { useParams } from "react-router";
import { InterfaceContext } from "../../../../global/context.interface";
import { useTranslation } from "react-i18next";
import { ChatContext } from "global/context.chat";
import { getStepMessage } from "pages/public-worker-profile/PublicWorkerProfilePage.service";
import { Preset } from "matrix-js-sdk";

export function ProfessionalExperienceSubPage({
                                                  workerProfile,
                                                  isVisibleProfileUpdate,
                                                  versionProfile,
                                                  acceptChangeResponse,
                                                  toggleAcceptValueInUserDumpProfile,
                                                  stage,
                                                  profileRequest,
                                                  refreshStages,
                                                  position
                                              }: Props) {
    const { client, rooms, sendMessage } = useContext(ChatContext);
    const userCTX = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const { userId, responseId } = useParams<{ userId: string; responseId: string }>();
    const interfaceCTX = React.useContext(InterfaceContext);
    const { t } = useTranslation("common");

    const isValueExists = (where: any, what: any) => {
        if (!what) {
            // условие #1
            return true;
        }
        for (let i = 0; i < what.length; i++) {
            for (let j = 0; j < where.length; j++) {
                if (what[i] == where[j]) {
                    // вообще есть indexOf, но непонятно, под какие платформы это пишется, так что по старинке
                    break;
                }
                if (j === where.length - 1) {
                    // мы дошли до конца массива, и так и не нашли вхождение - значит, у нас есть элемент, который не входит в where, и нужно вернуть false
                    return false;
                }
            }
        }
        // ни для одного из элементов не сработал return false, а значит, все они найдены
        return true;
    };

    const isDeleted = (id: any, newData: any) => {
        return !!newData && !!newData.find((item: any) => item.id === id)
            ? newData.find((item: any) => item.id === id)
            : {
                production: null,
                unit: null,
                type: null,
                expYears: null,
                expMonths: null
            };
    };

    const requestProfileCompletion = () => {
        setIsLoading(true);
        req
            .post(`/recruiter/${responseId}/profile-request`)
            .then(() => {
                refreshStages && refreshStages();
                interfaceCTX.showMessage({
                    body: "Запрос на заполнение отправлен",
                    icon: "done"
                });

                sendMessage(workerProfile, getStepMessage("profile-request", position?.trim(), workerProfile.personalInfo.firstName))

            })
            .catch(e => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t(e.response.data)
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="proffessional-exp-subpage-wrapper">
            {workerProfile.mainProfession || workerProfile.subProfessions ? (
                <div className="profession-info-wrapper section">
                    <ul className="profession-info-list">
                        <li className="profession-info-item info-item" style={{ display: "flex" }}>
                            <InfoBlock title="Базовая профессия"
                                       content={getValue(workerProfile.mainProfession?.name)} />
                            {userCTX.user?.currentRole && userCTX.user?.organisation?.confirmed !== "rejected" &&
                                ["admin", "recruiter"].includes(userCTX.user?.currentRole) &&
                                stage &&
                                ["pending", "considering", "documents_preparation", "rejected", "rejected_by_worker"].includes(stage) && (
                                    <Button
                                        className={"profession-info-item-btn"}
                                        raised={true}
                                        disabled={isLoading || profileRequest}
                                        onClick={() => {
                                            requestProfileCompletion();
                                        }}
                                    >
                                        Запросить заполнить опыт
                                    </Button>
                                )}
                        </li>

                        {workerProfile && (
                            <>
                                <li className="profession-info-item info-item">
                                    <InfoBlock title="Разряд" content={getValue(workerProfile.grade)} />
                                    {isVisibleProfileUpdate !== undefined &&
                                        !isVisibleProfileUpdate &&
                                        acceptChangeResponse?.grade === EAcceptValues.AWAIT_ACCEPT && (
                                            <Icon className="edit_note" icon={"edit_note"} />
                                        )}
                                    {isVisibleProfileUpdate &&
                                        versionProfile &&
                                        acceptChangeResponse?.grade === EAcceptValues.AWAIT_ACCEPT && (
                                            <>
                                                <Icon className="keyboard_double_arrow_right"
                                                      icon={"keyboard_double_arrow_right"} />
                                                <div style={{ minWidth: "70px" }}>
                                                    <InfoBlock
                                                        title="Разряд"
                                                        content={
                                                            <>
                                                                <div
                                                                    className="info-item-version">{versionProfile.grade}</div>
                                                                {userCTX.user?.currentRole !== "organizer" && (
                                                                    <div className="version-buttons">
                                                                        <Icon
                                                                            className="done"
                                                                            icon={"done"}
                                                                            onClick={() => {
                                                                                toggleAcceptValueInUserDumpProfile &&
                                                                                toggleAcceptValueInUserDumpProfile({ grade: EAcceptValues.ACCEPTED });
                                                                            }}
                                                                        />
                                                                        <Icon
                                                                            className="close"
                                                                            icon={"close"}
                                                                            onClick={() => {
                                                                                toggleAcceptValueInUserDumpProfile &&
                                                                                toggleAcceptValueInUserDumpProfile({ grade: EAcceptValues.REFUSED });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </>
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )}
                                </li>
                            </>
                        )}

                        {workerProfile.subProfessions && (
                            <li className="profession-info-item">
                                {isVisibleProfileUpdate !== undefined &&
                                    !isVisibleProfileUpdate &&
                                    acceptChangeResponse?.subProfession === EAcceptValues.AWAIT_ACCEPT && (
                                        <Icon
                                            className="edit_note"
                                            icon={"edit_note"}
                                            style={{ position: "absolute", left: "228px", margin: "0 0 5px 10px" }}
                                        />
                                    )}
                                <InfoBlock
                                    title="Дополнительная профессия"
                                    content={
                                        <ul className="additional-professions-list">
                                            {workerProfile.subProfessions.map((prof: any, index: number) => (
                                                <li key={prof.code + "_" + index} className="additional-profession">
                                                    {String(prof.name)}
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                />
                            </li>
                        )}
                        {isVisibleProfileUpdate &&
                            versionProfile &&
                            !!versionProfile &&
                            acceptChangeResponse?.subProfession === EAcceptValues.AWAIT_ACCEPT && (
                                <li className="profession-info-item">
                                    <InfoBlock
                                        title="Изменения дополнительной профессии"
                                        content={
                                            <>
                                                <ul className="additional-professions-list">
                                                    {versionProfile.subProfessions ? (
                                                        versionProfile.subProfessions.map((prof: any, index: number) => (
                                                            <li key={prof.code + "_" + index}
                                                                className="additional-profession">
                                                                {String(prof.name)}
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <div>Информация удалена</div>
                                                    )}
                                                </ul>
                                                {userCTX.user?.currentRole !== "organizer" && (
                                                    <li style={{ margin: "auto 0 0 0" }}>
                                                        <div className="version">
                                                            <Icon
                                                                className="done"
                                                                icon={"done"}
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    toggleAcceptValueInUserDumpProfile &&
                                                                    toggleAcceptValueInUserDumpProfile({ subProfession: EAcceptValues.ACCEPTED });
                                                                }}
                                                            />
                                                            <Icon
                                                                className="close"
                                                                icon={"close"}
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    toggleAcceptValueInUserDumpProfile &&
                                                                    toggleAcceptValueInUserDumpProfile({ subProfession: EAcceptValues.REFUSED });
                                                                }}
                                                            />
                                                        </div>
                                                    </li>
                                                )}
                                            </>
                                        }
                                    />
                                </li>
                            )}
                    </ul>
                </div>
            ) : (
                ""
            )}

            {(workerProfile.qualificationDocuments.length !== 0 ||
                acceptChangeResponse?.qualificationDocuments === EAcceptValues.AWAIT_ACCEPT) && (
                <div className="section">
                    {isVisibleProfileUpdate !== undefined &&
                        !isVisibleProfileUpdate &&
                        acceptChangeResponse?.qualificationDocuments === EAcceptValues.AWAIT_ACCEPT && (
                            <Icon className="edit_note" icon={"edit_note"}
                                  style={{ position: "absolute", left: "556px" }} />
                        )}
                    <div className="section-title">Удостоверения, подтверждающие квалификацию</div>
                    <div className="documents-list">
                        {workerProfile.qualificationDocuments.map((doc: any, index: number) => (
                            <Documents
                                key={doc.id + "_" + index}
                                docNumber={doc.number}
                                issueDate={doc.date}
                                pathPreview={doc.pathPreview && doc.pathPreview}
                                path={doc.path && doc.path}
                            />
                        ))}
                    </div>
                </div>
            )}
            {isVisibleProfileUpdate &&
                versionProfile &&
                !!versionProfile &&
                acceptChangeResponse?.qualificationDocuments === EAcceptValues.AWAIT_ACCEPT && (
                    <div className="section">
                        <div className="section-title">Изменения удостоверений подтверждающих квалификацию</div>
                        <div className="documents-list">
                            {versionProfile.qualificationDocuments && versionProfile.qualificationDocuments.length > 0 ? (
                                versionProfile.qualificationDocuments.map((doc: any, index: number) => (
                                    <Documents
                                        key={doc.id + "_" + index}
                                        docNumber={doc.number}
                                        issueDate={doc.date}
                                        pathPreview={doc.pathPreview && doc.pathPreview}
                                        path={doc.path && doc.path}
                                    />
                                ))
                            ) : (
                                <div>Информация удалена</div>
                            )}
                            {userCTX.user?.currentRole !== "organizer" && (
                                <div className="version" style={{ marginTop: "auto" }}>
                                    <Icon
                                        className="done"
                                        icon={"done"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            toggleAcceptValueInUserDumpProfile &&
                                            toggleAcceptValueInUserDumpProfile({ qualificationDocuments: EAcceptValues.ACCEPTED });
                                        }}
                                    />
                                    <Icon
                                        className="close"
                                        icon={"close"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            toggleAcceptValueInUserDumpProfile &&
                                            toggleAcceptValueInUserDumpProfile({ qualificationDocuments: EAcceptValues.REFUSED });
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            {(!!workerProfile.permissions || (versionProfile && !!versionProfile.permissions)) && (
                <div className="section">
                    {!!workerProfile.permissions && (
                        <>
                            {isVisibleProfileUpdate !== undefined &&
                                !isVisibleProfileUpdate &&
                                acceptChangeResponse?.permissions === EAcceptValues.AWAIT_ACCEPT && (
                                    <Icon className="edit_note" icon={"edit_note"}
                                          style={{ position: "absolute", left: "80px" }} />
                                )}
                            <InfoBlock
                                title="Допуск"
                                content={workerProfile.permissions.map((permission: any, index: number) => {
                                    return (
                                        <p key={permission.id + "_" + index} className="allowance-text">
                                            {permission.name}
                                        </p>
                                    );
                                })}
                            />
                        </>
                    )}
                    {isVisibleProfileUpdate &&
                        versionProfile &&
                        acceptChangeResponse?.permissions === EAcceptValues.AWAIT_ACCEPT && (
                            <InfoBlock
                                title="Изменения допуска"
                                style={{ marginTop: "10px" }}
                                content={
                                    <>
                                        {!!versionProfile.permissions && versionProfile.permissions.length > 0 ? (
                                            versionProfile.permissions.map((permission: any, index: number) => {
                                                return (
                                                    <p key={permission.id + "_" + index} className="allowance-text">
                                                        {permission.name}
                                                    </p>
                                                );
                                            })
                                        ) : (
                                            <div>Информация удалена</div>
                                        )}
                                        {userCTX.user?.currentRole !== "organizer" && (
                                            <div className="version">
                                                <Icon
                                                    className="done"
                                                    icon={"done"}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        toggleAcceptValueInUserDumpProfile &&
                                                        toggleAcceptValueInUserDumpProfile({ permissions: EAcceptValues.ACCEPTED });
                                                    }}
                                                />
                                                <Icon
                                                    className="close"
                                                    icon={"close"}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        toggleAcceptValueInUserDumpProfile &&
                                                        toggleAcceptValueInUserDumpProfile({ permissions: EAcceptValues.REFUSED });
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </>
                                }
                            />
                        )}
                </div>
            )}

            {(workerProfile.permissionDocuments.length !== 0 ||
                acceptChangeResponse?.permissionDocuments === EAcceptValues.AWAIT_ACCEPT) && (
                <div className="section">
                    <div className="section-title" style={{ display: "flex", alignItems: "center" }}>
                        Допуски, подтвеждающий документ
                        {isVisibleProfileUpdate !== undefined &&
                            !isVisibleProfileUpdate &&
                            acceptChangeResponse?.permissionDocuments === EAcceptValues.AWAIT_ACCEPT && (
                                <Icon className="edit_note" icon={"edit_note"} />
                            )}
                    </div>
                    <div className="documents-list">
                        {workerProfile.permissionDocuments &&
                            workerProfile.permissionDocuments.map((doc: any, index: number) => (
                                <div className="documents-list-versions">
                                    <Documents
                                        key={doc.id + "_" + index}
                                        docNumber={doc.number}
                                        issueDate={doc.date}
                                        pathPreview={doc.pathPreview && doc.pathPreview}
                                        path={doc.path && doc.path}
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            )}

            {isVisibleProfileUpdate !== undefined &&
                isVisibleProfileUpdate &&
                !!versionProfile &&
                acceptChangeResponse?.permissionDocuments === EAcceptValues.AWAIT_ACCEPT && (
                    <div className="section">
                        <div className="section-title">Изменения в допуски, подтвеждающий документ</div>
                        <div className="documents-list">
                            {versionProfile.permissionDocuments ? (
                                versionProfile.permissionDocuments.map((doc: any, index: number) => (
                                    <Documents
                                        key={doc.id + "_" + index}
                                        docNumber={doc.number}
                                        issueDate={doc.date}
                                        pathPreview={doc.pathPreview && doc.pathPreview}
                                        path={doc.path && doc.path}
                                    />
                                ))
                            ) : (
                                <div>Информация удалена</div>
                            )}
                            {userCTX.user?.currentRole !== "organizer" && (
                                <li style={{ margin: "auto 0 0 0" }}>
                                    <div className="version">
                                        <Icon
                                            className="done"
                                            icon={"done"}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                toggleAcceptValueInUserDumpProfile &&
                                                toggleAcceptValueInUserDumpProfile({ permissionDocuments: EAcceptValues.ACCEPTED });
                                            }}
                                        />
                                        <Icon
                                            className="close"
                                            icon={"close"}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                toggleAcceptValueInUserDumpProfile &&
                                                toggleAcceptValueInUserDumpProfile({ permissionDocuments: EAcceptValues.REFUSED });
                                            }}
                                        />
                                    </div>
                                </li>
                            )}
                        </div>
                    </div>
                )}

            <div className="experience-wrapper section">
                {(workerProfile.experience || workerProfile.withoutExperience) && (
                    <div className="title">
                        Опыт работы
                        {isVisibleProfileUpdate !== undefined &&
                            !isVisibleProfileUpdate &&
                            !!versionProfile &&
                            versionProfile.experience &&
                            acceptChangeResponse?.experience === EAcceptValues.AWAIT_ACCEPT && (
                                <Icon className="edit_note" icon={"edit_note"} />
                            )}
                    </div>
                )}
                {workerProfile.withoutExperience &&
                    ((workerProfile.experience && workerProfile.experience.length === 0) || !workerProfile.experience) && (
                        <span>Без опыта работы</span>
                    )}
                {workerProfile.experience && (
                    <ul className="experience-card-list">
                        {workerProfile.experience.map((exp: any, index: number) => {
                            return (
                                <div key={exp.type + " " + index} style={{ marginBottom: "5px" }}>
                                    <li className="experience">
                                        <div className="experience-inner">
                                            <ul className="experience-list">
                                                <li className="experience-item">
                                                    <InfoBlock title="Наименование производства"
                                                               content={exp.production} />
                                                </li>
                                                <li className="experience-item">
                                                    <InfoBlock title="Наименование агрегата" content={exp.unit} />
                                                </li>
                                                <li className="experience-item">
                                                    <InfoBlock title="Тип работ" content={exp.type} />
                                                </li>
                                                <li className="experience-item">
                                                    <InfoBlock
                                                        title="Опыт работы"
                                                        content={getFormattedDeclinableDate(exp.expYears, exp.expMonths)}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </div>
                            );
                        })}
                    </ul>
                )}
                {versionProfile &&
                    acceptChangeResponse?.experience === EAcceptValues.AWAIT_ACCEPT &&
                    isVisibleProfileUpdate !== undefined &&
                    isVisibleProfileUpdate && (
                        <>
                            <div className="title" style={{ marginTop: "10px" }}>
                                Изменения в опыте работы
                            </div>
                            <ul className="experience-card-list">
                                {versionProfile.experience !== null ? (
                                    versionProfile.experience.map((exp: any, index: number) => {
                                        return (
                                            <div key={exp.type + " " + index} style={{ marginBottom: "5px" }}>
                                                <li className="experience">
                                                    <div className="experience-inner">
                                                        <ul className="experience-list">
                                                            <li className="experience-item">
                                                                <InfoBlock title="Наименование производства"
                                                                           content={exp.production} />
                                                            </li>
                                                            <li className="experience-item">
                                                                <InfoBlock title="Наименование агрегата"
                                                                           content={exp.unit} />
                                                            </li>
                                                            <li className="experience-item">
                                                                <InfoBlock title="Тип работ" content={exp.type} />
                                                            </li>
                                                            <li className="experience-item">
                                                                <InfoBlock
                                                                    title="Опыт работы"
                                                                    content={getFormattedDeclinableDate(exp.expYears, exp.expMonths)}
                                                                />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div>Информация удалена</div>
                                )}
                                <li style={{ margin: "auto 0 0 0" }}>
                                    {userCTX.user?.currentRole !== "organizer" && (
                                        <div className="version">
                                            <Icon
                                                className="done"
                                                icon={"done"}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    toggleAcceptValueInUserDumpProfile &&
                                                    toggleAcceptValueInUserDumpProfile({ experience: EAcceptValues.ACCEPTED });
                                                }}
                                            />
                                            <Icon
                                                className="close"
                                                icon={"close"}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    toggleAcceptValueInUserDumpProfile &&
                                                    toggleAcceptValueInUserDumpProfile({ experience: EAcceptValues.REFUSED });
                                                }}
                                            />
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </>
                    )}
            </div>
        </div>
    );
}
