import "@rmwc/linear-progress/styles";
import "@rmwc/snackbar/styles";
import "@rmwc/switch/styles";
import "@rmwc/tooltip/styles";
import "./global/index.sass";
import "./global/rmwc@modifications.sass";
import "@rmwc/tabs/styles";

import { CircularProgress } from "@rmwc/circular-progress";
import { createSnackbarQueue, SnackbarQueue } from "@rmwc/snackbar";
import { ThemeProvider } from "@rmwc/theme";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter, Redirect, Switch, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Page from "./components/page";
import Topbar from "./components/Topbar/Topbar";
import { req } from "./global/common";
import { Instruction, PreviewImg } from "./components";
import { InterfaceContext, InterfaceContextProvider } from "./global/context.interface";
import { UserContext, UserContextProvider } from "./global/context.user";
import { User } from "./global/definitions/definitions";
import Themes from "./themes.json";
import { localization } from "./translations/localization";
import { contractsApi, profileApi } from "global/api";
import { routes } from "global/routes";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { ChatContext, ChatContextProvider } from "./global/context.chat";
import { UpdateHistoryItem } from "./components/UpdateHistoryItem";
import { OnboardingView } from "./components/OnboardingView";
import { format } from "date-fns";

function App() {
    const { user, setUser, isLSTokenValid, userHasRole } = useContext(UserContext);
    const chatCTX = useContext(ChatContext);
    const { pathname } = useLocation();
    const {
        snackbarMessages,
        topBarRefreshed,
        getScrollEvent,
        instructionValue,
        previewImgURL,
        setIsOnboardingOpen,
        isOnboardingOpen,
        erasePreviewImgUrl
    } = useContext(InterfaceContext);
    const hideInterface =
        pathname === "/signin" ||
        pathname === "/worker/register" ||
        pathname.includes("/organization-signin") ||
        pathname.includes("/signin-moderator");
    const [versionId, setVersionId] = useState<number>();
    const { notify, messages } = createSnackbarQueue();
    const [, setRefresh] = React.useState<number | undefined>();
    const refresh = () => setRefresh(Date.now());
    let scrollTimeout: NodeJS.Timeout;

    React.useEffect(() => {
        notify(snackbarMessages.array[snackbarMessages.array.length - 1]);
    }, [snackbarMessages.array.length]);

    React.useEffect(() => {
        if (!user) return;

        const interval = setInterval(() => {
            req
                .post("/auth/refresh", {
                    token: localStorage.refreshToken,
                    dateTime: format(
                        new Date(),
                        "yyyy-MM-dd'T'HH:mm:ssXXXXX"
                    )
                })
                .then(({ data }) => {
                    localStorage.setItem("tokenDeathDate", String(Date.now() + data.tokenDurationMs));
                    localStorage.setItem("token", data.token);
                    localStorage.setItem("refreshToken", data.refreshToken);
                })
                .catch(() => {
                    clearInterval(interval);
                    localStorage.removeItem("tokenDeathDate");
                    chatCTX.disconnect();
                    setUser(null);
                });
        }, Number(localStorage.tokenDeathDate) - Date.now());

        return () => clearInterval(interval);
    }, [user]);

    const getConstants = () => {
        req.get("/info/constants")
            .then(({ data }) => {
                const currentVersion: {
                    id: number
                    version: string
                } = data.webVersion;
                setVersionId(currentVersion.id);
            });
    };

    const loginWithToken = () => {
        profileApi
            .getProfile()
            .then(({ data }) => {
                if (data.currentRole === "foreman" && data.roles && data.roles.length > 1) {
                    const newRole = data.roles.filter(role => role !== "foreman");
                    profileApi.pickRole(newRole[0]).then(() => {
                        const newUserData = new User(data);
                        if (newUserData.currentRole !== "moderator") {
                            chatCTX.connect();
                            if (!data.trainingCompleted) {
                                setIsOnboardingOpen(true);
                            } else {
                                getConstants();
                            }
                        }
                        setUser({
                            ...newUserData,
                            currentRole: newRole[0],
                            roles: newRole
                        });
                    });
                } else {
                    const newUserData = new User(data);
                    if (newUserData.currentRole !== "moderator") {
                        chatCTX.connect();
                        if (!data.trainingCompleted) {
                            setIsOnboardingOpen(true);
                        } else {
                            getConstants();
                        }
                    }
                    setUser({
                        ...newUserData
                    });
                }
            })
            .catch(() => {
                localStorage.clear();
                sessionStorage.clear();
                refresh();
            });
    };

    useEffect(() => {
        if (user && (user.currentRole?.length === 0 || user?.currentRole === "worker")) {
            contractsApi.getContracts(1, 0, "all").then(response => {
                response.data.data.length !== 0 && localStorage.setItem("isHaveContracts", "true");
            });
        }
    }, [user]);

    useEffect(() => {
        loginWithToken();
    }, [topBarRefreshed]);

    if (user === null && isLSTokenValid()) {
        loginWithToken();
        return <CircularProgress className="circular-progress--center" />;
    }

    if (user === null) {
        if (
            pathname != "/signin" &&
            pathname != "/worker/register" &&
            !pathname.includes("/organization-signin") &&
            !pathname.includes("/signin-moderator") &&
            !pathname.includes("/moderation")
        ) {
            return <Redirect to={"/signin"} />;
        }
    } else {
        if (pathname.includes("/signin-moderator") && user.currentRole === "moderator") {
            return <Redirect to={"/organizations"} />;
        } else if (pathname.includes("/signin-moderator") && !!user && user.currentRole !== "moderator") {
            return <Redirect to={"/vacancies"} />;
        } else if (!userHasRole("worker") && user.roles.length > 0) {
            if (pathname == "/signin" || pathname.includes("/organization-signin")) return <Redirect
                to={"/vacancies"} />;
        } else if (userHasRole("worker")) {
            if (pathname == "/worker/register" || pathname == "/signin" || pathname.includes("/organization-signin"))
                return <Redirect to={"/worker-profile"} />;
        } else if (user.roles.length == 0) {
            if (pathname == "/worker/register" || pathname == "/signin" || pathname.includes("/organization-signin"))
                return <Redirect to={"/worker-profile"} />;
        }
    }

    return (
        <>
            {((!hideInterface && user) || pathname.includes("/moderation")) && <Topbar />}
            <div className="app-container">
                {user &&
                    <UpdateHistoryItem
                        mode={"update"}
                        id={versionId}
                        setSelectedVersion={setVersionId}
                        firstView={true}
                    />
                }
                {isOnboardingOpen && !!user && user.currentRole !== "moderator" && <OnboardingView />}
                {instructionValue.isOpen && !!user && user.currentRole !== "moderator" && <Instruction />}
                <PreviewImg url={previewImgURL} erasePreviewImgUrl={erasePreviewImgUrl} />
                {!hideInterface && user && <Navbar />}
                {/*{((!hideInterface && userCTX.user) || pathname.includes('/moderation') )&& <Navbar />}*/}
                <div
                    className="app-content"
                    onScroll={e => {
                        clearTimeout(scrollTimeout);
                        scrollTimeout = setTimeout(() => getScrollEvent(e), 300);
                    }}
                >
                    <Switch>
                        {[...routes].reverse().filter(item => item.show !== false).map((page, pIndex) => {
                            return (
                                <Page
                                    key={"page-" + pIndex}
                                    title={page.title}
                                    path={page.path}
                                    component={page.component}
                                    availableRoles={page.availableRoles}
                                />
                            );
                        })}
                        <Redirect
                            from="*"
                            to={userHasRole("worker") || user?.roles.length === 0 ? "/worker-profile" : "/profile"}
                        />
                    </Switch>
                </div>
            </div>
            {hideInterface && (
                <div className="footer">
                    Copyright {new Date().getFullYear()}
                    {/* <Link to={'/brigade-render'} className="brigade-render-link" /> */}
                </div>
            )}
            <SnackbarQueue messages={messages} dismissesOnAction />
        </>
    );
}

ReactDOM.render(
    <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
            <ThemeProvider className="app__root" options={Themes["default"]}>
                <I18nextProvider i18n={localization()}>
                    <InterfaceContextProvider>
                        <UserContextProvider>
                            <ChatContextProvider>
                                <App />
                            </ChatContextProvider>
                        </UserContextProvider>
                    </InterfaceContextProvider>
                </I18nextProvider>
            </ThemeProvider>
        </QueryParamProvider>
    </BrowserRouter>,
    document.getElementById("root")
);
