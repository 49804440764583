import { Checkbox } from '@rmwc/checkbox'
import { Divider } from 'components/Divider/Divider'
import { FC, FormEvent, useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import Select, { Props as SelectProps } from 'react-select'
import { WorkerProfileExperienceOption } from '../../worker-profile-def'
import { ErrorWrapper } from '../error-wrapper/error-wrapper'
import { FormFieldLabel } from '../form-field-label/form-field-label'
import { FormField } from '../form-field/form-field'
import { isDuplicatedExperience } from '../professional-experience-form/professional-experience-form-utils'
import { ExperienceFieldProps, Production } from './experience-field-def'

const emptyOptions: SelectProps['options'] = []

export const ExperienceField: FC<ExperienceFieldProps> = ({
	control,
	value,
	onChange,
	productions,
	workTypes,
	withoutExperience,
	isVisible
}) => {
	const { setValue, setError } = useFormContext()
	const [isWithoutExperience, setIsWithoutExperience] = useState<boolean>(!!withoutExperience)

	const productionOptions = useMemo(
		() =>
			(productions || []).map(({ name }) => {
				return {
					label: name,
					value: name
				}
			}),
		[productions]
	)

	const unitOptionsByProductionName = useMemo(() => {
		return (productions || []).reduce<Record<Production['name'], { label: string; value: string }[]>>(
			(result, production) => {
				const { name, units } = production
				result[name] = units?.map(unit => ({
					label: unit,
					value: unit
				}))
				return result
			},
			{}
		)
	}, [productions])

	const handleAddExperience = useCallback(() => {
		if ((value?.length || 0) >= 5) {
			return
		}

		onChange?.([...(value || []), {} as WorkerProfileExperienceOption])
	}, [value, onChange])

	const handleDeleteExperience = useCallback(
		(idx?: number) => {
			onChange?.(value?.filter((_, index) => idx !== index))
		},
		[value, onChange]
	)

	const handleChangeType = useCallback(
		(e, idx, isChecked) => {
			const type = e?.currentTarget?.value
			onChange?.(
				value?.map((option, index) => {
					if (index === idx) {
						if (isChecked) {
							const { [type]: _, ...newOption } = option
							return newOption as WorkerProfileExperienceOption
						} else {
							return {
								...option,
								[type]: {}
							} as WorkerProfileExperienceOption
						}
					}
					return option
				})
			)
		},
		[value, onChange]
	)

	if (!productions) return null

	return (
		<>
			{!!value?.length && <Divider type='horizontally' />}
			<FormField
				value={value}
				title='Опыт работы'
				label='Добавить опыт работы'
				onClick={handleAddExperience}
				renderItem={(experienceItem, index) => {
					const { production, unit, id, ...types } = experienceItem || {}
					const unitCurrent = unitOptionsByProductionName?.[productionOptions[0]?.value]?.find(
						item => item.label === unit
					)
					const productionCurrent = productionOptions.find(item => item.label === production)
					return (
						<div className='experience-form-field-item'>
							<FormFieldLabel title='Наименование производства' value={index} onDelete={handleDeleteExperience} />
							<Controller
								name={`experience.${index}.production`}
								control={control}
								defaultValue={productionCurrent ? productionCurrent : productionOptions[0].value}
								render={({ field: { onChange, value, onBlur, ...field }, fieldState }) => {
									return (
										<ErrorWrapper error={fieldState.error}>
											<Select
												{...field}
												value={value ? { label: value, value } : productionOptions[0]}
												defaultValue={value ? { label: value, value } : null}
												options={[...productionOptions]}
												onChange={value => {
													const productionValue = value?.value
													return (
														setValue(`experience.${index}.production`, productionValue),
														setValue(`experience.${index}.unit`, unitOptionsByProductionName[productionValue][0].value)
													)
												}}
											/>
										</ErrorWrapper>
									)
								}}
							/>
							<Controller
								name={`experience.${index}.unit`}
								control={control}
								defaultValue={
									unitCurrent ? unitCurrent : unitOptionsByProductionName?.[productionOptions[0]?.value]?.[0].value
								}
								render={({ field: { onChange, value, ...field } }) => {
									const options = (production && unitOptionsByProductionName[production]) || emptyOptions
									return (
										<>
											<h4 style={{ margin: '24px 0 14px' }}>Наименование агрегата</h4>
											<Select
												{...field}
												options={options}
												value={
													value
														? {
																label: value,
																value
														  }
														: unitOptionsByProductionName?.[productionOptions[0]?.value]?.[0]
												}
												onChange={(value: any) => setValue(`experience.${index}.unit`, value?.value)}
											/>
										</>
									)
								}}
							/>
							<Controller
								name={`experience.${index}`}
								control={control}
								rules={{
									validate: value => {
										const { production, unit, ...types } = value
										if (!Object.values(types).filter(Boolean).length) {
											return 'Выберите тип работ'
										}
										return true
									}
								}}
								render={({ field, fieldState }) => {
									return (
										<>
											<ErrorWrapper error={fieldState.error}>
												<h4 className='experience-checkbox-title'>Тип работ</h4>
												{workTypes?.map(typeName => {
													const isChecked = Object.keys(types)?.includes(typeName)

													return (
														<div key={typeName}>
															<div>
																<Checkbox
																	{...field}
																	checked={isChecked}
																	className='experience-checkbox'
																	label={typeName}
																	value={typeName}
																	onChange={(e: FormEvent<HTMLInputElement>) => handleChangeType(e, index, isChecked)}
																/>
															</div>
															{isChecked && (
																<ErrorWrapper
																	error={
																		(fieldState.error as any)?.[typeName]?.expYears ||
																		(fieldState.error as any)?.[typeName]?.expMonths
																	}
																>
																	<div className='experience-checkboxes-field'>
																		<Controller
																			name={`experience.${index}.${typeName}.expYears`}
																			control={control}
																			rules={{
																				required: {
																					value: true,
																					message: 'Поле обязательно к заполнению'
																				},
																				min: {
																					value: 0,
																					message: 'Значения менее 0 не принимаются'
																				}
																			}}
																			render={({ field }) => {
																				return (
																					<div className='experience-checkboxes-block'>
																						{/*//@ts-ignore*/}
																						<NumberFormat
																							{...field}
																							maxLength={2}
																							defaultValue={experienceItem?.[typeName]?.expYears}
																							className='experience-checkboxes-block-input'
																						/>
																						<div className='experience-checkbox-after'>лет</div>
																					</div>
																				)
																			}}
																		/>
																		<Controller
																			name={`experience.${index}.${typeName}.expMonths`}
																			control={control}
																			rules={{
																				required: {
																					value: true,
																					message: 'Поле обязательно к заполнению'
																				},
																				max: {
																					value: 11,
																					message: 'Значения больше 11 не принимаются'
																				},
																				min: {
																					value: 0,
																					message: 'Значения менее 0 не принимаются'
																				},

																				validate: s => {
																					if (value && isDuplicatedExperience(value)) {
																						return 'Выбран одинаковый опыт работы'
																					}
																				}
																			}}
																			render={({ field }) => {
																				return (
																					<div className='experience-checkboxes-block'>
																						{/*//@ts-ignore*/}
																						<NumberFormat
																							{...field}
																							maxLength={2}
																							defaultValue={experienceItem?.[typeName]?.expMonths}
																							className='experience-checkboxes-block-input'
																						/>
																						<span className='experience-checkbox-after'>месяцев</span>
																					</div>
																				)
																			}}
																		/>
																	</div>
																</ErrorWrapper>
															)}
														</div>
													)
												})}
											</ErrorWrapper>
											<Divider type='horizontally' />
										</>
									)
								}}
							/>
						</div>
					)
				}}
			/>
			{value?.length === 0 && !isVisible && (
				<Controller
					name='withoutExperience'
					control={control}
					defaultValue={isWithoutExperience}
					render={({ field: { onChange, value, onBlur, ...field }, fieldState }) => {
						return (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Checkbox
									checked={value}
									onChange={() => {
										onChange(() => {
											setValue('withoutExperience', !value)
											return !value
										})
									}}
								/>
								<div>Без опыта работы</div>
							</div>
						)
					}}
				/>
			)}
		</>
	)
}
