import React, {useContext, useEffect, useState} from "react";
import {IUpdateHistoryItem, IUpdateHistoryListItem} from "./UpdateHistory.types";
import {req} from "../../global/common";
import {InterfaceContext} from "../../global/context.interface";
import {useTranslation} from "react-i18next";
import {PageTitle, ProgressPage} from "../../components";
import {useHistory} from "react-router";
import './UpdateHistory.style.sass'
import {Button} from "@rmwc/button";
import {UpdateHistoryItem} from "../../components/UpdateHistoryItem";

export const UpdateHistory = () => {
    const [updateHistoryList, setUpdateHistoryList] = useState<IUpdateHistoryListItem[]>([])
    const [selectedVersion, setSelectedVersion] = useState<number>()
    const [isLoading, setIsLoading] = useState<boolean | null>(null)
    const interfaceCTX = useContext(InterfaceContext)
    const {t} = useTranslation('common')

    const getUpdateHistory = () => {
        req.get('/profile/all-changelog?platformType=web')
            .then(({data}) => {
                setUpdateHistoryList(data.data)
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response?.data)
                })
            })
            .finally(() => {
                setIsLoading(true)
            })
    }

    useEffect(() => {
        getUpdateHistory()
    }, [])

    return (
        <div className={'update-history'}>
            <PageTitle title={'Инструкции'} breadcrumbs={true}/>
            <UpdateHistoryItem
                id={selectedVersion}
                setSelectedVersion={setSelectedVersion}
            />
            <ProgressPage
                state={isLoading}
                render={() => (
                    <>
                        <div className={'update-history-body'}>
                            {updateHistoryList.map((item) =>
                                <div className={'update-history-item'}
                                     key={item.id}
                                >
                                    <div className={'update-history-item-name'}>
                                        {item.title}
                                    </div>
                                    <div className={'update-history-item-body'}>
                                        {item.body}
                                    </div>
                                    <div className={'update-history-item-bottom'}>
                                        <Button
                                            raised={true}
                                            className={'update-history-item-button'}
                                            onClick={event => {
                                                event.preventDefault()
                                                setSelectedVersion(item.id)
                                            }}
                                        >
                                            Подробнее
                                        </Button>
                                    </div>
                                </div>)
                            }
                        </div>
                    </>
                )}/>
        </div>
    )
}