import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { Params, PublicProfile, PublicProfileSend } from "./public-profile-def";
import React, { MutableRefObject } from "react";
import { UserContext } from "../../../../global/context.user";
import { isSuperAdm, req } from "../../../../global/common";
import { InterfaceContext } from "../../../../global/context.interface";
import "./public-profile.sass";
import { useTranslation } from "react-i18next";
import { checkINN, checkKPP, checkOGRN } from "./public-profile-utils";
import { Portal } from "@rmwc/base";
import { CompanyAvatar } from "../companyAvatar/company-avatar";
import { FixedButtons, Raiting, Form, ProgressPage } from "components";
import { Icon } from "@rmwc/icon";


export default function PublicProfilePage() {
    const { searchID, status } = useParams() as Params;
    const { t } = useTranslation("common");
    const userCTX = React.useContext(UserContext);
    const interfaceCTX = React.useContext(InterfaceContext);
    const history = useHistory();
    const { state } = useLocation();

    const form = React.createRef() as MutableRefObject<HTMLFormElement>;
    const [loaded, setLoaded] = React.useState<boolean | null>(null);
    const [profile, setProfile] = React.useState({} as PublicProfile);
    const [viewMode, setViewMode] = React.useState(true);
    const [organizationRate, setOrganizationRate] = React.useState(0);
    const [organizationSpec, setOrganizationSpec] = React.useState(null as any);
    const [orgSpecFiltered, setOrgSpecFiltered] = React.useState(null as any);
    const [chosenOption, setChosenOption] = React.useState(null as any);
    const [chosenMultiOption, setChosenMultiOption] = React.useState([] as any[]);
    const [chosenOptions, setChosenOptions] = React.useState({} as any);

    const isModerationAccepted = profile.moderationPassed === "accepted";

    React.useEffect(() => {
        // if (userCTX.user) {
        req.get(`/info/organisation_types`).then(({ data }) => {
            let options = data.data.map((spec: any) => {
                return {
                    label: spec.name,
                    options: spec.content.map((s: any) => {
                        return {
                            label: s,
                            value: s
                        };
                    })
                };
            });
            setOrganizationSpec(options);
            setOrgSpecFiltered(options);
        });

        req
            .get(`/reviews/organisation/${searchID}/list?limit=0&offset=0`)
            .then(data => data.data.average && setOrganizationRate(data.data.average))
            .catch(() =>
                interfaceCTX.showMessage({
                    icon: "report",
                    body: "Рейтинг организации загрузить не удалось. Обратитесь к администратору"
                })
            );
    }, []);

    React.useEffect(() => {
        let chosenOptionsObj: any = {};
        chosenMultiOption && chosenMultiOption.forEach((opt: any) => (chosenOptionsObj[opt.value] = opt.value));
        if (chosenOption != null) {
            setChosenOptions({
                [chosenOption.value]: chosenOption.value,
                ...chosenOptionsObj
            });
        }
    }, [chosenMultiOption, chosenOption]);

    React.useEffect(() => {
        let values = Object.values(chosenOptions);
        organizationSpec &&
        setOrgSpecFiltered(
            organizationSpec.map((category: any) => {
                return {
                    label: category.label,
                    options: category.options.filter((opt: any) => values.every((v: any) => v != opt.value))
                };
            })
        );
    }, [chosenOptions]);

    React.useEffect(() => {
        setLoaded(null);
        if (searchID === undefined) return;
        let query = {
            showHidden: !viewMode
        };
        req
            .get(`/organisation/${searchID}/get`, query)
            .then(({ data }) => {
                setProfile(new PublicProfile(data));
                setLoaded(true);
                if (!!status && status === "redact" && userCTX.currentRole("admin") && !data.ogrn) {
                    setViewMode(false);
                }
            })
            .catch(() => setLoaded(false));
    }, [searchID, viewMode]);

    const onSubmit = (data: PublicProfile) => {
        // let aboutWithLinks = data.about
        // data.about = getTextWithLinks(data.about);
        req
            .post(`/organisation/${searchID}/update`, new PublicProfileSend(data))
            .then(() => {
                interfaceCTX.showMessage({
                    body: "Изменения сохранены",
                    icon: "done"
                });
                interfaceCTX.refreshTopBar();
                setProfile(data);
                setViewMode(true);
            })
            .catch(e => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response.data)
                });
            });
    };

    const requestModeration = () => {
        req.get("/organisation/request-moderation").then(({ data }) => {
            setProfile(data);
        });
    };

    if (searchID === undefined) return <Redirect to={"/public-profile/" + userCTX!.user!.organisation!.id} />;
    return (
        <ProgressPage
            state={loaded}
            className={"public-profile-page"}
            render={() => (
                <>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>

                    </div>
                    <div className={"form-header"}>
                        <CompanyAvatar image={profile.logoPreview} viewMode={viewMode} />
                        {!isModerationAccepted &&
                            <>
                                <div className="form-header-moderation-info">
                                    <Icon icon={"info_outlined"} />
                                    <span>Спасибо за регистрацию. <br /><br />Ваша компания находится на этапе модерации. Для успешного прохождения этого этапа необходимо заполнить профиль компании. В ближайшее время с вами свяжется менеджер.</span>
                                </div>
                                <div
                                    className="public-profile-page-status">{t("organization_status." + profile.moderationPassed)}</div>
                            </>
                        }
                    </div>
                    <Form
                        name={"publicProfile"}
                        onSubmit={onSubmit}
                        viewMode={viewMode}
                        useRef={form}
                        data={[
                            [
                                {
                                    values: [
                                        {
                                            type: "other",
                                            title: `${String(userCTX.user?.organisation?.id) !== searchID ? "Рейтинг организации" : ""}`,
                                            colSpan: -1,
                                            value: viewMode => {
                                                if (String(userCTX.user?.organisation?.id) !== searchID) {
                                                    return <Raiting value={organizationRate} withNumber />;
                                                } else {
                                                    return <></>;
                                                }
                                            }
                                        },
                                        {
                                            title: "Полное наименование",
                                            colSpan: 2,
                                            var: "fullName",
                                            defaultValue: profile.fullName,
                                            validate: { required: true, maxLength: 300 },
                                            errors: { maxLength: "Введите не более 300 символов" }
                                        },
                                        {
                                            title: "Краткое наименование",
                                            colSpan: 2,
                                            var: "shortName",
                                            defaultValue: profile.shortName,
                                            validate: { required: true, maxLength: 50 },
                                            errors: { maxLength: "Введите не более 50 символов" }
                                        },
                                        {
                                            title: "Юридический адрес",
                                            colSpan: 2,
                                            var: "addressRegistration",
                                            defaultValue: profile.addressRegistration,
                                            validate: { maxLength: 256 },
                                            errors: { maxLength: "Введите не более 256 символов" },
                                            requiredIcon: true
                                        },
                                        {
                                            title: "Фактический адрес",
                                            colSpan: 2,
                                            var: "addressActual",
                                            defaultValue: profile.addressActual,
                                            validate: { maxLength: 256 },
                                            errors: { maxLength: "Введите не более 256 символов" },
                                            requiredIcon: true
                                        }
                                    ]
                                }
                            ],
                            [
                                {
                                    title: "Контактная информация",
                                    values: [
                                        {
                                            type: "phone",
                                            title: "Контактный телефон",
                                            caption: "+ 7 (9XX) XXX XX XX",
                                            var: "phone",
                                            defaultValue: profile.phone,
                                            validate: {
                                                pattern: /\+7\040\(9[\d]{2}\)\040[\d]{3}\040[\d]{2}\040[\d]{2}/gm
                                            },
                                            errors: {
                                                pattern: "Введите номер в формате + 7 (9XX) XXX XX XX"
                                            },
                                            withEye: profile.hidden?.phone,
                                            requiredIcon: true
                                        },
                                        {
                                            title: "Контактный e-mail",
                                            link: "mailto",
                                            var: "email",
                                            defaultValue: profile.email,
                                            validate: {
                                                // required: true,
                                                pattern: interfaceCTX.emailValidationPattern,
                                                maxLength: 256
                                            },
                                            errors: {
                                                pattern: "Введите валидный e-mail",
                                                maxLength: "Введите не более 256 символов"
                                            },
                                            withEye: profile.hidden?.email,
                                            requiredIcon: true
                                        },
                                        {
                                            title: "Сайт",
                                            link: "link",
                                            var: "www",
                                            defaultValue: profile.www,
                                            validate: { maxLength: 256 },
                                            errors: { maxLength: "Введите не более 256 символов" },
                                            withEye: profile.hidden?.www
                                        }
                                    ]
                                },
                                {
                                    title: "Социальные сети",
                                    values: [
                                        {
                                            title: "Twitter",
                                            link: "link",
                                            var: "socialTwitter",
                                            defaultValue: profile.socialTwitter,
                                            validate: { maxLength: 256 },
                                            errors: { maxLength: "Введите не более 256 символов" },
                                            withEye: profile.hidden?.socialTwitter
                                        },
                                        // {
                                        //     title: 'Facebook',
                                        //     link: 'link',
                                        //     var: 'socialFacebook',
                                        //     defaultValue: profile.socialFacebook,
                                        //     validate: { maxLength: 256 },
                                        //     errors: { maxLength: "Введите не более 256 символов" }
                                        // },
                                        {
                                            title: "Telegram",
                                            link: "link",
                                            var: "socialTelegram",
                                            defaultValue: profile.socialTelegram,
                                            validate: { maxLength: 256 },
                                            errors: { maxLength: "Введите не более 256 символов" },
                                            withEye: profile.hidden?.socialTelegram
                                        }
                                    ]
                                }
                            ],
                            [
                                {
                                    values: [
                                        {
                                            type: "textarea",
                                            title: "О компании",
                                            colSpan: 2,
                                            defaultValue: profile.about,
                                            var: "about",
                                            validate: { maxLength: 512 },
                                            errors: { maxLength: "Введите не более 512 символов" },
                                            withEye: profile.hidden?.about
                                        }
                                    ]
                                }
                            ],
                            [
                                {
                                    values: [
                                        {
                                            type: "select",
                                            title: "Основной вид деятельности",
                                            options: orgSpecFiltered,
                                            // toText: (data: Spec) => new Specialization(data),
                                            defaultValue: profile.specialization,
                                            var: "specialization",
                                            // validate: {required: true},
                                            getValue: setChosenOption,
                                            requiredIcon: true
                                        },
                                        {
                                            type: "select",
                                            title: "Дополнительный вид деятельности",
                                            options: orgSpecFiltered,
                                            // toText: (data: Spec) => new Specialization(data),
                                            defaultValue: profile.subSpecializations,
                                            var: "subSpecializations",
                                            isMulti: true,
                                            getValue: setChosenMultiOption
                                        }
                                    ]
                                }
                            ],
                            [
                                {
                                    title: "Реквизиты",
                                    values: [
                                        {
                                            title: "ОГРН / ОГРНИП",
                                            defaultValue: profile.ogrn,
                                            var: "ogrn",
                                            validate: { validate: checkOGRN },
                                            errors: { validate: "Укажите валидный номер ОГРН" },
                                            requiredIcon: true
                                        },
                                        {
                                            title: "ИНН",
                                            defaultValue: profile.inn,
                                            var: "inn",
                                            validate: { validate: checkINN },
                                            errors: { validate: "Укажите валидный номер ИНН" },
                                            requiredIcon: true
                                        },
                                        {
                                            title: "КПП",
                                            defaultValue: profile.kpp,
                                            var: "kpp",
                                            validate: { validate: checkKPP },
                                            errors: { validate: "Укажите валидный номер КПП" }
                                        }
                                    ]
                                }
                            ],
                            [
                                {
                                    title: "Банковские реквизиты",
                                    values: [
                                        {
                                            title: "Р/С",
                                            defaultValue: profile.checkingAccount,
                                            var: "checkingAccount",
                                            validate: { maxLength: 256, pattern: /^\d{0,}$/gm },
                                            errors: {
                                                maxLength: "Введите не более 256 символов",
                                                pattern: "Введите только цифры"
                                            },
                                            requiredIcon: true
                                        },
                                        {
                                            title: "К/С",
                                            defaultValue: profile.correspondentAccount,
                                            var: "correspondentAccount",
                                            validate: { maxLength: 256, pattern: /^\d{0,}$/gm },
                                            errors: {
                                                maxLength: "Введите не более 256 символов",
                                                pattern: "Введите только цифры"
                                            },
                                            requiredIcon: true
                                        },
                                        {
                                            title: "Наименование банка",
                                            defaultValue: profile.bankName,
                                            var: "bankName",
                                            validate: { maxLength: 256 },
                                            errors: { maxLength: "Введите не более 256 символов" },
                                            requiredIcon: true
                                        },
                                        {
                                            title: "БИК",
                                            defaultValue: profile.bankCode,
                                            var: "bankCode",
                                            validate: { maxLength: 256 },
                                            errors: { maxLength: "Введите не более 256 символов" },
                                            requiredIcon: true
                                        }
                                    ]
                                }
                            ]
                        ]}
                    />
                    {!isSuperAdm(userCTX.user) &&
                        <FixedButtons
                            length={3}
                            buttons={[
                                {
                                    label: "Сохранить",
                                    onClick: () =>
                                        form.current.dispatchEvent(
                                            new Event("submit", {
                                                cancelable: true,
                                                bubbles: true
                                            })
                                        ),
                                    primary: true,
                                    hide: viewMode
                                },
                                {
                                    label: "Отменить",
                                    onClick: () => setViewMode(true),
                                    primary: true,
                                    hide: viewMode
                                },
                                {
                                    label: "Редактировать",
                                    primary: true,
                                    onClick: () => setViewMode(false),
                                    hide: !viewMode || !userCTX.userHasRole("admin") || profile.id !== userCTX.user?.organisation.id
                                },
                                {
                                    label: "Запросить модерацию",
                                    primary: true,
                                    onClick: () => requestModeration(),
                                    hide:
                                        !viewMode ||
                                        profile.moderationPassed === "expectation" ||
                                        profile.moderationPassed === "accepted" ||
                                        profile.moderationPassed === "re_moderation"
                                }
                            ]}
                        />
                    }
                    <Portal />
                </>
            )}
        />
    );
}
