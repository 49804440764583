import { Dialog, DialogContent } from "@rmwc/dialog";
import React, { useContext, useEffect, useState } from "react";
import { IInfoDialog } from "./UpdateHistoryItem.types";
import "./UpdateHistoryItem.style.sass";
import { Button } from "@rmwc/button";
import { IUpdateHistoryItem } from "../../pages/UpdateHistory";
import { req } from "../../global/common";
import { InterfaceContext } from "../../global/context.interface";
import { useTranslation } from "react-i18next";

export const UpdateHistoryItem: React.FC<IInfoDialog> = ({
                                                             id,
                                                             setSelectedVersion,
                                                             mode = "default",
                                                             firstView = false
                                                         }) => {
    const [steps, setSteps] = useState<{ count: number, current: number }>(null as unknown as { count: number, current: number });
    const [isOpen, setIsOpen] = useState(false);
    const [updateHistoryItem, setUpdateHistoryItem] = useState<IUpdateHistoryItem>(null as unknown as IUpdateHistoryItem);
    const interfaceCTX = useContext(InterfaceContext);
    const { t } = useTranslation("common");

    const getUpdateHistoryItem = (id: number) => {
        req.get(`/profile/changelog-info?versionId=${id}`)
            .then(({ data }) => {
                if (mode === "update" && data.viewed) {
                    return;
                } else {
                    if (data.media.length === 0) {
                        setSteps({
                            count: 1, current: 1
                        });
                    } else {
                        setSteps({
                            count: data.media.length, current: 1
                        });
                        setUpdateHistoryItem(data);
                        setIsOpen(true);
                    }
                }
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            });
    };

    const onClose = () => {
        setIsOpen(false);
        setSteps(null as unknown as { count: number, current: number });
        setUpdateHistoryItem(null as unknown as IUpdateHistoryItem);
        setSelectedVersion(undefined);
    };

    useEffect(() => {
        if (id) {
            getUpdateHistoryItem(id);
        }
    }, [id]);

    return (
        <Dialog
            className="update-history-dialog"
            open={isOpen}
            onClose={() => {
                onClose();
            }}
        >
            <DialogContent className={"update-history-dialog-content"}>
                {updateHistoryItem && steps &&
                    <>
                        <div className={"update-history-dialog-wrapper"}>
                            {/*{!firstView && steps.current === 1 &&*/}
                            {/*    <div className={"update-history-dialog-title"}>*/}
                            {/*        {updateHistoryItem.title}*/}
                            {/*    </div>*/}
                            {/*}*/}
                            <div className={"update-history-dialog-body"}>
                                {/*{*/}
                                {/*    firstView*/}
                                {/*        ? */}
                                <div
                                    className={"update-history-dialog-body-image"}
                                    style={{ backgroundImage: `url(${updateHistoryItem.media[steps.current - 1].path})` }}
                                />
                                {/*// : steps.current === 1*/}
                                {/*//     ? <div className={"update-history-dialog-body-text"}>*/}
                                {/*//         {updateHistoryItem.body}*/}
                                {/*//     </div>*/}
                                {/*//     : <div*/}
                                {/*//         className={"update-history-dialog-body-image"}*/}
                                {/*//         style={{ backgroundImage: `url(${updateHistoryItem.media[steps.current - 2].path})` }}*/}
                                {/*//     />*/}
                                {/*}*/}
                            </div>
                        </div>
                        <div
                            className={`update-history-dialog-footer${steps.current === steps.count
                                ? " update-history-dialog-footer-center" : ""}`}
                        >
                            {steps.current !== steps.count &&
                                <div className={"update-history-dialog-footer-points"}>
                                    <div className={"update-history-dialog-footer-count"}>
                                        {steps.current + "/" + steps.count}
                                    </div>
                                    <div className={"update-history-dialog-footer-points-wrapper"}>
                                        {updateHistoryItem.media.map((item: any, index) => (
                                            <div
                                                className={`update-history-dialog-footer-point 
                                        ${steps.current === index + 1 ? " point-active" : ""}`}
                                            />)
                                        )}
                                    </div>
                                </div>
                            }
                            <div className={"update-history-dialog-footer-buttons"}>
                                {steps.current !== steps.count &&
                                    <Button
                                        raised={false}
                                        className={"update-history-dialog-footer-btn"}
                                        outlined={false}
                                        onClick={event => {
                                            event.preventDefault();
                                            onClose();
                                        }}
                                    >
                                        Пропустить все
                                    </Button>
                                }
                                <Button
                                    raised={false}
                                    className={"update-history-dialog-footer-btn"}
                                    outlined={false}
                                    onClick={event => {
                                        event.preventDefault();
                                        if (steps.current === steps.count) {
                                            onClose();
                                        } else {
                                            setSteps(prevState => ({ ...prevState, current: prevState.current + 1 }));
                                        }
                                    }}
                                >
                                    {steps.current === steps.count ? "Продолжить" : "Далее"}
                                </Button>
                            </div>
                        </div>
                    </>
                }
            </DialogContent>
        </Dialog>
    );
};