import { CustomDialog } from "../CustomDialog";
import React, { useState } from "react";
import { req } from "../../global/common";
import { InterfaceContext } from "../../global/context.interface";
import { UserContext } from "../../global/context.user";
import { useTranslation } from "react-i18next";
import { Button } from "@rmwc/button";

export interface IExtensionVacancy {
    id: number;
    updateDaysBeforeTheEnd: () => void;
    isOpenDialogPublish: boolean;
    setIsOpenDialogPublish: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExtensionVacancy: React.FC<IExtensionVacancy> = ({
                                                                  id,
                                                                  updateDaysBeforeTheEnd,
                                                                  setIsOpenDialogPublish,
                                                                  isOpenDialogPublish
                                                              }) => {
    const { t } = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(false);
    const interfaceCTX = React.useContext(InterfaceContext);
    const userCTX = React.useContext(UserContext);

    const extendVacancy = () => {
        req.post(`/vacancy/organisation/${id}/prolong`)
            .then(({ data }) => {
                userCTX.changeCoinsCount();
                updateDaysBeforeTheEnd();
                setIsOpenDialogPublish(false);
            })
            .catch(e => {
                    setIsOpenDialogPublish(false);
                    if (e.response.data === "vacancy.need_more_gold") {
                        setIsOpenErrorDialog(true);
                    } else {
                        interfaceCTX.showMessage({
                            body: t(e.response.data),
                            icon: "report"
                        });
                    }
                }
            );
    };

    const amocrmCreateTask = () => {
        setIsLoading(true);
        req.post("/amocrm/create_task", {
            workforceUserId: userCTX.user?.id
        }).then(({ data }) => {
            req.get("/organisation/request-coin")
                .then(({ data }) => {

                });
            if (data.done) {
                interfaceCTX.showMessage({
                    body: "Запрос на пополнение отправлен",
                    icon: "done"
                });
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <div style={{ position: "absolute" }}
             onClick={(event) => {
                 event.stopPropagation();
             }}>
            <CustomDialog
                isOpen={isOpenErrorDialog}
                onClose={() => setIsOpenErrorDialog(false)}
                dialogTitle={"Пополнить баланс"}
                buttons={[
                    {
                        callback: () => {
                            amocrmCreateTask();
                        },
                        raised: true,
                        disabled: isLoading,
                        text: "ПОПОЛНИТЬ БАЛАНС"
                    }
                ]}
            >
                <div style={{
                    padding: "20px 4px",
                    width: "642px",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    textAlign: "center",
                    color: "#12324F"
                }}>
                    Для размещения вакансий, необходимо приобрести возможность публиковать объявления, на которые потом
                    смогут откликаться соискатели. Нажмите кнопку "Пополнить баланс" и менеджер свяжется с вами в
                    ближайшее время.
                </div>
            </CustomDialog>
            <CustomDialog
                isOpen={isOpenDialogPublish}
                onClose={() => setIsOpenDialogPublish(false)}
                dialogTitle={"Продлить срок размещения публикации?"}
                buttons={[{
                    callback: () => {
                        setIsOpenDialogPublish(false);
                    },
                    outlined: true,
                    raised: false,
                    text: "Нет, не надо"
                }, {
                    callback: () => extendVacancy(),
                    outlined: true,
                    raised: true,
                    text: "Да, продлить"
                }]}
            >
                <div style={{
                    padding: "20px 50px",
                    width: "500px",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    color: "#12324F",
                    flexDirection: "column"
                }}>
                    Продление увеличит срок размещения на месяц, с вашего баланса будет списана 1
                    публикация.
                </div>
            </CustomDialog>
        </div>
    );
};