import { stringToURL } from "../../../../components/Form/Form.service"

export {checkOGRN, checkINN, checkKPP, getTextWithLinks}


const isValidOGRN = {
    entrepreneur(ogrn: number[]): boolean {
        return (ogrn.length === 13) && (ogrn[12] === Math.floor((Number(ogrn.join('')) / 10) % 11) % 10);
    },
    business(ogrn: number[]): boolean {
        return (ogrn.length === 15) && (ogrn[14] === Math.floor((Number(ogrn.join('')) / 10) % 13) % 10);
    }
};

const checkOGRN = (value: string | number): boolean => {
    if(!value || value === '') return true
    const ogrn = String(value).split('').map(Number);

    return isValidOGRN.business(ogrn) || isValidOGRN.entrepreneur(ogrn);
};

function checkINN(value: string): boolean {
    if (!/\d+/g.test(value) || value.includes("0000000000")) return false

    if(value.length === 10){
        return Number(value[9]) === (value.split('').slice(0, -1)
                .reduce(
                    (summ, symbol, index) =>
                        [2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
                    0)
            % 11) % 10;

    }else if(value.length === 12){
        let checkSumOne = (value.split('').slice(0, -2)
                .reduce(
                    (summ, symbol, index) =>
                        [7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
                    0)
            % 11 ) % 10;

        let checkSumTwo = (value.split('').slice(0, -1)
                .reduce(
                    (summ, symbol, index) =>
                        [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
                    0)
            % 11 ) % 10;

        return (checkSumOne === Number(value[10]) && checkSumTwo === Number(value[11]));
    } return false
}

function checkKPP(num: string): boolean {
    if(num.length === 0) return true
    return (num.length === 9 && /\d+/g.test(num) && !num.includes("000000000"))
}

function getTextWithLinks (text: string) {
    return text
    .split(' ')
    .map(word => {
        if (word.match(/((https?:\/\/)?[^\s.]+\.[\w][^\s]+)/gm) != null) {
            return `<a href="${stringToURL(word)}">${word}</a>`
        }
        return word
    })
    .join(" ")
}