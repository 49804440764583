import {Prompt, Redirect, Route, useHistory} from 'react-router-dom'
import { Role } from '../global/definitions/definitions'
import { UserContext } from '../global/context.user'
import React from 'react'
import { ErrorPage } from 'pages'
import { InterfaceContext } from '../global/context.interface'
interface PageProps {
	title: string
	path: string
	component: React.ReactElement
	availableRoles?: Role[]
}

const excludeRoles: Role[] = ['foreman']

function isIncludeSomeRole(target: Role[] | null, roles: Role[]): boolean {
	if (!target) return false
	// if (target.length == 0 && roles.includes('pre-worker')) return false
	return target.some(userRole => roles.includes(userRole))
}

export default function Page({ title, path, component, availableRoles }: PageProps): JSX.Element {
	const userCTX = React.useContext(UserContext)
	const interfaceCTX = React.useContext(InterfaceContext)

	const history = useHistory()

	let formChanged = Object.values(interfaceCTX.isFormChanged).filter(v => v == true).length != 0

	React.useEffect(() => {
		console.log('Page is mounted')
		interfaceCTX.resetIsFormChanged()

		return () => {
			console.log('Page is unmounted')
			interfaceCTX.resetIsFormChanged()
			interfaceCTX.clearMsgQueue()
		}
	}, [])

	document.title = title

	if (userCTX.user?.currentRole === 'moderator' && availableRoles && !isIncludeSomeRole(userCTX.user.roles, availableRoles)) {
		 return <Redirect to={'/organizations'}/>
	}else if (userCTX.user && userCTX.user.roles.length == 1 && isIncludeSomeRole(userCTX.user.roles, excludeRoles)) {
		return <ErrorPage code='wrong_role' />
	} else if (availableRoles && (!userCTX.user || !isIncludeSomeRole(userCTX!.user!.roles, availableRoles))) {
		return <ErrorPage code='wrong_role' />
	}

	//@ts-ignore
	return (<Route path={path}>
			{component}
		 	<Prompt when={formChanged} message={'Все несохраненные данные будут удалены. Вы уверены?'} />
		</Route>
	)
}
