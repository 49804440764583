import i18next from "i18next";
import RU_LANG from './ru.json';

export function localization(){
	i18next.init({
		interpolation: { escapeValue: false },
		lng: 'ru',
		resources: {
			ru: { common: RU_LANG }
		}
	});
	return i18next
}