import { Icon } from '@rmwc/icon'
import { TextField } from '@rmwc/textfield'
import clsx from 'clsx'
import { FC } from 'react'
import { FindTextFieldProps } from './find-textfield-def'
import './find-textfield.sass'

export const FindTextField: FC<FindTextFieldProps> = ({
	className,
	filtersIsEmpty,
	onOpenMenu,
	...rest
}): JSX.Element => {
	return (
		<TextField
			className={clsx('find-textfield', className)}
			label='Поиск по должности'
			maxLength={100}
			trailingIcon={{
				icon: 'tune',
				onClick: onOpenMenu
			}}
			outlined
			{...rest}
		>
			<Icon className='find-textfield-search-icon' icon='search' />
			<span
				className={clsx('find-textfield-filter-indicator', {
					'find-textfield-filter-indicator--active': filtersIsEmpty
				})}
			/>
		</TextField>
	)
}
