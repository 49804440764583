import { ColDef, FirstDataRenderedEvent, GridSizeChangedEvent, ICellRendererParams, ViewportChangedEvent } from "ag-grid-community";
import { IPaymentHistoryListItem } from "./PaymentHistory.types";
import { format } from "date-fns";
import { Tooltip } from "@rmwc/tooltip";

interface IPaymentHistoryColDef extends ColDef {
	field?: keyof IPaymentHistoryListItem;
	cellRenderer?: (
		params: ICellRendererParams<IPaymentHistoryListItem, number | string, any>
	) => React.ReactNode;
}

export const PaymentHistoryColDef: IPaymentHistoryColDef[] = [
	{
		headerName: "ID тарифа",
		field: "tariffId",
		minWidth: 100,
	},
	{
		headerName: "Дата добавления",
		field: "addDate",
		minWidth: 170,
		cellRenderer: (params) => {
			return format(new Date(params.data?.addDate || ""), "dd.MM.yyyy HH:mm");
		},
	},
	{
		headerName: "Дата начала",
		field: "tariffStartDate",
		minWidth: 170,
		cellRenderer: (params) => {
			return params.data?.tariffStartDate ? format(new Date(params.data?.tariffStartDate), "dd.MM.yyyy HH:mm") : "";
		},
	},
	{
		headerName: "Дата окончания",
		field: "tariffEndDate",
		minWidth: 170,
		cellRenderer: (params) => {
			return params.data?.tariffEndDate
				? format(new Date(params.data?.tariffEndDate! || ""), "dd.MM.yyyy HH:mm")
				: "";
		},
	},
	{
		headerName: "Стоимость пакета",
		field: "price",
		minWidth: 180,
	},
	{
		headerName: "Размер пакета",
		field: "type",
		minWidth: 250,
	},
	{
		headerName: "Ответственный",
		field: "responsible",
		minWidth: 250,
	},
	{
		headerName: "Основание",
		field: "reason",
		minWidth: 250,
		cellRenderer: (params) => {
			return (
				<Tooltip content={params.data?.reason && params.data?.rationale} activateOn={params.data?.reason && params.data?.rationale ? "hover" : "focus"}>
					<div
						className={"payment-history-reason payment-history-cell"}
						style={{ cursor: "pointer" }}
					>
						{params.data?.reason}
					</div>
				</Tooltip>
			);
		},
	},
];

export const AgGridParams = {
	rowHeight: 60,
	headerHeight: 60,
	gridOptions: {
		defaultColDef: {
			autoHeight: true,
			autoHeaderHeight: true,
		},
		stopEditingWhenCellsLoseFocus: true,
		singleClickEdit: true,
	},
	onFirstDataRendered: (event: FirstDataRenderedEvent) => {
		event.api.sizeColumnsToFit();
	},
	onGridSizeChanged: (event: GridSizeChangedEvent<any, any>) => {
		event.api.sizeColumnsToFit();
	},
	onViewportChanged: (event: ViewportChangedEvent) => {
		event.api.sizeColumnsToFit();
	},
	overlayNoRowsTemplate: 'Чтобы добавить данные, нажмите на кнопку "Добавить тарриф',
	overlayLoadingTemplate: "Загрузка...",
	animateRows: true,
};
