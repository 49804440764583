import {useHistory} from "react-router";
import React from "react";
import { SurveyList } from "./components/SurveyList/SurveyList";
import { SurveyItem } from "./components/SurveyItem/SurveyItem";

export const SurveyPage: React.FC = () => {
    const history = useHistory()

    return (
        <div className={'versions-history'}>
            {history.location.pathname.includes(`list`) &&
                <SurveyList/>
            }
            {history.location.pathname.includes(`item`) &&
                <SurveyItem/>
            }
        </div>
    )
}