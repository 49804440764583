import { requestsApi, RequestWorkerResponse } from 'global/api'
import { InterfaceContext } from 'global/context.interface'
import { useContext, useEffect, useState } from 'react'

export const useRequest = (requestId: number) => {
	const { showMessage } = useContext(InterfaceContext)
	const [request, setRequest] = useState<RequestWorkerResponse>({} as RequestWorkerResponse)
	const [isLoading, setIsLoading] = useState(false)
	const [isSuccess, setIsSuccess] = useState<boolean | null>(null)

	useEffect(() => {
		setIsLoading(true)
		requestsApi
			.getRequestById(requestId)
			.then(response => {
				setRequest(response.data)
				setIsSuccess(true)
			})
			.catch(e => {
				setIsSuccess(false)
				showMessage({
					body: 'Ошибка при загрузке потребности'
				})
			})
			.finally(() => {
				setIsLoading(false)
			})
	}, [])

	return { request, setRequest, isLoading, isSuccess }
}
