import { IAvatarProps } from "./Avatar.types";
import defaultAvatar from '../../misc/user-avatar-default.png'
import profileDeletedAvatar from '../../misc/deleted_user.png'
import './Avatar.style.sass'
import { Link } from "react-router-dom";


export function Avatar(props: IAvatarProps) {
    return (
        props.linkDisabled ?
            <div
                className={`avatar ${props.className} linkDisabled`}
                style={{ ...props.style, backgroundImage: `${props.previewHref === null ? props.isProfileDeleted ? `url(${profileDeletedAvatar})` : `url(${defaultAvatar})` : `url(${props.previewHref})`}` }}
            >
            </div>
            :
        <Link
            className={`avatar ${props.className}`}
            style={{ ...props.style, backgroundImage: `${props.previewHref === null ? props.isProfileDeleted ? `url(${profileDeletedAvatar})` : `url(${defaultAvatar})` : `url(${props.previewHref})`}` }}
            to={props.linkProps ? props.linkProps :
                {
                    pathname: props.pathToProfile,
                    state: { prevPath: props.prevPath }
                }
            }
        >
        </Link>
    )
}