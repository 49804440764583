import {SpanButtons, Form, Divider} from 'components'
import { FC, MutableRefObject, useCallback, useRef, useState } from 'react'
import { useExperienceOptions, usePermissionOptions, useProfessionOptions } from '../../worker-profile-utils'
import { ExperienceField } from '../experience-field/experience-field'
import { FormFieldLabel } from '../form-field-label/form-field-label'
import { MainProfessionField } from '../main-profession-field/main-profession-field'
import { PermissionDocumentsField } from '../permission-documents-field/permission-documents-field'
import { PermissionsField } from '../permissions-field/permissions-field'
import { QualificationDocumentsField } from '../qualification-documents-field/qualification-documents-field'
import { SubProfessionField } from '../sub-profession-field/sub-profession-field'
import { ProfessionalExperienceFormProps } from './professional-experience-form-def'
import './professional-experience-form.sass'

export const ProfessionalExperienceForm: FC<ProfessionalExperienceFormProps> = ({
	mainProfession,
	subProfessions,
	grade,
	permissions,
	permissionDocuments,
	experience,
	qualificationDocuments,
	onClickClose,
	onSubmit,
	onAvatarUpdate,
	onDocumentDelete,
	onImageDelete,
	withoutExperience
}) => {
	const formRef = useRef() as MutableRefObject<HTMLFormElement>
	const [subProfessionSearch, setSubProfessionSearch] = useState('')
	const [permissionSearch, setPermissionSearch] = useState('')
	const [profession, setProfession] = useState<string | undefined>(mainProfession?.name)

	const allProfessionsOptions = useProfessionOptions({
		search: subProfessionSearch
	})

	const allPermissionsOptions = usePermissionOptions({
		search: permissionSearch
	})

	const allExperienceOptions = useExperienceOptions()

	const handleClickCancel = useCallback(() => {
		onClickClose?.(1)
	}, [onClickClose])

	const handleSubmit = useCallback(
		values => {
			onSubmit?.({
				...values,
				mainProfession: values.mainProfession,
				experience: values?.experience?.map((exp: any) => {
					if (exp.id < 0) {
						const { id, ...newExp } = exp
						return newExp
					}
					return exp
				}),
				withoutExperience: values?.experience.length <= 0 && mainProfession?.name ? values?.withoutExperience : false
			})
		},
		[onSubmit]
	)

	return (
		<div className='experience-form-wrapper'>
			<Form
				className='experience-form'
				elevation={0}
				name='workerProfile'
				onSubmit={handleSubmit}
				useRef={formRef}
				data={[
					[
						{
							values: [
								{
									type: 'other',
									colSpan: -1,
									value: () => (
										<div className='experience-form-buttons-save'>
											<div className='button-save'>
												<SpanButtons
													data={[
														{
															label: 'Не сохранять',
															onClick: handleClickCancel
														}
													]}
												/>
											</div>
											<SpanButtons
												data={[
													{
														label: 'Сохранить изменения',
														onClick: () => {
															formRef.current.dispatchEvent(
																new Event('submit', {
																	cancelable: true,
																	bubbles: true
																})
															)
														}
													}
												]}
											/>
										</div>
									)
								}
							]
						}
					],
					[
						{
							values: [
								{
									type: 'other',
									colSpan: 1,
									watchVar: 'mainProfession',
									defaultValue: mainProfession,
									value: (e, currentMainProfession, { setValue }) => {
										return (
											<>
												<FormFieldLabel title='Базовая профессия' />
												<MainProfessionField
													value={currentMainProfession}
													onChange={newMainProfession => {
														setValue('mainProfession', newMainProfession)
														setProfession(newMainProfession.name)
													}}
												/>
											</>
										)
									}
								},
								{
									className: 'experience-form-field-title',
									type: 'textfield',
									colSpan: 1,
									title: 'Разряд',
									caption: ' (не обязательно)',
									defaultValue: grade || '',
									var: 'grade',
									validate: {
										pattern: /\d+/g,
										maxLength: 4
									}
								},
								{
									type: 'other',
									colSpan: 1,
									watchVar: 'subProfessions',
									defaultValue: subProfessions,
									value: (e, currentSubProfessions, { setValue }) => (
										<>
											<FormFieldLabel title='Дополнительная профессия' caption=' (не обязательно)' />
											<SubProfessionField
												search={subProfessionSearch}
												onChangeSearch={e => setSubProfessionSearch(e.target.value)}
												options={allProfessionsOptions}
												value={currentSubProfessions}
												onChange={newSubProfession => setValue('subProfessions', newSubProfession)}
											/>
										</>
									)
								},
								{
									type: 'other',
									value: () => <Divider type='horizontally' />
								},
								{
									type: 'other',
									colSpan: 1,
									watchVar: 'qualificationDocuments',
									defaultValue: qualificationDocuments,
									value: (_, currentQualificationDocuments, { setValue, control }) => {
										return (
											<QualificationDocumentsField
												control={control}
												value={currentQualificationDocuments}
												onChange={newQualificationDocuments => {
													setValue('qualificationDocuments', newQualificationDocuments)
												}}
											/>
										)
									}
								}
							]
						},

						{
							values: [
								{
									type: 'other',
									colSpan: 1,
									watchVar: 'permissions',
									defaultValue: permissions,
									value: (e, currentPermissions, { setValue }) => (
										<>
											<FormFieldLabel title='Допуски' />
											<PermissionsField
												search={permissionSearch}
												onChangeSearch={e => setPermissionSearch(e.target.value)}
												options={allPermissionsOptions}
												value={currentPermissions}
												onChange={newPermissions => setValue('permissions', newPermissions)}
											/>
										</>
									)
								},
								{
									type: 'other',
									colSpan: 1,
									watchVar: 'permissionDocuments',
									defaultValue: permissionDocuments,
									value: (e, currentPermissionDocuments, { setValue, control }) => {
										return (
											<PermissionDocumentsField
												control={control}
												value={currentPermissionDocuments}
												onChange={newQualificationDocuments => {
													setValue('permissionDocuments', newQualificationDocuments)
												}}
											/>
										)
									}
								},
								{
									type: 'other',
									colSpan: 1,
									watchVar: 'experience',
									defaultValue: experience,
									value: (e, currentExperience, { setValue, control }) => {

										return (
											<ExperienceField
												control={control}
												value={currentExperience}
												onChange={newExperienceValues => {
													setValue('experience', newExperienceValues)
												}}
												productions={allExperienceOptions?.productions}
												workTypes={allExperienceOptions?.workTypes}
												withoutExperience={withoutExperience}
												isVisible={!!profession}
											/>
										)
									}
								}
							]
						}
					]
				]}
			/>
		</div>
	)
}
