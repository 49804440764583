import { CustomDialog } from "components";
import { IModalFormWrapperProps } from "../def";
import "./styles.sass";

export function ModalWrapper({
	open,
	onSave,
	onClose,
	isSaveDisabled,
	children,
	title,
	buttons,
}: IModalFormWrapperProps) {
	return (
		<CustomDialog
			isOpen={open}
			containerStyle={{ maxHeight: "700px" }}
			onClose={() => onClose()}
			dialogTitle={title}
			buttons={
				!buttons
					? [
							{
								callback: () => onClose(),
								outlined: true,
								text: "Отменить",
							},
							{
								callback: () => onSave(),
								outlined: true,
								raised: true,
								disabled: isSaveDisabled,
								text: "Сохранить",
							},
					  ]
					: buttons
			}
		>
			{children}
		</CustomDialog>
	);
}
