import { RefObject, createRef, useContext, useEffect } from "react";
import { ModalWrapper } from "./components/ModalWrapper";
import { IFormProps } from "./def";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { TextField } from "@rmwc/textfield";
import { req } from "global/common";
import { InterfaceContext } from "global/context.interface";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import { FormFieldWrapper } from "./components/FormFieldWrapper";
import { ReasonFieldSwitcher } from "./components/ReasonFieldsSwitcher";
import { ValidationErrorMessage } from "./components/ValidationErrorMessage";

export function AddPublicationsForm({ open, onClose, setRefreshPaymentHistory }: IFormProps) {
	const { orgID } = useParams() as { orgID: string; key: string };

	const interfaceCTX = useContext(InterfaceContext);

	const methods = useForm({
		mode: "all",
	});

	const formRef = createRef() as RefObject<HTMLFormElement>;

	useEffect(() => {
		open &&
			req
				.get("/moderator/tariff/current", { organisationId: Number(orgID) })
				.then(({ data }) => {
					methods.reset({
						id: data.id,
						count: ""
					});
				});
	}, [open]);

	function onSave() {
		formRef.current!.dispatchEvent(
			new Event("submit", {
				cancelable: true,
				bubbles: true,
			})
		);
	}

	function onSubmit(data: any) {
		const body = {
			...data,
			count: Number(data?.count),
			reason: data.reason?.label,
			price: Number(data?.price?.replace(" руб.", "").trim()) || 0,
			fileInput: undefined,
			id: undefined,
		};

		const formData = new FormData();
		Object.keys(body).forEach(
			(key) => body[key] && formData.append(key, body[key])
		);

		req
			.post(
				`/moderator/tariff/publications?tariffId=${Number(
					methods.getValues("id")
				)}&organisationId=${Number(orgID)}`,
				formData
			)
			.then(() => {
				interfaceCTX.showMessage({
					title: "Успех",
					body: "Публикации добавлены к действующему тарифу",
				});
				onClose();
				setRefreshPaymentHistory(prevState => !prevState)
			})
			.catch((e) => {
				console.log(e)
				interfaceCTX.showMessage({
					title: "Ошибка",
					body: "Обратитесь к администратору",
				});
			});
			interfaceCTX.setRefreshCurrentTariff()
	}

	return (
		<ModalWrapper
			title="Добавить публикации"
			open={open}
			onSave={onSave}
			onClose={onClose}
			isSaveDisabled={!methods.formState.isValid}
		>
			<FormProvider {...methods}>
				<form
					className={"payment-history-form"}
					ref={formRef}
					onSubmit={methods.handleSubmit(onSubmit)}
				>
					<FormFieldWrapper label="ID тарифа">
						<Controller
							name="id"
							control={methods.control}
							render={({ field }) => {
								return <TextField disabled {...field} outlined />;
							}}
						/>
					</FormFieldWrapper>

					<FormFieldWrapper label="Количество публикаций">
						<Controller
							name="count"
							control={methods.control}
							rules={{
								required: true,
								validate: {
									max: (v) => Number(v) <= 999999 || "Максимум 999999",
								},
							}}
							render={({ field, fieldState }) => {
								return (
									<>
										<NumberFormat
											{...field}
											customInput={TextField}
											outlined
											allowNegative={false}
											focus={fieldState.error?.message}
										/>
										<ValidationErrorMessage
											message={fieldState.error?.message || ""}
										/>
									</>
								);
							}}
						/>
					</FormFieldWrapper>

					<ReasonFieldSwitcher />
				</form>
			</FormProvider>
		</ModalWrapper>
	);
}
