import {FixedButtons} from 'components'
import { UserContext } from 'global/context.user'
import { FC, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ErrorPageProps } from './error-page-def'
import './error-page.sass'

export const ErrorPage: FC<ErrorPageProps> = ({ code }): JSX.Element => {
	const { t } = useTranslation('common')
	const history = useHistory()
	const { user, setUser } = useContext(UserContext)

	const isWorkerRole = useMemo(() => user?.roles?.includes('worker') || !user?.roles?.length, [user])

	return (
		<div className='error-template-page'>
			<div className='container'>
				<h2>Ошибка {code == 'wrong_role' ? '' : code}</h2>
				<span className='description'>{t('error.http.' + code)}</span>
				<FixedButtons
					length={2}
					buttons={[
						{
							label: `Перейти в профиль`,
							primary: true,
							onClick: () => {
								if (isWorkerRole) {
									setUser(null)
									history.push('/worker-profile')
								} else {
									setUser(null)
									history.push('/profile')
								}
							},
							hide: code != 'wrong_role' || user!.roles[0] == 'foreman'
						},
						{
							label: 'Выход',
							primary: true,
							onClick: () => {
								localStorage.clear()
								sessionStorage.clear()
								setUser(null)
								history.push('/sign-in')
							}
						}
					]}
				/>
			</div>
		</div>
	)
}
