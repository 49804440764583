import {Checkbox} from '@rmwc/checkbox'
import '@rmwc/checkbox/styles'
import {SimpleDialog} from '@rmwc/dialog'
import {Elevation} from '@rmwc/elevation'
import {Icon} from '@rmwc/icon'
import {TextField} from '@rmwc/textfield'
import {PageTitle, FixedButtons, ProgressPage} from 'components'
import {authorName, req} from 'global/common'
import {InterfaceContext} from 'global/context.interface'
import {UserContext} from 'global/context.user'
import {PagesRoutes} from 'global/routes'
import React from 'react'
import {useParams} from "react-router"
import {Link, useHistory} from 'react-router-dom'
import {Params} from "../../../public-profile/components/PublicProfilePage/public-profile-def"
import {IMobilizeStep} from './mobilize-subpage-def'
import './mobilize-subpage.sass'

export function MobilizeSubPage() {

    const history = useHistory()
    let _location = history.location.pathname.split('/')

    let isMobilizePage = _location[3] == 'mobilize'
    let status = _location[_location.length - 1]

    let requestPath = isMobilizePage ? 'mobilization' : 'demobilization'
    let whatToDo = isMobilizePage ? 'mobilize' : 'demobilize'

    const interfaceCTX = React.useContext(InterfaceContext)
    const userCTX = React.useContext(UserContext)

    const {searchID, recordID} = useParams() as Params

    const [currentWorker, setCurrentWorker] = React.useState(null as any)
    const [checkboxesStatus, setCheckboxesStatus] = React.useState<any>(null as unknown as IMobilizeStep[])
    const [allCheckboxesStatus, setAllCheckboxesStatus] = React.useState(false)
    const [checkboxesDisabled, setCheckboxesDisabled] = React.useState(true)
    const [btnsHideStatus, setBtnsHideStatus] = React.useState({
        mobilize: true,
        checkPlanned: true,
        save: true,
        review: true
    })
    const [openDialog, setOpenDialog] = React.useState(false)

    let disabledCheckboxStyle: any = {
        opacity: '0.3',
        pointerEvents: 'none'
    }

    const toggleAllCheckboxesStatus = (value: boolean) => {
        setAllCheckboxesStatus(value)
        setCheckboxesStatus((prevState: IMobilizeStep[]) => {
            return prevState.map(item => ({
                ...item,
                planned: value
            }))
        })
    }

    React.useEffect(() => {


        if (userCTX.userHasRole('admin') || userCTX.userHasRole('organizer')) {
            if (isMobilizePage) {
                if (status == 'pending') {
                    setBtnsHideStatus({
                        ...btnsHideStatus,
                        mobilize: false,
                        checkPlanned: false,
                        save: false
                    })

                    setCheckboxesDisabled(false)
                }
            } else if (isMobilizePage == false) {
                if (status == 'pending') {
                    setBtnsHideStatus({
                        ...btnsHideStatus,
                        mobilize: false,
                        checkPlanned: false,
                        save: false
                    })

                    setCheckboxesDisabled(false)
                }
                if (status == 'mobilized') {
                    setBtnsHideStatus({
                        ...btnsHideStatus,
                        mobilize: false,
                        checkPlanned: false,
                        save: false
                    })
                    setCheckboxesDisabled(false)
                } else if (status == 'demobilized') {
                    setBtnsHideStatus({
                        ...btnsHideStatus,
                        review: false
                    })
                }
            }
        }

        let query = {
            limit: 0,
            offset: 0,
        }
        req.get(`/hr/mobilization/list`, query)
            .then(({data}) => setCurrentWorker(data.data.filter((dataItem: { worker: { recordID: string } }) => dataItem.worker.recordID == recordID)[0].worker))
            .catch(() => setCurrentWorker(false))
    }, [])

    React.useEffect(() => {
        req.get(`/hr/mobilization/${recordID}/${requestPath}/records`)
            .then(({data}) => setCheckboxesStatus(data.data))
            .catch(() => setCheckboxesStatus(false))
    }, []);

    async function saveMobilizeSteps(steps: any) {
        let body: { data?: IMobilizeStep[] } = {};
        body.data = steps;
        await req.post(`/hr/mobilization/${recordID}/${requestPath}/update-records`, body)
            .then(() => interfaceCTX.showMessage({
                body: 'Данные сохранены',
                icon: 'done'
            }))
            .catch(() => interfaceCTX.showMessage({
                body: 'Ошибка сервера. Сохранить не удалось. Обратитесь к администратору',
                icon: 'report'
            }))
    }

    function isPlanEqualFact() {
        let unCompletedSteps = checkboxesStatus.filter((step: any) => {
            if (step.planned != step.completed) {
                return true
            }
        })

        if (unCompletedSteps.length > 0) {
            return false
        } else {
            return true
        }
    }

    function isAnyPlanesStepChecked() {
        let planedStepsChecked = checkboxesStatus.filter((step: any) => {
            if (step.planned == true) {
                return true
            }
        })

        if (planedStepsChecked.length > 0) {
            return true
        } else {
            return false
        }
    }

    async function mobilize() {

        if (isPlanEqualFact() && isAnyPlanesStepChecked()) {
            let body: { data?: IMobilizeStep[] } = {};
            body.data = Object.assign(checkboxesStatus);
            req.post(`/hr/mobilization/${recordID}/${requestPath}/update-records`, body)
                .then(() => req.post(`/hr/mobilization/${recordID}/${requestPath}/${whatToDo}`))
                .then(() => interfaceCTX.showMessage({
                    body: `Работник ${isMobilizePage ? 'мобилизован' : 'демобилизован'}. Данные сохранены`,
                    icon: 'done'
                }))
                .then(() => history.goBack())
                .catch((e) => interfaceCTX.showMessage({
                    body: `Ошибка сервера. ${isMobilizePage ? 'Мобилизовать' : 'Демобилизовать'} не удалось. Обратитесь к администратору ${e.response.data}`,
                    icon: 'report'
                }))
        } else if (!isPlanEqualFact()) {
            interfaceCTX.showMessage({
                body: 'Завершите запланированные мероприятия',
                icon: 'report'
            })
        } else if (!isAnyPlanesStepChecked()) {
            interfaceCTX.showMessage({
                body: 'Запланируйте хотя бы одно мероприятие',
                icon: 'report'
            })
        }
    }

    function markPlannedAsFact() {
        setCheckboxesStatus((state: any) => state.map((step: any) => {
            if (step.planned == true) {
                step.completed = true
            }
            return step;
        }))
    }

    let mobilizeSteps = checkboxesStatus && (checkboxesStatus as unknown as IMobilizeStep[]).map((step, index) => {
            return (
                <li key={step.type + '_' + index} className="row">
                    <div className="checkbox plan cell">
                        <Checkbox
                            className={step.planned ? '' : 'off-checkbox'}
                            style={checkboxesDisabled ? disabledCheckboxStyle : {}}
                            checked={step.planned}
                            onChange={(e) => {
                                let newPlanned = e.currentTarget.checked
                                setCheckboxesStatus((state: any) => state.map((el: any) => {
                                    if (step.type == el.type) {
                                        step.planned = !!newPlanned
                                    }
                                    return el;
                                }))
                            }}
                        />
                    </div>
                    <div className="checkbox completed cell">
                        <Checkbox
                            style={checkboxesDisabled ? disabledCheckboxStyle : {}}
                            checked={step.completed}
                            onChange={(e) => {
                                let newCompleted = e.currentTarget.checked
                                setCheckboxesStatus((state: any) => state.map((el: any) => {
                                    if (step.type == el.type) {
                                        step.completed = !!newCompleted
                                    }
                                    return el;
                                }))
                            }}
                        />
                    </div>
                    <div className="comments cell">
                        <div className="title">
                            {step.type}
                        </div>
                        <TextField
                            outlined
                            className={'input'}
                            label={"Комментарии"}
                            defaultValue={step.comment === 'null' ? "" : step.comment}
                            onChange={(e) => setCheckboxesStatus((state: any) => state.map((el: any) => {
                                if (el.type == step.type) {
                                    el.comment = (e.target as HTMLInputElement).value
                                }
                                return el;
                            }))}
                        />
                    </div>
                </li>
            )
        }
    )

    return (
        <ProgressPage
            state={checkboxesStatus && currentWorker}
            render={() =>
                <div className='mobilize-subpage'>

                    {currentWorker && <PageTitle
                        title={authorName(currentWorker.profile && currentWorker.profile)}
                        // back={'/mobilization'}
                        breadcrumbs={true}
                    >
                        <Link
                            className="link-to-contract"
                            to={{
                                pathname: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION + PagesRoutes.CONTRACT_CARD + `/${currentWorker?.recordID}`,
                                state: history.location.state
                            }}>
                            <div className="text-wrapper">
                                <Icon className="icon" icon="description"></Icon>
                                Договор
                            </div>
                        </Link>
                    </PageTitle>
                    }
                    <Elevation z={3} className='mobilize-checkbox-list-wrapper'>


                        <ul className="mobilize-checkbox-list">
                            <li className="headings row">
                                <div className="heading cell">
                                    План
                                </div>
                                <div className="heading cell">
                                    Факт
                                </div>
                                <div className="heading cell">
                                    Действия, подлежащие проверке
                                </div>
                            </li>
                            {mobilizeSteps}
                        </ul>


                    </Elevation>
                    <div className="btn-panel">
                        <FixedButtons length={4}
                                      buttons={[
                                          {
                                              label: `${allCheckboxesStatus ? 'Отменить выбор' : 'Выбрать все'}`,
                                              primary: true,
                                              onClick: () => toggleAllCheckboxesStatus(!allCheckboxesStatus),
                                              disabled: checkboxesDisabled,
                                              hide: checkboxesDisabled
                                          },
                                          {
                                              label: `${isMobilizePage ? 'Мобилизовать' : 'Работа в компании завершена'}`,
                                              primary: true,
                                              onClick: () => setOpenDialog(true),
                                              hide: btnsHideStatus.mobilize
                                          },
                                          {
                                              label: 'Сохранить',
                                              primary: false,
                                              onClick: () => saveMobilizeSteps(Object.assign(checkboxesStatus)),
                                              hide: userCTX.user?.currentRole === 'hr'
                                          },
                                          {
                                              label: 'Оставить отзыв',
                                              primary: false,
                                              hide: !userCTX.currentRole('organizer') || btnsHideStatus.review,
                                              onClick: () => history.push({
                                                  pathname: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION + PagesRoutes.PUBLIC_PROFILE + `/${searchID}/${recordID}`,
                                                  search: '?reviews=4',
                                                  state: history.location.state
                                              })
                                          },
                                          {
                                              label: 'Запланированные действия выполнены',
                                              primary: true,
                                              onClick: () => markPlannedAsFact(),
                                              hide: btnsHideStatus.checkPlanned
                                          },
                                      ]}
                        />
                    </div>
                    <SimpleDialog
                        // title="Подтверждение"
                        body={`Вы уверены, что хотите ${isMobilizePage ? '' : 'де'}мобилизовать Работника?`}
                        cancelLabel={'Нет'}
                        acceptLabel={'Да'}
                        open={openDialog}
                        onClose={evt => {
                            if (evt.detail.action === 'accept') mobilize()
                            setOpenDialog(false)
                        }}
                    />
                </div>
            }
        />
    )
}