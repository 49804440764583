import { FC } from 'react'
import { RequestField } from '../request-field'
import { RequestRangeFieldProps } from './request-range-field-def'

export const RequestRangeField: FC<RequestRangeFieldProps> = ({
	className,
	title,
	valueTo,
	valueFrom
}): JSX.Element => {
	if (valueFrom && valueTo) {
		return <RequestField className={className} title={title} value={`от ${valueFrom} до ${valueTo}`} />
	}

	return <></>
}
