import { IModuleBlockNotification } from './ModuleBlockNotification.types'

export const getModuleTypeText = (moduleType: IModuleBlockNotification['moduleType']) => {
	switch (moduleType) {
		case 'need':
			return (
				<div>
					<ul>
						<li>Инструмент позволяет организовать структурированный сбор заявок на потребности в рабочем персонале;</li>
						<li>Он помогает формировать объявления о вакансиях, на основе поступивших заявок;</li>
						<li>Инструмент заявки позволяет понимать картину укомплектованности рабочими по всем направлениям;</li>
						<li>Позволяет перенаправлять соискателей на собеседования с заинтересованными лицами в один клик;</li>
						<li>Кроме того, инструментарий позволяет отслеживать результаты собеседований.</li>
					</ul>
				</div>
			)
		case 'worker':
			return (
				<div>
					<ul>
						<li>
							Учет работников в строительной компании необходим для планирования и оптимизации процесса трудоустройства
							и увольнения сотрудников на объектах;
						</li>
						<li>
							Он помогает контролировать затраты на перевозку и размещение рабочей силы, учитывая географическое
							расположение объекта и доступность транспортных средств;
						</li>
						<li>
							Учет работников также позволяет обеспечить безопасность на объекте, контролируя наличие необходимых
							документов и квалификации рабочих;
						</li>
						<li>
							Он является важным инструментом для анализа эффективности работы на объекте и принятия решений по
							оптимизации процессов и улучшению результативности.
						</li>
					</ul>
				</div>
			)
		case 'time_keeping':
			return (
				<div>
					<ul>
						<li>
							Учет рабочего времени позволяет оптимизировать график работы и повысить производительность труда, что
							способствует более эффективному выполнению проектов;
						</li>
						<li>
							С помощью табеля учета рабочего времени можно контролировать переработки и выходные дни, что помогает
							сократить затраты на заработную плату и избежать возможных штрафов за нарушения трудового
							законодательства;
						</li>
						<li>
							Табель учета рабочего времени является важным инструментом для расчета заработной платы и учета отпусков и
							больничных дней, что позволяет строительной компании точно планировать свой бюджет и избежать финансовых
							рисков;
						</li>
						<li>
							Кроме того, учет рабочего времени помогает соблюдать законодательные нормы по трудовому законодательству и
							предотвращать возможные конфликты с работниками, что способствует созданию благоприятной рабочей атмосферы
							в компании.
						</li>
					</ul>
				</div>
			)
		case 'timesheet':
			return (
				<div>
					<ul>
						<li>
							Табель учета рабочего времени позволяет прорабу контролировать рабочие часы каждого работника, что
							помогает сократить вероятность задержек в выполнении проекта;
						</li>
						<li>
							С помощью табеля учета рабочего времени можно отслеживать переработки и выходные дни, что позволяет
							оптимизировать график работы и увеличить производительность труда;
						</li>
						<li>
							Табель учета рабочего времени является важным инструментом для расчета заработной платы и учета отпусков и
							больничных дней;
						</li>
						<li>
							Кроме того, табель учета рабочего времени помогает соблюдать законодательные нормы по трудовому
							законодательству и предотвращать возможные конфликты с работниками.
						</li>
					</ul>
				</div>
			)
		case 'objects':
			return (
				<div>
					<ul>
						<li>
							Справочник объектов строительства помогает быстро и удобно находить информацию о проектах и объектах, на
							которых работают сотрудники компании;
						</li>
						<li>
							Это позволяет более эффективно планировать распределение персонала и ресурсов на различных объектах,
							учитывая их географическое расположение и другие параметры;
						</li>
						<li>
							Справочник объектов строительства полезен для обмена информацией между разными отделами компании и для
							координации работы между ними;
						</li>
						<li>
							Кроме того, он может использоваться для анализа эффективности работы на разных объектах и для принятия
							решений по оптимизации процессов и улучшению результативности.
						</li>
					</ul>
				</div>
			)
		case 'mobilization':
			return (
				<div>
					<ul>
						<li>
							Инструменты перемещения, отправки, возвращения и снятия рабочих со строительного объекта помогают
							эффективно планировать и организовывать процесс трудоустройства и увольнения сотрудников на объекте;
						</li>
						<li>
							Они также позволяют контролировать и оптимизировать затраты на перевозку и размещение рабочей силы,
							учитывая географическое расположение объекта и доступность транспортных средств;
						</li>
						<li>
							Инструменты мобилизации и демобилизации могут быть полезны для обеспечения безопасности на объекте, путем
							контроля за наличием необходимых документов и квалификации рабочих;
						</li>
						<li>
							Кроме того, они могут использоваться для анализа эффективности работы на объекте и принятия решений по
							оптимизации процессов и улучшению результативности.
						</li>
					</ul>
				</div>
			)
	}
}
