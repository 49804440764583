import { Elevation } from '@rmwc/elevation'
import { PageTitle, ProgressPage } from 'components'
import { useSearchParams } from 'custom-hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { req } from 'global/common'
import { useWorkerProfile } from '../hooks/useWorkerProfile'
import { AboutMeForm } from './components/about-me-form/about-me-form'
import { DocumentsForm } from './components/documents-form/documents-form'
import { ExitDialog } from './components/exit-dialog/exit-dialog'
import { GarmentsForm } from './components/garments-form/garments-form'
import { PageDefault } from './components/page-default/page-default'
import { PersonalInfoForm } from './components/personal-info-form/personal-info-form'
import { PersonalInfo } from './components/personal-info/personal-info'
import { ProfessionalExperienceForm } from './components/professional-experience-form/professional-experience-form'
import { Tabs } from './components/tabs/tabs'
import { AboutMeSubPage } from './subPages/about-me/about-me'
import { DocumentsSubPage } from './subPages/documents/documents'
import { GarmentsSubPage } from './subPages/garments/garments'
import { PendingReview } from './subPages/pending-review'
import { ProfessionalExperienceSubPage } from './subPages/professional-experience/professional-experience'
import Reviews from './subPages/reviews/reviews'
import { IDocConstants, WorkerProfile } from './worker-profile-def'
import { mapWorkerProfileToFormValues, useReviewsOptions } from './worker-profile-utils'
import './worker-profile.sass'
import { PendingReviewContextProvider } from './subPages/pending-review/context/PendingReviewContextProvider'

export const WorkerPublicProfile = () => {
	const history = useHistory<{ prevPath?: string }>()
	const searchParams = useSearchParams()
	const tab = searchParams.get('tab') || 0
	const [activeTab, setActiveTab] = useState(+tab)
	const [openDialog, setOpenDialog] = useState(-1)
	const [openEditWorkerProfileForm, setOpenEditWorkerProfileForm] = useState('')

	const { loaded, workerProfile, updateWorkerProfile, deleteWorkerProfileDocumentById, deleteWorkerProfileImage } =
		useWorkerProfile()

	const workerProfileFormValues = useMemo(() => mapWorkerProfileToFormValues(workerProfile), [workerProfile])

	const {
		mainProfession,
		grade,
		subProfessions,
		experience,
		permissions,
		qualificationDocuments,
		permissionDocuments,
		isDeleted,
		documents,
		anthropometry
	} = workerProfile

	const { about, workSearchStatus, workSearchSchedule } =
		workerProfile?.personalInfo || ({} as WorkerProfile['personalInfo'])

	const reviews = useReviewsOptions()

	useEffect(() => {
		setOpenEditWorkerProfileForm('')
	}, [workerProfile])

	useEffect(() => {
		history.replace(`?tab=${activeTab}`)
	}, [activeTab])

	useEffect(() => {
		setActiveTab(+tab)
	}, [tab])

	const handleClickEditTopForm = useCallback(() => {
		setOpenEditWorkerProfileForm('worker-profile')
	}, [])

	const handleClickCloseForm = useCallback((value = 0) => {
		setOpenDialog(value)
	}, [])

	const handleOnActiveTab = useCallback(
		value => {
			if (openEditWorkerProfileForm && openEditWorkerProfileForm !== 'worker-profile') {
				setOpenDialog(value)
			} else {
				setActiveTab(value)
			}
		},
		[openEditWorkerProfileForm, openDialog]
	)

	const isPageDefaultAnthropometry = !Object.values(anthropometry || {}).find(el => Boolean(Number(el)))

	const isPageDefaultAbout = !about && !workSearchStatus && !workSearchSchedule

	const isPageDefaultDocuments =
		!documents?.inn && !documents?.passport && !documents?.snils && !documents?.media?.length

	const isPageDefaultProfessionalExperienceForm =
		!mainProfession &&
		!grade &&
		!subProfessions?.length &&
		!qualificationDocuments?.length &&
		!permissionDocuments?.length &&
		!permissions?.length &&
		!experience?.length

	const isDisabledTopBlock = !!openEditWorkerProfileForm && openEditWorkerProfileForm !== 'worker-profile'
	const [docConstants, setDocConstants] = useState<IDocConstants>({} as IDocConstants)

	useEffect(() => {
		req.get('/info/constants').then(({ data }) => {
			setDocConstants(data)
		})
	}, [])

	return (
		<ProgressPage
			className='worker-profile-page'
			state={loaded}
			render={() => (
				<>
					<PageTitle
						title={!isDeleted ? 'Профиль пользователя' : 'Профиль удален'}
						back={history.location.state && history.location.state.prevPath}
					/>
					{!openEditWorkerProfileForm || openEditWorkerProfileForm !== 'worker-profile' ? (
						<PersonalInfo
							isDisabled={isDisabledTopBlock}
							onEdit={handleClickEditTopForm}
							avatar={workerProfile?.avatar}
							{...(workerProfile?.personalInfo || {})}
						/>
					) : (
						<PersonalInfoForm
							onSubmit={updateWorkerProfile}
							onCancel={handleClickCloseForm}
							{...(workerProfileFormValues?.personalInfo || {})}
							avatar={workerProfileFormValues?.avatar}
						/>
					)}

					<Elevation
						z={3}
						className={openEditWorkerProfileForm === 'worker-profile' ? 'tabs-button opacity noSelect' : 'tabs-button'}
						style={{pointerEvents: `${openEditWorkerProfileForm === 'worker-profile' ? 'none' : 'auto'}`}}
					>
						<Tabs
							activeTab={activeTab}
							onActiveTab={handleOnActiveTab}
							isDisabled={openEditWorkerProfileForm === 'worker-profile'}
						/>
						{activeTab === 0 &&
							(openEditWorkerProfileForm === 'about-me' ? (
								<AboutMeForm
									onSubmit={updateWorkerProfile}
									about={workerProfileFormValues?.personalInfo?.about}
									workSearchStatus={workerProfileFormValues?.personalInfo?.workSearchStatus}
									workSearchSchedule={workerProfileFormValues?.personalInfo?.workSearchSchedule}
									onClickClose={handleClickCloseForm}
								/>
							) : isPageDefaultAbout ? (
								<PageDefault
									handleClick={() => {
										setOpenEditWorkerProfileForm('about-me')
									}}
								/>
							) : (
								<AboutMeSubPage
									about={about}
									workSearchStatus={workSearchStatus}
									workSearchSchedule={workSearchSchedule}
									setIsOpenEditWorkerProfileForm={setOpenEditWorkerProfileForm}
									isDisabledBlock={!!openEditWorkerProfileForm && openEditWorkerProfileForm !== 'about-me'}
								/>
							))}
						{activeTab === 1 &&
							(openEditWorkerProfileForm === 'professional-experience' ? (
								<ProfessionalExperienceForm
									onSubmit={updateWorkerProfile}
									mainProfession={mainProfession || undefined}
									grade={workerProfileFormValues?.grade}
									subProfessions={workerProfileFormValues?.subProfessions}
									permissions={workerProfileFormValues?.permissions}
									permissionDocuments={workerProfileFormValues?.permissionDocuments}
									withoutExperience={workerProfileFormValues.withoutExperience}
									experience={workerProfileFormValues?.experience}
									qualificationDocuments={workerProfileFormValues?.qualificationDocuments}
									onClickClose={handleClickCloseForm}
									onDocumentDelete={deleteWorkerProfileDocumentById}
									onImageDelete={deleteWorkerProfileImage}
								/>
							) : isPageDefaultProfessionalExperienceForm ? (
								<PageDefault
									handleClick={() => {
										setOpenEditWorkerProfileForm('professional-experience')
									}}
								/>
							) : (
								<ProfessionalExperienceSubPage
									workerProfile={workerProfile}
									onClickEditButtonTabs={setOpenEditWorkerProfileForm}
									isDisabledBlock={
										!!openEditWorkerProfileForm && openEditWorkerProfileForm !== 'professional-experience'
									}
								/>
							))}
						{activeTab === 2 &&
							(openEditWorkerProfileForm === 'documents' ? (
								<DocumentsForm
									docConstants={docConstants}
									personalInfo={workerProfile?.personalInfo}
									registrationAddress={workerProfile?.registrationAddress}
									actualAddress={workerProfile?.actualAddress}
									documents={workerProfile.documents}
									onSubmit={updateWorkerProfile}
									onClickClose={handleClickCloseForm}
								/>
							) : isPageDefaultDocuments ? (
								<PageDefault
									handleClick={() => {
										setOpenEditWorkerProfileForm('documents')
									}}
								/>
							) : (
								<DocumentsSubPage
									documents={documents}
									birthDate={workerProfile.personalInfo.birthDate}
									citizenship={workerProfile?.personalInfo?.citizenship}
									registrationAddress={workerProfile?.registrationAddress}
									actualAddress={workerProfile?.actualAddress}
									setIsOpenEditWorkerProfileForm={setOpenEditWorkerProfileForm}
									isDisabledBlock={!!openEditWorkerProfileForm && openEditWorkerProfileForm !== 'documents'}
								/>
							))}
						{activeTab === 3 &&
							(openEditWorkerProfileForm === 'anthropometry' ? (
								<GarmentsForm
									anthropometry={anthropometry}
									onClickClose={handleClickCloseForm}
									onSubmit={updateWorkerProfile}
								/>
							) : isPageDefaultAnthropometry ? (
								<PageDefault
									handleClick={() => {
										setOpenEditWorkerProfileForm('anthropometry')
									}}
								/>
							) : (
								<GarmentsSubPage
									anthropometry={anthropometry}
									setIsOpenEditWorkerProfileForm={setOpenEditWorkerProfileForm}
									isDisabledBlock={!!openEditWorkerProfileForm && openEditWorkerProfileForm !== 'anthropometry'}
								/>
							))}
						{activeTab === 4 &&
							(reviews?.length ? (
								<Reviews reviews={reviews} />
							) : (
								<div className='page-default-reviews'>Список пуст</div>
							))}
						{activeTab === 5 && (
							<PendingReviewContextProvider>
								<PendingReview />
							</PendingReviewContextProvider>
						)}
					</Elevation>
					<ExitDialog
						open={openDialog}
						onSuccess={() => {
							setOpenEditWorkerProfileForm('')
							setActiveTab(openDialog)
							setOpenDialog(-1)
						}}
						onClose={() => {
							setOpenDialog(-1)
						}}
					/>
				</>
			)}
		/>
	)
}
