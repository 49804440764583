export interface IDocumentsTable {
    documents: IDocumentsData[] | null
    onChangeSortValues: (fieldName: string, sortType: 'ASC' | 'DESC' | null) => void
    isAddNewDocument?: boolean
    onChangeNewDocument?: (field: ERequestDocumentFields, value: string | null) => void
    uploadNewDocument?: (file: File | null, callback: () => void) => void
    deleteDocument?: (id: number) => void
    startChangeDocument?: (document: IDocumentsData, index: number) => void
    editedExistingDocument?: IRequestDocumentData
    onChangeExistingDocument?: (field: ERequestDocumentFields, value: string | null) => void
    isEdited?: boolean
    newDocument?: IRequestDocumentData
}

export interface IDocumentsData {
    beginDate: string
    creationDate: string
    document: {
        id: number
        path: string
        userId: number
        organisationID: number
        name: string
    },
    documentName: string
    documentNumber: string
    endDate: string
    orgInfo: {
        organizationId: number
        organizationName: string
    },
    provisioningCenterName: string
    userIssuedDocument: number
    id: number
    //Для редактирования
    isChanged?: boolean
}

export type IRequestDocumentData = {
    [ERequestDocumentFields.BEGIN_DATE]: string
    [ERequestDocumentFields.DOCUMENT]: number | null | {
        id: number
        path: string
        userId: number
        organisationID: number
        name: string
    }
    [ERequestDocumentFields.DOCUMENT_NUMBER]: string
    [ERequestDocumentFields.DOCUMENT_NAME]: string
    [ERequestDocumentFields.END_DATE]: string
    [ERequestDocumentFields.PROVISIONING_CENTER_NAME]: string
    [ERequestDocumentFields.USER_ISSUED_DOCUMENT]?: string
    [ERequestDocumentFields.CREATION_DATE]?: string
    userID?: number
}

export enum ERequestDocumentFields {
    BEGIN_DATE = "beginDate",
    DOCUMENT = "document",
    DOCUMENT_NUMBER = "documentNumber",
    DOCUMENT_NAME = "documentName",
    END_DATE = "endDate",
    PROVISIONING_CENTER_NAME = "provisioningCenterName",
    USER_ISSUED_DOCUMENT = "userIssuedDocument",
    CREATION_DATE = "creationDate",
}
