import React, {useContext, useState} from "react";
import {IRoomMeta} from "../../messages-page-def";
import {useHistory} from "react-router-dom";
import {ChatContext} from "../../../../global/context.chat";
import defaultAvatar from "../../../../misc/user-avatar-default.png";
import {Icon} from "@rmwc/icon";
import {MatrixClient} from "matrix-js-sdk";

export const Room: React.FC<{ room: IRoomMeta, isOpenRoomMenu: any, setIsOpenRoomMenu: any }> = ({
                                                                                                     room,
                                                                                                     setIsOpenRoomMenu,
                                                                                                     isOpenRoomMenu
                                                                                                 }) => {
    const history = useHistory();
    const {client, sendIgnoredUsers} = useContext(ChatContext);
    const [isVisible, setIsVisible] = useState(true)

    const renderUnreadCountMessage = (value: number) => {
        if (value > 9) {
            return "+9"
        } else if (value < 9) {
            return value
        }
    }

    if (!isVisible) return null

    return (
        <>
            {
                (history.location.pathname === "/messages/ignored" && room.isIgnored ||
                    history.location.pathname === "/messages/archive" && room.isArchiveOrder ||
                    history.location.pathname === "/messages" && !room.isIgnored && !room.isArchiveOrder) &&
                <div className='messages-page-item'
                     onClick={() => {
                         history.push(`/messages/${room.roomId}`)
                     }}
                >
                    <div style={{position: 'relative'}}>
                        {room.getAvatar
                            ? <div className='messages-page-item-avatar'
                                   style={{
                                       backgroundImage: `url(${room.getAvatar})`,
                                   }}/>
                            : <div className='messages-page-item-avatar'
                                   style={{
                                       backgroundImage: `url(${defaultAvatar})`,
                                       backgroundColor: '#FFFFFF',
                                       border: '1px solid #D9D9D9',
                                   }}/>}
                        {!!room.count &&
                            <div className='messages-page-item-notification'>
                                <div>
                                    {renderUnreadCountMessage(room.count)}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='messages-page-text'>
                        <div className='messages-page-item-fullName'>
                            {room.name}
                        </div>
                        <div className='messages-page-item-last-message'>
                            {room.lastMessage}
                        </div>
                    </div>
                    <Icon
                        icon='more_horiz'
                        className='messages-page-item-more'
                        onClick={(event) => {
                            event.stopPropagation()
                            if (isOpenRoomMenu === room.roomId) {
                                setIsOpenRoomMenu(null)
                            } else {
                                setIsOpenRoomMenu(room.roomId)
                            }
                        }}
                    />
                    {isOpenRoomMenu === room.roomId &&
                        <div className='messages-page-menu-body'
                             onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                                 e.stopPropagation()
                             }}>
                            <div className='messages-page-menu-list'>
                                {!room.isIgnored &&
                                    <div className='messages-page-menu-item'>
                                        <div onClick={(event) => {
                                            if (room.isArchiveOrder) {
                                                client?.deleteRoomTag(room.roomId, 'archive', (err, data) => {
                                                    setIsVisible(false)
                                                })
                                            } else if (!room.isArchiveOrder) {
                                                client?.setRoomTag(room.roomId, 'archive', {order: 1}, (err, data) => {
                                                    setIsVisible(false)
                                                })
                                            }
                                            setIsOpenRoomMenu(null)
                                        }}>
                                            {room.isArchiveOrder ? 'Вернуть из архива' : 'Отправить в архив'}
                                        </div>
                                    </div>
                                }
                                <div className='messages-page-menu-item'
                                     onClick={() => {
                                         sendIgnoredUsers(room.userId, room.roomId, client as MatrixClient)
                                             .then(() => {
                                                 setIsVisible(false)
                                                 setIsOpenRoomMenu(null)
                                             })
                                     }}>
                                    <div>
                                        {room.isIgnored ? 'Вернуть из ЧС' : 'Заблокировать'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}
