import { IFormFieldWrapperProps } from "../def";

export function FormFieldWrapper({ label, children }: IFormFieldWrapperProps) {
	return (
		<div >
			<div className={"form-field-label"}>{label}</div>
			<div className={"form-field-input-wrapper"}>{children}</div>
		</div>
	);
}
