import { PagesRoutes } from "global/routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { TableCell } from "../../../components/Table/TableCell/TableCell";
import { IOrganization } from "./organizations-def";
import { getOrganizationStatusColor, organizationSettings } from "./organizations-utils";
import "./organizations.sass";
import { req } from "../../../global/common";
import {
    ExtendedCustomTable,
    IColumnSettings,
    IExtendedCustomTableApiURLS,
    ISortingAndFilteringArray
} from "../../../components";
import { format } from "date-fns";
import { Icon } from "@rmwc/icon";

export const Organizations = () => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const apiURLS: IExtendedCustomTableApiURLS = {
        getTableValues: "/moderator/organisation/list",
        getUniqueColumnValues: "/moderator/organisation/unique",
        getTableSettings: "/moderator/organisation/table/settings/get",
        saveTableSettings: "/moderator/organisation/table/settings/set"
    };

    const renderFilterValue = (
        filterValue: ISortingAndFilteringArray["filterType"],
        columnType: IColumnSettings["columnType"]
    ) => {
        let values: string = "";
        if (!!filterValue) {
            if (columnType === "moderationStatus") {
                values = filterValue.map(item => t("extended_custom_table." + item)).join(", ");
                return values;
            } else if (columnType.toLowerCase().includes("date") && columnType !== "tariffUntilDate") {
                values = filterValue.join(" - ");
            } else {
                values = filterValue.join(", ");
            }
            
            return values;
        }
        return values;
    };

    const getOrganizationKey = (id: number) => {
        req.post(`/moderator/organisation/key?organisationId=${id}`)
            .then(({ data }) => {
                history.push({
                    pathname: `/organizations/organization-profile/${data.organisationId}/${data.key}`,
                    state: history.location.state
                });
            });
    };

    const renderRow = (tableValue: any, columnSettings: IColumnSettings[], cellIsActive: (columnType: string, columnSettings: IColumnSettings[]) => string) => {
        return columnSettings.map((column, index) => {
            if (column.columnType === "moderationStatus" && column.visibility) {
                return (
                    <TableCell key={column.columnType + index} devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: "100%"
                        }}>
                        <div className={"organization-status"}>
                            <div className={"organization-status-color"}
                                style={{ backgroundColor: getOrganizationStatusColor(tableValue.moderationStatus) }} />
                            <div className={"organization-status-text"}>
                                {t("extended_custom_table." + tableValue.moderationStatus)}
                            </div>
                        </div>
                    </TableCell>
                );
            } else if (column.visibility && column.columnType === "fullName") {
                return (
                    <TableCell key={column.columnType + index} devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: "100%"
                        }}>
                        <div className={"organization-name"}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                getOrganizationKey(tableValue.id);
                            }}
                        >{tableValue[column.columnType]}</div>
                    </TableCell>
                );
            } else if (column.visibility && column.columnType === "registrationDate") {
                return (
                    <TableCell key={column.columnType + index} devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: "100%"
                        }}>
                        {tableValue.registrationDate && format(new Date(tableValue.registrationDate), "dd.MM.yyyy")}
                    </TableCell>
                );
            } else if (column.visibility && column.columnType === "countActiveUsers") {
                return (
                    <TableCell key={column.columnType + index} devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: "100%"
                        }}>
                        <Link className={"organization-name"}
                            to={{
                                pathname: "/organizations" + PagesRoutes.USERS + `/${tableValue.id}`,
                                state: history.location.state
                            }}>{tableValue[column.columnType]}</Link>
                    </TableCell>
                );
            } else if (column.visibility && column.columnType === "module") {
                return (
                    <TableCell key={column.columnType + index} devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: "100%"
                        }}>
                        <div className={"organization-active-modules"}>
                            {
                                tableValue.module.includes("admin") &&
                                <Icon className={"organization-module-type"}
                                    icon={require("../../../misc/admin.svg").default}
                                />
                            }
                            {
                                tableValue.module.includes("recruiter") &&
                                <Icon className={"organization-module-type"}

                                    icon={require("../../../misc/recruiter.svg").default}
                                />
                            }
                            {
                                tableValue.module.includes("foreman") &&
                                <Icon className={"organization-module-type"}
                                    icon={require("../../../misc/foreman.svg").default}
                                />
                            }
                        </div>
                    </TableCell>
                );
            }
            else if (column.visibility && column.columnType === "tariffStartDate") {
                return (
                    <TableCell key={column.columnType + index} devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: "100%"
                        }}>
                        {format(new Date(tableValue[column.columnType]), 'dd.MM.yyyy') || "Не определено"}
                    </TableCell>
                )
            }
            else if (column.visibility && column.columnType === "tariffEndDate") {
                return (
                    <TableCell key={column.columnType + index} devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: "100%"
                        }}>
                        {tableValue[column.columnType] ? format(new Date(tableValue[column.columnType]), 'dd.MM.yyyy') : "Бессрочно"}
                    </TableCell>
                )
            }
            else if (column.columnType && column.visibility) {
                return (
                    <TableCell key={column.columnType + index} devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: "100%"
                        }}>
                        {tableValue[column.columnType]}
                    </TableCell>
                );
            } else return <></>;
        });
    };

    const arrayStrings = (array: string[]) => {
        let result: string = ''
        array.map((item: string, index: number) => {
            if (index === 0) {
                result = t("connected_modules.module." + item)
            } else if (index === array.length - 1) {
                result = `${result}, ${t("connected_modules.module." + item)}.`
            } else if (index !== 0) {
                result = `${result}, ${t("connected_modules.module." + item)}`
            }
        })
        return result
    }


    const reorderData = (columnSettings: IColumnSettings[], thereIs: (value: any) => any, tableValues: any[]) => {
        return tableValues.map((value: IOrganization, index) => {
            const item: any = {} as IOrganization;
            columnSettings.forEach((column) => {
                if (column.columnType === "moderationStatus" && column.visibility) {
                    return item[column.columnType] = t("extended_custom_table." + value.moderationStatus);
                } else if (column.columnType === "registrationDate" && column.visibility) {
                    return item[column.columnType] = value.registrationDate && format(new Date(value.registrationDate), "dd.MM.yyyy");
                } else if (column.columnType === "module" && column.visibility) {
                    return item[column.columnType] = arrayStrings(value.module)
                } else if (column.columnType && column.visibility) {
                    //@ts-ignore
                    return item[column.columnType] = value[column.columnType];
                }
            });
            return item;
        });
    };

    return (

        <>
            <ExtendedCustomTable
                rootClassName={"organizations-page"}
                renderFilterValue={renderFilterValue}
                renderRow={renderRow}
                apiURLS={apiURLS}
                settings={organizationSettings}
                pageTitle={"Список организаций"}
                formattedTableValuesForEXEL={reorderData}
                paginateFilter={true}
                sortingAndFilteringOptions={[{
                    columnType: "activeModules",
                    isFiltering: false
                }]}
            />
        </>
    );
};
