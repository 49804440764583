import BannerScr from "../../misc/banner-big.svg";
import React, { useEffect, useState } from "react";
import { Button } from "@rmwc/button";
import { formatSecondsToTime, req } from "../../global/common";
import { SpanButtons } from "components";
import NumberFormat from "react-number-format";
import { InterfaceContext } from "../../global/context.interface";
import { UserContext } from "../../global/context.user";
import { useTranslation } from "react-i18next";
import { User } from "../../global/definitions/definitions";
import { useHistory } from "react-router";
import "./OrganizationRegistration.style.sass";
import { Checkbox } from "@rmwc/checkbox";
import { ChatContext } from "../../global/context.chat";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@rmwc/textfield";
import { checkINN } from "../public-profile/components/PublicProfilePage/public-profile-utils";
import { profileApi } from "global/api";
import { sendMyTrackerMetric } from "../../global/metrics";
import { AuthRequest, AuthValidate } from "../Signin";


export function OrganizationRegistration() {
    const userCTX = React.useContext(UserContext);
    const interfaceCTX = React.useContext(InterfaceContext);
    const chatCTX = React.useContext(ChatContext);
    const history = useHistory();
    const [checkboxValues, setCheckboxValues] = useState({
        policy: false,
        agreement: false
    });
    const [timer, setTimer] = React.useState<number | null>(null);
    const { t } = useTranslation("common");
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [mode, setMode] = React.useState<"first_stage" | "second_stage" | "code">("first_stage");
    const [state, setState] = React.useState<any>({
        phone: "",
        code: "",
        remainResends: null
    });

    const { control, handleSubmit } = useForm({
        defaultValues: {
            fullName: "",
            shortName: "",
            inn: "",
            lastName: "",
            firstName: "",
            middleName: "",
            email: "",
            phone: ""
        }
    });

    const formSubmit = (data: any) => {
        switch (mode) {
            case "first_stage": {
                setIsLoadingBtn(true);
                req
                    .post("/auth/check-inn", { inn: data.inn })
                    .then(() => {
                        setMode("second_stage");
                        sendMyTrackerMetric({
                            type: "reachGoal",
                            goal: "user_reg_screen"
                        });
                    })
                    .catch(e => {
                        interfaceCTX.showMessage({
                            title: "Ошибка",
                            body: t(e.response?.data ?? "error.internet")
                        });
                    })
                    .finally(() => {
                        setIsLoadingBtn(false);
                    });
                break;
            }

            case "second_stage": {
                setIsLoadingBtn(true);
                req
                    .post("/auth/registration-organisation", {
                        organisationRequest: {
                            fullName: data.fullName,
                            inn: data.inn,
                            shortName: data.shortName,
                            type: "construction"
                        },
                        userRequest: {
                            email: data.email,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            middleName: data.middleName,
                            phone: data.phone.replace(/\D/g, "")
                        }
                    })
                    .then(() => {
                        req
                            .post("/auth/request", { phone: state.phone })
                            .then(({ data }: { data: AuthRequest }) => {
                                setTimer(data.nextResendAfterMs / 1000);
                                setState({
                                    ...state,
                                    remainResends: data.remainResends
                                });
                                setMode("code");
                                sendMyTrackerMetric({
                                    type: "reachGoal",
                                    goal: "tel_verification"
                                });
                            })
                            .catch(e => {
                                interfaceCTX.showMessage({
                                    title: "Ошибка",
                                    body: t("error." + e.response?.data ?? "internet")
                                });
                            });
                    })
                    .catch(e => {
                        interfaceCTX.showMessage({
                            title: "Ошибка",
                            body: t(e.response?.data ?? "internet")
                        });
                    })
                    .finally(() => {
                        setIsLoadingBtn(false);
                    });
                break;
            }
            case "code": {
                setIsLoadingBtn(true);
                // если всё верно, устанавливаем данные о токене в localStorage, обработка идёт в index.ts.tsx
                req
                    .post("/auth/validate", { code: state.code, phone: state.phone.replace(/\D/g, "") })
                    .then(({ data }: { data: AuthValidate }) => {
                        localStorage.setItem("tokenDeathDate", String(Date.now() + data.tokenDurationMs));
                        localStorage.setItem("token", data.token);
                        localStorage.setItem("refreshToken", data.refreshToken);
                        profileApi.getProfile().then(response => {
                            chatCTX.connect();
                            history.replace(`/public-profile/${response.data.organisation?.id}?`);
                            userCTX.setUser(new User(response.data));
                            sendMyTrackerMetric({
                                type: "reachGoal", goal: "organization_profile"
                            });
                        });
                    })
                    .catch(e => {
                        interfaceCTX.showMessage({
                            title: "Ошибка",
                            body: t("error." + e.response.data)
                        });
                    })
                    .finally(() => {
                        setIsLoadingBtn(false);
                    });
                break;
            }
        }
    };

    useEffect(() => {
        sendMyTrackerMetric({
            type: "reachGoal",
            goal: "comp_reg_screen"
        });
    }, []);

    React.useEffect(() => {
        if (mode !== "code") return;
        const interval = setInterval(() => {
            setTimer((prev: any) => {
                if (prev - 1 === 0) clearInterval(interval);
                return prev - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, [mode]);

    const newCode = () => {
        setIsLoadingBtn(true);
        req
            .post("/auth/request", { phone: state.phone })
            .then(({ data }: { data: AuthRequest }) => {
                setTimer(data.nextResendAfterMs / 1000);
                setState({
                    ...state,
                    remainResends: data.remainResends
                });
                const interval = setInterval(() => {
                    setTimer((prev: any) => {
                        if (prev - 1 === 0) clearInterval(interval);
                        return prev - 1;
                    });
                }, 1000);
                return () => clearInterval(interval);
            })
            .catch(e => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data ?? "internet")
                });
            })
            .finally(() => {
                setIsLoadingBtn(false);
            });
    };
    useEffect(() => {
        if (userCTX.user) {
            // redi
        }
    }, []);

    return (
        <div className="organization-authorization-page">
            <h1>{mode === "first_stage" ? "Регистрация Работодателя" : "Регистрация профиля пользователя"}</h1>
            <img
                src={BannerScr}
                alt="pragma"
                style={{
                    height: "70px",
                    margin: "145px 0 30px 0"
                }}
            />

            <form onSubmit={handleSubmit(formSubmit)} className="container">
                {mode !== "code" && (
                    <div className="container-body">
                        {mode === "first_stage" && (
                            <>
                                <Controller
                                    name="fullName"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Поле обязательно к заполнению"
                                        },
                                        maxLength: 300,
                                        minLength: 1,
                                        // pattern: {
                                        //     value: interfaceCTX.fioValidationPattern,
                                        //     message: 'Только кириллица, знаки "-", "_" и пробел'
                                        // },
                                        validate: v => {
                                            let trimmedValue = v.trim();
                                            if (v.length > 0 && trimmedValue == "") {
                                                return "Поле не должно состоять только из пробелов";
                                            } else {
                                                return true;
                                            }
                                        }
                                    }}
                                    render={({ field, fieldState }) => {
                                        return (
                                            <div className="container-body-field">
                                                <div className="container-body-field-head">
                                                    <div className="required-field">*</div>
                                                    <div>Полное наименование организации</div>
                                                </div>
                                                {/*@ts-ignore*/}
                                                <TextField
                                                    {...field}
                                                    outlined
                                                    maxLength={300}
                                                    type="text"
                                                    className="container-body-field-input"
                                                />
                                                <div className="container-body-field-error">
                                                    {JSON.stringify(fieldState.error?.message)?.replace(/"/g, " ")}
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                                <Controller
                                    name="shortName"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Поле обязательно к заполнению"
                                        },
                                        maxLength: 50,
                                        minLength: 1,
                                        // pattern: {
                                        //     value: interfaceCTX.fioValidationPattern,
                                        //     message: 'Только кириллица, знаки "-", "_" и пробел'
                                        // },
                                        validate: v => {
                                            let trimmedValue = v.trim();
                                            if (v.length > 0 && trimmedValue == "") {
                                                return "Поле не должно состоять только из пробелов";
                                            } else {
                                                return true;
                                            }
                                        }
                                    }}
                                    render={({ field, fieldState }) => {
                                        return (
                                            <div className="container-body-field">
                                                <div className="container-body-field-head">
                                                    <div className="required-field">*</div>
                                                    <div>Краткое наименование организации</div>
                                                </div>
                                                {/*@ts-ignore*/}
                                                <TextField
                                                    {...field}
                                                    outlined
                                                    maxLength={50}
                                                    type="text"
                                                    className="container-body-field-input"
                                                />
                                                <div className="container-body-field-error">
                                                    {JSON.stringify(fieldState.error?.message)?.replace(/"/g, " ")}
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                                <Controller
                                    name="inn"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Поле обязательно к заполнению"
                                        },
                                        validate: v => {
                                            let trimmedValue = v.trim();
                                            if (v.length > 0 && trimmedValue == "") {
                                                return "Поле не должно состоять только из пробелов";
                                            } else if (!checkINN(v)) {
                                                return "Укажите валидный номер ИНН";
                                            } else {
                                                return true;
                                            }
                                        }
                                    }}
                                    render={({ field, fieldState }) => {
                                        return (
                                            <div className="container-body-field">
                                                <div className="container-body-field-head">
                                                    <div className="required-field">*</div>
                                                    <div>ИНН организации</div>
                                                </div>
                                                {/*@ts-ignore*/}
                                                <NumberFormat
                                                    {...field}
                                                    className="container-body-field-input"
                                                    placeholder="00000000"
                                                    // format={'###############'}
                                                />
                                                <div className="container-body-field-error">
                                                    {JSON.stringify(fieldState.error?.message)?.replace(/"/g, " ")}
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            </>
                        )}

                        {mode === "second_stage" && (
                            <>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Поле обязательно к заполнению"
                                        },
                                        maxLength: 35,
                                        minLength: 1,
                                        pattern: {
                                            value: interfaceCTX.fioValidationPattern,
                                            message: "Только кириллица, знаки \"-\", \"_\" и пробел"
                                        },
                                        validate: v => {
                                            let trimmedValue = v.trim();
                                            if (v.length > 0 && trimmedValue == "") {
                                                return "Поле не должно состоять только из пробелов";
                                            } else {
                                                return true;
                                            }
                                        }
                                    }}
                                    render={({ field, fieldState }) => {
                                        return (
                                            <div className="container-body-field">
                                                <div className="container-body-field-head">
                                                    <div className="required-field">*</div>
                                                    <div>Фамилия</div>
                                                </div>
                                                <TextField
                                                    {...field}
                                                    outlined
                                                    maxLength={35}
                                                    type="text"
                                                    className="container-body-field-input"
                                                />
                                                <div className="container-body-field-error">
                                                    {JSON.stringify(fieldState.error?.message)?.replace(/"/g, " ")}
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                                <Controller
                                    name="firstName"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Поле обязательно к заполнению"
                                        },
                                        maxLength: 25,
                                        minLength: 1,
                                        pattern: {
                                            value: interfaceCTX.fioValidationPattern,
                                            message: "Только кириллица, знаки \"-\", \"_\" и пробел"
                                        },
                                        validate: v => {
                                            let trimmedValue = v.trim();
                                            if (v.length > 0 && trimmedValue == "") {
                                                return "Поле не должно состоять только из пробелов";
                                            } else {
                                                return true;
                                            }
                                        }
                                    }}
                                    render={({ field, fieldState }) => {
                                        return (
                                            <div className="container-body-field">
                                                <div className="container-body-field-head">
                                                    <div className="required-field">*</div>
                                                    <div>Имя</div>
                                                </div>
                                                <TextField
                                                    {...field}
                                                    outlined
                                                    maxLength={25}
                                                    type="text"
                                                    className="container-body-field-input"
                                                />
                                                <div className="container-body-field-error">
                                                    {JSON.stringify(fieldState.error?.message)?.replace(/"/g, " ")}
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                                <Controller
                                    name="middleName"
                                    control={control}
                                    rules={{
                                        // required: {
                                        //     value: true,
                                        //     message: 'Поле обязательно к заполнению'
                                        // },
                                        maxLength: 40,
                                        minLength: 1,
                                        pattern: {
                                            value: interfaceCTX.fioValidationPattern,
                                            message: "Только кириллица, знаки \"-\", \"_\" и пробел"
                                        },
                                        validate: v => {
                                            let trimmedValue = v.trim();
                                            if (v.length > 0 && trimmedValue == "") {
                                                return "Поле не должно состоять только из пробелов";
                                            } else {
                                                return true;
                                            }
                                        }
                                    }}
                                    render={({ field, fieldState }) => {
                                        return (
                                            <div className="container-body-field">
                                                <div className="container-body-field-head">
                                                    {/*<div className='required-field'>*</div>*/}
                                                    <div>Отчество</div>
                                                </div>
                                                <TextField
                                                    {...field}
                                                    outlined
                                                    maxLength={40}
                                                    type="text"
                                                    className="container-body-field-input"
                                                />
                                                <div className="container-body-field-error">
                                                    {JSON.stringify(fieldState.error?.message)?.replace(/"/g, " ")}
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Поле обязательно к заполнению"
                                        },
                                        maxLength: 30,
                                        minLength: 1,
                                        pattern: {
                                            value: interfaceCTX.emailValidationPattern,
                                            message: "Введите валидную почту"
                                        },
                                        validate: v => {
                                            let trimmedValue = v.trim();
                                            if (v.length > 0 && trimmedValue == "") {
                                                return "Поле не должно состоять только из пробелов";
                                            } else {
                                                return true;
                                            }
                                        }
                                    }}
                                    render={({ field, fieldState }) => {
                                        return (
                                            <div className="container-body-field">
                                                <div className="container-body-field-head">
                                                    <div className="required-field">*</div>
                                                    <div>E-mail</div>
                                                </div>
                                                <TextField
                                                    {...field}
                                                    outlined
                                                    maxLength={30}
                                                    type="text"
                                                    className="container-body-field-input"
                                                />
                                                <div className="container-body-field-error">
                                                    {JSON.stringify(fieldState.error?.message)?.replace(/"/g, " ")}
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                                <Controller
                                    name="phone"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Поле обязательно к заполнению"
                                        },
                                        pattern: {
                                            value: /\+7\040\(9[\d]{2}\)\040[\d]{3}\040[\d]{2}\040[\d]{2}/gm,
                                            message: "Введите номер в формате + 7 (9XX) XXX XX XX"
                                        },
                                        validate: v => {
                                            let trimmedValue = v.trim();
                                            if (v.length > 0 && trimmedValue == "") {
                                                return "Поле не должно состоять только из пробелов";
                                            } else {
                                                return true;
                                            }
                                        }
                                    }}
                                    render={({ field, fieldState }) => {
                                        return (
                                            <div className="container-body-field">
                                                <div className="container-body-field-head">
                                                    <div className="required-field">*</div>
                                                    <div>Телефон</div>
                                                </div>
                                                {/*@ts-ignore*/}
                                                <NumberFormat
                                                    {...field}
                                                    onValueChange={v =>
                                                        setState({
                                                            ...state,
                                                            phone: v.formattedValue
                                                        })
                                                    }
                                                    className="container-body-field-input"
                                                    type="tel"
                                                    placeholder="+7 (000) 000 00 00"
                                                    format="+7 (###) ### ## ##"
                                                    mask="-"
                                                />
                                                <div className="container-body-field-error">
                                                    {JSON.stringify(fieldState.error?.message)?.replace(/"/g, " ")}
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                                <div className="consent_to_processing">
                                    <Checkbox
                                        onChange={e => {
                                            setCheckboxValues(prevState => ({
                                                ...prevState,
                                                policy: e.currentTarget.checked
                                            }));
                                        }}
                                        checked={checkboxValues.policy}
                                    />
                                    <SpanButtons
                                        data={[
                                            {
                                                label: "Я согласен (-а) на обработку персональных данных",
                                                href: `https://workforce.su/policy`,
                                                tag: "span",
                                                class: "support",
                                                isBlank: true
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="consent_to_processing">
                                    <Checkbox
                                        onChange={e => {
                                            setCheckboxValues(prevState => ({
                                                ...prevState,
                                                agreement: e.currentTarget.checked
                                            }));
                                        }}
                                        checked={checkboxValues.agreement}
                                    />
                                    <SpanButtons
                                        data={[
                                            {
                                                label: "Я согласен (-а) и принимаю условия пользовательского соглашения",
                                                href: `https://workforce.su/agreement`,
                                                tag: "span",
                                                class: "support",
                                                isBlank: true
                                            }
                                        ]}
                                    />
                                </div>
                                <br/>
                            </>
                        )}
                        <Button raised disabled={(mode === "second_stage" && (!checkboxValues.policy || !checkboxValues.agreement)) || isLoadingBtn}>
                            {mode === "first_stage" ? "Далее" : "Отправить на модерацию"}
                        </Button>
                    </div>
                )}

                {mode === "code" && (
                    <>
                        <div className="container-body-code">
                            <div className="message">
                                {mode === "code" && (
                                    <>
                                        Мы выслали код на номер
                                        <br />
                                        {state.phone}
                                    </>
                                )}
                            </div>

                            {mode === "code" && (
                                //@ts-ignore
                                <NumberFormat
                                    required
                                    className={"false-mdc-input-field"}
                                    placeholder="0000"
                                    format="####"
                                    mask="-"
                                    defaultValue={state.code}
                                    onValueChange={v =>
                                        setState({
                                            ...state,
                                            code: v.value
                                        })
                                    }
                                />
                            )}

                            <Button raised
                                    disabled={(mode === "code" ? state.code.length < 4 || state.remainResends === 0 : false) || isLoadingBtn}>
                                {mode === "code" ? "Войти" : "Отправить код"}
                            </Button>
                        </div>

                        <div className="footer-message">
                            {!(mode === "code" && timer !== null) ? null : timer! > 0 ? (
                                state.remainResends ? (
                                    <span>Получить новый код можно через {formatSecondsToTime(timer as unknown as string)}</span>
                                ) : (
                                    <span>
										Вы исчерпали количество попыток ввода кода.
										<br />
										Повторный запрос кода будет доступен через 1 час.
									</span>
                                )
                            ) : (
                                <SpanButtons data={[{ label: "Получить новый код", onClick: newCode, tag: "span" }]} />
                            )}
                            <SpanButtons
                                data={[
                                    {
                                        label: "Связаться с техподдержкой",
                                        href: `${"mailto:" + interfaceCTX.getMailToArr()}`,
                                        tag: "span",
                                        class: "support"
                                    }
                                ]}
                            />
                        </div>
                    </>
                )}
                <div className="footer-message">

                    <SpanButtons
                        data={[
                            {
                                label: "Авторизация",
                                href: `/signin`,
                                tag: "span",
                                class: "support"
                            }
                        ]}
                    />
                </div>
            </form>
        </div>
    );
}
