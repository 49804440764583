import { useContext, useEffect, useState } from "react";
import "./PaymentHistory.style.sass";
import { req } from "../../../../../global/common";
import { useParams } from "react-router-dom";
import { InterfaceContext } from "../../../../../global/context.interface";
import { IPaymentHistoryListItem } from "./PaymentHistory.types";
import { AgGridReact } from "ag-grid-react";
import {
	AgGridParams,
	PaymentHistoryColDef,
} from "./PaymentHistory.AgGridParams";
import { AddNewTariff } from "./Forms/AddNewTariffForm";
import { AddContactsForm } from "./Forms/AddContactsForm";
import { AddPublicationsForm } from "./Forms/AddPublicationsForm";
import { useTranslation } from "react-i18next";

export const PaymentHistory = () => {
	const { orgID } = useParams() as { orgID: string; key: string };

	const [paymentHistoryList, setPaymentHistoryList] = useState<
		IPaymentHistoryListItem[]
	>([]);
	const [isOpenModalForm, setIsOpenModalForm] = useState({
		addTariff: false,
		addContacts: false,
		addPublications: false,
	});
	const interfaceCTX = useContext(InterfaceContext);
	const [refresh, setRefresh] = useState<boolean>(false);
	const { t } = useTranslation("common");

	useEffect(() => {
		getPaymentHistory();
	}, [refresh]);

	function getPaymentHistory() {
		req
			.get(
				`/moderator/organisation/all-payments-history?organisationId=${orgID}`
			)
			.then(({ data }) => {
				setPaymentHistoryList(data);
			})
			.catch((e) => {
				console.log(e);
				interfaceCTX.showMessage({
					title: "Ошибка",
					body: t(e.response.data),
				});
			});
	}

	function controlModalForm(
		formType: keyof typeof isOpenModalForm,
		openState: boolean
	) {
		setIsOpenModalForm((prevState) => ({
			...prevState,
			[formType]: openState,
		}));
	}

	return (
		<div className={"payment-history"}>
			<div className="btn-panel-group">
				<div
					className={"btn"}
					onClick={() => controlModalForm("addTariff", true)}
				>
					Создать тариф
				</div>

				<div
					className={"btn"}
					onClick={() => controlModalForm("addContacts", true)}
				>
					Добавить контакты
				</div>
				<div
					className={"btn"}
					onClick={() => controlModalForm("addPublications", true)}
				>
					Добавить публикации
				</div>
			</div>

			<AddNewTariff
				open={isOpenModalForm.addTariff}
				onClose={() => controlModalForm("addTariff", false)}
				setRefreshPaymentHistory={setRefresh}
			/>

			<AddContactsForm
				open={isOpenModalForm.addContacts}
				onClose={() => controlModalForm("addContacts", false)}
				setRefreshPaymentHistory={setRefresh}
			/>

			<AddPublicationsForm
				open={isOpenModalForm.addPublications}
				onClose={() => controlModalForm("addPublications", false)}
				setRefreshPaymentHistory={setRefresh}
			/>

			<div className={"payment-history-table-wrapper"}>
				<AgGridReact
					{...AgGridParams}
					rowData={paymentHistoryList}
					columnDefs={PaymentHistoryColDef}
				/>
			</div>
		</div>
	);
};
