export const newDateValues = (values: string[] | null) => {
    let newValues = []
    // @ts-ignore
    if ((values !== null || values !== ['', '']) && Array.isArray(values)) {
        newValues[0] = [values[0].split('.')[2], values[0].split('.')[1], values[0].split('.')[0]].join('-')
        newValues[1] = [values[1]?.split('.')[2], values[1]?.split('.')[1], values[1]?.split('.')[0]].join('-')
    }
    else {
        newValues = ['','']
    }
    return newValues
}