import { useHistory } from 'react-router'
import { useLocation, useRouteMatch } from 'react-router-dom'
import React, { useEffect } from 'react'

export interface IBreadcrumbsLocationState {
	id: string
	path: string
	title: string
	url: string
	search?: string
}

export const useBreadcrumbs = (title: string) => {
	const history = useHistory()
	const { state } = useLocation<IBreadcrumbsLocationState[]>()
	const { url, path } = useRouteMatch()

	const isContainRoute = (state: IBreadcrumbsLocationState[], route: string): boolean =>
		state.some(({ url }) => url && url === route)

	useEffect(() => {
		if (state && !isContainRoute(state, url)) {
			history.replace({
				state: [...state, { path, url, title: React.isValidElement(title) ? 'Учет рабочего времени' : title }]
			})
		}
	}, [path, url, state, history])
}
