import './group-changing.sass'
import React, {useContext, useEffect, useRef, useState} from "react";
import {req} from "../../../../../../global/common";
import {columns} from "./group-changing-utils";
import {Icon} from "@rmwc/icon";
import {TextField} from "@rmwc/textfield";
import {Checkbox} from "@rmwc/checkbox";
import {Tooltip} from "@rmwc/tooltip";
import {IFilterOrSortingValues, TCurrentFilterValues} from "../groups-list/groups-list-def";
import {useTranslation} from "react-i18next";
import {InterfaceContext} from "../../../../../../global/context.interface";
import {useHistory} from "react-router";
import {IProfessionItem} from "../../../setting-professions-list/setting-professions-list-def";
import {useAutoCloseDialog} from "../../../../../../custom-hooks";
import {CircularProgress} from "@rmwc/circular-progress";
import { FixedButtons, LoadingProgress, MenuFilter, ProgressPage, Table } from "components";
import {TableRow} from "../../../../../../components/Table/TableRow/TableRow";
import {TableCell} from "../../../../../../components/Table/TableCell/TableCell";


export const GroupChanging = () => {
    const [headCells, setHeadCells] = useState(columns)
    const [isMenuFilterOpen, setIsMenuFilterOpen] = useState('')
    const [currentFilter, setCurrentFilter] = useState<TCurrentFilterValues>([])
    const [filterOrSortValues, setFilterOrSortValues] = useState<IFilterOrSortingValues>({
        filter: {},
        sort: {}
    })
    const [showOnlySelected, setShowOnlySelected] = useState(false)
    const [professionsList, setProfessionsList] = useState<{ static: IProfessionItem[], mutable: IProfessionItem[] }>({
        static: [],
        mutable: []
    })
    const {t} = useTranslation('common')
    const interfaceCTX = useContext(InterfaceContext)
    const history = useHistory()
    const [selectedProfessions, setSelectedProfessions] = useState([])
    const [currentGroup, setCurrentGroup] = useState<{
        id?: number
        professionGroupName: string
        professionsIds: number[]
        category: string | null
    }>({
        professionGroupName: '',
        professionsIds: [],
        category: null
    })
    const [isLoading, setIsLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(50)
    const [fetching, setFetching] = useState(false)
    const [isFirstStart, setIsFirstStart] = useState(false)
    const scroller = useRef<HTMLInputElement>(null)
    const isSorted = (fieldName: string, sortType: 'ASC' | 'DESC') => {
        const sortedBy = headCells.find((item: any) => item.fieldName === fieldName)
        if (sortedBy) return sortedBy?.sortType === sortType
        else return false
    }

    const sortTable = (title: string, fieldName: string, sortType: 'ASC' | 'DESC' | 'NONE') => {
        setHeadCells((prevState: any[]) => {
            return prevState.map((item: any) => {
                if (item.fieldName === fieldName && item.sortType === sortType) {
                    onChangeSortValues(title, fieldName, 'NONE')
                    return {...item, sortType: "NONE"}
                } else if (item.fieldName === fieldName) {
                    onChangeSortValues(title, fieldName, sortType)
                    return {...item, sortType: sortType}
                } else return {...item, sortType: 'NONE'}
            })
        })
    }

    const onChangeSortValues = (title: string, fieldName: string, sortType: 'ASC' | 'DESC' | 'NONE') => {
        const alreadyExists = currentFilter.length > 0 && currentFilter.find(item => item.fieldName === fieldName)
        if (alreadyExists) {
            setCurrentFilter(prevState => {
                return [...prevState].map(item => {
                    if (item.fieldName === fieldName) {
                        return {...item, sortType: sortType}
                    } else return {...item, sortType: 'NONE'}
                })
            })
        } else {
            setCurrentFilter(prevState => {
                const result: TCurrentFilterValues = [...prevState].map(item => ({...item, sortType: 'NONE'}))
                return [...result, {title: title, fieldName: fieldName, sortType: sortType, filterValue: null}]
            })
        }
        setFilterOrSortValues(prevState => ({
            ...prevState,
            sort: {
                [fieldName]: sortType === "NONE" ? null : sortType
            }
        }))
    }

    const renderFilterValue = (filterValue: null | string[], fieldName: string) => {
        let values: string = ''
        if (!!filterValue) {
            if (fieldName === 'category') {
                values = filterValue.map(item => t('menu_filter.' + item)).join(', ')
                return values
            } else {
                values = filterValue.join(', ')
            }
            return values
        }
        return values
    }

    const onChangeFilterValues = (fieldName: string, title: string, filterValue: string[] | null) => {
        const alreadyExists = currentFilter.length > 0 && currentFilter.find(item => item.fieldName === fieldName)
        if (alreadyExists) {
            setCurrentFilter(prevState => {
                return [...prevState].map(item => {
                    if (item.fieldName === fieldName) {
                        return {...item, filterValue: filterValue}
                    } else return {...item}
                })
            })
        } else {
            setCurrentFilter(prevState => {
                const result: TCurrentFilterValues = [...prevState]
                return [...result, {title: title, fieldName: fieldName, sortType: 'NONE', filterValue: filterValue}]
            })
        }
        setFilterOrSortValues(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [fieldName]: filterValue
            }
        }))
    }

    const deleteFilter = (fieldName: string) => {
        setCurrentFilter(prevState => {
            return [...prevState].map(item => {
                if (item.fieldName === fieldName) {
                    return {...item, filterValue: null}
                } else return {...item}
            })
        })
        setFilterOrSortValues(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [fieldName]: null
            }
        }))
    }

    const getCurrentFilterValues = (fieldName: string) => {
        const result = currentFilter.find(item => item.fieldName === fieldName)?.filterValue
        if (!!result) {
            return result
        } else return []
    }

    const changeGroup = () => {
        req.post('/moderator/settings/groups/set', currentGroup)
            .then(({data}) => {
                setCurrentGroup(data)
                interfaceCTX.checkIsFormChanged(false, 'settings')
                setIsLoading(false)
                getProfessionGroup()
                history.replace({
                    pathname: 'redact-group', state: [data]
                })
                interfaceCTX.showMessage({
                    body: `Группа успешно сохранена`,
                    icon: 'done'
                })
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response?.data)
                })
            })
    }

    const toggleShowOnlySelected = (bool: boolean) => {
        if (!bool) {
            setFilterOrSortValues(prevState => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    ids: selectedProfessions
                }
            }))
            setShowOnlySelected(true)
        } else {
            setFilterOrSortValues(prevState => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    ids: null
                }
            }))
            setShowOnlySelected(false)
        }
    }

    const scrollHandler = (e: React.UIEvent<HTMLElement>) => {
        //@ts-ignore
        if (e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + scroller.current.offsetHeight) < 100) {
            if (!professionsList) return
            if (total > professionsList.mutable.length) {
                setFetching(true)
            }
        }
    }

    const getProfessionsList = (options?: any) => {
        setIsLoading(true)
        req.post(`/moderator/settings/professions/all?limit=50&offset=0`, options ? {
            ...filterOrSortValues,
            filter: {...filterOrSortValues.filter, category: [options.category]}
        } : filterOrSortValues)
            .then(({data}) => {
                setProfessionsList({
                    mutable: data.data,
                    static: data.data,
                })
                setTotal(data.total)
                setIsLoading(false)
                setLimit(50)
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response?.data)
                })
            })
    }

    const getProfessionGroup = () => {
        if (Array.isArray(history.location.state)) {
            const groupData = history.location.state.find((item: any) => item.category)
            if (groupData.id) {
                req.get(`/moderator/settings/groups/get?groupId=${groupData.id}`)
                    .then(({data}) => {
                        setSelectedProfessions(data.professionsIds)
                        setCurrentGroup(data);
                        getProfessionsList(groupData)
                        setIsFirstStart(true)
                    })
                    .catch((e) => {
                        interfaceCTX.showMessage({
                            title: 'Ошибка',
                            body: t('error.' + e.response?.data)
                        })
                    })
            } else {
                setCurrentGroup(prevState => ({
                    ...prevState,
                    ...groupData
                }))
                getProfessionsList(groupData)
                setIsFirstStart(true)
            }
        }

    }

    useEffect(() => {
        if (fetching && isFirstStart) {
            const newLimit = limit + 20
            req
                .post(`/moderator/settings/professions/all?limit=${newLimit}&offset=0`, {
                    ...filterOrSortValues,
                    filter: {...filterOrSortValues.filter, category: [currentGroup.category]}
                })
                .then(({data}) => {
                    setProfessionsList({
                        static: data.data,
                        mutable: data.data
                    })
                    setTotal(data.total)
                    setLimit(newLimit)
                })
                .catch((e) => {
                    interfaceCTX.showMessage({
                        title: 'Ошибка',
                        body: t('error.' + e.response?.data)
                    })
                })
                .finally(() => setFetching(false))
        }
    }, [fetching])

    useEffect(() => {
        if (isFirstStart) {
            getProfessionsList(currentGroup)
        }
    }, [filterOrSortValues])

    useEffect(() => {
        getProfessionGroup()
        return () => {
            interfaceCTX.checkIsFormChanged(false, 'settings')
        }
    }, [])

    useAutoCloseDialog(() => {
        setIsMenuFilterOpen('')
    }, [])

    return (
        <div className={'group-changing'}>
            <ProgressPage
                state={[]}
                render={() => (
                    <>
                        <div className={'professions-top'}>
                            <div className='sorted-value'>
                                {currentFilter.map((item, index) => {
                                    if (item.sortType !== 'NONE') {
                                        return (
                                            <div className={'sort'} key={item.fieldName + index + 'sort'}>
                                                <div className='sorted-item'>
                                                    <div className='column_name'>{item.title}:</div>
                                                    <div
                                                        className='type'>({item.sortType === 'ASC' ? 'А - Я' : 'Я - А'})
                                                    </div>
                                                </div>
                                                <Icon
                                                    className='cancel'
                                                    icon={'cancel'}
                                                    onClick={() => sortTable(item.title, item.fieldName, item.sortType)}
                                                />
                                            </div>
                                        )
                                    }
                                })}
                                {currentFilter.map((item, index) => {
                                    if (item.filterValue) {
                                        return (
                                            <div className='sort' key={item.fieldName + index + 'filter'}>
                                                <div className='sorted-item'>
                                                    <div className='column_name'>{item.title}:</div>
                                                    <Tooltip
                                                        content={
                                                            <div
                                                                style={{
                                                                    maxWidth: '250px',
                                                                    maxHeight: '100px',
                                                                    overflow: 'hidden'
                                                                }}
                                                            >
                                                                {renderFilterValue(item.filterValue, item.fieldName)}
                                                            </div>
                                                        }
                                                    >
                                                        <div className='type' style={{cursor: 'help'}}>
                                                            {renderFilterValue(item.filterValue, item.fieldName)}
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                                <Icon
                                                    className='cancel'
                                                    icon={'cancel'}
                                                    onClick={() => deleteFilter(item.fieldName)}
                                                />
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div>
                                <TextField
                                    maxLength={125}
                                    outlined
                                    style={{width: '100%'}}
                                    className={'profession-group-textfield'}
                                    placeholder={"Введите название группы"}
                                    value={currentGroup.professionGroupName}
                                    onChange={(e) => {
                                        const value = e.currentTarget.value
                                        interfaceCTX.checkIsFormChanged(true, 'settings')
                                        if (value === " ") return
                                        if (value !== '' && !(/^[?!,/\-.а-яА-ЯёЁ\s]+$/).test(value)) return
                                        setCurrentGroup(prevState => ({
                                            ...prevState,
                                            professionGroupName: !!value ? value : ''
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                        <div className='custom-table-wrapper' ref={scroller} onScroll={scrollHandler}>
                            {fetching && <LoadingProgress style={{
                                right: "4rem",
                                bottom: "9rem",
                                zIndex: "99999"
                            }}/>}
                            <Table className="custom-table">
                                <div className="headings heading custom-table-header">
                                    {headCells.map((cell, index) => {
                                        return (
                                            <div key={cell.id} className={"devider custom-table-header-item"}
                                                 style={index === 0 ? {maxWidth: cell.minWidth} : {minWidth: cell.minWidth}}>
                                                <div className="table-cell-inner custom-table-header-inner">
                                                    {index !== 0 &&
                                                        <div className='expands'>
                                                            <Icon className={`icons expand `}
                                                                  icon={"arrow_drop_up"}
                                                                  style={{color: isSorted(cell.fieldName, 'ASC') ? 'red' : ''}}
                                                                  onClick={(event) => {
                                                                      sortTable(cell.title, cell.fieldName, "ASC")
                                                                  }}/>
                                                            <Icon className={`icons expand `}
                                                                  icon={"arrow_drop_down"}
                                                                  style={{color: isSorted(cell.fieldName, 'DESC') ? 'red' : ''}}
                                                                  onClick={(event) => {
                                                                      sortTable(cell.title, cell.fieldName, 'DESC')
                                                                  }}/>
                                                        </div>
                                                    }
                                                    <div className='name'>{cell.title}</div>
                                                    {(index === 1 || index === 2) &&
                                                        <>
                                                            <Icon className={`icons`}
                                                                  icon={"more_horiz"}
                                                                  onClick={(event) => {
                                                                      event.stopPropagation()
                                                                      setIsMenuFilterOpen(prevState => prevState === cell.fieldName ? '' : cell.fieldName)
                                                                  }}/>
                                                            {isMenuFilterOpen === cell.fieldName && currentGroup.category !== null &&
                                                                <MenuFilter
                                                                    isMenuFilterOpen={isMenuFilterOpen}
                                                                    onCloseMenuFilterOpen={() => {
                                                                        setIsMenuFilterOpen('')
                                                                    }}
                                                                    onChangeFilterValues={onChangeFilterValues}
                                                                    uniqueValues={[]}
                                                                    columnTitle={cell.title}
                                                                    columnType={cell.fieldName}
                                                                    currentFilter={getCurrentFilterValues(cell.fieldName)}
                                                                    getApiUrl={'/moderator/settings/professions/all'}
                                                                    request={{category: [currentGroup.category]}}
                                                                />
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {isLoading
                                    ? <CircularProgress
                                        className='circular-progress'
                                        style={{
                                            position: 'absolute',
                                            top: '290px',
                                            left: '50%'
                                        }}
                                    />
                                    : <>
                                        {professionsList.mutable.map((profession: IProfessionItem, index) => {
                                            return (
                                                <TableRow type='row' key={`${profession.id} ${index}`}
                                                          style={{minHeight: '65px'}}>
                                                    <TableCell
                                                        devider
                                                        style={{
                                                            maxWidth: columns[0].minWidth
                                                        }}
                                                    >
                                                        <Checkbox
                                                            className={currentGroup.professionsIds.includes(profession.id) ? 'horosiy-checkbox' : 'off-checkbox'}
                                                            onChange={e => {
                                                                interfaceCTX.checkIsFormChanged(true, 'settings')
                                                                if (currentGroup.professionsIds.includes(profession.id)) {
                                                                    setCurrentGroup(prevState => ({
                                                                        ...prevState,
                                                                        professionsIds: [...prevState.professionsIds.filter((num: number) => num !== profession.id)]
                                                                    }))
                                                                } else {
                                                                    setCurrentGroup(prevState => ({
                                                                        ...prevState,
                                                                        professionsIds: [...prevState.professionsIds, profession.id]
                                                                    }))
                                                                }
                                                            }}
                                                            checked={currentGroup.professionsIds.includes(profession.id)}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        devider
                                                        style={{
                                                            minWidth: columns[1].minWidth
                                                        }}
                                                    >
                                                        <div style={{
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                            wordWrap: 'break-word'
                                                        }}
                                                        >
                                                            {profession.professionName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        devider
                                                        style={{
                                                            minWidth: columns[2].minWidth
                                                        }}
                                                    >
                                                        {profession.professionCode}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </>
                                }
                            </Table>
                        </div>
                        <div className={'professions-bottom'}>
                            <FixedButtons
                                className={'buttons'}
                                length={1}
                                buttons={[
                                    {
                                        label: 'СОХРАНИТЬ',
                                        primary: true,
                                        disabled: (!currentGroup.professionGroupName.trim() || isLoading),
                                        className: 'button',
                                        onClick: () => {
                                            setIsLoading(true)
                                            changeGroup()
                                        }
                                    },
                                    {
                                        label: 'Отменить',
                                        primary: false,
                                        outlined: false,
                                        className: 'button',
                                        onClick: () => {
                                            setCurrentGroup(prevState => ({
                                                ...prevState,
                                                professionsIds: selectedProfessions
                                            }))
                                        }
                                    }
                                ]}
                            />
                            {currentGroup.id &&
                                <div className={'group-changing-visible-selected'}>
                                    <Checkbox
                                        checked={showOnlySelected}
                                        disabled={isLoading}
                                        onChange={() => {
                                            if (interfaceCTX.isFormChanged.settings) {
                                                if (window.confirm('Все несохраненные данные будут удалены. Вы уверены?')) {
                                                    toggleShowOnlySelected(showOnlySelected)
                                                    setCurrentGroup(prevState => ({
                                                        ...prevState,
                                                        professionsIds: selectedProfessions
                                                    }))
                                                    interfaceCTX.checkIsFormChanged(false, 'settings')
                                                }
                                            } else {
                                                toggleShowOnlySelected(showOnlySelected)
                                            }
                                        }}
                                    />
                                    <div className={'noSelect'}>Отображать только выбранные</div>
                                </div>
                            }
                        </div>

                    </>
                )}
            />
        </div>
    )
}