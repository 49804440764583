import equal from 'fast-deep-equal'
import { WorkerProfile } from 'global/definitions'
import { WorkerProfileExperienceOption } from '../../worker-profile-def'

export const mapExperienceByProductionAndUnit = (
	experience: WorkerProfile['experience']
): WorkerProfileExperienceOption[] => {
	const groupedExperiencesByProductionAndUnit: Record<string, WorkerProfileExperienceOption> = {}
	const keys: string[] = []
	experience?.forEach((exp, i) => {
		const { expMonths, expYears, production, type, unit } = exp
		const key = String(production) + String(unit)
		if (!groupedExperiencesByProductionAndUnit[key]) {
			keys.push(key)
			groupedExperiencesByProductionAndUnit[key] = {
				production,
				unit
			} as WorkerProfileExperienceOption
		}
		if (type) {
			groupedExperiencesByProductionAndUnit[key] = {
				...groupedExperiencesByProductionAndUnit[key],
				[type]: { expMonths, expYears }
			}
		}
	})

	return keys.map((key: string) => groupedExperiencesByProductionAndUnit[key])
}

export const mapExperienceByType = (
	options?: WorkerProfileExperienceOption[]
): NonNullable<WorkerProfile['experience']> => {
	const allExperience: WorkerProfile['experience'] = []
	options?.forEach(exp => {
		const { production, unit, ...types } = exp

		Object.entries(types)
			.filter(([_, value]) => Boolean(value))
			.forEach(([type, typeValue]) => {
				//@ts-ignore
				const { expMonths, expYears } = typeValue || {}
				allExperience.push({
					production,
					unit,
					type,
					expMonths: Number(expMonths),
					expYears: Number(expYears)
				})
			})
	})

	return allExperience
}

export const isDuplicatedExperience = (data: WorkerProfileExperienceOption[]) => {
	for (let i = 0; i < data.length; i++) {
		for (let j = 1; j <= data.length; j++) {
			if (equal(data[i], data[j]) && i !== j) return true
		}
	}

	return false
}
