import {LinearProgress} from "@rmwc/linear-progress";
import React from "react";
import {useTranslation} from "react-i18next";
import "./Need.style.sass";
import {INeedCard} from "./Need.types";


const statusesToShow = [
    "pending",
    "documents_preparation",
    "documents_undelivered",
    "signature_awaiting",
    "signed",
    "accepted",
    "documents_awaiting",
    "documents_confirmed"
];

export function authorNameShort(author: any) {
    return `${author.lastName ? author.lastName : ''} ${author.firstName ? author.firstName[0] : ''}. ${author.middleName ? author.middleName[0] : ''}`
}

export function NeedCard(props: INeedCard) {
    const {t} = useTranslation("common");

    function declOfNum(n: number, text_forms: string[]) {
        n = Math.abs(n) % 100;
        let n1 = n % 10;
        if (n > 10 && n < 20) {
            return text_forms[2];
        }
        if (n1 > 1 && n1 < 5) {
            return text_forms[1];
        }
        if (n1 == 1) {
            return text_forms[0];
        }
        return text_forms[2];
    }

    React.useEffect(() => {
    }, []);

    return (
        <div className="need-wrapper" onClick={props.onClick}>
            {props.need.state === 'published' &&
                <div
                    style={{color: props.need.daysBeforeTheEndPublication <= 3 ? '#C81F1F' : ''}}
                    className={'need-end-of-publication'}
                >
                    До окончания
                    публикации: {props.need.daysBeforeTheEndPublication + declOfNum(props.need.daysBeforeTheEndPublication, [' день', ' дня', ' дней'])}
                </div>
            }
            <div className="need-params-wrapper">
                <div className="date-and-status-wrapper need-detail  gray">
                    <div>{t("request.state." + props.need.state)}</div>
                    <div
                        className={"bold"}>{props.need.publicationDate ? props.need.publicationDate : props.need.date}</div>
                </div>
                {/* <div className="initiator-wrapper"> */}
                <div className="initiator need-detail gray">
                    <div className={"title"}>Инициатор</div>
                    <div className={"text bold"}>
                        {authorNameShort(props.need!.author)}
                    </div>
                </div>
                <div className="number need-detail gray">
                    <div className={"title"}>Номер заявки</div>
                    <div className={"text bold"}>
                        {props.need.number}
                    </div>
                </div>

                <div className="position need-detail gray">
                    <div className={"title"}>Должность кандидата</div>
                    <div className={"text bold"}>
                        {props.need!.position ? props.need!.position : "Не указано"}
                    </div>
                </div>
                {/* </div> */}
            </div>
            <div className="quantity-total need-row bold">
                Вакансий в заявке {props.need!.quantity ? props.need!.quantity : 0}
            </div>
            <div className="fill-progress need-row">
                <div className="title">
                    Заполненность{" "}
                    {props.need!.quantity
                        ? (
                            (Number(props.need.responses.accepted) /
                                Number(props.need!.quantity)) *
                            100
                        ).toFixed()
                        : 0}
                    %
                </div>
                <LinearProgress
                    progress={
                        Number(props.need.responses.accepted) / Number(props.need!.quantity)
                    }
                />
            </div>
            <div className="response-statuses-wrapper">
                {statusesToShow.map((status) => {
                    return props.need.responses[status] ? (
                        <div className={`${status} response-status`} key={status}>
                            <div className={"title"}>{t("response." + status)}</div>
                            <div className={"count"}>{props.need.responses[status]}</div>
                        </div>
                    ) : (
                        <div className={`${status} response-status`} key={status}>
                            <div className={"title"}>{t("response." + status)}</div>
                            <div className={"count"}>{0}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
