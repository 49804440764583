import {IColumnSettings} from "./ExtendedCustomTable.types";
import * as XLSX from "sheetjs-style";

export const headerTableTitleStyle = {
    font: {
        name: "arial",
        sz: 16,
        bold: true
    },
    fill: {
        fgColor: {
            rgb: "FFFFFF"
        }
    },
    alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: '1',
    },
    border: {
        right: {
            style: "medium",
            color: "000000"
        },
        left: {
            style: "thin",
            color: "000000"
        },
        bottom: {
            style: "medium",
            color: "000000"
        }
    }
}

export const settingsStyle = {
    font: {
        name: "arial",
        sz: 11,
    },
    fill: {
        fgColor: {
            rgb: "FFFFFF"
        }
    },
    alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: '1',
    },
    border: {
        right: {
            style: "medium",
            color: "000000"
        },
        left: {
            style: "thin",
            color: "000000"
        },
        bottom: {
            style: "medium",
            color: "000000"
        },
        top: {
            style: "medium",
            color: "000000"
        }
    }
}

export const headerTableStyle = {
    font: {
        name: "arial",
        sz: 12,
        bold: true
    },
    fill: {
        fgColor: {
            rgb: "DEE7EF"
        }
    },
    alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: '1',
    },
    border: {
        right: {
            style: "medium",
            color: "000000"
        },
        left: {
            style: "thin",
            color: "000000"
        },
        bottom: {
            style: "medium",
            color: "000000"
        },
        top: {
            style: "medium",
            color: "000000"
        }
    }
}

export const bodyTableStyle = {
    font: {
        name: "arial",
        sz: 12,
    },
    fill: {
        fgColor: {
            rgb: "FFFFFF"
        }
    },
    alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: '1',
    },
    border: {
        right: {
            style: "thin",
            color: "000000"
        },
        left: {
            style: "thin",
            color: "000000"
        },
        bottom: {
            style: "thin",
            color: "000000"
        }
    }
}

export const downloadEXCELTable = (tableValues: any[], columnSettings: IColumnSettings[], fileName: string, formattedTableValues: (columnSettings: IColumnSettings[], thereIs: (value: any) => any, tableValues: any[]) => any[], numbered?: boolean) => {
    const thereIs = (value: any) => {
        if (!!value && value !== 0) {
            return value
        } else if (value === 0) {
            return "0"
        } else return ' '
    }

    const tableSettings = () => {
        let resultFilter = ['Отфильтровано по: ']
        let resultSort = ['Сортировано по: ']
        columnSettings.forEach((setting, index) => {
            if (setting.filterType) {
                resultFilter.push(setting.title + ', ')
            }
            if (setting.sortType !== 'NONE') {
                resultSort.push(setting.title)
            }
        })

        return [resultFilter.join(''), '', '', resultSort.join('')]
    }

    const columnsTable: IColumnSettings[] = []
    columnSettings.forEach((columnSetting) => {
        if (columnSetting.visibility) return columnsTable.push(columnSetting)
    })

    var workbook = XLSX.utils.book_new()
    var worksheet = XLSX.utils.aoa_to_sheet(
        [
            [fileName],
            tableSettings(),
            columnsTable.map((c: any, i) => c.title),
            ...formattedTableValues(columnSettings, thereIs, tableValues).map((c: any) => Object.values(c))
        ]
    )

    for (let i in worksheet) {
        if (typeof (worksheet[i]) != "object") continue;
        if ((/^[A-Z]1+$\b/).test(i) && i.length === 2) {
            worksheet[i].s = headerTableTitleStyle
        } else if ((/^[A-Z]2+$\b/).test(i) && i.length === 2) {
            //Стили заголовков
            worksheet[i].s = settingsStyle
        } else if ((/^[A-Z]3+$\b/).test(i) && i.length === 2) {
            //Стили заголовков
            worksheet[i].s = headerTableStyle
        } else {
            //Стили остальной части
            worksheet[i].s = bodyTableStyle
        }

    }

    worksheet["!cols"] = columnSettings.map((c: any, i) => {
        if (i === 0 && numbered) {
            return {wch: 5}
        } else {
            return {wch: 30}
        }
    })

    worksheet["!rows"] = columnSettings.map((c: any, i) => {
        if (i === 0 && numbered) {
            return {hpt: 50}
        } else if (i === 1) {
            return {hpt: 60}
        } else {
            return {hpt: 30}
        }
    })

    worksheet['!merges'] = [{s: {c: 0, r: 0}, e: {c: 1, r: 0}}, {s: {c: 0, r: 1}, e: {c: 2, r: 1}}]
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName)
    XLSX.writeFile(workbook, `${fileName}.xlsx`, {bookType: 'xlsx', cellStyles: true})
}

