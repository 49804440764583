import './SurveyList.style.sass'
import { FixedButtons, ProgressPage } from '../../../../../../components'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { req } from '../../../../../../global/common'
import { ISurveyListItem } from '../SurveyItem/SurveyItem.types'
import { Icon } from '@rmwc/icon'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'global/context.user'
import { getSurveyStatus } from './SurveyList.service'

export const SurveyList: React.FC = () => {
	const history = useHistory()
	const [surveyList, setSurveyList] = useState<ISurveyListItem[]>([])
	const { t } = useTranslation('common')
	const { user } = useContext(UserContext)

	const isModerator = user?.roles.includes('moderator')
	


	useEffect(() => {
		req.get('/moderator/survey/list').then(({ data }) => {
			setSurveyList(data.data)
		})
	}, [])

	return (
		<div className={'survey-list'}>
			<ProgressPage
				state={surveyList}
				render={() => (
					<>
						<div className={'survey-list-top'}>
							<FixedButtons
								className={'buttons'}
								length={1}
								buttons={[
									{
										label: 'Создать',
										primary: true,
										disabled: false,
										onClick: () => {
											history.push({
												pathname: 'item',
												state: []
											})
										}
									}
								]}
							/>
						</div>
						<div className={'survey-list-body'}>
							{surveyList.map(item => (
								<div
									className={'survey-list-item'}
									onClick={() => {
										history.push({
											pathname: 'item',
											state: [{ id: item.id }]
										})
									}}
									key={item.id}
								>
									<div className={'survey-list-item-container'}>
										<div className={'survey-list-item-title-container'}>
											<div className={'survey-list-item-title'}>{item.topic}</div>
											<div>{getSurveyStatus(item.dateFrom, item.dateTo)}</div>
										</div>
										<div className={'survey-list-item-roles'}>
											{item.roles.map((role: string, index) => {
												if (index === item.roles.length - 1) {
													return t('role.' + role)
												} else {
													return t('role.' + role) + ', '
												}
											})}
										</div>
										{isModerator && <div className={'survey-list-item-description'}>{item.description}</div>}
										<div className={'survey-list-item-period'}>
											Доступен с {item.dateFrom} до {item.dateTo}
										</div>
									</div>
									<Icon icon={'chevron_right'} />
								</div>
							))}
						</div>
					</>
				)}
			/>
		</div>
	)
}
