import { profileApi } from 'global/api'
import { useEffect, useState } from 'react'
import { isAllProfileIsFilling } from '../auth-as-worker-utils'

export const useProfileFilling = () => {
	const [isProfileFilling, setIsProfileFilling] = useState(false)
	const [isSuccess, setIsSucess] = useState(false)

	useEffect(() => {
		profileApi
			.getWorkerProfileFilling()
			.then(response => {
				setIsProfileFilling(isAllProfileIsFilling(response.data))
				setIsSucess(true)
			})
			.catch(e => {
				setIsSucess(false)
			})
	}, [])

	return { isProfileFilling, isSuccess }
}
