import clsx from 'clsx'
import { PagesRoutes } from 'global/routes'
import { getShortInitials } from 'global/utils'
import { RequestRangeField } from 'pages/auth-as-worker'
import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ContractCardProps } from './contract-card-def'
import './contract-card.sass'

export const ContractCard: FC<ContractCardProps> = ({ className, contract, ...rest }): JSX.Element => {
	const location = useLocation()

	return (
		<Link className={className} to={{ pathname: `${PagesRoutes.CONTRACT_LIST}/${contract.id}`, state: location.state }}>
			<div className={clsx('contract-card')} {...rest}>
				<div className='contract-card-date-wrapper'>
					<span>Дата заключения договора</span>
					<span className='contract-card-date'>{contract.requestAcceptDate}</span>
				</div>
				<div className='contract-card-position'>{contract.position}</div>
				<div className='contract-card-number'>{contract.requestNumber}</div>
				<div className='contract-card-organisation'>{contract.organisation.shortName}</div>
				<RequestRangeField
					className='contract-card-period'
					title='Срок действия договора'
					valueFrom={contract.workFrom}
					valueTo={contract.workTo}
				/>
				<div>
					<div>Непосредственный руководитель</div>
					<div>
						{getShortInitials(
							contract.supervisor?.lastName,
							contract.supervisor?.firstName,
							contract.supervisor?.middleName
						)}
					</div>
				</div>
			</div>
		</Link>
	)
}
