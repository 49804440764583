import clsx from 'clsx'
import { getSelectStyles } from 'components/FormSelect'
import { forwardRef, MutableRefObject } from 'react'
import { GroupBase, SelectInstance } from 'react-select'
import { AsyncPaginate, AsyncPaginateProps } from 'react-select-async-paginate'
import { AsyncSelectComponentType } from './AsyncFormSelect.types'

export const AsyncFormSelect = forwardRef(
	<
		Option,
		IsMulti extends boolean = false,
		Additional extends boolean = false,
		Group extends GroupBase<Option> = GroupBase<Option>
	>(
		{ className, styles, title, components, ...rest }: AsyncPaginateProps<Option, Group, Additional, IsMulti>,
		ref:
			| ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
			| MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
			| null
	): JSX.Element => {
		return (
			<div className={clsx('asyncFormSelect', className)}>
				{title && <div className='form-field-title'>{title}</div>}
				<AsyncPaginate
					selectRef={ref}
					loadingMessage={() => 'Загрузка...'}
					noOptionsMessage={() => 'Нет опций'}
					styles={{ ...getSelectStyles<Option, IsMulti, Group>(), ...styles }}
					components={{ IndicatorSeparator: () => null, ...components }}
					{...rest}
				/>
			</div>
		)
	}
) as AsyncSelectComponentType
