import React, {FC, MutableRefObject, useCallback, useRef} from "react";
import {Form, SpanButtons} from "components";
import {AboutMeFormProps} from "./garments-form-def";
import NumberFormat from "react-number-format";
import {Controller} from "react-hook-form";
import "./garments-form.sass";
import {ErrorWrapper} from "../error-wrapper/error-wrapper";

export const GarmentsForm: FC<AboutMeFormProps> = ({
                                                       anthropometry,
                                                       onClickClose,
                                                       onSubmit,
                                                   }) => {
    const {headGirth, bustGirth, waistGirth, height, hipsGirth, shoeSize} =
        anthropometry;

    const formRef = useRef() as MutableRefObject<HTMLFormElement>;

    const handleCancel = useCallback(() => {
        onClickClose(3);
    }, []);

    const handleSubmit = useCallback(
        (values) => {
            onSubmit?.({
                anthropometry: {
                    ...anthropometry,
                    bustGirth: Number(values.bustGirth),
                    headGirth: Number(values.headGirth),
                    height: Number(values.height),
                    hipsGirth: Number(values.hipsGirth),
                    shoeSize: Number(values.shoeSize),
                    waistGirth: Number(values.waistGirth),
                },
            });
        },
        [onSubmit]
    );
    return (
        <Form
            className="anthropometry-form"
            name="workerProfile"
            onSubmit={handleSubmit}
            viewMode={false}
            useRef={formRef}
            data={[
                [
                    {
                        values: [
                            {
                                type: "other",
                                colSpan: -1,
                                value: () => (
                                    <div className="anthropometry-form-buttons-save">
                                        <div className="button-save">
                                            <SpanButtons
                                                data={[
                                                    {
                                                        label: "Не сохранять",
                                                        onClick: handleCancel,
                                                    },
                                                ]}
                                            />
                                        </div>
                                        <SpanButtons
                                            data={[
                                                {
                                                    label: "Сохранить изменения",
                                                    onClick: () => {
                                                        formRef.current.dispatchEvent(
                                                            new Event("submit", {
                                                                cancelable: true,
                                                                bubbles: true,
                                                            })
                                                        );
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                ),
                            },
                        ],
                    },
                    {
                        values: [
                            {
                                type: "other",
                                colSpan: 1,
                                watchVar: "headGirth",
                                title: "Обхват головы, см",
                                defaultValue: headGirth,
                                value: (e, headGirthValue, {control}) => {
                                    return (
                                        <Controller
                                            name="headGirth"
                                            control={control}
                                            rules={{
                                                min: {
                                                    value: 52,
                                                    message: "Значения менее 52 не принимаются",
                                                },
                                                max: {
                                                    value: 64,
                                                    message: "Значения более 64 не принимаются",
                                                },
                                            }}
                                            render={({field, fieldState}) => {
                                                return (
                                                    <ErrorWrapper error={fieldState.error}>
                                                        {/*//@ts-ignore*/}
                                                        <NumberFormat
                                                            {...field}
                                                            className="anthropometry-input"
                                                            maxLength={4}
                                                            decimalSeparator={"." || "."}
                                                        />
                                                    </ErrorWrapper>
                                                );
                                            }}
                                        />
                                    );
                                },
                            },
                            {
                                type: "other",
                                colSpan: 1,
                                watchVar: "bustGirth",
                                title: "Обхват груди, см",
                                defaultValue: bustGirth,
                                value: (e, bustGirthValue, {control}) => {
                                    return (
                                        <Controller
                                            name="bustGirth"
                                            control={control}
                                            rules={{
                                                min: {
                                                    value: 86,
                                                    message: "Значения менее 86 не принимаются",
                                                },
                                                max: {
                                                    value: 158,
                                                    message: "Значения более 158 не принимаются",
                                                },
                                            }}
                                            render={({field, fieldState}) => {
                                                return (
                                                    <ErrorWrapper error={fieldState.error}>
                                                        {/*//@ts-ignore*/}
                                                        <NumberFormat
                                                            {...field}
                                                            className="anthropometry-input"
                                                            maxLength={5}
                                                            decimalSeparator={"." || "."}
                                                        />
                                                    </ErrorWrapper>
                                                );
                                            }}
                                        />
                                    );
                                },
                            },
                            {
                                type: "other",
                                colSpan: 1,
                                watchVar: "waistGirth",
                                title: "Обхват талии, см",
                                defaultValue: waistGirth,
                                value: (e, waistGirthValue, {control}) => {
                                    return (
                                        <Controller
                                            name="waistGirth"
                                            control={control}
                                            rules={{
                                                min: {
                                                    value: 76,
                                                    message: "Значения менее 76 не принимаются",
                                                },
                                                max: {
                                                    value: 148,
                                                    message: "Значения более 148 не принимаются",
                                                },
                                            }}
                                            render={({field, fieldState}) => {
                                                return (
                                                    <ErrorWrapper error={fieldState.error}>
                                                        {/*//@ts-ignore*/}
                                                        <NumberFormat
                                                            {...field}
                                                            className="anthropometry-input"
                                                            maxLength={5}
                                                            decimalSeparator={"." || "."}
                                                        />
                                                    </ErrorWrapper>
                                                );
                                            }}
                                        />
                                    );
                                },
                            },
                        ],
                    },
                    {
                        values: [
                            {
                                type: "other",
                                colSpan: 1,
                                watchVar: "height",
                                title: "Рост, см",
                                defaultValue: height,
                                value: (e, heightValue, {control}) => {
                                    return (
                                        <Controller
                                            name="height"
                                            control={control}
                                            rules={{
                                                min: {
                                                    value: 130,
                                                    message: "Значения менее 130 не принимаются",
                                                },
                                                max: {
                                                    value: 210,
                                                    message: "Значения более 210 не принимаются",
                                                },
                                            }}
                                            render={({field, fieldState}) => {
                                                return (
                                                    <ErrorWrapper error={fieldState.error}>
                                                        {/*//@ts-ignore*/}
                                                        <NumberFormat
                                                            {...field}
                                                            className="anthropometry-input"
                                                            maxLength={3}
                                                        />
                                                    </ErrorWrapper>
                                                );
                                            }}
                                        />
                                    );
                                },
                            },
                            {
                                type: "other",
                                colSpan: 1,
                                watchVar: "shoeSize",
                                title: "Размер ноги, см",
                                defaultValue: shoeSize,
                                value: (e, shoeSizeValue, {control}) => {
                                    return (
                                        <Controller
                                            name="shoeSize"
                                            control={control}
                                            rules={{
                                                min: {
                                                    value: 22,
                                                    message: "Значения менее 22 не принимаются",
                                                },
                                                max: {
                                                    value: 35,
                                                    message: "Значения более 35 не принимаются",
                                                },
                                            }}
                                            render={({field, fieldState}) => {
                                                return (
                                                    <ErrorWrapper error={fieldState.error}>
                                                        {/*//@ts-ignore*/}
                                                        <NumberFormat
                                                            {...field}
                                                            className="anthropometry-input"
                                                            maxLength={4}
                                                            decimalSeparator={"." || "."}
                                                        />
                                                    </ErrorWrapper>
                                                );
                                            }}
                                        />
                                    );
                                },
                            },
                        ],
                    },
                ],
            ]}
        />
    );
};
