import {
	ArchiveRequestListPage,
	ContractEmploymentProcess,
	ContractListPage,
	ContractPage,
	EmploymentProcessPage,
	RequestPage,
	ResponseInfoListPage,
	TimeKeepingPage,
	WorkerRequestListPage
} from 'pages/auth-as-worker'
import { WorkerPublicProfile } from 'pages/auth-as-worker/profile/worker-profile'
import {Signin} from 'pages/Signin/Signin'
import { Route } from './routes-def'
import { PagesRoutes } from './routes-utils'

export const workerProfileRoutes: Route[] = [
	// Работник
	{
		title: 'Регистрация Работника',
		path: '/worker/register',
		component: <Signin />,
		navBar: false,
		icon: 'false'
	},
	{
		title: 'Профиль',
		path: '/worker-profile',
		component: <WorkerPublicProfile />,
		navBar: false,
		availableRoles: ['worker']
	},
	{
		title: 'Список потребностей',
		path: '/worker-need-list',
		component: <WorkerRequestListPage />,
		icon: 'format_list_bulleted',
		navBar: true,
		availableRoles: ['worker']
	},
	{
		title: 'Потребность',
		path: PagesRoutes.WORKER_NEED_LIST + '/:requestId',
		component: <RequestPage />,
		navBar: false,
		availableRoles: ['worker']
	},
	{
		title: 'Процесс трудоустройства',
		path: PagesRoutes.WORKER_NEED_LIST + '/:requestId/employment-process',
		component: <EmploymentProcessPage />,
		navBar: false,
		availableRoles: ['worker']
	},
	{
		title: 'Архив',
		path: PagesRoutes.WORKER_NEED_LIST + '/archive',
		component: <ArchiveRequestListPage />,
		navBar: false,
		availableRoles: ['worker']
	},
	{
		title: 'Направлено откликов',
		path: PagesRoutes.WORKER_NEED_LIST + '/pending',
		component: <ResponseInfoListPage requestStatus='pending' />,
		navBar: false,
		availableRoles: ['worker']
	},
	{
		title: 'На стадии перговоров',
		path: PagesRoutes.WORKER_NEED_LIST + '/consideration',
		component: <ResponseInfoListPage requestStatus='consideration' />,
		navBar: false,
		availableRoles: ['worker']
	},
	{
		title: 'Отказ в приеме',
		path: PagesRoutes.WORKER_NEED_LIST + '/rejected',
		component: <ResponseInfoListPage requestStatus='rejected' />,
		navBar: false,
		availableRoles: ['worker']
	},
	{
		title: 'Текущая работа',
		path: PagesRoutes.CONTRACT_LIST,
		component: <ContractListPage />,
		icon: 'person',
		navBar: true,
		availableRoles: ['worker']
	},
	{
		title: 'Работа',
		path: PagesRoutes.CONTRACT_LIST + '/:workerRecordId',
		component: <ContractPage />,
		navBar: false,
		availableRoles: ['worker']
	},
	{
		title: 'Процесс мобилизации',
		path: PagesRoutes.CONTRACT_LIST + '/:workerRecordId/employment-process',
		component: <ContractEmploymentProcess />,
		navBar: false,
		availableRoles: ['worker']
	},
	{
		title: 'Учет рабочего времени',
		path: '/worker-time-keeping',
		component: <TimeKeepingPage />,
		navBar: true,
		icon: 'assignment',
		availableRoles: ['worker']
	}
]
