import { FC, MutableRefObject, useCallback, useRef } from 'react'
import {SpanButtons, Form} from 'components'
import { AboutMeFormProps } from './about-me-form-def'
import './about-me-form.sass'
import { getObjValueByName } from './utils'

const ALL_STATUSES_WORK = [
	{
		label: 'Не работаю, рассматриваю предложения',
		value: 'Не работаю, рассматриваю предложения'
	},
	{
		label: 'Трудоустроен, рассматриваю предложения',
		value: 'Трудоустроен, рассматриваю предложения'
	},
	{
		label: 'Трудоустроен, не рассматриваю предложения',
		value: 'Трудоустроен, не рассматриваю предложения'
	}
]

const ALL_SCHEDULE_WORK = [
	{
		value: 'Не выбран',
		label: 'Не выбран'
	},
	{
		label: 'Штатный сотрудник',
		value: 'Штатный сотрудник'
	},
	{
		label: 'Срочный трудовой договор',
		value: 'Срочный трудовой договор'
	},
	{
		label: 'Вахта',
		value: 'Вахта'
	}
]

export const AboutMeForm: FC<AboutMeFormProps> = ({
	about,
	workSearchStatus,
	workSearchSchedule,
	onClickClose,
	onSubmit
}) => {
	const formRef = useRef() as MutableRefObject<HTMLFormElement>

	const handleClick = useCallback(() => {
		onClickClose(0)
	}, [])

	const handleSubmit = useCallback(
		personalInfo => {
			onSubmit?.({
				personalInfo: {
					...personalInfo,
					workSearchStatus: personalInfo?.workSearchStatus?.value,
					workSearchSchedule: personalInfo?.workSearchSchedule?.value
				}
			})
		},
		[onSubmit]
	)

	return (
		<div className='about-me-form'>
			<Form
				elevation={0}
				className='style-worker-form'
				name='workerProfile'
				onSubmit={handleSubmit}
				useRef={formRef}
				data={[
					[
						{
							values: [
								{
									type: 'select',
									title: 'Текущий статус работы',
									options: ALL_STATUSES_WORK,
									defaultValue: getObjValueByName(ALL_STATUSES_WORK, workSearchStatus || ''),
									var: 'workSearchStatus',
									validate: { required: true }
								},
								{
									type: 'select',
									title: 'График работы',
									options: ALL_SCHEDULE_WORK,
									defaultValue: getObjValueByName(
										ALL_SCHEDULE_WORK,
										!!workSearchSchedule ? workSearchSchedule : 'Не выбран'
									),
									var: 'workSearchSchedule',
									validate: { required: true }
								},
								{
									type: 'textarea',
									className: 'about-textarea',
									title: 'О себе',
									defaultValue: about || '',
									var: 'about',
									validate: {
										required: false,
										maxLength: 300
									},
									errors: {
										maxLength: 'Введите не больше 300 символов'
									}
								}
							]
						},
						{
							values: [
								{
									type: 'other',
									value: () => (
										<div className='about-buttons-save'>
											<div className='about-button-save'>
												<SpanButtons
													data={[
														{
															label: 'Не сохранять',
															onClick: handleClick
														}
													]}
												/>
											</div>
											<div>
												<SpanButtons
													data={[
														{
															label: 'Сохранить изменения',
															onClick: () =>
																formRef.current.dispatchEvent(
																	new Event('submit', {
																		cancelable: true,
																		bubbles: true
																	})
																)
														}
													]}
												/>
											</div>
										</div>
									)
								}
							]
						}
					]
				]}
			/>
		</div>
	)
}
