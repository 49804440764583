import { StylesConfig } from 'react-select'
import { GroupBase } from 'react-select'

export const getSelectStyles = <
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>
>() => {
	const selectStyle: StylesConfig<Option, IsMulti, Group> = {
		control: (provided, state) => ({
			...provided,
			cursor: 'pointer',
			boxShadow: 'none',
			backgroundColor: '#fff',
			outline: 'none',
			minHeight: '44px',
			borderColor: state.isFocused ? '#12324f' : '#9e9e9e',
			borderWidth: state.isFocused ? '2px' : '1px',
			'&:hover': { borderColor: state.isFocused ? '#12324f' : '#212121' }
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: '#fff',
			color: 'rgba(0, 0, 0, 0.87)',
			opacity: state.isDisabled ? '0.5' : '1',
			'&:hover': { backgroundColor: '#12324f7a', color: '#fff' }
		})
	}

	return selectStyle
}
