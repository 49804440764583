import React, { useContext } from 'react'
import { Tooltip } from '@rmwc/tooltip'
import { useTranslation } from 'react-i18next'
import { InterfaceContext } from 'global/context.interface'

export const RenderModuleNotPaid: React.FC<{ moduleName: string }> = ({ moduleName }) => {
	const { t } = useTranslation('common')
	const { salesEmail } = useContext(InterfaceContext)

	const renderText = () => {
		if (moduleName === 'hr') {
			return 'Модуль кадровика предоставляет инструменты, которые позволяют автоматизировать процесс трудоустройства работников в системе, ведение документооборота при трудоустройстве, а также использовать механизмы расторжения трудовых отношений. '
		} else if (moduleName === 'organizer') {
			return 'Модуль организатора предоставляет инструменты, которые позволяют автоматизировать учет и проведение мобилизации/демобилизации рабочих на объект. '
		} else if (moduleName === 'foreman') {
			return 'Модуль прораба предоставляет инструменты, которые позволяют автоматизировать ведение табельного учета работников на объектах прораба. '
		}
	}

	return (
		<Tooltip
			activateOn='hover'
			leaveDelay={200}
			content={
				<div
					style={{
						width: '510px',
						fontWeight: '500',
						fontSize: '16px',
						lineHeight: '145.69%',
						color: '#12324F'
					}}
				>
					{renderText()}
					Для получения дополнительной информации и подключения модуля свяжитесь с менеджером по продажам
					<br />
					<a
						href={`mailto:${salesEmail}`}
						style={{
							padding: '0 5px'
						}}
					>
						{salesEmail}
					</a>
					или
					<a
						href={`https://t.me/pragmacorebot`}
						target='_blank'
						rel='noreferrer'
						style={{
							padding: '0 5px'
						}}
					>
						Telegram
					</a>
				</div>
			}
		>
			<div style={{ cursor: 'pointer' }}>{t('role.' + moduleName)}</div>
		</Tooltip>
	)
}
