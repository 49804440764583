import { TextField } from "@rmwc/textfield";
import { reformatToMask } from "pages/auth-as-worker/auth-as-worker-utils";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { InfoBlock, PageTitle, ProgressPage } from "components";
import { isSuperAdm, req } from "../../global/common";
import { UserContext } from "../../global/context.user";
import { PagesRoutes } from "global/routes";
import { SuperadminUser } from "./SuperadminUsers.types";
import "./SuperadminUsers.style.sass";
import { Params } from "../public-profile/components/PublicProfilePage/public-profile-def";
import { Icon } from "@rmwc/icon";
import { Tooltip } from "@rmwc/tooltip";
import { differenceInCalendarDays, parse } from "date-fns";
import { InterfaceContext } from "../../global/context.interface";

export function SuperadminUsersPage() {
    const interfaceCTX = useContext(InterfaceContext);
    const userCTX = React.useContext(UserContext);
    const { searchID } = useParams() as Params;
    const [superadminUsers, setSuperadminUsers] = React.useState<any>(null as unknown as SuperadminUser[]);
    const [search, setSearch] = React.useState("");
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation("common");
    let timeoutForSearch: NodeJS.Timeout;
    let isArchive = location.pathname.includes("/users/archive");

    interface IQuery {
        limit: number;
        offset: number;
        search?: string;
        showDeleted: boolean;
    }

    const getUsers = (userId: any) => {
        let query: IQuery = {
            limit: 999,
            offset: 0,
            showDeleted: isArchive
        };
        if (search != "") {
            setSuperadminUsers(null);
            query.search = search;
            req.get(`/organisation/${userId}/staff`, query).then(({ data }) => {
                setSuperadminUsers(data.data.map((user: any) => new SuperadminUser(user)));
            });
        } else {
            setSuperadminUsers(null);
            req.get(`/organisation/${userId}/staff`, query).then(({ data }) => {
                setSuperadminUsers(data.data.map((user: any) => new SuperadminUser(user)));
            });
        }
    };

    const sendInvite = (userId: number) => {
        req.post(`/organisation/reinvite?userId=${userId}`)
            .then(({ data }) => {
                getUsers(userCTX.user?.organisation.id);
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t(e.response?.data)
                });
            })
            .finally(() => {
            });
    };

    React.useEffect(() => {
        if (userCTX.user?.currentRole === "moderator") {
            getUsers(searchID);
        } else {
            getUsers(userCTX.user?.organisation.id);
        }
    }, [search]);

    const superadmiUsersListItems =
        superadminUsers &&
        superadminUsers.map((superadminUser: any) => (
            <li key={superadminUser.id} className="user-list-item">
                {superadminUser.activeStatus
                    ? <div className="user-statusbar">
                        <div
                            className="user-statusbar-title"
                            style={{ backgroundColor: "#008E1F14" }}
                        >
                            Активен
                        </div>
                    </div>
                    : <div className="user-statusbar">
                        <Tooltip content="Пользователь не авторизовался по ссылке">
                            <div
                                className="user-statusbar-title"
                                style={{ backgroundColor: "#E1E1E780" }}
                            >
                                Не активен
                            </div>
                        </Tooltip>
                        {!isArchive &&
                            <div
                                className="user-statusbar-btn"
                                onClick={() => {
                                    sendInvite(superadminUser.id);
                                }}
                                style={!superadminUser.canInvite
                                    ? { pointerEvents: "none", opacity: "0.3" }
                                    : { pointerEvents: "auto" }
                                }
                            >
                                Повторно пригласить
                            </div>
                        }
                    </div>
                }
                <div className="user-name-and-position">
                    <div className="user-info">
                        <div className="user-fullname">
                            <div className="user-lastname">{superadminUser.lastName}</div>
                            <div className="user-name-and-middlename">
                                {superadminUser.firstName} {superadminUser.middleName}
                            </div>
                        </div>
                        <div className="birtdate-and-contacts">
                            {/* <InfoBlock
                        title={"Дата рождения"}
                        content={superadminUser.birthDate}
                    /> */}
                        </div>
                    </div>
                    <div className="roles">
                        {superadminUser.roles.map((su: any, index: number) => (
                            <div key={superadminUser.firstName + index}>{t("role." + su)}</div>
                        ))}
                    </div>
                </div>
                {!isArchive && (
                    <InfoBlock
                        title={"Контактные данные"}
                        content={
                            <div className="contacts">
                                <a className="phone" href={`tel:${superadminUser.contactPhone}`}>
                                    {reformatToMask(superadminUser.contactPhone)}
                                    {/* {superadminUser.contactPhone} */}
                                </a>
                                <a className="email" href={`mailto:${superadminUser.contactEmail}`}>
                                    {superadminUser.contactEmail}
                                </a>
                            </div>
                        }
                    />
                )}
                {userCTX.userHasRole("admin") && !isSuperAdm(userCTX.user) && (
                    <div className="btn-group">
                        {isArchive ? (
                            <Link
                                className={"btn erase"}
                                to={{
                                    pathname: PagesRoutes.USERS + PagesRoutes.RESTORE_USER + `/${superadminUser.id}`,
                                    state: history.location.state
                                }}
                            >
                                Восстановить
                            </Link>
                        ) : (
                            <>
                                <Link
                                    className={"btn erase"}
                                    to={{
                                        pathname: PagesRoutes.USERS + PagesRoutes.DELETE_USER + `/${superadminUser.id}`,
                                        state: history.location.state
                                    }}
                                >
                                    Удалить
                                </Link>
                                <Link
                                    className={"btn edit"}
                                    to={{
                                        pathname: PagesRoutes.USERS + PagesRoutes.EDIT_USER + `/${superadminUser.id}`,
                                        state: history.location.state
                                    }}
                                >
                                    Редактировать
                                </Link>
                            </>
                        )}
                    </div>
                )}
            </li>
        ));

    return (
        <div className="superadmin-users-page">
            <PageTitle title={!isArchive ? "Пользователи" : "Архив"} breadcrumbs={true}>
                {!isArchive && (
                    <Link
                        to={{
                            pathname: userCTX.user?.currentRole === "moderator" ? "/organizations" + PagesRoutes.USERS + PagesRoutes.ARCHIVE + `/${searchID}` : PagesRoutes.USERS + PagesRoutes.ARCHIVE,
                            state: history.location.state
                        }}
                        className="link-to-another-page"
                    >
                        {"Архив"}
                    </Link>
                )}
                {userCTX.userHasRole("admin") && !isSuperAdm(userCTX.user) && (
                    <Link
                        to={{
                            pathname: PagesRoutes.USERS + PagesRoutes.CREATE_USER,
                            state: history.location.state
                        }}
                        className="link-to-another-page"
                    >
                        {"Создать пользователя"}
                    </Link>
                )}
            </PageTitle>
            <TextField
                outlined
                maxLength={160}
                trailingIcon="search"
                onChange={function(e) {
                    clearTimeout(timeoutForSearch);

                    timeoutForSearch = setTimeout(() => {
                        setSearch((e.target as HTMLInputElement).value);
                    }, 500);
                }}
                className="search"
                label="Поиск по фамилии"
            ></TextField>

            <ProgressPage
                state={superadminUsers}
                render={() => (
                    <div className="user-list-wrapper">
                        <ul className="user-list">{superadmiUsersListItems}</ul>
                    </div>
                )}
            />
        </div>
    );
}
