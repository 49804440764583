import clsx from 'clsx'
import { StepperCheckbox } from 'components'
import { FC } from 'react'
import { EmploymentProcessStepProps } from './employment-process-step-def'
import './employment-process-step.sass'

export const EmploymentProcessStep: FC<EmploymentProcessStepProps> = ({
	className,
	titleClassName,
	title = '',
	body = '',
	isActive = false,
	checked = false
}): JSX.Element => {
	return (
		<div className={clsx('employment-process-step', className)}>
			<StepperCheckbox isActive={isActive} checked={checked} />
			<div className='employment-process-step-info'>
				<span
					className={clsx(
						'employment-process-step-title',
						{
							'employment-process-step-title-no-body': !body
						},
						titleClassName
					)}
				>
					{title}
				</span>
				{body && <span className='employment-process-step-body'>{body}</span>}
			</div>
		</div>
	)
}
