export const columns = [
    {
        id: 0,
        title: "Выбрать",
        minWidth: '170px',
        sortType: 'NONE',
        fieldName: 'select'
    },
    {
        id: 1,
        title: "Название группы",
        minWidth: '600px',
        sortType: 'NONE',
        fieldName: 'professionGroupName'
    },
    {
        id: 2,
        title: "Категория",
        minWidth: '200px',
        sortType: 'NONE',
        fieldName: 'category'
    },
    {
        id: 3,
        title: "Количество профессий в группе",
        minWidth: '280px',
        sortType: 'NONE',
        fieldName: 'professionsCount'
    },
]

export function declOfNum(n: number, text_forms: string[]) {
    n = Math.abs(n) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) {
        return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return text_forms[1];
    }
    if (n1 == 1) {
        return text_forms[0];
    }
    return text_forms[2];
}
