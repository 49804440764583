import { RequestStateType, ScheduleResponse } from 'global/api'

const requestStatuses: { [key: string]: string } = {
	open: 'Вакансия открыта',
	cancelled: 'Вы отозвали отклик',
	pending: 'Отклик направлен',
	documents_awaiting: 'Запрос на заполнение «Личных документов»',
	documents_preparation: 'Для вас готовят пакет документов',
	documents_confirmed: '«Личные документы» заполнены',
	documents_undelivered: 'Документы запрошены повторно',
	signature_awaiting: 'Требуется подпись',
	signed: 'Документы подписаны',
	accepted: 'Вас приняли на работу',
	rejected: 'Отказ в приёме'
}

const requestDateMessage: { [key: string]: string } = {
	cancelled: 'Отклик отозван',
	pending: 'Отклик направлен',
	documents_awaiting: 'Запрос на заполнение «Личных документов»',
	documents_preparation: 'Подготовка документов',
	documents_confirmed: '«Личные документы» заполнены',
	documents_undelivered: 'Документы не получены',
	signature_awaiting: 'Требуется подпись',
	signed: 'Документы подписаны',
	accepted: 'Принят на работы',
	rejected: 'Отказ в приёме'
}

export const getRequestStatusMessage = (status: RequestStateType) => {
	return requestStatuses[status]
}

export const getRequestDateMessage = (status: RequestStateType) => {
	return requestDateMessage[status]
}

export const getSheduleInfo = (shedule: ScheduleResponse | null) => {
	if (shedule) {
		return `${shedule.name}	Среднее количество часов в месяц - ${shedule.workHoursPerMonth}`
	}

	return null
}
