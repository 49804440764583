import { Button } from '@rmwc/button'
import { PageTitle, ProgressPage } from 'components'
import { useDebounce } from 'custom-hooks'
import { WorkerRequestListRequest } from 'global/api'
import { LocationState } from 'global/definitions/definitions'
import { PagesRoutes } from 'global/routes'
import {
	FindTextField,
	RequestFilterDialog, RequestFilterDialogDataType, RequestList, useRequests
} from 'pages/auth-as-worker'
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { DelimitedArrayParam, NumberParam, StringParam, useQueryParams } from 'use-query-params'
import './archive-request-list.sass'

export const ArchiveRequestListPage: FC = (): JSX.Element => {
	const { state, search } = useLocation<LocationState[]>()
	const [isOpen, setIsOpen] = useState(false)
	const [urlParams, setUrlParams] = useQueryParams({
		city: StringParam,
		organisation: StringParam,
		mainProfession: StringParam,
		salaryNetMin: NumberParam,
		salaryNetMax: NumberParam,
		subProfessions: DelimitedArrayParam
	})
	const requestFilters: Partial<WorkerRequestListRequest> = useMemo(() => {
		return {
			city: urlParams.city,
			organisation: urlParams.organisation,
			mainProfession: urlParams.mainProfession,
			salaryNetMin: urlParams.salaryNetMin,
			salaryNetMax: urlParams.salaryNetMax,
			subProfessions: urlParams.subProfessions?.filter(value => value !== null) as string[] | null | undefined,
			type: 'archived'
		}
	}, [urlParams])
	const [searchValue, setSearchValue] = useState<string | undefined>(urlParams.mainProfession || undefined)
	const debouncedValue = useDebounce(searchValue)
	const { requests, isFetching, isUploading } = useRequests(requestFilters)

	useEffect(() => setUrlParams({ mainProfession: debouncedValue || undefined }), [debouncedValue])

	const inputHandler = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)

	const toggleMenuHanlder = () => setIsOpen(!isOpen)

	const submitHandler = (data: RequestFilterDialogDataType) => {
		setUrlParams({ ...data, subProfessions: data.subProfessions?.length !== 0 ? data.subProfessions : undefined })
		setSearchValue(data.mainProfession ?? '')
		setIsOpen(false)
	}

	return (
		<div className='archive-request-list-page'>
			<div className='archive-request-list-page-title'>
				<PageTitle title='Архив' breadcrumbs />
				<Link
					to={{
						pathname: PagesRoutes.WORKER_NEED_LIST,
						state: state?.[0] ? [state[0]] : undefined
					}}
				>
					<Button raised>СПИСОК ПОТРЕБНОСТЕЙ</Button>
				</Link>
			</div>
			<FindTextField
				className='archive-request-list-page-find-input'
				value={searchValue ?? ''}
				filtersIsEmpty={!!search}
				onChange={inputHandler}
				onOpenMenu={toggleMenuHanlder}
			/>
			<ProgressPage state={isFetching} render={() => <RequestList requests={requests} isUploading={isUploading} />} />
			{isOpen && <RequestFilterDialog open={isOpen} onClose={toggleMenuHanlder} onSubmit={submitHandler} />}
		</div>
	)
}
