import {Button} from '@rmwc/button'
import {TextField} from '@rmwc/textfield'
import {ProgressPage} from 'components'
import {useDebounce} from 'custom-hooks'
import {reviewsApi} from 'global/api'
import {ChangeEvent, FC, useContext, useEffect, useState} from 'react'
import {PendingReviewCard} from './components'
import {PendingReviewContext} from './context'

import './pending-review.sass'
import {InterfaceContext} from "../../../../../global/context.interface";
import {CircularProgress} from "@rmwc/circular-progress";

export const PendingReview: FC = (): JSX.Element => {
    const {reviews, mode, setReviews, setMode, isDirty} = useContext(PendingReviewContext)
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [searchValue, setSearchValue] = useState('')
    const debounceValue = useDebounce(searchValue)
    const interfaceCTX = useContext(InterfaceContext)

    useEffect(() => {
        setIsSuccess(null)
        mode === 'create'
            ? reviewsApi.getListOfUsersNotReviewed(100, 0, debounceValue).then(response => {
                setReviews(response.data)
                setIsSuccess(true)
            })
            : reviewsApi.getMyReviewList(100, 0, debounceValue).then(response => {
                setReviews(response.data)
                setIsSuccess(true)
            })
    }, [mode, debounceValue])

    useEffect(() => {
        setSelectedIndex(-1)
        // setSearchValue('')
    }, [mode, reviews])

    const inputHandler = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)

    const openCommentFormHandler = (index: number) => setSelectedIndex(index)

    const setModeHandler = () => {
        if (interfaceCTX.isFormChanged.review) {
            if (window.confirm('Все несохраненные данные будут удалены. Вы уверены?')) {
                setMode(mode === 'create' ? 'update' : 'create')
                interfaceCTX.checkIsFormChanged(false, 'review')
            }
        } else {
            setMode(mode === 'create' ? 'update' : 'create')
        }
    }

    return (
        <div className='pending-review-page'>
            <div className='pending-review-page-form'>
                <TextField
                    className='pending-review-page-textfield'
                    trailingIcon={{
                        icon: 'search',
                        tabIndex: 0
                    }}
                    placeholder='Поиск по ФИО'
                    value={searchValue}
                    outlined
                    onChange={inputHandler}
                />
                <Button className='pending-review-page-button' raised onClick={setModeHandler}>
                    {mode === 'create' ? 'отзыв оставлен' : 'ждут отзыв'}
                </Button>
            </div>
            <ProgressPage
                state={true}
                render={() => (
                        isSuccess ?
                            <>
                                {reviews.data.map((review, index) => (
                                    <PendingReviewCard
                                        key={index}
                                        className='pending-review-page-review-card'
                                        review={review}
                                        openComment={index === selectedIndex}
                                        onOpenCommentForm={() => openCommentFormHandler(index)}
                                    />
                                ))}
                            </>
                            : <CircularProgress className='circular-progress' style={{
                                margin: '0 auto',
                                display: 'flex',
                            }}/>
                )}
            />
        </div>
    )
}
