export const allOptionRoles = (t: any) => {
    return [
        {
            label: t("role." + "admin"),
            value: "admin"
        },
        {
            label: t("role." + "recruiter"),
            value: "recruiter"
        },
        {
            label: t("role." + "organizer"),
            value: "organizer"
        },
        {
            label: t("role." + "foreman"),
            value: "foreman"
        },
        {
            label: t("role." + "hr"),
            value: "hr"
        },
        {
            label: t("role." + "worker"),
            value: "worker"
        },
    ]
}