import { AxiosResponse } from 'axios'
import { profileApi } from 'global/api'
import { req } from 'global/common'
import { InterfaceContext } from 'global/context.interface'
import { UserContext } from 'global/context.user'
import { UserProfile } from 'global/definitions/definitions'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { mapExperienceByType } from '../profile/components/professional-experience-form/professional-experience-form-utils'
import { WorkerProfile } from '../profile/worker-profile-def'
import { mapDocumentsToRequests, mapMediasToRequests, sequenceRequests } from '../profile/worker-profile-utils'

export const useWorkerProfile = () => {
	const { t } = useTranslation('common')
	const { user } = useContext(UserContext)
	const { showMessage, refreshTopBar, checkIsFormChanged } = useContext(InterfaceContext)
	const [loaded, setLoaded] = useState<boolean | null>(null)
	const [workerProfile, setWorkerProfile] = useState<UserProfile>({} as UserProfile)
	const { avatar, personalInfo: { firstName, lastName, middleName } = {} } = workerProfile

	useEffect(() => {
		refreshTopBar()
	}, [avatar, firstName, lastName, middleName])

	useEffect(() => {
		if (user) {
			profileApi
				.getPublicWorkerProfile(user.id)
				.then(response => {
					setWorkerProfile(response.data as unknown as UserProfile)
					setLoaded(true)
				})
				.catch(e => {
					setLoaded(false)
					showMessage({
						title: 'Ошибка',
						body: t('error.' + e.response.data)
					})
				})
		}
	}, [])

	const updateWorkerProfile = useCallback(
		async (newWorkerProfile: Partial<WorkerProfile>) => {
			const {
				avatar,
				personalInfo,
				experience,
				mainProfession,
				subProfessions,
				grade,
				permissions,
				withoutExperience,
				qualificationDocuments,
				permissionDocuments,
				anthropometry,
				documents,
				registrationAddress,
				actualAddress
			} = newWorkerProfile

			let updatedWorkerProfile: Partial<UserProfile> = {}
			if (
				personalInfo ||
				experience ||
				mainProfession ||
				subProfessions ||
				typeof grade !== undefined ||
				permissions ||
				anthropometry
			) {
				const result = await req.post('/profile/worker/update', {
					personalInfo,
					experience: mapExperienceByType(experience),
					mainProfession: mainProfession?.id || null,
					subProfessions,
					grade,
					permissions,
					anthropometry
				})

				updatedWorkerProfile = {
					...updatedWorkerProfile,
					...(result?.data || {})
				}
				mainProfession?.name && localStorage.setItem('mainProfession', mainProfession.name)
			}

			if (withoutExperience !== null) {
				const result = await req.post('/profile/worker/update', {
					withoutExperience
				})

				updatedWorkerProfile = {
					...updatedWorkerProfile,
					...(result?.data || {})
				}
			}

			if (avatar) {
				const isNewAvatar = avatar.path?.includes('blob:')
				if (isNewAvatar) {
					const newLocalAvatar = await fetch(avatar.path).then(r => r.blob())
					const formData = new FormData()
					formData.append('file', newLocalAvatar)
					const newAvatar = await req.post(`/profile/worker/media/upload?type=avatar`, formData)
					if (newAvatar?.data) {
						updatedWorkerProfile = {
							...updatedWorkerProfile,
							avatar: newAvatar.data
						}
					}
				}

				const isNeedDeleteAvatar = avatar.id && !avatar.path
				if (isNeedDeleteAvatar) {
					await req.delete(`/profile/worker/media/delete/${avatar.id}`)
					updatedWorkerProfile = {
						...updatedWorkerProfile,
						avatar: null
					}
				}
			}

			if (qualificationDocuments) {
				const documentRequests = await mapDocumentsToRequests<AxiosResponse<UserProfile>>(
					workerProfile?.qualificationDocuments,
					qualificationDocuments,
					'qualification'
				)

				try {
					const lastResponse = await sequenceRequests(documentRequests)
					if (lastResponse?.data) {
						updatedWorkerProfile = {
							...updatedWorkerProfile,
							...lastResponse.data
						}
					}
				} catch (e) {
					//
				}
			}

			if (permissionDocuments) {
				const documentRequests = await mapDocumentsToRequests<AxiosResponse<UserProfile>>(
					workerProfile?.permissionDocuments,
					permissionDocuments,
					'permission'
				)

				try {
					const lastResponse = await sequenceRequests(documentRequests)
					if (lastResponse?.data) {
						updatedWorkerProfile = {
							...updatedWorkerProfile,
							...lastResponse.data
						}
					}
				} catch (e) {
					showMessage({
						title: 'Ошибка',
						body: t('error.' + e)
					})
				}
			}

			if (documents || registrationAddress || actualAddress) {
				const documentRequests = await mapMediasToRequests<AxiosResponse<UserProfile>>(
					workerProfile?.documents.media,
					//@ts-ignore
					documents?.media
				)

				try {
					const lastResponse = await sequenceRequests(documentRequests)
					if (lastResponse?.data) {
						updatedWorkerProfile = {
							...updatedWorkerProfile,
							...lastResponse.data
						}
					}
				} catch (e) {
					showMessage({
						title: 'Ошибка',
						body: t('error.' + e)
					})
				}

				try {
					const result = await req.post('/profile/worker/update', {
						documents,
						registrationAddress,
						actualAddress
					})

					updatedWorkerProfile = {
						...updatedWorkerProfile,
						...(result?.data || {})
					}
				} catch (e: any) {
					showMessage({
						title: 'Ошибка',
						body: t(e.response.data)
					})
					return
				}
			}

			if (Object.keys(updatedWorkerProfile).length) {
				setWorkerProfile((state: UserProfile) => ({
					...state,
					...updatedWorkerProfile
				}))
			}
		},
		[workerProfile, showMessage]
	)

	const deleteWorkerProfileDocumentById = useCallback(
		(documentId, callback) => {
			req
				.delete(`/profile/worker/documents/additional/${documentId}/delete`)
				.then(data => {
					callback?.(data)
				})
				.then(() => {
					showMessage({
						body: 'Удален успешно',
						icon: 'done'
					})
				})
				.catch(e => {
					showMessage({
						title: 'Ошибка',
						body: t('error.' + e.response.data)
					})
				})
		},
		[workerProfile]
	)

	const deleteWorkerProfileImage = useCallback(
		(documentId, callback) => {
			req
				.delete(`/profile/worker/documents/additional/${documentId}/delete-media`)
				.then(data => {
					callback?.(data)
					// setWorkerProfile(data?.data);
				})
				.then(() => checkIsFormChanged(true, 'workerProfile'))
				.then(() => {
					showMessage({
						body: 'Фото удалена',
						icon: 'done'
					})
				})
				.catch(e => {
					showMessage({
						title: 'Ошибка',
						body: t('error.' + e.response.data)
					})
				})
		},
		[workerProfile]
	)

	return {
		loaded,
		workerProfile,
		updateWorkerProfile,
		deleteWorkerProfileDocumentById,
		deleteWorkerProfileImage
	}
}
