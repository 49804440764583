import { AxiosPromise } from 'axios'
import { req } from 'global/common'
import {
	PassedStages,
	RequestShortListWorkerResponse,
	RequestWorkerResponse,
	WorkerRequestListRequest
} from './requests-def'

export const requestsApi = {
	getRequests: (body: WorkerRequestListRequest): AxiosPromise<RequestShortListWorkerResponse> => {
		return req.post('/requests/worker/list', body)
	},
	getRequestById: (requestId: number): AxiosPromise<RequestWorkerResponse> => {
		return req.get(`/requests/worker/${requestId}/get`)
	},
	applyToTheRequest: (requestId: number): AxiosPromise<RequestWorkerResponse> => {
		return req.post(`/requests/worker/${requestId}/apply`)
	},
	cancelRequest: (requestId: number): AxiosPromise<RequestWorkerResponse> => {
		return req.post(`/requests/worker/${requestId}/cancel`)
	},
	submitDocuments: (requestId: number): AxiosPromise<RequestWorkerResponse> => {
		return req.post(`/requests/worker/${requestId}/submit-docs`)
	},
	requestDocuments: (requestId: number): AxiosPromise<RequestWorkerResponse> => {
		return req.post(`/requests/worker/${requestId}/request-docs`)
	},
	sendDocuments: (requestId: number): AxiosPromise<RequestWorkerResponse> => {
		return req.post(`/requests/worker/${requestId}/send-docs`)
	},
	getRoadMapHistory: (requestId: number): AxiosPromise<PassedStages> => {
		return req.get(`/response/${requestId}/road-map-history`)
	}
}
