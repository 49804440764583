import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Checkbox } from "@rmwc/checkbox";
import { Elevation } from "@rmwc/elevation";
import "@rmwc/elevation/styles";
import { Icon } from "@rmwc/icon";
import { ListDivider } from "@rmwc/list";
import "@rmwc/select/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { Tooltip } from "@rmwc/tooltip";
import React, { useCallback, useState } from "react";
import {
    Control,
    Controller,
    FieldValues,
    FormProvider,
    useForm,
    UseFormRegister,
    UseFormWatch,
    Validate
} from "react-hook-form";
import Linkify from "react-linkify";
import NumberFormat from "react-number-format";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import Async from "react-select/async";
import { attrList, classList, req } from "../../global/common";
import { InterfaceContext } from "../../global/context.interface";
import { BlockSettings, Column, ColumnValues, ErrorsQueue, ErrorsText, FormComponentRT, FormProps } from "./Form.types";
import { stringToURL } from "./Form.service";
import "./Form.style.sass";
import { useHistory } from "react-router";

function formatData(data: Column[][], viewMode?: boolean): [Column[][], Record<any, any>] {
    const defaultValues: Record<any, any> = {};
    for (const block of data) {
        for (const column of block) {
            for (const value of column.values) {
                switch (value.type) {
                    default:
                    case "textfield": {
                        if (value.defaultValue) defaultValues[value.var] = value.defaultValue;
                        break;
                    }
                    case "textfields": {
                        value.values.forEach(v => {
                            if (v.defaultValue) defaultValues[v.var] = v.defaultValue;
                        });
                        break;
                    }
                    case "other": {
                        if (value.defaultValue && value.watchVar) defaultValues[value.watchVar] = value.defaultValue;
                        break;
                    }

                    case "checkbox":
                    case "text":
                        break;
                    case "headline":
                        break;
                    case "period": {
                        value.values.forEach(v => {
                            if (v.defaultValue) defaultValues[v.var] = v.defaultValue;
                        });
                        break;
                    }
                }
            }
        }
    }

    const mdata = [];
    for (const block of data) {
        const columns: Column[] = [];

        for (const column of block) {
            const filteredValues = column.values.filter(item => {
                switch (item.type) {
                    default:
                    case "textfield":
                        return viewMode ? item.defaultValue != null && item.defaultValue != "" : true;
                    case "textfields":
                        return viewMode ? !item.values.every(v => v.defaultValue === null) : true;
                    case "text":
                        return item.value != null;
                    case "other":
                        return true;
                    case "checkbox":
                        return true;
                    case "headline":
                        return true;
                    case "period":
                        return viewMode ? !item.values.every(v => v.defaultValue == null) : true;
                }
            });

            if (filteredValues.length) {
                column.values = filteredValues;
                columns.push(column);
            }
        }
        if (columns.length) mdata.push([...columns]);
    }

    return [mdata, defaultValues];
}

export function Form({ elevation = 3, className = "", ...props }: FormProps) {
    let [data, defaultValues] = formatData(props.data, props.viewMode);
    const interfaceCTX = React.useContext(InterfaceContext);

    const form = useForm({
        mode: props.validateMode === "onSubmit" ? undefined : props.validateMode === undefined ? "all" : props.validateMode,
        defaultValues: defaultValues
    });

    const {
        register,
        control,
        watch,
        handleSubmit,
        formState: { errors },
        getValues,
        formState,
        reset
    } = form;
    const history = useHistory();
    const location = useLocation();

    const showAlertOnLeave = useCallback(e => {
        e.preventDefault();
        e.returnValue = `Are you sure you want to leave?`;
    }, []);

    React.useEffect(() => {
        formState.isDirty
            ? window.addEventListener("beforeunload", showAlertOnLeave)
            : window.removeEventListener("beforeunload", showAlertOnLeave);
    }, [formState.isDirty]);

    React.useEffect(() => {
        if (formState.isSubmitSuccessful == true) {
            reset(getValues(), {
                keepDirty: false
            });
            interfaceCTX.checkIsFormChanged(false, props.name);
        }
        reset();
    }, [formState.isSubmitSuccessful]);

    React.useEffect(() => {
        if (props.viewMode && location.pathname.includes("time-keeping")) {
            interfaceCTX.checkIsFormChanged(!props.viewMode, props.name);
        } else {
            interfaceCTX.checkIsFormChanged(formState.isDirty, props.name);
        }
    }, [formState.isDirty, props.viewMode]);

    React.useEffect(() => {
        interfaceCTX.formMethods(reset, getValues);
        return () => {
            window.removeEventListener("beforeunload", showAlertOnLeave);
        };
    }, []);
    const [blocksSettings, setBlockSettings] = useState<BlockSettings[] | null>(props.dropDownBlocks ?? null);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={handleSubmit(data => {
                    props.onSubmit!(data);
                })}
                style={props.style ? props.style : {}}
                // onSubmit={props.onSubmit
                //     ? handleSubmit((data) => {
                //         props.onSubmit!(data)
                //     })
                //     : e => e.preventDefault()}
                ref={props.useRef}
                {...attrList(["className", "form-component"], ["className", props.solidMode && "form-component--solid"])}
            >
                {data.map((block, blockIndex) => (
                    <React.Fragment key={"block-" + blockIndex}>
                        {props.solidMode && blockIndex > 0 && <ListDivider />}
                        {props.isDropDown ? (
                            <Elevation z={elevation}>
                                {props.isDropDown && blocksSettings && blocksSettings[blockIndex].headName && (
                                    <div
                                        className="form-block-header"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setBlockSettings(
                                                prev =>
                                                    prev &&
                                                    prev.map((item, index) =>
                                                        index === blockIndex ? {
                                                            ...item,
                                                            isOpen: !item.isOpen
                                                        } : { ...item, isOpen: false }
                                                    )
                                            );
                                        }}
                                    >
                                        <div
                                            className="form-block-header-name">{blocksSettings[blockIndex].headName}</div>
                                        {!!blocksSettings[blockIndex].icon && blocksSettings[blockIndex].icon?.visible.current && (
                                            <Icon
                                                className="icon form-block-header-icon"
                                                style={{ color: "#d52121" }}
                                                icon={blocksSettings[blockIndex].icon?.name}
                                            />
                                        )}
                                        {props.hideButtons &&
                                            props.buttons &&
                                            props.buttons[blockIndex] &&
                                            blocksSettings[blockIndex].isOpen && (
                                                <div className="form-block-header-buttons">
                                                    {props.buttons[blockIndex].map((item, index) => {
                                                        if (item.isVisible === false) return;
                                                        if (item.type === "button") {
                                                            return (
                                                                <Button
                                                                    className="form-block-header-button"
                                                                    raised={true}
                                                                    key={item.key}
                                                                    label={item.title}
                                                                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                                        e.stopPropagation();
                                                                        item.callback(e);
                                                                    }}
                                                                />
                                                            );
                                                        } else if (item.type === "selector" && !!item.options) {
                                                            return (
                                                                <div
                                                                    key={item.key}
                                                                    style={{
                                                                        width: "450px",
                                                                        height: "100%",
                                                                        padding: "5px 0"
                                                                    }}
                                                                    onClick={(e: any) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <Select
                                                                        classNamePrefix={"select"}
                                                                        noOptionsMessage={() => "Нет опций"}
                                                                        placeholder={item.placeholder}
                                                                        isSearchable={false}
                                                                        options={[
                                                                            // ...[{
                                                                            //     value: 0,
                                                                            //     label: "",
                                                                            // },
                                                                            // ],
                                                                            ...item.options.map(item => ({
                                                                                value: item.contractInfo.contractId,
                                                                                label: item.contractInfo.contractRequestNumber
                                                                            }))
                                                                        ]}
                                                                        onChange={evt => {
                                                                            item.callback(evt);
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            )}
                                        <Icon
                                            className="expand_more"
                                            icon={blocksSettings[blockIndex].isOpen ? "expand_less" : "expand_more"}
                                        />
                                    </div>
                                )}
                                {props.isDropDown && blocksSettings && blocksSettings[blockIndex].headName ? (
                                    blocksSettings &&
                                    blocksSettings[blockIndex].isOpen && (
                                        <div className={`form-block ${className}`}>
                                            {block.map((column, columnIndex) => (
                                                <React.Fragment key={"column-" + columnIndex}>
                                                    <div {...classList("form-column", column.fullWidth && "form-column--fullwidth")}>
                                                        {column.title && (
                                                            <span className="form-column--title">
																{column.title}
                                                                {column.caption && <span
                                                                    className="form-column--caption">{" " + column.caption}</span>}
															</span>
                                                        )}
                                                        {column.values.map((item, itemIndex) => {
                                                            const { component, errorMessage } = getItemComponent(
                                                                item,
                                                                //@ts-ignore
                                                                errors,
                                                                register,
                                                                watch,
                                                                control,
                                                                props.viewMode ?? false,
                                                                form,
                                                                history
                                                            );

                                                            if (!component && item.type === "other") return null;

                                                            // вычисляем ширину блока
                                                            let colSpan: string | number = 500;
                                                            if ("colSpan" in item && item.colSpan) {
                                                                if (item.colSpan === -1) {
                                                                    colSpan = "100vw";
                                                                } else {
                                                                    colSpan = 500 * item.colSpan + 25.6 * (item.colSpan - 1);
                                                                }
                                                            }

                                                            // отображаем знак обязательного поля
                                                            let showRequired = false;
                                                            if (!props.viewMode) {
                                                                if ("validate" in item && item.validate && item.validate.required) showRequired = true;
                                                                if ("values" in item && item.values.some(v => v.validate?.required)) showRequired = true;
                                                            }
                                                            if (item.hidden !== true) {
                                                                return (
                                                                    <div
                                                                        className={"form-column--item"}
                                                                        key={"item-" + itemIndex}
                                                                        style={{ width: colSpan }}
                                                                    >
                                                                        {item.title && item.type !== "headline" && (
                                                                            <span
                                                                                {...classList(
                                                                                    "item-title",
                                                                                    (props.viewMode || item.type === "text") && "item-title--view"
                                                                                )}
                                                                            >
																				{item.title}
                                                                                {(showRequired || (item.requiredIcon && !props.viewMode)) && (
                                                                                    <span
                                                                                        className={"item-title--required"}>*</span>
                                                                                )}
                                                                                {item.caption && <span
                                                                                    className={"item-caption"}>{" " + item.caption}</span>}
																			</span>
                                                                        )}
                                                                        {component}
                                                                        {errorMessage.map((message, i) => (
                                                                            <span className={"error-text"}
                                                                                  key={"error-" + i}>
																				{message}
																			</span>
                                                                        ))}
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                    </div>
                                                    {column.divider && <ListDivider />}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    )
                                ) : (
                                    <div className={`form-block ${className}`}>
                                        {block.map((column, columnIndex) => (
                                            <React.Fragment key={"column-" + columnIndex}>
                                                <div {...classList("form-column", column.fullWidth && "form-column--fullwidth")}>
                                                    {column.title && (
                                                        <span className="form-column--title">
															{column.title}
                                                            {column.caption && <span
                                                                className="form-column--caption">{" " + column.caption}</span>}
														</span>
                                                    )}

                                                    {column.values.map((item, itemIndex) => {
                                                        const { component, errorMessage } = getItemComponent(
                                                            item,
                                                            //@ts-ignore
                                                            errors,
                                                            register,
                                                            watch,
                                                            control,
                                                            props.viewMode ?? false,
                                                            form,
                                                            history
                                                        );
                                                        if (!component && item.type === "other") return null;

                                                        // вычисляем ширину блока
                                                        let colSpan: string | number = 500;
                                                        if ("colSpan" in item && item.colSpan) {
                                                            if (item.colSpan === -1) {
                                                                colSpan = "100vw";
                                                            } else {
                                                                colSpan = 500 * item.colSpan + 25.6 * (item.colSpan - 1);
                                                            }
                                                        }

                                                        // отображаем знак обязательного поля
                                                        let showRequired = false;
                                                        if (!props.viewMode) {
                                                            if ("validate" in item && item.validate && item.validate.required) showRequired = true;
                                                            if ("values" in item && item.values.some(v => v.validate?.required)) showRequired = true;
                                                        }
                                                        if (item.hidden !== true) {
                                                            return (
                                                                <div
                                                                    className={"form-column--item"}
                                                                    key={"item-" + itemIndex}
                                                                    style={{ width: colSpan }}
                                                                >
                                                                    {item.title && item.type !== "headline" && (
                                                                        <span
                                                                            {...classList(
                                                                                "item-title",
                                                                                (props.viewMode || item.type === "text") && "item-title--view"
                                                                            )}
                                                                        >
																			{item.title}
                                                                            {(showRequired || (item.requiredIcon && !props.viewMode)) && (
                                                                                <span
                                                                                    className={"item-title--required"}>*</span>
                                                                            )}
                                                                            {item.caption && <span
                                                                                className={"item-caption"}>{" " + item.caption}</span>}
																		</span>
                                                                    )}
                                                                    {component}
                                                                    {errorMessage.map((message, i) => (
                                                                        <span className={"error-text"}
                                                                              key={"error-" + i}>
																			{message}
																		</span>
                                                                    ))}
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                                {column.divider && <ListDivider />}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                )}
                            </Elevation>
                        ) : (
                            <Elevation className={`form-block ${className}`} z={elevation}>
                                {block.map((column, columnIndex) => (
                                    <React.Fragment key={"column-" + columnIndex}>
                                        <div {...classList("form-column", column.fullWidth && "form-column--fullwidth")}>
                                            {column.title && (
                                                <span className="form-column--title">
													{column.title}
                                                    {column.caption && <span
                                                        className="form-column--caption">{" " + column.caption}</span>}
												</span>
                                            )}

                                            {column.values.map((item, itemIndex) => {
                                                const { component, errorMessage } = getItemComponent(
                                                    item,
                                                    //@ts-ignore
                                                    errors,
                                                    register,
                                                    watch,
                                                    control,
                                                    props.viewMode ?? false,
                                                    form,
                                                    history
                                                );

                                                if (!component && item.type === "other") return null;

                                                // вычисляем ширину блока
                                                let colSpan: string | number = 500;
                                                if ("colSpan" in item && item.colSpan) {
                                                    if (item.colSpan === -1) {
                                                        colSpan = "100vw";
                                                    } else {
                                                        colSpan = 500 * item.colSpan + 25.6 * (item.colSpan - 1);
                                                    }
                                                }

                                                // отображаем знак обязательного поля
                                                let showRequired = false;
                                                if (!props.viewMode) {
                                                    if ("validate" in item && item.validate && item.validate.required) showRequired = true;
                                                    if ("values" in item && item.values.some(v => v.validate?.required)) showRequired = true;
                                                }
                                                if (item.hidden !== true) {
                                                    return (
                                                        <div className={"form-column--item"} key={"item-" + itemIndex}
                                                             style={{ width: colSpan }}>
                                                            {item.title && item.type !== "headline" && (
                                                                <span
                                                                    {...classList(
                                                                        "item-title",
                                                                        (props.viewMode || item.type === "text") && "item-title--view"
                                                                    )}
                                                                >
																	{item.title}
                                                                    {(showRequired || (item.requiredIcon && !props.viewMode)) && (
                                                                        <span
                                                                            className={"item-title--required"}>*</span>
                                                                    )}
                                                                    {item.caption && <span
                                                                        className={"item-caption"}>{" " + item.caption}</span>}
																</span>
                                                            )}
                                                            {component}
                                                            {errorMessage.map((message, i) => (
                                                                <span className={"error-text"} key={"error-" + i}>
																	{message}
																</span>
                                                            ))}
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                        {column.divider && <ListDivider />}
                                    </React.Fragment>
                                ))}
                            </Elevation>
                        )}
                    </React.Fragment>
                ))}
            </form>
        </FormProvider>
    );
}

const errorsText: ErrorsText = {
    required: () => "Поле обязательно к заполнению",
    minLength: v => `Требуется ввести не менее ${v} символов`,
    maxLength: v => `Требуется ввести не более ${v} символов`,
    min: v => `Значения менее ${v} не принимаются`,
    max: v => `Значения больше ${v} не принимаются`,
    pattern: () => "Введите данные в требуемой форме"
};

function getItemComponent(
    item: ColumnValues,
    errors: ErrorsQueue,
    register: UseFormRegister<FieldValues>,
    watch: UseFormWatch<FieldValues>,
    control: Control<FieldValues, Object>,
    viewMode: boolean,
    form: any,
    history: any
): FormComponentRT {
    const rt: FormComponentRT = {
        component: null,
        errorMessage: []
    };


    switch (item.type) {
        default:
        case "textfield": {
            if (viewMode && item.overrideMode !== "edit") {
                if (item.link) {
                    let linkURL = item.defaultValue != undefined ? stringToURL(item.defaultValue as string) : "";
                    rt.component = (
                        <a href={item.link != "link" ? item.link + ":" + item.defaultValue : linkURL}
                           className={"item-text link"}>
                            {item.defaultValue}
                        </a>
                    );
                } else {
                    rt.component = <div className={"item-text"}>{item.defaultValue}</div>;
                }
                break;
            }
            if (item.hidden) {
                break;
            }
            if (item.isUseValue) {
                rt.component = (
                    <>
                        <TextField
                            outlined
                            autoComplete={"off"}
                            key={item.var}
                            value={item.defaultValue ? item.defaultValue : ""}
                            invalid={errors.hasOwnProperty(item.var)}
                            {...register(
                                item.var,
                                item.validate?.validate
                                    ? {
                                        ...item.validate,
                                        validate: {
                                            [item.errors?.validate as string]: item.validate?.validate as Validate<any>,
                                            // validate: !item.validate?.validate ? {
                                            [String("Поле не должно состоять только из пробелов")]: (v: any) => {
                                                let trimmedValue = v.trim();
                                                if (v.length > 0 && trimmedValue == "") {
                                                    return false;
                                                } else {
                                                    return true;
                                                }
                                            }
                                        }
                                    }
                                    : {
                                        ...item.validate,
                                        validate: {
                                            [String("Поле не должно состоять только из пробелов")]: (v: any) => {
                                                if (typeof v === "number") return;
                                                else {
                                                    let trimmedValue = v.trim();
                                                    if (v.length > 0 && trimmedValue == "") {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                            )}
                            {...classList(item.className)}
                            placeholder={item.placeholder}
                            onChange={item.onChange}
                        />
                        {item.withEye && (
                            <Tooltip content={<div className="tooltip">Поле скрыто из публичного представления</div>}>
                                <Icon className="eye" icon="visibility_off"></Icon>
                            </Tooltip>
                        )}
                    </>
                );
                break;
            }
            rt.component = (
                <>
                    <TextField
                        outlined
                        autoComplete={"off"}
                        key={item.var}
                        invalid={errors.hasOwnProperty(item.var)}
                        {...register(
                            item.var,
                            item.validate?.validate
                                ? {
                                    ...item.validate,
                                    validate: {
                                        [item.errors?.validate as string]: item.validate?.validate as Validate<any>,
                                        // validate: !item.validate?.validate ? {
                                        [String("Поле не должно состоять только из пробелов")]: (v: any) => {
                                            let trimmedValue = v.trim();
                                            if (v.length > 0 && trimmedValue == "") {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        }
                                    }
                                }
                                : {
                                    ...item.validate,
                                    validate: {
                                        [String("Поле не должно состоять только из пробелов")]: (v: any) => {
                                            if (typeof v === "number") return;
                                            else {
                                                let trimmedValue = v.trim();
                                                if (v.length > 0 && trimmedValue === "") {
                                                    return false;
                                                } else {
                                                    return true;
                                                }
                                            }
                                        }
                                    }
                                }
                        )}
                        {...classList(item.className)}
                        placeholder={item.placeholder}
                        onChange={item.onChange}
                    />
                    {item.withEye && (
                        <Tooltip content={<div className="tooltip">Поле скрыто из публичного представления</div>}>
                            <Icon className="eye" icon="visibility_off"></Icon>
                        </Tooltip>
                    )}
                </>
            );
            break;
        }

        case "select": {
            if (item.overrideMode === "view" || viewMode) {
                rt.component = (
                    <div className={"item-text"}>
                        {Array.isArray(item!.defaultValue)
                            ? item.defaultValue.map(v => v.label).join(", ")
                            : item.defaultValue
                                ? item!.defaultValue!.label
                                : ""}
                    </div>
                );
                break;
            }
            rt.component = (
                <>
                    <Controller
                        name={item.var}
                        control={control}
                        rules={item.validate}
                        render={({ field }) => (
                            <Select
                                {...field}
                                {...classList(
                                    "react-select-component",
                                    errors.hasOwnProperty(item.var) && "react-select-component--invalid"
                                )}
                                classNamePrefix={"select"}
                                placeholder={""}
                                noOptionsMessage={() => "Нет опций"}
                                closeMenuOnSelect={!item.isMulti}
                                options={item.options}
                                isClearable={item.isClearable}
                                isSearchable={item.isSearchable}
                                isDisabled={item.isDisabled}
                                isMulti={item.isMulti}
                                // getValue={item.getValue ? item.getValue(field.value) : () => {
                                // }}
                            />
                        )}
                    />
                    {item.info && (
                        <div style={{ display: "flex", alignItems: "center", margin: "8px 0 10px 0" }}>
                            {item.info.icon && (
                                <Icon icon={item.info.icon.name}
                                      style={{ color: item.info.icon.color, marginRight: "10px" }} />
                            )}
                            <div
                                style={{
                                    fontWeight: "500",
                                    fontSize: "13px",
                                    lineHeight: "15px",
                                    letterSpacing: "0.3px",
                                    color: "#12324F"
                                }}
                            >
                                {item.info.title}
                            </div>
                        </div>
                    )}
                </>
            );
            break;
        }

        case "textfields": {
            if (viewMode) {
                rt.component = (
                    <div className={"item-text"}>
                        {item.values.map(v => v.defaultValue && v.label + " " + v.defaultValue).join(" ")}
                    </div>
                );
                break;
            }
            if (item.hidden) {
                break;
            } else {
                rt.component = (
                    <div className={"item-textfields"}>
                        {item.values.map((v, i) => (
                            <React.Fragment key={"textfields-" + i}>
                                {v.label}
                                <TextField
                                    outlined
                                    key={v.var}
                                    autoComplete={"off"}
                                    invalid={errors.hasOwnProperty(v.var)}
                                    {...register(
                                        v.var,
                                        v.validate?.validate
                                            ? {
                                                ...v.validate,
                                                validate: {
                                                    [v.errors?.validate as string]: v.validate?.validate as Validate<any>,
                                                    // validate: !v.validate?.validate ? {
                                                    [String("Поле не должно состоять только из пробелов")]: (v: any) => {
                                                        let trimmedValue = v.trim();
                                                        if (v.length > 0 && trimmedValue == "") {
                                                            return false;
                                                        } else {
                                                            return true;
                                                        }
                                                    }
                                                }
                                            }
                                            : {
                                                ...v.validate,
                                                validate: {
                                                    [String("Поле не должно состоять только из пробелов")]: (v: any) => {
                                                        let trimmedValue = v.trim();
                                                        if (v.length > 0 && trimmedValue == "") {
                                                            return false;
                                                        } else {
                                                            return true;
                                                        }
                                                    }
                                                }
                                            }
                                    )}
                                    {...classList(item.className)}
                                    placeholder={v.placeholder}
                                    onChange={v.onChange}
                                />
                            </React.Fragment>
                        ))}
                    </div>
                );

                for (const value of item.values) {
                    if (!errors.hasOwnProperty(value.var)) continue;

                    const errorType = errors[value.var].type;
                    const errorValue = value.validate![errorType];

                    rt.errorMessage.push(
                        value.errors?.[errorType] ??
                        (errorsText.hasOwnProperty(errorType) ? errorsText[errorType](errorValue) : errorType)
                    );
                }

                break;
            }
        }

        case "async-select": {
            if (viewMode) {
                let value = Array.isArray(item!.defaultValue)
                    ? item.defaultValue.map(v => v.label).join(", ")
                    : item.defaultValue
                        ? item!.defaultValue!.label
                        : "";
                rt.component = (
                    <>
                        {item.route
                            ? <Link className={"item-text link"} to={{
                                pathname: item.route,
                                state: history.location.state
                            }}>
                                {value}
                            </Link>
                            : <div className={"item-text"}>
                                {value}
                            </div>
                        }
                    </>
                );
                break;
            }

            const promiseOptions = (input: string) =>
                req
                    .get(item.url, {
                        limit: item.limit ?? 200,
                        offset: 0,
                        search: input,
                        ...item.query
                    })
                    .then(({ data }) => {
                        if (item.toText !== undefined) return data.data.map((v: any) => item.toText!(v));
                        return data.data;
                    });

            rt.component = (
                <Controller
                    name={item.var}
                    control={control}
                    rules={item.validate}
                    render={({ field }) => (
                        <Async
                            {...field}
                            {...classList(
                                "react-select-component",
                                errors.hasOwnProperty(item.var) && "react-select-component--invalid"
                            )}
                            classNamePrefix={"select"}
                            placeholder={""}
                            noOptionsMessage={() => "Нет опций"}
                            closeMenuOnSelect={!item.isMulti}
                            loadOptions={promiseOptions}
                            cacheOptions
                            defaultOptions
                            isClearable={item.isClearable}
                            isSearchable={item.isSearchable}
                            isDisabled={item.isDisabled}
                            isMulti={item.isMulti}
                        />
                    )}
                />
            );
            break;
        }

        case "text": {
            rt.component = <div className={"item-text"}>{item.value}</div>;
            break;
        }

        case "other": {
            const content = item.value(viewMode, item.watchVar ? watch(item.watchVar) : undefined, form);

            rt.component = content as null | React.ReactElement;
            break;
        }

        case "textarea": {
            if (viewMode && item.overrideMode !== "edit") {
                rt.component = (
                    <Linkify>
                        <div className={"item-text"}>{item.defaultValue}</div>
                    </Linkify>
                );
                break;
            }
            if (item.hidden) {
                break;
            }
            rt.component = (
                <>
                    <TextField
                        className={"textarea"}
                        outlined
                        textarea
                        style={{ width: "100%" }}
                        autoComplete={"off"}
                        invalid={errors.hasOwnProperty(item.var)}
                        {...register(item.var, {
                            ...item.validate,
                            validate: {
                                "Поле не должно состоять только из пробелов": v => {
                                    let trimmedValue = v.trim();
                                    if (v.length > 0 && trimmedValue == "") {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            }
                        })}
                        {...classList(item.className)}
                        placeholder={item.placeholder}
                        // onChange={item.onChange}
                    />
                    {item.withEye && (
                        <Tooltip content={<div className="tooltip">Поле скрыто из публичного представления</div>}>
                            <Icon className="eye" icon="visibility_off"></Icon>
                        </Tooltip>
                    )}
                </>
            );
            break;
        }
        case "checkbox": {
            if (viewMode && item.value) {
                rt.component = (
                    <Checkbox ref={item.ref} style={{ pointerEvents: "none" }} label={item.label}
                              checked={item.value} />
                );
                break;
            } else if (viewMode && item.value == false) {
                break;
            }

            rt.component = (
                <div ref={item.ref}>
                    <Checkbox {...register(item.var)} label={item.label} checked={item.value} onChange={item.onChange}
                              disabled={item.isDisabled} style={item.isDisabled ? { opacity: "0.6" } : {}} />
                </div>
            );
            break;
        }
        case "phone": {
            if (viewMode) {
                rt.component = (
                    <div className={"item-text"}>
                        <a className="link" href={"tel:" + item.defaultValue}>
                            {item.defaultValue}
                        </a>
                    </div>
                );
                break;
            }

            rt.component = (
                <>
                    <Controller
                        name={item.var}
                        control={control}
                        rules={{
                            ...item.validate,
                            pattern: /\+7\040\(9[\d]{2}\)\040[\d]{3}\040[\d]{2}\040[\d]{2}/gm
                        }}
                        render={({ field }) => {
                            //@ts-ignore
                            return (
                                <NumberFormat
                                    {...field}
                                    className={"false-mdc-input-field"}
                                    name="mblz-phone"
                                    defaultValue={item.defaultValue}
                                    placeholder="+7 (9XX) XXX XX XX"
                                    format={"+7 (###) ### ## ##"}
                                    mask="-"
                                />
                            );
                        }}
                    />
                    {item.withEye && (
                        <Tooltip content={<div className="tooltip">Поле скрыто из публичного представления</div>}>
                            <Icon className="eye" icon="visibility_off"></Icon>
                        </Tooltip>
                    )}
                </>
            );
            break;
        }

        case "date": {
            if (viewMode) {
                rt.component = <div className={"item-text"}>{item.defaultValue}</div>;
                break;
            }

            rt.component = (
                <Controller
                    name={item.var}
                    control={control}
                    rules={{
                        ...item.validate,
                        pattern: /^(0?[1-9]|[12][0-9]|3[01])[\/\.](0?[1-9]|1[012])[\/\.]\d{4}$/
                    }}
                    render={({ field }) => {
                        //@ts-ignore
                        return (
                            <NumberFormat
                                {...field}
                                className={"false-mdc-input-field"}
                                name="mblz-phone"
                                defaultValue={item.defaultValue}
                                placeholder="ДД.ММ.ГГГГ"
                                mask={["Д", "Д", "М", "М", "Г", "Г", "Г", "Г"]}
                                format={"##.##.####"}
                            />
                        );
                    }}
                />
            );
            break;
        }

        case "period": {
            if (viewMode) {
                rt.component = (
                    <div className={"item-text"}>
                        {item.values.map(v => v.defaultValue && v.label + " " + v.defaultValue).join(" ")}
                    </div>
                );
                break;
            } else {
                rt.component = (
                    <div className={"item-periods"}>
                        {item.values.map((v, i) => (
                            <>
                                {v.label}
                                {v.isDisabled
                                    ? <div
                                        className={"false-mdc-input-field noSelect"}
                                        style={{
                                            opacity: "0.6",
                                            pointerEvents: "none"
                                        }}
                                    >
                                        {v.defaultValue}
                                    </div>
                                    : <Controller
                                        name={v.var}
                                        control={control}
                                        rules={{
                                            ...v.validate,
                                            pattern: /^(0?[1-9]|[12][0-9]|3[01])[\/\.](0?[1-9]|1[012])[\/\.]\d{4}$/
                                        }}
                                        render={({ field, fieldState }) => {
                                            //@ts-ignore
                                            return (
                                                <NumberFormat
                                                    {...field}
                                                    //@ts-ignore
                                                    invalid={errors.hasOwnProperty(v.var)}
                                                    className={"false-mdc-input-field"}
                                                    name="mblz-phone"
                                                    defaultValue={v.defaultValue}
                                                    // placeholder={v.placeholder}
                                                    format={"##.##.####"}
                                                    placeholder="ДД.ММ.ГГГГ"
                                                    mask={["Д", "Д", "М", "М", "Г", "Г", "Г", "Г"]}
                                                    onValueChange={e => v.onChange && v.onChange(e)}
                                                />
                                            );
                                        }}
                                    />
                                }
                            </>
                        ))}
                    </div>
                );

                for (const value of item.values) {
                    if (!errors.hasOwnProperty(value.var)) continue;

                    const errorType = errors[value.var].type;
                    const errorValue = value.validate![errorType];

                    rt.errorMessage.push(
                        value.errors?.[errorType] ??
                        (errorsText.hasOwnProperty(errorType) ? errorsText[errorType](errorValue) : errorType)
                    );
                }

                break;
            }
        }

        case "headline": {
            rt.component = <div className={"item-text-headline"}>{item.title}&nbsp;</div>;
            break;
        }
    }

    if (!rt.errorMessage.length && "var" in item && errors[item.var] && errors[item.var].type && item.validate) {
        const errorType = errors[item.var].type;
        const errorValue = item.validate[errorType];

        rt.errorMessage.push(
            item.errors?.[errorType] ?? (errorsText.hasOwnProperty(errorType) ? errorsText[errorType](errorValue) : errorType)
        );
    }

    return rt;
}
