import { Button } from '@rmwc/button'
import { Icon } from '@rmwc/icon'
import clsx from 'clsx'
import { FormField } from 'components'
import { FC, useContext, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Rating from 'react-rating'
import { PendingReviewContext } from '../../context'
import { isReview } from '../../pending-review-utils'
import { ReviewCommentFormFields, ReviewCommentFormProps } from './review-comment-form-def'
import './review-comment-form.sass'
import {InterfaceContext} from "../../../../../../../global/context.interface";

export const ReviewCommentForm: FC<ReviewCommentFormProps> = ({ className }): JSX.Element => {
	const { mode, selectedReview, onCreateReview, onDeleteReview, onUpdateReview, setIsDirty } =
		useContext(PendingReviewContext)

	const interfaceCTX = useContext(InterfaceContext)
	const {
		formState: { errors, isDirty },
		control,
		getValues,
		register,
		handleSubmit
	} = useForm<ReviewCommentFormFields>({
		mode: 'onChange',
		defaultValues: {
			score: (isReview(selectedReview) && selectedReview.review.score) || 0,
			comment: (isReview(selectedReview) && selectedReview.review.comment) || ''
		}
	})

	useEffect(() => {
		setIsDirty(isDirty)
	}, [isDirty])

	const submitHandler: SubmitHandler<ReviewCommentFormFields> = data => {
		mode === 'create'
			? onCreateReview(selectedReview.profile.id, {
					comment: data.comment ?? null,
					score: data.score,
					workerRecordID: selectedReview.contract.id
			  })
			: onUpdateReview({ comment: data.comment ?? null, score: data.score })

		interfaceCTX.checkIsFormChanged(false, 'review')
	}

	const deleteCommentHandler = () => isReview(selectedReview) && onDeleteReview(selectedReview.review.id)

	return (
		<form className={clsx('review-comment-form', className)} onSubmit={handleSubmit(submitHandler)}>
			<Controller
				name='score'
				control={control}
				rules={{
					validate: value => {
						if (mode === 'create' && value === 0) return 'Поставьте оценку'
					}
				}}
				render={({ field, fieldState: { error } }) => (
					<div className='review-comment-form-rating'>
						<Rating
							initialRating={getValues('score')}
							emptySymbol={<Icon className='review-comment-form-star review-comment-form-star--empty' icon='star' />}
							fullSymbol={<Icon className='review-comment-form-star' icon='star' />}
							{...field}
						/>
						<div className='review-comment-form-rating-error'>{error?.message}</div>
					</div>
				)}
			/>
			<FormField
				{...register('comment', {
					maxLength: {
						value: 160,
						message: 'Максимальное количество символов: 160'
					},
					validate: value => {
						interfaceCTX.checkIsFormChanged(true, 'review')
						const score = getValues('score')

						if (!value && score < 5 && score !== 0) return 'При оценке ниже 5 требуется комментарий'

						if (value && value.trim().length === 0) return 'Поле не может состоять только из пробелов'
					}
				})}
				className='review-comment-form-textarea'
				title='Написать отзыв'
				error={errors.comment?.message}
				textarea
				resizeable
				fullWidth
			/>
			<Button className='review-comment-form-button-save' type='submit' raised>
				{mode === 'create' ? 'Написать отзыв' : 'Сохранить изменения'}
			</Button>
			{mode === 'update' && (
				<Button outlined onClick={deleteCommentHandler}>
					Удалить
				</Button>
			)}
		</form>
	)
}
