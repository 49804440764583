import { Checkbox } from "@rmwc/checkbox";
import { Elevation } from "@rmwc/elevation";
import { Icon } from "@rmwc/icon";
import { PageTitle, FixedButtons, ProgressPage, Form } from "components";
import { req } from "global/common";
import { InterfaceContext } from "global/context.interface";
import { UserContext } from "global/context.user";
import { Role } from "global/definitions/definitions";
import { checkInputedAge, reformatToMask } from "pages/auth-as-worker/auth-as-worker-utils";
import React, { MutableRefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Params } from "../../../public-profile/components/PublicProfilePage/public-profile-def";
import { availableRoles, editUser } from "./EditUserPage.types";
import "./EditUserPage.style.sass";
import { Tooltip } from "@rmwc/tooltip";
import { RenderModuleNotPaid } from "../RenderModuleNotPaid";

export function EditUserPage() {
    const userCTX = React.useContext(UserContext);
    const history: any = useHistory();
    const interfaceCTX = React.useContext(InterfaceContext);
    const { searchID } = useParams() as Params;
    const { t } = useTranslation("common");

    const editUserForm = React.useRef() as MutableRefObject<HTMLFormElement>;

    const [userToEdit, setUserToEdit] = React.useState(null as any);
    const [cautionMsgs, setCautionMsgs] = React.useState([]);
    const [userRoles, setUserRoles] = React.useState(null as any);
    const [deletedRoles, setDeletedRoles] = useState<string[]>([]);

    const [showPage, setShowPage] = React.useState(false);

    const [refresh, setRefresh] = React.useState(false);

    let typeOfPage = "";

    if (history.location.pathname.includes("/users/delete-user/")) {
        typeOfPage = "delete";
    }
    if (history.location.pathname.includes("/users/edit-user/")) {
        typeOfPage = "edit";
    }
    if (history.location.pathname.includes("/users/restore-user/")) {
        typeOfPage = "restore";
    }

    React.useEffect(() => {
        req
            .get(`/profile/${searchID}/get`)
            .then(data => {
                if (data.data.isDeleted && typeOfPage != "restore") {
                    history.push("/404", {
                        prevPath: "/users"
                    });
                }
                if (!data.data.isDeleted && typeOfPage == "restore") {
                    history.push("/404", {
                        prevPath: "/users"
                    });
                }
                setUserToEdit(new editUser(data.data));
                setUserRoles(data.data.roles);
            })
            .catch(e => {
                interfaceCTX.showMessage({
                    body: t(e.response.data),
                    icon: "report"
                });
                setUserToEdit(false);
            });
        if (typeOfPage == "delete" || typeOfPage == "restore") {
            req
                .get(`/organisation/employee/${searchID}/${typeOfPage == "delete" ? "pre-delete" : "pre-restore"}`)
                .then(data => {
                    setCautionMsgs(data.data.cautions);
                })
                .catch(e => {
                    interfaceCTX.showMessage({
                        body: t(e.response.data),
                        icon: "report"
                    });
                });
        }
    }, [refresh]);

    function onSubmit(data: any) {
        // data.organisationID = userCTX!.user!.organisation.id
        // data.position = data.role.label
        data = { ...data, ...userToEdit.roles };
        let timeout: any;
        data.role = null;
        if (data.birthDate === "" || data.birthDate === undefined) {
            data.birthDate = null;
        }
        if (!Object.keys(userToEdit.roles).every(role => userToEdit.roles[role] == false)) {
            req
                .put(`/organisation/employee/${searchID}/update`, data)
                .then(() => {
                    // interfaceCTX.showMessage({
                    //     body: "Изменения сохранены",
                    //     icon: 'done'
                    // })
                })
                .then(() => {
                    availableRoles.map(role => {
                        if (data[role] && !userRoles.includes(role)) {
                            req
                                .post(`/organisation/employee/${userToEdit.id}/role/add`, { role: role })
                                .then(() => {
                                    setUserRoles((prevState: string[]) => {
                                        return [...prevState, role];
                                    });
                                    // interfaceCTX.showMessage({
                                    //     body: "Роли изменены",
                                    //     icon: 'done'
                                    // })
                                })
                                .catch(e => {
                                    interfaceCTX.showMessage({
                                        title: "Добавить роль не удалось",
                                        body: t("error." + e.response.data)
                                    });
                                });
                        }
                        if (!data[role] && userRoles.includes(role)) {
                            req
                                .post(`/organisation/employee/${userToEdit.id}/role/delete`, { role: role })
                                .then(() => {
                                    setUserRoles((prevState: string[]) => {
                                        return prevState.filter(item => item !== role);
                                    });
                                    // interfaceCTX.showMessage({
                                    //     body: "Роли изменены",
                                    //     icon: 'done'
                                    // })
                                })
                                .catch(e => {
                                    interfaceCTX.showMessage({
                                        title: "Удалить роль не удалось",
                                        body: t("error." + e.response.data)
                                    });
                                });
                        }
                    });
                })
                .then(() => {
                    if (userCTX.user?.id === userToEdit.id) {
                        interfaceCTX.showMessage({
                            body: "Данные успешно сохранены",
                            icon: "done"
                        });
                        if (!!timeout) {
                            clearTimeout(timeout);
                        }
                        timeout = setTimeout(() => {
                            interfaceCTX.refreshTopBar();
                        }, 2200);
                    } else {
                        interfaceCTX.showMessage({
                            body: "Данные успешно сохранены",
                            icon: "done"
                        });
                    }
                });
        } else {
            req.get(`/profile/${searchID}/get`).then(() =>
                interfaceCTX.showMessage({
                    body: "Должна остаться хотя бы одна роль",
                    icon: "report"
                })
            );
        }
    }

    function deleteOrRestoreUser(userID: string) {
        let errorMsgs = cautionMsgs.filter((msg: any) => msg.type === "ERROR");

        if (errorMsgs.length > 0) {
            req
                .get(`/profile/${searchID}/get`)
                .then(() =>
                    interfaceCTX.showMessage({
                        body: errorMsgs.map((msg: any) => msg.message).join("; "),
                        icon: "report"
                    })
                )
                .catch(e => {
                    interfaceCTX.showMessage({
                        body: t("error." + e.response.data),
                        icon: "report"
                    });
                });
        } else if (typeOfPage == "delete")
            req
                .delete(`/organisation/employee/${userID}/delete`)
                .then(() => {

                        interfaceCTX.showMessage({
                            body: "Пользователь удален",
                            icon: "report"
                        });
                    }
                )
                .then(() => history.goBack())
                .catch(e => {
                    interfaceCTX.showMessage({
                        body: t(e.response.data),
                        icon: "report"
                    });
                });
        else if (typeOfPage == "restore")
            req
                .put(`/organisation/employee/${userID}/restore`)
                .then(() =>
                    interfaceCTX.showMessage({
                        body: "Пользователь восстановлен",
                        icon: "done"
                    })
                )
                .then(() => history.goBack())
                .catch(e => {
                    interfaceCTX.showMessage({
                        body: t(e.response.data),
                        icon: "report"
                    });
                });
    }

    function changeRole(role: Role) {
        setUserToEdit((prevState: any) => {
            setDeletedRoles((prev: string[]) => {
                let newState = prev.length > 0 ? [...prev] : [];
                if (prev.includes(role)) {
                    newState = newState.filter(item => item !== role);
                } else if (prevState.roles[role]) {
                    newState.push(role);
                } else if (!prevState.roles[role]) {
                    newState = newState.filter(item => item !== role);
                } else {
                    newState = newState.filter(item => item !== role);
                }
                return newState;
            });
            return {
                ...prevState,
                roles: {
                    ...prevState.roles,
                    [role]: !prevState.roles[role]
                }
            };
        });
    }

    return (
        <div className="edit-user-page">
            <PageTitle
                title={
                    typeOfPage == "delete"
                        ? "Удаление пользователя"
                        : typeOfPage == "edit"
                            ? "Редактирование пользователя"
                            : "Восстановление пользователя"
                }
                breadcrumbs={true}
                // back={(history as any).location.state?.prevPath}
            ></PageTitle>
            <ProgressPage
                className="profile-page"
                state={userToEdit}
                render={() => (
                    <>
                        <Elevation z={1}>
                            <Form
                                name="editUserPage"
                                // validateMode={'onSubmit'}
                                viewMode={typeOfPage != "edit"}
                                onSubmit={onSubmit}
                                useRef={editUserForm}
                                data={[
                                    [
                                        {
                                            // title: 'Создать пользователя',
                                            values: [
                                                {
                                                    title: "Фамилия",
                                                    var: "lastName",
                                                    defaultValue: userToEdit && userToEdit.lastName,
                                                    validate: {
                                                        required: true,
                                                        maxLength: 30,
                                                        pattern: interfaceCTX.fioValidationPattern
                                                    },
                                                    errors: {
                                                        maxLength: "Не более 30 символов",
                                                        pattern: `Только кириллица, знаки "-", "_" и пробел`
                                                    }
                                                },
                                                {
                                                    title: "Имя",
                                                    var: "firstName",
                                                    defaultValue: userToEdit && userToEdit.firstName,
                                                    validate: {
                                                        required: true,
                                                        maxLength: 30,
                                                        pattern: interfaceCTX.fioValidationPattern
                                                    },
                                                    errors: {
                                                        maxLength: "Не более 30 символов",
                                                        pattern: `Только кириллица, знаки "-", "_" и пробел`
                                                    }
                                                },
                                                {
                                                    title: "Отчество",
                                                    var: "middleName",
                                                    defaultValue: userToEdit && userToEdit.middleName,
                                                    validate: {
                                                        maxLength: 30,
                                                        pattern: interfaceCTX.fioValidationPattern
                                                    },
                                                    errors: {
                                                        maxLength: "Не более 30 символов",
                                                        pattern: `Только кириллица, знаки "-", "_" и пробел`
                                                    }
                                                },
                                                {
                                                    type: "date",
                                                    title: "Дата рождения",
                                                    defaultValue: userToEdit && userToEdit.birthDate,
                                                    var: "birthDate",
                                                    validate: { required: false, validate: checkInputedAge },
                                                    errors: {
                                                        pattern: "Введите дату в формате ДД.ММ.ГГГГ",
                                                        validate: "Допустимый возраст от 18 до 65 лет"
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            // title: 'Создать пользователя',
                                            values:
                                                typeOfPage == "edit"
                                                    ? [
                                                        {
                                                            title: "Контактный e-mail",
                                                            var: "contactEmail",
                                                            defaultValue: userToEdit && userToEdit.contactEmail,
                                                            validate: {
                                                                required: true,
                                                                maxLength: 60,
                                                                pattern: interfaceCTX.emailValidationPattern
                                                            },
                                                            errors: {
                                                                pattern: "Введите валидную почту",
                                                                maxLength: "Введите не больше 60 символов"
                                                            }
                                                        },
                                                        {
                                                            type: "phone",
                                                            title: "Контактный телефон",
                                                            caption: "+ 7 (9XX) XXX XX XX",
                                                            var: "contactPhone",
                                                            defaultValue: userToEdit && reformatToMask(userToEdit.contactPhone),
                                                            validate: {
                                                                required: true
                                                            },
                                                            errors: {
                                                                pattern: "Введите номер в формате + 7 (9XX) XXX XX XX"
                                                            }
                                                        },
                                                        {
                                                            type: "other",
                                                            value: () => (
                                                                <div className="edit-user-page-roles-settings">
                                                                    <div className="edit-user-page-roles-header">Роль</div>
                                                                    <div className="edit-user-page-roles">
                                                                        <div className="edit-user-page-role">
                                                                            <Checkbox
                                                                                checked={userToEdit.roles.admin}
                                                                                onChange={() => {
                                                                                    changeRole("admin");
                                                                                }}
                                                                            />
                                                                            <div>Админ</div>
                                                                        </div>
                                                                        <div className="edit-user-page-role">
                                                                            <Checkbox
                                                                                checked={userToEdit.roles.recruiter}
                                                                                onChange={() => {
                                                                                    changeRole("recruiter");
                                                                                }}
                                                                            />
                                                                            <div>Рекрутер</div>
                                                                        </div>
                                                                        <div
                                                                            className={`edit-user-page-role${userCTX.user?.organisation.modulesStatus.hr !== "active" ? " edit-user-page-role-off" : ""}`}>
                                                                            {userCTX.user?.organisation.modulesStatus.hr !== "active" ?
                                                                                <Icon icon={"lock"} className={'lock'} />
                                                                                : <Checkbox
                                                                                    checked={userToEdit.roles.hr}
                                                                                    onChange={() => {
                                                                                        changeRole("hr");
                                                                                    }}
                                                                                />
                                                                            }
                                                                            {userCTX.user?.organisation.modulesStatus.hr === "active"
                                                                                ? <div>Кадровик</div>
                                                                                : <RenderModuleNotPaid moduleName={"hr"}/>
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className={`edit-user-page-role${userCTX.user?.organisation.modulesStatus.organizer !== "active" ? " edit-user-page-role-off" : ""}`}>
                                                                            {userCTX.user?.organisation.modulesStatus.organizer !== "active" ?
                                                                                <Icon icon={"lock"} className={'lock'} />
                                                                                : <Checkbox
                                                                                    checked={userToEdit.roles.organizer}
                                                                                    onChange={() => {
                                                                                        changeRole("organizer");
                                                                                    }}
                                                                                    disabled={userCTX.user?.organisation.modulesStatus.organizer !== "active"}
                                                                                />
                                                                            }
                                                                            {userCTX.user?.organisation.modulesStatus.organizer === "active"
                                                                                ? <div>Организатор</div>
                                                                                : <RenderModuleNotPaid moduleName={"organizer"}/>
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className={`edit-user-page-role${userCTX.user?.organisation.modulesStatus.foreman !== "active" ? " edit-user-page-role-off" : ""}`}>
                                                                            {userCTX.user?.organisation.modulesStatus.foreman !== "active" ?
                                                                                <Icon icon={"lock"} className={'lock'} />
                                                                                : <Checkbox
                                                                                    checked={userToEdit.roles.foreman == true}
                                                                                    onChange={() => {
                                                                                        changeRole("foreman");
                                                                                    }}
                                                                                    disabled={userCTX.user?.organisation.modulesStatus.foreman !== "active"}
                                                                                />
                                                                            }
                                                                            {userCTX.user?.organisation.modulesStatus.foreman === "active"
                                                                                ? <div>Прораб</div>
                                                                                : <RenderModuleNotPaid moduleName={"foreman"}/>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    ]
                                                    : [
                                                        {
                                                            title: "Контактный e-mail",
                                                            var: "contactEmail",
                                                            defaultValue: userToEdit && userToEdit.contactEmail,
                                                            validate: {
                                                                required: true,
                                                                maxLength: 60,
                                                                pattern: interfaceCTX.emailValidationPattern
                                                            },
                                                            errors: {
                                                                pattern: "Введите валидную почту",
                                                                maxLength: "Введите не больше 60 символов"
                                                            }
                                                        },
                                                        {
                                                            type: "phone",
                                                            title: "Контактный телефон",
                                                            caption: "+ 7 (9XX) XXX XX XX",
                                                            var: "contactPhone",
                                                            defaultValue: userToEdit && reformatToMask(userToEdit.contactPhone),
                                                            validate: {
                                                                required: true
                                                            },
                                                            errors: {
                                                                pattern: "Введите номер в формате + 7 (9XX) XXX XX XX"
                                                            }
                                                        }
                                                        // {
                                                        //     title: 'Роли', var: 'roles',
                                                        //     defaultValue: Object.keys(userToEdit.roles).filter(role => userToEdit.roles[role] === true).map(role => t('role.' + role)).join(' / '),
                                                        //     // validate: {
                                                        //     //     required: true, maxLength: 30, pattern: interfaceCTX.fioValidationPattern
                                                        //     // },
                                                        //     errors: {
                                                        //         maxLength: "Не более 30 символов",
                                                        //         pattern: `Только кириллица, знаки "-", "_" и пробел`
                                                        //     }
                                                        // },
                                                    ]
                                        }
                                    ]
                                ]}
                            />
                            {cautionMsgs.length > 0 && (
                                <div className="caution-msgs-wrapper">
                                    <div className="caution-msgs-inner">
                                        <h3 className="title">Обратите
                                            внимание</h3>
                                        {cautionMsgs &&
                                            cautionMsgs.map((msg: any, i) => (
                                                <div className="msg"
                                                     key={msg.type + msg.role + i}>
                                                    <div
                                                        className="alert-sign-wrapper">
                                                        {msg.type === "ERROR" ? (
                                                            <Icon
                                                                className="icon error"
                                                                icon="report_gmailerrorred"></Icon>
                                                        ) : (
                                                            <Icon
                                                                className="icon"
                                                                icon="warning_amber"></Icon>
                                                        )}
                                                    </div>
                                                    <div
                                                        className="text">{msg.message}</div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </Elevation>
                        <FixedButtons
                            length={4}
                            buttons={[
                                typeOfPage == "delete"
                                    ? {
                                        label: "Удалить",
                                        onClick: () => deleteOrRestoreUser(searchID)
                                    }
                                    : typeOfPage == "restore"
                                        ? {
                                            label: "Восстановить",
                                            primary: true,
                                            onClick: () => deleteOrRestoreUser(searchID)
                                        }
                                        : {
                                            label: "Сохранить",
                                            primary: true,
                                            onClick: () =>
                                                editUserForm.current.dispatchEvent(
                                                    new Event("submit", {
                                                        cancelable: true,
                                                        bubbles: true
                                                    })
                                                )
                                        }
                            ]}
                        />
                    </>
                )}
            />
        </div>
    );
}
