import React from 'react';
import {ISpoiler} from './Spoiler.types';
import { Elevation } from '@rmwc/elevation';
import { Icon } from '@rmwc/icon';
import './Spoiler.style.sass';
import {classList} from "../../global/common";
import { useHistory } from 'react-router';

export function Spoiler({
  title, caption, subTitle, subText, render
}: ISpoiler) {
    const [open, setOpen] = React.useState(false)

    const history = useHistory()

    return (
        <Elevation {...classList('spoiler', open && 'spoiler--open')} z={3}>
            <div className={'header'}
                onClick={() => setOpen((state: boolean) => !state)}
            >
                <h3 className={'title'}>
                    <span className={'text-title'}>{title}</span>
                    {caption &&
                        <span className={'caption'}>
                            {' ' + caption}
                        </span>
                    }
                </h3>

                {(subTitle && subText) &&
                    <div className={'subitem'}>
                        <span className={'subtitle'}>{subTitle}</span>
                        <span className={'subtext'}>{subText}</span>
                    </div>
                }

                <Icon icon={open ? 'expand_less' : 'expand_more'} />
            </div>

            {open && <div className={'body'}>{render(history)}</div>}
        </Elevation>
    )
}