import { LocationState } from 'global/definitions/definitions'
import { PagesRoutes } from 'global/routes'
import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { WorkerResponseStatisticsProps } from './worker-response-statistics-def'
import './worker-response-statistics.sass'

export const WorkerResponseStatistics: FC<WorkerResponseStatisticsProps> = ({
	pendingCount,
	considerationCount,
	rejectedCount
}): JSX.Element => {
	const { state } = useLocation<LocationState[]>()

	return (
		<div className='worker-response-statistics'>
			<Link
				to={{
					pathname: PagesRoutes.WORKER_NEED_LIST + '/pending',
					state
				}}
			>
				<div className=' worker-response-statistics-card'>
					<div className='worker-response-statistics-value'>{pendingCount}</div>
					<div className='worker-response-statistics-title'>Направлено откликов</div>
				</div>
			</Link>
			<Link
				to={{
					pathname: PagesRoutes.WORKER_NEED_LIST + '/consideration',
					state
				}}
			>
				<div className='worker-response-statistics-card'>
					<div className='worker-response-statistics-value'>{considerationCount}</div>
					<div className='worker-response-statistics-title'>На стадии переговоров</div>
				</div>
			</Link>
			<Link to={{ pathname: PagesRoutes.WORKER_NEED_LIST + '/rejected', state }}>
				<div className='worker-response-statistics-card'>
					<div className='worker-response-statistics-value'>{rejectedCount}</div>
					<div className='worker-response-statistics-title'>Отказ в приеме</div>
				</div>
			</Link>
		</div>
	)
}
