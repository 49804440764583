import {useHistory} from "react-router";
import React from "react";
import {VersionsHistoryList} from "./components/VersionsHistoryList/VersionsHistoryList";
import {VersionsHistoryItem} from "./components/VersionsHistoryRedact/VersionsHistoryItem";

export const VersionsHistory: React.FC<{platform: "mobile" | 'web'}> = ({platform}) => {
    const history = useHistory()

    return (
        <div className={'versions-history'}>
            {history.location.pathname.includes(`${platform}-list`) &&
                <VersionsHistoryList platform={platform}/>
            }
            {history.location.pathname.includes(`${platform}-redact`) &&
                <VersionsHistoryItem platform={platform}/>
            }
        </div>
    )
}