import React, { useContext, useState } from "react";
import { req, authorName, authorNameAbbreviated } from "../../global/common";
import { ProgressPage, Avatar, PageTitle, CustomDialog, LoadingProgress } from "components";
import { IconButton } from "@rmwc/icon-button";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../global/context.user";
import { Icon } from "@rmwc/icon";
import { TextField } from "@rmwc/textfield";
import { CircularProgress } from "@rmwc/circular-progress";
import { InterfaceContext } from "../../global/context.interface";
import { TableHeading, TableRow, Table, TableCell } from "../../components";
import { NameAndStatus } from "../../components/NameAndStatus/NameAndStatus";
import { Period } from "../../components/Period/Period";
import "./EmploymentPage.style.sass";
import { PagesRoutes } from "global/routes";
import Select from "react-select";
import {
    reasonAddingPackage,
    sizeNeedsSelectValues
} from "../auth-as-moderator/OrganizationProfile/components/PaymentHistory/PaymentHistory.service";
import parse from "date-fns/parse";
import { Checkbox } from "@rmwc/checkbox";
import { useTranslation } from "react-i18next";
import { IContractDialogSettings } from "./EmploymentPage.types";
import { differenceInCalendarDays } from "date-fns";

export function EmploymentPage() {
    const userCTX = React.useContext(UserContext);
    const [dialogSettings, setDialogSettings] = useState<IContractDialogSettings>({
        isOpen: false,
        newDate: ""
    });

    const [settingsError, setSettingsError] = useState<{ message: string } | null>(null);
    const [workers, setWorkers] = React.useState(null as any);
    const [search, setSearch] = React.useState("");
    const [workersTotal, setWorkersTotal] = React.useState(0);

    const [offsetForServer, setOffsetForServer] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [filter, setFilter] = React.useState("");

    const history = useHistory();
    const interfaceCTX = useContext(InterfaceContext);
    const { t } = useTranslation("common");

    let breakPointOffset = 10;
    let searchTimeout: NodeJS.Timer;

    let filterOffStyle = {
        backgroundColor: "transparent",
        outline: "none"
    };

    React.useEffect(() => {
        let targetValue = interfaceCTX.scrollTop + interfaceCTX.offsetHeight;
        if (
            interfaceCTX.scrollHeight > 0 &&
            interfaceCTX.scrollHeight - 20 <= targetValue &&
            workers &&
            workers.length < workersTotal &&
            search == ""
        ) {
            interfaceCTX.resetScrollValues();
            setOffsetForServer((state: number) => (state += breakPointOffset));
        }
    }, [interfaceCTX.scrollTop]);

    function getWorkers(limit: number, offset: number, search: string, filter: string) {
        if (workers != null) {
            setLoading(true);
        }

        let query = {
            limit: limit,
            offset: offset,
            search: search,
            status: filter
        };
        req
            .get("/hr/mobilization/employment/list", query)
            .then(({ data }) => {
                if (!data.total) {
                    throw new Error();
                }
                let newWorkers: any[] = [];
                setWorkersTotal(data.total);
                data.data.forEach((worker: any) => newWorkers.push(worker));
                setWorkers(newWorkers);
            })
            .then(() => setLoading(false))
            .catch(() => {
                setWorkers(false);
                setLoading(false);
            });
    }

    React.useEffect(() => {
        setWorkers(null);
        if (search == "") {
            getWorkers(breakPointOffset, 0, search, filter);
        } else {
            getWorkers(0, 0, search, filter);
        }
    }, [search, filter]);

    React.useEffect(() => {
        if (workers != null && offsetForServer < workersTotal) {
            setLoading(true);
        }

        let query = {
            limit: breakPointOffset,
            offset: offsetForServer,
            search: search,
            status: filter
        };
        req
            .get("/hr/mobilization/employment/list", query)
            .then(({ data }) => {
                if (!data.total) {
                    throw new Error();
                }
                let newWorkers: any[] = [];
                if (workers != null) {
                    newWorkers = workers!.splice(0);
                }
                setWorkersTotal(data.total);
                data.data.forEach((worker: any) => newWorkers.push(worker));

                setWorkers(newWorkers);
            })
            .then(() => setLoading(false))
            .catch(() => setWorkers(false));
    }, [offsetForServer]);

    function initFilter(status: "employed" | "expiration" | "fired" | "unilateral_contract_termination" | "dismissal_request") {
        setWorkers(null);
        setOffsetForServer(0);
        if (filter === status) {
            setFilter("");
        } else {
            setFilter(status);
        }
    }

    const updateEndDateContract = () => {
        let date = new Date(dialogSettings.newDate).toLocaleString("ru-RU").split(", ")[0];
        const currentDate = new Date(new Date()).toLocaleString("ru-RU").split(",")[0];
        if (!dialogSettings.isPerpetual && (parse(date, "dd.MM.yyyy", new Date()) as unknown as string) == "Invalid Date") {
            setSettingsError({ message: "Некорректная дата" });
            return;
        } else if (!dialogSettings.isPerpetual && date === dialogSettings.date) {
            setSettingsError({ message: "Новая дата окончания договора не может быть равна текущей дате окончания договора" });
            return;
        } else if (!dialogSettings.isPerpetual && differenceInCalendarDays(parse(date, "dd.MM.yyyy", new Date()).getTime(), parse(currentDate, "dd.MM.yyyy", new Date()).getTime()) <= 0) {
            setSettingsError({ message: "Новая дата окончания договора не может быть раньше завтрашнего дня" });
            return;
        } else if (!dialogSettings.isPerpetual && dialogSettings.workFrom && differenceInCalendarDays(parse(date, "dd.MM.yyyy", new Date()).getTime(), parse(dialogSettings.workFrom as string, "dd.MM.yyyy", new Date()).getTime()) < 0) {
            setSettingsError({ message: "Новая дата окончания договора не может быть ранее текущей даты начала договора" });
            return;
        }
        req.post(`/hr/mobilization/dismiss?contractId=${dialogSettings.contractId}&userId=${dialogSettings.userId}&newEndDate=${dialogSettings.isPerpetual ? null : date}`)
            .then(({ data }) => {
                closeDialog();
                interfaceCTX.showMessage({
                    body: `Новая дата окончания договора сохранена`
                });
                getWorkers(breakPointOffset, 0, search, filter);
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            })
            .finally(() => {
            });
    };

    const closeDialog = () => {
        setDialogSettings({
            isOpen: false,
            newDate: ""
        });
        setSettingsError(null)
    };

    return (
        <div className="mobilization-page">
            {loading && <LoadingProgress/>}

            <PageTitle title={"Трудоустройство"} breadcrumbs={true} />
            <CustomDialog
                isOpen={dialogSettings.isOpen}
                onClose={() => closeDialog()}
                dialogTitle={"Дата окончания договора"}
                buttons={[
                    {
                        callback: () => {
                            closeDialog();
                        },
                        outlined: false,
                        className: "dialog-work-to-btn",
                        raised: false,
                        text: "Отмена"
                    },
                    {
                        callback: () => {
                            updateEndDateContract();
                        },
                        outlined: true,
                        className: "dialog-work-to-btn",
                        raised: true,
                        text: "Сохранить"
                    }
                ]}
            >

                <div className={"dialog-work-to"}>
                    <div className={"dialog-work-to-description"}>
                        Если указать новую дату окончания договора позже текущей, то это продлит срок действия
                        договора, если указать новую дату окончания договора ранее текущей, то это сократит срок
                        действия договора
                    </div>
                    <div className={"dialog-work-to-field dialog-work-to-field-block"}>
                        <div className={"dialog-work-to-headline"}>
                            Текущая дата окончания договора
                        </div>
                        <div className={"dialog-work-to-change noSelect"}>
                            {dialogSettings.date ? dialogSettings.date : "Не указано"}
                        </div>
                    </div>
                    <div className={"dialog-work-to-field"}>
                        <div className={"dialog-work-to-headline"}>
                            Новая дата окончания договора
                        </div>
                        <TextField
                            outlined
                            invalid={!!settingsError}
                            className={"dialog-work-to-change"}
                            value={dialogSettings.newDate}
                            type="date"
                            maxLength={10}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                if (value.length > 10) return;
                                setSettingsError(null);
                                setDialogSettings(prevState => ({ ...prevState, newDate: value }));
                            }}
                        />
                        {settingsError &&
                            <div className={"dialog-work-to-error"}>
                                {settingsError.message}
                            </div>
                        }
                    </div>
                    <div className="dialog-work-to-checkbox">
                        <Checkbox
                            className={!!dialogSettings.isPerpetual ? "horosiy-checkbox" : "off-checkbox"}
                            checked={!!dialogSettings.isPerpetual}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                setDialogSettings(prevState => ({
                                    ...prevState,
                                    isPerpetual: !dialogSettings.isPerpetual
                                }));
                            }}
                        />
                        <div className="dialog-work-to-checkbox-text">Бессрочный договор</div>
                    </div>
                </div>
            </CustomDialog>
            <TextField
                outlined
                maxLength={160}
                onChange={function(e) {
                    clearTimeout(searchTimeout);

                    searchTimeout = setTimeout(() => {
                        setOffsetForServer(0);
                        setSearch((e.target as HTMLInputElement).value);
                    }, 500);
                }}
                className="search-input"
                label="Поиск по фамилии"
            >
                <Icon icon={"search"} className="search-icon" />
            </TextField>

            <div className={"sort-btns-panel"}>
                <div
                    onClick={() => initFilter("employed")}
                    className={"sort-btn pending"}
                    style={filter === "employed" ? {} : filterOffStyle}
                >
                    <div className={"sort-btn-text"}>Трудоустроен</div>
                    <Icon style={filter === "employed" ? {} : { display: "none" }} className={"close-sort-btn"}
                          icon={"close"} />
                </div>

                <div
                    onClick={() => initFilter("expiration")}
                    className={"sort-btn mobilize"}
                    style={filter === "expiration" ? {} : filterOffStyle}
                >
                    <div className={"sort-btn-text"}>Окончание действия договора</div>
                    <Icon style={filter === "expiration" ? {} : { display: "none" }} className={"close-sort-btn"}
                          icon={"close"} />
                </div>

                <div
                    onClick={() => initFilter("fired")}
                    className={"sort-btn demobilize"}
                    style={filter === "fired" ? {} : filterOffStyle}
                >
                    <div className={"sort-btn-text"}>Уволен</div>
                    <Icon
                        style={filter === "fired" ? {} : { display: "none" }}
                        className={"close-sort-btn"}
                        icon={"close"}
                    />
                </div>

                <div
                    onClick={() => initFilter("dismissal_request")}
                    className={"sort-btn deleted_user"}
                    style={filter === "dismissal_request" ? {} : filterOffStyle}
                >
                    <div className={"sort-btn-text"}>Запрос на увольнение</div>
                    <Icon
                        style={filter === "dismissal_request" ? {} : { display: "none" }}
                        className={"close-sort-btn"}
                        icon={"close"}
                    />
                </div>

                <div
                    onClick={() => initFilter("unilateral_contract_termination")}
                    className={"sort-btn deleted_user"}
                    style={filter === "unilateral_contract_termination" ? {} : filterOffStyle}
                >
                    <div className={"sort-btn-text"}>Односторонний отказ от договора</div>
                    <Icon
                        style={filter === "unilateral_contract_termination" ? {} : { display: "none" }}
                        className={"close-sort-btn"}
                        icon={"close"}
                    />
                </div>
            </div>

            <ProgressPage
                state={workers}
                render={
                    () => (
                        <Table className="mobilization-table">
                            <TableRow type="heading">
                                <TableHeading devider style={{ width: "12.41%" }}>
                                    ФОТО
                                </TableHeading>
                                <TableHeading devider style={{ width: "23%" }}>
                                    ФИО
                                </TableHeading>
                                <TableHeading devider style={{ width: "15%" }}>
                                    Статус
                                </TableHeading>
                                <TableHeading devider style={{ width: "20%" }}>
                                    Руководитель
                                </TableHeading>
                                <TableHeading devider style={{ width: "6%" }}>
                                    Договор
                                </TableHeading>
                                <TableHeading devider style={{ width: "6%" }}>
                                    Отзывы
                                </TableHeading>
                                <TableHeading devider style={{ width: "9%" }}>
                                    Дата начала
                                </TableHeading>
                                <TableHeading devider style={{ width: "9%" }}>
                                    Срок действия договора
                                </TableHeading>
                            </TableRow>
                            {workers &&
                                workers.map((dataItem: any, index: number) => {
                                    let mobilizeStatus: { color: any; text: any };

                                    if (dataItem.status === "employed") {
                                        mobilizeStatus = {
                                            color: "#00FF0A",
                                            text: "Трудоустроен"
                                        };
                                    } else if (dataItem.status === "expiration") {
                                        mobilizeStatus = {
                                            color: "#EBFF00",
                                            text: "Окончание действия договора"
                                        };
                                    } else if (dataItem.status === "fired") {
                                        mobilizeStatus = {
                                            color: "#9AA2B0",
                                            text: "Уволен"
                                        };
                                    } else if (dataItem.status === "unilateral_contract_termination") {
                                        mobilizeStatus = {
                                            color: "#ED0000",
                                            text: "Односторонний отказ от договора"
                                        };
                                    } else if (dataItem.status === "dismissal_request") {
                                        mobilizeStatus = {
                                            color: "#FF9900",
                                            text: "Запрос на увольнение"
                                        };
                                    } else {
                                        mobilizeStatus = {
                                            color: "#ED0000",
                                            text: ""
                                        };
                                    }

                                    return (
                                        <TableRow isProfileDeleted={dataItem.worker.profile.isDeleted} type="row"
                                                  key={index}
                                                  style={{ justifyContent: "center" }}>
                                            <TableCell devider style={{ width: "calc(12% + 5px)", padding: "0px" }}>
                                                <Avatar
                                                    isProfileDeleted={dataItem.worker.profile.isDeleted}
                                                    linkProps={{
                                                        pathname:
                                                            dataItem.worker.profile.isDeleted && !authorName(dataItem.worker.profile).includes("null")
                                                                ? PagesRoutes.WORKERS +
                                                                PagesRoutes.MOBILIZATION +
                                                                PagesRoutes.WORKER_CARD +
                                                                `/${dataItem.worker.profile.id}/${dataItem.worker.request.id}`
                                                                : PagesRoutes.WORKERS +
                                                                PagesRoutes.MOBILIZATION +
                                                                PagesRoutes.PUBLIC_PROFILE +
                                                                `/${dataItem.worker.profile.id}/${dataItem.worker.recordID}`,
                                                        state: history.location.state
                                                    }}
                                                    prevPath={history.location.pathname}
                                                    previewHref={dataItem.worker.profile.avatar?.pathPreview || dataItem.worker.profile.avatar}
                                                />
                                            </TableCell>
                                            <TableCell devider style={{ width: "23%" }}>
                                                {authorName(dataItem.worker.profile)}

                                            </TableCell>
                                            <TableCell className="mobilize-status" devider style={{ width: "15%" }}>
                                                <div className="mobilize-status-wrapper">
                                                    <div
                                                        className="mobilize-status-circle"
                                                        style={{ backgroundColor: mobilizeStatus.color }}
                                                    ></div>
                                                    <div className="mobilize-status">{mobilizeStatus.text}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell className="mobilize-status" devider style={{ width: "20%" }}>
                                                <div className="mobilize-status-wrapper">
                                                    {dataItem.supervisor ? authorNameAbbreviated({
                                                        firstName: dataItem.supervisor.split(" ")[1],
                                                        lastName: dataItem.supervisor.split(" ")[0],
                                                        middleName: dataItem.supervisor.split(" ")[2]
                                                    }) : "-"}
                                                </div>
                                            </TableCell>
                                            <TableCell devider style={{ width: "6%" }}>
                                                {!dataItem.worker.profile.isDeleted ? (
                                                    <div
                                                        className="item"
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                            history.push({
                                                                pathname:
                                                                    PagesRoutes.WORKERS +
                                                                    PagesRoutes.MOBILIZATION +
                                                                    PagesRoutes.CONTRACT_CARD +
                                                                    `/${dataItem.worker.recordID}`,
                                                                state: history.location.state
                                                            });
                                                        }}
                                                    >
                                                        <Icon className="icon description" icon={"description"} />
                                                    </div>
                                                ) : (
                                                    <div className="item" style={{ opacity: 0.38 }}>
                                                        <Icon className="icon description" icon={"description"} />
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell devider style={{ width: "6%" }}>
                                                {dataItem.worker.profile.isDeleted ? (
                                                    <IconButton
                                                        style={{ opacity: dataItem.worker.profile.isDeleted ? 0.38 : 1 }}
                                                        disabled={dataItem.worker.profile.isDeleted}
                                                        icon="thumb_up_off_alt"
                                                    />
                                                ) : (
                                                    <Link
                                                        className="mobilize-link"
                                                        style={{ color: "rgb(53, 76, 98)" }}
                                                        to={{
                                                            pathname:
                                                                dataItem.worker.profile.isDeleted &&
                                                                !authorName(dataItem.worker.profile).includes("null")
                                                                    ? PagesRoutes.WORKERS +
                                                                    PagesRoutes.MOBILIZATION +
                                                                    PagesRoutes.WORKER_CARD +
                                                                    `/${dataItem.worker.profile.id}/${dataItem.worker.request.id}`
                                                                    : PagesRoutes.WORKERS +
                                                                    PagesRoutes.MOBILIZATION +
                                                                    PagesRoutes.PUBLIC_PROFILE +
                                                                    `/${dataItem.worker.profile.id}/${dataItem.worker.recordID}`,
                                                            search: "?reviews=4",
                                                            state: history.location.state
                                                        }}
                                                    >
                                                        <IconButton disabled={dataItem.worker.profile.isDeleted}
                                                                    icon="thumb_up_off_alt" />
                                                    </Link>
                                                )}
                                            </TableCell>
                                            <TableCell devider style={{ width: "9%" }}>
                                                {dataItem.workFrom}
                                            </TableCell>
                                            <TableCell devider style={{ width: "9%" }}>
                                                <div>
                                                    <div
                                                        style={dataItem.status === "fired" ? {pointerEvents: 'none', opacity: '0.6'} : {}}
                                                        className={"contract-end-time"}
                                                        onClick={() => {
                                                            setDialogSettings(prevState => ({
                                                                ...prevState,
                                                                isOpen: true,
                                                                date: dataItem.workTo ? dataItem.workTo : null,
                                                                userId: dataItem.worker.profile.id,
                                                                contractId: dataItem.worker.recordID,
                                                                workFrom: dataItem.workFrom
                                                            }));
                                                        }}
                                                    >
                                                        {dataItem.workTo ? dataItem.workTo : "Не указано"}
                                                    </div>
                                                    {dataItem.isWorkToChanged &&
                                                        <div className={'contract-end-time-changed'}>
                                                            {'Изменено'}
                                                        </div>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </Table>
                    )
                }
            />
        </div>
    );
}
