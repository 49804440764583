export const downloadFile = (url: string, fullname: string) => {
    let [ext, ...name] = fullname.split(".").reverse();

    fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = blobUrl;
            link.setAttribute("download", `${name.reverse().join(".")}.${ext}`);
            document.body.appendChild(link);
            link.click();
        });
};