import { requestsApi, RequestShortListWorkerResponse, WorkerRequestListRequest } from 'global/api'
import { InterfaceContext } from 'global/context.interface'
import { useContext, useEffect, useState } from 'react'
import { GetRequestsResponseStatus } from '../requests/pages/worker-request-list/worker-request-list-def'

export const useRequests = (filterValues: Partial<WorkerRequestListRequest>) => {
	const { showMessage, scrollHeight, scrollTop, offsetHeight } = useContext(InterfaceContext)
	const [queryParams, setQueryParams] = useState({ limit: 30, offset: 0 })
	const [toggle, setToggle] = useState(false)
	const [data, setData] = useState<RequestShortListWorkerResponse>({
		data: [],
		total: 0,
		workerProfileFilling: { docs: 0, experience: 0, personal: 0 },
		workerResponseInfo: { consideration: [], pending: [], rejected: [] }
	})
	const [responseStatus, setResponseStatus] = useState<GetRequestsResponseStatus>({
		isSuccess: false,
		isFetching: null,
		isUploading: false
	})

	useEffect(() => {
		if (filterValues.mainProfession !== null) {
			setResponseStatus({ ...responseStatus, isUploading: false })

			requestsApi
				.getRequests({
					city: filterValues.city ?? null,
					gender: null,
					organisation: filterValues.organisation ?? null,
					limit: queryParams.limit,
					offset: queryParams.offset,
					salaryNetMin: filterValues.salaryNetMin ?? null,
					salaryNetMax: filterValues.salaryNetMax ?? null,
					mainProfession: filterValues.mainProfession ?? null,
					subProfessions: filterValues.subProfessions ?? null,
					type: filterValues.type ?? 'opds'
				})
				.then(response => {
					setResponseStatus({
						isSuccess: true,
						isFetching: response.data.total !== 0,
						isUploading: true
					})
					setData({
						data: [...data.data, ...response.data.data],
						total: response.data.total,
						workerProfileFilling: response.data.workerProfileFilling,
						workerResponseInfo: response.data.workerResponseInfo
					})
				})
				.catch(e => {
					setResponseStatus({
						isSuccess: false,
						isFetching: false,
						isUploading: false
					})
					showMessage({
						title: 'Ошибка',
						body: 'Ошибка при загрузке данных'
					})
				})
		}
	}, [queryParams, filterValues.mainProfession === null])

	useEffect(() => {
		if (responseStatus.isSuccess) {
			setResponseStatus({ ...responseStatus, isFetching: null })
			setData({ ...data, data: [] })
			setQueryParams({ ...queryParams, offset: 0 })
			setToggle(!toggle)
		}
	}, [filterValues])

	useEffect(() => {
		if (scrollTop > 0 && scrollTop + offsetHeight > scrollHeight - 10) {
			setQueryParams({ ...queryParams, offset: queryParams.offset + queryParams.limit })
		}
	}, [scrollTop])

	return {
		requests: data.data,
		pendingRequests: data.workerResponseInfo.pending,
		considerationRequests: data.workerResponseInfo.consideration,
		rejectedRequests: data.workerResponseInfo.rejected,
		profileFilling: data.workerProfileFilling,
		isSuccess: responseStatus.isSuccess,
		isFetching: responseStatus.isFetching,
		isUploading: responseStatus.isUploading
	}
}
