export const sizeNeedsSelectValues = [
    {
        value: 1,
        label: "1 потребность"
    },
    {
        value: 5,
        label: "5 потребностей"
    },
    {
        value: 10,
        label: "10 потребностей"
    },
    {
        value: 50,
        label: "50 потребностей"
    },
    {
        value: 100,
        label: "100 потребностей"
    },
    {
        value: 500,
        label: "500 потребностей"
    },
    {
        value: 1000,
        label: "1000 потребностей"
    }
];

export const sizeContactSelectValues = [
    {
        value: 150,
        label: 150
    },
    {
        value: 500,
        label: 500
    },
    {
        value: 1500,
        label: 1500
    },
    {
        value: 2000,
        label: 2000
    },
    {
        value: 5000,
        label: 5000
    }
];

export const reasonAddingPackage = [
    {
        value: "receiptPayment",
        label: "Поступление оплаты"
    },
    {
        value: "promo",
        label: "Промо"
    }

];

export const serviceTypeList = [
    {
        value: "contacts",
        label: "База рабочих"
    },
    {
        value: "coins",
        label: "Публикации"
    }
];

export const defaultPaymentItemValue = {
    price: undefined,
    reason: null,
    count: null,
    serviceType: null,
    rationale: null,
    startDate: '',
    endDate: ''
};
