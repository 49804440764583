import { AxiosPromise } from 'axios'
import { req } from 'global/common'
import { ShiftListResponse, ShiftStats, ShortShiftList } from './shift-def'

export const shiftApi = {
	getShifts: (dateStart: string, dateEnd?: string, onlyMine?: boolean): AxiosPromise<ShortShiftList> => {
		return req.get('/shift/list', { dateStart, dateEnd, onlyMine })
	},
	getShiftByDate: (date: string, onlyMine: boolean = false): AxiosPromise<ShiftListResponse> => {
		return req.get(`/shift/${date}`, { onlyMine })
	},
	getShiftStats: (): AxiosPromise<ShiftStats> => {
		return req.get('/shift/stats')
	}
}
