import React, { RefObject } from 'react'
import {IPreviewImg} from './PreviewImg.types'
import './PreviewImg.style.sass'
import { Icon } from '@rmwc/icon'

export function PreviewImg({ url, erasePreviewImgUrl }: IPreviewImg) {

    let wrapper: RefObject<HTMLDivElement> = React.createRef();

    return (
        <div ref={wrapper} className="zoomed-img-wrapper" style={{ display: `${url ? 'flex' : 'none'}` }}>
            <Icon onClick={erasePreviewImgUrl} icon={'close'} className="close-zoomed-preview">

            </Icon>
            <div className="zoomed-img-inner" style={{ backgroundImage: `url("${url}")` }}>

            </div>
        </div>
    )
}
