import React, { useEffect } from "react";
import { Drawer, DrawerContent } from "@rmwc/drawer";
import { useHistory, useLocation } from "react-router-dom";
import { List, ListItem, ListItemText } from "@rmwc/list";
import { PagesRoutes, routes } from "global/routes";
import { parse } from "path-to-regexp";
import { Icon } from "@rmwc/icon";
import { Link } from "react-router-dom";
import { InterfaceContext } from "../../global/context.interface";
import "@rmwc/list/styles";
import "@rmwc/drawer/styles";
import "./Navbar.style.sass";
import { UserContext } from "../../global/context.user";
import { ChatContext } from "../../global/context.chat";
import { Route } from "global/routes";
import { req } from "../../global/common";
import { ISurveyListItem } from "../../pages/SurveyListPage";
import { sortSurvey } from "./Navbar.service";
import { Button } from "@rmwc/button";
import { useTranslation } from "react-i18next";
import { stringToURL } from "../Form/Form.service";
import { CustomDialog } from "../CustomDialog";

export default function Navbar() {
    const history = useHistory();
    const location = useLocation();
    const [currentPath, setCurrentPath] = React.useState(null as any);
    const userCTX = React.useContext(UserContext);
    const chatCTX = React.useContext(ChatContext);
    const interfaceCTX = React.useContext(InterfaceContext);
    const { t } = useTranslation("common");
    const version = interfaceCTX.version;
    const [pages, setPages] = React.useState<Route[]>([]);
    const [surveyData, setSurveyData] = React.useState<{ surveyItem: ISurveyListItem | null, isSurveyCompleted: boolean, isOpenSurveyNotice: boolean }>({
        isSurveyCompleted: false,
        surveyItem: null,
        isOpenSurveyNotice: false
    });

    const getSurveyList = (requestType?: "notification") => {
        req.get("/profile/survey/list")
            .then(({ data }) => {
                if (data.data.length > 0 && data.suggestSurvey) {
                    setSurveyData(prevState => ({
                        ...prevState,
                        surveyItem: sortSurvey(data.data)[0],
                        isOpenSurveyNotice: requestType !== "notification"
                    }));
                } else {
                    setSurveyData(prevState => ({
                        ...prevState,
                        surveyItem: sortSurvey(data.data)[0]
                    }));
                    closeSurveyNotice();
                }
            });
    };

    React.useEffect(() => {
        setCurrentPath(history.location.pathname);
    }, [location]);

    const confirmViewingSurvey = () => {
        req.post(`/profile/survey/snooze-survey`)
            .then(({ data }) => {
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            });
    };

    const closeSurveyNotice = () => {
        sessionStorage.setItem("surveyCompleted", "true");
        setSurveyData(prevState => ({
            ...prevState,
            isOpenSurveyNotice: false
        }));
    };

    useEffect(() => {
        if (!userCTX.user || (!!userCTX.user && userCTX.user.currentRole === "moderator")) return;
        getSurveyList("notification");
        if (sessionStorage.getItem("surveyCompleted") === "true") return;
        let timerId = setTimeout(getSurveyList, 45000);

        return () => {
            clearTimeout(timerId);
        };
    }, []);


    React.useEffect(() => {
        setPages([...routes]);
        return () => {
            setPages([...[]]);
        };
    }, [userCTX.user]);

    if (!currentPath) return null;
    return (
        <Drawer id="navbar" className={interfaceCTX.showMenu ? "nav-opened" : "nav-closed"}>
            <DrawerContent>
                <Icon
                    onClick={() => interfaceCTX.showHideNav()}
                    className={interfaceCTX.showMenu ? "show-hide-menu" : "show-hide-menu-closed"}
                    icon={interfaceCTX.showMenu ? "menu_open" : "double_arrow"}
                ></Icon>
                {userCTX.user && (
                    <List>
                        {routes
                            .map(route =>
                                !sessionStorage.getItem("isHaveContracts") && route.path === PagesRoutes.CONTRACT_LIST
                                    ? { ...route, navBar: false }
                                    : route
                            )
                            .filter(
                                v =>
                                    ((v.navBar && (!v.availableRoles || v.availableRoles.includes(userCTX!.user!.roles[0]))) ||
                                        (v.navBar && userCTX!.user!.roles.length == 0 && v.availableRoles?.includes("pre-worker"))) &&
                                    v.show !== false
                            )
                            .map((v, i) => {
                                return (
                                    <div className="list-item" key={"list-item-" + i}>
                                        <Link
                                            to={{
                                                pathname: String(parse(v.path)[0]),
                                                state: [{ path: v.path, url: v.path, title: v.title }]
                                            }}
                                        >
                                            <ListItem
                                                activated={v.path.includes(currentPath.split("/")[1])}
                                            >
                                                {"/messages" === String(parse(v.path)[0]) && chatCTX.isNotification && (
                                                    <div className="navbar-notice" />
                                                )}
                                                <div className="icon">
                                                    <Icon icon={v.icon ? v.icon : "circle"} />
                                                </div>
                                                <ListItemText>{v.title}</ListItemText>
                                            </ListItem>
                                        </Link>
                                    </div>
                                );
                            })}
                    </List>
                )}
                <div className={"navbar-bottom"}>
                    {userCTX.user?.currentRole !== "moderator" && (interfaceCTX.showMenu
                        ? <div
                            className={"navbar-bottom-item " + `${history.location.pathname.includes("/feedback") ? "navbar-bottom-item-active" : ""}`}
                            onClick={() => {
                                history.push({
                                    pathname: "/feedback",
                                    state: [{ path: "/feedback", url: "/feedback", title: "Помощь" }]
                                });
                            }}
                        >
                            <Icon icon="sms_failed"></Icon>
                            Помощь
                            {surveyData.surveyItem && <div className={"navbar-bottom-item-notification"} />}
                        </div>
                        : <div
                            className={"navbar-bottom-item navbar-bottom-item-mini " + `${history.location.pathname.includes("/feedback") ? "navbar-bottom-item-active" : ""}`}
                            onClick={() => {
                                history.push({
                                    pathname: "/feedback",
                                    state: [{ path: "/feedback", url: "/feedback", title: "Помощь" }]
                                });
                            }}
                        >
                            <Icon icon="sms_failed"></Icon>
                            {surveyData.surveyItem && <div className={"navbar-bottom-item-notification"} />}
                        </div>)
                    }
                    <div
                        className={interfaceCTX.showMenu ? "version" : "version-mini"}>{interfaceCTX.showMenu ? `Версия: ${version}` : version}</div>
                    {!!userCTX.user && userCTX.user.currentRole !== "moderator" && surveyData.surveyItem &&
                        <CustomDialog
                            isOpen={surveyData.isOpenSurveyNotice}
                            onClose={() => {
                                confirmViewingSurvey();
                                closeSurveyNotice();
                            }}
                            dialogTitle={"Примите участие в опросе"}
                            buttons={[
                                {
                                    text: "Не сейчас",
                                    callback: () => {
                                        confirmViewingSurvey();
                                        closeSurveyNotice();
                                    },
                                    outlined: true
                                },
                                {
                                    text: "Пройти опрос",
                                    callback: () => {
                                        confirmViewingSurvey();
                                        closeSurveyNotice();
                                        history.push({
                                            pathname: "/feedback/survey-list",
                                            state: [{
                                                path: "/feedback/survey-list",
                                                url: "/feedback/survey-list",
                                                title: "Опрос"
                                            }]
                                        });
                                    },
                                    raised: true
                                }
                            ]}
                        >
                            <div style={{
                                padding: "8px 10px 15px",
                                width: "500px",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "16px",
                                textAlign: "center",
                                color: "#212121"
                            }}>
                                Здравствуйте! Мы хотели бы узнать Ваше мнение по некоторым вопросам, чтобы улучшить
                                сервис
                                WorkForce. Поэтому мы предлагаем Вам пройти небольшой опрос. Нажимая "Пройти опрос", Вы
                                будете перенаправлены на страницу с опросами.
                            </div>
                        </CustomDialog>
                    }
                </div>
            </DrawerContent>
        </Drawer>
    );
}
