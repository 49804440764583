import { Route } from "./routes-def";
import { initialWebRoles } from "./routes-utils";
import { Feedback } from "../../pages/Feedback";
import { WorkersDatabase } from "../../pages/WorkersDatabase";

export const workersDatabase: Route[] = [
    // { title: '7357', path: '/brigade-render', component: <TestPage />, navBar: false },
    {
        title: 'База рабочих',
        path: '/workers-database',
        component: <WorkersDatabase />,
        navBar: true,
        icon: 'ballot',
        availableRoles: [...initialWebRoles]
    },
]
