import React, { Fragment } from 'react'
import { Button } from '@rmwc/button'
import './form-field.sass'
import { FormFieldProps } from './form-field-def'

export const FormField = <TValue extends string | number | { id: number | string }>({
	value,
	title,
	caption,
	label,
	onClick,
	className,
	renderItem,
	style
}: FormFieldProps<TValue>) => {
	return (
		<div className={`form-field ${className}`}>
			{(title || caption) && (
				<div className='form-field-header'>
					{title && <h4 className='form-field-title'>{title}</h4>}
					{caption && <span className='form-field-caption'>{caption}</span>}
				</div>
			)}
			<div style={style && style}>
				{Array.isArray(value)
					? value.map((v, index) => {
							if (typeof v === 'number' || typeof v === 'string') {
								return <Fragment key={v}> {renderItem?.(v, index)}</Fragment>
							} else {
								return <Fragment key={v.id ? v.id : index}> {renderItem?.(v, index)}</Fragment>
							}
					  })
					: renderItem?.(value as TValue)}
			</div>
			<Button type='button' unelevated className='button' label={label} onClick={onClick} />
		</div>
	)
}
