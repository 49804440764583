import { differenceInCalendarDays, parse } from 'date-fns'

export const getSurveyStatus = (dateFrom: string, dateTo: string) => {
	const differenceDateFrom = differenceInCalendarDays(new Date(), parse(dateFrom, 'dd.MM.yyyy', new Date()))
	const differenceDateTo = differenceInCalendarDays(new Date(), parse(dateTo, 'dd.MM.yyyy', new Date()))

	switch (true) {
		case differenceDateFrom < 0:
			return 'новый'
		case differenceDateFrom <= 0 && differenceDateTo >= 0:
			return 'активен'
		case differenceDateTo > 0:
			return 'завершен'
		default:
			return 'активен'
	}
}
