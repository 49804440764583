import NeedPage from "pages/need-page/need-page";
import NeedsListPage from "pages/needs-list/needs-list";
import { Dump } from "pages/public-worker-profile/dump/dump";
import { PublicWorkerProfilePage } from "pages/public-worker-profile/public-worker-profile";
import { RespondedWorkers } from "pages/RespondedWorkers/RespondedWorkers";
import { WorkerCardPage } from "pages/WorkerCardPage/WorkerCardPage";
import { WorkerExperiensePage } from "pages/WorkerExperiencePage/WorkerExperiencePage";
import { Route } from "./routes-def";
import { initialWebRoles, PagesRoutes } from "./routes-utils";
import { UserProfilePage } from "../../pages/UserProfilePage";
import { VacanciesList } from "../../pages";
import { VacancyPage } from "../../pages/VacancyPage";

export const needListRoutes: Route[] = [
	//Список потребностей
	{
		title: 'Список вакансий',
		path: PagesRoutes.VACANCIES_LIST,
		component: <VacanciesList />,
		navBar: true,
		icon: 'person_search',
		availableRoles: ["admin", "recruiter"]
	},
	{
		title: 'Вакансия',
		path: PagesRoutes.VACANCIES_LIST + PagesRoutes.VACANCY_PAGE + "/:id",
		component: <VacancyPage />,
		navBar: false,
		availableRoles: ["admin", "recruiter"]
	},
	{
		title: 'Отклики',
		path: PagesRoutes.VACANCIES_LIST + '/response-list/:id',
		component: <RespondedWorkers />,
		navBar: false,
		availableRoles: ["admin", "recruiter"]
	},
	{
		title: 'Публичный профиль',
		path: PagesRoutes.VACANCIES_LIST + PagesRoutes.PUBLIC_PROFILE + '/:userId?/:vacancyId?/:responseId?',
		component: <PublicWorkerProfilePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	// {
	// 	title: 'Заявки',
	// 	path: PagesRoutes.NEEDS_LIST,
	// 	component: <NeedsListPage />,
	// 	navBar: true,
	// 	icon: 'description',
	// 	availableRoles: initialWebRoles
	// },
	{
		title: 'Потребность',
		path: PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + '/:searchID',
		component: <NeedPage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Профиль пользователя',
		path: `${PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + PagesRoutes.USERS}/:userId`,
		component: <UserProfilePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Публичный профиль',
		path: PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + PagesRoutes.PUBLIC_PROFILE + '/:searchID?/:recordID?',
		component: <PublicWorkerProfilePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + PagesRoutes.PUBLIC_PROFILE + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка сотрудника',
		path: PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + PagesRoutes.WORKER_CARD + '/:searchID?/:recordID?',
		component: <Dump />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + PagesRoutes.WORKER_CARD + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка договора',
		path: PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + PagesRoutes.CONTRACT_CARD + '/:searchID?',
		component: <WorkerCardPage />,
		navBar: false,
		availableRoles: initialWebRoles
	}
]
