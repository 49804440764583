import { shiftApi, ShiftStats } from 'global/api'
import { InterfaceContext } from 'global/context.interface'
import { useContext, useEffect, useState } from 'react'

export const useShiftStats = () => {
	const { showMessage } = useContext(InterfaceContext)
	const [isSuccess, setIsSuccess] = useState(false)
	const [shiftStats, setShiftStats] = useState<ShiftStats>({} as ShiftStats)

	useEffect(() => {
		shiftApi
			.getShiftStats()
			.then(response => {
				setShiftStats(response.data)
				setIsSuccess(true)
			})
			.catch(e => {
				showMessage({
					title: 'Ошибка',
					body: 'Ошибка при загрузке статистики'
				})
				setIsSuccess(false)
			})
	}, [])

	return {
		shiftStats,
		isSuccess
	}
}
