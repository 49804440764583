import { PageTitle } from "../../components";
import React, { useContext, useEffect, useState } from "react";
import "./EmployeeTraining.style.sass";
import { isSuperAdm, req } from "../../global/common";
import { IEmployeeTraining } from "./EmployeeTraining.types";
import { InterfaceContext } from "../../global/context.interface";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { PagesRoutes } from "../../global/routes";
import { UserContext } from "../../global/context.user";

export const EmployeeTraining = () => {
    const interfaceCTX = useContext(InterfaceContext);
    const location = useLocation();
    const userCTX = useContext(UserContext)
    const { t } = useTranslation("common");
    const [marketplaceData, setMarketplaceData] = useState<IEmployeeTraining[]>([]);

    const getMarketplaceData = () => {
        req.get("/marketplace/web/list")
            .then(({ data }) => {
                setMarketplaceData(data.data);
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            });
    };

    const signUpForCourse = (id: number) => {
        req.get(`/marketplace/web/${id}/apply`)
            .then(({ data }) => {
                setMarketplaceData((prevState) => {
                    return prevState.map((item) => {
                        if (id === item.id) {
                            return { ...item, ordered: true };
                        } else {
                            return item;
                        }
                    });
                });
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            });
    };

    useEffect(() => {
        getMarketplaceData();
    }, []);

    return (
        <div className="employee-training-page">
            <PageTitle title={"Обучение сотрудников"} breadcrumbs={true} />
            <div className={"employee-training-list"}>
                {marketplaceData.length > 0 && marketplaceData.map((item) => {
                    if (item.url) {
                        return (
                            <a
                                key={item.id}
                                href={item.url}
                                target="_blank"
                                className={"employee-training-item"}
                                onClick={() => {
                                    if(!item.ordered && !isSuperAdm(userCTX.user)) {
                                        signUpForCourse(item.id)
                                    }
                                }}
                            >
                                <img src={item.imageUrl} />
                            </a>
                        );
                    } else {
                        return (
                            <Link to={{
                                pathname: PagesRoutes.TRAINING + "/" + item.id,
                                state: location.state
                            }}
                                  key={item.id}
                                  className={"employee-training-item"}
                            >
                                <img src={item.imageUrl} />
                            </Link>
                        );
                    }
                })}
            </div>
        </div>
    );
};