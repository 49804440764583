import React from 'react'
import {ExtendedCustomTable} from '../../../components/ExtendedCustomTable/ExtendedCustomTable'

import {TableCell} from '../../../components/Table/TableCell/TableCell'
import {
    IColumnSettings,
    IExtendedCustomTableApiURLS,
    ISortingAndFilteringArray
} from '../../../components/ExtendedCustomTable/ExtendedCustomTable.types'
import {getWorkerStatusColor, workersSettings} from './workers-utils'
import {useTranslation} from 'react-i18next'
import {IWorker} from './workers-def'
import './workers.sass'
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router'
import {PagesRoutes} from 'global/routes'
import {req} from "../../../global/common";
import {format} from "date-fns";

export const WorkersPage = () => {
    const {t} = useTranslation('common')
    const history = useHistory()

    const apiURLS: IExtendedCustomTableApiURLS = {
        getTableValues: '/moderator/workers/list',
        getUniqueColumnValues: '/moderator/workers/unique',
        getTableSettings: '/moderator/workers/table/settings/get',
        saveTableSettings: '/moderator/workers/table/settings/set'
    }

    const getOrganizationKey = (id: number) => {
        req.post(`/moderator/organisation/key?organisationId=${id}`)
            .then(({data}) => {
                history.push({
                    pathname: `/workers-list/organization-profile/${data.organisationId}/${data.key}`,
                    state: history.location.state
                })
            })
    }

    const renderFilterValue = (
        filterValue: ISortingAndFilteringArray['filterType'],
        columnType: IColumnSettings['columnType']
    ) => {
        let values: string = ''
        if (!!filterValue) {
            if (columnType === 'activeStatus') {
                values = filterValue.map(item => t('extended_custom_table.' + item)).join(', ')
                return values
            } else if (columnType.toLowerCase().includes('date')) {
                values = filterValue.join(' - ')
            } else {
                values = filterValue.join(', ')
            }
            return values
        }
        return values
    }

    const arrayStrings = (array: string[]) => {
        let result: string = ''
        array.map((item: string, index: number) => {
            if (index === 0) {
                result = item
            } else if (index === array.length - 1) {
                result = `${result}, ${item}.`
            } else if (index !== 0) {
                result = `${result}, ${item}`
            }
        })
        return result
    }

    const renderRow = (
        tableValue: any,
        columnSettings: IColumnSettings[],
        cellIsActive: (columnType: string, columnSettings: IColumnSettings[]) => string
    ) => {
        return columnSettings.map((column, index) => {
            if (column.columnType === 'activeStatus' && column.visibility) {
                return (
                    <TableCell
                        key={column.columnType + index}
                        devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: '100%'
                        }}
                    >
                        <div className={'worker-status'}>
                            <div
                                className={'worker-status-color'}
                                style={{backgroundColor: getWorkerStatusColor(tableValue.activeStatus)}}
                            />
                            <div
                                className={'worker-status-text'}>{t('extended_custom_table.' + tableValue.activeStatus)}</div>
                        </div>
                    </TableCell>
                )
            } else if (column.columnType === 'currentContract' && column.visibility) {
                return (
                    <TableCell
                        key={column.columnType + index}
                        devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: '100%'
                        }}
                    >
                        {tableValue.currentContract ? (
                            <div
                                className={'worker-name'}
                                onClick={() => {
                                    getOrganizationKey(tableValue.currentContract.id)
                                }}
                            >
                                {tableValue.currentContract.shortName}
                            </div>
                        ) : (
                            ''
                        )}
                    </TableCell>
                )
            } else if (column.columnType === 'fullName' && column.visibility) {
                return (
                    <TableCell
                        key={column.columnType + index}
                        devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: '100%'
                        }}
                    >
                        <Link
                            className={'worker-name'}
                            to={{
                                pathname: '/workers-list' + PagesRoutes.PUBLIC_PROFILE + `/${tableValue.id}`,
                                state: history.location.state
                            }}
                        >
                            {tableValue[column.columnType]}
                        </Link>
                    </TableCell>
                )
            } else if (column.columnType === 'email' && column.visibility) {
                return (
                    <TableCell
                        key={column.columnType + index}
                        devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: '100%'
                        }}
                    >
                        <a
                            href={`mailto:${tableValue.email}`}
                            className='worker-name'
                            style={{
                                pointerEvents: `${(tableValue.activeStatus === 'user_delete' || tableValue.email === 'Не указан') ? 'none' : 'auto'}`,
                                textDecoration: `${(tableValue.activeStatus === 'user_delete' || tableValue.email === 'Не указан') ? 'none' : 'underline'}`
                            }}
                        >
                            {tableValue.email}
                        </a>
                    </TableCell>
                )
            } else if (column.columnType === 'additionalProfession' && column.visibility) {
                return (
                    <TableCell
                        key={column.columnType + index}
                        devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: '100%'
                        }}
                    >
                        {arrayStrings(tableValue.additionalProfession)}
                    </TableCell>
                )
            } else if (['registrationDate', 'deletedDate'].includes(column.columnType) && column.visibility) {
                return (
                    <TableCell
                        key={column.columnType + index}
                        devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: '100%'
                        }}
                    >
                        {tableValue[column.columnType] && format(new Date(tableValue[column.columnType]), 'dd.MM.yyyy')}
                    </TableCell>
                )
            } else if (column.columnType && column.visibility) {
                return (
                    <TableCell
                        key={column.columnType + index}
                        devider
                        style={{
                            minWidth: column.minWidth,
                            background: cellIsActive(column.columnType, columnSettings),
                            height: '100%'
                        }}
                    >
                        {tableValue[column.columnType]}
                    </TableCell>
                )
            } else return <></>
        })
    }

    const reorderData = (columnSettings: IColumnSettings[], thereIs: (value: any) => any, tableValues: any[]) => {
        return tableValues.map((value: IWorker) => {
            const item: any = {} as IWorker
            columnSettings.forEach(column => {
                if (column.columnType === 'activeStatus' && column.visibility) {
                    return (item[column.columnType] = t('extended_custom_table.' + value.activeStatus))
                } else if (column.columnType === 'currentContract' && column.visibility) {
                    return (item[column.columnType] = value.currentContract ? value.currentContract.fullName : '')
                } else if (['registrationDate', 'deletedDate'].includes(column.columnType) && column.visibility) {
                    //@ts-ignore
                    return (item[column.columnType] = value[column.columnType] && format(new Date(value[column.columnType]), 'dd.MM.yyyy'))
                } else if (column.columnType === 'additionalProfession' && column.visibility) {
                    return (item[column.columnType] = arrayStrings(value.additionalProfession))
                } else if (column.columnType && column.visibility) {
                    //@ts-ignore
                    return (item[column.columnType] = thereIs(value[column.columnType]))
                }
            })
            return item
        })
    }

    return (
        <>
            <ExtendedCustomTable
                rootClassName={'workers-page'}
                renderFilterValue={renderFilterValue}
                renderRow={renderRow}
                apiURLS={apiURLS}
                settings={workersSettings}
                pageTitle={'Работники'}
                formattedTableValuesForEXEL={reorderData}
                paginateFilter={true}
            />
        </>
    )
}
