import {Icon} from "@rmwc/icon";
import {formatDate, formatTime} from "../../../../global/common";
import React, {useContext, useEffect, useState} from "react";
import {ChatContext} from "../../../../global/context.chat";
import {download} from "../../messages-page-utils";
import {IMessages, IMessageComponent, ImessageData} from "../../messages-page-def";
import {useAutoCloseDialog} from "../../../../custom-hooks/useAutoCloseDialog";
import {emitCustomEvent, useCustomEventListener} from "react-custom-events";
import matrix, {MatrixEvent} from "matrix-js-sdk";
import {differenceInCalendarDays, formatDistance, formatRelative, subDays} from "date-fns";
import ruLang from "date-fns/locale/ru";
import {CustomDialog} from "../../../../components/CustomDialog/CustomDialog";
import {InterfaceContext} from "../../../../global/context.interface";


export const Messages: React.FC<IMessages> = ({
                                                  messages,
                                                  roomId,
                                                  startForwardingMessage
                                              }) => {
    const {client} = useContext(ChatContext);
    const [isOpenMessageMenu, setIsOpenMessageMenu] = useState(null)

    useAutoCloseDialog(() => {
        setIsOpenMessageMenu(null)
    }, [])

    const parseEventData = () => {
        let prevData: number | null = null
        let isFirstDate = true
        let isPrevDay = false
        let event = new MatrixEvent({
            room_id: roomId,
            event_id: messages[messages.length - 1].event_id,
        });
        let isRead = true
        const room = client?.getRoom(messages[messages.length - 1].room_id)
        // room?.client.sendReadReceipt(event)
        // room.client.setRoomReadMarkers()
        // client?.getReceiptsForEvent()
        const anotherUserId = room?.getMembers().filter(item => item.userId !== client?.getUserId())[0].userId
        return messages.slice(0).map((eventData: any, index: number) => {
            let events = new MatrixEvent({
                room_id: eventData.room_id,
                event_id: messages[index].event_id,
            });
            // console.log(room?.getReceiptsForEvent(eventData));
            const ts = eventData.origin_server_ts;
            isPrevDay = !!(prevData && differenceInCalendarDays(prevData, ts) !== 0);
            prevData = ts
            const sender = eventData.sender;
            const readReceipts = room?.getUsersReadUpTo(events)
            const content = eventData.content;
            const eventId = eventData.event_id;

            const roomId = eventData.room_id
            const isMe = sender === client?.getUserId();
            let data = {
                typeEvent: eventData.type,
                msgType: content.msgtype ? content.msgtype.toLowerCase() : 'none',
                timeline: ts,
                messageOwner: content.messageOwner ? content.messageOwner : null,
                message: content.body ? content.body : null,
                url: content.url ? content.url : null,
                readReceipts: isRead,
                isFirstDate: isFirstDate,
                eventId: eventId,
                roomId: roomId,
                isMe: isMe,
                sender: sender,
                info: content.info ? content.info : null,
                isNewContent: !!content['m.new_content']
            }
            if (isFirstDate) {
                isFirstDate = false
            }
            if (isRead && readReceipts && readReceipts.includes(anotherUserId as string)) {
                isRead = false
            }
            if (!data.message) return
            switch (data.typeEvent) {
                case "m.room.message":
                    switch (data.msgType) {
                        case "m.video":
                            break;
                        case "m.image":
                            if (data.isNewContent) return (
                                <div
                                    key={eventData.event_id}
                                    className='chat-list-item'>
                                    {(isPrevDay || data.isFirstDate) &&
                                        <div className='chat-list-date'>{formatDate(ts as any)}</div>}
                                </div>
                            )
                            return (
                                <div
                                    key={eventData.event_id}
                                    className='chat-list-item'>
                                    {(isPrevDay || data.isFirstDate) &&
                                        <div className='chat-list-date'>{formatDate(ts as any)}</div>}
                                    <MessageComponent
                                        parsedEventData={{
                                            isMe: data.isMe,
                                            url: data.url,
                                            message: data.message,
                                            timeline: data.timeline,
                                            eventId: data.eventId,
                                            roomId: data.roomId as string,
                                            info: data.info,
                                            msgType: data.msgType,
                                            sender: data.sender,
                                            messageOwner: data.messageOwner,
                                            readReceipts: data.readReceipts

                                        }}
                                        setIsOpenMessageMenu={setIsOpenMessageMenu}
                                        isOpenedMessageId={isOpenMessageMenu}
                                        startForwardingMessage={startForwardingMessage}
                                    />
                                </div>
                            );
                        case "m.file":
                            if (data.isNewContent) return (
                                <div
                                    key={eventData.event_id}
                                    className='chat-list-item'>
                                    {(isPrevDay || data.isFirstDate) &&
                                        <div className='chat-list-date'>{formatDate(ts as any)}</div>}
                                </div>
                            )
                            return (
                                <div
                                    key={eventData.event_id}
                                    className='chat-list-item'>
                                    {(isPrevDay || data.isFirstDate) &&
                                        <div className='chat-list-date'>{formatDate(ts as any)}</div>}
                                    <MessageComponent
                                        parsedEventData={{
                                            isMe: data.isMe,
                                            url: data.url,
                                            message: data.message,
                                            timeline: data.timeline,
                                            eventId: data.eventId,
                                            roomId: data.roomId as string,
                                            info: data.info,
                                            msgType: data.msgType,
                                            sender: data.sender,
                                            messageOwner: data.messageOwner,
                                            readReceipts: data.readReceipts

                                        }}
                                        setIsOpenMessageMenu={setIsOpenMessageMenu}
                                        isOpenedMessageId={isOpenMessageMenu}
                                        startForwardingMessage={startForwardingMessage}
                                    />
                                </div>
                            );
                        case "m.audio":
                            break;
                        case "m.text":
                            if (data.isNewContent) return (
                                <div
                                    key={eventData.event_id}
                                    className='chat-list-item'>
                                    {(isPrevDay || data.isFirstDate) &&
                                        <div className='chat-list-date'>{formatDate(ts as any)}</div>}
                                </div>
                            )
                            return (<div
                                    key={eventData.event_id}
                                    className='chat-list-item'>
                                    {(isPrevDay || data.isFirstDate) &&
                                        <div className='chat-list-date'>{formatDate(ts as any)}</div>}
                                    <MessageComponent
                                        parsedEventData={{
                                            isMe: data.isMe,
                                            url: data.url,
                                            message: data.message,
                                            timeline: data.timeline,
                                            eventId: data.eventId,
                                            roomId: data.roomId as string,
                                            info: data.info,
                                            msgType: data.msgType,
                                            sender: data.sender,
                                            messageOwner: data.messageOwner,
                                            readReceipts: data.readReceipts

                                        }}
                                        setIsOpenMessageMenu={setIsOpenMessageMenu}
                                        isOpenedMessageId={isOpenMessageMenu}
                                        startForwardingMessage={startForwardingMessage}
                                    />
                                </div>
                            );
                        case "none":
                            if (data.isNewContent) return (
                                <div
                                    key={eventData.event_id}
                                    className='chat-list-item'>
                                    {(isPrevDay || data.isFirstDate) &&
                                        <div className='chat-list-date'>{formatDate(ts as any)}</div>}
                                </div>
                            )
                            return (<div
                                    key={eventData.event_id}
                                    className='chat-list-item'>
                                    {(isPrevDay || data.isFirstDate) &&
                                        <div className='chat-list-date'>{formatDate(ts as any)}</div>}
                                    <MessageComponent
                                        isOpenedMessageId={isOpenMessageMenu}
                                        setIsOpenMessageMenu={setIsOpenMessageMenu}
                                        parsedEventData={{
                                            isMe: data.isMe,
                                            url: data.url,
                                            message: data.message,
                                            timeline: data.timeline,
                                            eventId: data.eventId,
                                            roomId: data.roomId as string,
                                            info: data.info,
                                            msgType: data.msgType,
                                            sender: data.sender,
                                            messageOwner: data.messageOwner,
                                            readReceipts: data.readReceipts
                                        }}/>
                                </div>
                            )

                    }
                    break
                case "m.room.redaction":
                    return (
                        <div
                            key={eventData.event_id}
                            className='chat-list-item'>
                            {(isPrevDay || data.isFirstDate) &&
                                <div className='chat-list-date'>{formatDate(ts as any)}</div>}
                        </div>
                    )
            }
        })
    }

    return (
        parseEventData()
    )
}

const MessageComponent: React.FC<IMessageComponent> = ({
                                                           parsedEventData,
                                                           startForwardingMessage,
                                                           isOpenedMessageId,
                                                           setIsOpenMessageMenu
                                                       }) => {
    const {client} = useContext(ChatContext);
    const [isOpen, setIsOpen] = useState(false)
    const [messageData, setMessageData] = useState<IMessageComponent['parsedEventData']>(parsedEventData)
    const chatCTX = useContext(ChatContext)
    const interfaceCTX = React.useContext(InterfaceContext);
    const renderMessage = () => {
        let res: any[] = []
        //@ts-ignore
        messageData.message && messageData.message.replace(/((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim, (m: string, link: string, text: string) => {
            res.push(link ?
                <a href={(link[0] === "w" ? "//" : "") + link} target="_blank" key={res.length}>{link}</a> : text)
        })

        return <div className="user-text">{res}</div>
    }

    useCustomEventListener("m.room.message.redact", (data: { body: string, eventId: string }) => {
        if (data.eventId === messageData.eventId) {
            setMessageData((prevState: IMessageComponent['parsedEventData']) => ({
                ...prevState,
                message: data.body
            }))
        }
    })

    const [menuPosition, setMenuPosition] = useState(false)

    if (messageData.isDelete) return null
    else return (
        <div className={`chat-message ${messageData.isMe ? 'right-message' : 'left-message'}`}>
            <div className='chat-message-body'>
                <div className='chat-message-body-content'>
                    {messageData.messageOwner &&
                        <div className='chat-message-link-user'
                             onClick={() => {
                                 if (messageData.messageOwner !== client?.getUserId()) {
                                     chatCTX.sendMessage({chatCredentials: messageData.messageOwner})
                                 }
                             }}>
                            <Icon/>
                            <div>Пересланное сообщение</div>
                        </div>
                    }
                    {!!messageData.url && !!messageData.message && messageData.info.mimetype.includes('image')
                        ? <div className='chat-message-body-value' style={{flexDirection: 'column'}}>
                            <div className='chat-message-file-image'>
                                <div
                                    style={{backgroundImage: `url(${client?.mxcUrlToHttp(messageData.url as string)})`}}/>
                                <Icon icon={'zoom_in'} className="zoom_in"
                                      onClick={() => interfaceCTX.getPreviewImgUrl((client?.mxcUrlToHttp(messageData.url as string) as string))}/>
                            </div>
                            {messageData.message
                                ? <div className='chat-message-text'>
                                    {renderMessage()}
                                </div>
                                : <div className='chat-message-text-delete'>
                                    <Icon icon='delete'/>
                                    <div className='chat-message-text-delete-text'>
                                        Сообщение удалено
                                    </div>
                                </div>
                            }
                        </div>
                        : <div className='chat-message-body-value'>
                            {!!messageData.url && !!messageData.message &&
                                <div className='chat-message-file-back'
                                     onClick={() => download(client?.mxcUrlToHttp(messageData.url as string) as string, messageData.message as string)}
                                >
                                    <Icon className='chat-message-file-icon' icon='description'/>
                                </div>
                            }
                            {messageData.message
                                ? <div className='chat-message-text'>
                                    {renderMessage()}
                                </div>
                                : <div className='chat-message-text-delete'>
                                    <Icon icon='delete'/>
                                    <div className='chat-message-text-delete-text'>
                                        Сообщение удалено
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {!!messageData.message &&
                    <Icon
                        className='chat-message-body-button'
                        icon='more_vert'
                        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {                            //199 143

                            setMenuPosition(document.body.offsetHeight / 3 >= e.pageY)
                            e.stopPropagation()
                            setIsOpenMessageMenu(isOpenedMessageId === messageData.eventId ? null : messageData.eventId)
                        }}
                    />
                }
                {isOpenedMessageId === messageData.eventId &&
                    <div className={`chat-message-menu ${menuPosition ? 'chat-message-menu-top' : ''}`}
                         onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                             e.stopPropagation()
                         }}
                    >
                        <div className='chat-message-menu-items'>
                            {startForwardingMessage &&
                                <div className='chat-message-menu-item'
                                     onClick={() => {
                                         startForwardingMessage({
                                             body: messageData.message as string,
                                             messageOwner: messageData.sender,
                                             msgType: messageData.msgType,
                                             url: messageData.url as string,
                                             info: messageData.info,
                                         })
                                         setIsOpenMessageMenu(null)
                                     }}
                                >
                                    <div>
                                        Переслать сообщение
                                    </div>
                                </div>
                            }
                            {!!messageData.url && !!messageData.message && messageData.info.mimetype.includes('image') &&
                                <div className='chat-message-menu-item'
                                     onClick={() => download(client?.mxcUrlToHttp(messageData.url as string) as string, messageData.message as string)}
                                >
                                    <div>
                                        Скачать
                                    </div>
                                </div>
                            }
                            {!messageData.messageOwner && messageData.isMe && messageData.msgType === "m.text" &&
                                <div className='chat-message-menu-item'
                                     onClick={() => {
                                         emitCustomEvent('chat-message-redaction', {
                                             eventId: messageData.eventId,
                                             message: messageData.message as string
                                         })
                                         setIsOpenMessageMenu(null)
                                     }}
                                >
                                    <div>
                                        Редактировать
                                    </div>
                                </div>
                            }
                            {messageData.isMe &&
                                <div className='chat-message-menu-item'
                                     onClick={() => {
                                         setIsOpen(true)
                                     }}
                                >
                                    <div>
                                        Удалить
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            <CustomDialog
                isOpen={isOpen}
                buttons={[
                    {
                        text: 'Отмена',
                        callback: () => setIsOpen(false),
                        outlined: true,
                    },
                    {
                        text: 'Удалить',
                        callback: () => {
                            let content = {
                                body: '', //Новый текст сообщения
                                msgtype: 'm.text',
                                "m.new_content": {
                                    body: '', //Новый текст сообщения
                                    msgtype: 'm.text',
                                },
                                "m.relates_to": {
                                    rel_type: "m.replace",
                                    event_id: messageData?.eventId, //id редактируемого эвента
                                }
                            }
                            client?.sendEvent(messageData.roomId, "m.room.message", content, "").then((res) => {
                                setIsOpenMessageMenu(null)
                                setIsOpen(false)
                            })

                            // client?.redactEvent(messageData.roomId, messageData.eventId, '', () => {
                            //     setIsOpenMessageMenu(null)
                            //     emitCustomEvent('chat-message-delete', messageData.eventId)
                            //     setMessageData((prevState: IMessageComponent['parsedEventData']) => ({
                            //         ...prevState,
                            //         message: null,
                            //         isDelete: true
                            //     }))
                            //     setIsOpen(false)
                            // })
                        },
                        raised: true
                    }
                ]}
                onClose={() => setIsOpen(false)}
                dialogTitle={'Удалить это сообщение?'}>
                <div style={{
                    width: '565px',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center'
                }}>
                    <div style={{margin: '0 auto'}}>
                        Это действие удалит сообщение и у получателя.
                    </div>
                </div>
            </CustomDialog>
            <div className='chat-massage-time'>
                <div>{formatTime(messageData.timeline)}</div>
                {/*{messageData.isMe &&*/}
                {/*    <>*/}
                {/*        {messageData.readReceipts*/}
                {/*            ? <Icon style={{color: "#5C6E8C"}} icon={{icon: 'done_all', size: 'xsmall'}}/>*/}
                {/*            : <Icon icon={{icon: 'done_all', size: 'xsmall'}}/>}*/}
                {/*    </>*/}
                {/*}*/}
            </div>
        </div>
    )
}