import React, { FC, useCallback, useMemo, useState } from 'react'
import { FormField } from '../form-field/form-field'
import { FormFieldLabel } from '../form-field-label/form-field-label'
import { PermissionsFieldProps, Option } from './permissions-field-def'
import { CheckboxOptionsDialog } from '../checkbox-options-dialog/checkbox-options-dialog'

export const PermissionsField: FC<PermissionsFieldProps> = ({ value, onChange, search, onChangeSearch, options }) => {
	const optionsByValue = useMemo(
		() =>
			(options || []).reduce<Record<Option['value'], Option>>((result, option) => {
				const { value } = option
				result[value] = option
				return result
			}, {}),
		[options]
	)

	const [isOpenOptionsDialog, setIsOpenOptionsDialog] = useState(false)
	const handleOpenOptionsDialog = useCallback(() => {
		setIsOpenOptionsDialog(true)
	}, [])
	const handleCloseOptionsDialog = useCallback(() => {
	/* 	onChangeSearch?.('') */
		setIsOpenOptionsDialog(false)
	}, [onChangeSearch])

	const handleDeleteValue = useCallback(
		id => {
			onChange?.((value || []).filter(valueId => valueId !== id))
		},
		[value, onChange]
	)

	return (
		<>
			<FormField
				value={value}
				label='Выбрать допуски'
				onClick={handleOpenOptionsDialog}
				renderItem={permissionId => {
					const option = permissionId && optionsByValue[permissionId]

					if (!option) {
						return <></>
					}
					const { name } = option
					return <FormFieldLabel title={name} value={permissionId} onDelete={handleDeleteValue} />
				}}
			/>
			<CheckboxOptionsDialog
				title='Допуски'
				description='Вы можете выбрать до 5 допусков'
				open={isOpenOptionsDialog}
				hasSearch={true}
				search={search}
				onChangeSearch={onChangeSearch}
				defaultValues={value}
				options={options}
				onClose={handleCloseOptionsDialog}
				onSuccess={onChange}
			/>
		</>
	)
}
