import { Button } from "@rmwc/button";
import { Dialog, SimpleDialog } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { IconButton } from "@rmwc/icon-button";
import { ListDivider } from "@rmwc/list";
import { PageTitle, ProgressPage, FixedButtons, Form, SpanButtons, CustomDialog } from "components";
import { differenceInCalendarDays, differenceInDays } from "date-fns";
import parse from "date-fns/parse";
import { InterfaceContext } from "global/context.interface";
import { UserContext } from "global/context.user";
import { PagesRoutes } from "global/routes";
import React, { MutableRefObject, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { authorName, formatDate, req } from "../../global/common";
import { FormNeed, FormNeedSend, Mode, Need, Params } from "./need-page-def";
import "./need-page.sass";
import { CurrentTariff } from "../../components/CurrentTariff";
import { Author } from "../../global/definitions/definitions";
import { PageHeader } from "components/PageHeader/PageHeader";

function scheduleDescription(value: any): JSX.Element | null {
    let rt;
    switch (value?.value) {
        case "1":
            rt = "Пятидневная рабочая неделя по 8 часов в день, № 40 ч.";
            break;
        case "2":
            rt = "Сокращённая пятидневная рабочая неделя по 7 часов в день, № 36 ч.";
            break;
        case "3":
            rt = "2х-сменный, 4х-бригадный. Продолжительность рабочей смены 11,5 часов (день/ночь/48)";
            break;
        case "4":
            rt = "1-сменный, 2х-бригадный. Продолжительность рабочей смены 11,5 часов (2x2)";
            break;
        case "5":
            rt = "Вахтовый. Продолжительность рабочей смены 11,5 часов (месяц через месяц)";
            break;
    }
    if (!rt) return null;
    return (
        <>
            <div className={"item-title item-title--view"}>Описание</div>
            <div className={"item-text"}>{rt}</div>
        </>
    );
}

function scheduleHours(value: any): JSX.Element | null {
    let rt;
    switch (value?.value) {
        case "1":
            rt = "164";
            break;
        case "2":
            rt = "143";
            break;
        case "3":
            rt = "175";
            break;
        case "4":
            rt = "175";
            break;
        case "5":
            rt = "236";
            break;
    }
    if (!rt) return null;
    return (
        <>
            <div className={"item-title item-title--view"}>Среднее количество часов в месяц</div>
            <div className={"item-text"}>{rt}</div>
        </>
    );
}

function getSchedule(id?: string) {
    const options = [
        { label: "№1 - обычный", value: "1" },
        { label: "№2 - сокращённый", value: "2" },
        { label: "№3 - сменный", value: "3" },
        { label: "№4 - сменный", value: "4" },
        { label: "№5 - вахта", value: "5" }
    ];
    if (id) return options.find(v => v.value === id);
    return options;
}

export default function NeedPage() {
    const { searchID } = useParams() as Params;
    const { t } = useTranslation("common");
    const history = useHistory();

    const userCTX = React.useContext(UserContext);
    const interfaceCTX = React.useContext(InterfaceContext);

    const formRef = React.createRef() as MutableRefObject<HTMLFormElement>;
    const orgID = userCTX.user?.organisation?.id as number;
    const isModerator = userCTX.user?.currentRole === "moderator";
    const [load, setLoad] = React.useState<boolean | null>(null);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openBackDialog, setOpenBackDialog] = React.useState(false);
    const [withoutProbation, setWithoutProbation] = React.useState(false);

    const [mode, setMode] = React.useState("view" as Mode);
    const [need, setNeed] = React.useState({} as Need);
    const [quanityEdit, setQuanityEdit] = React.useState(false);
    const [needChanged, setNeedChanged] = React.useState(false);
    const [wantToPublish, setWantToPublish] = React.useState(false);
    const [isWaitingForServer, setIsWaitingForServer] = React.useState(false);
    // const [refresh, setRefresh] = React.useState(false)
    let buttonClick = "";

    React.useEffect(() => {
        if (!searchID) return;
        if (searchID === "create") {
            req.get("/requests/organisation/fields-settings/get")
                .then(({ data }) => {
                    let need = new Need({
                        state: "create",
                        publicationDate: null,
                        city: null,
                        organisation: userCTX.user?.organisation.shortName,
                        position: null,
                        quantity: null,
                        grade: null,
                        category: null,
                        subdivision: null,
                        supervisor: null,
                        organizer: null,
                        subordinates: null,
                        responsibleForThePreparationOfPersonnelDocuments: null,
                        employmentDecisionMaker: null,
                        responsibilities: null,
                        address: null,
                        schedule: null,
                        probationDuration: null,
                        salaryFullNet: null,
                        salaryFullGross: null,
                        salaryProbationNet: null,
                        probation: null,
                        salaryProbationGross: null,
                        ageMin: null,
                        ageMax: null,
                        gender: null,
                        education: null,
                        additionalComments: null,
                        hiringReason: null,
                        history: [],
                        responses: [],
                        workFrom: null,
                        workTo: null,
                        settings: data
                    });
                    setMode("edit");
                    setNeed({ ...need, author: userCTX.user as unknown as Author });
                    setLoad(true);
                })
                .catch(e => {
                    setLoad(false);
                    interfaceCTX.showMessage({
                        title: "Ошибка",
                        body: t(e.response.data)
                    });
                });
        } else {
            req
                .get(`/requests/organisation/${searchID}/get`)
                .then(({ data }) => {
                    const need = new Need(data);
                    if (isModerator) {
                        setMode("view");
                    } else {
                        let mode: Mode = "edit";
                        if (need.state == "published") {
                            setWantToPublish(true);
                        }
                        switch (need.state) {
                            case "published":
                            case "closed_manual":
                            case "closed_completed":
                            case "deleted": {
                                mode = "view";
                                break;
                            }

                            case "withdrawn_from_publication": {
                                mode = "view";
                                break;
                            }

                            case "created": {
                                if (need.author.roles.includes("foreman")) {
                                    mode = "view";
                                    break;
                                }
                                mode = "edit";
                                break;
                            }
                            case "on_approval": {
                                // if ((userCTX.userHasRole('hr') && (Need.author.id == userCTX!.user!.id || Need.author.roles.includes("foreman")) || userCTX.userHasRole('admin'))) {
                                mode = "edit";
                                break;
                                // }
                                // mode = 'view'
                                // break
                            }
                        }
                    }

                    setMode(mode);
                    setNeed(need);
                    setLoad(true);
                })
                .catch(e => {
                    setLoad(false);
                    interfaceCTX.showMessage({
                        title: "Ошибка",
                        body: t(e.response.data)
                    });
                });
        }

    }, [searchID, needChanged]);

    const isMode = <VIEW, EDIT>(view: VIEW, edit: EDIT): VIEW | EDIT => {
        switch (mode) {
            case "view":
                return view;
            case "edit":
                return edit;
        }
    };

    const onSubmit = (formNeed: FormNeed) => {
        formNeed.salaryProbationGross = Number(formNeed.salaryProbationGross) == 0 ? "" : formNeed.salaryProbationGross;
        formNeed.salaryProbationNet = Number(formNeed.salaryProbationNet) == 0 ? "" : formNeed.salaryProbationNet;
        if (formNeed.ageMin > formNeed.ageMax) {
            req.get(`/requests/organisation/fields-settings/get`).then(() =>
                interfaceCTX.showMessage({
                    body: `Возраст "от" ${formNeed.ageMin} не может быть больше возраста "до" ${formNeed.ageMax}`,
                    icon: "report"
                })
            );
            return;
        }
        let dateEndParsed = parse(formNeed.workTo, "dd.MM.yyyy", new Date());
        let dateStartParsed = parse(formNeed.workFrom, "dd.MM.yyyy", new Date());
        const currentDate = new Date(new Date()).toLocaleString("ru-RU").split(",")[0].split(".");
        if (differenceInCalendarDays(parse(formNeed.workFrom, "dd.MM.yyyy", new Date()).getTime(), parse(currentDate.join("."), "dd.MM.yyyy", new Date()).getTime()) <= 0 && ["on_approval", "create"].includes(need.state)) {
            req.get(`/requests/organisation/fields-settings/get`).then(() =>
                interfaceCTX.showMessage({
                    body: `Дата начала договора не может быть раньше текущего дня`,
                    icon: "report"
                })
            );
            return;
        }
        if (dateStartParsed <= dateEndParsed) {
            const differenceBtwDates = differenceInDays(dateEndParsed, dateStartParsed);
            if (differenceBtwDates < 13) {
                req.get(`/requests/organisation/fields-settings/get`).then(() =>
                    interfaceCTX.showMessage({
                        title: "Ошибка",
                        body: "Минимальный срок действия договора - 14 дней",
                        icon: "report"
                    })
                );
                return;
            }
            if (quanityEdit) {
                setNeed({
                    ...need,
                    quantity: formNeed.quantity
                });
                // Need.quantity = data.quantity
                setQuanityEdit(false);
            }
            switch (buttonClick) {
                case "save": {
                    if (need.id) {
                        req.post(`/requests/organisation/${searchID}/update`, new FormNeedSend(formNeed))
                            .then(() => {
                                interfaceCTX.showMessage({
                                    body: "Данные успешно сохранены",
                                    icon: "done"
                                });
                                setNeedChanged(!needChanged);
                            })
                            .catch(e => {
                                interfaceCTX.showMessage({
                                    title: "Ошибка",
                                    body: t(
                                        e.response.data.includes("dayOfMonth")
                                            ? "Неверный формат даты. Проверьте правильно ли указан день и/или месяц"
                                            : e.response.data
                                    )
                                });
                            });
                    } else {
                        req.post(`/requests/organisation/create`, new FormNeedSend(formNeed))
                            .then(({ data }) => {
                                setNeedChanged(!needChanged);
                                let currentHistoryState = Array.isArray(history.location.state) ? history.location.state : [];
                                history.push({
                                    pathname: PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + `/${data.id}`,
                                    state: [...currentHistoryState].filter(item => item.title !== "Потребность")
                                });
                                interfaceCTX.showMessage({
                                    body: "Данные успешно сохранены",
                                    icon: "done"
                                });
                            })
                            .catch(e => {
                                interfaceCTX.showMessage({
                                    title: "Ошибка",
                                    body: t(
                                        e.response.data.includes("dayOfMonth")
                                            ? "Неверный формат даты. Проверьте правильно ли указан день и/или месяц"
                                            : e.response.data
                                    )
                                });
                            });
                    }
                    break;
                }

                case "publish": {
                    // methods.
                    setIsWaitingForServer(true);
                    if (need.id) {
                        req
                            .post(`/requests/organisation/${need.id}/update`, new FormNeedSend(formNeed))
                            .then(() => req.post(`/requests/organisation/${need.id}/publish`))
                            .then(() => {
                                userCTX.changeCoinsCount();
                                setNeed(prevState => ({
                                    ...prevState,
                                    daysBeforeTheEndPublication: prevState.daysBeforeTheEndPublication + 30
                                }));
                                setNeedChanged(!needChanged);
                            })
                            .then(() => {
                                interfaceCTX.showMessage({
                                    body: "Заявка успешно опубликована",
                                    icon: "done"
                                });
                            })
                            .then(() => setIsWaitingForServer(false))
                            .catch(e => {
                                if (e.response.data === "request.no_have_work_coins") {
                                    setIsOpenErrorDialog(true);
                                } else {
                                    interfaceCTX.showMessage({
                                        title: "Ошибка",
                                        body: t(
                                            e.response?.data.includes("dayOfMonth")
                                                ? "Неверный формат даты. Проверьте правильно ли указан день и/или месяц"
                                                : e.response.data
                                        )
                                    });
                                }
                                setIsWaitingForServer(false);
                            });
                    } else {
                        let newId: number;
                        req.post(`/requests/organisation/create`, new FormNeedSend(formNeed))
                            .then(({ data }) => req.post(`/requests/organisation/${data.id}/update`, new FormNeedSend(formNeed)))
                            .then(({ data }) => {
                                newId = data.id;
                                req.post(`/requests/organisation/${data.id}/publish`);
                            })
                            .then(() => {
                                userCTX.changeCoinsCount();
                                interfaceCTX.showMessage({
                                    body: "Заявка успешно опубликована",
                                    icon: "done"
                                });
                                setMode("view");
                                setNeedChanged(!needChanged);
                                let currentHistoryState = Array.isArray(history.location.state) ? history.location.state : [];
                                history.push({
                                    pathname: PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + `/${newId}`,
                                    state: [...currentHistoryState].filter(item => item.title !== "Потребность")
                                });
                            })
                            .then(() => setIsWaitingForServer(false))
                            .catch(e => {
                                if (e.response.data === "request.no_have_work_coins") {
                                    setIsOpenErrorDialog(true);
                                } else {
                                    interfaceCTX.showMessage({
                                        title: "Ошибка",
                                        body: t(
                                            e.response?.data.includes("dayOfMonth")
                                                ? "Неверный формат даты. Проверьте правильно ли указан день и/или месяц"
                                                : e.response.data
                                        )
                                    });
                                }
                                setIsWaitingForServer(false);
                            });
                    }
                    return;
                }
            }
        } else if (
            (dateStartParsed as unknown as string) == "Invalid Date" ||
            (dateEndParsed as unknown as string) == "Invalid Date"
        ) {
            req.get(`/requests/organisation/fields-settings/get`).then(() =>
                interfaceCTX.showMessage({
                    body: "Неверный формат даты. Проверьте правильно ли указан день и/или месяц",
                    icon: "report"
                })
            );
        } else {
            req.get(`/requests/organisation/fields-settings/get`).then(() =>
                interfaceCTX.showMessage({
                    body: "Дата окончания договора не может быть меньше даты начала",
                    icon: "report"
                })
            );
        }
    };

    const needEdit = () => {
        if (need.responses.total) setQuanityEdit(true);
        else setMode("edit");
    };
    const needPublish = () => {
        setWantToPublish(true);
        buttonClick = "publish";
        formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };
    const needSave = () => {
        buttonClick = "save";
        if (need.state == "published") {
            setWantToPublish(true);
        } else {
            setWantToPublish(false);
        }
        formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };

    const needDelete = () => {
        if (need.responses.accepted) {
            interfaceCTX.showMessage({
                body: "Потребность с принятыми на работу сотрудниками удалить нельзя",
                icon: "report"
            });
        } else {
            req
                .delete(`/requests/organisation/${searchID}/delete`)
                .then(() => {
                    history.push({
                        pathname: "/needs-list",
                        state: [{ path: "/needs-list", url: "/needs-list", title: "Список потребностей" }]
                    });
                })
                .catch(e =>
                    interfaceCTX.showMessage({
                        body: t(e.response.data),
                        icon: "report"
                    })
                );
        }
    };

    function inverseCheckboxValue(e: React.ChangeEvent<HTMLInputElement>) {
        setWithoutProbation(!!e.currentTarget.checked);
    }

    const [isOpenDialogPublish, setIsOpenDialogPublish] = useState(false);
    const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(false);

    function declOfNum(n: number, text_forms: string[]) {
        n = Math.abs(n) % 100;
        let n1 = n % 10;
        if (n > 10 && n < 20) {
            return text_forms[2];
        }
        if (n1 > 1 && n1 < 5) {
            return text_forms[1];
        }
        if (n1 == 1) {
            return text_forms[0];
        }
        return text_forms[2];
    }

    const extendNeed = () => {
        req.post(`/requests/organisation/prolong?requestID=${searchID}`)
            .then(({ data }) => {
                setIsOpenDialogPublish(false);
                userCTX.changeCoinsCount();
                setNeed(prevState => ({
                    ...prevState,
                    state: "published",
                    daysBeforeTheEndPublication: prevState.daysBeforeTheEndPublication + 30
                }));
            })
            .catch(e => {
                    setIsOpenDialogPublish(false);
                    if (e.response.data === "request.no_have_work_coins") {
                        setIsOpenErrorDialog(true);
                    } else {
                        interfaceCTX.showMessage({
                            body: t(e.response.data),
                            icon: "report"
                        });
                    }
                }
            );
    };

    const methods = useForm();

    return (
        <div className="need-page">
            <ProgressPage
                state={load}
                render={() => (
                    <>
                        <CustomDialog
                            isOpen={isOpenDialogPublish}
                            onClose={() => setIsOpenDialogPublish(false)}
                            dialogTitle={"Продлить срок размещения публикации?"}
                            buttons={[{
                                callback: () => setIsOpenDialogPublish(false),
                                outlined: true,
                                raised: false,
                                text: "Нет, не надо"
                            }, {
                                callback: () => extendNeed(),
                                outlined: true,
                                raised: true,
                                text: "Да, продлить"
                            }]}
                        >
                            <div style={{
                                padding: "20px 50px",
                                width: "500px",
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "19px",
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                justifyContent: "center",
                                color: "#12324F",
                                flexDirection: "column"
                            }}>
                                {/*Срок публикации заканчивается*/}
                                {/*через {need.daysBeforeTheEndPublication + declOfNum(need.daysBeforeTheEndPublication, [" день", " дня", " дней"])}.*/}
                                Продление увеличит срок размещения на месяц, с вашего баланса будет списана 1
                                публикация.
                            </div>
                        </CustomDialog>
                        <CustomDialog
                            isOpen={isOpenErrorDialog}
                            onClose={() => setIsOpenErrorDialog(false)}
                            dialogTitle={"Пополнить баланс"}
                        >
                            <div style={{
                                padding: "20px 50px",
                                width: "500px",
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "19px",
                                textAlign: "center",
                                color: "#12324F"
                            }}>
                                У вас закончились публикации.
                                Для пополнения баланса свяжитесь с менеджером по продажам<br />
                                <a href={`mailto:${interfaceCTX.salesEmail}`}
                                   style={{
                                       color: "#12324F",
                                       textDecoration: "underline",
                                       padding: "0 5px"
                                   }}>
                                    {interfaceCTX.salesEmail}
                                </a>
                                или напишите в чат
                                <a href={`https://t.me/pragmacorebot`}
                                   target="_blank"
                                   rel="noreferrer"
                                   style={{
                                       color: "#12324F",
                                       textDecoration: "underline",
                                       padding: "0 5px"
                                   }}>
                                    Telegram
                                </a>
                            </div>
                        </CustomDialog>
                        <PageHeader>
                            <PageTitle
                                title={"Потребность"}
                                breadcrumbs={true}
                            />
                            {!["organizer", "moderator"].includes(userCTX.user?.currentRole as string) &&
                                <CurrentTariff />}
                        </PageHeader>
                        <FormProvider {...methods}>
                            <Form
                                name={"need"}
                                useRef={formRef}
                                onSubmit={onSubmit}
                                // validateMode="onChange"
                                viewMode={userCTX.userHasRole("admin") || userCTX.userHasRole("hr") ? mode == "view" : true}
                                solidMode={isMode(true, false)}
                                data={[
                                    [
                                        {
                                            fullWidth: true,
                                            divider: (need.state === "published" || need.state === "withdrawn_from_publication"),
                                            values: [
                                                {
                                                    type: "other",
                                                    value: () => {
                                                        return (
                                                            <>
                                                                {need.state === "published" &&
                                                                    <div style={{
                                                                        fontWeight: "500",
                                                                        fontSize: "16px",
                                                                        lineHeight: "19px",
                                                                        color: need.daysBeforeTheEndPublication <= 3 ? "#C81F1F" : ""
                                                                    }}>
                                                                        Срок публикации истекает
                                                                        через {need.daysBeforeTheEndPublication + declOfNum(need.daysBeforeTheEndPublication, [" день", " дня", " дней"])}.
                                                                    </div>}
                                                            </>
                                                        );
                                                    }
                                                },
                                                {
                                                    type: "other",
                                                    value: () => {
                                                        return (
                                                            <>
                                                                {(need.state === "published" || need.state === "withdrawn_from_publication") && userCTX.user?.currentRole !== "organizer" && !isModerator &&
                                                                    <SpanButtons
                                                                        data={[
                                                                            {
                                                                                label: "Продлить",
                                                                                onClick: () => setIsOpenDialogPublish(true)
                                                                            }
                                                                        ]}
                                                                    />}
                                                            </>
                                                        );
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            fullWidth: true,
                                            divider: true,
                                            values: [
                                                {
                                                    type: "text",
                                                    title: "Статус",
                                                    value: t("request.state." + need.state)
                                                },
                                                {
                                                    type: "other",
                                                    hidden: isModerator,
                                                    value: () => (
                                                        <>
                                                            {need.id &&
                                                                <SpanButtons
                                                                    data={[
                                                                        {
                                                                            label: `Посмотреть откликнувшихся (${need.responses.total})`,
                                                                            to: {
                                                                                pathname: PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + `/${searchID}/response-list`,
                                                                                state: history.location.state
                                                                            },
                                                                            hide: isMode(false, true)
                                                                        },
                                                                        {
                                                                            label: "История изменений заявки",
                                                                            onClick: () => setOpenHistory(true)
                                                                        }
                                                                    ]}
                                                                />
                                                            }
                                                        </>
                                                    )
                                                }
                                            ]
                                        },
                                        {
                                            values: [
                                                { type: "text", title: "Номер заявки", value: need.number },
                                                { type: "text", title: "Дата заявки", value: need.date },
                                                {
                                                    type: "text",
                                                    title: "Наименование организации",
                                                    value: need.organisation
                                                }
                                            ]
                                        }
                                    ],
                                    [
                                        {
                                            values: [
                                                {
                                                    type: "async-select",
                                                    title: "Должность кандидата",
                                                    var: "position",
                                                    isClearable: true,
                                                    defaultValue: need.position ? {
                                                        label: need.position,
                                                        value: need.position
                                                    } : undefined,
                                                    url: "/info/professions",
                                                    toText: v => ({ label: v.name, value: v.name }),
                                                    validate: { required: true }
                                                },
                                                {
                                                    // type: 'textfield',
                                                    title: "Количество вакансий",
                                                    var: "quantity",
                                                    overrideMode: quanityEdit ? "edit" : undefined,
                                                    defaultValue: need.quantity,
                                                    validate: {
                                                        required: true,
                                                        pattern: /^\d+$/gm,
                                                        maxLength: 4,
                                                        min: 1
                                                    },
                                                    errors: {
                                                        pattern: "Разрешены только числа",
                                                        maxLength: "Введите не более 4-ёх цифр"
                                                    }
                                                },
                                                {
                                                    title: "Разряд",
                                                    var: "grade",
                                                    defaultValue: need.grade,
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["grade"],
                                                        maxLength: 30
                                                    },
                                                    errors: { maxLength: "Не более 30 знаков" },
                                                    hidden: need.settings.hidden["grade"]
                                                },
                                                {
                                                    title: "Категория",
                                                    var: "category",
                                                    defaultValue: need.category,
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["category"],
                                                        maxLength: 200
                                                    },
                                                    errors: { maxLength: "Не более 200 знаков" },
                                                    hidden: need.settings.hidden["category"]
                                                },
                                                {
                                                    title: "Подразделение, в котором открыта вакансия",
                                                    var: "subdivision",
                                                    defaultValue: need.subdivision,
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["subdivision"],
                                                        maxLength: 120
                                                    },
                                                    errors: { maxLength: "Не более 120 знаков" },
                                                    hidden: need.settings.hidden["subdivision"]
                                                },
                                                {
                                                    type: "async-select",
                                                    title: "Ответственный за оформление кадровых документов",
                                                    var: "responsibleForThePreparationOfPersonnelDocuments",
                                                    defaultValue: need.responsibleForThePreparationOfPersonnelDocuments
                                                        ? {
                                                            label: authorName(need.responsibleForThePreparationOfPersonnelDocuments),
                                                            value: need.responsibleForThePreparationOfPersonnelDocuments.id
                                                        }
                                                        : undefined,
                                                    isClearable: true,
                                                    // route: `${PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + PagesRoutes.USERS}/${need.responsibleForThePreparationOfPersonnelDocuments?.id}`,
                                                    url: `/organisation/${orgID}/staff`,
                                                    query: { role: "hr" },
                                                    toText: v => ({ label: authorName(v), value: v.id }),
                                                    validate: {
                                                        required: need.settings.required["responsibleForThePreparationOfPersonnelDocuments"]
                                                    },
                                                    hidden: need.settings.hidden["responsibleForThePreparationOfPersonnelDocuments"]
                                                },
                                                // {
                                                //     type: 'async-select',
                                                //     title: 'Ответственный за мобилизацию',
                                                //     var: 'organizer',
                                                //     isClearable: true,
                                                //     // route: `${PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + PagesRoutes.USERS}/${need.organizer?.id}`,
                                                //     defaultValue: need.organizer
                                                //         ? {label: authorName(need.organizer), value: need.organizer.id}
                                                //         : undefined,
                                                //     url: `/organisation/${orgID}/staff`,
                                                //     query: {role: 'organizer'},
                                                //     toText: v => ({label: authorName(v), value: v.id}),
                                                //     // validate: { required: wantToPublish && Need.settings.required["organizer"]},
                                                //     validate: {required: false},
                                                //     // hidden: need.settings.hidden['organizer']
                                                //     // hidden: userCTX.user?.organisation.modulesStatus.organizer
                                                // },
                                                {
                                                    type: "async-select",
                                                    title: "Непосредственный руководитель кандидата",
                                                    var: "supervisor",
                                                    defaultValue: need.supervisor
                                                        ? {
                                                            label: authorName(need.supervisor),
                                                            value: need.supervisor.id
                                                        }
                                                        : undefined,
                                                    isClearable: true,
                                                    // route: `${PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + PagesRoutes.USERS}/${need.supervisor?.id}`,
                                                    url: `/organisation/${orgID}/staff`,
                                                    query: { role: "foreman" },
                                                    toText: v => ({ label: authorName(v), value: v.id }),
                                                    validate: { required: false },
                                                    // validate: { required: wantToPublish && Need.settings.required["supervisor"]},
                                                    hidden: need.settings.hidden["supervisor"]
                                                },
                                                {
                                                    type: "async-select",
                                                    title: " Принимающий решение о трудоустройстве",
                                                    var: "employmentDecisionMaker",
                                                    defaultValue: need.employmentDecisionMaker
                                                        ? {
                                                            label: authorName(need.employmentDecisionMaker),
                                                            value: need.employmentDecisionMaker.id
                                                        }
                                                        : undefined,
                                                    isClearable: true,
                                                    // route: `${PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + PagesRoutes.USERS}/${need.employmentDecisionMaker?.id}`,
                                                    url: `/organisation/${orgID}/staff`,
                                                    toText: v => ({ label: authorName(v), value: v.id }),
                                                    validate: { required: need.settings.required["employmentDecisionMaker"] },
                                                    hidden: need.settings.hidden["employmentDecisionMaker"]
                                                },
                                                {
                                                    title: "Количество и наименование должностей в подчинении кандидата",
                                                    var: "subordinates",
                                                    defaultValue: need.subordinates,
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["subordinates"],
                                                        maxLength: 200
                                                    },
                                                    errors: { maxLength: "Не более 200 знаков" },
                                                    hidden: need.settings.hidden["subordinates"]
                                                },
                                                {
                                                    type: "period",
                                                    title: "Предполагаемый срок действия договора",
                                                    values: [
                                                        {
                                                            label: "c",
                                                            var: "workFrom",
                                                            defaultValue: need.workFrom,
                                                            validate: { required: true },
                                                            errors: { pattern: "Введите дату в формате ДД.ММ.ГГГГ" }
                                                        },
                                                        {
                                                            label: "по",
                                                            var: "workTo",
                                                            defaultValue: need.workTo,
                                                            validate: { required: true },
                                                            errors: { pattern: "Введите дату в формате ДД.ММ.ГГГГ" }
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: "textarea",
                                                    title: "Функциональные обязанности кандидата",
                                                    var: "responsibilities",
                                                    defaultValue: need.responsibilities,
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["responsibilities"],
                                                        maxLength: 1000
                                                    },
                                                    errors: { maxLength: "Не более 1000 знаков" },
                                                    hidden: need.settings.hidden["responsibilities"]
                                                },
                                                {
                                                    title: "Адрес места работы",
                                                    var: "address",
                                                    defaultValue: need.address,
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["address"],
                                                        maxLength: 200
                                                    },
                                                    errors: { maxLength: "Не более 200 знаков" },
                                                    hidden: need.settings.hidden["address"]
                                                }
                                            ]
                                        },
                                        {
                                            values: [
                                                {
                                                    type: "async-select",
                                                    title: "Место производства работ, город РФ",
                                                    var: "city",
                                                    isClearable: true,
                                                    defaultValue: need.city ? {
                                                        label: need.city,
                                                        value: need.city
                                                    } : undefined,
                                                    url: `/info/cities`,
                                                    toText: (v: string) => ({ label: v, value: v }),
                                                    validate: { required: true }
                                                },
                                                {
                                                    type: "select",
                                                    title: "График работ",
                                                    var: "schedule",
                                                    isClearable: true,
                                                    defaultValue: need.schedule
                                                        ? (getSchedule(String(need.schedule.id)) as { label: string; value: string })
                                                        : undefined,
                                                    options: getSchedule() as { label: string; value: string }[],
                                                    validate: { required: true }
                                                },
                                                {
                                                    type: "other",
                                                    watchVar: "schedule",
                                                    value: (_: any, v: any) => scheduleDescription(v)
                                                },
                                                {
                                                    type: "other",
                                                    watchVar: "schedule",
                                                    value: (_: any, v: any) => scheduleHours(v)
                                                },
                                                {
                                                    type: "checkbox",
                                                    label: "Без испытательного срока",
                                                    var: "probation",
                                                    validate: { required: wantToPublish && need.settings.required["probation"] },
                                                    value: withoutProbation,
                                                    onChange: inverseCheckboxValue,
                                                    hidden: need.settings.hidden["probation"]
                                                    // ref: checkboxRef
                                                },
                                                {
                                                    title: "Период испытательного срока, месяцев",
                                                    var: "probationDuration",
                                                    defaultValue: need.probationDuration,
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["probation"],
                                                        pattern: /^\d+$/gm,
                                                        maxLength: 2,
                                                        min: 1,
                                                        max: 12
                                                    },
                                                    errors: { pattern: "Разрешены только числа" },
                                                    hidden: withoutProbation || need.settings.hidden["probation"]
                                                },
                                                {
                                                    title: "Заработная плата на испытательный срок (на руки), руб",
                                                    var: "salaryProbationNet",
                                                    defaultValue: Number(need.salaryProbationNet) == 0 ? "" : need.salaryProbationNet,
                                                    // className: 'align-right',
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["probation"],
                                                        pattern: /^\d+(\.\d+)?$/gm,
                                                        maxLength: 9,
                                                        min: 1
                                                    },
                                                    errors: {
                                                        pattern: "Разрешены только числа, и числа с плавающей точкой",
                                                        maxLength: "Введите не более 9-ти цифр"
                                                    },
                                                    hidden: withoutProbation || need.settings.hidden["probation"]
                                                },
                                                {
                                                    title: "Заработная плата (на руки), руб",
                                                    var: "salaryFullNet",
                                                    defaultValue: need.salaryFullNet,
                                                    // className: 'align-right',
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["salaryFullNet"],
                                                        pattern: /^\d+(\.\d+)?$/gm,
                                                        maxLength: 9,
                                                        min: 1
                                                    },
                                                    errors: {
                                                        pattern: "Разрешены только числа, и числа с плавающей точкой",
                                                        maxLength: "Введите не более 9-ти цифр"
                                                    },
                                                    hidden: need.settings.hidden["salaryFullNet"]
                                                },
                                                // {
                                                //     title: 'Заработная плата (gross), руб, с учётом НДФЛ', var: 'salaryFullGross',
                                                //     defaultValue: Need.salaryFullGross,
                                                //     className: 'align-right',
                                                //     validate: { required: wantToPublish, pattern: /^\d+(\.\d+)?$/gm, maxLength: 9, min: 1 },
                                                //     errors: { pattern: 'Разрешены только числа, и числа с плавающей точкой', maxLength: 'Введите не более 9-ти цифр' }
                                                // },
                                                // {
                                                //     title: 'Заработная плата на испытательный срок (gross), руб, с учётом НДФЛ', var: 'salaryProbationGross',
                                                //     defaultValue: Number(Need.salaryProbationGross) == 0 ? "" : Need.salaryProbationGross,
                                                //     className: 'align-right',
                                                //     validate: { pattern: /^\d+(\.\d+)?$/gm, maxLength: 9, min: 1 },
                                                //     errors: { pattern: 'Разрешены только числа, и числа с плавающей точкой', maxLength: 'Введите не более 9-ти цифр' }
                                                // },
                                                {
                                                    type: "textfields",
                                                    title: "Возраст кандидата, лет",
                                                    values: [
                                                        {
                                                            label: "от",
                                                            placeholder: "18",
                                                            var: "ageMin",
                                                            defaultValue: need.ageMin,
                                                            validate: {
                                                                required: wantToPublish && need.settings.required["age"],
                                                                min: 18,
                                                                pattern: /^\d*$/gm,
                                                                maxLength: 2
                                                            },
                                                            errors: { pattern: "Разрешены только числа" }
                                                        },
                                                        {
                                                            label: "до",
                                                            placeholder: "65",
                                                            var: "ageMax",
                                                            defaultValue: need.ageMax,
                                                            validate: {
                                                                required: wantToPublish && need.settings.required["age"],
                                                                max: 65,
                                                                min: 18,
                                                                pattern: /^\d*$/gm,
                                                                maxLength: 2
                                                            },
                                                            errors: { pattern: "Разрешены только числа" }
                                                        }
                                                    ],
                                                    hidden:
                                                        need.settings.hidden["age"] ||
                                                        (!need.ageMin &&
                                                            !need.ageMax &&
                                                            (userCTX.userHasRole("admin") || userCTX.userHasRole("hr") ? mode == "view" : true))
                                                },
                                                {
                                                    type: "select",
                                                    title: "Пол",
                                                    var: "gender",
                                                    isClearable: true,
                                                    validate: { required: wantToPublish && need.settings.required["gender"] },
                                                    defaultValue: need.gender ? {
                                                        label: need.gender,
                                                        value: need.gender
                                                    } : undefined,
                                                    options: [
                                                        { label: "Мужской", value: "Мужской" },
                                                        { label: "Женский", value: "Женский" }
                                                    ],
                                                    hidden: need.settings.hidden["gender"]
                                                },
                                                {
                                                    title: "Образование",
                                                    var: "education",
                                                    defaultValue: need.education,
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["education"],
                                                        maxLength: 100
                                                    },
                                                    errors: { maxLength: "Не более 100 знаков" },
                                                    hidden: need.settings.hidden["education"]
                                                },
                                                {
                                                    title: "Причины появления потребности",
                                                    var: "hiringReason",
                                                    defaultValue: need.hiringReason,
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["hiringReason"],
                                                        maxLength: 150
                                                    },
                                                    errors: { maxLength: "Не более 100 знаков" },
                                                    hidden: need.settings.hidden["hiringReason"]
                                                },
                                                {
                                                    type: "textarea",
                                                    title: "Дополнительная информация",
                                                    var: "additionalComments",
                                                    defaultValue: need.additionalComments,
                                                    validate: {
                                                        required: wantToPublish && need.settings.required["additionalComments"],
                                                        maxLength: 1000
                                                    },
                                                    errors: { maxLength: "Не более 1000 знаков" },
                                                    hidden: need.settings.hidden["additionalComments"]
                                                }
                                            ]
                                        }
                                    ]
                                ]}
                            />
                        </FormProvider>
                        <FixedButtons
                            length={3}
                            buttons={[
                                {
                                    label: "Редактировать",
                                    disabled: isWaitingForServer,
                                    primary: true,
                                    onClick: needEdit,
                                    hide:
                                        userCTX.userHasRole("organizer") ||
                                        (need.author.roles.includes("foreman") && need.state == "created") ||
                                        (need.state != "published" && need.state != "withdrawn_from_publication") ||
                                        mode == "edit" ||
                                        quanityEdit ||
                                        isModerator
                                    // hide: userCTX.userHasRole('admin') && Need.state == 'published' && mode == 'view' && !quanityEdit
                                    //     || userCTX.userHasRole('hr') && Need.state == 'published' && mode == 'view' && !quanityEdit && (Need.author.id == userCTX!.user!.id || Need.author.roles.includes('foreman'))
                                    //     ?
                                    //     false
                                    //     :
                                    //     true
                                },
                                {
                                    label: "Опубликовать",
                                    disabled: isWaitingForServer,
                                    primary: true,
                                    onClick: needPublish,
                                    hide:
                                        userCTX.userHasRole("organizer") ||
                                        (need.author.roles.includes("foreman") && need.state == "created") ||
                                        need.state != "on_approval" ||
                                        isModerator
                                    // hide: userCTX.userHasRole('admin') && Need.state == 'on_approval'
                                    //     || userCTX.userHasRole('hr') && Need.state == 'on_approval' && (Need.author.id == userCTX!.user!.id || Need.author.roles.includes('foreman'))
                                    //     ?
                                    //     false
                                    //     :
                                    //     true
                                },
                                {
                                    label: "Сохранить",
                                    disabled: isWaitingForServer,
                                    onClick: needSave,
                                    hide:
                                        userCTX.userHasRole("organizer") ||
                                        (need.author.roles.includes("foreman") && need.state == "created") ||
                                        (mode != "edit" && !quanityEdit) ||
                                        isModerator
                                    // hide: userCTX.userHasRole('admin') && (mode == 'edit' || quanityEdit)
                                    //     || userCTX.userHasRole('hr') && (Need.author.id == userCTX!.user!.id || Need.author.roles.includes('foreman')) && (mode == 'edit' || quanityEdit)
                                    //     ?
                                    //     false
                                    //     :
                                    //     true
                                },
                                {
                                    label: "Удалить",
                                    disabled: isWaitingForServer,
                                    onClick: () => setOpenDeleteDialog(true),
                                    hide: !need.id || (userCTX.userHasRole("organizer") || (need.author.roles.includes("foreman") && need.state == "created") || mode != "edit" || quanityEdit) ||
                                        isModerator
                                    // hide: userCTX.userHasRole('admin') && (mode == 'edit' || quanityEdit)
                                    //     || userCTX.userHasRole('hr') && (Need.author.id == userCTX!.user!.id || Need.author.roles.includes('foreman')) && (mode == 'edit' || quanityEdit)
                                    //     ?
                                    //     false
                                    //     :
                                    //     true
                                }
                            ]}
                        />

                        <Dialog open={openHistory} onClose={() => setOpenHistory(false)}>
                            <div className="dialog-title">
                                <h2>История изменений</h2>
                                <IconButton icon="close" onClick={() => setOpenHistory(false)} />
                            </div>
                            <div className="dialog-body">
                                {need.history.map((v, i) => (
                                    <React.Fragment key={"item-" + i}>
                                        <div className="dialog-item">
                                            <div>
                                                <div className="item--title">Дата изменений</div>
                                                <div className="item--text">{formatDate(v.date, true)}</div>
                                            </div>
                                            <div>
                                                <div className="item--title">Статус</div>
                                                <div className="item--text">{t("request.state." + v.state)}</div>
                                            </div>
                                            <div>
                                                <div className="item--title">Внёс изменения</div>
                                                <div className="item--text">{v.author ? authorName(v.author) : ""}</div>
                                            </div>
                                        </div>
                                        <ListDivider />
                                    </React.Fragment>
                                ))}
                            </div>
                            <Button label="Закрыть" onClick={() => setOpenHistory(false)} />
                        </Dialog>
                        <SimpleDialog
                            title="Подтверждение"
                            body="Вы уверены что хотите удалить заявку?"
                            cancelLabel={"Нет"}
                            acceptLabel={"Да"}
                            open={openDeleteDialog}
                            onClose={evt => {
                                if (evt.detail.action === "accept") needDelete();
                                setOpenDeleteDialog(false);
                            }}
                        />
                    </>
                )}
            />
        </div>
    );
}

