import { StepInfo } from './employment-process-def'

export const stepInfo: StepInfo[] = [
	{
		title: 'Ваш отклик рассматривают',
		body: 'После направления отклика работодатель рассматривает вашу кандидатуру.',
		stepNames: ['pending']
	},
	{
		title: 'Для вас готовят пакет документов',
		body: 'Работодатель подготавливает пакет документов для вас, следующим шагом будет подписание направленных документов.',
		stepNames: ['documents_confirmed', 'documents_preparation', 'documents_awaiting']
	},
	{
		title: 'Подписание документов',
		body: 'Работодатель отправил вам документы по электронной почте. Распечатайте их, подпишите, отсканируйте и отправьте обратно. Если вы не получили документы, воспользуйтесь соответствующей кнопкой на экране потребности.',
		stepNames: ['signature_awaiting']
	},
	{
		title: 'Документы запрошены повторно',
		body: 'Ожидайте повторной отправки документов',
		stepNames: ['documents_undelivered']
	},
	{
		title: 'Идет проверка пакета документов',
		body: 'Ожидайте проверку направленных Вами документов.',
		stepNames: ['signed']
	},
	{
		title: 'Вас приняли на работу',
		body: 'Вы приняты на работу, перейдите на экран договоров, чтобы ознакомиться с заключенным договором.',
		stepNames: ['accepted']
	}
]
