import { Button } from '@rmwc/button'
import { Icon } from '@rmwc/icon'
import clsx from 'clsx'
import { MessageInfoCard, PageTitle, ProgressPage } from 'components'
import { useDebounce } from 'custom-hooks'
import { WorkerRequestListRequest } from 'global/api'
import { UserContext } from 'global/context.user'
import { LocationState } from 'global/definitions/definitions'
import { PagesRoutes } from 'global/routes'
import {
	FindTextField,
	isAllProfileIsFilling,
	RequestFilterDialog,
	RequestFilterDialogDataType,
	RequestList,
	useRequests,
	WorkerProfileFillingStatistics,
	WorkerResponseStatistics
} from 'pages/auth-as-worker'
import { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { DelimitedArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import './worker-request-list.sass'

export const WorkerRequestListPage: FC = (): JSX.Element => {
	const { user } = useContext(UserContext)
	const { state, search } = useLocation<LocationState[]>()
	const [urlParams, setUrlParams] = useQueryParams({
		city: StringParam,
		organisation: StringParam,
		mainProfession: withDefault(
			StringParam,
			localStorage.getItem('mainProfession') ?? user?.workerProfile?.mainProfession?.name
		),
		salaryNetMin: NumberParam,
		salaryNetMax: NumberParam,
		subProfessions: DelimitedArrayParam
	})
	const requestFilters: Partial<WorkerRequestListRequest> = useMemo(
		() => ({
			city: urlParams.city,
			organisation: urlParams.organisation,
			mainProfession: urlParams.mainProfession,
			salaryNetMin: urlParams.salaryNetMin,
			salaryNetMax: urlParams.salaryNetMax,
			subProfessions: urlParams.subProfessions?.filter(value => value !== null) as string[] | null | undefined
		}),
		[urlParams]
	)
	const {
		requests,
		pendingRequests,
		considerationRequests,
		rejectedRequests,
		profileFilling,
		isFetching,
		isUploading,
		isSuccess
	} = useRequests(requestFilters)
	const [searchValue, setSearchValue] = useState<string | undefined>(
		localStorage.getItem('mainProfession') ?? urlParams.mainProfession ?? ''
	)
	const debouncedValue = useDebounce(searchValue)
	const [isOpen, setIsOpen] = useState(false)
	const [isOpenResponseStatistics, setIsOpenResponseStatistics] = useState(false)

	const isProfileFilling = useMemo(() => {
		return isAllProfileIsFilling(profileFilling)
	}, [profileFilling])

	useEffect(() => {
		if (debouncedValue !== null) {
			localStorage.setItem('mainProfession', debouncedValue || '')
			setUrlParams({ mainProfession: debouncedValue || undefined })
		}
	}, [debouncedValue])

	useEffect(() => {
		if (isSuccess) setIsOpenResponseStatistics(isProfileFilling)
	}, [isSuccess, isProfileFilling])

	const switchButtonHandler = () => setIsOpenResponseStatistics(!isOpenResponseStatistics)

	const inputHandler = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)

	const closeHanlder = () => setIsOpen(false)

	const submitHandler = (data: RequestFilterDialogDataType) => {
		localStorage.setItem('mainProfession', data.mainProfession ?? '')
		setSearchValue(data.mainProfession ?? '')
		setUrlParams(
			{
				city: data.city || undefined,
				organisation: data.organisation || undefined,
				mainProfession: data.mainProfession || undefined,
				salaryNetMin: data.salaryNetMin || undefined,
				salaryNetMax: data.salaryNetMax || undefined,
				subProfessions: data.subProfessions?.length ? data.subProfessions : undefined
			},
			'push'
		)
		setIsOpen(false)
	}

	return (
		<div className='worker-request-list'>
			<div className='worker-request-list-title-wrapper'>
				<PageTitle title='Список потребностей' breadcrumbs />
				<Link to={{ pathname: PagesRoutes.WORKER_NEED_LIST + '/archive', state }}>
					<Button raised>Архив</Button>
				</Link>
			</div>
			{isSuccess && !isProfileFilling && (
				<MessageInfoCard>
					<>
						<div className='worker-request-list-message-card'>
							<Icon className='worker-request-list-message-card-icon' icon='info_outline' />
							<span>Для повышения шанса устроиться на работу, заполните профиль</span>
						</div>
						<Icon
							className={clsx('worker-request-list-message-card-toggle-button', {
								'worker-request-list-message-card-toggle-button-active': isOpenResponseStatistics
							})}
							icon='assignment'
							onClick={switchButtonHandler}
						/>
					</>
				</MessageInfoCard>
			)}
			<FindTextField
				className='worker-request-list-find-input'
				value={searchValue ?? ''}
				filtersIsEmpty={!!search}
				onChange={inputHandler}
				onOpenMenu={() => setIsOpen(true)}
			/>
			{isSuccess && isProfileFilling && (
				<p className='profile-filling-title profile-filling-title-new-actions'>Новые действия</p>
			)}
			{isSuccess && (
				<div className='worker-request-list-profile-info'>
					<div className='profile-filling-card-list'>
						{!isProfileFilling && !isOpenResponseStatistics && (
							<WorkerProfileFillingStatistics profileFilling={profileFilling} />
						)}
						{isOpenResponseStatistics && (
							<WorkerResponseStatistics
								pendingCount={pendingRequests.length}
								considerationCount={considerationRequests.length}
								rejectedCount={rejectedRequests.length}
							/>
						)}
					</div>
				</div>
			)}
			<ProgressPage state={isFetching} render={() => <RequestList requests={requests} isUploading={isUploading} />} />
			{isOpen && <RequestFilterDialog open={isOpen} onClose={closeHanlder} onSubmit={submitHandler} />}
		</div>
	)
}
