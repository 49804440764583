import {Icon} from "@rmwc/icon"
import { Tooltip } from "@rmwc/tooltip"
import React from "react"
import {TableCell, TableRow, Table} from "components"
import {authorName} from "../../../global/common"
import {formatHoursAndMinutes, tableColumnDimensions} from "../TimeKeepingReport.service"

export function ObjectForTKReport(props: any) {

    const [isObjectOpen, setIsObjectOpen] = React.useState(true)

    return (
        <Table className="object"
               style={{minWidth: 65 + 300 + 300 + 300 + 150 + 150 + 150 + 140 + 140 + 140 + (props.daysInChosenMonth * 50)}}>
            <TableRow type="row" className="object-row" onClick={() => setIsObjectOpen(!isObjectOpen)}>
                <TableCell className="dropdow-arrow" style={{...tableColumnDimensions.orderNumber}}>
                    {isObjectOpen ?
                        <Icon icon="expand_less"></Icon>
                        :
                        <Icon icon="expand_more"></Icon>
                    }
                </TableCell>
                <TableCell className="object-name" devider style={{minWidth: 50 * props.daysInChosenMonth + 1350}}>
                    {props.object.name}
                </TableCell>
                <TableCell className="stats" devider style={{maxWidth: 140}}>
                    {props.object.stats.countShifts}
                </TableCell>
                <TableCell className="stats" devider style={{maxWidth: 140}}>
                    {formatHoursAndMinutes(props.object.stats.totalTime)}
                </TableCell>
                <TableCell className="stats" devider style={{maxWidth: 140}}>
                    {formatHoursAndMinutes(props.object.stats.nightTime)}
                </TableCell>
                {/* <TableCell className="stats" devider style={{ ...tableColumnDimensions.total }}>
                    {props.object.stats.countAbsence}
                </TableCell>
                <TableCell className="stats" devider style={{ ...tableColumnDimensions.total }}>
                    {props.brigade.stats.countAbsence}
                </TableCell> */}

            </TableRow>
            {isObjectOpen && props.object.brigades.map((brigade: any, index: number) =>
                <BrigadeForTKReport
                    brigade={brigade}
                    width={props.width}
                    daysInChosenMonth={props.daysInChosenMonth}
                />
            )}
        </Table>
    )
}

export function BrigadeForTKReport(props: any) {
    const [isBrigadeOpen, setIsBrigadeOpen] = React.useState(true)
    return (
        <Table className="brigade-with-workers">
            <TableRow type="row" className="brigade-row" onClick={() => setIsBrigadeOpen(!isBrigadeOpen)}>
                <TableCell className="dropdow-arrow" style={{...tableColumnDimensions.orderNumber}}>
                    {isBrigadeOpen ?
                        <Icon icon="expand_less"></Icon>
                        :
                        <Icon icon="expand_more"></Icon>
                    }
                </TableCell>
                <TableCell className="brigade-name" devider style={{minWidth: 50 * props.daysInChosenMonth + 1350}}>

                    {props.brigade.name}
                </TableCell>
                <TableCell className="stats" devider style={{maxWidth: 140}}>
                    {props.brigade.stats.countShifts}
                </TableCell>
                <TableCell className="stats" devider style={{maxWidth: 140}}>
                    {formatHoursAndMinutes(props.brigade.stats.totalTime)}
                </TableCell>
                <TableCell className="stats" devider style={{maxWidth: 140}}>
                    {formatHoursAndMinutes(props.brigade.stats.nightTime)}
                </TableCell>
                {/* <TableCell className="stats" devider style={{ ...tableColumnDimensions.total }}>
                    {props.brigade.stats.countAbsence}
                </TableCell>
                <TableCell className="stats" devider style={{ ...tableColumnDimensions.total }}>
                    {props.brigade.stats.countAbsence}
                </TableCell> */}
            </TableRow>
            {isBrigadeOpen && props.brigade.workers.map((worker: any, index: any) =>
                <TableRow type="row" className={"worker-row " + ((index + 1) % 2 !== 0 && ' dark')}>
                    <TableCell devider style={{...tableColumnDimensions.orderNumber}}>
                        {index + 1}
                    </TableCell>
                    <TableCell devider style={{...tableColumnDimensions.name}}>
                        {worker.workerLastName + ' ' + worker.workerFirstName + ' ' + worker.workerMiddleName}
                    </TableCell>
                    <TableCell devider style={{...tableColumnDimensions.position}}>
                        {worker.position}
                    </TableCell>
                    <TableCell devider style={{...tableColumnDimensions.responsible}}>
                        {authorName(worker.foreman)}
                    </TableCell>
                    <TableCell devider style={{...tableColumnDimensions.tabularNumber}}>
                        {worker.tabularNumber}
                    </TableCell>
                    <TableCell devider style={{...tableColumnDimensions.grade}}>
                        {worker.workerGrade}
                    </TableCell>
                    <TableCell devider style={{...tableColumnDimensions.shift}}>
                        {worker.shiftType === "day" ? "День" : "Ночь"}
                    </TableCell>
                    {new Array(props.daysInChosenMonth).fill('s').map((day: number, index: number) => {
                        if(worker.days[index + 1]?.shiftWarning) {
                            return (
                                <TableCell className={'time-keeping-cell-warning'} devider style={{...tableColumnDimensions.day}}>
                                    <Tooltip content="Смена не закрыта">
                                        <Icon className='warning' icon={"warning"} style={{color: 'red'}}/>
                                    </Tooltip>
                                </TableCell>
                            )
                        } else return (
                                <TableCell className={'time-keeping-cell'} devider style={{...tableColumnDimensions.day}}>
                                    <div className="absent-or-not">
                                        {worker.days[index + 1] === undefined
                                            ? "-"
                                            : worker.days[index + 1].convention
                                        }
                                    </div>
                                    <div className="hours-of-work">
                                        {worker.days[index + 1] === undefined
                                            ? "-"
                                            : formatHoursAndMinutes(worker.days[index + 1].worktime)
                                        }
                                    </div>
                                </TableCell>
                            )
                        }
                    )}
                    <TableCell devider style={{minWidth: 140}}>
                        {worker.stats.countShifts}
                    </TableCell>
                    <TableCell devider style={{minWidth: 140}}>
                        {formatHoursAndMinutes(worker.stats.totalTime)}
                    </TableCell>
                    <TableCell devider style={{minWidth: 140}}>
                        {formatHoursAndMinutes(worker.stats.nightTime)}
                    </TableCell>
                    {/* <TableCell devider style={{ ...tableColumnDimensions.total }}>
                        {worker.stats.countAbsence}
                    </TableCell>
                    <TableCell devider style={{ ...tableColumnDimensions.total }}>
                        {worker.efficiency}
                    </TableCell> */}
                </TableRow>
            )}
        </Table>
    )
}

