import React, {useRef, useState} from 'react'
import {
    IColumnSettings,
    IExtendedCustomTable,
    IFilterOrSortingValues,
    ISortingAndFilteringArray
} from './ExtendedCustomTable.types'
import { LoadingProgress, PageTitle } from "components";
import {ProgressPage} from 'components'
import {ExtendedCustomTableHeader} from './components/ExtendedCustomTableHeader/ExtendedCustomTableHeader'
import './ExtendedCustomTable.style.sass'
import {Icon} from '@rmwc/icon'
import {Tooltip} from '@rmwc/tooltip'
import {Button} from '@rmwc/button'
import {MenuSettings} from './components/MenuSettings/MenuSettings'
import {CircularProgress} from '@rmwc/circular-progress'
import {Table, FixedButtons} from 'components'
import {req} from '../../global/common'
import {ExtendedCustomTableBody} from './components/ExtendedCustomTableBody/ExtendedCustomTableBody'
import {downloadEXCELTable} from './ExtendedCustomTable.service'

export const ExtendedCustomTable: React.FC<IExtendedCustomTable> = ({
                                                                        renderFilterValue,
                                                                        settings,
                                                                        apiURLS,
                                                                        pageTitle,
                                                                        renderRow,
                                                                        rootClassName,
                                                                        formattedTableValuesForEXEL,
                                                                        customFilterValues = {},
                                                                        sortingAndFilteringOptions= [],
                                                                        paginateFilter = false,
                                                                        numbered = false
                                                                    }) => {
    const [tableValues, setTableValues] = React.useState<any[]>(null as unknown as any)
    const [isFirstStart, setIsFirstStart] = useState(false)
    const [delayedProcess, setDelayedProcess] = useState<boolean>(false)
    const [columnSettings, setColumnSettings] = useState<IColumnSettings[]>([])
    const [isVisibleSettingsOpen, setIsVisibleSettingsOpen] = useState('NONE')
    const [dialogSettingsIsVisible, setDialogSettingsIsVisible] = useState(false)
    const [filterOrSortValues, setFilterOrSortValues] = useState<IFilterOrSortingValues>({
        filter: {},
        sort: {}
    })
    const [sortingAndFilteringArray, setSortingAndFilteringArray] = useState<ISortingAndFilteringArray[]>([])
    const scroller = useRef<HTMLInputElement>(null)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(20)
    const [fetching, setFetching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const newOrderColumns = (newColumnSettings: IColumnSettings[]) => {
        setColumnSettings(newColumnSettings)
        saveColumnSettings(newColumnSettings)
    }

    const clearSettings = () => {
        saveColumnSettings(settings)
        applyColumnSettings(settings)
    }

    const defaultVisibleColumns = (columnSettings: IColumnSettings[]) => {
        const defaultVisibleSettings = [
            ...columnSettings.map(item => ({
                ...item,
                visibility: true
            }))
        ]
        saveColumnSettings(defaultVisibleSettings)
        applyColumnSettings(defaultVisibleSettings)
    }

    const applyColumnSettings = (newSettings: IColumnSettings[]) => {
        let currentSettings = newSettings.length > 0 ? [...newSettings] : [...settings]
        currentSettings.sort((a: IColumnSettings, b: IColumnSettings) => a.sortOrder - b.sortOrder)
        setColumnSettings(currentSettings)
        const filterType = (value: string | null) => {
            let result: string | null
            if (value !== null) {
                result = JSON.parse(value)
            } else {
                result = null
            }
            return result
        }

        setSortingAndFilteringArray(
            currentSettings.map(
                item =>
                    ({
                        columnName: item.title,
                        columnType: item.columnType,
                        sortType: item.sortType,
                        filterType: filterType(item.filterType),
                        isVisible: item.visibility
                    } as ISortingAndFilteringArray)
            )
        )
        const filterAndSortValues: IFilterOrSortingValues = {filter: {}, sort: {}}
        newSettings.forEach(item => {
            if (item.filterType !== null) {
                filterAndSortValues.filter = {
                    ...filterAndSortValues.filter,
                    [item.columnType]: JSON.parse(item.filterType)
                }
            }
            if (item.sortType !== 'NONE') {
                filterAndSortValues.sort = {
                    ...filterAndSortValues.sort,
                    [item.columnType]: item.sortType
                }
            }
        })
        setFilterOrSortValues(filterAndSortValues)
        req
            .post(`${apiURLS.getTableValues}?limit=${limit}&offset=0`, filterAndSortValues)
            .then(({data}) => {
                setTableValues(data.data)
                setTotal(data.total)
            })
            .catch(() => {
            })
    }

    const saveColumnSettings = (newColumnSettings: IColumnSettings[]) => {
        req.post(`${apiURLS.saveTableSettings}`, newColumnSettings).then(({data}) => {
        })
    }

    const toggleIsVisibleSettings = (columnType: string) => {
        setIsVisibleSettingsOpen(columnType)
    }

    const toggleIsVisibleColumn = (checked: boolean, ind: number | string) => {
        setColumnSettings((prevState: IColumnSettings[]) => {
            const newState = prevState.map((column, index) => {
                let newColumn = {} as IColumnSettings
                if (index === ind || column.columnType === ind) {
                    newColumn = {
                        ...column,
                        visibility: checked,
                        filterType: null,
                        sortType: 'NONE'
                    }
                } else {
                    newColumn = {...column}
                }
                return newColumn
            })
            saveColumnSettings(newState)
            return newState
        })
        setSortingAndFilteringArray((prevState: ISortingAndFilteringArray[]) =>
            prevState.map((column, index) => {
                let newColumn = {}
                if (index === ind || column.columnType === ind) {
                    newColumn = {
                        ...column,
                        isVisible: checked,
                        filterType: null,
                        sortType: 'NONE'
                    }
                } else {
                    newColumn = {...column}
                }
                return newColumn as ISortingAndFilteringArray
            })
        )
        setIsVisibleSettingsOpen('NONE')
    }

    const onChangeSortValues = (columnType: string, columnName: string, sortType: 'ASC' | 'DESC' | 'NONE') => {
        const alreadyExists =
            sortingAndFilteringArray.length > 0 && sortingAndFilteringArray.find(item => item.columnType === columnType)
        const newColumnSettings = [
            ...columnSettings.map(item => {
                if (item.columnType === columnType) {
                    return {...item, sortType: sortType}
                } else {
                    return {...item, sortType: 'NONE'}
                }
            })
        ]
        setColumnSettings(newColumnSettings as IColumnSettings[])
        saveColumnSettings(newColumnSettings as IColumnSettings[])
        if (alreadyExists) {
            setSortingAndFilteringArray((prevState: ISortingAndFilteringArray[]) => [
                ...(prevState.map(item => {
                    if (item.columnType === columnType) {
                        return {...item, sortType: sortType}
                    } else if (item.columnType !== columnType) {
                        return {...item, sortType: 'NONE'}
                    }
                }) as ISortingAndFilteringArray[])
            ])
            setFilterOrSortValues((prevState: any) => ({
                ...prevState,
                sort: {
                    [columnType]: sortType
                }
            }))
            setDelayedProcess((prevState: boolean) => !prevState)
        } else if (!alreadyExists) {
            setSortingAndFilteringArray(
                (prevState: ISortingAndFilteringArray[]) =>
                    [
                        ...prevState.map(item => ({...item, sortType: 'NONE'})),
                        {
                            columnType,
                            columnName,
                            sortType
                        }
                    ] as ISortingAndFilteringArray[]
            )
            setFilterOrSortValues((prevState: any) => ({
                ...prevState,
                sort: {
                    [columnType]: sortType
                }
            }))
            setDelayedProcess((prevState: boolean) => !prevState)
        }
    }

    const deleteSortOrFilter = (columnType: string, command: 'sort' | 'filter', index: number) => {
        const newColumnSettings = [
            ...columnSettings.map(item => {
                if (item.columnType === columnType && command === 'sort') {
                    return {...item, sortType: 'NONE'}
                } else if (item.columnType === columnType && command === 'filter') {
                    return {...item, filterType: null}
                } else return item
            })
        ]
        setColumnSettings(newColumnSettings as IColumnSettings[])
        saveColumnSettings(newColumnSettings as IColumnSettings[])
        if (command === 'sort') {
            setFilterOrSortValues(
                (prevState: IFilterOrSortingValues) =>
                    ({
                        ...prevState,
                        sort: {
                            ...prevState.sort,
                            [columnType]: null
                        }
                    } as IFilterOrSortingValues)
            )
            setSortingAndFilteringArray((prevState: ISortingAndFilteringArray[]) => {
                const newState = Array.from(prevState)
                newState[index].sortType = 'NONE'
                return newState
            })
        } else if (command === 'filter') {
            setFilterOrSortValues((prevState: IFilterOrSortingValues) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    [columnType]: null
                }
            }))
            setSortingAndFilteringArray((prevState: ISortingAndFilteringArray[]) => {
                const newState = Array.from(prevState)
                newState[index].filterType = null
                return newState
            })
        }
        setDelayedProcess((prevState: boolean) => !prevState)
    }

    const onChangeFilterValues = (columnType: string, columnName: string, filterValue: string[] | null) => {
        const alreadyExists =
            sortingAndFilteringArray.length > 0 && sortingAndFilteringArray.find(item => item.columnType === columnType)
        const newColumnSettings = [
            ...columnSettings.map(item => {
                if (item.columnType === columnType) {
                    return {...item, filterType: filterValue !== null ? JSON.stringify(filterValue) : null}
                } else {
                    return item
                }
            })
        ]
        setColumnSettings(newColumnSettings)
        saveColumnSettings(newColumnSettings as IColumnSettings[])
        if (alreadyExists) {
            setSortingAndFilteringArray(
                (prevState: ISortingAndFilteringArray[]) =>
                    [
                        ...prevState.map(item => {
                            if (item.columnType === columnType) {
                                return {...item, filterType: filterValue}
                            } else if (item.columnType !== columnType) {
                                return item
                            }
                        })
                    ] as ISortingAndFilteringArray[]
            )
            setFilterOrSortValues((prevState: any) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    [columnType]: filterValue
                }
            }))
            setDelayedProcess((prevState: boolean) => !prevState)
        } else if (!alreadyExists) {
            setSortingAndFilteringArray(
                (prevState: ISortingAndFilteringArray[]) =>
                    [
                        ...prevState,
                        {
                            columnType,
                            columnName,
                            filterValue
                        }
                    ] as ISortingAndFilteringArray[]
            )
            setFilterOrSortValues((prevState: any) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    [columnType]: filterValue
                }
            }))
            setDelayedProcess((prevState: boolean) => !prevState)
        }
    }

    const downloadTableEXCEL = (columnSettings: any) => {
        req
            .post(`${apiURLS.getTableValues}`, filterOrSortValues)
            .then(({data}) => {
                downloadEXCELTable(data.data, columnSettings, pageTitle, formattedTableValuesForEXEL, numbered)
            })
            .catch(() => {
            })
    }

    React.useEffect(() => {
        req.get(`${apiURLS.getTableSettings}`).then(({data}) => {
            setIsFirstStart(true)
            if (data.length !== settings.length) {
                clearSettings()
            } else {
                applyColumnSettings(data)
            }
        })
    }, [])

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (isFirstStart) {
                setIsLoading(true)
                req
                    .post(`${apiURLS.getTableValues}?limit=${limit}&offset=0`, filterOrSortValues)
                    .then(({data}) => {
                        setTableValues(data.data)
                        setTotal(data.total)
                        setIsLoading(false)
                    })
                    .catch(() => {
                    })
            }
        }, 300)

        return () => clearTimeout(delayDebounceFn)
    }, [delayedProcess])

    React.useEffect(() => {
        if (fetching) {
            const newLimit = limit + 20
            req
                .post(`${apiURLS.getTableValues}?limit=${newLimit}&offset=0`, filterOrSortValues)
                .then(({data}) => {
                    setTableValues(data.data)
                    setTotal(data.total)
                    setLimit(newLimit)
                })
                .finally(() => setFetching(false))
        }
    }, [fetching])

    const scrollHandler = (e: React.UIEvent<HTMLElement>) => {
        //@ts-ignore
        if (e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + scroller.current.offsetHeight) < 100) {
            if (total > tableValues.length) {
                setFetching(true)
            }
        }
    }

    React.useEffect(() => {
        function closeSearch(e: any) {
            if (document.activeElement?.tagName == 'BODY') {
                toggleIsVisibleSettings('none')
                setDialogSettingsIsVisible(false)
            }
        }

        document.getElementsByTagName('body')[0].addEventListener('click', closeSearch)
        return () => {
            document.getElementsByTagName('body')[0].removeEventListener('click', closeSearch)
        }
    }, [])

    return (
        <div className={`${rootClassName} extended-custom-table-component`}>
            {fetching && (
                <LoadingProgress/>
            )}
            <PageTitle title={pageTitle} breadcrumbs={true}/>
            <div className='extended-custom-table-component-top'>
                <div className='filtered_or_sorted_items'>
                    {sortingAndFilteringArray.map((item, index) => {
                        console.log(sortingAndFilteringArray)
                        if (item.filterType && item.isVisible) {
                            return (
                                <div className='filtered_or_sorted_item' key={item.columnName + index + 'filter'}>
                                    <div className='item'>
                                        <div className='column_name'>{item.columnName}:</div>
                                        <Tooltip
                                            content={
                                                <div
                                                    style={{
                                                        maxWidth: '250px',
                                                        maxHeight: '100px',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    {renderFilterValue(item.filterType, item.columnType)}
                                                </div>
                                            }
                                        >
                                            <div className='type' style={{cursor: 'help'}}>
                                                {renderFilterValue(item.filterType, item.columnType)}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <Icon
                                        className='cancel'
                                        icon={'cancel'}
                                        onClick={() => deleteSortOrFilter(item.columnType, 'filter', index)}
                                    />
                                </div>
                            )
                        }
                    })}
                    {sortingAndFilteringArray.map((item, index) => {
                        if (item.sortType !== 'NONE' && item.isVisible) {
                            return (
                                <div className='filtered_or_sorted_item' key={item.columnName + index + 'sort'}>
                                    <div className='item'>
                                        <div className='column_name'>{item.columnName}:</div>
                                        <div className='type'>({item.sortType === 'ASC' ? 'А - Я' : 'Я - А'})</div>
                                    </div>
                                    <Icon
                                        className='cancel'
                                        icon={'cancel'}
                                        onClick={() => deleteSortOrFilter(item.columnType, 'sort', index)}
                                    />
                                </div>
                            )
                        }
                    })}
                </div>
                <div className='buttons'>
                    <Tooltip content='Управление столбцами'>
                        <Button
                            className='restart_settings'
                            disabled={tableValues == null}
                            raised={true}
                            onClick={() => setDialogSettingsIsVisible((prevState: any) => !prevState)}
                        >
                            <Icon className='settings' icon={'settings'} style={{color: '#FFFFFF'}}/>
                        </Button>
                    </Tooltip>
                    <Tooltip content='Сбросить настройки'>
                        <Button className='restart_settings' disabled={tableValues == null} raised={true}
                                onClick={clearSettings}>
                            <Icon className='restart_alt' icon={'restart_alt'} style={{color: '#FFFFFF'}}/>
                        </Button>
                    </Tooltip>
                    <FixedButtons
                        length={1}
                        buttons={[
                            {
                                label: 'Сохранить EXCEL',
                                primary: true,
                                disabled: tableValues == null,
                                onClick: () => downloadTableEXCEL(columnSettings)
                            }
                        ]}
                    />
                </div>
                {dialogSettingsIsVisible && (
                    <MenuSettings
                        columnSettings={columnSettings}
                        toggleIsVisibleColumn={toggleIsVisibleColumn}
                        defaultVisibleColumns={defaultVisibleColumns}
                        numbered={numbered}
                    />
                )}
            </div>
            <ProgressPage
                state={tableValues}
                render={() => (
                    <>
                        <div className='table-wrapper' ref={scroller} onScroll={scrollHandler}>
                            <Table className='extended-custom-table'>
                                <ExtendedCustomTableHeader
                                    newOrderColumns={newOrderColumns}
                                    columnSettings={columnSettings}
                                    toggleIsVisibleSettings={toggleIsVisibleSettings}
                                    isVisibleSettingsOpen={isVisibleSettingsOpen}
                                    toggleIsVisibleColumn={toggleIsVisibleColumn}
                                    onChangeSortValues={onChangeSortValues}
                                    sortingAndFilteringArray={sortingAndFilteringArray}
                                    onChangeFilterValues={onChangeFilterValues}
                                    getUniqueColumnValuesURL={apiURLS.getUniqueColumnValues}
                                    sortingAndFilteringOptions={sortingAndFilteringOptions}
                                    customFilterValues={customFilterValues}
                                    paginateFilter={paginateFilter}
                                    numbered={numbered}
                                />
                                {isLoading
                                    ? <CircularProgress
                                        className='circular-progress'
                                        style={{
                                            position: 'absolute',
                                            top: '290px',
                                            left: '50%'
                                        }}
                                    />
                                    : <ExtendedCustomTableBody
                                        tableValues={tableValues}
                                        columnSettings={columnSettings}
                                        renderRow={renderRow}
                                    />
                                }
                            </Table>
                        </div>
                    </>
                )}
            />
        </div>
    )
}
