import { Dialog } from "@rmwc/dialog";
import { Icon } from "@rmwc/icon";
import React, { useContext } from "react";
import checkIcon from "../../misc/check_circle_24px.svg";
import "./TariffCards.style.sass";
import { Button } from "@rmwc/button";
import { req } from "../../global/common";
import { InterfaceContext } from "../../global/context.interface";

export interface ITariffCards {
    isOpen: boolean;
    onClose: () => void;
}

const tariffCardValues = [
    {
        type: "Пакет “БАЗОВЫЙ”",
        label: {
            contacts: "150 контактов",
            period: "на 7 дней"
        },
        description: "Для небольших компаний, которым надо закрыть единичные потребности.",
        plan: {
            contacts: "150 контактов",
            publications: "50 публикаций"
        },
        price: "9 900 руб.",
        req: "base"
    },
    {
        type: "Пакет “СТАНДАРТ”",
        label: {
            contacts: "500 контактов",
            period: "на 30 дней"
        },
        description: "Для средних компаний, которым необходимо закрыть по 3-5 позиций на потребность.",
        plan: {
            contacts: "500 контактов",
            publications: "150 публикаций"
        },
        price: "29 900 руб.",
        req: "standard"
    },
    {
        type: "Пакет “ЛЮКС”",
        label: {
            contacts: "1500 контактов",
            period: "на 90 дней"
        },
        description: "Для крупных проектов, когда необходимо закрывать от 20 позиций на потребность.",
        plan: {
            contacts: "1 500 контактов",
            publications: "450 публикаций"
        },
        price: "79 900 руб.",
        req: "lux"
    }
];

export const TariffCards: React.FC<ITariffCards> = ({ isOpen, onClose }) => {
    const interfaceCTX = useContext(InterfaceContext);

    const pickTypeColor = (index: number) => {
        if (index === 0) return "rgba(255, 168, 0, 1)";
        else if (index === 1) return "rgba(38, 123, 223, 1)";
        else if (index === 2) return "rgba(22, 137, 75, 1)";
    };

    const requestTariff = (type: string) => {
        req.post(`/organisation/modules/request-tariff?tariffType=${type}`)
            .then(() => {
                interfaceCTX.showMessage({
                    body: "Запрос на подключение тарифа отправлен",
                    icon: "done"
                })
            })
            .finally(() => {
                onClose()
            });
    };

    return (
        <div
            className={"tariff-cards"}
        >
            <Dialog
                open={isOpen}
                onClose={(evt) => {
                    onClose();
                }}
            >
                <div className={"tariff-cards-layout"}>
                    {tariffCardValues.map((item, index) =>
                        <div key={item.price} className={"tariff-card"}>
                            <div className={"tariff-card-item tariff-card-type"}
                                 style={{
                                     color: pickTypeColor(index)
                                 }}
                            >
                                {item.type}
                            </div>
                            <div className={"tariff-card-item tariff-card-label"}
                                 style={index === 1 ? { fontSize: "32px", lineHeight: "37px" } : {}}
                            >
                                <div
                                    style={index === 1 ? { color: "rgba(173, 0, 255, 1)" } : {}}
                                >
                                    {item.label.contacts}
                                </div>
                                <div>
                                    {item.label.period}
                                </div>
                            </div>
                            <div className={"tariff-card-item tariff-card-description"}>
                                {item.description}
                            </div>
                            <div className={"tariff-card-item tariff-card-plan"}>
                                <div className={"tariff-card-plan-item"}
                                     style={index === 0 ? { width: "125px" } : {}}
                                >
                                    <Icon icon={{ icon: checkIcon, size: "xsmall" }} />
                                    <div className={"tariff-card-plan-item-text"}>
                                        {item.plan.contacts}
                                    </div>
                                </div>
                                <div className={"tariff-card-plan-item"}
                                     style={index === 0 ? { width: "125px" } : {}}
                                >
                                    <Icon icon={{ icon: checkIcon, size: "xsmall" }} />
                                    <div className={"tariff-card-plan-item-text"}>
                                        {item.plan.publications}
                                    </div>
                                </div>
                            </div>
                            <div className={"tariff-card-item tariff-card-price"}>
                                {item.price}
                            </div>
                            <div className={"tariff-card-item tariff-card-divider"} />
                            <div className={"tariff-card-item tariff-card-btn"}>
                                <Button
                                    className={""}
                                    outlined={index !== 1}
                                    raised={index === 1}
                                    onClick={() => {
                                        requestTariff(item.req);
                                    }}
                                >
                                    подключить
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Dialog>
        </div>
    );
};