import './VersionsHistoryList.style.sass'
import {FixedButtons, ProgressPage} from "../../../../../../components";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {req} from "../../../../../../global/common";
import {IVersionsHistoryItem} from "../VersionsHistoryRedact/VersionsHistoryItem.types";

export const VersionsHistoryList: React.FC<{platform: "mobile" | 'web'}> = ({platform}) => {
    const history = useHistory()
    const [mobileVersionsList, setMobileVersionsList] = useState<IVersionsHistoryItem[]>([])

    useEffect(() => {
        req.get(`/moderator/versions/all?platformType=${platform}`)
            .then(({data}) => {
                setMobileVersionsList(data)
            })
    }, [])

    return (
        <div className={'versions-history-list'}>
            <ProgressPage
                state={mobileVersionsList}
                render={() => (
                    <>
                        <div className={'versions-history-list-top'}>
                            <FixedButtons
                                className={'buttons'}
                                length={1}
                                buttons={[
                                    {
                                        label: 'Создать',
                                        primary: true,
                                        disabled: false,
                                        onClick: () => {
                                            history.push({
                                                pathname: `${platform}-redact`,
                                                state: []
                                            })
                                        }
                                    }
                                ]}
                            />
                        </div>
                        <div className={'versions-history-list-body'}>
                            {mobileVersionsList.map((item) =>
                                <div className={'versions-history-list-item'}
                                     onClick={() => {
                                         history.push({
                                             pathname: `${platform}-redact`,
                                             state: [{id: item.id}]
                                         })
                                     }}
                                     key={item.id}
                                >
                                    <div className={'versions-history-list-item-name'}>
                                        {item.title}
                                    </div>
                                    <div className={'versions-history-list-item-body'}>
                                        <div className={'versions-history-list-item-version'}>
                                            Версия {item.versionNumber}
                                        </div>
                                        <div className={'versions-history-list-item-date'}>
                                            {item.createdAt}
                                        </div>
                                    </div>
                                </div>)
                            }
                        </div>
                    </>
                )}/>
        </div>
    )
}