import { CircularProgress } from '@rmwc/circular-progress'
import { FC } from 'react'
import './LoadingProgress.style.sass'

export const LoadingProgress: FC<{style?: {[key: string]: string}}> = ({style}): JSX.Element => {
	return (
		<div className='loading-wrapper' style={style ? style : {}}>
			<div className='loading-inner'>
				<CircularProgress className='circular-progress' />
				<div>Загрузка</div>
			</div>
		</div>
	)
}
