import { Dialog } from '@rmwc/dialog'
import { Elevation } from '@rmwc/elevation'
import { Icon } from '@rmwc/icon'
import { Radio } from '@rmwc/radio'
import { TextField } from '@rmwc/textfield'
import { ChangeEvent, forwardRef, useCallback, useEffect, useState } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import { FormField } from '../form-field/form-field'
import { RadioOptionsDialogProps } from './radio-options-dialog-def'
import './radio-options-dialog.sass'

export const RadioOptionsDialog = forwardRef<HTMLDivElement, RadioOptionsDialogProps>(
	(
		{ open, title, hasSearch, search, defaultValue, options, onClose, onChangeSearch, onSuccess, onListScroll },
		ref
	) => {
		const [value, setValue] = useState(defaultValue || { id: 0, name: '' })

		useEffect(() => {
			if (open) {
				setValue(defaultValue || { id: 0, name: '' })
			}
		}, [open, defaultValue])

		const handleOnClickSelect = useCallback(() => {
			onSuccess?.(value)
			onClose?.()
		}, [value, onSuccess, onClose])

		return (
			<Dialog open={open} onClose={onClose}>
				<div className='radio-dialog-wrapper'>
					<div className='radio-dialog-title'>
						<h3>{title}</h3>
						<Icon icon='close' className='icon-close' onClick={onClose} />
					</div>

					<div className='radio-dialog'>
						{hasSearch && (
							<div className='search-wrapper'>
								<TextField
									className='search'
									label='Поиск'
									trailingIcon='search'
									maxLength={160}
									value={search}
									outlined
									onChange={onChangeSearch}
								/>
							</div>
						)}
						<Elevation className='radio-dialog-content' z={3} ref={ref} onScroll={onListScroll}>
							<Radio
								className='radio-dialog-field-top'
								checked={!value.id}
								label='Не выбрано'
								value={0}
								onChange={() => setValue({ id: 0, name: '' })}
							/>
							{options?.length !== 0 &&
								options?.map(({ name, value: id }) => {
									return (
										<Radio
											key={id}
											checked={value.id === id}
											className='radio-dialog-field'
											label={name}
											value={id}
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												setValue({ id: +id, name })
											}}
										/>
									)
								})}
						</Elevation>
					</div>
				</div>
				<Elevation className='radio-dialog-button-wrapper' z={3}>
					<FormField className='radio-dialog-button' label='Выбрать' onClick={handleOnClickSelect} />
				</Elevation>
			</Dialog>
		)
	}
)
