import React, { useEffect, useState } from "react";
import { Elevation } from "@rmwc/elevation";

import { Props } from "./TabButtons.types";
import "./TabButtons.style.sass";
import { Icon } from "@rmwc/icon";
import { useTranslation } from "react-i18next";

export function TabButtons({
                               staticHeader,
                               data,
                               onTabSwitch,
                               embed,
                               open,
                               elevation = 3,
                               isDisabled,
                               isUpdatedTabs,
                               whiteBackground = true,
                               tabClick,
                               langPrefix,
                               isUpdateTab
                           }: Props) {
    const [activeTab, setActiveTab] = useState(open ?? 0);
    const { t } = useTranslation("common");

    const activateTab = (event: React.MouseEvent<HTMLElement>) => {
        if (!!tabClick) {
            const tab = Number((event.target as any).id ?? activeTab);
            if (tabClick(Object.keys(data)[tab])) {
                setActiveTab(tab);
                if (onTabSwitch) onTabSwitch(tab);

            }
        } else {
            const tab = Number((event.target as any).id ?? activeTab);
            setActiveTab(tab);
            if (onTabSwitch) onTabSwitch(tab);
        }
    };

    useEffect(() => {
        if (isUpdateTab !== undefined) {
            setActiveTab(0);
        }
    }, [isUpdateTab]);

    return (

        <>
            {whiteBackground
                ? <Elevation className={"tabs"} z={elevation}>
                    <div
                        className={"tab-btns"}
                        onClick={isDisabled ? undefined : (e) => activateTab(e)}
                    >
                        {Object.keys(data).map((k, i) => (
                            <h4
                                id={String(i)}
                                key={i}
                                className={"tab-btn" + (i === activeTab ? " active" : "")}
                            >
                                <div
                                    id={String(i)}
                                    className="tab-btn-title"
                                >
                                    {langPrefix
                                        ? t(langPrefix + k)
                                        : k
                                    }
                                </div>
                                {isUpdatedTabs && isUpdatedTabs[i] &&
                                    <Icon className="tab-edit_note" icon={"edit_note"} />}
                            </h4>
                        ))}
                    </div>
                    {staticHeader}

                    {embed?.[activeTab] && Object.values(data)[activeTab]()}
                </Elevation>
                : <div className={"tabs"}>
                    <div
                        className={"tab-btns"}
                        onClick={isDisabled ? undefined : (e) => activateTab(e)}
                    >
                        {Object.keys(data).map((k, i) => (
                            <h4
                                id={String(i)}
                                key={i}
                                className={"tab-btn" + (i === activeTab ? " active" : "")}
                            >
                                <div
                                    id={String(i)}
                                    className="tab-btn-title"
                                >
                                    {langPrefix
                                        ? t(langPrefix + k)
                                        : k
                                    }
                                </div>
                                {isUpdatedTabs && isUpdatedTabs[i] &&
                                    <Icon className="tab-edit_note" icon={"edit_note"} />}
                            </h4>
                        ))}
                    </div>
                    {staticHeader}

                    {embed?.[activeTab] && Object.values(data)[activeTab]()}
                </div>
            }
            {!embed?.[activeTab] && Object.values(data)[activeTab]()}
        </>
    );
}
