import {Props} from './about-me-def'
import './about-me.sass'
import {InfoBlock} from '../../../../components/InfoBlock/InfoBlock'
import {Icon} from "@rmwc/icon";
import React from "react";
import {getValue, valueDelete} from "../../dump/dump-utils";


function getJobStatusColor(status: string) {

    const colors = {
        green: "green",
        orange: "orange",
        red: "red"
    }

    switch (status) {
        case "Не работаю, рассматриваю предложения":
            return colors.green;
        case "Трудоустроен, рассматриваю предложения":
            return colors.orange;
        case "Трудоустроен, не рассматриваю предложения":
            return colors.red;
        default:
            return colors.red
    }
}

export function AboutMeSubPage({
                                   workerProfile,
                               }: Props) {
    return (
        <div className="about-me-page-wrapper">
            <div className="about-me section">
                <ul className="about-me-list">
                    {workerProfile.personalInfo &&
                        <li className="about-me-item info-item">
                            <InfoBlock
                                title="Текущий статус работы"
                                content={
                                    <div className="job-status">
                                        <div
                                            style={{backgroundColor: getJobStatusColor(workerProfile.personalInfo.workSearchStatus)}}
                                            className="job-status-circle"/>
                                        <span>{getValue(workerProfile.personalInfo.workSearchStatus)}</span>
                                    </div>
                                }
                            />
                        </li>
                    }
                    {workerProfile.personalInfo &&
                        <li className="about-me-item info-item">
                            <InfoBlock
                                title="Желательный график работы"
                                content={getValue(workerProfile.personalInfo.workSearchSchedule)}
                            />
                        </li>
                    }
                    {workerProfile.personalInfo &&
                        <li className="about-me-item info-item">
                            <InfoBlock
                                title="О себе"
                                content={getValue(workerProfile.personalInfo.about)}
                            />
                        </li>
                    }
                </ul>
            </div>
        </div>
    )
}