import React, { BaseSyntheticEvent } from 'react'

let globalInputRef: React.MutableRefObject<HTMLInputElement>
export function openInput(): void {
    if (!globalInputRef) return
    globalInputRef.current.click()
}

interface Props {
    onFileChoise: (data: Blob) => any,
    filter?: string[]
}

/**
 * Компонент скрытого ввода файлов, 
 * открывается при использовании функции `openInput()` из контекста интерфейса
 * @param onFileChoise Функция возвращающая выбранный файл
 * @param filter Фильтр типов файлов для элемента ввода
 * @author rikzun
 */
export function HiddenInput({
    onFileChoise, filter
}: Props) {
    const localInputRef = React.useRef(undefined as unknown as HTMLInputElement)
    globalInputRef = localInputRef

    const reader = new FileReader()
    reader.onload = ({target}) => {
        onFileChoise( new Blob([target!.result as ArrayBuffer]) )
    }

    return <input type="file"
        className="hidden-input" 
        onChange={(e: BaseSyntheticEvent) => reader.readAsArrayBuffer(e.target.files[0])}
        onClick={(e: BaseSyntheticEvent) => e.target.value = ''}
        accept={filter && filter.join(', ')}
        ref={localInputRef}
    />
}