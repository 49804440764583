export {PublicProfile, PublicProfileSend, Specialization}
export type {Params, Spec, LogoUploadStatus}

interface Params { searchID: string; recordID: string, status: string }

type LogoUploadStatus = 'changed' | 'uploadedAndSaved' | 'deleted'


class PublicProfile {
    id: number
    fullName: string
    shortName: string
    inn: string
    kpp: string
    ogrn: string
    checkingAccount: string
    correspondentAccount: string
    bankName: string
    bankCode: string
    bankDetails: string
    addressLegal: string
    addressActual: string
    addressRegistration: string
    phone: string
    email: string
    www: string
    socialFacebook: string
    socialTwitter: string
    socialTelegram: string
    about: string
    logo: string
    logoPreview: string
    specialization?: {label: string, value: string}
    subSpecializations?: {label: string, value: string}[]
    hidden: {[key : string]: boolean}
    moderationPassed: 'expectation' | 'accepted' | 'rejected' | 're_moderation'
    workCoins: number

    constructor(data: any) {
        this.id = data.id
        this.fullName = data.fullName ?? ''
        this.shortName = data.shortName ?? ''
        this.inn = data.inn ?? ''
        this.kpp = data.kpp ?? ''
        this.ogrn = data.ogrn ?? ''
        this.checkingAccount = data.checkingAccount ?? ''
        this.correspondentAccount = data.correspondentAccount ?? ''
        this.bankName = data.bankName ?? ''
        this.bankCode = data.bankCode ?? ''
        this.bankDetails = data.bankDetails ?? ''
        this.addressLegal = data.addressLegal ?? ''
        this.addressActual = data.addressActual ?? ''
        this.addressRegistration = data.addressRegistration ?? ''
        this.phone = data.phone ?? ''
        this.email = data.email ?? ''
        this.www = data.www ?? ''
        this.socialFacebook = data.socialFacebook ?? ''
        this.socialTwitter = data.socialTwitter ?? ''
        this.socialTelegram = data.socialTelegram ?? ''
        this.about = data.about ?? ''
        this.logo = data.logo ?? ''
        this.logoPreview = data.logoPreview ?? ''
        this.specialization = data.specialization ? {label: data.specialization, value: data.specialization} : undefined
        this.subSpecializations = data.subSpecializations ? data.subSpecializations.map((v: string) => ({label: v, value: v})) : undefined
        this.hidden = data.hidden
        this.moderationPassed = data.moderationPassed
        this.workCoins = data.workCoins
    }
}

class PublicProfileSend {
    fullName: string
    shortName: string
    inn: string
    kpp: string
    ogrn: string
    checkingAccount: string
    correspondentAccount: string
    bankName: string
    bankCode: string
    bankDetails: string
    addressLegal: string
    addressActual: string
    addressRegistration: string
    phone: string
    email: string
    www: string
    socialFacebook: string
    socialTwitter: string
    socialTelegram: string
    about: string
    logo: string
    logoPreview: string
    specialization?: string
    subSpecializations?: string[]

    constructor(data: PublicProfile) {
        this.fullName = data.fullName
        this.shortName = data.shortName
        this.inn = data.inn
        this.kpp = data.kpp
        this.ogrn = data.ogrn
        this.checkingAccount = data.checkingAccount
        this.correspondentAccount = data.correspondentAccount
        this.bankName = data.bankName
        this.bankCode = data.bankCode
        this.bankDetails = data.bankDetails
        this.addressLegal = data.addressLegal
        this.addressActual = data.addressActual
        this.addressRegistration = data.addressRegistration
        this.phone = data.phone
        this.email = data.email
        this.www = data.www
        this.socialFacebook = data.socialFacebook
        this.socialTwitter = data.socialTwitter
        this.socialTelegram = data.socialTelegram
        this.about = data.about
        this.logo = data.logo
        this.logoPreview = data.logoPreview
        this.specialization = data.specialization?.value
        this.subSpecializations = data.subSpecializations?.map(v => v.value)
    }
}

interface Spec {
    name: string
    content: string[]
}

class Specialization {
    label: string
    options: {
        label: string
        value: string
    }[]

    constructor(data: Spec) {
        this.label = data.name
        this.options = data.content.map(v => ({
            label: v,
            value: v
        }))
    }
}