export const getPluralForm = (number: number, form1: string, form2: string, form3: string) => {
	const n = Math.trunc(Math.abs(number)) % 100
	const n1 = n % 10

	if (n > 4 && n < 21) return form3

	if (n1 === 1) return form1

	if (n1 > 1 && n1 < 5) return form2

	return form3
}
