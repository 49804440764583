import "./OnboardingView.style.sass";
import React, { useContext, useEffect, useState } from "react";
import { InterfaceContext } from "../../global/context.interface";
import { useTranslation } from "react-i18next";
import { req } from "../../global/common";
import { Dialog, DialogContent } from "@rmwc/dialog";
import { Button } from "@rmwc/button";
import { IOnboardingView } from "./OnboardingView.types";
import { UserContext } from "../../global/context.user";

export const OnboardingView = () => {
    const [steps, setSteps] = useState<{ count: number, current: number }>(null as unknown as { count: number, current: number });
    const [isOpen, setIsOpen] = useState(false);
    const [onboardingItem, setOnboardingItem] = useState<IOnboardingView[]>([]);
    const interfaceCTX = useContext(InterfaceContext);
    const userCTX = useContext(UserContext);
    const { t } = useTranslation("common");

    const getOnboardingItem = () => {
        let role = "";
        // export type Role = 'worker' | 'foreman' | 'admin' | 'hr' | 'organizer' | 'pre-worker' | 'moderator'
        const roles = userCTX.user?.allRoles;
        if (!roles) return;
        if (roles.includes("hr")) {
            role = "hr";
        } else if (roles.length === 1 && roles.includes("admin")) {
            role = "admin";
        } else if ((roles.length === 2 && roles.includes("recruiter") && roles.includes("admin")) || (roles.length === 1 && roles.includes("recruiter"))) {
            role = "recruiter";
        } else {
            interfaceCTX.setIsOnboardingOpen(false);
            return;
        }
        req.get(`/info/onboarding?platformType=web&module=${role}`)
            .then(({ data }) => {
                if (data.total === 0) {
                    onClose();
                } else {
                    setSteps({
                        count: data.total, current: 1
                    });
                    setOnboardingItem(data.data);
                    setIsOpen(true);
                }
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t("error." + e.response?.data)
                });
            });
    };

    const onClose = () => {
        setIsOpen(false);
        setSteps(null as unknown as { count: number, current: number });
        setOnboardingItem([]);
        interfaceCTX.setIsOnboardingOpen(false);
        // if (userCTX.user?.currentRole !== "worker") {
        //     interfaceCTX.setInstructionValue(true, 0);
        // } else {
        req.post("/profile/training-complete");
        // }
    };

    useEffect(() => {
        getOnboardingItem();
    }, []);

    return (
        <Dialog
            className="onboarding-item-dialog"
            open={isOpen}
            onClose={() => {
                onClose();
            }}
        >
            <DialogContent className={"onboarding-item-dialog-content"}>
                {onboardingItem.length > 0 && steps &&
                    <>
                        <div className={"onboarding-item-dialog-wrapper"}>
                            <div className={"onboarding-item-dialog-body"}>
                                <div
                                    className={"onboarding-item-dialog-body-image"}
                                    style={{ backgroundImage: `url(${onboardingItem[steps.current - 1].path})` }}
                                />

                            </div>
                        </div>
                        <div
                            className={`onboarding-item-dialog-footer${steps.current === steps.count
                                ? " onboarding-item-dialog-footer-center" : ""}`}>
                            {steps.current !== steps.count &&
                                <div className={"onboarding-item-dialog-footer-points"}>
                                    <div className={"onboarding-item-dialog-footer-count"}>
                                        {steps.current + "/" + steps.count}
                                    </div>
                                    <div className={"onboarding-item-dialog-footer-points-wrapper"}>
                                        {onboardingItem.map((item: any, index) => (
                                            <div
                                                className={`onboarding-item-dialog-footer-point 
                                        ${steps.current === index + 1 ? " point-active" : ""}`}
                                            />)
                                        )}
                                    </div>
                                </div>
                            }
                            <div className={"onboarding-item-dialog-footer-buttons"}>
                                {steps.current !== steps.count &&
                                    <Button
                                        raised={false}
                                        className={"onboarding-item-dialog-footer-btn"}
                                        outlined={false}
                                        onClick={event => {
                                            event.preventDefault();
                                            onClose();
                                        }}
                                    >
                                        Пропустить все
                                    </Button>
                                }
                                <Button
                                    raised={false}
                                    className={"onboarding-item-dialog-footer-btn"}
                                    outlined={false}
                                    onClick={event => {
                                        event.preventDefault();
                                        if (steps.current === steps.count) {
                                            onClose();
                                        } else {
                                            setSteps(prevState => ({ ...prevState, current: prevState.current + 1 }));
                                        }
                                    }}
                                >
                                    {steps.current === steps.count ? "Начать работу!" : "Далее"}
                                </Button>
                            </div>
                        </div>
                    </>
                }
            </DialogContent>
        </Dialog>
    );
};