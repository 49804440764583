import { LoadingProgress, PageTitle, ProgressPage } from 'components'
import { ContractCard, useContracts } from 'pages/auth-as-worker'
import { FC } from 'react'
import './contract-list-page.sass'

export const ContractListPage: FC = (): JSX.Element => {
	const { contracts, isFetching, isUploading } = useContracts()

	return (
		<div className='contract-list-page'>
			<ProgressPage
				state={isFetching}
				render={() => (
					<>
						<PageTitle title='Текущая работа' />
						<div className='contract-list-page-list'>
							{contracts.map(contract => (
								<ContractCard key={contract.id} className='contract-list-page-card' contract={contract} />
							))}
						</div>
						{!isUploading && <LoadingProgress />}
					</>
				)}
			/>
		</div>
	)
}
