import React, { useEffect, useState } from "react";
import { TextField } from "@rmwc/textfield";
import { authorNameAbbreviated, isSuperAdm, req } from "../../global/common";
import { useHistory } from "react-router-dom";
import "./VacanciesList.style.sass";
import { UserContext } from "../../global/context.user";
import { InterfaceContext } from "../../global/context.interface";
import { FixedButtons, LoadingProgress, PageTitle, ProgressPage, TabButtons } from "components";
import { Icon } from "@rmwc/icon";
import { useAutoCloseDialog, useDebounceEffect } from "../../custom-hooks";
import { CurrentTariff } from "../../components/CurrentTariff";
import { VacancyCard } from "./components/VacancyCard";
import { IVacancyResponse } from "./VacanciesList.types";
import { PagesRoutes } from "../../global/routes";
import { PublicProfilePage } from "../auth-as-moderator/OrganizationProfile/components/PublicProfile/PublicProfile";
import { PaymentHistory } from "../auth-as-moderator/OrganizationProfile/components/PaymentHistory/PaymentHistory";
import {
    ConnectedModules
} from "../auth-as-moderator/OrganizationProfile/components/ConnectedModules/ConnectedModules";
import { User } from "../../global/definitions/definitions";
import { PageHeader } from "components/PageHeader/PageHeader";


export const VacanciesList = () => {
    const history = useHistory();
    const userCTX = React.useContext(UserContext);
    const interfaceCTX = React.useContext(InterfaceContext);
    const [isArchive, setIsArchive] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenMessageMenu, setIsOpenMessageMenu] = useState(false);
    const [vacanciesList, setVacanciesList] = useState<IVacancyResponse[] | false>([]);
    const [total, setTotal] = useState(0);
    const [query, setQuery] = React.useState({
        limit: 30,
        offset: 0,
        type: "active",
        position: "",
        exactState: ""
    });

    const [isReceiveLetters, setIsReceiveLetters] = useState<{
        hrGetRequestsOnApproval: boolean
    }>({
        hrGetRequestsOnApproval: false
    });

    const saveIsReceiveLetters = (payload: {
        hrGetRequestsOnApproval?: boolean
    }) => {
        req
            .post("/profile/settings/save", payload)
            .then(({ data }) => {
                setIsReceiveLetters((prevState: any) => ({
                    ...prevState,
                    hrGetRequestsOnApproval: !isReceiveLetters.hrGetRequestsOnApproval
                }));
            });
    };

    const refreshData = () => {
        setQuery(prevState => ({
            ...prevState,
            limit: 30
        }));
    };

    useEffect(() => {
        req.get("/profile/get")
            .then(({ data }) => {
                const newUserData = new User(data);
                userCTX.setUser({
                    ...newUserData
                });
            });
    }, [])

    useDebounceEffect(() => {
        setIsLoading(true);
        req.get(`/vacancy/organisation/list`, query)
            .then(({ data }) => {
                if (data.data.length > 0) {
                    setVacanciesList(data.data);
                    setTotal(data.total);
                    setIsLoading(false);
                } else {
                    setVacanciesList(false);
                    setTotal(data.total);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setVacanciesList(false);
                setIsLoading(false);
            });
    }, 300, [query]);

    useEffect(() => {
        req.get("/profile/settings/get").then(({ data }) => {
            setIsReceiveLetters(data);
        });
    }, []);

    useEffect(() => {
        //@ts-ignore
        if (interfaceCTX.scrollTop > 0 && interfaceCTX.scrollTop + interfaceCTX.offsetHeight > interfaceCTX.scrollHeight - 10) {
            if (!vacanciesList) return;
            if (total > vacanciesList.length) {
                setQuery(prevState => ({
                    ...prevState,
                    limit: prevState.limit + 30
                }));
            }
        }
    }, [interfaceCTX.scrollTop]);


    const tabClick = (tab: any) => {
        if (tab === query.exactState || (tab === 'all' && query.exactState === "") || isLoading) return false
        if (tab === "all") {
            setQuery(prevState => ({
                ...prevState,
                exactState: ""
            }));
        } else {
            setQuery(prevState => ({
                ...prevState,
                exactState: tab
            }));
        }
        return true;
    };
    useAutoCloseDialog(() => {
        setIsOpenMessageMenu(false);
    }, []);

    return (
        <div className="vacancies-page">
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <PageHeader>
                    <PageTitle
                        title={isArchive ? "Архив" : "Список вакансий"}
                        breadcrumbs={true}
                    />
                    {userCTX.user?.currentRole !== "organizer" &&
                        <CurrentTariff />}
                </PageHeader>
            </div>
            <div className="vacancies-page-top">
                <TextField
                    outlined
                    className={"vacancies-page-search"}
                    label="Поиск по профессии"
                    maxLength={160}
                    trailingIcon="search"
                    onChange={(e: any) => {
                        const value = e.currentTarget.value;
                        setQuery(prevState => ({
                            ...prevState,
                            position: value
                        }));
                    }}
                />
                <div className="vacancies-message">
                    <div className="vacancies-message-block">
                        <div
                            style={{ display: "flex", cursor: "pointer" }}
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsOpenMessageMenu((prevState: boolean) => !prevState);
                            }}
                        >
                            <div className="vacancies-message-text">
                                Получение писем
                            </div>
                            <Icon icon={"expand_more"} />
                        </div>
                        {isOpenMessageMenu &&
                            <div className="vacancies-message-menu noSelect"
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}>
                                <div className={"vacancies-message-menu-item"}
                                    onClick={() => {
                                        saveIsReceiveLetters({
                                            hrGetRequestsOnApproval: !isReceiveLetters.hrGetRequestsOnApproval
                                        });
                                    }}
                                >
                                    {isReceiveLetters.hrGetRequestsOnApproval ? <Icon icon={"done"} /> :
                                        <div className="vacancies-message-menu-item-plug" />}
                                    <div className="vacancies-message-menu-item-text">
                                        Получать письма об откликах на вакансию
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={"fixed-buttons-right"}>
                    {isArchive ?
                        <FixedButtons
                            length={1}
                            buttons={[
                                {
                                    label: "Список вакансий",
                                    primary: true,
                                    onClick: () => {
                                        setIsArchive(false);
                                        setQuery(prevState => ({
                                            ...prevState,
                                            type: "active",
                                            exactState: ""
                                        }));
                                    }
                                }
                            ]}
                        />
                        :
                        <FixedButtons
                            length={2}
                            buttons={
                                [
                                    {
                                        label: "Архив",
                                        primary: false,
                                        outlined: true,
                                        onClick: () => {
                                            setQuery(prevState => ({
                                                ...prevState,
                                                type: "archived",
                                                exactState: ""
                                            }));
                                            setIsArchive(true);
                                        }
                                    },
                                    {
                                        label: "Создать",
                                        primary: true,
                                        onClick: () => {
                                            history.push({
                                                pathname: PagesRoutes.VACANCIES_LIST + PagesRoutes.VACANCY_PAGE + `/create`,
                                                state: history.location.state
                                            });
                                        },
                                        disabled: isLoading || isSuperAdm(userCTX.user),
                                    }

                                ]
                            }
                        />
                    }
                </div>
            </div>
            <TabButtons
                langPrefix={"vacancy.tabs."}
                data={isArchive
                    ? {
                        "all": () => <div />,
                        "withdrawn_from_publication": () => <div />,
                        "deleted": () => <div />
                    }
                    : {
                        "all": () => <div />,
                        "published": () => <div />,
                        "saved": () => <div />,
                    }
                }
                tabClick={tabClick}
                whiteBackground={false}
                isUpdateTab={isArchive}
            />
            <ProgressPage
                state={vacanciesList}
                errorPage={() => (
                    <div className="fullpage-alert">
                        <Icon icon={require("../../misc/crane.svg").default} />
                        <div style={{ textAlign: "center", paddingTop: "47px" }}>
                            Пока нет созданных вакансий.<br />
                            Добавьте вакансию, нажав на кнопку “Создать”
                        </div>
                    </div>
                )}
                render={() => (
                    <div className={"vacancies-page-body"}>
                        <div className={"vacancies-page-list"}>
                            {!!vacanciesList && vacanciesList.map(item => {
                                return (
                                    <VacancyCard
                                        key={item.id}
                                        city={item.city}
                                        sinceLastView={item.sinceLastView}
                                        daysBeforeTheEndPublication={item.daysBeforeTheEndPublication}
                                        position={item.position}
                                        refreshData={refreshData}
                                        id={item.id}
                                        vacancyState={item.vacancyState}
                                        author={authorNameAbbreviated(item.author)}
                                        dateCreatedAt={item.dateCreatedAt}
                                        dateDeletedAt={item.dateDeletedAt}
                                        datePublication={item.datePublication}
                                        dateWithdrawnFromPublicationAt={item.dateWithdrawnFromPublicationAt}
                                        responseCount={item.responseCount}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
            />
            {isLoading && <LoadingProgress />}
        </div>
    );
};
