import { Checkbox } from '@rmwc/checkbox'
import { Dialog } from '@rmwc/dialog'
import { Elevation } from '@rmwc/elevation'
import { Icon } from '@rmwc/icon'
import { TextField } from '@rmwc/textfield'
import { FormEvent, forwardRef, useCallback, useEffect, useState } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import { FormField } from '../form-field/form-field'
import { CheckboxOptionsDialogProps } from './checkbox-options-dialog-def'
import './checkbox-options-dialog.sass'

export const CheckboxOptionsDialog = forwardRef<HTMLDivElement, CheckboxOptionsDialogProps>(
	(
		{
			open,
			title,
			description,
			hasSearch,
			search,
			defaultValues,
			options,
			onClose,
			onSuccess,
			onChangeSearch,
			onListScroll
		},
		ref
	) => {
		const [values, setValues] = useState<any[]>(defaultValues || [])

		useEffect(() => {
			if (open) {
				setValues(defaultValues || [])
			}
		}, [open, defaultValues])

		const handleOnChangeCheckbox = useCallback((value: any, isChecked: boolean) => {
			if (isChecked) {
				setValues((state: any[]) => state.filter(element => element !== value))
			} else {
				setValues((state: any[]) => (state?.length <= 4 ? [...state, value] : state))
			}
		}, [])

		const handleOnClickSelect = useCallback(() => {
			onSuccess?.(values)
			onClose()
		}, [values, onSuccess, onClose])

		return (
			<Dialog open={open} onClose={onClose}>
				<div className='checkbox-dialog-wrapper'>
					<div className='checkbox-dialog-title'>
						<h3>{title}</h3>
						<Icon icon='close' className='icon-close' onClick={onClose} />
					</div>
					<div className='checkbox-dialog'>
						{hasSearch && (
							<div className='search-wrapper'>
								<TextField
									outlined
									maxLength={160}
									trailingIcon='search'
									value={search}
									onChange={onChangeSearch}
									className='search'
									label='Поиск'
								/>
							</div>
						)}
						<div className='checkbox-dialog-description'>{description}</div>
						<Elevation className='checkbox-dialog-content' z={3} ref={ref} onScroll={onListScroll}>
							{!!options?.length &&
								options?.map(({ name, value }) => {
									const isChecked = !!values?.includes(value)

									return (
										<Checkbox
											checked={isChecked}
											className={`checkbox-dialog-field ${isChecked ? ' y-checkbox' : ' off-checkbox'}`}
											key={value}
											label={name}
											value={value}
											onChange={(e: FormEvent<HTMLInputElement>) => {
												handleOnChangeCheckbox(+e.currentTarget.value, isChecked)
											}}
										/>
									)
								})}
						</Elevation>
					</div>
				</div>
				<Elevation className='checkbox-dialog-button-wrapper' z={3}>
					<FormField className='checkbox-dialog-button' label='Выбрать' onClick={handleOnClickSelect} />
				</Elevation>
			</Dialog>
		)
	}
)
