import { Icon } from '@rmwc/icon'
import clsx from 'clsx'
import { FC } from 'react'
import { MessageInfoCardProps } from './MessageInfoCard.types'
import './MessageInfoCard.style.sass'

export const MessageInfoCard: FC<MessageInfoCardProps> = ({ className, message, icon, children }): JSX.Element => {
	return (
		<div className={clsx('message-info-card', className)}>
			<div className='message-info-card-body'>
				{icon && message && (
					<div className='message-info-card-message'>
						<Icon className='message-info-card-icon' icon={icon} />
						<span className='message-info-card-text'>{message}</span>
					</div>
				)}
				{children && children}
			</div>
		</div>
	)
}
