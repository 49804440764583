import React from "react";
import { TabButtons } from "../../../../../components";
import { Onboarding } from "../Onboarding";

export const OnboardingTabsLayout: React.FC<{ platform: "mobile" | "web"}> = ({ platform}) => {

    return (
        <TabButtons
            data={platform === "web"
                ? {
                    "Админ": () => <Onboarding platform={platform} module={'admin'} />,
                    "Кадровик": () => <Onboarding platform={platform} module={'hr'} />,
                    "Рекрутер": () => <Onboarding platform={platform} module={'recruiter'} />,
                }
                : {
                    "Прораб": () => <Onboarding platform={platform} module={'foreman'} />,
                    "Работник": () => <Onboarding platform={platform} module={'worker'} />
                }}
            embed={[true, true, true, true, false, true]}
        />
    );
};
