import React from "react";
import {Link} from "react-router-dom";
import {attrList} from "../../global/common";
import './SpanButtons.style.sass'
import {DataPropClick, DataPropHref, DataPropLink} from "./SpanButtons.types";


export function SpanButtons({data}: {data: (DataPropLink|DataPropHref|DataPropClick)[]}) {
    return (
        <div className="span-buttons">
            {data.map((button, index) => {
                if (button.hide) return null

                const ButtonTag = button.tag ?? 'h3'
                return (
                    <ButtonTag key={'span-button-' + index}
                        {...attrList(['className', 'span-a'], ['className', button.disabled && 'span-a--disabled'], ['className', button.class])}
                    >
                        {button.disabled
                            ? <span>{button.label}</span>
                            : 'onClick' in button && button.onClick
                                ? <span onClick={button.onClick}>{button.label}</span>
                                : 'to' in button && button.to
                                    ? <Link to={button.to}>{button.label}</Link>
                                    : 'href' in button && button.href
                                        ? <a href={button.href} target={button.isBlank ? '_blank' : '_self'}>{button.label}</a>
                                        : <span>{button.label}</span>
                        }
                    </ButtonTag>
                )
            })}
        </div>
    )
}