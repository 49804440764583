import { ReviewsProps } from "./reviews-def";
import { Review } from "./components/review";
import { authorNameShort } from "../../../../../components/Need/Need";
import "./reviews.sass";

export default function Reviews({ reviews }: ReviewsProps) {
  return (
    <div className={"reviews"}>
      {reviews?.map(
        ({
          id,
          date,
          score,
          workFrom,
          workTo,
          author,
          comment,
          authorProfile,
        }) => {
          return (
            <Review
              key={id}
              authorName={authorNameShort(authorProfile)}
              score={score}
              comment={comment}
              date={date}
              author={author}
              workFrom={workFrom}
              workTo={workTo}
            />
          );
        }
      )}
    </div>
  );
}
