import { PageTitle, ProgressPage } from 'components'
import { EmploymentProcessStep, useRequest } from 'pages/auth-as-worker'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { EmploymentProcessUrlParams } from './employment-process-def'
import { stepInfo } from './employment-process-utils'
import './employment-process.sass'

export const EmploymentProcessPage: FC = (): JSX.Element => {
	const { requestId } = useParams<EmploymentProcessUrlParams>()
	const {
		request: { state },
		isSuccess
	} = useRequest(+requestId)
	const [currentStep, setCurrentStep] = useState(-1)
	const [steps, setSteps] = useState<boolean[]>(new Array(stepInfo.length).fill(false))
	/* const { stages, isSuccess } = useRoadMap(+requestId) */

	useEffect(() => {
		if (isSuccess) {
			const activeStep = stepInfo.findIndex(step => step.stepNames.find(stepName => stepName === state))
			activeStep >= 0 && setCurrentStep(activeStep)
		}
	}, [isSuccess])

	useEffect(() => {
		if (isSuccess) {
			state !== 'cancelled'
				? setSteps((steps: boolean[]) => steps.map((_, index) => (index <= currentStep ? true : false)))
				: setSteps((steps: boolean[]) => steps.map((_, index) => (index === steps.length - 1 ? true : false)))
		}
	}, [currentStep])

	return (
		<div className='employment-process-page'>
			<PageTitle title='Процесс трудоустройства' breadcrumbs />
			<ProgressPage
				state={isSuccess}
				render={() => (
					<div className='employment-process-page-employment-card'>
						{/* {stages.map((stage, index.ts) => (
							<EmploymentProcessStep
								key={index.ts}
								className='employment-process-page-employment-card-item'
								title={stage.title}
								body={stage.text}
								checked={!!stage.date && stage.current === false}
								isActive={stage.current}
							/>
						))} */}
						{stepInfo.map((step, index) => {
							if (!step.stepNames.includes('documents_undelivered') || state === 'documents_undelivered') {
								return (
									<EmploymentProcessStep
										key={step.title}
										className='employment-process-page-employment-card-item'
										title={step.title}
										body={step.body}
										checked={steps[index] && steps[index + 1]}
										isActive={
											step.stepNames.includes(state) &&
											state !== 'documents_confirmed' &&
											state !== 'documents_awaiting'
										}
									/>
								)
							}
						})}
						{state === 'documents_confirmed' && (
							<EmploymentProcessStep
								className='employment-process-page-employment-card-item'
								title='Раздел «Личные документы» заполнен'
								checked={false}
								isActive
							/>
						)}
						{state === 'documents_awaiting' && (
							<EmploymentProcessStep
								className='employment-process-page-employment-card-item'
								title='Запрос на заполнение «Личных документов»'
								body='Для того, чтобы Работодатель подготовил документы, ему необходимо ознакомиться с Вашими данными в разделе документов'
								checked={false}
								isActive
							/>
						)}
						{state === 'rejected' && (
							<EmploymentProcessStep
								className='employment-process-page-employment-card-item'
								title='Отказ по отклику'
								body='На данный момент работодатель не может предложить вам работу'
								checked={false}
								isActive
							/>
						)}
						{state === 'cancelled' && (
							<EmploymentProcessStep
								className='employment-process-page-employment-card-item'
								title='Отклик отозван'
								body='Вы отозвали свой отклик. Для того, чтобы снова откликнуться на потребность перейдите на экран потребности'
								checked={false}
								isActive
							/>
						)}
					</div>
				)}
			/>
		</div>
	)
}
