import clsx from 'clsx'
import { FC } from 'react'
import { RequestFieldProps } from './request-field-def'
import './request-field.sass'

export const RequestField: FC<RequestFieldProps> = ({
	className,
	titleClassName,
	valueClassName,
	title,
	value
}): JSX.Element => {
	if (value) {
		return (
			<div className={clsx('request-field', className)}>
				<div className={clsx('request-field-title', titleClassName)}>{title}</div>
				<div className={clsx('request-field-value', valueClassName)}>{value}</div>
			</div>
		)
	}

	return <></>
}
