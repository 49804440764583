import { Elevation } from '@rmwc/elevation'
import { Icon } from '@rmwc/icon'
import clsx from 'clsx'
import { format, getISODay, getISOWeek } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DataObject, WeekScrollerProps } from './WeekScroller.types'
import { getWeekData, isCurrentDay, isCurrentWeek } from './WeekScroller.service'
import './WeekScroller.style.sass'

export const WeekScroller: FC<WeekScrollerProps> = ({
	className,
	currentPage,
	currentDay,
	shifts,
	onSetCurrentPage,
	onSetCurrentDay
}): JSX.Element => {
	const { t } = useTranslation('common')

	const getWeekText = (dateObject: DataObject) => {
		return (
			'Неделя ' +
			getISOWeek(dateObject.date[0]) +
			'\n' +
			dateObject.date.map(v => t('date.month.full.' + v.getMonth())).join('/')
		)
	}

	const decreaseHandler = () => onSetCurrentPage(currentPage - 3)

	const increaseHandler = () => onSetCurrentPage(currentPage + 3)

	return (
		<div className={clsx('week-scroller', className)}>
			<div className='page-button' onClick={decreaseHandler}>
				<Icon icon='arrow_back_ios' />
			</div>
			{getWeekData(currentPage).map((dateObject, index) => (
				<div
					key={index}
					className={clsx('week-block', {
						'week-block--current': isCurrentWeek(dateObject.date)
					})}
				>
					<div className='week-title'>{getWeekText(dateObject)}</div>
					<div className='days-container'>
						{dateObject.days.map((day, index) => {
							const formattedDay = format(day, 'dd.MM.yyyy')
							const shift = shifts.filter(shift => shift.date === formattedDay && shift.brigadeCount > 0).length > 0

							return (
								<Elevation
									key={index}
									className={clsx('day-block', 'unselectable', {
										'day-block--current': isCurrentDay(day),
										'day-block--active': currentDay === formattedDay
									})}
									z={3}
									onClick={() => onSetCurrentDay(formattedDay)}
								>
									<div className='day-title'>{t('date.ISODay.short.' + getISODay(day)).toUpperCase()}</div>
									<div className='day-number'>{day.getDate()}</div>
									{shift && <Icon icon='star_outline' />}
								</Elevation>
							)
						})}
					</div>
				</div>
			))}
			<div className='page-button' onClick={increaseHandler}>
				<Icon icon='arrow_forward_ios' />
			</div>
		</div>
	)
}
