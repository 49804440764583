import { Elevation } from "@rmwc/elevation";
import { reformatToMask } from "pages/auth-as-worker/auth-as-worker-utils";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import {
    Avatar,
    DocumentsTable,
    FixedButtons,
    IDocumentsData,
    InfoBlock,
    PageTitle,
    ProgressPage,
    TabButtons
} from "components";
import { authorName, req } from "../../global/common";
import { ChatContext } from "../../global/context.chat";
import { UserProfile } from "../../global/definitions/definitions";
import { ICurrentStepsInfo, NotReviewed, ReviewBody, ReviewsObject } from "./public-worker-profile-def";
import "./public-worker-profile.sass";
import { AboutMeSubPage } from "./subPages/about-me/about-me";
import { AnthropometrySubPage } from "./subPages/anthropometry/anthropometry";
import { DocumentsSubPage } from "./subPages/documents/documents";
import { ProfessionalExperienceSubPage } from "./subPages/professional-experience/professional-experience";
import { UserContext } from "../../global/context.user";
import { Icon } from "@rmwc/icon";
import { useAutoCloseDialog } from "../../custom-hooks";
import { getColorState, getStepMessage, renderStepsList } from "./PublicWorkerProfilePage.service";
import { useTranslation } from "react-i18next";
import { InterfaceContext } from "../../global/context.interface";
import { vacanciesApi, VacancyResponse } from "global/api";
import { Preset } from "matrix-js-sdk";
import { getValue } from "./dump/dump-utils";
import { Button } from "@rmwc/button";
import { EAcceptValues } from "./dump/dump";
import { Documents } from "./components/documents-component/documents-component";
import { getFormattedDeclinableDate } from "../../global/utils";
import { InviteWorkerBtn } from "components/InviteWorkerBtn/InviteWorkerBtn";

export function PublicWorkerProfilePage() {
    const [loaded, setLoaded] = useState<boolean | null>(null);
    const { userId, vacancyId, responseId } = useParams<{ userId: string; responseId: string; vacancyId: string }>();
    const chatCTX = useContext(ChatContext);
    const { t } = useTranslation("common");
    const userCTX = useContext(UserContext);
    const [isDump, setIsDump] = React.useState(false);
    const [vacancy, setVacancy] = useState<VacancyResponse | undefined>(undefined);
    const [workerProfile, setWorkerProfile] = useState<UserProfile>(null!);
    const [reviews, setReviews] = useState<ReviewsObject>(null!);
    const [canAddReview, setCanAddReview] = useState<boolean>(null!);
    const [reviewsReload, setReviewsReload] = useState<boolean>(false);
    const [revMode, setRevMode] = useState<number>(null!);
    const [documents, setDocuments] = useState<IDocumentsData[] | null>(null);
    const history: any = useHistory();
    const interfaceCTX = React.useContext(InterfaceContext);
    const [experienceQty, setExperienceQty] = useState(0);
    const [currentStepsInfo, setCurrentStepsInfo] = useState<ICurrentStepsInfo>({} as ICurrentStepsInfo);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { state } = useLocation();

    console.log(userId)

    const query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        vacancyId && vacanciesApi
            .getVacancy(vacancyId)
            .then(({ data }) => {
                setVacancy(data);
            })
            .catch(e => {
                // interfaceCTX.showMessage({
                //     title: "Ошибка",
                //     body: t(e.response.data)
                // });
            });
    }, []);

    const onChangeSortValues = (fieldName: string, sortType: "ASC" | "DESC" | null) => {
        getDocumentsList(userId, { [fieldName]: sortType });
    };

    const getDocumentsList = (id: string, sortBy?: any) => {
        req
            .post(`/profile/worker/certificates-user-list?userID=${id}`, sortBy ? sortBy : { endDate: "ASC" })
            .then(({ data }) => {
                setDocuments(data);
            });
    };

    function getJobStatusColor(status: string) {

        const colors = {
            green: "rgba(51, 255, 0, 1)",
            orange: "rgba(235, 255, 0, 1)",
            red: "rgba(255, 1, 1, 1)"
        };

        switch (status) {
            case "Не работаю, рассматриваю предложения":
                return colors.green;
            case "Трудоустроен, рассматриваю предложения":
                return colors.orange;
            case "Трудоустроен, не рассматриваю предложения":
                return colors.red;
            default:
                return colors.red;
        }
    }

    const requestProfileCompletion = () => {
        setIsLoading(true);
        req
            .post(`/recruiter/${responseId}/profile-request`)
            .then(() => {
                getResponseSteps();
                interfaceCTX.showMessage({
                    body: "Запрос на заполнение отправлен",
                    icon: "done"
                });
                if (vacancy) {
                    chatCTX.sendMessage(workerProfile, getStepMessage("profile-request", vacancy.position?.trim(), workerProfile.personalInfo.firstName));
                }
            })
            .catch(e => {
                interfaceCTX.showMessage({
                    title: "Ошибка",
                    body: t(e.response.data)
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const renderButtons = (state: string | undefined) => {
        if (userCTX.user?.currentRole && !["admin", "recruiter"].includes(userCTX.user?.currentRole)) return null;
        let buttonsData: any[] = [];
        const changeState = (mode: string) => {
            setIsLoading(true);
            req
                .post(`/recruiter/${responseId}/${mode}`)
                .then(({ data }) => {
                    getResponseSteps();
                    if (vacancy) {
                        chatCTX.sendMessage(workerProfile, getStepMessage(mode, vacancy.position?.trim(), workerProfile.personalInfo.firstName, vacancy.organisationName.trim()));
                    }
                })
                .then(() => setReviewsReload(prevState => !prevState))
                .catch(e => {
                    if (e.response.data === "organisation.not_enough_contacts") {

                    } else {
                        interfaceCTX.showMessage({
                            title: "Ошибка",
                            body: t(e.response.data)
                        });
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        const isChatNotifications = () => {
            let currentRoom = chatCTX.rooms.find(room => room.userId === workerProfile.chatCredentials);
            return !!(currentRoom && currentRoom.count > 0);
        };

        if (state === "pending") {
            buttonsData = [
                {
                    label: "пригласить",
                    disabled: isLoading,
                    primary: true,
                    onClick: () => {
                        changeState("considering");
                    }
                },
                {
                    label: "отказать",
                    disabled: isLoading,
                    primary: true,
                    onClick: () => {
                        changeState("decline");
                    }
                },
                {
                    label: "ЧАТ",
                    disabled: true,
                    primary: true,
                    onClick: () => {
                    }
                }
            ];
        } else if (state === "considering") {
            buttonsData = [
                {
                    label: "Предложить работу",
                    disabled: isLoading,
                    primary: true,
                    onClick: () => {
                        changeState("invite");
                    }
                },
                {
                    label: "отказать",
                    disabled: isLoading,
                    primary: true,
                    onClick: () => {
                        changeState("decline");
                    }
                },
                {
                    label: "ЧАТ",
                    disabled: false,
                    notification: isChatNotifications(),
                    outlined: true,
                    onClick: () => {
                        chatCTX.sendMessage(workerProfile);
                    }
                }
            ];
        } else if (state === "job_offer") {
            buttonsData = [
                {
                    label: "отказать",
                    disabled: isLoading,
                    primary: true,
                    onClick: () => {
                        changeState("decline");
                    }
                },
                {
                    label: "ЧАТ",
                    disabled: false,
                    notification: isChatNotifications(),
                    outlined: true,
                    onClick: () => {
                        chatCTX.sendMessage(workerProfile);
                    }
                }
            ];
        } else if (state === "documents_preparation") {
            buttonsData = [
                {
                    label: "Вышел на работу",
                    disabled: isLoading,
                    primary: true,
                    onClick: () => {
                        changeState("accept");
                    }
                },
                {
                    label: "отказать",
                    disabled: isLoading,
                    primary: true,
                    onClick: () => {
                        changeState("decline");
                    }
                },
                {
                    label: "ЧАТ",
                    disabled: false,
                    notification: isChatNotifications(),
                    outlined: true,
                    onClick: () => {
                        chatCTX.sendMessage(workerProfile);
                    }
                }
            ];
        } else if (state === "accepted") {
            buttonsData = [];
        } else if (state === "rejected" || state === "rejected_by_worker") {
            buttonsData = [
                {
                    label: "Пригласить",
                    disabled: isLoading,
                    primary: true,
                    onClick: () => {
                        changeState("considering");
                    }
                },
                {
                    label: "ЧАТ",
                    disabled: true,
                    primary: true,
                    onClick: () => {
                    }
                }
            ];
        } else return null;
        return <FixedButtons length={buttonsData.length} buttons={buttonsData} />;
    };

    const getResponseSteps = () => {
        if (userCTX.user?.currentRole === "moderator") return;
        req.get(`/recruiter/${responseId}/steps`).then(({ data }) => {
            setCurrentStepsInfo({
                passedStages: data.passedStages,
                currentStage: data.currentStage,
                profileRequest: data.profileRequest
            });
        });
    };

    React.useEffect(() => {
        req
            .get(`/profile/worker/${userId}/public-profile`)
            .then(({ data }) => {
                responseId && getResponseSteps();
                setWorkerProfile(data);
                getDocumentsList(userId);
                setLoaded(true);
            })
            .catch(() => setLoaded(false));
    }, [reviewsReload]);

    React.useEffect(() => {
        // if (!isDump) {
        req.get(`/reviews/person/${userId}/list`).then(({ data }) => {
            setCanAddReview(data.notCommented.data.findIndex((d: NotReviewed) => d.contract.id === +responseId) !== -1);
            const newData = Object.assign(data, {
                data: data.data.sort((a: ReviewBody, b: ReviewBody) => (a.canEdit === true ? -1 : b.canEdit === true ? 1 : 0))
            });
            setReviews(newData);
        });
        // }
    }, [reviewsReload]);

    useAutoCloseDialog(() => {
        setIsOpen(false);
    }, []);

    return (
        <ProgressPage
            className="public-worker-profile-page"
            state={loaded}
            render={() => (
                <>
                    <PageTitle
                        title={
                            !workerProfile.isDeleted
                                ? "Профиль"
                                : "Профиль удален"
                        }
                        breadcrumbs={true}
                    >
                        {!workerProfile.isDeleted && userCTX.user?.organisation?.confirmed !== "rejected" && userCTX.user?.currentRole !== "moderator" && renderButtons(currentStepsInfo.currentStage)}
                    </PageTitle>
                    <Elevation z={3} className="worker-panel">
                        <div className="inner-container">
                            <div className="worker-info-wrapper">
                                <div className="worker-avatar">
                                    <Avatar
                                        className={"worker-avatar-class"}
                                        linkDisabled
                                        previewHref={workerProfile.avatar?.pathPreview || (workerProfile.avatar as null)}
                                        isProfileDeleted={workerProfile.isDeleted}
                                    />
                                </div>
                                {workerProfile.isDeleted && userCTX.user?.currentRole === "moderator" && (
                                    <ul className="worker-info-list">
                                        <li className="info-item">
                                            <InfoBlock
                                                title="Причина удаления аккаунта"
                                                content={
                                                    <div className={"worker-info-list-reason-deleted"}>
                                                        {workerProfile.deletedReason ? workerProfile.deletedReason : "Без причины"}
                                                    </div>
                                                }
                                            />
                                        </li>
                                    </ul>
                                )}
                                {workerProfile.isDeleted && userCTX.user?.currentRole !== "moderator" && (
                                    <ul className="worker-info-list">
                                        <li className="info-item">
                                            <InfoBlock
                                                title="Профиль удален"
                                                content={<div className={"worker-info-list-reason-deleted"}></div>}
                                            />
                                        </li>
                                    </ul>
                                )}
                                {!workerProfile.isDeleted &&
                                    userCTX.user?.currentRole !== "moderator" && (
                                        <ul className="worker-info-list">
                                            <li className="info-item">
                                                <InfoBlock
                                                    title="ФИО"
                                                    content={authorName(workerProfile.personalInfo)}
                                                />
                                            </li>
                                            <li className="info-item">
                                                <InfoBlock
                                                    title="Контактный телефон"
                                                    content={
                                                        workerProfile.personalInfo.contactPhone ? (
                                                            <a className="telephone"
                                                               href={`tel:${workerProfile.personalInfo.contactPhone}`}>
                                                                {reformatToMask(workerProfile.personalInfo.contactPhone)}
                                                            </a>
                                                        ) : (
                                                            "Будет доступен после приглашения"
                                                        )
                                                    }
                                                />
                                            </li>
                                            <li className="info-item">
                                                <InfoBlock
                                                    title="E-mail"
                                                    content={
                                                        workerProfile.personalInfo.contactPhone ?
                                                        (workerProfile.personalInfo.contactEmail ? (
                                                            <a className="text email"
                                                               href={`mailto:${workerProfile.personalInfo.contactEmail}`}>
                                                                {workerProfile.personalInfo.contactEmail}
                                                            </a>
                                                        ) : "Не указано") : (
                                                            "Будет доступен после приглашения"
                                                        )
                                                    }
                                                />
                                            </li>
                                            <li className="info-item">
                                                <InfoBlock
                                                    title="Место жительства"
                                                    content={
                                                        workerProfile.workerLocation.countryName ? (
                                                            <div className="text">
                                                                {workerProfile.workerLocation.countryName + (workerProfile.workerLocation.regionName ? ", " + workerProfile.workerLocation.regionName : "")}
                                                            </div>
                                                        ) : (
                                                            "Не указано"
                                                        )
                                                    }
                                                />
                                            </li>
                                            {!currentStepsInfo.currentStage && <li><InviteWorkerBtn workerProfile={workerProfile} setReviewsReload={setReviewsReload}/></li>}
                                            {currentStepsInfo.currentStage && (
                                                <li className="info-item">
                                                    <InfoBlock
                                                        title="Статус трудоустройства"
                                                        style={{ overflow: "visible" }}
                                                        content={
                                                            <div
                                                                className={"response-state"}
                                                                style={
                                                                    currentStepsInfo.currentStage && (currentStepsInfo.currentStage === "rejected" || currentStepsInfo.currentStage === "rejected_by_worker")
                                                                        ? { cursor: "default" }
                                                                        : {}
                                                                }
                                                                onClick={event => {
                                                                    event.stopPropagation();
                                                                    if (currentStepsInfo.currentStage !== "rejected" && currentStepsInfo.currentStage !== "rejected_by_worker") {
                                                                        setIsOpen(prevState => !prevState);
                                                                    }
                                                                }}
                                                            >
                                                                <div
                                                                    className={"response-state-circle"}
                                                                    style={{ backgroundColor: getColorState(currentStepsInfo.currentStage) }}
                                                                />
                                                                <div className={"response-state-title"}>
                                                                    {t("vacancy." + currentStepsInfo.currentStage)}
                                                                </div>
                                                                {currentStepsInfo.currentStage !== "rejected" && currentStepsInfo.currentStage !== "rejected_by_worker" && (
                                                                    <Icon
                                                                        icon={isOpen ? "expand_less" : "expand_more"} />
                                                                )}
                                                                {renderStepsList(currentStepsInfo.passedStages, isOpen, t)}
                                                            </div>
                                                        }
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    )}
                                {!workerProfile.isDeleted && userCTX.user?.currentRole === "moderator" && (
                                    <ul className="worker-info-list">
                                        <li className="info-item">
                                            <InfoBlock
                                                title="ФИО"
                                                content={authorName(workerProfile.personalInfo)}
                                            />
                                        </li>
                                        <li className="info-item">
                                            <InfoBlock
                                                title="Контактный телефон"
                                                content={
                                                    <a className="telephone"
                                                       href={`tel:${workerProfile.personalInfo.contactPhone}`}>
                                                        {reformatToMask(workerProfile.personalInfo.contactPhone)}
                                                    </a>
                                                }
                                            />
                                        </li>
                                        <li className="info-item">
                                            <InfoBlock
                                                title="E-mail"
                                                content={
                                                    workerProfile.personalInfo.contactEmail ? (
                                                        <a className="text email"
                                                           href={`mailto:${workerProfile.personalInfo.contactEmail}`}>
                                                            {workerProfile.personalInfo.contactEmail}
                                                        </a>
                                                    ) : (
                                                        "Не указано"
                                                    )
                                                }
                                            />
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </Elevation>
                    <div className="about-me-page-wrapper">
                        <div className="about-me section">
                            <ul className="about-me-list">
                                {workerProfile.personalInfo &&
                                    <li className="about-me-item info-item"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                    }}
                                    >
                                        <InfoBlock
                                            title="Статус поиска"
                                            content={
                                                <div className="job-status">
                                                    {workerProfile.personalInfo.workSearchStatus && <div
                                                        style={{ backgroundColor: getJobStatusColor(workerProfile.personalInfo.workSearchStatus as string) }}
                                                        className="job-status-circle" />
                                                    }
                                                    <span>{getValue(workerProfile.personalInfo.workSearchStatus)}</span>
                                                </div>
                                            }
                                        />
                                        {userCTX.user?.currentRole && userCTX.user?.organisation?.confirmed !== "rejected" &&
                                            ["admin", "recruiter"].includes(userCTX.user?.currentRole) &&
                                            currentStepsInfo.currentStage &&
                                            ["pending", "considering", "documents_preparation", "rejected", "rejected_by_worker"].includes(currentStepsInfo.currentStage) && (
                                                <Button
                                                    className={"profession-info-item-btn"}
                                                    raised={true}
                                                    disabled={isLoading || currentStepsInfo.profileRequest}
                                                    onClick={() => {
                                                        requestProfileCompletion();
                                                    }}
                                                >
                                                    Запросить заполнить опыт
                                                </Button>
                                            )}
                                    </li>
                                }
                                {workerProfile.personalInfo &&
                                    <li className="about-me-item info-item">
                                        <InfoBlock
                                            title="Желательный график работы"
                                            content={getValue(workerProfile.personalInfo.workSearchSchedule)}
                                        />
                                    </li>
                                }
                                {workerProfile.personalInfo &&
                                    <li className="about-me-item info-item">
                                        <InfoBlock
                                            title="О себе"
                                            content={getValue(workerProfile.personalInfo.about)}
                                        />
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="proffessional-exp-subpage-wrapper">
                            {workerProfile.mainProfession || workerProfile.subProfessions ? (
                                <div className="profession-info-wrapper section">
                                    <ul className="profession-info-list">
                                        <li className="profession-info-item info-item" style={{ display: "flex" }}>
                                            <InfoBlock title="Базовая профессия"
                                                       content={getValue(workerProfile.mainProfession?.name)} />
                                        </li>

                                        {workerProfile && (
                                            <>
                                                <li className="profession-info-item info-item">
                                                    <InfoBlock title="Разряд" content={getValue(workerProfile.grade)} />
                                                </li>
                                            </>
                                        )}

                                        {workerProfile.subProfessions && (
                                            <li className="profession-info-item">
                                                <InfoBlock
                                                    title="Дополнительная профессия"
                                                    content={
                                                        <ul className="additional-professions-list">
                                                            {workerProfile.subProfessions.map((prof: any, index: number) => (
                                                                <li key={prof.code + "_" + index}
                                                                    className="additional-profession">
                                                                    {String(prof.name)}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    }
                                                />
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            ) : (
                                ""
                            )}


                            {workerProfile.qualificationDocuments.length !== 0 && (
                                <div className="section">
                                    <div className="section-title">Удостоверения, подтверждающие квалификацию</div>
                                    <div className="documents-list">
                                        {workerProfile.qualificationDocuments.map((doc: any, index: number) => (
                                            <Documents
                                                key={doc.id + "_" + index}
                                                docNumber={doc.number}
                                                issueDate={doc.date}
                                                pathPreview={doc.pathPreview && doc.pathPreview}
                                                path={doc.path && doc.path}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                            {!!workerProfile.permissions && (
                                <div className="section">
                                    {!!workerProfile.permissions && (
                                        <>
                                            <InfoBlock
                                                title="Допуск"
                                                content={
                                                    <>
                                                        {workerProfile.permissions.map((permission: any, index: number) => {
                                                            return (
                                                                <p key={permission.id + "_" + index}
                                                                   className="allowance-text">
                                                                    {permission.name}
                                                                </p>
                                                            );
                                                        })}
                                                    </>}
                                            />
                                        </>
                                    )}
                                </div>
                            )}

                            {workerProfile.permissionDocuments.length !== 0 && (
                                <div className="section">
                                    <div className="section-title" style={{ display: "flex", alignItems: "center" }}>
                                        Допуски, подтвеждающий документ
                                    </div>
                                    <div className="documents-list">
                                        {workerProfile.permissionDocuments &&
                                            workerProfile.permissionDocuments.map((doc: any, index: number) => (
                                                <div className="documents-list-versions">
                                                    <Documents
                                                        key={doc.id + "_" + index}
                                                        docNumber={doc.number}
                                                        issueDate={doc.date}
                                                        pathPreview={doc.pathPreview && doc.pathPreview}
                                                        path={doc.path && doc.path}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}

                            <div className="experience-wrapper section">
                                {(workerProfile.experience || workerProfile.withoutExperience) && (
                                    <div className="title">
                                        Опыт работы
                                    </div>
                                )}
                                {workerProfile.withoutExperience &&
                                    ((workerProfile.experience && workerProfile.experience.length === 0) || !workerProfile.experience) && (
                                        <span>Без опыта работы</span>
                                    )}
                                {workerProfile.experience && (
                                    <ul className="experience-card-list">
                                        {workerProfile.experience.map((exp: any, index: number) => {
                                            return (
                                                <div key={exp.type + " " + index} style={{ marginBottom: "5px" }}>
                                                    <li className="experience">
                                                        <div className="experience-inner">
                                                            <ul className="experience-list">
                                                                <li className="experience-item">
                                                                    <InfoBlock title="Наименование производства"
                                                                               content={exp.production} />
                                                                </li>
                                                                <li className="experience-item">
                                                                    <InfoBlock title="Наименование агрегата"
                                                                               content={exp.unit} />
                                                                </li>
                                                                <li className="experience-item">
                                                                    <InfoBlock title="Тип работ" content={exp.type} />
                                                                </li>
                                                                <li className="experience-item">
                                                                    <InfoBlock
                                                                        title="Опыт работы"
                                                                        content={getFormattedDeclinableDate(exp.expYears, exp.expMonths)}
                                                                    />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </div>
                                            );
                                        })}
                                    </ul>
                                )}

                            </div>
                        </div>

                    </div>

                </>
            )}
        />
    );
}
